import React, { useState, useEffect } from "react";
import AreaClient from "../../api-services/area/area-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../../hooks/useStateOfTree";

function CreateArea({ data, hide, handleCallback }) {
  const [areaName, setAreaName] = useState("");
  const [areaPosition, setAreaPosition] = useState("true");
  const [positionList, setPositionList] = useState([]);
  const [chosenPosition, setChosenPosition] = useState();
  const [parentId, setParentId] = useState();

  const client = new AreaClient();
  const { t } = useTranslation();
  const { changeActId } = useLocalStorage();

  const addArea = async () => {
    let order = positionList.filter((x) => x.value === chosenPosition)[0]
      ?.orderBy;
    let data = {
      areaName: areaName,
      parentId: parentId,
      isLast: areaPosition === "true",
      orderBy: areaPosition === "true" ? undefined : order,
    };
    let response = await client.addArea(data);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      changeActId(response.data.id);
      handleCallback();
    }
    hide();
  };

  useEffect(() => {
    let res = data.children?.map((x) => {
      return { value: x.id, label: x.areaName, orderBy: x.orderBy };
    });
    setPositionList(res);
    setChosenPosition(res[0]?.value);
    setParentId(data.id);
    setAreaPosition("true");
  }, [data]);

  return (
    <>
      <div className="form-group">
        <label>Назив области</label>
        <input
          className="form-control"
          type="text"
          value={areaName}
          onChange={(e) => setAreaName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="radio"
          value="true"
          checked={areaPosition === "true" ? "checked" : ""}
          onChange={(e) => setAreaPosition(e.target.value)}
        />{" "}
        Додај као последњу
        <br />
        <input
          type="radio"
          value="false"
          checked={areaPosition === "false" ? "checked" : ""}
          disabled={positionList?.length < 1 ? "disabled" : ""}
          onChange={(e) => setAreaPosition(e.target.value)}
        />{" "}
        Додај на позицију
      </div>
      <div className="form-group">
        <Select
          options={positionList}
          onChange={(e) => setChosenPosition(e.value)}
          value={positionList.filter((x) => x.value === chosenPosition)}
          isDisabled={areaPosition === "true" ? "disabled" : ""}
        />
      </div>
      <div className="form-group">
        <button type="button" className="btn btn-primary" onClick={addArea}>
          Додај
        </button>
      </div>
    </>
  );
}

export default CreateArea;
