import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PPP;

class SettingsClient {
    async getAll(isBackground) {
        try {
            var response = await api.get(baseurl + "/Settings/GetAll", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getByParameterKey(parameterKey, isBackground) {
        try {
          const params = {
            params: {parameterKey: parameterKey},
            isBackground: isBackground
          };
          var response = await api.get(baseurl + "/Settings/GetByParameterKey/", params);
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async update(data, isBackground) {
        try {
          var response = await api.put(baseurl + "/Settings/Update/", data, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
}

export default SettingsClient;