import Joi from "joi-browser";

class SettingsModel {
    constructor(id = 0, parameterKey = "", parameterValue = "", description="") {
        this.id = id;
        this.parameterKey = parameterKey;
        this.parameterValue = parameterValue;
        this.description = description;
    }
}

export const JSONFilesList = [
    {
      value: 0,
      label: "Сви",
    },
    {
      value: 1,
      label: "RFP_USER_1",
    },
    {
      value: 2,
      label: "RFP_USER_2",
    },
    {
      value: 3,
      label: "RFP_USER_3"
    },
    {
      value: 4,
      label: "RFP_USER_4",
    },
    ];

const settingsSchema = {
    parameterKey: Joi.string().required(),
    parameterValue: Joi.string().max(500).required(),
    description: Joi.string().optional()

}



export { SettingsModel, settingsSchema }