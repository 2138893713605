import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_REGULATIONS;

class ActTypeClient {
    async getAllActType(isBackground) {
        try {
            const response = await API.get(baseurl + "/ActType/GetAllActTypes", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async getActiveActTypes(isBackground) {
        try {
            const response = await API.get(baseurl + "/ActType/GetActiveActTypes", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async createActType(data, isBackground) {
        try {
            const response = await API.post(baseurl + "/ActType/CreateActType", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async updateActType(data, isBackground) {
        try {
            const response = await API.put(baseurl + "/ActType/UpdateActType", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    } 
}

export default ActTypeClient;