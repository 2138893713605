import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import "react-datepicker/dist/react-datepicker.css";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import DialogModal from "../../../common/modal/Modal.component";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";

const AllEditions = ({ packages, setPackages, userGuid }) => {
  const [tableData, setTableData] = useState([]);
  const [edition, setEdition] = useState({
    validFrom: new Date(),
    validTo: new Date(),
    comment: "",
  });

  const [show, setShow] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    validFrom: Joi.date().required(),
    validTo: Joi.date().required(),
    comment: Joi.string().allow("").optional(),
  };

  const columns = [
    {
      Header: "Број пакета",
      accessor: "id",
    },
    {
      Header: "Назив пакета",
      accessor: "shortDescription",
    },
  ];

  const client = new CreateClient();

  useEffect(() => {
    setTableData(packages);
  }, [packages]);

  const showModal = () => setShow(true);

  const hideModal = () => setShow(false);

  const CreateRequest = async (approve) => {
    var data = [];
    tableData.forEach((x, index) => {
      if (selectedRowIds.includes(index.toString())) {
        data.push({
          packageId: x.id,
          validFrom: edition.validFrom,
          validTo: edition.validTo,
          comment: edition.comment,
        });
      }
    });

    const errorsResult = validateForm(edition, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      let response = await client.requestForAssignUserPackage(
        userGuid,
        approve,
        data
      );
      hideModal();
      var handledResponse = HandlingResponse(response);
      if(handledResponse) {
        let result = await client.getNonAssignedUserPackages(userGuid);
        let res = HandlingResponse(result);
        setPackages(res);
      }
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, edition, errors, schema, t);
    setEdition(result.data);
    setErrors(result.errors);
  };

  return packages.length > 0 ? (
    <>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={false}
        serverSide={false}
        onRowSelectStateChange={setSelectedRowIds}
      />
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            showModal();
          }}
        >
          Додај
        </button>
      </div>
      <DialogModal
        show={show}
        onHide={() => hideModal()}
        title={"Захтев за додавање пакета"}
      >
        <div className="form-group">
          <label>Важи од<span className="required-asterisk">*</span></label>
          <CustomDatePicker
            selected={edition.validFrom}
            onChange={(date) => handlePropertyChange(date, "validFrom")}
            showMonthDropdown
            useShortMonthInDropdown
          />
          {errors?.validFrom && (
            <div className="text-danger">{errors.validFrom}</div>
          )}
        </div>
        <div className="form-group">
          <label>Важи до<span className="required-asterisk">*</span></label>
          <CustomDatePicker
            selected={edition.validTo}
            onChange={(date) => handlePropertyChange(date, "validTo")}
            showMonthDropdown
            useShortMonthInDropdown
          />
          {errors?.validTo && (
            <div className="text-danger">{errors.validTo}</div>
          )}
        </div>
        <div className="form-group">
          <label>Коментар</label>
          <textarea
            className="form-control"
            name="comment"
            value={edition.comment}
            onChange={(e) => handlePropertyChange(e.target)}
          ></textarea>
          {errors?.comment && (
            <div className="text-danger">{errors.comment}</div>
          )}
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => CreateRequest(false)}
          >
            Креирај захтев
          </button>
          <button
            type="button"
            className="btn btn-primary m-2"
            onClick={() => CreateRequest(true)}
          >
            Креирај и одобри захтев
          </button>
        </div>
      </DialogModal>
    </>
  ) : (
    <label>Не постоје пакети за додавање.</label>
  );
};

export default AllEditions;
