import React, { useState, useEffect } from "react";
import SourceKindClient from "../../api-services/source-kind/source-kind-clinet";
import { SourceKindModel, SourceKindSchema } from "../../models/SourceKind";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";

const CreateSourceKind = ({ row, handleCallback, isEdit }) => {
  const {t} = useTranslation();
  
  const [sourceKind, setSourceKind] = useState(new SourceKindModel());
  const [errors, setErrors] = useState({});
  var clientSourceKind = new SourceKindClient();

  useEffect(() => {
    setSourceKind(row);
    setErrors({});
  }, [row]);

  const createSourceKind = async (e) => {
    e.preventDefault();
    const result = validateForm(sourceKind, SourceKindSchema, t);
    if (isEdit) var response = await clientSourceKind.updateSourceKind(sourceKind);
    else var response = await clientSourceKind.createSourceKind(sourceKind);
    validateAction(response);
    setErrors(result);
    setSourceKind(new SourceKindModel());
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, sourceKind, errors, SourceKindSchema, t);
    setSourceKind(result.data);
    setErrors(result.errors);
  };

  const validateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  }

  return (
    <form onSubmit={createSourceKind}>
      <div className="form-group row">
        <div>
          <label>{t("Name")}<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={sourceKind.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div>
          <label>{t("Weight")}<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="weight"
            value={sourceKind.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <br />
      </div>
      <input
                id="activeSourceKindCB"
                    type="checkbox"
                    value={sourceKind.active}
                    checked={sourceKind.active}
                    onChange={(event) => {
                        setSourceKind({ ...sourceKind, active: event.target.checked });
                    }}
          />
          <label htmlFor="activeSourceKindCB">{t("Active")}</label>
      <button
        className="btn btn-primary pull-right"
        onClick={createSourceKind}
      >
        {t("Save")}
      </button>
    </form>
  );
};

export default CreateSourceKind;
