import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_LOZA;

class SettingsClient {
    async getAll(isBackground) {
        try {
          var response = await api.get(baseurl + "/PromoSettings/GetAll", {isBackground: isBackground});
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getByParameterKey(parameterKey, isBackground) {
        try {
          var response = await api.get(baseurl + "/PromoSettings/GetByParameterKey?parameterKey=" + parameterKey, {isBackground: isBackground});
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateSetting(data, isBackground) {
        try {
          var response = await api.put(baseurl + "/PromoSettings/Update", data, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
}

export default SettingsClient;