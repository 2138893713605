import React, { useState, useEffect, useCallback } from "react";
import SideModal from "../../../common/modal/SideModal.component";
import Table from "../../../common/table/Table.component";
import CreateAuthorityGroup from "../create-authority-group/createAuthorityGroup.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthorityClient from "../../api-services/authority/authority-client";
import { AuthorityGroupModel } from "../../models/Authority";
import ButtonWithComfirmation from '../../../common/button-with-confirmation/ButtonWithConfirmation.component'
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";


const AuthorityGroup = ({handleChange}) => {
  const {t} = useTranslation();
  const tableName = 'MML_AUTHORITY_GROUP';
  
  const client = new AuthorityClient();

  const columns = [
    {
      Header: "Id",
      accessor: "id"
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
       
    },
    {
      Header: t("Weight"),
      accessor: "weight"
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
          <button
          title="Измени"
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
          <ButtonWithComfirmation 
          className="btn btn-danger table-btn" 
          onClick={() => onDeleteClick(row.original.id)}
          contentText={"Група доносиоца ће бити трајно обрисана."}
          handleYesText={"Обриши"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];


  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AuthorityGroupModel());
  const [allAuthorityGroups, setAllAuthorityGroups] = useState([]);
  const [title, setTitle] = useState("");

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AuthorityGroupModel())
  }

  const getAuthorityGroups= async () => {
    let response = await client.getAuthorityGroup();
    let res = HandlingResponse(response);
    setAllAuthorityGroups(res);
  }

  useEffect(() => {
    getAuthorityGroups();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new AuthorityGroupModel());
    showModal();
    setTitle(t("CreateAuthorityGroup"));
  };
  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditAuthorityGroup"));
  };

  const onDeleteClick = async (id) => {
    let response = await client.deleteAuthorityGroup(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error") {
      let allAuthorityGroupsCopy = allAuthorityGroups.filter((x) => x.id !== id);
      setAllAuthorityGroups(allAuthorityGroupsCopy);
    }
  };

  const handleTableRowClick = useCallback((response) => {
    getAuthorityKindsByGroup(response.id, response.name);
  });

  const getAuthorityKindsByGroup = async (id, name) => {
    let response = await client.getAuthorityKindsByAuhtorityGroupId(id, true);
    let res = HandlingResponse(response);
    handleChange(res, id, name);
  };

  return (
    <>
      <div>
        <h5>{t("AuthorityGroups")}</h5>
        <button className="btn btn-primary" onClick={onCreateClick}>
          {t("AddGroup")}
        </button>
      </div>
      <br />
      <Table
        rememberFilter={true}
        tableName={tableName}
        columns={columns}
        data={allAuthorityGroups}
        hiddenColumn={["id"]}
        serverSide={false}
        handleClickOnRow={handleTableRowClick}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAuthorityGroup
          row={rowData}
          isEdit={isEdit}
          handleCallback={() => {
            hideModal();
            getAuthorityGroups();
          }}
        />
      </SideModal>
    </>
  );
};

export default AuthorityGroup;
