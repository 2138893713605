import { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import UserClient from "../../api-services/create-users/create-users-client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const AddPackages = ({ userId, onHide, tableName, handleCallback }) => {
    const [selectAll, setSelectAll] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            accessor: "checkbox",
            disableSortBy: true,
            className: "checkbox-column",
            Header: () => (
                <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    checked={selectAll}
                    onChange={handleChange}
                />
            ),
            Cell: ({ row }) => {
                return (
                    <>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={row.original.id}
                            checked={row.original.isChecked}
                            onChange={() => HandleCheckbox(row.original)}
                        />
                    </>
                );
            },
            width: 100
        },
        {
            Header: "Шифра",
            accessor: "extCode",
            width: 200
        },
        {
            Header: "Опис пакета",
            accessor: "shortDescription",
            width: 350
        },
        {
            Header: "Цена",
            accessor: "price",
            width: 100
        },
        {
            Header: "Датум од",
            accessor: "dateFrom",
            Cell: ({ row }) => {
                return (
                    <CustomDatePicker
                        selected={new Date(row.original.dateFrom)}
                        onChange={(date) => handleInputChange(row.original, date, "dateFrom")}
                        showMonthDropdown
                        useShortMonthInDropdown
                        placeholderText="Изабери датум"
                        disabled={row.original.isChecked ? "" : "disabled"}
                    />
                )
            },
            width: 200
        },
        {
            Header: "Датум до",
            accessor: "dateTo",
            Cell: ({ row }) => {
                return (
                    <CustomDatePicker
                        selected={new Date(row.original.dateTo)}
                        onChange={(date) => handleInputChange(row.original, date, "dateTo")}
                        showMonthDropdown
                        useShortMonthInDropdown
                        placeholderText="Изабери датум"
                        disabled={row.original.isChecked ? "" : "disabled"}
                    />
                )
            },
            width: 200
        },
        {
            Header: "Е-маил",
            disableSortBy: true,
            accessor: "sendEmail",
            Cell: ({ row }) => {
                return (
                    <>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={row.original.sendEmail}
                            onChange={() => handleInputChange(row.original, !(row.original.sendEmail), "sendEmail")}
                            disabled={row.original.isChecked ? "" : "disabled"}
                        />
                    </>
                );
            },
            width: 150
        },
        {
            Header: "Детаљан е-маил",
            disableSortBy: true,
            accessor: "advancedEmail",
            Cell: ({ row }) => {
                return (
                    <>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={row.original.advancedEmail}
                            onChange={() => handleInputChange(row.original, !(row.original.advancedEmail), "advancedEmail")}
                            disabled={row.original.isChecked ? "" : "disabled"}
                        />
                    </>
                );
            },
            width: 150
        },
        {
            Header: "СМС",
            accessor: "sendSms",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={row.original.sendSms}
                            onChange={() => handleInputChange(row.original, !(row.original.sendSms), "sendSms")}
                            disabled={row.original.isChecked ? "" : "disabled"}
                        />
                    </>
                );
            },
            width: 150
        }
    ]

    const client = new UserClient();

    const getAllPackages = async () => {
        let response = await client.GetAllPackages();
        let res = HandlingResponse(response);
        res = res.filter((item) => !item.isGroup)
        var result = res.map((item) => {
            return { ...item, sendEmail: false, advancedEmail: false, sendSms: false, isChecked: false };
        });
        setData(result);
    }

    useEffect(() => {
        getAllPackages();
    }, []);

    const addUserPackages = async () => {
        let packages = data.filter((item) => item.isChecked).map((p) => {
            return { packageIdOfProduct: p.id, userId: userId, validFrom: p.dateFrom, validTo: p.dateTo, sendEmail: p.sendEmail, sendSms: p.sendSms, advancedEmail: p.advancedEmail };
        })
        let response = await client.AddUserPackages(packages);
        onHide();
        HandlingResponse(response);
        handleCallback();
    }

    const handleChange = (e) => {
        let tableDataCopy = [...data];
        tableDataCopy.forEach((x) => (x.isChecked = !selectAll));
        setSelectAll(!selectAll);
        setData(tableDataCopy);
    };

    const HandleCheckbox = (row) => {
        let tableDataCopy = [...data];
        let index = tableDataCopy.indexOf(row);
        tableDataCopy[index].isChecked = !tableDataCopy[index].isChecked;
        setData(tableDataCopy);

        let tableDataCount = tableDataCopy
            .filter((data) => data.isChecked == false).length;

        if (tableDataCount > 0) {
            setSelectAll(false)
        }
        else {
            setSelectAll(true)
        }
    };

    const handleInputChange = (row, value, column) => {
        let tableDataCopy = [...data];
        let index = tableDataCopy.indexOf(row);
        tableDataCopy[index][column] = value;
        setData(tableDataCopy);
    }

    return (
      <>
        <Table
          columns={columns}
          data={data}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
        />
        <div style={{ marginTop: "10px" }}>
          <button type="button" className="btn btn-primary" onClick={onHide}>
            Одустани
          </button>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => addUserPackages()}
          >
            Додај пакете
          </button>
        </div>
      </>
    );
}

export default AddPackages;