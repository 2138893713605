import React, { useEffect, useRef, useState } from "react";
import DialogModal from "../../../common/modal/Modal.component";
import CreateOrEditProduct from "../../components/create-edit-product/CreateOrEditProduct.component";
import ProductClient from "../../api-services/product/product-client";
import Tree from "../../../common/tree/Tree.component";
import CreateOrEditArticle from "../../components/create-edit-article/CreateOrEditArticle.component";
import { productOperationTypes } from "../../models/ProductModel";
import ArticleClient from "../../api-services/article/article-client";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { ArticleModel } from "../../models/ArticleModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSearchParams } from "react-router-dom";

function Index() {
  const [searchParams] = useSearchParams();
  const [productTreeData, setProductTreeData] = useState(null);
  const [articleTreeData, setArticleTreeData] = useState(null);
  const [productOfArticlesChanged, setProductOfArticlesChanged] = useState(false);
  const [deletingType, setDeletingType] = useState();

  const [showModalForCreateProduct, setShowModalForCreateProduct] =
    useState(false);
  const [showModalForCreateArticle, setShowModalForCreateArticle] =
    useState(false);
  const [showModalForDelete, setShowModalForDelete] =
    useState(false);

  const selectedProduct = useRef(null);
  const selectedArticle = useRef(null);
  const selectedOperationType = useRef(null);

  const productClient = new ProductClient();
  const articleClient = new ArticleClient();

  const preselectedProductId = searchParams.get("productId") && parseInt(searchParams.get("productId"));
  const preselectedArticleId = searchParams.get("articleId") && searchParams.get("articleId");

  const productContextMenu = Object.keys(productOperationTypes).map(x => ({
    callback: (obj) => {
      selectedProduct.value = obj;
      if (productOperationTypes[x] == productOperationTypes.deleteElement) {
        ShowModalDelete(productOperationTypes[x], selectedProduct.value.id);
        setDeletingType('DELETE_PRODUCT')
      }
      else {
        ShowModalCreateProduct(productOperationTypes[x]);
        setDeletingType();
      }
    },
    label: productOperationTypes[x].label,
    renderingCondition: (obj) => obj.outdated === false || productOperationTypes[x].outdatedAllowed == true
  }));

  const articleContextMenu = Object.keys(productOperationTypes).map(x => ({
    callback: (obj) => {
      selectedArticle.value = obj;
      if (productOperationTypes[x] == productOperationTypes.deleteElement) {
        ShowModalDelete(productOperationTypes[x], selectedArticle.value.id);
        setDeletingType('DELETE_ARTICLE')
      }
      else {
        ShowModalCreateArticle(productOperationTypes[x]);
        setDeletingType();
      }
    },
    label: productOperationTypes[x].label,
    renderingCondition: (obj) => obj.id !== 0
  }));

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    productClient.getAllProducts().then((response) => {
      setProductTreeData(response.data);
    });
  };
  const getArticlesForProduct = (obj) => {
    selectedProduct.value = obj;
    articleClient.getArticlesByProductId(obj.id).then((response) => {
      setArticleTreeData(response.data.articles);
      setProductOfArticlesChanged(response.data.changed)
    });
  };

  const deleteElement = async () => {
    if (deletingType === 'DELETE_ARTICLE' && selectedArticle.value) {
      var response = await articleClient.deleteArticle(selectedArticle.value.id);
      var handledResponse = HandlingResponse(response);
      if (handledResponse !== 'Error') {
        getArticlesForProduct(selectedProduct.value);
      }
    }
    else if(deletingType === 'DELETE_PRODUCT') {
      response = await productClient.deleteProduct(selectedProduct.value.id);
      handledResponse = HandlingResponse(response);
      if (handledResponse !== 'Error') {
        getProducts();
        setArticleTreeData(null)
      }
    }

  };

  const addArticleToEmptyProduct = () => {
    if (selectedProduct.value) {
      selectedArticle.value = new ArticleModel();
      selectedArticle.value.parentId = null;
      selectedArticle.value.productId = selectedProduct.value.id;
      ShowModalCreateArticle(productOperationTypes.addElement);
    }
  };
  const clearArticleText = async () => {
    const response = await articleClient.clearArticleText(
      selectedProduct.value.id
    );
    var handledResponse = HandlingResponse(response);
    if (handledResponse !== 'Error') {
      getArticlesForProduct(selectedProduct.value);
    }
  };

  const ShowModalCreateProduct = (operationType) => {
    selectedOperationType.value = operationType;
    setShowModalForCreateProduct(true);
  };
  const HideModalCreateProduct = () => {
    selectedOperationType.value = null;
    setShowModalForCreateProduct(false);
  };
  const ShowModalCreateArticle = (operationType) => {
    selectedOperationType.value = operationType;
    setShowModalForCreateArticle(true);
  };
  const HideModalCreateArticle = () => {
    selectedOperationType.value = null;
    setShowModalForCreateArticle(false);
  };

  const ShowModalDelete = (operationType) => {
    selectedOperationType.value = operationType;
    setShowModalForDelete(true);
  };
  const HideModalDelete = () => {
    selectedOperationType.value = null;
    setShowModalForDelete(false);
  };
  return (
    <div>
      {selectedProduct.value && selectedOperationType.value && (
        <DialogModal
          show={showModalForCreateProduct}
          onHide={HideModalCreateProduct}
          title={selectedOperationType.value.label}
        >
          <CreateOrEditProduct
            selectedOperationType={selectedOperationType.value}
            selectedProduct={selectedProduct.value}
            hide={HideModalCreateProduct}
            handleRefreshTree={getProducts}
          />
        </DialogModal>
      )}
      {selectedArticle.value && selectedOperationType.value && (
        <DialogModal
          show={showModalForCreateArticle}
          onHide={HideModalCreateArticle}
          title={selectedOperationType.value.label}
        >
          <CreateOrEditArticle
            selectedOperationType={selectedOperationType.value}
            selectedArticle={selectedArticle.value}
            hide={HideModalCreateArticle}
            handleRefreshTree={() =>
              getArticlesForProduct(selectedProduct.value)
            }
          />
        </DialogModal>
      )}
      {(selectedProduct.value || selectedArticle.value) && (
        <AlertDialog
          handleYes={() => {
            deleteElement();
            HideModalDelete();
          }}
          handleNo={() => {
            HideModalDelete();
          }}
          show={showModalForDelete}
          contentText={"Да ли сте сигурни да желите да обришете елемент?"}
          handleYesText={"Обриши"}
          className="btn btn-primary"
        />
      )}
      <div className="row">
        {productTreeData && (
          <div className="col-md-6">
            <h5>Производи</h5>
            <hr />
            <Tree
              data={productTreeData}
              contextMenu={productContextMenu}
              expanded={true}
              handleSelected={(obj) => getArticlesForProduct(obj)}
              preselectedNodeId={preselectedProductId}
              saveStateKey={"productsTree"}
            />
          </div>
        )}
        {articleTreeData ? (
          <div className="col-md-6">
            <h5>
              Артикли
              <button
                type="button"
                className="btn btn-primary pull-right"
                onClick={addArticleToEmptyProduct}
              >
                <FontAwesomeIcon icon={solid("plus")} /> Додај артикал
              </button>
              {selectedProduct.value.outdated != true &&
                selectedProduct.value.id != 0 &&
                productOfArticlesChanged && (
                  <button
                    type="button"
                    className="btn btn-light pull-right"
                    style={{ marginRight: "5px" }}
                    onClick={clearArticleText}
                  >
                    <FontAwesomeIcon icon={solid("trash")} /> Поништи промене
                  </button>
                )}
            </h5>
            <hr />
            {articleTreeData.length > 0 && (
              <Tree
                data={articleTreeData}
                contextMenu={articleContextMenu}
                expanded={true}
                preselectedNodeId={preselectedArticleId}
                saveStateKey={"articlesTree"}
              />
            )}
            {articleTreeData.length === 0 && (
              <div className="center-info">
                Одабрани производ нема додељених артикала.
              </div>
            )}
          </div>
        ) : (
          <div className="col-md-6 center-info">
            Изаберите производ како бисте видели додељене артикле.
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
