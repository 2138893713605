import { useEffect, useState } from "react";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import DefaultInterestClient from "../../api-services/codebooks/default-interests/default-interests";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const CreateDefaultInterest = ({ row, isEdit, handleAddingDataToTable }) => {
  const { t } = useTranslation();
  const client = new DefaultInterestClient();
  const [defaultInterest, setDefaultInterest] = useState({
    value: "",
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [errors, setErrors] = useState({});

  const schema = {
    value: Joi.number().min(0).max(9999.9999),
    dateFrom: Joi.date().required(),
  };

  useEffect(() => {
    setDefaultInterest(row);
    setErrors({});
  }, [row]);

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, defaultInterest, errors, schema, t);
    setDefaultInterest(result.data);
    setErrors(result.errors);
  };

  const CreateDefaultInterest = async () => {
    const errorResult = validateForm(defaultInterest, schema, t);
    setErrors(errorResult);

    if (!errorResult) {
      if (isEdit)
        var response = await client.UpdateInterestRateHistory(defaultInterest);
      else
        var response = await client.CreateInterestRateHistory(defaultInterest);
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
      else handleAddingDataToTable();
    }
  };

  return (
    <>
      <label>Вредност<span className="required-asterisk">*</span></label>
      <input
        className="form-control"
        type="number"
        name="value"
        value={defaultInterest.value}
        onChange={(event) => handlePropertyChange(event.target)}
      />
      {errors?.value && <div className="text-danger">{errors.value}</div>}
      <div className="form-group">
        <label>Датум од<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={new Date(defaultInterest.dateFrom)}
          showMonthDropdown
          useShortMonthInDropdown
          disabled
          isClearable={false}
        />
        {errors?.dateFrom && (
          <div className="text-danger">{errors.dateFrom}</div>
        )}
      </div>
      <div className="form-group">
        <label>Датум до</label>
        <CustomDatePicker
          selected={defaultInterest.dateTo ? new Date(defaultInterest.dateTo) : ""}
          onChange={(date) =>
            setDefaultInterest({ ...defaultInterest, dateTo: date })
          }
          showMonthDropdown
          useShortMonthInDropdown
          disabled={isEdit}
          isClearable={!isEdit && defaultInterest.dateTo}
        />
        {errors?.dateTo && <div className="text-danger">{errors.dateTo}</div>}
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={() => CreateDefaultInterest()}
        >
          Сачувај
        </button>
      </div>
    </>
  );
};

export default CreateDefaultInterest;
