import { env } from "../../../env";
import api from "../../../api";
import axios from "axios";

var baseurl = env.REACT_APP_API_URL_REG;

class LawActClient {
  async getLawActVersions(id, isBackground) {
    try {
      const params = {
        params: { id: id },
        isBackground: isBackground
      };
      var response = await api.get(baseurl + "/LawAct/GetLawActVersions/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async getLawAct(id, isBackground) {
    try {
      const params = {
        params: { id: id },
        isBackground: isBackground
      };
      var response = await api.get(baseurl + "/LawAct/GetLawAct/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawActByName(inputValue, isBackground) {
    let data = {
      actName: inputValue,
    };
    try {
      var response = await api.post(baseurl + "/LawAct/GetLawActByName", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async generateLawActLinks(actNames, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAct/GenerateLawActLinks", actNames, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawActByNameForReg(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAct/GetLawActByNameForReg", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllDeadlineLawActs(isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAct/GetAllDeadlineLawActs", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async addLawAct(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.post(baseurl + "/LawAct/AddLawAct", data,
        {
          headers: headers
        },
        { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async addLawActFromGazette(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAct/AddLawActFromGazette", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateLawAct(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.put(baseurl + "/LawAct/UpdateLawAct", data, {
        headers: headers
      }, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteLawActVersion(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/LawAct/DeleteLawActVersion/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createNewLawActVersion(id, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAct/CreateNewLawActVersion/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async changeLawActVersionToActual(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAct/ChangeLawActVersionToActual/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deactivateLawAct(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAct/DeactivateLawAct/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterByAreaId(id, isBackground) {
    const params = {
      params: { areaId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/FilterByAreaId", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawActsByAreaId(id, isBackground) {
    const params = {
      params: { areaId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/GetLawActsByAreaId", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterByName(name, isBackground) {
    const params = {
      params: { name: name },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/FilterByName", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterByExpiredValidToDate(date, isBackground) {
    const params = {
      params: { validTo: date },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/FilterByExpiredValidToDate", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async moveLawActs(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAct/MoveLawActs/", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async cancelUpdateLawAct(lawActId, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAct/CancelUpdateLawAct/" + lawActId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async massIndexing(areaIdsList, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAct/MassIndexing/", areaIdsList, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async indexLawAct(id, isBackground) {
    const params = {
      params: { id: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/IndexLawAct/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexLawAct(id, isBackground) {
    const params = {
      params: { id: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/DeleteIndexLawAct/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFilesFromRepo(id, zipName) {
    try {
      var response = await api.get(baseurl + "/LawAct/DownloadFilesFromRepo/" + id, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = zipName;
          link.click();
        });
    }
    catch (err) {
      if (err.response.status === 404 && err.response.config.responseType === 'blob') {
        let responseObj = await err.response.data.text();
        return JSON.parse(responseObj);
      }
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawActWithoutEli(isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAct/GetLawActsWithoutEli/", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetLawActHtml(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAct/GetLawActHtml?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateLawActFile(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.put(baseurl + "/LawAct/UpdateLawActFile", data, {
        headers: headers,
        isBackground: isBackground
      });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLockedLawActsByAreaId(areaId, isBackground) {
    const params = {
      params: { areaId: areaId },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/LawAct/GetLockedLawActsByAreaId", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllLockedLawActs(isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAct/GetAllLockedLawActs",  { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async unlockLawAct(lawActId, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAct/UnlockLawAct/" + lawActId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default LawActClient;