import { createContext } from "react";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import AreaClient from "../api-services/area/area-client";

const LockingAreaContext = createContext({});

export const LockingAreaProvider = ({ children }) => {

    const areaClient = new AreaClient();

    const lockArea = async (id) => {
        let response = await areaClient.lockArea(id);
        HandlingResponse(response);
    }

    const unlockAllArea = async () => {
        let response = await areaClient.unlockAllArea();
        HandlingResponse(response);
    }
    return (
        <LockingAreaContext.Provider value={{ lockArea, unlockAllArea }}>
            {children}
        </LockingAreaContext.Provider>
    )
}

export default LockingAreaContext