import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_LOZA;

class CreateClient {
    async getAllPackages(isBackground) {
        try {
            var response = await api.get(baseurl + "/Package/GetAllActivePackages", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

    }

    async createUser(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForCreateUser?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async createGroupAccounts(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForGroupCreationUser?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getUsersByUsername(username, isBackground) {
        try {
            var response = await api.get(baseurl + "/User/GetIndividualUsersByUsername?username=" + username, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getUserWithPackages(userGuid, isBackground) {
        try {
            var response = await api.get(baseurl + "/UserPackage/GetUserPackagesByUserGuid?userGuid=" + userGuid, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getNonAssignedUserPackages(userGuid, isBackground) {
        try {
            var response = await api.get(baseurl + "/Package/GetNonAssignedPackages?userGuid=" + userGuid,{ isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async requestForCancellationOrExtendingUserPackage(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForCancellationOrExtendingUserPackage?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async requestForAssignUserPackage(userGuid, approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForAssignUserPackage?userGuid=" + userGuid + "&approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async resetPassword(userGuid, isBackground) {
        try {
            var response = await api.get(baseurl + "/User/ResetPassword?userGuid=" + userGuid, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async changeNumberOfConnections(userGuid, numOfConnections, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/SetMaxNumOfSession?userGuid=" + userGuid + "&maxNumOfSession=" + numOfConnections, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async extendGroupUserPackage(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForGroupExtendingUserPackage?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async cancelGroupUserPackage(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForGroupCancellationUserPackage?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getGroupUserPackagesForExtending(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/UserPackage/GetGroupUserPackagesForExtending", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getGroupUserPackagesForCancellation(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/UserPackage/GetGroupUserPackagesForCancellation", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getUsersByPackageIds(packageIds, isBackground) {
        try {
            var response = await api.post(baseurl + "/UserPackage/GetUserPackagesByPackageIds", packageIds, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async extendUserPackages(approve, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/RequestForExtendingUserPackage?approve=" + approve, data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getPackages(isBackground) {
        try {
            var response = await api.get(baseurl + "/Package", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterIndividualUsers(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/FilterIndividualUsers", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterGroupUsers(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/FilterGroupUsers", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetAllGroupPackages(isBackground) {
        try {
            var response = await api.get(baseurl + "/Package/GetAllGroupPackages", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

    }

    async GetUserInfoByUserGuid(userGuid, isBackground) {
        const params = {
            params: { userGuid: userGuid },
            isBackground: isBackground
          };
        try {
            var response = await api.get(baseurl + "/UserPackage/GetUserInfoByUserGuid/", params);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async killSessions(userGuid, isBackground) {
        try {
            var response = await api.get(baseurl + "/User/KillActiveSessions?userGuid=" + userGuid, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetAllCustomersFromNav(isBackground) {
      try {
          var response = await api.get(baseurl + "/User/GetAllCustomersFromNav", { isBackground: isBackground });
          return response.data;
      } catch (err) {
    if (err.response.status === 400) return err.response.data;
    else return err.response;
    }
  }

  async GetCustomersFromNavByNo(customerNo, isBackground) {
    try {
        var response = await api.get(baseurl + "/User/GetCustomersFromNavByNo?customerNo=" + customerNo, { isBackground: isBackground });
        return response.data;
    } catch (err) {
  if (err.response.status === 400) return err.response.data;
  else return err.response;
  }
}
}

export default CreateClient;