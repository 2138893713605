import React, { useState, useEffect } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ScheduleClient from "../../api-services/schedule/schedule-client";
import SideModal from "../../../common/modal/SideModal.component";
import CreateSchedule from "../../components/create-schedule/CreateSchedule.component";

const Schedule = () => {
  const tableName = "NL_SCHEDULE";
  const client = new ScheduleClient();

  const columns = [
    {
      Header: "Опис",
      accessor: "description",
    },
    {
      Header: "Категорија",
      accessor: "categoryName",
    },
    {
      Header: "Окидач",
      accessor: "trigger",
    },
    {
      Header: "Активан",
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original.id)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState({
    id: 0,
    description: "",
    categoryName: "",
    trigger: "",
    active: false,
    isPeriodic: false,
    periodicFlag: undefined,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const getSchedules = async () => {
    let response = await client.GetSchedules();
    let result = HandlingResponse(response);
    setTableData(result);
  };

  useEffect(() => {
    getSchedules();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      id: 0,
      description: "",
      categoryName: "",
      trigger: "",
      notBefore: "",
      notBeforeTime: null,
      notAfterTime: null,
      active: false,
      subjectTemplate: "",
      bodyTemplate: "",
      isPeriodic: false,
      periodicFlag: undefined,
    });
    showModal();
    setTitle("Креирање распореда");
  };

  const onEditClick = async (id) => {
    var response = await client.GetScheduleById(id);
    setIsEdit(true);
    setRowData(response.data);
    showModal();
    setTitle("Измена распореда");
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    getSchedules();
  };
  return (
    <>
      <h5 className="col-md-2">Распоред</h5>
      <button
        onClick={() => {
          onCreateClick();
        }}
        style={{ marginTop: "10px", marginBottom: "20px" }}
        className="btn btn-primary col-md-1"
      >
        Додај распоред
      </button>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={true}
        tableName={tableName}
        serverSide={false}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateSchedule
          row={rowData}
          isEdit={isEdit}
          handleAddingDataToTable={() => {
            hideModal();
            getSchedules();
          }}
        />
      </SideModal>
    </>
  );
};

export default Schedule;
