class AuthorityModel {
    constructor(id = 0, authorityName = "", authorityId = null, description = "", creationDate = new Date(), eliAuthority = null, sort = "", isCurrent = false, active = false, ){
        this.id = id;
        this.authorityName = authorityName;
        this.authorityId = authorityId;
        this.description = description;
        this.creationDate = creationDate;
        this.eliAuthority = eliAuthority;
        this.sort = sort;
        this.isCurrent = isCurrent;
        this.active = active;
    }
}

export default AuthorityModel;