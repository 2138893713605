import React, { useEffect, useState } from "react";
import LegalMatterClient from "../../api-services/coders/legal-matter/legal-matter-client";
import LegalSubmatterClient from "../../api-services/coders/legal-sub-matter/legal-sub-matter-client";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import Moment from "moment";

const CreateLegalSubMatter = ({ row, handleCallback, isCreate }) => {
  const [legalMatterList, setLegalMatterList] = useState([]);

  const [legalSubMatter, setLegalSubMatter] = useState({
    id: 0,
    name: "",
    weight: 0,
    fkLegalMatter: "",
    isActive: true,
    userFullName: "",
    lastModifyDate: null,
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    fkLegalMatter: Joi.number().min(0).max(2147483647).required(),
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
  };

  const client = new LegalSubmatterClient();
  const legalMatterClient = new LegalMatterClient();

  useEffect(() => {
    setLegalSubMatter(row);
    setErrors({});
  }, [row, isCreate]);

  const LegalMatter = async () => {
    await legalMatterClient.LegalMatter().then((response) => {
      var handledResponse = HandlingResponse(response);
      var data =
        handledResponse !== "Error"
          ? handledResponse.map((item) => {
              return {
                value: item.id,
                label: item.name,
                name: "fkLegalMatter",
              };
            })
          : [];
      setLegalMatterList(data);
    });
  };

  const CreateLegalSubmatter = async () => {
    const errorsResult = validateForm(legalSubMatter, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...legalSubMatter,
        weight: parseInt(legalSubMatter.weight),
        lastModifyDate: new Date(),
      };
      var response = await client.CreateLegalSubmatter(data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const UpdateLegalSubmatter = async () => {
    const errorsResult = validateForm(legalSubMatter, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...legalSubMatter,
        weight: parseInt(legalSubMatter.weight),
        lastModifyDate: new Date(),
      };
      var response = await client.UpdateLegalSubmatter(legalSubMatter.id, data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  useEffect(() => {
    LegalMatter();
  }, []);

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, legalSubMatter, errors, schema, t);
    setLegalSubMatter(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Правна материја<span className="required-asterisk">*</span></label>
          <Select
            options={legalMatterList}
            onChange={(event) => {
              handlePropertyChange(event);
            }}
            value={legalMatterList?.filter(
              (x) => x.value === legalSubMatter.fkLegalMatter
            )}
          />
          {errors?.fkLegalMatter && (
            <div className="text-danger">{errors.fkLegalMatter}</div>
          )}
        </div>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={legalSubMatter.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={legalSubMatter.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div>
          <input
            id="activeSubMatterCB"
            type="checkbox"
            value={legalSubMatter.isActive}
            checked={legalSubMatter.isActive}
            onChange={(event) => {
              setLegalSubMatter({
                ...legalSubMatter,
                isActive: event.target.checked,
              });
            }}
          />
          <label htmlFor="activeSubMatterCB">Активан</label>
        </div>
        {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{legalSubMatter.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{legalSubMatter.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>
                {Moment(legalSubMatter.lastModifyDate).format("DD.MM.YYYY.")}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateLegalSubmatter()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateLegalSubmatter()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateLegalSubMatter;
