import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAuthority from "../../../components/create-authority/createAuthority.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthorityClient from "../../../api-services/authority/authority-client";
import AuthorityModel from "../../../models/Authority";
import { useCallback } from "react";
import AuthorityAdvertisementAndActType from "../../../components/authority-advertisement-and-act-type/authorityAdvertisementAndActType.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const Authority = () => {
  const { t } = useTranslation();
  const tableName = "AP_AUTHORITY";
  
  const client = new AuthorityClient();

  const columns = [
    {
      Header: t("Id"),
      accessor: "id",
      disableFilters: false,
    },
    {
      Header: t("Name"),
      accessor: "authorityName",
      disableFilters: false,
    },
    {
      Header: t("Description"),
      accessor: "description",
      disableFilters: false,
    },
    {
      Header: t("Sort"),
      accessor: "sort",
      disableFilters: false,
    },
    {
      Header: t("Actual"),
      accessor: "isCurrent",
      Cell: ({ row }) => {
        return (
          row.original.isCurrent && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      Header: t("Archival"),
      accessor: "archival",
      Cell: ({ row }) => {
        return (
          row.original.archival && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AuthorityModel());
  const [allAuthorities, setAllAuthorities] = useState([]);
  const [title, setTitle] = useState("");
  const [
    allAuthorityAdvertisementAndActTypeData,
    setAllAuthorityAdvertisementAndActTypeData,
  ] = useState([]);
  const [
    showAuthorityAdvertisementAndActType,
    setShowAuthorityAdvertisementAndActType,
  ] = useState(false);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AuthorityModel());
  };

  const getAuthorities = async () => {
    let response = await client.Authority();
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") setAllAuthorities(handledResponse);
  };

  const getAuthorityAdvertisementAndActType = async (authorityId) => {
    let response =
      await client.getAuthorityAdvertisementAndActTypesByAuthorityId(
        authorityId
      );
    let handledResponse = HandlingResponse(response);
    setAllAuthorityAdvertisementAndActTypeData(handledResponse);
    handleChangeAuthorityAdvertisementAndActType(handledResponse);
  };

  const handleTableClickOnRow = useCallback((response) => {
    getAuthorityAdvertisementAndActType(response.id);
  });

  const handleChangeAuthorityAdvertisementAndActType = useCallback(
    (response) => {
      if (response) {
        setShowAuthorityAdvertisementAndActType(true);
      }
    }
  );

  const handleAddingDataToTable = () => {
    hideModal();
    getAuthorities();
  };

  useEffect(() => {
    getAuthorities();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new AuthorityModel());
    showModal();
    setTitle(t("CreatingAuthority"));
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingAuthority"));
  };

  return (
    <>
      <div className="form-control">
        <div className="form-group">
          <h5 className="col-md-2">{t("Authorities")}</h5>
          <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
            {t("AddAuthority")}
          </button>
        </div>

        <Table
          columns={columns}
          data={allAuthorities}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
          handleClickOnRow={handleTableClickOnRow}
        />
      </div>
      <div>
        {showAuthorityAdvertisementAndActType ? (
          <AuthorityAdvertisementAndActType
            dataAuthorityAdvertisementAndActType={
              allAuthorityAdvertisementAndActTypeData
            }
          />
        ) : (
          <></>
        )}
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAuthority
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default Authority;
