import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_ADMIN_PANEL;

class CountryClient {

  async getCountry(isBackground) {
    try {
      var response = await API.get(baseurl + "/Country", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createCountry(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Country/CreateCountry", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateCountry(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Country/UpdateCountry", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteCountry(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/Country/DeleteCountry/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default CountryClient;