import { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import Joi from "joi-browser";
import ScheduleClient from "../../api-services/schedule/schedule-client";
import CategoryClient from "../../api-services/category/category-client";
import parse from "html-react-parser";
import InputMask from 'react-input-mask';


const CreateSchedule = ({ row, isEdit, handleAddingDataToTable }) => {
  const [errors, setErrors] = useState({});
  const [scheduleData, setScheduleData] = useState({
    description: "",
    categoryName: "",
    trigger: "",
    dayOfWeekFlag: 0,
    notBeforeTime: "",
    notAfterTime: "",
    active: false,
    subjectTemplate: "",
    bodyTemplate: "",
    template: "",
    isPeriodic: false,
    periodicFlag: undefined
  });
  const [hiddenBodyTemplate, setHiddenBodyTemplate] = useState("");

  const client = new ScheduleClient();
  const categoryClient = new CategoryClient();

  const [categoryList, setCategeoryList] = useState([]);
  const [dayOfWeeksList, setDayOfWeeksList] = useState([]);
  const [triggersList, setTriggerslist] = useState([]);
  const [periodicFlags, setPeriodFlags] = useState([])

  const getCategories = async () => {
    let response = await categoryClient.GetCategories();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.id, label: x.name, name: "categoryId" };
    });
    setCategeoryList(result);
  };

  const getDayOfWeeksList = async () => {
    let response = await client.GetDayOfWeeks();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.value, label: t(x.text) };
    });
    setDayOfWeeksList(result);
  };

  const getTriggers = async () => {
    let response = await client.GetTriggers();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.value, label: x.text, name: "trigger" };
    });
    setTriggerslist(result);
  };

  const getPeriodicFlags = async () => {
    let response = await client.GetPeriodicFlags();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.value, label: t(x.text), name: "periodicFlag" };
    });
    setPeriodFlags(result);
  };

  useEffect(() => {
    getCategories();
    getDayOfWeeksList();
    getTriggers();
    getPeriodicFlags();
  }, []);

  useEffect(() => {
    if (!scheduleData.isPeriodic) { setErrors({...errors, periodicFlag: ""})}
  }, [scheduleData.isPeriodic])

  useEffect(() => {
    scheduleData.trigger === "MANUAL" && setScheduleData({...scheduleData,
      dayOfWeekFlag: 0,
      notBeforeTime: null,
      notAfterTime: null,
      subjectTemplate: "",
      bodyTemplate: "",
      isPeriodic: false,
      periodicFlag: undefined
    })
  }, [scheduleData.trigger])

  const { t } = useTranslation();
 
  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, scheduleData, errors, schema, t);
    setScheduleData(result.data);
    setErrors(result.errors);
  };

  const schema = {
    categoryId: Joi.number().min(0).max(2147483647).required(),
    description: Joi.string().required(),
    trigger: Joi.string().required(),
    subjectTemplate: scheduleData.trigger === "MANUAL" ? Joi.string().allow("").optional() : Joi.string().required(),
    bodyTemplate: scheduleData.trigger === "MANUAL" ? Joi.string().allow("").optional() : Joi.string().required(),
    periodicFlag: Joi.any().when("isPeriodic", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
  };

  useEffect(() => {
    setScheduleData(row);
    setErrors({});

    if(isEdit) {
        const generateHtml = async () => {
          var data = {
            trigger: row.trigger,
            template: row.bodyTemplate,
          };
          var response = await client.GenerateExampleHtml(data);
          setHiddenBodyTemplate(response.data)
      }
  
      generateHtml()
    }
    
  }, [row]);

  const CreateSchedule = async () => {
    const errorResult = validateForm(scheduleData, schema, t);
    setErrors(errorResult);

    var data = {
      ...scheduleData,
    };

    if (!errorResult) {
      if (isEdit) var response = await client.UpdateSchedule(data);
      else var response = await client.CreateSchedule(data);
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      } else handleAddingDataToTable();
    }
  };

  // Renderovanje HTML-a

  const onChangeHtml = async (event) => {
    const { name, value } = event.target;

    var data = {
      trigger: scheduleData.trigger,
      template: value,
    };

    var response = await client.GenerateExampleHtml(data);
    setHiddenBodyTemplate(response.data);

  };

  const handleChange = (event, field) => {
    const input = event.target.value;
    const limitedInput = limitTimeInput(input);
  
    setScheduleData(prevState => ({
      ...prevState,
      [field]: limitedInput,
    }));
  };
  
  const limitTimeInput = (input) => {
    // Ogranicavanje inputa na brojeve/kolone i deljenje inputa na sate, minute i sekunde
    const cleanInput = input.replace(/[^0-9:]/g, '');
    const [hours, minutes, seconds] = cleanInput.split(':');
  
    // parsujemo input value u int
    let limitedHours = parseInt(hours, 10);
    let limitedMinutes = parseInt(minutes, 10);
    let limitedSeconds = parseInt(seconds, 10);
  
    // ako je bila koja vrednost NaN tj nisu validni brojevi, setujemo ih na 0
    if (isNaN(limitedHours)) {
      limitedHours = 0;
    }
  
    if (isNaN(limitedMinutes)) {
      limitedMinutes = 0;
    }
  
    if (isNaN(limitedSeconds)) {
      limitedSeconds = 0;
    }
   
    // limitranje sata na 23, minuta i sekundi na 59
    limitedHours = Math.min(limitedHours, 23);
    limitedMinutes = Math.min(limitedMinutes, 59);
    limitedSeconds = Math.min(limitedSeconds, 59);
  
    // formatiranje izlimitiranih vrednosti    
    return `${limitedHours.toString().padStart(2, '0')}:${limitedMinutes.toString().padStart(2, '0')}:${limitedSeconds.toString().padStart(2, '0')}`;
  };


  return (
    <>
      <div className="col-md">
        <label>Опис<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="description"
          value={scheduleData.description}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.description && (
          <div className="text-danger">{errors.description}</div>
        )}
      </div>
      <div className="col-md">
        <label>Категорија<span className="required-asterisk">*</span></label>
        <Select
          options={categoryList}
          name="categoryId"
          onChange={(event) =>
            handlePropertyChange(event)
          }
          value={categoryList?.filter(
            (x) => x.value === scheduleData.categoryId
          )}
        />
        {errors?.categoryId && <div className="text-danger">{errors.categoryId}</div>}
      </div>
      <div className="col-md">
        <label>Окидач<span className="required-asterisk">*</span></label>
        <Select
          options={triggersList}
          name="trigger"
          onChange={(event) =>
            handlePropertyChange(event)
          }
          value={triggersList?.filter((x) => x.value === scheduleData.trigger)}
          isClearable
        />
        {errors?.trigger && <div className="text-danger">{errors.trigger}</div>}
      </div>
      {scheduleData.trigger !== "MANUAL" && <div>
      <div className="col-md">
        <label>Слати:</label>
        <Select
          options={dayOfWeeksList}
          onChange={(event) =>
            setScheduleData({ ...scheduleData, dayOfWeekFlag: event.value })
          }
          value={dayOfWeeksList?.filter(
            (x) => x.value === scheduleData.dayOfWeekFlag
          )}
        />
      </div>
      <div className="col-md">
        <label>Не слати пре:</label>
        <InputMask
        mask="99:99:99"
        className="form-control form-group"
        value={scheduleData.notBeforeTime}
        onChange={(event) => handleChange(event, 'notBeforeTime')}
      />
      </div>
      <div className="col-md">
        <label>Не слати после:</label>
        <InputMask
        mask="99:99:99"
        className="form-control form-group"
        value={scheduleData.notAfterTime}
        onChange={(event) => handleChange(event, 'notAfterTime')}
      />
      </div>
      <div className="col-md">
      <input
        className="form-group"
        id="isPeriodic"
        type="checkbox"
        value={scheduleData.isPeriodic}
        checked={scheduleData.isPeriodic}
        onChange={(event) => {
          setScheduleData({ ...scheduleData, isPeriodic: event.target.checked });
        }}
      />
      <label htmlFor="isPeriodic">Периодично</label>
        <br/>
      {scheduleData.isPeriodic && <><label>Период:</label>
        <Select
          options={periodicFlags}
          onChange={(event) => handlePropertyChange(event)}
          value={periodicFlags?.filter(
            (x) => x.value === scheduleData.periodicFlag
          )}
        /></>}
        {errors?.periodicFlag && (
          <div className="text-danger">{errors.periodicFlag}</div>
        )}
      </div>
      <div className="col-md">
        <label>Шаблон наслова мејла:<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="subjectTemplate"
          value={scheduleData.subjectTemplate}
          onChange={(event) =>handlePropertyChange(event.target) }
        />
      </div>
      {errors?.subjectTemplate && <div className="text-danger">{errors.subjectTemplate}</div>}
      <label>Шаблон текста мејла<span className="required-asterisk">*</span></label>
      <textarea
        className="form-control form-group"
        type="text"
        name="bodyTemplate"
        value={scheduleData.bodyTemplate}
        onChange={(event) => {
          onChangeHtml(event);
          handlePropertyChange(event.target)
        }}
      />
      {errors?.bodyTemplate && <div className="text-danger">{errors.bodyTemplate}</div>}
      <div>{parse(String(hiddenBodyTemplate))}</div>
      </div>}
      <br/>
      <input
        className="form-group"
        id="activeSchedule"
        type="checkbox"
        value={scheduleData.active}
        checked={scheduleData.active}
        onChange={(event) => {
          setScheduleData({ ...scheduleData, active: event.target.checked });
        }}
      />
      <label htmlFor="activeSchedule">Активан</label>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={() => CreateSchedule()}
        >
          Сачувај
        </button>
      </div>
    </>
  );
};

export default CreateSchedule;
