import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import Table from "../../../common/table/Table.component";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import useFormContext from "../../hooks/useFormContext";
import SettingsClient from "../../api-services/settings/settings-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import AvailableTabClient from "../../api-services/available-tab/available-tab-client";
import { useTranslation } from "react-i18next";

function ActOtherData() {
  const {
    data,
    setData,
    handleChangeInput,
    handleChangeDate,
    handleChangeList,
  } = useFormContext();
  const { t } = useTranslation();
  
  const model = "lawActOtherDataModel";

  const settingsClient = new SettingsClient();
  const availableTabClient = new AvailableTabClient();

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "availableTabId",
      accessor: "availableTabId",
    },
    {
      Header: "Назив базе",
      accessor: "availableTabName",
    },
    {
      Header: "sort",
      accessor: "sort",
    },
  ];

  const [listOfAllAvailableTabs, setListOfAllAvailableTabs] = useState([]);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [listOfActiveTabs, setListOfActiveTabs] = useState([]);

  const [titleClassList, setTitleClassList] = useState([]);
  const [desctiptionClassList, setDescriptionClassList] = useState([]);
  const [superscriptClassList, setSuperscriptClassList] = useState([]);
  const [subscriptClassList, setSubscriptClassList] = useState([]);

  const languageList = [
    {
      value: "rs",
      label: "Српски",
      model: model,
    },
    {
      value: "eng",
      label: "Енглески",
      model: model,
    },
  ];

  useEffect(() => {
    getAllAvailableTabs();
    fillDescriptionList();
    fillTitleList();
    fillSubscriptList();
    fillSuperscriptList();
    if(data.lawActOtherDataModel.lawActTabs.length > 0)
      setListOfActiveTabs(data.lawActOtherDataModel.lawActTabs)
  }, []);

  useEffect(() => {
    setData({
      ...data,
      lawActOtherDataModel: {
        ...data.lawActOtherDataModel,
        lawActTabs: [...listOfActiveTabs],
      },
    });
  }, [listOfActiveTabs])

  const getAllAvailableTabs = async () => {
    let response = await availableTabClient.getAll();
    let handledResponse = HandlingResponse(response);
    handledResponse && setListOfAllAvailableTabs(handledResponse);
  };

  const fillDescriptionList = async () => {
    let response = await settingsClient.getByParameterKey(
      "ABSTRACT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssDescriptionClass",
        model: model,
      };
    });
    handledResponse && setDescriptionClassList(data);
  };

  const fillTitleList = async () => {
    let response = await settingsClient.getByParameterKey("TITLE_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssTitleClass",
        model: model,
      };
    });
    handledResponse && setTitleClassList(data);
  };

  const fillSubscriptList = async () => {
    let response = await settingsClient.getByParameterKey(
      "SUBSCRIPT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssSubscriptClass",
        model: model,
      };
    });
    handledResponse && setSubscriptClassList(data);
  };

  const fillSuperscriptList = async () => {
    let response = await settingsClient.getByParameterKey(
      "SUPERSCRIPT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssSuperscriptClass",
        model: model,
      };
    });
    handledResponse && setSuperscriptClassList(data);
  };

  const addAndRemoveTabs = () => {
    var mappedSelectedTabs = selectedTabs.map((item, id) => {
          return {
            availableTabId: item.id,
            availableTabName: item.name,
            sort: id,
          };
      });
      if(listOfActiveTabs.length !== 0) {
        var addedTabs = mappedSelectedTabs.filter(
          (el) =>!listOfActiveTabs.some((x) => x.availableTabId === el.availableTabId)
        );
        var removedTabs = mappedSelectedTabs.length > 0 && listOfActiveTabs.filter(
          (el) =>  !mappedSelectedTabs.some((x) => x.availableTabId === el.availableTabId)
        );
        if (removedTabs.length > 0) {
          var listAfterRemoving = listOfActiveTabs.filter((v) => {
            return !removedTabs.includes(v);
          });
          if(addedTabs.length === 0) setListOfActiveTabs(listAfterRemoving);
          else setListOfActiveTabs([...listAfterRemoving, ...addedTabs]);
        }
        else if(addedTabs.length > 0) setListOfActiveTabs([...listOfActiveTabs, ...addedTabs]);
      }
      else setListOfActiveTabs([...mappedSelectedTabs]);
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <label>Уредничка напомена</label>
          <textarea
            className="form-control"
            type="text"
            name="editorMark"
            data-model={model}
            id="editorMark"
            value={data.lawActOtherDataModel.editorMark}
            onChange={(e) => {
              handleChangeInput(e);
              data.lawActOtherDataModel.editorMark == "" &&
                handleChangeDate(null, "editorMarkDeadlineDate", model);
            }}
          />
        </div>
        <div className="col-3">
          <label>Датум уредничке напомене</label>
          <CustomDatePicker
            selected={
              data.lawActOtherDataModel.editorMarkDeadlineDate
                ? new Date(data.lawActOtherDataModel.editorMarkDeadlineDate)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "editorMarkDeadlineDate", model)
            }
            disabled={data.lawActOtherDataModel.editorMark ? false : true}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-5">
        <label>Додавање веза ка другим базама</label>
          <Select
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={listOfAllAvailableTabs}
            onChange={(e) => setSelectedTabs(e)}
            isMulti
            defaultValue={data.lawActOtherDataModel.lawActTabs.map((x) => {
              return { id: x.availableTabId, name: x.availableTabName };
            })}
            />
        </div>
        <div className="col-2" style={{paddingTop: "29px"}}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => addAndRemoveTabs(e)}
          >
          Генериши везе
        </button>
        </div>
        <div className="col-5">
          <label>Везе са другим базама</label>
          <br />
          {data.lawActOtherDataModel.lawActTabs && (
            <Table
              columns={columns}
              data={listOfActiveTabs}
              setData={setListOfActiveTabs}
              rememberFilter={false}
              hiddenColumn={["id", "availableTabId", "sort"]}
              serverSide={false}
              rowSortable={true}
            />
          )}
        </div>
      </div>
      <br />
      <div>
        <label>Кључне речи</label>
        <input
          className="form-control"
          id="keywords"
          name="keywords"
          data-model={model}
          type="text"
          value={data.lawActOtherDataModel.keywords}
          onChange={handleChangeInput}
          />
        <label>СЕО кључне речи</label>
        <input
          className="form-control"
          id="keywordsSeo"
          name="keywordsSeo"
          data-model={model}
          type="text"
          defaultValue={data.lawActOtherDataModel.keywordsSeo}
          onChange={handleChangeInput}
        />
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <label>Стил наднапомене</label>
          <Select
            options={superscriptClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSuperscriptClass", value: null, model: model})}
            value={superscriptClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssSuperscriptClass
            )}
            isClearable
          />
          <label>Стил наслова</label>
          <Select
            options={titleClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssTitleClass", value: null, model: model})}
            value={titleClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssTitleClass
            )}
            isClearable
          />
        </div>
        <div className="col-6">
          <label>Стил описа</label>
          <Select
            noOptionsMessage={() => t("noResult")}
            placeholder={"Изаберите..."}
            options={desctiptionClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssDescriptionClass", value: null, model: model})}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            value={desctiptionClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssDescriptionClass
            )}
            isClearable
          />
          <label>Стил поднапомене</label>
          <Select
            options={subscriptClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSubscriptClass", value: null, model: model})}
            value={subscriptClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssSubscriptClass
            )}
            isClearable
          />
        </div>
      </div>
      <div className="col-4">
        <label>Језик</label>
        <Select
          options={languageList}
          onChange={handleChangeList}
          value={languageList.filter(
            (x) => x.value === data.lawActOtherDataModel.languageId
          )}
        />
      </div>
    </>
  );
}

export default ActOtherData;
