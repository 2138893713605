import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateActType from "../../../components/create-act-type/CreateActType.component";
import { ActTypeModel } from "../../../models/Codebook";
import ActTypeClient from "../../../api-services/act-type/act-type-client";

const ActType = () => {
  const tableName = "REGULATIONS_ADVERTISEMENT_AND_ACT_TYPE";

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [rowData, setRowData] = useState(new ActTypeModel());
  const [allActType, setAllActType] = useState(
    []
  );

  const client = new ActTypeClient();

  const columns = [
    {
      Header: t("Id"),
      accessor: "id",
      disableFilters: false,
    },
    {
      Header: t("Description"),
      accessor: "description",
      Cell: ({ value }) => (
        <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
      ),
      disableFilters: false,
    },
    {
      Header: t("DefaultSort"),
      accessor: "defaultSort",
      Cell: ({ value }) => (
        <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
      ),
      disableFilters: false,
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const getAllActType = async () => {
    const response = await client.getAllActType();
    const handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error")
      setAllActType(handledResponse);
  };

  useEffect(() => {
    getAllActType();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    showModal();
    setTitle(t("CreatingAKindOfLegalAct"));
    setRowData(new ActTypeModel());
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    showModal();
    setTitle(t("EditingAKindOfLegalAct"));
    setRowData(row);
  };

  return (
    <>
      <div className="form-group">
        <h5 className="col-md-2">{t("KindsOfLegalActs")}</h5>
        <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
          {t("AddKindOfLegalAct")}
        </button>
      </div>

      <Table
        columns={columns}
        data={allActType}
        rememberFilter={true}
        tableName={tableName}
        serverSide={false}
      />

      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateActType
          row={rowData}
          isEdit={isEdit}
          handleCallback={() => {
            hideModal();
            getAllActType();
          }}
        />
      </SideModal>
    </>
  );
};

export default ActType;
