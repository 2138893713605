import React, { useEffect, useState } from "react";
import { CreateUserModal, UserSchema } from "../../models/UserModel";
import UserClient from "../../api-services/user/user-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Moment from "moment";
import { DatePickerWithCustomHeader } from "../../../common/custom-date-picker/CustomDatePicker.component";
import { useTranslation } from "react-i18next";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";

const CreateOrEditUser = ({ userId, edit, handleClose, handleAddingUser }) => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState(new CreateUserModal());
  const [disableUsername, setDisableUsername] = useState(false);
  const [errors, setErrors] = useState({});

  var client = new UserClient();

  useEffect(() => {
    edit && getUser();
  }, [edit]);

  const getUser = async () => {
    var response = await client.getUser(userId);
    var handledResponse = HandlingResponse(response);
    handledResponse.loginName && setDisableUsername(true)
    setUserInfo(handledResponse);
  };

  const createEditUser = async (e) => {
    e.preventDefault();
    const errorsResult = validateForm(userInfo, UserSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
    var response;
    if (edit) {
      response = await client.updateUser(userInfo);
    } else {
      response = await client.createUser(userInfo);
    }
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else {
      handleAddingUser(handledResponse);
      handleClose();
    }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeWithValidation = (e) => {
    const obj = { name: e.target.name, value: e.target.value };
    const result = handleSave(obj, userInfo, errors, UserSchema, t);
    setUserInfo(result.data);
    setErrors(result.errors);
  }

  const handleDatePickerChange = (date, name) => {
    setUserInfo((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  return (
    <form onSubmit={createEditUser}>
      <div className="row">
        <div className="col-6">
          <label>Име</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.firstName}
            name="firstName"
            onChange={handleInputChangeWithValidation}
          />
          {errors?.firstName && <div className="text-danger">{errors?.firstName}</div>}
        </div>
        <div className="col-6">
          <label>Презиме</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.lastName}
            name="lastName"
            onChange={handleInputChangeWithValidation}
          />
          {errors?.lastName && <div className="text-danger">{errors?.lastName}</div>}
        </div>
        <div className="col-6">
          <label>Корисничко име</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.loginName}
            name="loginName"
            onChange={handleInputChangeWithValidation}
            disabled={disableUsername}
          /> 
          {errors?.loginName && <div className="text-danger">{errors?.loginName}</div>}
        </div>
        <div className="col-6">
          <label>Шифра</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.password}
            name="password"
            onChange={handleInputChangeWithValidation}
          />
          {errors?.password && <div className="text-danger">{errors?.password}</div>}
        </div>
        <div className="col-6">
          <label>Имејл</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.email}
            name="email"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Компанија</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.company}
            name="company"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>ПИБ/ЈМБГ</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.pib}
            name="pib"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Адреса 1</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.address1}
            name="address1"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Адреса 2</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.address2}
            name="address2"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Град</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.city}
            name="city"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Поштански број</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.postalCode}
            name="postalCode"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Држава</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.state}
            name="state"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Телефон 1</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.phone1}
            name="phone1"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Телефон 2</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.phone2}
            name="phone2"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>FAX</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.fax}
            name="fax"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Мобилни телефон</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.mobile}
            name="mobile"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>www</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.www}
            name="www"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-6">
          <label>Сектор</label>
          <input
            className="form-control"
            type="text"
            value={userInfo?.sector}
            name="sector"
            onChange={handleInputChange}
          />
        </div>
        {edit && (
          <>
            <div className="col-6">
              <label>Датум првог пријављивања</label>
              <input
                className="form-control"
                type="text"
                value={
                  userInfo?.firstAccess
                    ? Moment(userInfo?.firstAccess).format("DD.MM.YYYY.")
                    : undefined
                }
                name="firstAccess"
                disabled
              />
            </div>
            <div className="col-6">
              <label>Датум последњег пријављивања</label>
              <input
                className="form-control"
                type="text"
                value={
                  userInfo?.lastAccess
                    ? Moment(userInfo?.lastAccess).format("DD.MM.YYYY.")
                    : undefined
                }
                name="lastAccess"
                disabled
              />
            </div>
          </>
        )}
        <div className="col-6">
          <label>Датум истицања претплате</label>
          <DatePickerWithCustomHeader
            selected={
              userInfo.orderAlarm ? new Date(userInfo.orderAlarm) : null
            }
            onChange={(orderAlarm) => {
              handleDatePickerChange(orderAlarm, "orderAlarm");
            }}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <label>Напомена за корисника</label>
            <textarea
              className="form-control"
              type="text"
              value={userInfo?.noteContacts}
              name="noteContacts"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <label>Напомена о поруџбини</label>
            <textarea
              className="form-control"
              type="text"
              value={userInfo?.noteOrders}
              name="noteOrders"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ marginTop: "10px", marginLeft: "5px" }}
        >
          {edit ? "Измени" : "Креирај"}
        </button>
      </div>
    </form>
  );
};

export default CreateOrEditUser;
