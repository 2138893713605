class PackagesExtensionModel {
  constructor(name = "", lastName = "", company = "", userName = "") {
    this.name = name;
    this.lastName = lastName;
    this.company = company;
    this.userName = userName;
  }
}

class AllRequestsModel {
  constructor(
    userName = "",
    company = "",
    pib = "",
    packageId = null,
    status = null,
    validFrom = null,
    validTo = null,
    requestedBy = "",
    requestedDate = null,
    processedBy = "",
    processingDate = null,
    requestType = null,
    name = "",
    lastName = "",
    comment = ""
  ) {
    this.userName = userName;
    this.company = company;
    this.pib = pib;
    this.packageId = packageId;
    this.status = status;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.requestedBy = requestedBy;
    this.requestedDate = requestedDate;
    this.processedBy = processedBy;
    this.processingDate = processingDate;
    this.requestType = requestType;
    this.name = name;
    this.lastName = lastName;
    this.comment = comment;
  }
}

class UnprocessedRequestsModel {
  constructor(
    userName = "",
    company = "",
    pib = "",
    packageId = "",
    requestType = "",
    validFrom = null,
    validTo = null,
    requestedBy = "",
    requestedDate = null,
    requestedExtendingDate = null,
    comment = ""
  ) {
    this.userName = userName;
    this.company = company;
    this.pib = pib;
    this.packageId = packageId;
    this.requestType = requestType;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.requestedBy = requestedBy;
    this.requestedDate = requestedDate;
    this.requestedExtendingDate = requestedExtendingDate;
    this.comment = comment;
  }
}

class ApprovedAccountsModel {
  constructor(
    customerNo = "",
    customerName = "",
    customerPIB = "",
    paymentDate = null,
    userName = "",
    password = "",
    validFrom = null,
    validTo = null
  ) {
    this.customerNo = customerNo;
    this.customerName = customerName;
    this.customerPIB = customerPIB;
    this.paymentDate = paymentDate;
    this.userName = userName;
    this.password = password;
    this.validFrom = validFrom;
    this.validTo = validTo;
  }
}

export { PackagesExtensionModel, AllRequestsModel, UnprocessedRequestsModel, ApprovedAccountsModel };
