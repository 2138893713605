import { env } from "../../../env";
import API from "../../../api";

var baseurl = env.REACT_APP_API_URL_DI;

class IndexSmallAds {
    async adsIndexingFuruna(data, isBackground) {
        try {
          var response = await API.post(baseurl + "/MO/IndexMO", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    
    }

    async adsIndexingNewFuruna(data, isBackground) {
        try {
          var response = await API.get(baseurl + "/MO/IndexLastMO", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    
    }

    async adsIndexing(data, isBackground) {
        try {
          var response = await API.post(baseurl + "/MO/IndexSmallAdds", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    
    }
}

export default IndexSmallAds;