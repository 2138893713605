import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PPP;

class ActClient {

  async getActsByParentId(parentId, isBackground) {
    try {
      var response = await api.get(baseurl + "/Act/GetActsByParentId/" + parentId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetGlobalActReferencesByAcId(actId, isBackground) {
    try {
      const response = await api.get(baseurl + "/Act/GetGlobalActReferencesByActId/" + actId, { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateGlobalActReferenceForAct(data, isBackground) {
    try {
      const response = await api.post(baseurl + "/Act/UpdateGlobalActReferenceForAct/", data, { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateAct(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Act/CreateAct", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteActById(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Act/DeleteActbyId/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async highlightAct(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/Act/HighlightAct/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async removeHighlightFromAct(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/Act/RemoveHighlightFromAct/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async activateAct(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/Act/ActivateAct/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deactivateAct(id, isBackground) {
    try {
      var response = await api.put(baseurl + "/Act/DeactivateAct/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async orderActs(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Act/OrderActs", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async indexActById(id, isBackground) {
    try {
      var response = await api.post(baseurl + "/Act/IndexActById/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexActById(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Act/DeleteIndexActById/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async massIndexing(isBackground) {
    try {
      var response = await api.post(baseurl + "/Act/MassIndexing", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }


  async getActiveAuthorities(isBackground) {
    try {
      var response = await api.get(baseurl + "/Authority/GetActiveAuthorities", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActiveAdvertisement(isBackground) {
    try {
      var response = await api.get(baseurl + "/AdvertisementAndActType/getActiveAdvertisementAndActTypes", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getByParameterKey(parameterKey, isBackground) {
    try {
      const params = {
        params: { parameterKey: parameterKey },
        isBackground: isBackground
      };
      var response = await api.get(baseurl + "/Settings/GetByParameterKey/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActByName(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/Act/GetActByName/",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async downloadFilesFromRepo(id, zipName) {
    try {
      var response = await api.get(baseurl + "/Act/DownloadFilesFromRepo/" + id, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.download = zipName;
          link.href = url;
          link.click();
        });
    }
    catch (err) {
      if (err.response.status === 404 && err.response.config.responseType === 'blob') {
        let responseObj = await err.response.data.text();
        return JSON.parse(responseObj);
      }
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

}

export default ActClient;