import Joi from "joi-browser";

class PreviewLawActModel {
    constructor(lawActBasicDataModel = new LawActBasicDataModel(), officialGazetteModel = new OfficialGazetteModel(), lawActReferenceModel = new LawActReferenceModel(),
        lawActOtherDataModel = new LawActOtherDataModel(), lawActChangesHistoryModel = new LawActChangesHistoryModel(), file = null, files = [], pictures = []) {
        this.lawActBasicDataModel = lawActBasicDataModel;
        this.officialGazetteModel = officialGazetteModel;
        this.lawActReferenceModel = lawActReferenceModel;
        this.lawActOtherDataModel = lawActOtherDataModel;
        this.lawActChangesHistoryModel = lawActChangesHistoryModel;
        this.file = file;
        this.files = files;
        this.pictures = pictures;
    }
}

class LawActBasicDataModel {
    constructor(id = 0, areaId = 0, categoryGuid = "", authorityGuid = "", superscript = "", lawActName = "", abstract = "", abstractSeo = "", subscript = "",
        detailedDefinition = "", acclamationDateTime = null, effectiveDateTime = null, applicationDateTime = null, intlContractStartDate = null,
        validFromDate = null, validToDate = null, versionValidFromDate = null, versionApplicationDate = null, gazeteSortOrder = 0, nameDateGazette = "",
        hasNoHtml = false, showIdCard = false, showStructureLink = false, showEditorMark = false, isOfficial = false, userGuid = "", userFullName = "",
        locked = false, indexStatus = 1, versionGuid = "") {

        this.id = id;
        this.areaId = areaId;
        this.categoryGuid = categoryGuid;
        this.authorityGuid = authorityGuid;
        this.superscript = superscript;
        this.lawActName = lawActName;
        this.abstract = abstract;
        this.abstractSeo = abstractSeo;
        this.subscript = subscript;
        this.detailedDefinition = detailedDefinition;
        this.acclamationDateTime = acclamationDateTime;
        this.effectiveDateTime = effectiveDateTime;
        this.applicationDateTime = applicationDateTime;
        this.intlContractStartDate = intlContractStartDate;
        this.validFromDate = validFromDate;
        this.validToDate = validToDate;
        this.versionValidFromDate = versionValidFromDate;
        this.versionApplicationDate = versionApplicationDate;
        this.gazeteSortOrder = gazeteSortOrder;
        this.nameDateGazette = nameDateGazette;
        this.hasNoHtml = hasNoHtml;
        this.showIdCard = showIdCard;
        this.showStructureLink = showStructureLink;
        this.showEditorMark = showEditorMark;
        this.isOfficial = isOfficial;
        this.userGuid = userGuid;
        this.userFullName = userFullName;
        this.locked = locked;
        this.indexStatus = indexStatus;
        this.versionGuid = versionGuid;
    }
}

class OfficialGazetteModel {
    constructor(gazetteLinks = Array(new GazetteLinkModel()), basicGlobalActRefferences = Array(new BasicGlobalActRefferenceModel())) {
        this.gazetteLinks = gazetteLinks;
        this.basicGlobalActRefferences = basicGlobalActRefferences;
    }
}

class GazetteLinkModel {
    constructor(id = 0, lawActId = 0, countryGuid = "", countryName = "", issueTypeGuid = "", issueTypeName = "", yearNumber = "", pdfDocumentUrl = "") {
        this.id = id;
        this.lawActId = lawActId;
        this.countryGuid = countryGuid;
        this.countryName = countryName;
        this.issueTypeGuid = issueTypeGuid;
        this.issueTypeName = issueTypeName;
        this.yearNumber = yearNumber;
        this.pdfDocumentUrl = pdfDocumentUrl;
    }
}

class BasicGlobalActRefferenceModel {
    constructor(id = 0, actGuid = "", actName = "", actReferenceTypeGuid = "", additionalInfo = "") {
        this.id = id;
        this.actGuid = actGuid;
        this.actName = actName;
        this.actReferenceTypeGuid = actReferenceTypeGuid;
        this.additionalInfo = additionalInfo;
    }
}
class LawActReferenceModel {
    constructor(predecessor = "", globalActRefferences = Array(new GlobalActRefferenceModel()), globalActReferencesByType = Array(new GlobalActRefferenceByTypeModel())) {
        this.predecessor = predecessor;
        this.globalActRefferences = globalActRefferences;
        this.globalActReferencesByType = globalActReferencesByType;
    }
}

class GlobalActRefferenceByTypeModel {
    constructor(actReferenceTypeGuid = "", actReferenceTypeName = "", globalActRefferences = Array(new GlobalActRefferenceModel())) {
        this.actReferenceTypeGuid = actReferenceTypeGuid;
        this.actReferenceTypeName = actReferenceTypeName;
        this.globalActRefferences = globalActRefferences;

    }
}

class GlobalActRefferenceModel {
    constructor(id = 0, actReferenceTypeGuid = "", actReferenceTypeName = "", packageName = null, refActGuid = "", fkRegAct = 0, actName = "", additionalInfo = "",
        isActive = false, externalUrlName = "", externalUrlValue = "") {

        this.id = id;
        this.actReferenceTypeGuid = actReferenceTypeGuid;
        this.actReferenceTypeName = actReferenceTypeName;
        this.packageName = packageName;
        this.refActGuid = refActGuid;
        this.fkRegAct = fkRegAct;
        this.actName = actName;
        this.additionalInfo = additionalInfo;
        this.isActive = isActive;
        this.externalUrlName = externalUrlName;
        this.externalUrlValue = externalUrlValue
    }
}

class LawActOtherDataModel {
    constructor(editorMark = "", editorMarkDeadlineDate = null, cssSuperscript = "", cssTitle = "", cssSubscript = "", cssDescription = "", cssSuperscriptClass = "",
        cssTitleClass = "", cssSubscriptClass = "", cssDescriptionClass = "", languageId = "", lawActTabs = Array(new LawActTabModel()), keywords = "", keywordsSeo = "") {

        this.editorMark = editorMark;
        this.editorMarkDeadlineDate = editorMarkDeadlineDate;
        this.cssSuperscript = cssSuperscript;
        this.cssTitle = cssTitle;
        this.cssSubscript = cssSubscript;
        this.cssDescription = cssDescription;
        this.cssSuperscriptClass = cssSuperscriptClass;
        this.cssTitleClass = cssTitleClass;
        this.cssSubscriptClass = cssSubscriptClass;
        this.cssDescriptionClass = cssDescriptionClass;
        this.languageId = languageId;
        this.lawActTabs = lawActTabs;
        this.keywords = keywords;
        this.keywordsSeo = keywordsSeo;
    }
}

class LawActTabModel {
    constructor(id = 0, availableTabId = 0, availableTabName = "", sort = 0, active = false) {
        this.id = id;
        this.availableTabId = availableTabId;
        this.availableTabName = availableTabName;
        this.sort = sort;
        this.active = active;
    }
}

class LawActChangesHistoryModel {
    constructor(actsChangesHistory = Array(new ActsChangesHistoryModel())) {
        this.actsChangesHistory = actsChangesHistory;
    }
}

class ActsChangesHistoryModel {
    constructor(username = "", editorMark = "", changedAt = null) {
        this.username = username;
        this.editorMark = editorMark;
        this.changedAt = changedAt;
    }
}
const schemaForBasicData = Joi.object().keys({
    lawActName: Joi.string().required()
})

export const schema = Joi.object({
    lawActBasicDataModel: schemaForBasicData
})

export const databaseList = [
    {
        value: "regClient",
        label: "Регистри",
        enumVal: 0,
    },
    {
        value: "spClient",
        label: "Судска пракса",
        enumVal: 1,
    },
    {
        value: "slglClient",
        label: "Службена гласила",
        enumVal: 2,
    },
    {
        value: "mmlClient",
        label: "Мишљења, модели, литература",
        enumVal: 3,
    },
];

export { PreviewLawActModel, LawActBasicDataModel, OfficialGazetteModel, LawActReferenceModel, LawActOtherDataModel, LawActChangesHistoryModel }