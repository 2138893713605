import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_SLGL;

class SettingsSLGLClient {

  async getAll(isBackground) {
    try {
      var response = await api.get(baseurl + "/Settings", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateSetting(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Settings/UpdateSetting", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateNotificationMailParameter(turnOn, isBackground) {
    try {
      var response = await api.put(baseurl + "/Settings/UpdateNotificationMailParameter?turnOn=" + turnOn, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateNotificationSMSParameter(turnOn, isBackground) {
    try {
      var response = await api.put(baseurl + "/Settings/UpdateNotificationSMSParameter?turnOn=" + turnOn, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetNotificationSettings(isBackground) {
    try {
      var response = await api.get(baseurl + "/Settings/GetNotificationSettings", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

}

export default SettingsSLGLClient;