import React, { useEffect, useState, useCallback } from "react";
import Publications from "../../../components/publications/publications-table/publications.component";

const ArchivedPublications = () => {
    const [isCurrent, setIsCurrent] = useState(false);
    const [isArchived, setIsArchived] = useState(true);
  
    return (
      <>
        <Publications isCurrent={isCurrent} isArchived={isArchived}></Publications>
      </>
    );
}
export default ArchivedPublications;