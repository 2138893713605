import React from 'react'
import Table from '../../../common/table/Table.component';
import { format } from 'date-fns';
import useUpdateLawAct from '../../hooks/useUpdateLawAct';

const TabHistoryChanges = () => {
  const { data } = useUpdateLawAct();
  const columns = [
    {
      Header: "Датум и време измене",
      accessor: "changedAt",
      Cell: ({ row }) => {
        let date = new Date(row.original.changedAt);
        return (
          <div>{format(date, "dd.MM.yyyy. HH:mm:ss")}</div>
        )
        
      },
    },
    {
      Header: "Корисник",
      accessor: "username",
    },
    {
      Header: "Уредничка напомена",
      accessor: "editorMark",
    },
  ]
  return (
    <div>
      <Table 
      columns={columns}
      data={data.lawActChangesHistoryModel.actsChangesHistory}
      rememberFilter={false}
      serverSide={false} />
    </div>
  )
}

export default TabHistoryChanges