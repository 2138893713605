import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class LegalSubmatterClient {
  async LegalSubmatter(isBackground) {
    try {
      var response = await api.get(baseurl + "/LegalSubmatter", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async CreateLegalSubmatter(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/LegalSubmatter", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateLegalSubmatter(id, data,isBackground) {
    try {
      var response = await api.put(baseurl + "/LegalSubmatter/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DeleteLegalSubmatter(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/LegalSubmatter?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetLegalSubmattersByLegalMatter(id, isBackground) {
    try {
      var response = await api.get(baseurl +
        "/LegalSubmatter/GetLegalSubmattersByLegalMatter/" + id, {isBackground: isBackground}
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default LegalSubmatterClient;
