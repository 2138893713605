import PublicRoute from "./publicRoute";
import UserService from "../../utils/authentification/keycloak";
import { getRolesForLocation } from "./getRolesForLocation";
import { Navigate, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  useNavigate();

  if (UserService.isLoggedIn()) {
    var roles = getRolesForLocation(window.location.pathname);

    if (UserService.hasRole(roles)) return children;
    else return <Navigate replace to={"/notAllowed"} />;
  } else {
    return <PublicRoute url={window.location.pathname} />;
  }
};

export default ProtectedRoute;
