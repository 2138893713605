import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../../common/table/Table.component";
import AuthorityClient from "../../../api-services/authority/authority-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import AuthorityModel from "../../../models/Authority";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAuthority from "../../../components/create-authority/CreateAuthority.component";

const Authority = () => {
    const { t } = useTranslation();
    const tableName = "PPP_AUTHORITY";

    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [rowData, setRowData] = useState(new AuthorityModel());
    const [allAuthorities, setAllAuthorities] = useState([]);
    const [title, setTitle] = useState("");

    const client = new AuthorityClient();

    const columns = [
        {
          Header: t("Id"),
          accessor: "id",
          disableFilters: false,
        },
        {
          Header: t("Name"),
          accessor: "authorityName",
          disableFilters: false,
        },
        {
          Header: t("Description"),
          accessor: "description",
          disableFilters: false,
        },
        {
          Header: t("Sort"),
          accessor: "sort",
          disableFilters: false,
        },
        {
          Header: t("Active"),
          accessor: "active",
          Cell: ({ row }) => {
            return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
          },
        },
        {
          accessor: "edit",
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              <button
                className="btn btn-primary table-btn"
                onClick={() => onEditClick(row.original)}
              >
                <FontAwesomeIcon icon={solid("pen-to-square")} />
              </button>
            );
          },
        },
    ];

    const showModal = () => setShow(true);
    const hideModal = () => {
        setShow(false);
        setRowData(new AuthorityModel());
    };

    const getAllAuthorities = async () => {
        const response = await client.getAllAuthorities();
        const handledResponse = HandlingResponse(response);
        if (handledResponse != "Error") setAllAuthorities(handledResponse);
    }

    useEffect(() => {
        getAllAuthorities();
    }, []);

    const onCreateClick = () => {
        setIsEdit(false);
        setRowData(new AuthorityModel());
        showModal();
        setTitle(t("CreatingAuthority"));
      };
    
      const onEditClick = (row) => {
        setIsEdit(true);
        setRowData(row);
        showModal();
        setTitle(t("EditingAuthority"));
      };

    return (
        <>
            <div className="form-control">
                <div className="form-group">
                    <h5 className="col-md-2">{t("Authorities")}</h5>
                    <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
                        {t("AddAuthority")}
                    </button>
                </div>

                <Table
                    columns={columns}
                    data={allAuthorities}
                    rememberFilter={true}
                    tableName={tableName}
                    serverSide={false}
                />

                <SideModal show={show} handleClose={hideModal} title={title}>
                    <CreateAuthority
                        row={rowData}
                        isEdit={isEdit}
                        handleCallback={() => {
                          hideModal();
                          getAllAuthorities();
                        }}
                    />
                </SideModal>
            </div>
        </>
    )
};

export default Authority;