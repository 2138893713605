import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_VASKE;

class CourtPracticeClient {
  async FilterCourtPracites(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/CourtPractice/FilterCourtPracites",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CourtPractice(isBackground) {
    try {
      var response = await api.get(baseurl + "/CourtPractice", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtPracticeById(id, isBackground) {
    try {
      var response = await api.get(
        baseurl + "/CourtPractice/GetCourtPracticeById?id=" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async CreateCourtPractice(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/CourtPractice/CreateCourtPractice",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateCourtPractice(data, isBackground) {
    try {
      var response = await api.put(
        baseurl + "/CourtPractice/UpdateCourtPractice",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CourtPracticeSubgroupClarification(id, isBackground) {
    try {
      var response = await api.get(
        baseurl +
          "/CourtPracticeSubgroup/GetCourtPracticeSubgroupsByType?isClarification=" +
          id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CourtPracticeSubgroupSentence(id, isBackground) {
    try {
      var response = await api.get(
        baseurl +
          "/CourtPracticeSubgroup/GetCourtPracticeSubgroupsByType?isSentence=" +
          id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CourtPracticeSubgroupLegalStance(id, isBackground) {
    try {
      var response = await api.get(
        baseurl +
          "/CourtPracticeSubgroup/GetCourtPracticeSubgroupsByType?isLegalStance=" +
          id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DeleteCourtPractice(id, isBackground) {
    try {
      var response = await api.delete(
        baseurl + "/CourtPractice/DeleteCourtPractice/" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CancelUpdateCourtPractice(id, isBackground) {
    try {
      var response = await api.put(
        baseurl + "/CourtPractice/CancelUpdateCourtPractice/" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFile(guid, name, isBackground) {
    try {
      var response = await api
        .get(baseurl + "/CourtPractice/DownloadFile/" + guid, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = name;
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFileById(id, name, isBackground) {
    try {
      var response = await api
        .get(baseurl + "/CourtPractice/DownloadFileByCourtPracticeId/" + id, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = name;
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFilesFromRepo(dataId, fileName, isBackground) {
    try {
      var response = await api
        .get(baseurl + "/CourtPractice/DownloadFilesFromRepo/" + dataId, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: "application/zip" });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          if(fileName.includes(".html"))
            link.download = fileName.replace(".html", ".zip");
          else if(fileName.includes(".htm"))
            link.download = fileName.replace(".htm", ".zip");
          link.click();
        });
    } catch (err) {
      if (err.response.status === 404 && err.response.config.responseType === 'blob') {
        let responseObj = await err.response.data.text();
        return JSON.parse(responseObj);
      }
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getCourtPracticeByName(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/CourtPractice/GetCourtPracticeByName",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async massIndexing(isBackground) {
    try {
      var response = await api.post(baseurl + "/CourtPractice/MassIndexing", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async indexCourtPractice(id, isBackground) {
    try {
      var response = await api.post(baseurl + "/CourtPractice/IndexCourtPractice?id=" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexCourtPractice(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/CourtPractice/DeleteIndexCourtPractice?id=" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterCourtPracticesByInfo(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/CourtPractice/FilterCourtPracticesByInfo",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtPracticeHtml(id, isBackground){
    try{
        var response = await api.get(baseurl + "/CourtPractice/GetCourtPracticeHtml?id=" + id, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
  if (err.response.status === 400) return err.response.data;
  else return err.response;
}
}

async UpdateCourtPracticeFile(data, isBackground) {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        var response = await api.put(baseurl + "/CourtPractice/UpdateCourtPracticeFile", data, {
            headers: headers,
            isBackground: isBackground
        });
        return response.data;
    }
    catch (err) {
  if (err.response.status === 400) return err.response.data;
  else return err.response;
}
}
}

export default CourtPracticeClient;
