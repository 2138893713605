import { env } from "../../../env";
import API from "../../../api";

var baseurl = env.REACT_APP_API_URL_DI;

class SmallAddsClient {

    async FilterMO (data, isBackground){
        try{
            var response = await API.post(baseurl + "/MO/FilterMO", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetDocumentKinds(isBackground) {
        try{
            var response = await API.get(baseurl + "/MO/DocumentKinds", {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetDocumentSubkinds(kindId, isBackground) {
        try{
            var response = await API.get(baseurl + "/MO/DocumentSubkinds?kindId=" + kindId, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async PreviewMO(id, isBackground) {
        try{
            var response = await API.get(baseurl + "/MO/PreviewMO/" + id, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GenerateConfirmationDocument(id, isBackground){
        try{
            var response = await API.get(baseurl + "/MO/GenerateConfirmationDocument/" + id, { responseType: 'blob' }
            ).then(({data}) => {
               const blob = new Blob([data]);
               const link = document.createElement('a');
               const url = URL.createObjectURL(blob);
               link.href = url;
               link.download = "Потврда-o-објављеном-огласу.docx";
               link.click();
             });
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async AggregateByYear(isBackground) {
        try{
            var response = await API.get(baseurl + "/MO/AggregateByYear", {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterByYearAndAggregateByNumber(year, isBackground) {
        try{
            var response = await API.get(baseurl + "/MO/FilterByYearAndAggregateByNumber/" + year, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterByYearAndNumberAggregateByDocumentKind(data, isBackground) {
        try{
            var response = await API.post(baseurl + "/MO/FilterByYearAndNumberAggregateByDocumentKind", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    
}

export default SmallAddsClient;