import React, { useState, useRef, useEffect } from "react";
import Authority from "../../components/document-authority/documentAuthority.component";
import DocumentType from "../../components/document/documentType.component";
import PrincipalAndRelatedActs from "../../components/principal-and-related-acts/principalAndRelatedActs.component";
import PublishingMethod from "../../components/publishing-method/publishingMethod.component";
import GlobalActReferences from "../../components/global-act-references/globalActReferences.component";
import DataClient from "../../api-services/data/data-client";
import { CreateDataModel, dataSchema } from "../../models/CreateData";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DeleteButton,
  DownloadButton,
  NewFileBtn,
} from "../../../common/multiple-files/multipleFiles.style";
import UserService from "../../../utils/authentification/keycloak";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import { formatDateForUpdate } from "../../../common/custom-date-picker/CustomDatePicker.component";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import Joi from "joi-browser";
import DataAttachmentClient from "../../api-services/data-attachment/data-attachment-client";
import {
  validateParentheses,
  validateQuotationMarks,
} from "../../../utils/common-functions/CommonFunctions";

const Document = () => {
  var client = new DataClient();
  var clientAttachment = new DataAttachmentClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [documentId, setDocumentId] = useSearchParams();
  var docId = documentId.get("documentId");

  //podaci pribavljeni na Index stranici se cuvaju u state
  const { state } = useLocation();

  const [dataModel, setDataModel] = useState(new CreateDataModel());
  const [userGuid, setUserGuid] = useState("");
  const [authorities, setAuthorities] = useState([]);
  const [principalActs, setPrincipalActs] = useState([]);
  const [relatedActs, setRelatedActs] = useState([]);
  const [lawActName, setLawActName] = useState("");
  const [globalActReferences, setGlobalActReferences] = useState([]);
  const [dataSources, setDataSources] = useState([]);

  const [htmlFile, setHtmlFile] = useState(null);

  const htmlInputRef = useRef(null);

  const [pdfFile, setPdfFile] = useState(null);
  const pdfInputRef = useRef(null);

  const [wordFile, setWordFile] = useState();
  const wordInputRef = useRef(null);

  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const [dataForSubmit, setDataForSubmit] = useState(null);

  const [primaryPublicationExist, setPrimaryPublicationExist] = useState(false);
  const [errors, setErrors] = useState({});

  const [validationForRelatedActs, setValidationForRelatedActs] = useState("");

  useEffect(() => {
    GetDataForUpdate();
  }, []);

  useEffect(() => {
    GetDataForUpdate();
    setErrors({});
  }, [docId]);

  useEffect(() => {
    setDataForSubmit({
      ...dataModel,
      userGuid: userGuid,
      authorities: [...authorities],
      principalActs: [...principalActs],
      relatedActs: [...relatedActs],
      globalActReferences: [...globalActReferences],
      dataSources: [...dataSources],
    });
  }, [
    dataModel,
    userGuid,
    authorities,
    principalActs,
    relatedActs,
    globalActReferences,
    dataSources,
  ]);

  useEffect(() => {
    var actNames = lawActName.split("\n");
    var data = actNames.map((el) => {
      return { regActName: el };
    });
    setRelatedActs(data);
  }, [lawActName]);

  useEffect(() => {
    if (htmlFile) setPdfFile(undefined);
    const obj = { name: "htmlFile", value: htmlFile };
    const result = handleSave(obj, htmlFile, errors, dataSchema, t);
    htmlFile && setErrors({ errors: result.errors, pdfFile: "" });
  }, [htmlFile]);

  useEffect(() => {
    const obj = { name: "pdfFile", value: pdfFile };
    const result = handleSave(obj, pdfFile, errors, dataSchema, t);
    pdfFile && setErrors(result.errors);
  }, [pdfFile]);

  const createDocument = async (event, logout = true) => {
    event.preventDefault();
    var response, handledResponse;

    if (htmlFile) dataSchema.pdfFile = Joi.optional();
    else dataSchema.pdfFile = Joi.required();

    if (pdfFile) dataSchema.htmlFile = Joi.optional();
    else dataSchema.htmlFile = Joi.required();

    if (!primaryPublicationExist) {
      dataSchema.primaryRequest = Joi.required();
    } else if (dataSources.length != 0 && primaryPublicationExist) {
      dataSchema.primaryRequest = Joi.optional();
    }

    const errorsResult = validateForm(
      {
        ...dataForSubmit,
        primaryPublicationExist: primaryPublicationExist,
        htmlFile: htmlFile,
        pdfFile: pdfFile,
      },
      dataSchema,
      t
    );
    setErrors(errorsResult);
    if (!errorsResult && checkCharactersValidation(lawActName)) {
      var formData = new FormData();

      dataForSubmit.principalActs.forEach((x, i) => {
        x.sort = i;
      });

      serializeObjectIntoFormData(formData, dataForSubmit);

      htmlFile?.guid
        ? formData.append("htmlFile.guid", htmlFile?.guid)
        : formData.append("htmlFile.file", htmlFile);

      pdfFile?.guid
        ? formData.append("pdfFile.guid", pdfFile?.guid)
        : formData.append("pdfFile.file", pdfFile);

      wordFile?.guid
        ? formData.append("wordFile.guid", wordFile?.guid)
        : formData.append("wordFile.file", wordFile);

      for (let i = 0; i < images.length; i++) {
        if (images[i].guid)
          formData.append("images[" + i + "].guid", images[i].guid);
        else formData.append("images[" + i + "].file", images[i]);

        formData.append("images[" + i + "].name", images[i].name);
      }
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].guid)
          formData.append("attachments[" + i + "].guid", attachments[i].guid);
        else formData.append("attachments[" + i + "].file", attachments[i]);
        formData.append("attachments[" + i + "].name", attachments[i].name);
      }

      if (docId) {
        response = await client.updateData(formData, logout);
        if (response !== "Error") handledResponse = HandlingResponse(response);
      } else {
        response = await client.createData(formData);
        if (response !== "Error") handledResponse = HandlingResponse(response);
      }
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      } else if (handledResponse !== "Error" && logout) navigate("/mml");
    }
    if (errorsResult || handledResponse === "Error") return true;
    else return false;
  };

  const GetDataForUpdate = async () => {
    var response, handeledResponse;
    if (docId) {
      response = await client.GetDataForUpdateById(docId);
      handeledResponse = HandlingResponse(response);

      if (handeledResponse != "Error") {
        setDataModel({
          id: handeledResponse.id,
          fileName: handeledResponse.fileName,
          typeId: handeledResponse.typeId,
          typesIdsWithParentIds: handeledResponse.typesIdsWithParentIds,
          enactmentDate: formatDateForUpdate(handeledResponse.enactmentDate),
          showEnactmentDate: handeledResponse.showEnactmentDate,
          actLabelNumber: handeledResponse.actLabelNumber,
          archived: handeledResponse.archived,
          highlighted: handeledResponse.highlighted,
          year: handeledResponse.year,
          actCloserDesignation: handeledResponse.actCloserDesignation,
          editorNote: handeledResponse.editorNote,
          published: handeledResponse.published,
          locked: handeledResponse.locked,
          userFullName: handeledResponse.userFullName,
        });
        setUserGuid(handeledResponse.userGuid);
        setAuthorities(handeledResponse.authorities);
        setPrincipalActs(handeledResponse.principalActs);
        setRelatedActs(handeledResponse.relatedActs);
        setGlobalActReferences(handeledResponse.globalActReferences);
        setDataSources(handeledResponse.dataSources);
        setHtmlFile(handeledResponse.htmlFile);
        setPdfFile(handeledResponse.pdfFile);
        setAttachments(handeledResponse.attachments);
        setWordFile(handeledResponse.wordFile);
        setImages(handeledResponse.images);

        let result = handeledResponse.relatedActs
          .map((el) => {
            return el.regActName;
          })
          .join("\n");
        setLawActName(result);
      }
    } else {
      setDataModel(new CreateDataModel());
      setUserGuid(UserService.getUserGuid());
      setHtmlFile();
      setWordFile();
      setPdfFile();
      setImages([]);
      setAttachments([]);
      setAuthorities([]);
      setPrincipalActs([]);
      setRelatedActs([]);
      setGlobalActReferences([]);
      setDataSources([]);
    }
  };

  const onPreview = async (event) => {
    let logout = false;
    var errorExist = await createDocument(event, logout);
    if (!errorExist) {
      GetDataForUpdate();
      window.open(
        window.location.origin +
          "/mml/document/previewDocument?documentId=" +
          docId,
        "_blank"
      );
    }
  };

  const onCancel = async () => {
    if (docId) {
      var response = await client.cancelUpdate(docId);
      HandlingResponse(response);
    }
    setErrors({});
    navigate("/mml");
  };

  const onDeleteClick = async () => {
    var response = await client.deleteData(docId);
    HandlingResponse(response);
    navigate("/mml");
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, dataModel, errors, dataSchema, t);
    setDataModel(result.data);
    setErrors(result.errors);
  };

  const handleUploadPdfClick = () => {
    pdfInputRef.current?.click();
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  const handleUploadWordClick = () => {
    wordInputRef.current.value = null;
    wordInputRef.current?.click();
  };

  const removeWordElement = () => {
    setWordFile(null);
    wordInputRef.current.value = null;
  };

  const removePdfFile = () => {
    setPdfFile(undefined);
    pdfInputRef.current.value = null;
  };

  const removeHtmlFile = () => {
    setHtmlFile(undefined);
    htmlInputRef.current.value = null;
  };

  const downloadMainFile = async () => {
    var response = await client.DownloadMainFile(pdfFile.guid, pdfFile.name);
    HandlingResponse(response);
  };

  const downloadFilesFromRepo = async () => {
    var fileName = dataModel.actCloserDesignation
      ? dataModel.actCloserDesignation
      : htmlFile.name.split(".").slice(0, -1).join(".");
    fileName = fileName.length > 30 ? fileName.substring(0, 30) : fileName;
    var response = await client.DownloadFilesFromRepo(docId, fileName);
    HandlingResponse(response);
  };

  const downloadWordFile = async () => {
    await client.DownloadWordFile(wordFile.guid, wordFile.name);
  };

  const getDataHtml = async () => {
    var response = await client.GetDataHtml(dataModel.id);
    return response;
  };

  const GetAttachmentByGuid = async (e) => {
    e?.preventDefault();
    await clientAttachment
      .GetAttachmentByGuid(pdfFile.guid)
      .then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      });
  };
  const handlePublicationsPrimaryChange = (primary) => {
    const obj = { name: "primaryRequest", value: primary };
    const result = handleSave(
      obj,
      primaryPublicationExist,
      errors,
      dataSchema,
      t
    );
    setPrimaryPublicationExist(primary);
    setErrors(result.errors);
  };

  const checkCharactersValidation = (sentence) => {
    const parentheses = validateParentheses(sentence);
    const questionMarks = validateQuotationMarks(sentence);

    if (!parentheses && !questionMarks) {
      setValidationForRelatedActs(t("parenthesesAndQuestionMarksMessage"));
      return false;
    } else if (!parentheses) {
      setValidationForRelatedActs(t("parenthesesMessage"));
      return false;
    } else if (!questionMarks) {
      setValidationForRelatedActs(t("questionMarksMessage"));
      return false;
    } else {
      setValidationForRelatedActs("");
      return true;
    }
  };

  return (
    <>
      <form onSubmit={createDocument} id="createDocumentForm">
        <div className="container clearfix">
          {dataModel.locked && userGuid !== UserService.getUserGuid() && (
            <label style={{ color: "red", marginBottom: "15px" }}>
              {`Корисник ${dataModel.userFullName} уређује овај документ`}
            </label>
          )}
          <DocumentType
            formData={dataModel}
            setFormData={setDataModel}
            errors={errors}
            setErrors={setErrors}
          />
          <br />
          <Authority
            authoritiesData={authorities}
            setAuthoritiesData={setAuthorities}
            errors={errors}
            setErrors={setErrors}
          />

          <label>{t("ActCloserDesignation")}</label>
          <textarea
            className="form-control"
            type="text"
            value={dataModel.actCloserDesignation}
            onChange={(event) => {
              setDataModel({
                ...dataModel,
                actCloserDesignation: event.target.value,
              });
            }}
          />
          <PrincipalAndRelatedActs
            principalActsData={principalActs}
            setPrincipalActsData={setPrincipalActs}
            lawActName={lawActName}
            setLawActName={setLawActName}
            validationForRelatedActs={validationForRelatedActs}
          />
          <br />
          <GlobalActReferences
            globalActReferencesData={globalActReferences}
            setGlobalActReferencesData={setGlobalActReferences}
          />
          <br />
          <PublishingMethod
            dataSources={dataSources}
            setDataSources={setDataSources}
            primaryPublicationExist={primaryPublicationExist}
            setPrimaryPublicationExist={setPrimaryPublicationExist}
            handlePublicationsPrimaryChange={handlePublicationsPrimaryChange}
          />
          {errors?.primaryRequest && (
            <div className="text-danger">
              Мора да постоји једна примарна објава
            </div>
          )}
          <label>{t("EditorNote")}</label>
          <textarea
            type="text"
            className="form-control"
            name="editorNote"
            value={dataModel.editorNote}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.editorNote && (
            <div className="text-danger">{errors.editorNote}</div>
          )}
          <br />
          <div className="row">
            <div className="col-2">
              <input
                id="publishedCB"
                type="checkbox"
                name="published"
                value={dataModel.published || ""}
                checked={dataModel.published || ""}
                onChange={(event) =>
                  setDataModel({
                    ...dataModel,
                    published: event.target.checked,
                  })
                }
              />
              <label htmlFor="publishedCB">{t("PublishOnPortal")}</label>
            </div>
          </div>
          <div className="row">
            {/* dodavanje pdf-a */}
            <div className="col-8">
              {!htmlFile &&
                (pdfFile ? (
                  <div>
                    <label>ПДФ фајл&nbsp;</label>
                    {pdfFile.guid ? (
                      <a href="#" target="_blank" onClick={GetAttachmentByGuid}>
                        {pdfFile.name}
                      </a>
                    ) : (
                      <span>{pdfFile.name}</span>
                    )}
                    {pdfFile.guid ? (
                      <DownloadButton
                        title="Преузми фајл"
                        type="button"
                        onClick={() => downloadMainFile()}
                      >
                        <FontAwesomeIcon icon={solid("download")} />
                      </DownloadButton>
                    ) : (
                      <></>
                    )}
                    <ButtonWithComfirmation
                      onClick={removePdfFile}
                      handleYesText={"Обриши фајл"}
                      contentText={
                        "Уколико сачувате измене, фајл ће бити трајно обрисан"
                      }
                      className="deleteButton"
                    >
                      <FontAwesomeIcon icon={solid("times")} />
                    </ButtonWithComfirmation>
                  </div>
                ) : (
                  <>
                    <NewFileBtn onClick={handleUploadPdfClick} type="button">
                      <FontAwesomeIcon icon={solid("plus")} />
                      Додај ПДФ фајл
                    </NewFileBtn>
                    или
                  </>
                ))}
              <input
                type="file"
                ref={pdfInputRef}
                onChange={(event) => {
                  setPdfFile(event.target.files[0]);
                  setHtmlFile(undefined);
                }}
                hidden
                accept=".pdf"
                name="pdfFile"
              />

              {/* dodavanje html-a */}

              {!pdfFile && !htmlFile && (
                <>
                  <NewFileBtn onClick={handleUploadHtmlClick} type="button">
                    додај ХТМЛ фајл
                  </NewFileBtn>
                  или
                </>
              )}
              <input
                type="file"
                ref={htmlInputRef}
                onChange={(event) => {
                  setHtmlFile(event.target.files[0]);
                  setPdfFile(undefined);
                }}
                hidden
                accept=".html, .htm"
                name="htmlFile"
              />
              {!pdfFile && (
                <SubmitFile
                  mainFile={htmlFile}
                  setMainFile={setHtmlFile}
                  photoFiles={images}
                  setPhotoFiles={setImages}
                  attachmentFiles={attachments}
                  setAttachmentFiles={setAttachments}
                  isEdit={
                    docId &&
                    htmlFile?.guid &&
                    ((state?.data.locked &&
                      userGuid === UserService.getUserGuid()) ||
                      !state?.data.locked)
                  }
                  downloadFilesFromRepo={downloadFilesFromRepo}
                  getHtml={getDataHtml}
                  id={dataModel.id}
                  pathToNavigate={
                    window.location.pathname + window.location.search
                  }
                ></SubmitFile>
              )}
            </div>

            {/* dodavanje word-a */}
            <div className="col-4">
              {wordFile ? (
                <div>
                  <label>Word документ&nbsp;</label>
                  {wordFile?.name}
                  {wordFile?.guid ? (
                    <DownloadButton
                      title="Преузми фајл"
                      type="button"
                      onClick={() => downloadWordFile()}
                    >
                      <FontAwesomeIcon icon={solid("download")} />
                    </DownloadButton>
                  ) : (
                    <></>
                  )}
                  <DeleteButton
                    title="Обриши документ"
                    type="button"
                    onClick={() => removeWordElement()}
                  >
                    <FontAwesomeIcon icon={solid("times")} />
                  </DeleteButton>
                </div>
              ) : (
                <NewFileBtn onClick={handleUploadWordClick} type="button">
                  <FontAwesomeIcon icon={solid("plus")} />
                  Додај Word документ
                </NewFileBtn>
              )}
              <input
                type="file"
                ref={wordInputRef}
                onChange={(event) => setWordFile(event.target.files[0])}
                hidden
                accept=".doc, .docx"
                name="word"
              />
            </div>
          </div>
          {(errors?.htmlFile || errors?.pdfFile) && (
            <div className="text-danger">
              {errors?.htmlFile || errors?.pdfFile}
            </div>
          )}
          <br />
          {docId &&
            !state?.data.published &&
            ((state?.data.locked && userGuid === UserService.getUserGuid()) ||
              !state?.data.locked) && (
              <ButtonWithComfirmation
                className="btn btn-danger"
                onClick={() => onDeleteClick()}
                contentText={"Документ ће бити трајно обрисан."}
                handleYesText={"Обриши"}
              >
                Обриши документ
              </ButtonWithComfirmation>
            )}
          {userGuid === UserService.getUserGuid() && (
            <>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginLeft: "10px" }}
                onClick={onCancel}
              >
                Одустани
              </button>
              {docId ? (
                <span className="info-about-cancel-span">
                  <FontAwesomeIcon icon={solid("circle-info")} /> Уколико не
                  кликнете на дугме "Одустани" документ ће остати закључан и
                  други корисници неће моћи да врше измену над њим
                </span>
              ) : (
                <span className="info-about-cancel-span">
                  <FontAwesomeIcon icon={solid("circle-info")} /> Документ неће
                  бити сачуван док не кликнете на дугме "Сачувај"
                </span>
              )}
              <button className="pull-right btn btn-primary" type="submit">
                {t("Save")}
              </button>
            </>
          )}
          {docId && (
            <button
              className="pull-right btn btn-primary"
              style={{ marginRight: "10px" }}
              type="button"
              onClick={onPreview}
            >
              Сними и прикажи
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default Document;
