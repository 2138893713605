import Joi  from "joi-browser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { handleSave, serverValidation, validateForm } from "../../../../common/validation/validation";
import CourtKindClient from "../../../api-services/coders/court-types/court-types-client";
import Moment from "moment";

const CreateCourtType = ({ row, handleCallback, isCreate }) => {
  var { t } = useTranslation();

  const [courtKind, setCourtKind] = useState({
    id: 0,
    name: "",
    weight: 0,
    isActive: true,
    userGuid: "",
    lastModifyDate: null,
    userFullName: ""
  })
  
  const [isCreateForm, setIsCreateForm] = useState("");
  const [errors, setErrors] = useState({});

  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required()
  };

  useEffect(() => {
    setCourtKind(row);
    
    setIsCreateForm(isCreate);
    setErrors({});
  }, [row, isCreate]);

  const client = new CourtKindClient();

  const CreateCourtKind = async () => {
    const data = {
      id: 0,
      name: courtKind.name,
      weight: courtKind.weight,
      isActive: courtKind.isActive,
      lastModifyDate: new Date(),
    };
    const result = validateForm(data, schema, t);
    var response = await client.CreateCourtKind(data);
    serverValidateAction(response);
    setErrors(result);
  };

  const UpdateCourtKind = async () => {
    const data = {
      id: courtKind.id,
      name: courtKind.name,
      weight: courtKind.weight,
      isActive: courtKind.isActive,
      lastModifyDate: new Date(),
    };
    const result = validateForm(data, schema, t);
    var response = await client.UpdateCourtKind(courtKind.id, data);
    serverValidateAction(response);
    setErrors(result);
  };

  const serverValidateAction = (response) => {
    var handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, courtKind, errors, schema, t);
    setCourtKind(result.data);
    setErrors(result.errors);
  }

  return (
    <>
      <form>
        <div className="form-group">
          <label>Врста суда<span className="required-asterisk">*</span></label>
          <input
          name="name"
            className="form-control"
            value={courtKind.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Тежина</label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={courtKind.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
          <br/>
          <input
                id="activeCourtKindCB"
                    type="checkbox"
                    value={courtKind.isActive}
                    checked={courtKind.isActive}
                    onChange={(event) => {
                        setCourtKind({ ...courtKind, isActive: event.target.checked });
                    }}
          />
          <label htmlFor="activeCourtKindCB">{t("Active")}</label>
        </div>
        {!isCreateForm ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{courtKind.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{courtKind.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>{Moment(courtKind.lastModifyDate).format("DD.MM.YYYY.")}</div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="text-right">
          {isCreateForm ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateCourtKind()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateCourtKind()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateCourtType;
