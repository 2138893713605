import React, { useEffect, useState } from "react";
import LawActClient from "../../api-services/law-act/law-act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import AreaClient from "../../api-services/area/area-client";

const LockedActs = () => {
  const tableNameActs = "REG_LOCKED_ACTS";
  const tableNameAreas = "REG_LOCKED_AREAS";

  const actColumns = [
    {
      Header: "lawActId",
      accessor: "lawActId",
    },
    {
      Header: "Област",
      accessor: "areaName",
      disableFilters: false,
    },
    {
      Header: "Назив акта",
      accessor: "lawActName",
      disableFilters: false,
    },
    {
      Header: "Корисник",
      accessor: "userFullName",
      disableFilters: false,
    },
    {
      accessor: "unlock",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <RenderOnRole roles={["REG_Admin"]}>
            <ButtonWithComfirmation
              title="Откључај акт"
              className="btn btn-primary table-btn"
              onClick={() => unlockLawAct(row.original.lawActId)}
              contentText="Да ли сте сигурни да желите да откључате овај акт?"
              handleYesText="Откључај акт"
            >
              <FontAwesomeIcon icon={solid("unlock")} />
            </ButtonWithComfirmation>
          </RenderOnRole>
        );
      },
    },
  ];

  const areaColumns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Област",
      accessor: "areaName",
      disableFilters: false,
    },
    {
      Header: "Корисник",
      accessor: "userFullName",
      disableFilters: false,
    },
    {
      accessor: "unlock",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <RenderOnRole roles={["REG_Admin"]}>
            <ButtonWithComfirmation
              title="Откључај област"
              className="btn btn-primary table-btn"
              onClick={() => unlockArea(row.original.id)}
              contentText="Да ли сте сигурни да желите да откључате ову област?"
              handleYesText="Откључај област"
            >
              <FontAwesomeIcon icon={solid("unlock")} />
            </ButtonWithComfirmation>
          </RenderOnRole>
        );
      },
    },
  ];
  const [lockedActs, setLockedActs] = useState([]);
  const [lockedAreas, setLockedAreas] = useState([]);

  const actClient = new LawActClient();
  const areaClient = new AreaClient();

  useEffect(() => {
    setTimeout(() => {
      getAllLockedLawActs();
      getAllLockedAreas();
  }, 50);
  }, []);

  const getAllLockedLawActs = async () => {
    let response = await actClient.getAllLockedLawActs();
    let handledResponse = HandlingResponse(response);
    handledResponse !== "Error" && setLockedActs(handledResponse);
  };

  const unlockLawAct = async (lawActId) => {
    let response = await actClient.unlockLawAct(lawActId);
    let handledResponse = HandlingResponse(response);
    if(handledResponse && handledResponse !== 'Error') getAllLockedLawActs();
  };

  const getAllLockedAreas = async () => {
    let response = await areaClient.getAllLockAreas();
    let handledResponse = HandlingResponse(response);
    handledResponse && handledResponse !== "Error" && setLockedAreas(handledResponse);
  };

  const unlockArea = async (id) => {
    let response = await areaClient.unlockArea(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse && handledResponse !== 'Error') getAllLockedAreas();
  };

  return (
    <div>
      <h5>Закључани акти</h5>
      <Table
        columns={actColumns}
        data={lockedActs}
        rememberFilter={true}
        tableName={tableNameActs}
        hiddenColumn={["lawActId"]}
        serverSide={false}
      />
      <br />
      <h5>Закључане области</h5>
      <Table
        columns={areaColumns}
        data={lockedAreas}
        rememberFilter={true}
        tableName={tableNameAreas}
        hiddenColumn={["id"]}
        serverSide={false}
      />
    </div>
  );
};

export default LockedActs;
