
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";


const requestTypes = [
  {
    value: 0,
    label: "Додавање",
  },
  {
    value: 1,
    label: "Укидање",
  },
];
const statusTypes = [
  {
    value: 0,
    label: "Необрађен",
  },
  {
    value: 1,
    label: "Одобрен",
  },
  {
    value: 2,
    label: "Обрисан",
  },
];

const SearchRequests = ({ filterData, setFilterData, handleCallback, resetFilters }) => {
  const [allPackages, setAllPackages] = useState([]);
  const { t } = useTranslation();

  const client = new CreateClient();

  const getAllPackages = async () => {
    let response = await client.getPackages();
    let res = HandlingResponse(response);
    var result = res
      .filter((x) => !x.isGroup)
      .map((item) => {
        return { value: item.id, label: item.shortDescription };
      });
    setAllPackages(result);
  };

  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <form onSubmit={handleCallback}>
      <div className="row col-md-12">
        <div className="form-group col-md-3">
          <label>Корисничко име</label>
          <input
            type="text"
            className="form-control"
            value={filterData.userName}
            onChange={(e) =>
              setFilterData({ ...filterData, userName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Име компаније</label>
          <input
            type="text"
            className="form-control"
            value={filterData.company}
            onChange={(e) =>
              setFilterData({ ...filterData, company: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>ПИБ</label>
          <input
            type="text"
            className="form-control"
            value={filterData.pib}
            onChange={(e) =>
              setFilterData({ ...filterData, pib: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Пакет</label>
          <Select
            options={allPackages}
            onChange={(e) =>
              setFilterData({ ...filterData, packageId: e ? e.value : null })
            }
            value={allPackages.filter((x) => x.value === filterData.packageId)}
            isClearable
          />
        </div>
        <div className="form-group col-md-3">
          <label>Врста захтева</label>
          <Select
            options={requestTypes}
            onChange={(e) =>
              setFilterData({ ...filterData, requestType:e ? e.value : null })
            }
            value={requestTypes.filter(
              (x) => x.value === filterData.requestType
            )}
            isClearable
          />
        </div>
        <div className="form-group col-md-3">
          <label>Важи од</label>
          <CustomDatePicker
            selected={filterData.validFrom}
            onChange={(validFrom) =>
              setFilterData({ ...filterData, validFrom: validFrom })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Важи до</label>
          <CustomDatePicker
            selected={filterData.validTo}
            onChange={(validTo) =>
              setFilterData({ ...filterData, validTo: validTo })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Статус</label>
          <Select
            options={statusTypes}
            onChange={(e) => setFilterData({ ...filterData, status: e ? e.value : null })}
            value={statusTypes.filter((x) => x.value === filterData.status)}
            isClearable
          />
        </div>
      </div>
      <div className="row col-md-12">
        <div className="form-group col-md-3">
          <label>Захтев креирао</label>
          <input
            type="text"
            className="form-control"
            value={filterData.requestedBy}
            onChange={(e) =>
              setFilterData({ ...filterData, requestedBy: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Датум креирања захтева</label>
          <CustomDatePicker
            selected={filterData.requestedDate}
            onChange={(requestedDate) =>
              setFilterData({ ...filterData, requestedDate: requestedDate })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Захтев обрадио</label>
          <input
            type="text"
            className="form-control"
            value={filterData.processedBy}
            onChange={(e) =>
              setFilterData({ ...filterData, processedBy: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Датум обраде захтева</label>
          <CustomDatePicker
            selected={filterData.processingDate}
            onChange={(processingDate) =>
              setFilterData({ ...filterData, processingDate: processingDate })
            }
          />
        </div>
      </div>
      <div className="row col-md-12">
        <div className="form-group col-md-3">
          <label>Име</label>
          <input
            type="text"
            className="form-control"
            value={filterData.name}
            onChange={(e) =>
              setFilterData({ ...filterData, name: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Презиме</label>
          <input
            type="text"
            className="form-control"
            value={filterData.lastName}
            onChange={(e) =>
              setFilterData({ ...filterData, lastName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Коментар</label>
          <input
            type="text"
            className="form-control"
            value={filterData.comment}
            onChange={(e) =>
              setFilterData({ ...filterData, comment: e.target.value })
            }
          />
        </div>
      <div className="text-right col-md-3" style={{ marginBottom: "28px"}}>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ marginRight: "15px" }}
        >
          Претражи
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => resetFilters()}
        >
          Поништи претрагу
        </button>
      </div>
      </div>
    </form>
  );
};

export default SearchRequests;
