import React, { useState, useEffect } from "react";
import AdvertisementAndActTypeClient from "../../api-services/advertisement-and-act-type/advertisement-and-act-type";
import AdvertisementCategoryModel from "../../models/AdvertisementCategory";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateAdvertisementCategory = ({ row, isEdit, handleCallback }) => {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        defaultSort: Joi.string().min(5).max(250).required(),
        description: Joi.string().max(250).required(),
    }

    var client = new AdvertisementAndActTypeClient();
    const [advertisementCategory, setAdvertisementCategory] = useState(new AdvertisementCategoryModel());

    useEffect(() => {
        setAdvertisementCategory(row);
        setErrors({});
    }, [row]);


    const createAdvertisementAndActType = async (e) => {
        e.preventDefault();
        const errorsResult = validateForm(advertisementCategory, schema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            if (isEdit) var response = await client.updateAdvertisementAndActType(advertisementCategory);
            else var response = await client.createAdvertisementAndActType(advertisementCategory);
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
            else
                handleCallback(handledResponse);
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, advertisementCategory, errors, schema, t);
        setAdvertisementCategory(result.data);
        setErrors(result.errors);
    }

    return (
        <form onSubmit={createAdvertisementAndActType}>
            <div className="col-md">
                <label>{t("Description")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="description"
                    value={advertisementCategory.description}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.description && (
                    <div className="text-danger">
                        {errors.description}
                    </div>
                )}
                <label>{t("DefaultSort")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="defaultSort"
                    value={advertisementCategory.defaultSort}
                    onChange={(event) => { setAdvertisementCategory({ ...advertisementCategory, sort: event.target.value }); handlePropertyChange(event.target) }}
                />
                {errors?.defaultSort && (
                    <div className="text-danger">
                        {errors.defaultSort}
                    </div>
                )}
            </div>
            <input
                    id="advertisementCategoryActiveCB"
                    type="checkbox"
                    value={advertisementCategory.active}
                    checked={advertisementCategory.active}
                    onChange={(event) => {
                        setAdvertisementCategory({
                        ...advertisementCategory,
                        active: event.target.checked,
                    });
                }}
            />
            <label htmlFor="advertisementCategoryActiveCB">{t("Active")}</label>
            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={createAdvertisementAndActType}
                >
                    {t("Save")}
                </button>
            </div>
        </form>
    )
};

export default CreateAdvertisementCategory;