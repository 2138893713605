import Joi from "joi-browser";

class TypesModel {
    constructor(id =0, name= "", weight = 0, parentId = 0, parentName = "", active = true) {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.parentId = parentId;
        this.parentName = parentName;
        this.active = active;
    }
}

export const typesLevelEnum = {
    parentType: 1,
    type: 2,
    subtype: 3
}

const typeSchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required()
}

export {TypesModel, typeSchema};