import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";

const ProductLinks = ({data, tableName}) => {
  const [tableData, setTableData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleChange = (e) => {
    let tableDataCopy = [...tableData];
    tableDataCopy.forEach((x) => (x.isChecked = !selectAll));
    setSelectAll(!selectAll);
    setTableData(tableDataCopy);
  };

  const HandleCheckbox = (row) => {
    let tableDataCopy = [...tableData];
    let index = tableDataCopy.indexOf(row);
    tableDataCopy[index].isChecked = !tableDataCopy[index].isChecked;
    setTableData(tableDataCopy);

    let tableDataCount = tableDataCopy.filter(
      (data) => data.isChecked == false
    ).length;

    if (tableDataCount > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const columns = [
    {
      accessor: "checkbox",
      disableSortBy: true,
      className: "checkbox-column",
      Header: (state) => (
        <input
          type="checkbox"
          className="form-check-input"
          name="allSelect"
          checked={selectAll}
          onChange={handleChange}
        />
      ),
      Cell: ({ row }) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              name={row.original.brojIzdanja}
              checked={row.original.isChecked}
              onChange={() => HandleCheckbox(row.original)}
            />
          </>
        );
      },
    },
    {
      Header: "Идентификатор",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "name",
    },
    {
      Header: "Екстерна референца",
      accessor: "extCode",
    },
    {
      Header: "Опис",
      accessor: "description",
    },
    {
      Header: "Кратак опис",
      accessor: "shortDescription",
    },
  ];

  return (
    <Table
      columns={columns}
      data={tableData}
      rememberFilter={true}
      tableName={tableName}
      serverSide={false}
    />
  );
};

export default ProductLinks;
