import React, {useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";
import { MainContainer } from "./Layout.style";

const REGSLayout = () => {
  const { setAppPath, setAppName } = useAppUrl();

  useEffect(() => {
    setAppName("Регистри");
    setAppPath("/registries");
  }, [])
  return (
    <>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </>
  );
};

export default REGSLayout;
