import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { preventDefaultEvents } from "../../utils/common-functions/CommonFunctions";

const ContextMenu = ({ contextMenu, showParams, handleClose }) => {
    const [positions, setPositions] = useState(null)
    useEffect(() => {
        if (showParams) {
            const { top, left, callbackParams } = showParams;
            setPositions({ top, left, callbackParams: callbackParams })
        }
        else {
            setPositions(null);
        }
    }, [showParams])

    const isOpen = useMemo(() => positions != null, [positions])
    const closeMenu = e => {
        preventDefaultEvents(e);
        handleClose()
    }

    return positions && <Menu open={isOpen} onClose={handleClose} anchorReference="anchorPosition"
        anchorPosition={positions} onContextMenu={closeMenu}>
        {contextMenu.map(((item, index) => {
            if (item.renderingCondition && item.renderingCondition(positions.callbackParams)) {
                return <MenuItem key={index} onClick={() => {
                    handleClose();
                    item.callback(positions.callbackParams);
                }}>{item.label}</MenuItem>
            }
            return null;
        }))}
    </Menu>
}

ContextMenu.propTypes = {
    contextMenu: PropTypes.arrayOf(PropTypes.shape({
        callback: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
}
export default ContextMenu;