import React, { useState, useEffect, useCallback } from "react";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import DialogModal from "../../../common/modal/Modal.component";
import AddProductAuthority from "../add-product-authority/AddProductAuthority.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const ProductReview = ({ data, productId, handleCallback }) => {
  const [productData, setProductData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  const columnsProduct = [
    {
      Header: "Идентификатор",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "authorityName",
    },
    {
      Header: "Екстерна референца",
      accessor: "extCode",
    },
    {
      Header: "Сортирање",
      accessor: "sort",
    },
    {
      Header: "Актуелан",
      accessor: "actual",
    },
    {
      Header: "Коментар",
      accessor: "comments",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("remove")} />
            </button>
          </>
        );
      },
    },
  ];

  const client = new PackageClient();

  const addProductToUser = () => {
    ShowDialog();
  };

  const ShowDialog = () => {
    setShowDialog(true);
  };

  const HideDialog = () => {
    setShowDialog(false);
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteProductAuthority(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error") {
      handleCallback()
    }
  };

  const handleAddingDataToTable = (childData) => {
    HideDialog();
    handleCallback()  }
  
  useEffect(() => {
    setProductData(data);
  }, [data]);

  return (
    <>
      <h2>Преглед доносиоца</h2>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
        onClick={() => addProductToUser()}
      >
        Додај
      </button>
      {productData.length > 0 && <Table
        columns={columnsProduct}
        data={productData}
        rememberFilter={false}
      /> }
      <DialogModal
        show={showDialog}
        onHide={HideDialog}
        title="Додавање доносиоца за производ"
      >
        <AddProductAuthority
          onHide={HideDialog}
          productId={productId}
          handleCallback={handleAddingDataToTable}
        />
      </DialogModal>
    </>
  );
};

export default ProductReview;
