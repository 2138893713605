import React, { useState, useEffect } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { useTranslation } from "react-i18next";
import useFormContext from "../../hooks/useFormContext";
import DataClient from "../../../MML/api-services/data/data-client";
import CourtPracticeClient from "../../../VASKE/api-services/cases/cases-client";
import LawActClient from "../../api-services/law-act/law-act-client";
import LawAdActClient from "../../../SLGL/api-services/law-ad-act/law-ad-act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import ActReferenceTypeClient from "../../../ADMIN-PANEL/api-services/act-reference-type/act-reference-type";
import { databaseList } from "../../models/PreviewLawAct";
import IssueTypeClient from "../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import { format } from 'date-fns';
import Joi from "joi-browser";
import { handleSave, validateForm } from "../../../common/validation/validation";
import DatePicker from "react-datepicker";

const CreateBasicGlobalActReference = ({
  fromOfficialGazette,
  handleCallback,
}) => {
  const { t } = useTranslation();
  const { data } = useFormContext();

  const columns = [
    {
      Header: "ActGuid",
      accessor: "actGuid",
    },
    {
      Header: "Назив акта",
      accessor: "actName",
    },
    {
      Header: "Додатне информације",
      accessor: "additionalInfo",
      Cell: ({ row }) => {
        let date = new Date(row.original.additionalInfo);
        return row.original.additionalInfo ? (row.original.additionalInfo?.includes('0001') ? "" : (!isNaN(date) ? (
            <div>{format(date, "dd.MM.yyyy.")}</div>
          ) : row.original.additionalInfo)) : ""
      },
    },
  ];

  const gazetteList = [
    {
      value: "slglClient",
      label: "Службена гласила",
      enumVal: 2,
    },
  ];
  const schema = {
    typeGuid: Joi.string().required(),
  };

  const actReferenceTypeClient = new ActReferenceTypeClient();
  const issueTypeClient = new IssueTypeClient();
  
  const [actName, setActName] = useState("");
  const [gazetteIssueTypeGuid, setGazetteIssueTypeGuid] = useState("");
  const [gazetteNumber, setGazetteNumber] = useState("");
  const [gazetteYear, setGazetteYear] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [databaseAction, setDatabaseAction] = useState({});
  const [actReferenceType, setActReferenceType] = useState({});
  const [listOfGlobalActReferences, setListOfGlobalActReferences] = useState([]);
  const [errors, setErrors] = useState({});
  const [listOfIssueTypes, setListOfIssueTypes] = useState([]);
  const [listOfActReferenceTypes, setListOfActReferenceTypes] = useState([]);

  const resetValues = () => {
    setActName("");
    setGazetteIssueTypeGuid("");
    setGazetteNumber("");
    setGazetteYear("");
    setSelectedRowIds([]);
    setDatabaseAction({});
    !fromOfficialGazette && setActReferenceType({});
    setListOfGlobalActReferences([]);
    setErrors({});
  }


  useEffect(() => {
    if(fromOfficialGazette)
      setDatabaseAction({ name: "slglClient", client: new LawAdActClient() });
    getActReferencesTypes();
    getIssueTypeNames();
    setErrors({});
  }, []);

  const getIssueTypeNames = async() => {
    var response = await issueTypeClient.getActiveIssueTypes(true);
    var handledResponse = HandlingResponse(response);
    let types = handledResponse.map((item) => {
      return {
        value: item.guid,
        label: item.shortDescription,
        
      };
    });
    setListOfIssueTypes(types);
  }

 const getActReferencesTypes = async () => {
    var response = await actReferenceTypeClient.getActiveActReferenceTypes(true);
    var handledResponse = HandlingResponse(response);

    if (fromOfficialGazette) {
      
        let types = handledResponse
        .map((item) => {
          return {
            value: item.guid,
            label: item.description,
          };
        }).filter((item) => {
            return (
              item.label === "Основни текст, измене, допуне, исправке")
          });
        setListOfActReferenceTypes(types);
        setActReferenceType({typeGuid: types[0].value, typeName: types[0].label})
      
    } else {
      let types = handledResponse
        .filter(
          (item) =>
            item.description !== "Основни текст, измене, допуне, исправке"
        )
        .map((item) => {
          return {
            value: item.guid,
            label: item.description,
            name: "typeGuid"
          };
        });
      setListOfActReferenceTypes(types);
    } 
  }

  const onChangeBase = (databaseName) => {
    resetValues();
    // ako hoce da im se ne ponisiti bas sve kad promene bazu zakomentarisati reset, a otkomentarisati ovo ispod
    // setSelectedRowIds([]);
    // setListOfGlobalActReferences([]);
    // setErrors({});
    // setGazetteIssueTypeGuid("");
    // setGazetteNumber("");
    // setGazetteYear("");

    if (databaseName === "mmlClient")
      setDatabaseAction({ name: databaseName, client: new DataClient() });
    else if (databaseName === "spClient")
      setDatabaseAction({
        name: databaseName,
        client: new CourtPracticeClient(),
      });
    else if (databaseName === "regClient")
      setDatabaseAction({ name: databaseName, client: new LawActClient() });
    else if (databaseName === "slglClient")
      setDatabaseAction({ name: databaseName, client: new LawAdActClient() });
  };

  const onSearchForGlobalActReference = async (e) => {
    e.preventDefault();
    let data = {
      actName: actName,
      gazetteIssueTypeGuid: gazetteIssueTypeGuid,
      gazetteNumber: gazetteNumber === "" ? null : gazetteNumber,
      gazetteYear: gazetteYear === "" ? null : gazetteYear,
    };
    if(!databaseAction.name) {
      setErrors({...errors, selectDatabase: "Морате селектовати базу у којој желите да претражите"})
    }
    else {
      var response;
      if (databaseAction.name === "mmlClient") {
        response = await databaseAction.client.getDataByName(data);
      } else if (databaseAction.name === "spClient") {
        response = await databaseAction.client.getCourtPracticeByName(data);
      } else if (databaseAction.name === "regClient") {
        response = await databaseAction.client.getLawActByNameForReg(data);
      } else if (databaseAction.name === "slglClient") {
        response = await databaseAction.client.getLawAdActByInfos(data);
      }
      var handledResponse = HandlingResponse(response);
      if(handledResponse.length <= 0)
        setErrors({...errors, emptyList: "Није пронађен ниједан акт за тражени упит"});
      else if(handledResponse.length < 49)
        setErrors({...errors, emptyList: ""});
      else setErrors({...errors, emptyList: "Приказано је првих 50 резултата претраге, уколико желите више унесите прецизнији упит"});
      handledResponse && setListOfGlobalActReferences(handledResponse);
    }
  };

  const addBasicGlobalActReference = (event, close) => {
    var result = [];
    const errorsResult = validateForm(actReferenceType, schema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      listOfGlobalActReferences.forEach((x, index) => {
        if (selectedRowIds.includes(index.toString())) {
          result.push(x);
        }
      });
      let base = databaseList
        .filter((x) => {
          return databaseAction.name === x.value;
        })
        .map((x) => {
          return x.enumVal;
        })[0];
      result.forEach((x, index) => {
        x.id = 0;
        x.sort = data.lawActReferenceModel.globalActRefferences.length + index;
        x.packageName = base;
        x.actReferenceTypeGuid = actReferenceType.typeGuid;
        x.actReferenceTypeName = actReferenceType.typeName;

        if(x.additionalInfo) {
          let date = new Date(x.additionalInfo);
          if (!isNaN(date)) x.additionalInfo = format(date, "dd.MM.yyyy.");
        }
      })
      handleCallback(result, actReferenceType, close);
      onSearchForGlobalActReference(event);
      //resetValues();
      //!fromOfficialGazette && setDatabaseAction({ name: "", client: null });
      //!fromOfficialGazette && setActReferenceType({});
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, actReferenceType.typeGuid, errors, schema, t);
    setActReferenceType({typeGuid: event.value, typeName: event.label});
    setErrors(result.errors);
  };

  return (
    <div>
      <form onSubmit={onSearchForGlobalActReference}>
      <div>
        <div className="inline-element width-50">
          <label>База</label>
          <Select
            options={
              fromOfficialGazette
                ? gazetteList
                : databaseList.filter((x) => x.value != undefined)
            }
            onChange={(event) => {
              onChangeBase(event ? event.value : "");
            }}
            value={
              fromOfficialGazette
                ? gazetteList[0]
                : databaseList.filter(
                    (x) =>
                      x.value === databaseAction.name && x.value != undefined
                  )
            }
          />
        </div>
        {databaseAction.name === "slglClient" || fromOfficialGazette ? (
          <>
            <div className="inline-element width-50">
              <label>Назив</label>
              <input
                className="form-control"
                type="text"
                value={actName}
                onChange={(e) => setActName(e.target.value)}
              />
            </div>
            <div className="inline-element width-50">
              <label>Тип</label>
              <Select
                options={listOfIssueTypes}
                onChange={(e) => setGazetteIssueTypeGuid(e ? e.value : "")}
                value={listOfIssueTypes.filter(
                  (x) => x.value === gazetteIssueTypeGuid
                )}
                isClearable
              />
            </div>
            <div className="inline-element width-25">
              <label>Број</label>
              <input
                className="form-control"
                type="number"
                value={gazetteNumber}
                onChange={(e) => setGazetteNumber(e.target.value || null)}
              />
            </div>
            <div className="inline-element width-25">
              <label>Година</label>
              <DatePicker
                  className="form-control"
                  name="gazetteYear"
                  selected={gazetteYear ? new Date(gazetteYear.toString()) : null}
                  onChange={(date) => {
                    date === null &&
                      document
                        .querySelector('button[type="submit"]')
                        ?.focus({ preventScroll: true });

                        setGazetteYear(date?.getFullYear());
                  }
                  }
                  onCalendarClose={() =>
                    document.querySelector('button[type="submit"]')?.focus({preventScroll: true})
                  }
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={10}
                  isClearable
                />
            </div>
          </>
        ) : (
          <>
            <div className="inline-element width-50">
              <label>Упит</label>
              <input
                className="form-control"
                type="text"
                value={actName}
                onChange={(e) => setActName(e.target.value)}
              />
            </div>
          </>
        )}
        {errors?.selectDatabase && (
          <div className="text-danger">{errors.selectDatabase}</div>
        )}
      </div>
      <br />
      <div className="pull-right">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onSearchForGlobalActReference}
        >
          Тражи
        </button>
      </div>
      </form>
      <br />
      <div>
        <label>Тип везе</label>
        <Select
          options={listOfActReferenceTypes}
          defaultValue={fromOfficialGazette ? listOfActReferenceTypes[0] : null}
          onChange={(event) => {
            handlePropertyChange(event);
          }}
          value={
            fromOfficialGazette
              ? listOfActReferenceTypes[0]
              : listOfActReferenceTypes.filter(
                  (x) => x.value === actReferenceType.typeGuid
                )
          }
        />
        {errors?.typeGuid && (
          <div className="text-danger">{errors.typeGuid}</div>
        )}
      </div>
      <br />
        {errors?.emptyList && (
          <>
          <div className="text-danger">{errors.emptyList}</div>
          <br />
          </>
          )}
      {listOfGlobalActReferences.length > 0 && (
        <>
          <Table
            columns={columns}
            data={listOfGlobalActReferences}
            rememberFilter={false}
            hiddenColumn={[databaseAction.name === "regClient" && "additionalInfo", "actGuid"]}
            serverSide={false}
            onRowSelectStateChange={setSelectedRowIds}
          />

          <br />
          <button
            disabled={selectedRowIds.length > 0 ? false : true}
            type="button"
            className="btn btn-primary pull-right"
            onClick={(event) => addBasicGlobalActReference(event, false)}
            >
            Додај
          </button>
          <button
            disabled={selectedRowIds.length > 0 ? false : true}
            type="button"
            className="btn btn-primary pull-right"
            style={{marginRight: '10px'}}
            onClick={(event) => addBasicGlobalActReference(event, true)}
          >
            Додај и затвори
          </button>
        </>
      )}
    </div>
  );
};

export default CreateBasicGlobalActReference;
