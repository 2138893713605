import Joi from "joi-browser";

class ProductModel {
    constructor(id = 0, name = "", originalName = "", parentId = null, note = "", rang = null, changed = false, outdated = false, price = null, showAsNewest = false,
        active = true, nameStyleId = null, noteStyleId = null) {
        this.id = id;
        this.name = name;
        this.originalName = originalName;
        this.parentId = parentId;
        this.note = note;
        this.rang = rang;
        this.changed = changed;
        this.outdated = outdated;
        this.price = price;
        this.showAsNewest = showAsNewest;
        this.active = active;
        this.nameStyleId = nameStyleId;
        this.noteStyleId = noteStyleId;
    }
}

export const ProductSchema = {
    name: Joi.string().max(1000).required(),
    price: Joi.number().min(0).max(2147483647).required(),
    rang: Joi.number().min(0).max(2147483647).required(),
}

const productOperationTypes = {
    addElement: { label: "Додај елемент", outdatedAllowed: false },
    addSubelement: { label: "Додај поделемент", outdatedAllowed: false },
    editElement: { label: "Измени", outdatedAllowed: true },
    deleteElement: { label: "Обриши", outdatedAllowed: true },
}

export { ProductModel, productOperationTypes }