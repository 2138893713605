import Table from "../../../common/table/Table.component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../common/modal/SideModal.component";
import CreatePackageGroup from "../../components/create-package-group/CreatePackageGroup.component";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import { format } from 'date-fns';
import { DateRangeColumnFilter, dateBetweenFilterFn, SelectColumnFilter } from "../../../common/table/filterHelpFunction.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";

const PackageGroups = () => {
    const [packageGroups, setPackageGroups] = useState([]);
    const {t} = new useTranslation();

    const filter = {
        filterValue: "equals",
        selectOptions: [
          {
            value: undefined,
            label: "Сви",
          },
          {
            value: "0",
            label: "Активна",
          },
          {
            value: "1",
            label: "Неактивна",
          },
        ],
      };
    const columns = [
        {
            Header: "Шифра",
            accessor: "extCode",
            disableFilters: false,
             
        },
        {
            Header: "Коментар",
            accessor: "shortDescription",
            disableFilters: false,
             
        },
        {
            Header: "Префикс",
            accessor: "prefixName",
            disableFilters: false,
             
        },
        {
            Header: "Статус",
            accessor: "packageStatus",
            disableFilters: false,
            Filter: SelectColumnFilter,
            filter: filter,
            Cell: ({row}) => { return (row.original.packageStatus === 0 ? "Активна" : "Неактивна") }
          },
        {
            Header: "Цена",
            accessor: "price",
            disableFilters: false,
             
            width: 200
        },
        {
            Header: "Сортирање",
            accessor: "sort",
            disableFilters: false,
             
            width: 200
        },
        {
            Header: "Важи од",
            accessor: "dateFrom",
            Cell: ({ row }) => {
                return (
                    <div>
                        {format(new Date(row.original.dateFrom), 'dd.MM.yyyy.')}
                    </div>
                )
            },
            disableFilters: false,
            Filter: DateRangeColumnFilter,
            filter: dateBetweenFilterFn,
            width: 200
        },
        {
            Header: "Важи до",
            accessor: "dateTo",
            Cell: ({ row }) => {
                return (
                    <div>
                        {format(new Date(row.original.dateTo), 'dd.MM.yyyy.')}
                    </div>
                )
            },
            disableFilters: false,
            Filter: DateRangeColumnFilter,
            filter: dateBetweenFilterFn,
            width: 200
        },
        {
            accessor: "edit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <button type="button" className="btn btn-primary table-btn" onClick={() => updatePackageGroup(row.original)}><FontAwesomeIcon icon={solid("edit")} /></button>
                )
            },
            width: 50
        }
    ];

    const [rowData, setRowData] = useState({
        shortDescription: '',
        description: '',
        dateFrom: new Date(),
        dateTo: new Date(),
        price: 0,
        extCode: '',
        sort: 0,
        prefixName: ''
    });

    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);

    const client = new PackageClient();

    const getAllPackageGroups = async () => {
        await client.GetPackages().then((response) => {
            let handledResponse = HandlingResponse(response);
            var result = handledResponse.filter((x) => x.isGroup);
            setPackageGroups(result);
        })
    }

    useEffect(() => {
        getAllPackageGroups();
    }, []);

    const showModal = () => { setShow(true) };
    const hideModal = () => { setShow(false) };

    const createPackageGroup = () => {
        setEdit(false);
        setRowData({
            shortDescription: '',
            description: '',
            dateFrom: new Date(),
            dateTo: new Date(),
            price: 0,
            extCode: '',
            sort: 0,
            prefixName: '',
            id: 0,
            packageType: "PRODUCTS",
            packageStatus: 1,
            chosenPackages: []
        });
        setPackageGroupList([])
        showModal();
    }

    const [packageGroupList, setPackageGroupList] = useState([])

    const updatePackageGroup = async (data) => {
        setEdit(true);
        await client.GetPackageById(data.id).then((response) => {
            let handledResponse = HandlingResponse(response);
            var packageGroupList = handledResponse.childPackages.map((x) => { return { value: x.id, label: x.name}})
            setRowData({
                ...handledResponse,
                dateFrom: new Date(handledResponse.dateFrom),
                dateTo: new Date(handledResponse.dateTo),
                packages: handledResponse.childPackages.map((x) => { return { value: x.id, label: x.shortDescription} })
            });
            setPackageGroupList(packageGroupList)
            showModal();
        })
    }

    const handleAddingDataToTable = (childData) => {
        hideModal();
        getAllPackageGroups();
      };

    return (
      <>
        <h4>Групе пакета</h4>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
          onClick={() => createPackageGroup()}
        >
          Креирај групу пакета
        </button>
        <Table
          data={packageGroups}
          columns={columns}
          rememberFilter={false}
        ></Table>
        <SideModal
          show={show}
          handleClose={hideModal}
          title={edit ? "Измена групе пакета" : "Креирање групе пакета"}
        >
          <CreatePackageGroup
            data={rowData}
            edit={edit}
            handleCallback={handleAddingDataToTable}
            packageGroups={packageGroupList}
          />
        </SideModal>
      </>
    );

}

export default PackageGroups;