import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import EditActs from "../edit-acts/EditActs.component"

const CurrentYearAct = ({ activeTab }) => {
  const [isCurrent, setIsCurrent] = useState(true);

  return (
    <>
      {isCurrent ? (
        <EditActs
          isCurrent={isCurrent}
          tableName={"SLGL_CURRENT_EDIT_ACTS"}
          filterName={"SLGL_CURRENT_EDIT_ACTS_FILTER"}
          activeTab={activeTab}
        ></EditActs>
      ) : (
        <></>
      )}
    </>
  );
}

export default CurrentYearAct;