import TreeNode from "./TreeNode.component";

const TreeList = ({
  data,
  expanded,
  className,
  handleSelected,
  contextMenuEvent,
  preselectedNodeId,
  openParent,
}) => (
  <ul className={className}>
    {data.map((node) => (
      <TreeNode
        expanded={expanded}
        key={node.id}
        node={node}
        contextMenuEvent={contextMenuEvent}
        handleSelected={handleSelected}
        preselectedNodeId={preselectedNodeId}
        openParent={openParent}
      />
    ))}
  </ul>
);

export default TreeList;
