import React, { useState, useEffect } from "react";
import AuthorityClient from "../../api-services/authority/authority-client";
import AuthorityModel from "../../models/Authority";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateAuthority = ({ row, isEdit, handleCallback }) => {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        authorityName: Joi.string().max(250).required(),
        description: Joi.string().max(250).required(),
        sort: Joi.string().min(5).max(250).required(),
    }

    const client = new AuthorityClient();

    const [authority, setAuthority] = useState(new AuthorityModel());

    const createAuthority = async (e) => {
        e.preventDefault();
        const errorsResult = validateForm(authority, schema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            if (isEdit) var response = await client.updateAuthority(authority);
            else var response = await client.createAuthority(authority);
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
            else
                handleCallback(handledResponse);
        }
    }

    useEffect(() => {
        setAuthority(row);
        setErrors({});
    }, [row]);

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, authority, errors, schema, t);
        setAuthority(result.data);
        setErrors(result.errors);
    }

    return (
        <form onSubmit={createAuthority}>
            <div className="col-md">
                <label>{t("Name")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="authorityName"
                    value={authority.authorityName}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.authorityName && (
                    <div className="text-danger">
                        {errors.authorityName}
                    </div>
                )}
                <label>{t("Description")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="description"
                    value={authority.description}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.description && (
                    <div className="text-danger">
                        {errors.description}
                    </div>
                )}
                <label>{t("Sort")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="sort"
                    value={authority.sort}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.sort && (
                    <div className="text-danger">
                        {errors.sort}
                    </div>
                )}
                <input
                id="currentCB"
                    type="checkbox"
                    value={authority.isCurrent}
                    checked={authority.isCurrent}
                    onChange={(event) => {
                        setAuthority({ ...authority, isCurrent: event.target.checked });
                    }}
                />
                <label htmlFor="currentCB" style={{marginRight: "15px"}}>{t("Actual")}</label>
                <input
                id="activeCB"
                    type="checkbox"
                    value={authority.active}
                    checked={authority.active}
                    onChange={(event) => {
                        setAuthority({ ...authority, active: event.target.checked });
                    }}
                />
                <label htmlFor="activeCB" style={{marginRight: "15px"}}>{t("Active")}</label>
                
                <input
                id="archival"
                    type="checkbox"
                    value={authority.archival}
                    checked={authority.archival}
                    onChange={(event) => {
                        setAuthority({ ...authority, archival: event.target.checked });
                    }}
                />
                <label htmlFor="archival">{t("Archival")}</label>
            </div>

            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={createAuthority}
                >
                    {t("Save")}
                </button>
            </div>
        </form>
    )
};

export default CreateAuthority;