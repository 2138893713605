import React, { useState, useEffect, useCallback } from "react";
import SideModal from "../../../common/modal/SideModal.component";
import Table from "../../../common/table/Table.component";
import CreateAuthorityArea from "../create-authority-area/createAuthorityArea.component";
import { AuthorityAreaModel } from "../../models/Authority";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthorityClient from "../../api-services/authority/authority-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const AuthorityArea = ({ authorityAreasData, handleChange, authorityInfo }) => {
  const { t } = useTranslation();
  const tableName = 'MML_AUTHORITY_AREA';
  
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
          <button
            title="Измени"
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
          <ButtonWithComfirmation
            className="btn btn-danger table-btn"
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Материја доносиоца ће бити трајно обрисана."}
            handleYesText={"Обриши"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  var client = new AuthorityClient();

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allAuthorityAreas, setAllAuthorityAreas] = useState([]);
  const [rowData, setRowData] = useState(new AuthorityAreaModel());
  const [title, setTitle] = useState("");

  useEffect(() => {
    setAllAuthorityAreas(authorityAreasData);
  }, [authorityAreasData]);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      authorityGroupId: authorityInfo.groupId,
      authorityGroupName: authorityInfo.groupName,
      authorityKindId: authorityInfo.kindId,
      authorityKindName: authorityInfo.kindName,
      name: "",
      weight: 0,
      active: true
    });
    setTitle(t("CreateAuthorityArea"));
    showModal();
  };
  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData({
      id: row.id,
      authorityGroupId: row.authorityGroupId,
      authorityGroupName: authorityInfo.groupName,
      authorityKindId: authorityInfo.kindId,
      authorityKindName: authorityInfo.kindName,
      name: row.name,
      weight: row.weight,
      active: row.active,
    });
    setTitle(t("EditAuthorityArea"));
    showModal();
  };

  const onDeleteClick = async (id) => {
    let response = await client.deleteAuthorityArea(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error") {
      let allAuthorityAreasCopy = allAuthorityAreas.filter((x) => x.id !== id);
      setAllAuthorityAreas(allAuthorityAreasCopy);
    }
  };

  const handleTableRowClick = useCallback((response) => {
    getAythoritiesByAuthorityAreaOrKind(response.id, response.name);
  });

  const getAythorityAreasByAuthorityKind = async () => {
    let response = await client.getAuthorityAreasByAuhtorityKindId(authorityInfo.kindId, true);
    let handledResponse = HandlingResponse(response);
    handledResponse && setAllAuthorityAreas(handledResponse);
  };


  const getAythoritiesByAuthorityAreaOrKind = async (id, name) => {
    let response = await client.getAuthoritiesByAuthorityAreaId(id, true);
    let res = HandlingResponse(response);

    handleChange(res, id, name);
  };

  return (
    <>
        <div>
          <h5>{t("AuthorityAreas")}</h5>
          <button className="btn btn-primary" onClick={onCreateClick}>
            {t("AddArea")}
          </button>
        </div>
        <br/>
        <Table
          columns={columns}
          data={allAuthorityAreas}
          rememberFilter={false}
          tableName={tableName}
          hiddenColumn={["id"]}
          serverSide={false}
          handleClickOnRow={handleTableRowClick}
        />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAuthorityArea
          row={rowData}
          isEdit={isEdit}
          handleCallback={() => {hideModal(); getAythorityAreasByAuthorityKind();}}
        />
      </SideModal>
    </>
  );
};

export default AuthorityArea;
