import Joi from "joi-browser";

class CreateActModel {
    constructor(areaId = 0, guid = "", externalReferenceTypeName = "", parentId = null) {
        this.areaId = areaId;
        this.guid = guid;
        this.externalReferenceTypeName = externalReferenceTypeName;
        this.parentId = parentId;
    }
}

class PreviewInternalActModel {
    constructor(internalActBasicData = new InternalActBasicData(),
        internalActChangesHistory = new InternalActChangesHistoryModel(), internalActOtherData = new InternalActOtherDataModel(), file = null, files = [], pictures = [] ) {

        this.internalActBasicData = internalActBasicData;
        this.internalActChangesHistory = internalActChangesHistory;
        //this.internalActReference = internalActReference;
        this.internalActOtherData = internalActOtherData;
        this.file = file;
        this.files = files;
        this.pictures = pictures;
    }
}

class InternalActBasicData {
    constructor( id = 0, guid = "", actTypeId = null, authorityId = null, superScript = "", internalActName = "", abstract = "", subScript = "", detailedDefinition = "", versionApplicationDate = new Date(), validToDate = new Date(), source = "", hasNoHtml = false, showIdCard = false, showStructureLink = false, showEditorMark = false, ){
        this.id = id;
        this.guid = guid;
        this.actTypeId = actTypeId;
        this.authorityId = authorityId;
        this.superScript = superScript;
        this.internalActName = internalActName;
        this.abstract = abstract;
        this.subScript = subScript;
        this.detailedDefinition = detailedDefinition;
        this.versionApplicationDate = versionApplicationDate;
        this.validToDate = validToDate;
        this.source = source;
        this.hasNoHtml = hasNoHtml;
        this.showIdCard = showIdCard;
        this.showStructureLink = showStructureLink;
        this.showEditorMark = showEditorMark;

    }       
}

// class InternalActReferenceModel {
//     constructor(globalActReferences = Array(new InternalGlobalActRefferenceModel())) {
//         this.globalActReferences = globalActReferences;
//     }
// }

// class InternalGlobalActRefferenceModel {
//     constructor(packageName = 0, id = 0, actGuid = "", actName = "", actReferenceTypeGuid = "", additionalInfo = "") {
//         this.packageName = packageName;
//         this.id = id;
//         this.actGuid = actGuid;
//         this.actName = actName;
//         this.actReferenceTypeGuid = actReferenceTypeGuid;
//         this.additionalInfo = additionalInfo;
//     }
// }

class InternalActOtherDataModel {
    constructor(editorMark = "", cssSuperscriptClass = "", cssTitleClass = "", cssSubscriptClass = "", cssDescriptionCLass = "", ) {

        this.editorMark = editorMark;
        this.cssSuperscriptClass = cssSuperscriptClass;
        this.cssTitleClass = cssTitleClass;
        this.cssSubscriptClass = cssSubscriptClass;
        this.cssDescriptionClass = cssDescriptionCLass;
    }
}

class InternalActChangesHistoryModel {
    constructor(actsChangesHistory = Array(new ActsChangesHistoryModel())) {
        this.actsChangesHistory = actsChangesHistory;
    }
}

class ActsChangesHistoryModel {
    constructor(username = "", editorMark = "", changedAt = null) {
        this.username = username;
        this.editorMark = editorMark;
        this.changedAt = changedAt;
    }
}

const schemaForBasicData = Joi.object().keys({
    internalActName: Joi.string().required()
})

export const schema = Joi.object({
    file: Joi.required(),
    internalActBasicData: schemaForBasicData
})


export { CreateActModel, PreviewInternalActModel, InternalActBasicData, InternalActChangesHistoryModel, ActsChangesHistoryModel, InternalActOtherDataModel }
