import styled from "styled-components";

export const TreeContainer = styled.div`
.left-tree {
    overflow-y: scroll;
    height: 680px;
   }
    
    .right-tree {
      max-height: calc(100vh - 500px);
      overflow: auto;
    }
   .clicked-tree {
    background-color: #e8e8e9;
   }
   
   .right-click-tree {
    background-color: #e8e8e9;
   }
  
   .hover-text {
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
    display: inline-block;
  }
  .hover-text:hover {
    background-color: #e8e8e9;
  }
  .prevent-select {
    user-select: none;
  }

  .inactive-act {
    color: red;
  }
`

export const TreeLabel = styled.label`
   margin-bottom: 0px;
   font-weight: 400;
`