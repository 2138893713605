import React, { useState, useEffect } from "react";
import SideModal from "../../../common/modal/SideModal.component";
import Table from "../../../common/table/Table.component";
import CreateAuthority from "../create-autority/createAuthority.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AuthorityModel } from "../../models/Authority";
import AuthorityClient from "../../api-services/authority/authority-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const Authority = ({ authorityData, authorityInfo }) => {
  const { t } = useTranslation();
  const tableName = 'MML_AUTHORITY';

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Измени"
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Доносилац ће бити трајно обрисан."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  var client = new AuthorityClient();

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allAuthorities, setAllAuthorities] = useState([]);
  const [rowData, setRowData] = useState(new AuthorityModel());
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (authorityData.length === 0 && authorityInfo)
      getAuthoritiesByAuthorityKind(authorityInfo.kindId);
  }, [authorityInfo]);

  useEffect(() => {
    setAllAuthorities(authorityData);
  }, [authorityData]);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const getAuthoritiesByAuthorityKind = async (id) => {
    let response = await client.getAuthoritiesByAuthorityKindId(id);
    setAllAuthorities(HandlingResponse(response));
  };

  const onCreateClick = async () => {
    setRowData({
      authorityGroupId: authorityInfo.groupId,
      authorityGroupName: authorityInfo.groupName,
      authorityKindId: authorityInfo.kindId,
      authorityKindName: authorityInfo.kindName,
      authorityAreaId: authorityInfo.areaId,
      authorityAreaName: authorityInfo.areaName,
      name: "",
      weight: 0,
      active: true
    });
    setIsEdit(false);
    setTitle(t("CreateAuthority"));
    showModal();
  };
  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData({
      id: row.id,
      authorityGroupId: row.authorityGroupId,
      authorityGroupName: authorityInfo.groupName,
      authorityKindId: authorityInfo.kindId,
      authorityKindName: authorityInfo.kindName,
      authorityAreaId: authorityInfo.areaId,
      authorityAreaName: authorityInfo.areaName,
      name: row.name,
      weight: row.weight,
      active: row.active,
    });
    setTitle(t("EditAuthority"));
    showModal();
  };

  const onDeleteClick = async (id) => {
    let response = await client.deleteAuthority(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      let allAuthoritiesCopy = allAuthorities.filter((x) => x.id !== id);
      setAllAuthorities(allAuthoritiesCopy);
    }
  };

  const getAythoritiesByAuthorityArea = async () => {
    let response = await client.getAuthoritiesByAuthorityAreaId(authorityInfo.areaId, true);
    let handledResponse = HandlingResponse(response);
    handledResponse && setAllAuthorities(handledResponse);

  };

  return (
    <>
      <div>
        <h5>{t("Authorities")}</h5>
        <button className="btn btn-primary" onClick={onCreateClick}>
          {t("AddAuthority")}
        </button>
      </div>
      <br />
      <Table
        columns={columns}
        data={allAuthorities}
        tableName={tableName}
        rememberFilter={false}
        hiddenColumn={["id"]}
        serverSide={false}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAuthority
          row={rowData}
          isEdit={isEdit}
          handleCallback={() => {
            hideModal();
            getAythoritiesByAuthorityArea();
          }}
        />
      </SideModal>
    </>
  );
};

export default Authority;
