import { Error, Success } from "../toastify/toastify";

const HandlingResponse = (response) => {
    var messageCode = '200';

    if ('responseTypeCode' in response)
        switch (response.responseTypeCode) {
            case 200: //Success
                {
                    Success(messageCode);
                    return 'Success';
                }
            case 201: //SuccessRedirect
                {
                    Success(messageCode);
                    return response.data;
                }
            case 202: //SuccessWithMessage
                {
                    if (response.messageCode)
                        messageCode = response.messageCode;
                    else messageCode = '200';

                    Success(messageCode);
                }
            case 203: //SuccessRedirectWithMessage
                {
                    break;
                }
            case 204: //SuccessReload
                {
                    Success(messageCode);
                    setTimeout(() => {
                        window.location.reload();
                    }, 800);
                    break;
                }
            case 205: //SuccessReloadWithMessage
                {
                    
                }
            case 206: //SuccessWithData
                {
                    Success(messageCode);
                    return response.data;
                }
            case 207: //SuccessWithDataAndMessage
                {
                    if (response.messageCode)
                        messageCode = response.messageCode;

                    Success(messageCode);
                    return response.data;
                }
            case 208: //SuccessWithDataGetMethod
                {
                    return response.data;
                }
            case 400: //Error
                {
                    messageCode = '400';
                    Error(messageCode);
                    return 'Error';
                }
            case 401: //ErrorRedirect
                {
                    break;
                }
            case 402: //ErrorWithMessage
                {
                    if (response.messageCode)
                        messageCode = response.messageCode;
                    else messageCode = '400';

                    Error(messageCode);
                    return 'Error';
                }
            case 403: //ErrorRedirectWithMessage
                {
                    break;
                }
            case 404: //ErrorReload
                {
                    break;
                }
            case 405: //ErrorReloadWithMessage
                {
                    break;
                }
            case 406: //ErrorWithData
                {
                    messageCode = '400';
                    Error(messageCode);
                    return response.data;
                }
            case 407: //ErrorWithDataAndMessage
                {
                    if (response.messageCode)
                        messageCode = response.messageCode;

                    Error(messageCode);
                    return response.data;
                }
            case 408: //Validation
                {
                    return { code: response.responseTypeCode, data: response.data };
                }
            default:
                {
                    Error('0');
                    break;
                }
        }
    else
        switch (response.status) {
            case 403: //Forbidden
                {
                    //window.location.replace('/notAllowed');
                    Error(response.status);
                    break; 
                }
                case 504: //TimeOut expired
                {
                    Error(response.status);
                    break;    
                }
            default:
                {
                    Error('0');
                    break;
                }
        }
}

export default HandlingResponse;