import React, { useState, useEffect } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import "react-datepicker/dist/react-datepicker.css";
import ChosenEdition from "../../components/chosen-edition/ChosenEdition.component";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";

const CreateIndividualUser = () => {
  const [chosenEditions, setChosenEditions] = useState([]);
  const [editionsList, setEditionsList] = useState([]);
  const [customersList, setCustomersList] = useState([]);

  const [user, setUser] = useState({
    editions: undefined,
    userType: "1",
    buyer: "",
    buyerNumber: "",
    company: "",
    pib: "",
    paymentDate: new Date(),
    name: "",
    lastName: "",
    email: "",
    telephone: "",
    userName: "",
    password: "",
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    editions: Joi.required(),
    buyerNumber:  user.userType === "1" ? Joi.string().max(10).required() : Joi.string().max(10).allow("").optional(),
    company:  user.userType === "1" ? Joi.string().required() : Joi.string().allow("").optional(),
    pib:  user.userType === "1" ? Joi.string().required() : Joi.string().allow("").optional(),
    name: Joi.string().required(),
    lastName: Joi.string().max(45).required(),
    email: Joi.string().email().required(),
    telephone: Joi.string().allow("").optional(),
    userName: Joi.string().max(45).required(),
    password: Joi.string().min(6).required()
  };

  const client = new CreateClient();

  const getPackages = async () => {
    let response = await client.getAllPackages();
    let res = HandlingResponse(response);
    var packageGroups = res
      .filter((x) => x.isGroup)
      .map((item) => {
        return { value: item.id, label: item.extCode };
      });
    setEditionsList(packageGroups);
  };

  const getAllCustomersFromNav = async () => {
    let response = await client.GetAllCustomersFromNav();
    var customersArray = response.map((item) => {
      return {value: item.customerValue, label:item.customerText}
    })
    setCustomersList(customersArray);
  }

  const getCustomersFromNavByNo = async (customerNo) => {
    let response = await client.GetCustomersFromNavByNo(customerNo);
    setUser({
      ...user,
      buyer: customerNo,
      buyerNumber: response.customerNo,
      company: response.customerName,
      pib: response.customerPIB
    })
    response && setErrors({...errors, buyerNumber: "", company: "", pib: ""})
  }

  useEffect(() => {
    getPackages();
    getAllCustomersFromNav();
  }, []);

  const CreateUser = async (event, approve) => {
    // .filter((edition) => {
    //   if (editions.findIndex((x) => x.value === edition.packageId) > -1) return edition;
    // })
    event.preventDefault();

    if (user.userType === "1") {
      schema.company = Joi.string().required();
    } else {
      schema.telephone = Joi.string().required();
    }

    const errorsResult = validateForm(user, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      let editionsCopy = chosenEditions.map((item) => {
        return {
          ...item,
          paymentDate: user.paymentDate,
          customerNo: user.buyerNumber,
          customerName: user.company,
          customerPib: user.pib,
        };
      });
      setChosenEditions(editionsCopy);

      const data = {
        ...user,
        isLegalEntity: user.userType === "1" ? true : false,
        legalEntityName: "",
        requestUsers: editionsCopy,
        isInternal: false,
        isGroupRequest: false,
      };

      let response = await client.createUser(approve, data);
      let handledResponse = HandlingResponse(response);
      if(handledResponse !== "Error") {
        if (handledResponse.code == 408) {
          var result = serverValidation(handledResponse.data, t);
          setErrors(result);
        } else handleReset();
      }
  }
  };

  const handleReset = () => {
    setUser({
      editions: null,
      userType: "1",
      buyer: "",
      buyerNumber: "",
      company: "",
      pib: "",
      paymentDate: new Date(),
      name: "",
      lastName: "",
      email: "",
      telephone: "",
      userName: "",
      password: "",
    });
  };

  const handleChange = (data) => {
    let editionsCopy = [...chosenEditions];
    let index = editionsCopy.findIndex(
      (edition) => edition.packageId === data.packageId
    );
    if (index > -1) {
      editionsCopy[index] = data;
    } else {
      editionsCopy.push(data);
    }
    setChosenEditions(editionsCopy);
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, user, errors, schema, t);
    setUser(result.data);
    setErrors(result.errors);
  };

  return (
    <form>
      <h2>Креирање корисничког налога</h2>
      <label> Поља са * су обавезна</label>
      <div className="form-group">
        <input
          type="radio"
          name="userType"
          value="1"
          checked={user.userType === "1" ? "checked" : ""}
          onChange={(e) => {
            setUser({ ...user, userType: e.target.value })
            setErrors({})
          }}
        />{" "}
        Правно лице &nbsp;
        <input
          type="radio"
          name="userType"
          value="2"
          checked={user.userType === "2" ? "checked" : ""}
          onChange={(e) => {
            setUser({ ...user, userType: e.target.value, buyer: "", buyerNumber: "", company: "", pib: ""})
            setErrors({})
          }
          }
        />{" "}
        Физичко лице
      </div>

      
          <div className="form-group">
            <label>Изабери купца</label>
            <Select
              options={customersList}
              onChange={(e) => {
                setUser({ ...user, buyer: e.value })
                getCustomersFromNavByNo(e.value)
              }}
              value={customersList.filter((x) => x.value === user.buyer)}
              isDisabled={user.userType === "1" ? "" : "disabled"}
            />
          </div>
          <div className="form-group">
            <label>Број купца{user.userType === "1" && <span className="required-asterisk">*</span>}</label>
            <input
              className="form-control"
              type="text"
              name="buyerNumber"
              value={user.buyerNumber}
              onChange={(e) => handlePropertyChange(e.target)}
              disabled={user.userType === "1" ? "" : "disabled"}
            />
            {errors?.buyerNumber && (
              <div className="text-danger">{errors.buyerNumber}</div>
            )}
          </div>
          <div className="form-group">
            <label>Назив фирме{user.userType === "1" && <span className="required-asterisk">*</span>}</label>
            <input
              className="form-control"
              type="text"
              name="company"
              value={user.company}
              onChange={(e) => handlePropertyChange(e.target)}
              disabled={user.userType === "1" ? "" : "disabled"}
            />
            {errors?.company && (
              <div className="text-danger">{errors.company}</div>
            )}
          </div>
          <div className="form-group">
            <label>ПИБ{user.userType === "1" && <span className="required-asterisk">*</span>}</label>
            <input
              className="form-control"
              type="text"
              name="pib"
              value={user.pib}
              onChange={(e) => handlePropertyChange(e.target)}
              disabled={user.userType === "1" ? "" : "disabled"}
            />
            {errors?.pib && <div className="text-danger">{errors.pib}</div>}
          </div>

      <div className="form-group">
        <label>Датум уплате</label>
        <CustomDatePicker
          selected={user.paymentDate}
          onChange={(date) => setUser({ ...user, paymentDate: date })}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.paymentDate && (
          <div className="text-danger">{errors.paymentDate}</div>
        )}
      </div>
      <div className="form-group">
        <label>Име<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="name"
          value={user.name}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.name && <div className="text-danger">{errors.name}</div>}
      </div>
      <div className="form-group">
        <label>Презиме<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="lastName"
          value={user.lastName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.lastName && (
          <div className="text-danger">{errors.lastName}</div>
        )}
      </div>
      <div className="form-group">
        <label>Е-маил адреса<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="email"
          value={user.email}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.email && <div className="text-danger">{errors.email}</div>}
      </div>

      {user.userType === "2" && (
        <div className="form-group">
          <label>Број телефона (без размака)<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="telephone"
            value={user.telephone}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.telephone && (
            <div className="text-danger">{errors.telephone}</div>
          )}
        </div>
      )}

      <div className="form-group">
        <label>Корисничко име<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="userName"
          value={user.userName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.userName && (
          <div className="text-danger">{errors.userName}</div>
        )}
      </div>
      <div className="form-group">
        <label>Лозинка<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="password"
          value={user.password}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.password && (
          <div className="text-danger">{errors.password}</div>
        )}
      </div>
      <div className="form-group">
        <label>Изабери пакет<span className="required-asterisk">*</span></label>
        <Select
          options={editionsList}
          onChange={(e) => handlePropertyChange(e, "editions")}
          value={user.editions}
          isClearable
        />
        {errors?.editions && (
          <div className="text-danger">{errors.editions}</div>
        )}
      </div>
      {user.editions && (
        <ChosenEdition
          data={user.editions}
          handleEditionChange={handleChange}
        />
      )}
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => {
            CreateUser(e, false);
          }}
        >
          Креирај захтев
        </button>
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={(e) => {
            CreateUser(e, true);
          }}
        >
          Креирај и одобри захтев
        </button>
      </div>
    </form>
  );
};

export default CreateIndividualUser;
