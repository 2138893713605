import Joi from "joi-browser";

class AuthorityGroupModel {
    constructor(id = 0, name = "", weight = 0, active = true) {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.active = active;
    }
}

class AuthorityKindModel {
    constructor(id = 0, name = "", weight = 0, active = true, authorityGroupId= 0, authorityGroupName ="") {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.active = active;
        this.authorityGroupId = authorityGroupId;
        this.authorityGroupName = authorityGroupName;
    }
}

class AuthorityAreaModel {
    constructor(id = 0, name = "", weight = 0, active = true,  authorityGroupId= 0, authorityGroupName ="", authorityKindId= 0, authorityKindName ="") {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.authorityGroupId = authorityGroupId;
        this.authorityGroupName = authorityGroupName;
        this.authorityKindId = authorityKindId;
        this.authorityKindName = authorityKindName;
        this.active = active;
    }
}

class AuthorityModel {
    constructor(id = 0, name = "", weight = 0, active = true,  authorityGroupId= 0, authorityGroupName ="", authorityKindId= 0, authorityKindName ="", authorityAreaId = 0, authorityAreaName = "") {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.authorityGroupId = authorityGroupId;
        this.authorityGroupName = authorityGroupName;
        this.authorityKindId = authorityKindId;
        this.authorityKindName = authorityKindName;
        this.authorityAreaId = authorityAreaId;
        this.authorityAreaName = authorityAreaName;
        this.active = active;
    }
}

export const authorityGroupSchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required()
}

export const authorityKindSchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    authorityGroupName: Joi.string().max(250).required()
}

export const authorityAreaSchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    authorityGroupName: Joi.string().max(250).required(),
    authorityKindName: Joi.string().max(250).required()
}

export const authoritySchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    authorityGroupName: Joi.string().max(250).required(),
    authorityKindName: Joi.string().max(250).required(),
    authorityAreaName: Joi.string().max(250).required()
}

export { AuthorityGroupModel, AuthorityKindModel, AuthorityAreaModel, AuthorityModel }