import { useState } from "react";
import CurrentYearNumbers from "../../../components/princess/current-year-numbers/CurrentYearNumbers.component";
import CurrentYearActs from "../../../components/princess/curret-year-acts/CurrentYearActs.component";
import EditActs from "../../../components/princess/edit-acts/EditActs.component";
import EditNumbers from "../../../components/princess/edit-numbers/EditNumbers.component";
import { TabContainer } from "../Tabs.style";
import UserService from "../../../../utils/authentification/keycloak";

const IndexPrincess = () => {
  const getActiveTab = () => {
    var tab;
    if (UserService.hasRole(["SLGL_Arhiva"])){
      tab = sessionStorage.getItem("indexPrincess_arhiva_active_tab");
    }
    if (UserService.hasRole(["SLGL_Urednik"])){
      tab = sessionStorage.getItem("indexPrincess_urednik_active_tab");
    }
    else if (UserService.hasRole(["SLGL_Admin", "SuperAdmin"]))
     tab = sessionStorage.getItem("indexPrincess_active_tab");
    return tab ? parseInt(tab) : 0;
}

  const [activeTab, setActiveTab] = useState(getActiveTab);
  
  const tabs = [
    {
      label: "Измена броја",
      roles: ["SLGL_Admin", "SuperAdmin", 'SLGL_Arhiva'],
      component: <EditNumbers isCurrent={false} filterName={"SLGL_EDIT_NUMBERS_FILTER"} activeTab={activeTab} />,
    },
    {
      label: "Измена акта",
      roles: ["SLGL_Admin", "SuperAdmin", 'SLGL_Arhiva'],
      component: <EditActs isCurrent={false} tableName={"SLGL_EDIT_ACTS"} filterName={"SLGL_EDIT_ACTS_FILTER"} activeTab={activeTab} />,
    },
    {
      label: "Измена броја у текућој години",
      roles: ["SLGL_Admin", "SuperAdmin", 'SLGL_Urednik'],
      component: <CurrentYearNumbers activeTab={activeTab} />,
    },
    {
      label: "Измена акта у текућој години",
      roles: ["SLGL_Admin", "SuperAdmin", 'SLGL_Urednik'],
      component: <CurrentYearActs activeTab={activeTab} />,
    },
  ];

  const tabsWithRoles = tabs.filter( x => UserService.hasRole(x.roles));
 
  const handleTabClick = (index) => {
    if (UserService.hasRole(["SLGL_Arhiva"]))
      sessionStorage.setItem("indexPrincess_arhiva_active_tab", index);
    else if (UserService.hasRole(["SLGL_Urednik"]))
      sessionStorage.setItem("indexPrincess_urednik_active_tab", index);
    else sessionStorage.setItem("indexPrincess_active_tab", index);
    setActiveTab(index);
  };

  return (
    <>
      <TabContainer>
        {tabsWithRoles.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </TabContainer>
      <div>{tabsWithRoles[activeTab].component}</div>
    </>
  );
};

export default IndexPrincess;
