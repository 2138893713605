import React, { useState, useEffect } from 'react'
import { FlexDiv } from '../../../common/table/Table.style';
import CourtKindClient from '../../api-services/coders/court-types/court-types-client';
import CourtClient from '../../api-services/coders/courts/courts-client';
import CourtDepartmentClient from '../../api-services/coders/court-departments/court-deparments-client';
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from '../../../common/table/Table.component';
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { handleSave } from '../../../common/validation/validation';
import { useTranslation } from "react-i18next";
import { CourtPracticeCourtModel, courtePracticeSchema } from '../../models/CourtPractice';

const CourtCase = ({ courtPracticeCourts, setCourtPracticeCourts, errors, setErrors }) => {
    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Врста суда",
            accessor: "courtKind.name",
        },
        {
            Header: "Суд",
            accessor: "court.name",
        },
        {
            Header: "Судско одељење",
            accessor: "courtDepartment.name",
        },
        {
            accessor: "edit",
            disableSortBy: true,
            className: "two-buttons-column",
            Cell: ({ row }) => {
                return (
                    <>
                        <button
                            type="button"
                            className="btn btn-primary table-btn"
                            onClick={() => onEditClick(row.original, row.index)}
                        >
                            <FontAwesomeIcon icon={solid("pen-to-square")} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger table-btn"
                            onClick={() => removeElementFromTable(row.index)}
                        >
                            <FontAwesomeIcon icon={solid("times")} />
                        </button>
                    </>
                );
            },
        },
    ];


    const { t } = useTranslation();

    const courtKindClient = new CourtKindClient();
    const courtClient = new CourtClient();
    const courtDepartmentClient = new CourtDepartmentClient();

    const [courtCaseData, setCourtCaseData] = useState(new CourtPracticeCourtModel());

    const [courtKindList, setCourtKindList] = useState([]);
    const [courtList, setCourtList] = useState([]);
    const [courtDepartmentList, setCourtDepartmentList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [disableAddingOrEditing, setDisableAddingOrEditing] = useState(true);

    useEffect(() => {
        CourtKind();
    }, []);

    const CourtKind = async () => {
        var response = await courtKindClient.GetAllActiveCourtKinds(true);
        let handledResponse = HandlingResponse(response);
        let data = handledResponse.map((el) => {
            return { value: el.id, label: el.name, name: "courtKindId" };
        });
        setCourtKindList(data);
        setCourtList([]);
        setCourtDepartmentList([]);
    };

    const onChangeCourtKind = async (courtKindId, courtKindlabel, isEdit, rowData = {}) => {
        let courtData = new CourtPracticeCourtModel();

        var response = await courtClient.GetCourtsByCourtKind(courtKindId, true);
        var handledResponse = HandlingResponse(response);
        var courtListResponse = handledResponse.map((x) => {
            return { value: x.id, label: x.name, name: "courtId" };
        });
        setCourtList(courtListResponse);

        if (isEdit) {
            const court = courtListResponse?.filter((x) => x.value === rowData.court.id)[0];

            response = await courtDepartmentClient.GetCourtDepartmentsByCourt(rowData.court.id, true)
            handledResponse = HandlingResponse(response);
            var courtDepartmentListResponse = handledResponse.map((x) => {
                return { value: x.id, label: x.name, name: "courtDepartmentId" };
            });
            const courtDepartment = courtDepartmentListResponse?.filter((x) => x.value === rowData.courtDepartment?.id)[0];
            setCourtDepartmentList(courtDepartmentListResponse);

            courtData = {
                id: rowData.id,
                courtKind: { id: courtKindId, name: courtKindlabel },
                court: { id: court.value, name: court.label },
                courtDepartment: courtDepartment && { id: courtDepartment?.value, name: courtDepartment?.label }
            }
        }
        else {
            courtData = {
                courtKind: { id: courtKindId, name: courtKindlabel },
                court: { id: 0, name: "" }
            }
            setDisableAddingOrEditing(true);
            setCourtDepartmentList([]);
        }
        setCourtCaseData(courtData);
    };

    const onChangeCourt = async (courtId, courtlabel) => {
        setCourtCaseData({ ...courtCaseData, court: { id: courtId, name: courtlabel } });
        let response = await courtDepartmentClient.GetCourtDepartmentsByCourt(courtId, true)
        let handledResponse = HandlingResponse(response);
        let data = handledResponse.map((x) => {
            return { value: x.id, label: x.name, name: "courtDepartmentId" };
        });
        setCourtDepartmentList(data);
        setDisableAddingOrEditing(false);
    };

    const onChangeCourtDepartment = (courtDepartmentId, courtDepartmentlabel) => {
        if(courtDepartmentId)
            setCourtCaseData({ ...courtCaseData, courtDepartment: { id: courtDepartmentId, name: courtDepartmentlabel } });
        else setCourtCaseData({ ...courtCaseData, courtDepartment: null });
    }

    const addCourtTableRow = () => {
        const obj = { name: "courtPracticeCourts", value: [courtCaseData] };
        const result = handleSave(obj, [courtCaseData], errors, courtePracticeSchema, t);
        setErrors(result.errors);
        setCourtPracticeCourts([...courtPracticeCourts, courtCaseData]);
        setDisableAddingOrEditing(true);
        setCourtCaseData(new CourtPracticeCourtModel());
        setCourtList([]);
        setCourtDepartmentList([]);
    }

    const onEditClick = (rowData, id) => {
        setIsEdit(true);
        setDisableAddingOrEditing(false);
        setRowId(id);
        const courtKind = courtKindList?.filter((x) => x.value === rowData.courtKind.id)[0];
        onChangeCourtKind(courtKind.value, courtKind.label, true, rowData);
    }

    const saveEditedRow = () => {
        const obj = { name: "courtPracticeCourts", value: [courtCaseData] };
        const result = handleSave(obj, [courtCaseData], errors, courtePracticeSchema, t);
        setErrors(result.errors);
        let dataCopy = [...courtPracticeCourts];
        dataCopy[rowId] = courtCaseData;
        setCourtPracticeCourts(dataCopy);
        resetValues();
    }

    const resetValues = () => {
        setCourtCaseData(new CourtPracticeCourtModel());
        setCourtList([]);
        setCourtDepartmentList([]);
        setDisableAddingOrEditing(true);
        setIsEdit(false);
    }

    const removeElementFromTable = (id) => {
        const courtPracticeCourtsCopy = [...courtPracticeCourts];
        courtPracticeCourtsCopy.splice(id, 1);
        setCourtPracticeCourts(courtPracticeCourtsCopy);
    };

    return (
        <>
            <div className="form-group">
                <h5>Суд<span className="required-asterisk">*</span></h5>
                <FlexDiv className="flex-holder-between">
                    <div className="form-group max-width-sm-padding">
                        <label>Врста суда</label>
                        <Select
                            options={courtKindList}
                            onChange={(event) => onChangeCourtKind(event.value, event.label, false)}
                            value={courtKindList?.filter((x) => x.value === courtCaseData.courtKind.id)}
                        />
                    </div>
                    <div className="form-group max-width-sm-padding">
                        <label>Суд</label>
                        <Select
                            options={courtList}
                            onChange={(event) => onChangeCourt(event.value, event.label)}
                            value={courtList?.filter((x) => x.value === courtCaseData.court.id)}
                        />
                    </div>
                    <div className="form-group max-width-sm-padding">
                        <label>Судско одељење</label>
                        <Select
                            options={courtDepartmentList}
                            onChange={(event) => onChangeCourtDepartment(event?.value, event?.label)}
                            value={courtDepartmentList?.filter((x) => x.value === courtCaseData.courtDepartment?.id)}
                            isClearable
                        />
                    </div>
                </FlexDiv>
                {errors?.courtPracticeCourts && (
                    <div className="text-danger">{errors.courtPracticeCourts}</div>
                )}
                <div>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={isEdit ? saveEditedRow : addCourtTableRow}
                        disabled={disableAddingOrEditing}
                    >
                        {isEdit ? <FontAwesomeIcon icon={solid("pen")} /> : <FontAwesomeIcon icon={solid("plus")} />}
                        {isEdit ? "Сачувај" : "Додај суд"}
                    </button>
                    {isEdit && <button
                        type="button"
                        className="btn btn-primary"
                        style={{marginLeft: "5px"}}
                        onClick={resetValues}>Поништи</button>
                        }
                </div>
                {courtPracticeCourts.length > 0 && (
                    <Table
                        columns={columns}
                        data={courtPracticeCourts}
                        rememberFilter={false}
                        hiddenColumn={["id"]}
                        serverSide={false}
                    />
                )}
            </div>
        </>
    )
}

export default CourtCase;