import axios from "axios";
import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_NEWSLETTER;

class SettingsClient {

    async getAll(isBackground) {
        try {
          var response = await api.get(baseurl + "/Settings/GetAll", {isBackground: isBackground});
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateSetting(data, isBackground) {
        try {
          var response = await api.put(baseurl + "/Settings/Update", data, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }

    async GetRegistrationMail(isBackground) {
        try{
            var response = await api.get(baseurl + "/Settings/GetRegistrationMail", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async UpdateRegistrationMail(updateRegistrationMail, isBackground) {
        try{
            var response = await api.put(baseurl + "/Settings/UpdateRegistrationMail", updateRegistrationMail, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

}

export default SettingsClient;