import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
import SideModal from "../../../common/modal/SideModal.component";
import {
  GreenTransparentButton,
  RedTransparentButton,
} from "../../../global-styles/GlobalStyles.style";
import { AreaModel, CreateAreaModel, areaSchema } from "../../models/Area";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import AreaClient from "../../api-services/areas/area-client";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import { levelEnum } from "../../contexts/RenderDifferentComponentContext";
import { SelectColumnFilter } from "../../../common/table/filterHelpFunction.component";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import ButtonsForAddingActs from "../buttons-for-adding-acts/ButtonsForAddingActs.component";
import AreasMoving from "../areas-moving/AreasMoving.component";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole"

const Areas = ({
  areas,
  setAreas,
  handleCallback,
  handleActsCallback,
  handleGroupSubgroupCallback,
  level,
  tableName,
}) => {
  const { t } = useTranslation();

  const filter = {
    filterValue: "equals",
    selectOptions: [
      {
        value: undefined,
        label: "Сви",
      },
      {
        value: "false",
        label: "Неактиван",
      },
      {
        value: "true",
        label: "Активан",
      },
    ],
  };
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "orderBy",
      accessor: "orderBy",
    },
    {
      Header: () => {
        if (level === levelEnum.Area) return "Назив области";
        else if (level === levelEnum.Group) return "Назив групе";
        else return "Назив подгрупе";
      },
      accessor: "name",
      disableFilters: false,
      Cell: ({ row }) => {
        return (
          <Link
            onClick={(e) =>
              groupsByAreaId(row.original.id, row.original.name, false)
            }
          >
            {row.original.name}
          </Link>
        );
      },
    },
    {
      Header: "Статус",
      accessor: "active",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return <div>{row.original.active ? "Активан" : "Неактиван"}</div>;
      },
    },
    {
      accessor: "actions",
      disableSortBy: true,
      className: "four-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Измени"
              className="btn btn-primary table-btn"
              onClick={() => onEditAreaClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            {level != levelEnum.Area && (
              <button
                title="Премести"
                className="btn btn-primary table-btn"
                onClick={() => onMoveClick(row.original)}
                type="button"
              >
                <FontAwesomeIcon icon={solid("arrow-right-arrow-left")} />
              </button>
            )}
            <RenderOnRole roles={["PPP_Admin"]}>
              {row.original.active ? (
                <RedTransparentButton
                  title="Деактивирај"
                  type="button"
                  className="btn table-btn"
                  onClick={() =>
                    activateOrDeactivateArea(
                      row.original.id,
                      !row.original.active
                    )
                  }
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </RedTransparentButton>
              ) : (
                <GreenTransparentButton
                  title="Активирај"
                  type="button"
                  className="btn table-btn"
                  onClick={() =>
                    activateOrDeactivateArea(
                      row.original.id,
                      !row.original.active
                    )
                  }
                >
                  <FontAwesomeIcon icon={solid("check")} />
                </GreenTransparentButton>
              )}
            </RenderOnRole>
            <ButtonWithComfirmation
              disabled={row.original.active}
              className="btn btn-danger table-btn"
              contentText={`${
                level === levelEnum.Area
                  ? "Област"
                  : level === levelEnum.Group
                  ? "Група"
                  : "Подгрупа"
              } ће бити трајно обрисана.`}
              handleYesText={"Обриши"}
              onClick={() => deleteArea(row.original.id)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  const {
    handleChangeComponent,
    currentLocation,
    getPackageId,
    isCreateAct,
    isListEmpty,
    setIsListEmpty,
  } = UseRenderDifferentComponent();

  const areaClient = new AreaClient();
  const packageId = getPackageId();

  const [showCreateEditArea, setShowCreateEditArea] = useState(false);
  const [showMoveArea, setShowMoveArea] = useState(false);
  const [errors, setErrors] = useState({});

  const [isEdit, setIsEdit] = useState(false);
  const [areaObj, setAreaObj] = useState(new CreateAreaModel());

  const [dataForMoving, setDataForMoving] = useState({});

  const [buttonName, setButtonName] = useState("Креирај област");
  const [labelName, setLabelName] = useState("Назив области");
  const [modalTitleName, setModalTitleName] = useState("Креирај нову област");

  const [enableSort, setEnableSort] = useState(false);

  useEffect(() => {
    if (level === levelEnum.Area) {
      setButtonName("Креирај област");
      setLabelName("Назив области");
      setModalTitleName("област");
    } else if (level === levelEnum.Group) {
      setButtonName("Креирај групу");
      setLabelName("Назив групе");
      setModalTitleName("групу");
    } else {
      setButtonName("Креирај подгрупу");
      setLabelName("Назив подгрупе");
      setModalTitleName("подгрупу");
    }
    getCurrentAreas();
  }, []);

  const showModalForCreatingEditingArea = () => {
    setErrors({});
    setShowCreateEditArea(true);
  };

  const hideModalForCreatingEditingArea = () => setShowCreateEditArea(false);

  const showModalForMovingArea = () => setShowMoveArea(true);
  const hideModalMovingArea = () => {
    setShowMoveArea(false);
    setDataForMoving({});
  };

  const onEditAreaClick = (row) => {
    setIsEdit(true);
    setAreaObj(row);
    showModalForCreatingEditingArea();
  };

  const activateOrDeactivateArea = async (id, active) => {
    let response;
    if (active === false) {
      response = await areaClient.deactivateArea(id);
    } else {
      response = await areaClient.activateArea(id);
    }

    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      const updatedListOfActs = areas.map((area) => {
        if (area.id === id) {
          return { ...area, active };
        }
        return area;
      });
      setAreas(updatedListOfActs);
    }
  };

  const onMoveClick = async (row) => {
    setDataForMoving(row);
    showModalForMovingArea(true);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, areaObj, errors, areaSchema, t);
    setAreaObj(result.data);
    setErrors(result.errors);
  };

  const groupsByAreaId = async (id, name, isMoving) => {
    let response = await areaClient.getAreaChildrenById(id);
    let handledResponse = HandlingResponse(response);
    //situacija kada je oblast/grupa/podgrupa prazna
    if (handledResponse.length == []) {
      if (level === levelEnum.Area) {
        handleChangeComponent(levelEnum.Group, id, name);
        await handleCallback(handledResponse);
      } else if (level === levelEnum.Group) {
        handleChangeComponent(levelEnum.Subgroup, id, name);
        await handleCallback(handledResponse);
      } else {
        handleChangeComponent(levelEnum.Act, id, name);
        await handleActsCallback(handledResponse);
      }
    } else {
      let level = handledResponse.map((x) => {
        return x.level;
      });
      if (level && level.every((el) => el === levelEnum.Group)) {
        handleChangeComponent(levelEnum.Group, id, name);
        if (isMoving) handleGroupSubgroupCallback(handledResponse);
        else await handleCallback(handledResponse);
      } else if (level && level.every((el) => el === levelEnum.Subgroup)) {
        handleChangeComponent(levelEnum.Subgroup, id, name);
        if (isMoving) handleGroupSubgroupCallback(handledResponse);
        else await handleCallback(handledResponse);
      } else {
        level && level.every((el) => el === levelEnum.Act);
        handleChangeComponent(levelEnum.Act, id, name);
        await handleActsCallback(handledResponse);
      }
    }
  };

  const createOrEditArea = async () => {
    const obj = {
      ...areaObj,
      parentAreaId: level === levelEnum.Area ? null : currentLocation.id,
      packageId: packageId,
    };
    setAreaObj(obj);

    const result = validateForm(obj, areaSchema, t);
    if (result) setErrors(result);
    else {
      if (isEdit) {
        var response = await areaClient.updateArea(obj);
      } else {
        var response = await areaClient.createArea(obj);
      }
      var handledResponse = HandlingResponse(response);
      if (handledResponse != "Error") {
        hideModalForCreatingEditingArea();
        setAreaObj(new AreaModel());
        setIsEdit(false);
        getCurrentAreas();
      }
    }
  };

  const deleteArea = async (id) => {
    var response = await areaClient.deleteArea(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      getCurrentAreas();
    }
  };

  const getCurrentAreas = async () => {
    if (level === levelEnum.Area)
      var response = await areaClient.getFirstLevelAreasByPackageId(packageId);
    else response = await areaClient.getAreaChildrenById(currentLocation.id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse.length > 0) setIsListEmpty(false);
    else setIsListEmpty(true);
    handledResponse != "Error" && setAreas(handledResponse);
  };

  const enableSorting = () => {
    setEnableSort(true);
  };

  const saveSorting = async () => {
    var listOfAreaCopy = [...areas];
    listOfAreaCopy.forEach((x, i) => {
      x.orderBy = i;
    });
    let response = await areaClient.orderAreas(listOfAreaCopy);
    let handledResponse = HandlingResponse(response);
    handledResponse != "Error" && setAreas(handledResponse);

    setEnableSort(false);
  };

  const cancelSorting = async () => {
    setEnableSort(false);
    const packageId = getPackageId();
    if (level === levelEnum.Area)
      var response = await areaClient.getFirstLevelAreasByPackageId(packageId);
    else response = await areaClient.getAreaChildrenById(currentLocation.id);
    var handledResponse = HandlingResponse(response);
    handledResponse != "Error" && setAreas(handledResponse);
  };

  //poziva se da bi se prikazali akti koji su krenirani u praznoj oblasti/grupi/podgrupi
  const getActs = async () => {
    var response = await areaClient.getAreaChildrenById(currentLocation.id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != "Error") {
      await handleActsCallback(handledResponse);
    }
  };
  return (
    <>
      <div>
        <div>
          <div>
            {!isCreateAct && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={showModalForCreatingEditingArea}
                >
                  {buttonName}
                </button>
                {isListEmpty && !(level === levelEnum.Area) && (
                  <ButtonsForAddingActs handleCallback={getActs} />
                )}
              </>
            )}
          </div>
          {areas?.length > 0 && (
            <div className="text-right">
              {enableSort ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginRight: "5px" }}
                    onClick={cancelSorting}
                  >
                    Одустани од сортирања
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveSorting}
                  >
                    Сачувај сортирање
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={enableSorting}
                >
                  Омогући сортирање
                </button>
              )}
            </div>
          )}
        </div>
        <br />
        {areas?.length > 0 && (
          <Table
            columns={columns}
            rememberFilter={true}
            tableName={tableName}
            hiddenColumn={["id", "orderBy"]}
            data={areas}
            setData={setAreas}
            serverSide={false}
            rowSortable={enableSort}
          />
        )}
      </div>
      <SideModal
        show={showCreateEditArea}
        handleClose={hideModalForCreatingEditingArea}
        title={
          isEdit ? `Измени ${modalTitleName}` : `Креирај ${modalTitleName}`
        }
      >
        <div>
          <label>
            {labelName}
            <span className="required-asterisk">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={areaObj.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <br />
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={createOrEditArea}
        >
          Сачувај
        </button>
      </SideModal>
      <SideModal
        show={showMoveArea}
        handleClose={hideModalMovingArea}
        title={
          level === levelEnum.Group
            ? "Премести у другу област"
            : "Премести у другу групу"
        }
      >
        {showMoveArea ? (
          <AreasMoving
            level={level}
            dataForMoving={dataForMoving}
            handleCallback={groupsByAreaId}
            hideModal={hideModalMovingArea}
          />
        ) : (
          <></>
        )}
      </SideModal>
    </>
  );
};

export default Areas;
