import { env } from "../../../env";
import api from "../../../api";
import axios from "axios";

var baseurl = env.REACT_APP_API_URL_REG;

class AttachmentClient {

  async GetAttachmentHtml(id, name, isBackground){
    try{
        var response = await api.get(baseurl + "/Attachment/GetAttachmentContent?lawActId=" + id + "&attachmentName=" + name, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
  if (err.response.status === 400) return err.response.data;
  else return err.response;
}
}

async UpdateAttachmentFile(data, isBackground) {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        var response = await api.put(baseurl + "/Attachment/UpdateAttachmentContent", data, {
            headers: headers,
            isBackground: isBackground
        });
        return response.data;
    }
    catch (err) {
  if (err.response.status === 400) return err.response.data;
  else return err.response;
}
}
}

export default AttachmentClient;