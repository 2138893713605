import { createContext } from "react";
import DataClient from "../../MML/api-services/data/data-client";
import LawAdActClient from "../../SLGL/api-services/law-ad-act/law-ad-act-client";
import StaticPagesClient from "../../FRONTPAGE/api-services/static-pages/static-pages";
import PreviewNewsClient from "../../FRONTPAGE/api-services/news/preview/preview";
import CourtPracticeClient from "../../VASKE/api-services/cases/cases-client";
import LawActClient from "../../REGISTRIES/api-services/law-act/law-act-client";
import InternalActClient from "../../PPP/api-services/internal-act/internal-act-client";
import AttachmentClientReg from "../../REGISTRIES/api-services/attachment/attachment-client";
import DataAttachmentClient from "../../MML/api-services/data-attachment/data-attachment-client";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import NewsAttachmentClient from "../../FRONTPAGE/api-services/news/news-attachment/news-attachment-client";
import StaticPageAttachmentClient from "../../FRONTPAGE/api-services/static-page-attachment/static-page-attachment-client";
import ArticleClient from "../../PENG/api-services/article/article-client";
import RegulationsLawActClient from "../../REGULATIONS/api-services/law-act/law-act-client";
import RegulationsAttachmentClient from "../../REGULATIONS/api-services/attachment/attachment-client"

const TextEditorContext = createContext({})

export const TextEditorProvider = ({ children }) => {

    const dataClient = new DataClient();
    const lawAdActClient = new LawAdActClient();
    const staticPageClient = new StaticPagesClient();
    const newsClient = new PreviewNewsClient();
    const courtPracticeClient = new CourtPracticeClient();
    const lawActClient = new LawActClient();
    const internalActClient = new InternalActClient();
    const attachmentClientReg = new AttachmentClientReg();
    const dataAttachmentClient = new DataAttachmentClient();
    const newsAttachmentClient = new NewsAttachmentClient();
    const staticPageAttachmentClient = new StaticPageAttachmentClient();
    const articleClient = new ArticleClient();
    const regulationsLawActClient = new RegulationsLawActClient();
    const regulationsAttachmentClient = new RegulationsAttachmentClient();

    const getHtml = async (id, path, mainFile, attachmentGuid, attachmentName) => {
        var response;
        if (mainFile?.includes("true")) {
            if (path?.includes("mml")) {
                response = await dataClient.GetDataHtml(id, false);
            }
            else if (path?.includes("slgl")) {
                response = await lawAdActClient.getLawAdActHtml(id);
            }
            else if (path?.includes("staticPage")) {
                response = await staticPageClient.GetStaticPageHtml(id);
            }
            else if (path?.includes("frontpage")) {
                response = await newsClient.GetNewsHtml(id);
            }
            else if (path?.includes("sp")) {
                response = await courtPracticeClient.GetCourtPracticeHtml(id);
            }
            else if (path?.includes("registries")) {
                response = await lawActClient.GetLawActHtml(id);
            }
            else if (path?.includes("ppp")) {
                response = await internalActClient.getInternalActHtml(id);
            }
            else if (path?.includes("peng")) {
                response = await articleClient.getArticleText(id);
            }
            else if (path?.includes("regulations")) {
                response = await regulationsLawActClient.GetLawActHtml(id);
            }
        }
        else {
            if (path?.includes("registries")) {
                response = await attachmentClientReg.GetAttachmentHtml(id, attachmentName);
            }
            else if (path?.includes("mml")) {
                response = await dataAttachmentClient.GetAttachmentHtml(id, attachmentName);
            }
            else if (path?.includes("frontpage/staticPages")) {
                response = await staticPageAttachmentClient.GetAttachmentHtml(id, attachmentName);
            }
            else if (path?.includes("frontpage")) {
                response = await newsAttachmentClient.GetAttachmentHtml(id, attachmentName);
            }
            else if (path?.includes("regulations")) {
                response = await regulationsAttachmentClient.GetAttachmentHtml(id, attachmentName);
            }
        }

        return response;
    }

    const updateHtmlFile = async (formData, path, mainFile, attachmentGuid) => {
        var response;
        if (mainFile?.includes("true")) {
            if (path.includes("mml")) {
                response = await dataClient.UpdateDataFile(formData);
            }
            else if (path.includes("slgl")) {
                response = await lawAdActClient.UpdateLawAdActFile(formData);
            }
            else if (path.includes("staticPage")) {
                response = await staticPageClient.UpdateStaticPageFile(formData);
            }
            else if (path.includes("frontpage")) {
                response = await newsClient.UpdateNewsFile(formData);
            }
            else if (path.includes("sp")) {
                response = await courtPracticeClient.UpdateCourtPracticeFile(formData);
            }
            else if (path.includes("registries")) {
                response = await lawActClient.UpdateLawActFile(formData);
            }
            else if (path.includes("ppp")) {
                response = await internalActClient.UpdateInternalActHtml(formData);
            }
            else if (path.includes("regulations")) {
                response = await regulationsLawActClient.UpdateLawActFile(formData);
            }
        }
        else {
            if (path.includes("registries")) {
                response = await attachmentClientReg.UpdateAttachmentFile(formData);
            }
            else if (path.includes("mml")) {
                response = await dataAttachmentClient.UpdateAttachmentFile(formData);
            }
            else if (path?.includes("frontpage/staticPages")) {
                response = await staticPageAttachmentClient.UpdateAttachmentFile(formData);
            }
            else if (path?.includes("frontpage")) {
                response = await newsAttachmentClient.UpdateAttachmentFile(formData);
            }
            else if (path?.includes("regulations")) {
                response = await regulationsAttachmentClient.UpdateAttachmentFile(formData);
            }
        }

        return response;
    }

    const cancelUpdate = async (id, path) => {
        if (path.includes("ppp")) {
            if (id) {
                var response = await internalActClient.cancelUpdate(id);
                HandlingResponse(response)
            }
        }
    }

    return (
        <TextEditorContext.Provider value={{ getHtml, updateHtmlFile, cancelUpdate }}>
            {children}
        </TextEditorContext.Provider>
    )
}

export default TextEditorContext;