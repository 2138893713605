import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CategoryClient from "../../api-services/category/category-client";
import SideModal from "../../../common/modal/SideModal.component";
import CreateCategory from "../../components/create-category/CreateCategory";

const Category = () => {
    const tableName="NL_CATEGORY";
    const client = new CategoryClient();

    const columns = [
        {
          Header: "Назив",
          accessor: "name",
        },
        {
            Header: "Опис",
            accessor: "description"
        },
        {
          Header: "Тежина",
          accessor: "weight",
        },
        {
          Header: "Активна",
          accessor: "active",
          Cell: ({ row }) => {
            return (
              row.original.active && <FontAwesomeIcon icon={solid("check")} />
            );
          },
        },
        {
            Header: "Видљива",
            accessor: "visible",
            Cell: ({ row }) => {
              return (
                row.original.visible && <FontAwesomeIcon icon={solid("check")} />
              );
            },
          },
        {
          accessor: "edit",
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              <button
                className="btn btn-primary table-btn"
                onClick={() => onEditClick(row.original.id)}
              >
                <FontAwesomeIcon icon={solid("pen-to-square")} />
              </button>
            );
          },
        },
      ];

      const [tableData, setTableData] = useState([]);
      const [rowData, setRowData] = useState({
        id: 0,
        name: "",
        description: "",
        weight: 0,
        packageGuid: "",
        active: false,
        visible: false,
        forGuests: false
      })
      const [isEdit, setIsEdit] = useState(false);
      const [show, setShow] = useState(false);
      const [title, setTitle] = useState("");
    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);

      const getCategories = async () => {
        let response = await client.GetCategories();
        let result = HandlingResponse(response);
        setTableData(result);
      };

      useEffect(() => {
        getCategories();
      }, [])

      const onCreateClick = () => {
        setIsEdit(false);
        setRowData({
          id: 0,
          name: "",
          description: "",
          weight: 0,
          packageGuid: "",
          active: false,
          visible: false,
          forGuests: false
        })
        showModal();
        setTitle("Креирање категорије");
      };
    
    const onEditClick = async (id) => {
        var response = await client.GetCategoryById(id);
        setIsEdit(true);
        setRowData(response.data);
        showModal();
        setTitle("Измена категорије");
    };

    const handleAddingDataToTable = (childData) => {
      hideModal();
      if (!isEdit) setTableData([...tableData, childData]);
      else {
        let categoriesCopy = [...tableData];
        let index = categoriesCopy.findIndex((x) => x.id === childData.id);
        categoriesCopy[index] = childData;
        setTableData(categoriesCopy);
      }
    };

    return (
      <>
        <h5 className="col-md-2">Категорије</h5>
        <button
          onClick={() => {
            onCreateClick();
          }}
          style={{ marginTop: "10px", marginBottom: "20px" }}
          className="btn btn-primary col-md-1"
        >
          Додај категорију
        </button>
        <Table
          columns={columns}
          data={tableData}
          rememberFilter={true}
          tableName={tableName}
          hiddenColumn={["id"]}
          serverSide={false}
        />
        <SideModal show={show} handleClose={hideModal} title={title}>
          <CreateCategory
            row={rowData}
            isEdit={isEdit}
            handleCallback={() => {
              hideModal();
              getCategories();
            }}
          />
        </SideModal>
      </>
    );
}

export default Category;