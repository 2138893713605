import React, { useEffect, useState, useRef } from "react";
import SideModal from "../../../common/modal/SideModal.component";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useUpdateLawAct from "../../hooks/useUpdateLawAct";
import { SelectColumnFilter } from "../../../common/table/filterHelpFunction.component";
import { databaseList } from "../../models/PreviewLawAct";
import CreateBasicGlobalActReference from "../create-basic-global-act-reference/CreateBasicGlobalActReference.component";
import { format } from "date-fns";
import { DeleteButton } from "../../../common/multiple-files/multipleFiles.style";
import Select from "../../../common/custom-select-list/CustomSelectList.component";

const TabActReference = () => {
  const tableName = "REGULATIONS_ACT_REFERENCE";

  const model = "lawActReferenceModel";
  const { data, setData, handleChangeInput } = useUpdateLawAct();

  const [listOfActReferenceTypes, setListOfActReferenceTypes] = useState([]);
  const [actReferenceType, setActReferenceType] = useState(null);

  const [listOfDatabase, setListOfDatabase] = useState([
    { value: undefined, label: "Сви" },
  ]);

  const databaseFilter = {
    filterValue: "equals",
    selectOptions: listOfDatabase,
  };

  const [showActReference, setShowActReference] = useState(false);

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "actReferenceTypeGuid",
      accessor: "actReferenceTypeGuid",
    },
    {
      Header: "Тип везе",
      accessor: "actReferenceTypeName",
      className: "table-select-list-width",
    },
    {
      Header: "Пакет",
      accessor: "packageName",
      className: "table-select-list-width",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: databaseFilter,
      Cell: ({ row }) => {
        return databaseList
          .filter((x) => {
            return row.original.packageName === x.enumVal;
          })
          .map((x) => {
            return x.label;
          })[0];
      },
    },
    {
      Header: "refActGuid",
      accessor: "refActGuid",
    },
    {
      Header: "fkRegulationsAct",
      accessor: "fkRegulationsAct",
    },
    {
      Header: "Назив акта",
      accessor: "actName",
      disableFilters: false,
    },
    {
      Header: "Додатне информације",
      accessor: "additionalInfo",
      disableFilters: false,
      Cell: ({ row }) => {
        let date = new Date(row.original.additionalInfo);
        return !row.original?.packageName && !row.original?.actName ? (
          row.original?.externalUrlName + ":" + row.original?.externalUrlValue
        ) : !row.original.additionalInfo?.includes("0001") ? (
          !isNaN(date) ? (
            <div>
              {row.original.additionalInfo ? format(date, "dd.MM.yyyy.") : ""}
            </div>
          ) : (
            row.original.additionalInfo || ""
          )
        ) : (
          ""
        );
      },
    },
    {
      Header: "Важећи акт",
      accessor: "isActive",
      Cell: ({ row }) => {
        return (
          row.original.isActive && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: "sort",
      accessor: "sort",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      sticky: "right",
      Cell: ({ row }) => {
        return (
          <>
            <DeleteButton
              type="button"
              onClick={() => {
                deleteActReference(row.index);
              }}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        );
      },
    },
  ];

  const [referencesByType, setReferencesByType] = useState({
    actReferenceTypeGuid: "",
    actReferenceTypeName: "",
    globalActReferences: [],
  });

  useEffect(() => {
    const actRefType = getActReferencesTypes();

    if (actRefType) {
      setListOfActReferenceTypes(actRefType);
      createReferenceListByType(actRefType[0]?.value);
    }
    var database = databaseList.map((item) => {
      return { value: item.enumVal.toString(), label: item.label };
    });
    setListOfDatabase([...listOfDatabase, ...database]);
  }, []);

  useEffect(() => {
    var globalActReferenceTypeCopy =
      data?.lawActReferenceModel?.globalActReferencesByType;
    var index = globalActReferenceTypeCopy?.findIndex(
      (item) => item.actReferenceTypeGuid === actReferenceType
    );
    if (index != undefined && index !== -1) {
      const newData = { ...data };
      newData.lawActReferenceModel.globalActReferencesByType[index] =
        referencesByType;

      setData(newData);
    }
  }, [referencesByType]);

  const showModalForActReference = () => setShowActReference(true);
  const hideModalForActReference = () => setShowActReference(false);

  //pomocna akcija koja vraca niz akata po tipu veze i index
  const findElementInListByIndex = (typeGuid) => {
    if (!typeGuid) typeGuid = actReferenceType;
    var globalActReferenceTypeCopy =
      data?.lawActReferenceModel?.globalActReferencesByType;
    var index = globalActReferenceTypeCopy?.findIndex(
      (item) => item.actReferenceTypeGuid === typeGuid
    );

    return { globalActReferenceTypeCopy, index };
  };

  const deleteActReference = (id) => {
    const referencesByTypeCopy = [...referencesByType.globalActReferences];
    referencesByTypeCopy.splice(id, 1);
    setReferencesByType({
      ...referencesByType,
      globalActReferences: referencesByTypeCopy,
    });

    var globalActReferenceTypeCopy =
      data?.lawActReferenceModel?.globalActReferencesByType;
    var index = globalActReferenceTypeCopy?.findIndex(
      (item) => item.actReferenceTypeGuid === actReferenceType
    );
    if (index != undefined && index !== -1) {
      const newData = { ...data };
      newData.lawActReferenceModel.globalActReferencesByType[index] = {
        ...newData.lawActReferenceModel.globalActReferencesByType[index],
        globalActReferences: referencesByTypeCopy,
      };
      setData(newData);
    }
  };

  const handleAddingActReferenceToTable = (childData, type, close) => {
    close && hideModalForActReference();
    const obj = findElementInListByIndex(type.typeGuid);
    if (obj.index != undefined && obj.index !== -1) {
      const newData = { ...data };
      newData.lawActReferenceModel.globalActReferencesByType[obj.index] = {
        ...newData.lawActReferenceModel.globalActReferencesByType[obj.index],
        globalActReferences: [
          ...obj.globalActReferenceTypeCopy[obj.index].globalActReferences,
          ...childData,
        ],
      };
      setData(newData);
      type.typeGuid === actReferenceType &&
        setReferencesByType({
          ...referencesByType,
          globalActReferences: [
            ...referencesByType.globalActReferences,
            ...childData,
          ],
        });
    } else {
      //ako u listi ne postoji akt sa novododatim tipom
      setListOfActReferenceTypes([
        ...listOfActReferenceTypes,
        { value: type.typeGuid, label: type.typeName },
      ]);
      const newData = { ...data };
      newData.lawActReferenceModel.globalActReferencesByType = [
        ...newData.lawActReferenceModel.globalActReferencesByType,
        {
          actReferenceTypeGuid: type.typeGuid,
          actReferenceTypeName: type.typeName,
          globalActReferences: childData,
        },
      ];
      setData(newData);
    }
  };

  const getActReferencesTypes = () => {
    const listOfTypes =
      data?.lawActReferenceModel?.globalActReferencesByType?.map((item) => ({
        value: item.actReferenceTypeGuid,
        label: item.actReferenceTypeName,
      }));

    return listOfTypes;
  };

  const createReferenceListByType = (typeGuid) => {
    //prilikom svake promene tipa, za slucaj da je izvrseno sortiranje iskopiramo trenutno prikazanu listu u postojecu
    if (actReferenceType)
      var index =
        data?.lawActReferenceModel?.globalActReferencesByType?.findIndex(
          (item) => item.actReferenceTypeGuid === actReferenceType
        );
    if (index != undefined && index !== -1) {
      const newData = { ...data };
      newData.lawActReferenceModel.globalActReferencesByType[index] = {
        ...newData.lawActReferenceModel.globalActReferencesByType[index],
        globalActReferences: referencesByType.globalActReferences.map(
          (x, i) => {
            return {
              ...x,
              sort: i,
            };
          }
        ),
      };
      setData(newData);
    }

    var refsByType =
      data.lawActReferenceModel?.globalActReferencesByType?.filter(
        (x) => x.actReferenceTypeGuid === typeGuid
      );
    refsByType?.length > 0
      ? setReferencesByType(refsByType[0])
      : setReferencesByType({
          actReferenceTypeGuid: "",
          actReferenceTypeName: "",
          globalActReferences: [],
        });
    setActReferenceType(typeGuid);
  };

  return (
    <>
      <div>
        <h5 className="inline-element">Везе ка другим актима</h5>
        <button
          onClick={showModalForActReference}
          type="button"
          className="btn btn-primary"
        >
          Додај
        </button>
      </div>
      <br />
      <div>
        <label>Тип везе</label>
        <Select
          options={listOfActReferenceTypes}
          onChange={(event) => {
            setActReferenceType(event.value);
            createReferenceListByType(event.value);
          }}
          value={listOfActReferenceTypes.filter(
            (x) => x.value === actReferenceType
          )}
        />
      </div>
      <Table
        columns={columns}
        data={referencesByType?.globalActReferences}
        setData={(value) =>
          setReferencesByType((prevFormData) => ({
            ...prevFormData,
            globalActReferences: value,
          }))
        }
        rememberFilter={true}
        tableName={tableName}
        hiddenColumn={[
          "id",
          "actReferenceTypeGuid",
          "refActGuid",
          "fkRegulationsAct",
          "sort",
        ]}
        serverSide={false}
        rowSortable={true}
      />

      <div>
        <label>Правни претходник - HTML</label>
        <textarea
          className="form-control"
          type="text"
          name="predecessor"
          data-model={model}
          id="predecessor"
          value={data.lawActReferenceModel.predecessor}
          onChange={handleChangeInput}
        />
      </div>

      <SideModal
        show={showActReference}
        handleClose={hideModalForActReference}
        title={"Додавање везе ка другим актима"}
      >
        <CreateBasicGlobalActReference
          handleCallback={handleAddingActReferenceToTable}
        />
      </SideModal>
    </>
  );
};

export default TabActReference;
