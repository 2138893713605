import styled from 'styled-components';

export const TreeContainer = styled.div`
    overflow-y: auto;
    height: 680px;

    .visible-acts {
        border-left: 1px solid lightgray;
    }

    li {
        list-style-type: none;
    }

    .active-act-background {
        font-weight: bold;
        font-style: italic;
    }
`

export const TreeLabel = styled.label`
    margin-bottom: 0px;
    font-weight: 400;
    cursor: pointer;

    span {
        margin-left: 5px;
    }

    :hover {
        background-color: #e8e8e9;
        border-radius: 5px;
    }
`