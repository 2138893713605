import api from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_MML;

class DataAttachmentClient {
    async GetAttachmentByGuid(guid, isBackground) {
        try {
            var response = await api.get(baseurl + "/DataAttachment/GetAttachmentByGuid?guid=" + guid, { responseType: 'blob' })
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async GetAttachmentHtml(id, name, isBackground){
        try{
            var response = await api.get(baseurl + "/DataAttachment/GetAttachmentContent?dataId=" + id + "&attachmentName=" + name, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
    
    async UpdateAttachmentFile(data, isBackground) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        try {
            var response = await api.put(baseurl + "/DataAttachment/UpdateAttachmentContent", data, {
                headers: headers,
                isBackground: isBackground
            });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

}

export default DataAttachmentClient;