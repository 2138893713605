import React from 'react'
import HierarchyComponentRender from '../../components/hierarchy-component-render/HierarchyComponentRender.component';

const Index = () => {
  
  return (
    <div>
        <HierarchyComponentRender />
    </div>
  )
}

export default Index
