import parse from "html-react-parser";
import { useState } from "react";
import SettingsClient from "../../api-services/settings/settings-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useEffect } from "react";

const RegistrationMail = () => {
  const [updateRegistrationMail, setUpdateRegistrationMail] = useState({
    registrationEmail: "",
  });
  const client = new SettingsClient();

  const getRegistrationMail = async () => {
    let response = await client.GetRegistrationMail();
    let result = HandlingResponse(response);
    setUpdateRegistrationMail({
      ...updateRegistrationMail,
      registrationEmail: result,
    });
  };

  useEffect(() => {
    getRegistrationMail();
  }, []);

  const UpdateRegistrationMail = async () => {
    var response = await client.UpdateRegistrationMail(updateRegistrationMail);
    if (response !== "Error") {
      let handledResponse = HandlingResponse(response);
      setUpdateRegistrationMail({
        ...updateRegistrationMail,
        registrationEmail: handledResponse,
      });
    }
  };

  return (
    <>
      <div>{parse(String(updateRegistrationMail.registrationEmail))}</div>
      <textarea
        className="form-control form-group"
        type="text"
        name="registrationEmail"
        value={updateRegistrationMail.registrationEmail}
        onChange={(event) =>
          setUpdateRegistrationMail({
            ...updateRegistrationMail,
            registrationEmail: event.target.value,
          })
        }
        style={{ minHeight: "200px" }}
      />
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={UpdateRegistrationMail}
          type="button"
        >
          Сачувај
        </button>
      </div>
    </>
  );
};

export default RegistrationMail;
