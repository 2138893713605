import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../common/table/Table.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import SettingsClient from "../../api-services/settings/settings-client";
import SideModal from "../../../common/modal/SideModal.component";
import EditSettingsNL from "../../components/settings/EditSettingsNL";

const SettingsNL = () => {
    const tableName = 'NL_SETTINGS';
    const columns = [
        {
          Header: "Id",
          accessor: "id",
        },
        {
          Header: "Назив",
          accessor: "parameterKey",
        },
        {
          Header: "Вредност",
          accessor: "parameterValue",
        },
        {
          Header: "Опис",
          accessor: "description",
        },
        {
          accessor: "edit",
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              <button
                title="Измена"
                className="btn btn-primary table-btn"
                onClick={() => onEditClick(row.original)}
                type="button"
              >
                <FontAwesomeIcon icon={solid("edit")} />
              </button>
            );
          },
        },
      ];

    const client = new SettingsClient();

    const [settingsList, setSettingsList] = useState([]);
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({
        id: 0,
        parameterKey: "",
        parameterValue: "",
        description: "",
    });

    useEffect(() => {
        getAll();
    }, []);

    const showModal = () => setShow(true);
    const hideModal = () => {
        setShow(false);
        setRowData({
        id: 0,
        parameterKey: "",
        parameterValue: "",
        description: "",});
    }

    const getAll = async () => {
        let response = await client.getAll();
        let handledResponse = HandlingResponse(response);
        handledResponse && setSettingsList(handledResponse);
    };

    const onEditClick = async (data) => {
        setRowData(data);
        showModal();
    };

    const handleAddingDataToTable = (childData) => {
        hideModal();
        let settingsListCopy = [...settingsList];
        let index = settingsListCopy.findIndex((x) => x.id === childData.id);
        settingsListCopy[index] = childData;
        setSettingsList(settingsListCopy);
    };

    return (
        <>
          <div>
            <h5 className="col-md-2">Подешавања</h5>
              {settingsList.length > 0 && (
                <Table
                    columns={columns}
                    data={settingsList}
                    rememberFilter={true}
                    tableName={tableName}
                    hiddenColumn={["id"]}
                    serverSide={false}
                />
              )}
          </div>
          <SideModal
              show={show}
              handleClose={hideModal}
              title={"Измена параметара"}
          >
            <EditSettingsNL
              row={rowData}
              handleCallback={handleAddingDataToTable}
            />
          </SideModal>
        </>
    )
}

export default SettingsNL;