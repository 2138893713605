import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PPP;

class InternalActClient {
    async AddInternalAct(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/InternalAct/AddInternalAct", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateInternalAct(data, isBackground) {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        try {
          var response = await api.put(baseurl + "/InternalAct/UpdateInternalAct", data, {
            headers: headers
          }, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
  
      async getInternalAct(id, isBackground) {
        try {
          var response = await api.get(baseurl + "/InternalAct/GetInternalAct?actId=" + id, {
            isBackground: isBackground,
          });
          return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }

      async cancelUpdate(actId, isBackground) {
        try {
            var response = await api.put(baseurl + "/InternalAct/CancelUpdateAct/" + actId, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getInternalActHtml(id, isBackground) {
      try {
        var response = await api.get(baseurl + "/InternalAct/GetInternalActHtml?id=" + id, { isBackground: isBackground });
        return response.data;
  
      }
      catch (err) {
        if (err.response.status === 400) return err.response.data;
        else return err.response;
      }
    }

    async UpdateInternalActHtml(data, isBackground) {
      const headers = {
          'Content-Type': 'multipart/form-data',
      };
      try {
          var response = await api.put(baseurl + "/InternalAct/UpdateInternalActFile", data, {
              headers: headers,
              isBackground: isBackground
          });
          return response.data;
      }
      catch (err) {
    if (err.response.status === 400) return err.response.data;
    else return err.response;
  }
  }
}

export default InternalActClient;