import React, { useState, useEffect, useRef } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Collapse from "../../../common/collapse/Collapse.component";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import {
  nextHierarchyMark,
  valueOfSelectedHierarchyMark,
} from "../../../REGISTRIES/common/CommonFunctions";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import {
  AddLawActModel,
  AddLawActSchema,
  hierarchyMarkPositionEnum,
} from "../../models/AddLawAct";
import LawActClient from "../../api-services/law-act/law-act-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn } from "../../../common/multiple-files/multipleFiles.style";

const CreateAct = ({ lawActInfo, createSubarea, hide, handleCallback }) => {
  const client = new LawActClient();
  const { t } = useTranslation();

  const [lawAct, setLawAct] = useState(new AddLawActModel());
  const [clicked, setClicked] = useState("1");
  const [nested, setNested] = useState("1.1");
  const [next, setNext] = useState("2");
  const [listOfLockedActs, setListOfLockedActs] = useState([]);

  const [htmlFile, setHtmlFile] = useState(null);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [visible, setVisible] = useState(false);

  const [errors, setErrors] = useState({});

  const htmlInputRef = useRef(null);

  useEffect(() => {
    getListOfLockedActs();
  }, []);

  useEffect(() => {
    if (lawActInfo.hierarchyMark) {
      setClicked(lawActInfo.hierarchyMark);
      setNested(lawActInfo.hierarchyMark + ".1");
      setNext(nextHierarchyMark(lawActInfo));
    }

    var selected = valueOfSelectedHierarchyMark(
      lawAct.shiftLawAct.hierarchyMarkPosition,
      lawActInfo
    );
    setLawAct({
      ...lawAct,
      shiftLawAct: {
        areaId: lawActInfo.areaId,
        hierarchyMarkPosition: lawAct.shiftLawAct.hierarchyMarkPosition,
        createSubarea: createSubarea,
        parentId: lawActInfo.id,
        selectedHierarchyMark: selected,
      },
    });
  }, [lawActInfo, lawAct.shiftLawAct.hierarchyMarkPosition]);
  
  useEffect(() => {
    if (htmlFile)
      setLawAct({
        ...lawAct,
        file: htmlFile,
        files: attachmentFiles,
        pictures: photoFiles,
      });
  }, [htmlFile]);

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, lawAct, errors, AddLawActSchema, t);
    setLawAct(result.data);
    setErrors(result.errors);
  };

  const getListOfLockedActs = async () => {
    var response = await client.getLockedLawActsByAreaId(lawActInfo.areaId);
    var handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != 'Error') setListOfLockedActs(handledResponse);
  };

  const createAct = async (event) => {
    event.preventDefault();

    const errorsResult = validateForm(lawAct, AddLawActSchema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      var formData = new FormData();

      serializeObjectIntoFormData(formData, lawAct);

      htmlFile?.guid
        ? formData.append("file.guid", htmlFile?.guid)
        : formData.append("file.file", htmlFile);

      for (let i = 0; i < photoFiles.length; i++) {
        if (photoFiles[i].guid)
          formData.append("pictures[" + i + "].guid", photoFiles[i].guid);
        else formData.append("pictures[" + i + "].file", photoFiles[i]);

        formData.append("pictures[" + i + "].name", photoFiles[i].name);
      }
      for (let i = 0; i < attachmentFiles.length; i++) {
        if (attachmentFiles[i].guid)
          formData.append("files[" + i + "].guid", attachmentFiles[i].guid);
        else formData.append("files[" + i + "].file", attachmentFiles[i]);
        formData.append("files[" + i + "].name", attachmentFiles[i].name);
      }

      let response = await client.addLawAct(formData);
      let res = HandlingResponse(response);
      hide();
      handleCallback(lawAct.shiftLawAct.areaId);
    }
  };

  useEffect(() => {
    const obj = { name: "file", value: htmlFile };
    const result = handleSave(obj, lawAct.file, errors, AddLawActSchema, t);
    setErrors(result.errors);
  }, [htmlFile]);

  return (
    <form onSubmit={createAct} id="createActFrom">
      <div>
        <label>
          Назив<span className="required-asterisk">*</span>
        </label>
        <input
          className="form-control"
          type="text"
          name="name"
          value={lawAct.name}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.name && <div className="text-danger">{errors.name}</div>}
        <br />
        {createSubarea ? (
          <></>
        ) : (
          <>
            <div>
              {!htmlFile && (
                <>
                <NewFileBtn onClick={handleUploadHtmlClick} type="button">
                  <FontAwesomeIcon icon={solid("plus")} />
                  Додај ХТМЛ фајл
                </NewFileBtn>
                или
                </>
              )}
              <input
                type="file"
                ref={htmlInputRef}
                onChange={(event) => {
                  setHtmlFile(event.target.files[0]);
                }}
                hidden
                accept=".html, .htm"
                name="file"
              />
            <SubmitFile
              mainFile={htmlFile}
              setMainFile={setHtmlFile}
              photoFiles={photoFiles}
              setPhotoFiles={setPhotoFiles}
              attachmentFiles={attachmentFiles}
              setAttachmentFiles={setAttachmentFiles}
              ></SubmitFile>
        </div>
            {errors?.file && <div className="text-danger">{errors.file}</div>}
          </>
        )}
      </div>
      <br />
      <div>
        <label>Позиција акта</label>
        <div className="radio">
          <label>
            <input
              type="radio"
              value={hierarchyMarkPositionEnum.Clicked}
              checked={
                lawAct.shiftLawAct.hierarchyMarkPosition ==
                hierarchyMarkPositionEnum.Clicked
              }
              onChange={(e) =>
                setLawAct({
                  ...lawAct,
                  shiftLawAct: {
                    ...lawAct.shiftLawAct,
                    hierarchyMarkPosition: e.target.value,
                  },
                })
              }
              name="hierarchyMarkPosition"
            />
            {" " + clicked}
          </label>
        </div>
        <div className="radio radio-margin">
          <label>
            <input
              disabled={
                (lawActInfo.isSubarea == true ? true : false) ||
                lawActInfo.addingInEmptyArea
              }
              type="radio"
              value={hierarchyMarkPositionEnum.Nested}
              checked={
                lawAct.shiftLawAct.hierarchyMarkPosition ==
                hierarchyMarkPositionEnum.Nested
              }
              onChange={(e) =>
                setLawAct({
                  ...lawAct,
                  shiftLawAct: {
                    ...lawAct.shiftLawAct,
                    hierarchyMarkPosition: e.target.value,
                  },
                })
              }
              name="hierarchyMarkPosition"
            />
            {" " + nested}
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              disabled={
                ((lawActInfo.isSubarea == true ? true : false) &&
                  (lawActInfo.children.length === 0 ? true : false)) ||
                lawActInfo.addingInEmptyArea
              }
              type="radio"
              value={hierarchyMarkPositionEnum.Next}
              checked={
                lawAct.shiftLawAct.hierarchyMarkPosition ==
                hierarchyMarkPositionEnum.Next
              }
              onChange={(e) =>
                setLawAct({
                  ...lawAct,
                  shiftLawAct: {
                    ...lawAct.shiftLawAct,
                    hierarchyMarkPosition: e.target.value,
                  },
                })
              }
              name="hierarchyMarkPosition"
            />
            {" " + next}
          </label>
        </div>
        <br />
        <div>
        {listOfLockedActs.length > 0 && (
            <Collapse
              open={visible}
              setOpen={setVisible}
              title={"Закључани акти у области"}
            >
              {listOfLockedActs.map((item, index) => {
                return (
                  <span className="info-about-locked-acts" key={index}>
                    <p>
                      {item.userFullName} - {item.lawActName}
                    </p>
                  </span>
                );
              })}
            </Collapse>
          )}
          <button
            type="submit"
            className="btn btn-primary pull-right"
            style={{ marginLeft: "5px" }}
          >
            Додај
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateAct;
