import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAdvertisementCategory from "../../../components/create-advertisement-category/createAdvertisementCategory.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AdvertisementCategoryModel from "../../../models/AdvertisementCategory";
import AdvertisementAndActTypeClient from "../../../api-services/advertisement-and-act-type/advertisement-and-act-type";
import AdvertisementSubcategory from "../../../components/advertisement-subcategory/advertisementSubcategory.component";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const AdvertisementCategory = () => {
  const { t } = useTranslation();
  const tableName = "AP_ADVERTISEMENT_CATEGORY";

  const client = new AdvertisementAndActTypeClient();

  const columns = [
    {
      Header: t("Id"),
      accessor: "id",
      disableFilters: false,
    },
    {
      Header: t("Description"),
      accessor: "description",
      disableFilters: false,
    },
    {
      Header: t("DefaultSort"),
      accessor: "defaultSort",
      disableFilters: false,
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AdvertisementCategoryModel());
  const [allAdvertisementCategories, setAllAdvertisementCategories] = useState(
    []
  );
  const [
    allAdvertisementSubcategoriesData,
    setAllAdvertisementSubcategoriesData,
  ] = useState([]);
  const [title, setTitle] = useState("");
  const [showAdvertisementSubcategory, setShowAdvertisementSubcategory] =
    useState(false);
  const [categoryParentId, setCategoryParentId] = useState(null);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AdvertisementCategoryModel());
  };
  const getAdvertisementCategories = async () => {
    let response = await client.getAdvertisementAndActType();
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      let categories = handledResponse.filter((x) => !x.isLaw);
      setAllAdvertisementCategories(categories);
    }
  };

  const handleAddingDataToTable = () => {
    hideModal();
    getAdvertisementCategories();
  };

  useEffect(() => {
    getAdvertisementCategories();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new AdvertisementCategoryModel());
    showModal();
    setTitle(t("CreatingAdvertisementCategory"));
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingAdvertisementCategory"));
  };

  const handleTableRowClick = useCallback((response) => {
    getAdvertisementSubcategoriesByParentId(response.id);
  });

  const getAdvertisementSubcategoriesByParentId = async (id) => {
    let response = await client.getAdvertisementAndActTypesByparentId(id);
    let handledResponse = HandlingResponse(response);
    setCategoryParentId(id);
    setAllAdvertisementSubcategoriesData(handledResponse);
    handleChangeAdvertisementCategory(handledResponse);
  };

  const handleChangeAdvertisementCategory = useCallback((response) => {
    if (response) {
      setShowAdvertisementSubcategory(true);
    }
  });

  return (
    <>
      <div className="form-control">
        <div className="form-group">
          <h5 className="col-md-2">{t("AdvertisementCategories")}</h5>
          <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
            {t("AddAdvertisementCategory")}
          </button>
        </div>

        <Table
          columns={columns}
          data={allAdvertisementCategories}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
          handleClickOnRow={handleTableRowClick}
        />
      </div>
      <div>
        {showAdvertisementSubcategory ? (
          <AdvertisementSubcategory
            dataSubcategory={allAdvertisementSubcategoriesData}
            categoryInfo={categoryParentId}
          />
        ) : (
          <></>
        )}
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAdvertisementCategory
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default AdvertisementCategory;
