import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { sr } from "date-fns/locale";
import { getYear, getMonth } from "date-fns";

function convertLocalToUTCDate(date) {
  if (date) return new Date(moment.utc(date.toDateString()).format());
  else return null;
}

//akcija koja formatira datum kada ga prikupi sa beck-a
export const formatDateForUpdate = (date) => {
  if (date)
    return new Date(
      new Date(moment.utc(new Date(date).toDateString()).format())
    );
  else return null;
};

export default function CustomDatePicker({ custom, onChange, ...props }) {
  return (
    <DatePicker
      locale={sr}
      dateFormat="dd.MM.yyyy."
      className="form-control"
      strictParsing
      onChange={(date) => {
        date === null &&
          document
            .querySelector('button[type="submit"]')
            ?.focus({ preventScroll: true });
        onChange(convertLocalToUTCDate(date));
      }}
      isClearable
      onCalendarClose={() =>
        document
          .querySelector('button[type="submit"]')
          ?.focus({ preventScroll: true })
      }
      {...props}
    />
  );
}

function renderCustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  function range(start, end) {
    const step = 1;
    const length = Math.floor(Math.abs((end - start) / step)) + 1;
    return Array.from(Array(length), (_, index) => start + index * step);
  }
  const years = range(1945, getYear(new Date()) + 15);
  const months = [
    "Јануар",
    "Фебруар",
    "Март",
    "Април",
    "Мај",
    "Јун",
    "Јул",
    "Август",
    "Септембар",
    "Октобар",
    "Новембар",
    "Децембар",
  ];
  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        className="btn btn-primary table-btn"
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        {"<"}
      </button>
      <select
        className="form-control datepicker-select"
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        className="form-control datepicker-select"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        className="btn btn-primary table-btn"
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        {">"}
      </button>
    </div>
  );
}

function DatePickerWithCustomHeader({ onChange, ...props }) {
  return (
    <DatePicker
      renderCustomHeader={renderCustomHeader}
      locale={sr}
      dateFormat="dd.MM.yyyy."
      className="form-control"
      strictParsing
      onChange={(date) => {
        date === null &&
          document
            .querySelector('button[type="submit"]')
            ?.focus({ preventScroll: true });
        onChange(convertLocalToUTCDate(date));
      }}
      isClearable
      onCalendarClose={() =>
        document
          .querySelector('button[type="submit"]')
          ?.focus({ preventScroll: true })
      }
      {...props}
    />
  );
}

export { DatePickerWithCustomHeader };
