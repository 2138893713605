import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ProcedureKindClient from "../../api-services/coders/procedure-kind/procedure-kind-client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import Moment from "moment";

const CreateProcedureKind = ({ row, handleCallback, isCreate }) => {
  const [procedureKind, setProcedureKind] = useState({
    id: 0,
    name: "",
    weight: 0,
    isActive: true,
    userFullName: "",
    lastModifyDate: null,
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
  };

  const client = new ProcedureKindClient();

  useEffect(() => {
    setProcedureKind(row);
    setErrors({});
  }, [row, isCreate]);

  const CreateProcedureKind = async () => {
    const errorsResult = validateForm(procedureKind, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...procedureKind,
        weight: parseInt(procedureKind.weight),
        lastModifyDate: new Date(),
      };
      var response = await client.CreateProcedureKind(data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const UpdateProcedureKind = async () => {
    const errorsResult = validateForm(procedureKind, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...procedureKind,
        weight: parseInt(procedureKind.weight),
        lastModifyDate: new Date(),
      };
      var response = await client.UpdateProcedureKind(procedureKind.id, data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, procedureKind, errors, schema, t);
    setProcedureKind(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={procedureKind.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={procedureKind.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div>

        <input
                id="activeProcedureKindCB"
                    type="checkbox"
                    value={procedureKind.isActive}
                    checked={procedureKind.isActive}
                    onChange={(event) => {
                        setProcedureKind({ ...procedureKind, isActive: event.target.checked });
                    }}
          />
          <label htmlFor="activeProcedureKindCB">Активан</label>
        </div>
          {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{procedureKind.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{procedureKind.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>
                {Moment(procedureKind.lastModifyDate).format("DD.MM.YYYY.")}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateProcedureKind()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateProcedureKind()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateProcedureKind;
