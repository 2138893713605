import React, { useState } from "react";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useNavigate } from "react-router-dom";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const EditUserAccount = () => {
  const tableName = "LOZA_EDIT_USER_ACCOUNT"
  const columns = [
    {
      Header: "",
      accessor: "userGuid",
    },
    {
      Header: "Име",
      accessor: "name",
    },
    {
      Header: "Презиме",
      accessor: "lastName",
    },
    {
      Header: "Корисничко име",
      accessor: "userName",
    },
    {
      Header: "Адреса",
      accessor: "address",
    },
    {
      Header: "Компанија",
      accessor: "company",
    },
    {
      Header: "Е-маил",
      accessor: "email",
    },
    {
      Header: "Иницијална лозинка",
      accessor: "initialPassword",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditUser(row.original.userGuid)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const client = new CreateClient();

  const [usersTableData, setUsersTableData] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldNamesForSort, setFieldNamesForSort] = useState([]);

  const [isGroupAccount] = useState(false);

  const [filterData, setFilterData] = useState({
    userName: "",
    name: "",
    lastName: "",
    company: "",
    pib: "",
  });

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("LOZA_USER_ACCOUNTS");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }
    let response = await client.FilterIndividualUsers({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    });
    let res = HandlingResponse(response);
    return { dataList: res.usersDto, count: res.count };
  };

  const filterUsers = async (e) => {
    e?.preventDefault();
    sessionStorage.setItem("LOZA_USER_ACCOUNTS", JSON.stringify(filterData));

    var result = await fetchDataForPage(
      pageNumber,
      pageSize,
      fieldNamesForSort
    );
    setUsersTableData(result);
  };

  const onEditUser = async (userGuid) => {
    navigate("/loza/EditUserAccountById?userGuid=" + userGuid, {state: isGroupAccount});
  };

  const resetFilters = async () => {
    setFilterData({
      userName: "",
      name: "",
      lastName: "",
      company: "",
      pib: "",
    });
    sessionStorage.removeItem("LOZA_USER_ACCOUNTS");
    let response = await client.FilterIndividualUsers({
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: fieldNamesForSort,
    });
    let res = HandlingResponse(response);

    setUsersTableData({ dataList: res.usersDto, count: res.count });
  };

  return (
    <form onSubmit={filterUsers}>
      <h2>Измена корисничких налога</h2>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Корисничко име</label>
          <input
            className="form-control"
            type="text"
            value={filterData.userName}
            onChange={(e) => {
              setFilterData({ ...filterData, userName: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>Име</label>
          <input
            className="form-control"
            type="text"
            value={filterData.name}
            onChange={(e) => {
              setFilterData({ ...filterData, name: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>Презиме</label>
          <input
            className="form-control"
            type="text"
            value={filterData.lastName}
            onChange={(e) => {
              setFilterData({ ...filterData, lastName: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Име компаније</label>
          <input
            className="form-control"
            type="text"
            value={filterData.company}
            onChange={(e) => {
              setFilterData({ ...filterData, company: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>ПИБ</label>
          <input
            className="form-control"
            type="text"
            value={filterData.pib}
            onChange={(e) => {
              setFilterData({ ...filterData, pib: e.target.value });
            }}
          />
        </div>
        <div className="text-right col-md-4" style={{ marginTop: "28px" }}>
          <button
            className="btn btn-primary"
            onClick={() => filterUsers()}
            style={{ marginRight: "15px" }}
          >
            Претрага
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => resetFilters()}
          >
            Поништи претрагу
          </button>
        </div>
      </div>
      <br />
      <div>
        <Table
          columns={columns}
          data={usersTableData}
          serverSide={true}
          setData={setUsersTableData}
          rememberFilter={true}
          tableName={tableName}
          fetchFunction={fetchDataForPage}
          hiddenColumn={["userGuid"]}
        />
      </div>
    </form>
  );
};

export default EditUserAccount;
