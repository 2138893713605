import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../common/table/Table.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import SideModal from "../../../common/modal/SideModal.component";
import EditPromoSettingsLoza from "../../components/edit-promo-settings/EditPromoSettingsLoza.component";
import SettingsClient from "../../api-services/settings/settings-client";

const PromoSettingsLoza = () => {
  const tableName = 'LOZA_SETTINGS_FPA';
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "parameterKey",
    },
    {
      Header: "Вредност",
      accessor: "parameterValue",
    },
    {
      Header: "Опис",
      accessor: "description",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            title="Измена"
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
            type="button"
          >
            <FontAwesomeIcon icon={solid("edit")} />
          </button>
        );
      },
    },
  ];

  const client = new SettingsClient();

  const [settingsList, setSettingsList] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    parameterKey: "",
    parameterValue: "",
    description: "",
  });

  useEffect(() => {
    getAll();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      parameterKey: "",
      parameterValue: "",
      description: ""
    })
  }

  const getAll = async () => {
    let response = await client.getAll();
    let handledResponse = HandlingResponse(response);
    handledResponse && setSettingsList(handledResponse);
  };

  const onEditClick = (row) => {
    setRowData(row);
    showModal();
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    getAll();
  };

  return (
    <>
      <div>
        <h5 className="col-md-2">Промо подешавања</h5>
        <br />
        {settingsList.length > 0 && (
          <Table
            columns={columns}
            data={settingsList}
            tableName={tableName}
            rememberFilter={true}
            hiddenColumn={["id"]}
            serverSide={false}
          />
        )}
      </div>
      <SideModal
        show={show}
        handleClose={hideModal}
        title={"Измена параметара"}
      >
        <EditPromoSettingsLoza
          row={rowData}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default PromoSettingsLoza;
