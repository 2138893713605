import React, { useState } from "react";
import Table, { getPageNumber, getPageSizeNumber, getSortBy } from "../../../common/table/Table.component";
import ApproveClient from "../../api-services/requests-approval/requests-approval-client";
import { format } from "date-fns";
import SearchRequests from "../../components/search-requests/searchRequests.component";
import { AllRequestsModel } from "../../models/DataFilter";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Moment from "moment";

const AllRequests = () => {
    const tableName = 'LOZA_ALL_REQUESTS';
    const rememberFilter = true;
  
    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState(new AllRequestsModel());
    const [reset, setReset] = useState(false);
  
    const columns = [
      {
        Header: "Корисничко име",
        accessor: "userName",
      },
      {
        Header: "Име",
        accessor: "name",
      },
      {
        Header: "Презиме",
        accessor: "lastName",
      },
      {
        Header: "Име компаније",
        accessor: "company",
      },
      {
        Header: "ПИБ",
        accessor: "pib",
      },
      {
        Header: "Пакет",
        accessor: "packageName",
      },
      {
        Header: "Врста захтева",
        accessor: "requestType",
        Cell: ({ row }) => {
          return <div>{row.original.requestType ? "Укидање" : "Додавање"}</div>;
        },
      },
      {
        Header: "Важи од",
        accessor: "validFrom",
        Cell: ({ row }) => {
          return (
            <div>{format(new Date(row.original.validFrom), "dd.MM.yyyy.")}</div>
          );
        },
      },
      {
        Header: "Важи до",
        accessor: "validTo",
        Cell: ({ row }) => {
          return (
            <div>{format(new Date(row.original.validTo), "dd.MM.yyyy.")}</div>
          );
        },
      },
      {
        Header: "Статус",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.status === 2
                ? "Обрисан"
                : row.original.status
                ? "Одобрен"
                : "Необрађен"}
            </div>
          );
        },
      },
      {
        Header: "Захтев креирао",
        accessor: "requestedBy",
      },
      {
        Header: "Датум креирања захтева",
        accessor: "requestedDate",
        Cell: ({ row }) => {
          return (
            <div>
              {Moment(row.original.requestedDate).format("DD.MM.YYYY. HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "Захтев обрадио",
        accessor: "processedBy",
      },
      {
        Header: "Датум обраде захтева",
        accessor: "processingDate",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.processingDate
                ? Moment(row.original.processingDate).format("DD.MM.YYYY. HH:mm")
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Коментар",
        accessor: "comment",
      },
    ];
  
    const client = new ApproveClient();
  
    const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
      let response = await client.FilterAllRequests({
        ...filterData,
        id: null,
        packageId:
          filterData.packageId === "" ? null : parseInt(filterData.packageId),
        status: filterData.status === "" ? null : parseInt(filterData.status),
        requestType:
          filterData.requestType === "" ? null : parseInt(filterData.requestType),
        pageSize: pageSize,
        pageNumber: pageNumber,
        fieldNamesBySort: sortBy,
      });
      let res = HandlingResponse(response);
      return { dataList: res.responseRequestDto, count: res.count };
    };
  
    const searchData = async (e) => {
      e.preventDefault();
      var result = await fetchDataForPage(
        getPageNumber(tableName, rememberFilter),
        getPageSizeNumber(tableName, rememberFilter),
        getSortBy(tableName, rememberFilter, true)
        );
      setTableData(result);
    };
  
    const resetFilters = async () => {
      setReset(true);
      setFilterData(new AllRequestsModel());
      let response = await client.FilterAllRequests({});
      let res = HandlingResponse(response);
      setTableData({ dataList: res.responseRequestDto, count: res.count });  }
  
    return (
      <>
        <h2>Сви захтеви</h2>
        <SearchRequests
          filterData={filterData}
          setFilterData={setFilterData}
          handleCallback={searchData}
          resetFilters = {resetFilters}
        />
        <hr />
        <Table
          columns={columns}
          data={tableData}
          rememberFilter={rememberFilter}
          tableName={tableName}
          fetchFunction={fetchDataForPage}
          serverSide={true}
          setData={setTableData}
          resetFilters={reset}
        />
      </>
    );
}

export default AllRequests;