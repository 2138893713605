import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_ADMIN_PANEL;

class AdvertisementAndActTypeClient {

  async getAdvertisementAndActType(isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAdvertisementAndActTypeId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAdvertisementAndActTypesByparentId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/getAdvertisementAndActTypesByparentId?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createAdvertisementAndActType(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/AdvertisementAndActType/CreateAdvertisementAndActType", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAdvertisementAndActType(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/AdvertisementAndActType/UpdateAdvertisementAndActType", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActiveAdvertisement(isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/getActiveAdvertisementAndActTypes", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAdvertisementForRegAndOg(isForReg, isForOg, isBackground) {
    try {
      var response = await API.post(baseurl + "/AdvertisementAndActType/GetAdvertisementAndActTypesForRegAndOg?isForReg=" + isForReg + "&isForOg=" + isForOg, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }

  async getAdvertisementForOg(isForOg, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/GetAdvertisementAndActTypesForOg?isForOg=" + isForOg, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }

  async getAdvertisementForReg(isForReg, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/getAdvertisementAndActTypesForReg?isForReg=" + isForReg, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }

  async getAdvertisement(isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }

  async GetActiveAdvertisementAndActTypesForOg(isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/GetActiveAdvertisementAndActTypesForOg", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }

  async GetActiveAdvertisementAndActTypesForOgByAuthorityGuid(authorityGuid, isLaw, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/GetActiveAdvertisementAndActTypesForOgByAuthorityGuid?authorityGuid=" + authorityGuid + "&isLaw=" + isLaw, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAdvertisementAndActTypesForOgByAuthorityGuid(authorityGuid, isLaw, isBackground) {
    try {
      var response = await API.get(baseurl + "/AdvertisementAndActType/GetAdvertisementAndActTypesForOgByAuthorityGuid?authorityGuid=" + authorityGuid + "&isLaw=" + isLaw, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

}

export default AdvertisementAndActTypeClient;