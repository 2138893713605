import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import AuthorityClient from "../../api-services/authority/authority-client";
import { AuthorityModel, authoritySchema } from "../../models/Authority";

const CreateAuthority = ({ row, isEdit, handleCallback }) => {
  const { t } = useTranslation();
  const [authority, setAuthority] = useState(new AuthorityModel());
  const [errors, setErrors] = useState({});

  var client = new AuthorityClient();

  useEffect(() => {
    setAuthority(row);
    setErrors({});
  }, [row]);

  const createAuthority = async (e) => {
    e.preventDefault();
    const result = validateForm(authority, authoritySchema, t);
    setErrors(result);
    if(!result) {
      if (isEdit) var response = await client.updateAuthority(authority);
      else var response = await client.createAuthority(authority);
      validateAction(response);
    }
  };

  const validateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, authority, errors, authoritySchema, t);
    setAuthority(result.data);
    setErrors(result.errors);
  };

  return (
    <form onSubmit={createAuthority}>
      <div>
        <div className="col-md">
          <label>{t("Name")}</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={authority.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
          <label>{t("Group")}</label>
          <input
            className="form-control"
            disabled
            type="text"
            name="authorityGroupName"
            value={authority.authorityGroupName}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.authorityGroupName && (
            <div className="text-danger">{errors.authorityGroupName}</div>
          )}
          <label>{t("Kind")}</label>
          <input
            className="form-control"
            disabled
            type="text"
            name="authorityKindName"
            value={authority.authorityKindName}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.authorityKindName && (
            <div className="text-danger">{errors.authorityKindName}</div>
          )}
          <label>{t("Area")}</label>
          <input
            className="form-control"
            disabled
            type="text"
            name="authorityAreaName"
            value={authority.authorityAreaName}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.authorityAreaName && (
            <div className="text-danger">{errors.authorityAreaName}</div>
          )}
          <label>{t("Weight")}</label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={authority.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
          <input
            id="activeAuthorityCB"
            type="checkbox"
            value={authority.active}
            checked={authority.active}
            onChange={(event) => {
              setAuthority({ ...authority, active: event.target.checked });
            }}
          />
          <label htmlFor="activeAuthorityCB">{t("Active")}</label>
        </div>
        <div className="text-right">
          <button
            className="btn btn-primary m-2"
            onClick={createAuthority}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateAuthority;
