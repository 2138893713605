import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import DatePicker from "react-datepicker";
import DocumentTypeClient from "../../../MML/api-services/document-type/document-type-client";
import SideModal from "../../../common/modal/SideModal.component";
import CreateType from "../create-type/createType.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { dataSchema } from "../../models/CreateData";
import { handleSave } from "../../../common/validation/validation";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";

const DocumentType = ({ formData, setFormData, errors, setErrors }) => {
  const { t } = useTranslation();

  const [mainTypes, setMainTypes] = useState([]);
  const [mainType, setMainType] = useState(null);

  const [types, setTypes] = useState([]);
  const [type, setType] = useState(null);
  const [disableCreateType, setDisableCreateType] = useState(true);

  const [subtypes, setSubtypes] = useState([]);
  const [subtype, setSubtype] = useState(null);
  const [disableCreateSubtype, setDisableCreateSubtype] = useState(true);

  const [typeInfo, setTypeInfo] = useState({});
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  var client = new DocumentTypeClient();

  useEffect(() => {
    getDocumentTypes();
    setErrors({});
  }, []);

  useEffect(() => {
    if(formData.typesIdsWithParentIds.length > 0) {
      getParentTypesForTypeId();
    }
  }, [formData?.typesIdsWithParentIds.length]);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setTypeInfo({});
    setShow(false);
  };

  const getDocumentTypes = async () => {
    let response = await client.getAllActiveTypes();
    let res = HandlingResponse(response);
    let mainTypesArray = res
      .filter((x) => x.parentId === 0)
      .map((x) => {
        return { value: x.id, label: x.name, name: "typeId" };
      });
    setMainTypes(mainTypesArray);
  };

  const getParentTypesForTypeId = async () => {
    for (var i = formData.typesIdsWithParentIds.length - 1; i >= 0; i--) {
      if (formData.typesIdsWithParentIds[i].parentId === 0)
        await onChangeMainType(formData.typesIdsWithParentIds[i].id);
      else if (i - 1 > -1 && formData.typesIdsWithParentIds[i].id === formData.typesIdsWithParentIds[i - 1].parentId)
        await onChangeType(formData.typesIdsWithParentIds[i].id);
      else {
        if (formData.typesIdsWithParentIds[i + 1].parentId === 0)
          await onChangeType(formData.typesIdsWithParentIds[i].id);
        else onChangeSubtype(formData.typesIdsWithParentIds[i].id);
      }
    }
    if (formData.typesIdsWithParentIds.length === 0) {
      setMainType(0);
      setType(0);
      setSubtype(0);
    }
  };

  const onChangeMainType = async (id) => {
    setMainType(id);
    let response = await client.getAllActiveTypesByParentId(id, true);
    let res = HandlingResponse(response);
    let arrayOfTypes = res.map((x) => {
      return { value: x.id, label: x.name, name: "typeId" };
    });
    setDisableCreateType(false);
    setTypes(arrayOfTypes);
    setFormData({ ...formData, typeId: id });
  };

  const onChangeType = async (id) => {
    setType(id);
    let response = await client.getAllActiveTypesByParentId(id, true);
    let res = HandlingResponse(response);
    let arrayOfSubtypes = res.map((x) => {
      return { value: x.id, label: x.name, name: "typeId"  };
    });
    if (id === 0) {
      setDisableCreateSubtype(true);
      setFormData({ ...formData, typeId: mainType });
    } else {
      setDisableCreateSubtype(false);
      setFormData({ ...formData, typeId: id });
    }
    setSubtypes(arrayOfSubtypes);
  };

  const onChangeSubtype = (id) => {
    setSubtype(id);
    id === 0
      ? setFormData({ ...formData, typeId: type })
      : setFormData({ ...formData, typeId: id });
  };

  const createType = async () => {
    let response = await client.getTypeById(mainType);
    let res = HandlingResponse(response);
    setTypeInfo({
      ...typeInfo,
      parentId: res.id,
      parentName: res.name,
    });
    setTitle("Креирање типа");
    showModal();
  };

  const createSubtype = async () => {
    let response = await client.getTypeById(type);
    let res = HandlingResponse(response);
    setTypeInfo({
      ...typeInfo,
      parentId: res.id,
      parentName: res.name,
    });
    setTitle("Креирање подтипа");
    showModal();
  };

  const handleAddingDataToList = (childData) => {
    hideModal();
    childData.active && 
    (//u zavisnostio od toga sta se kreira dodaje se novokreirani tip ili u listu tipova ili u listu podtipova
    childData.level === 2 
      ? 
      setTypes([...types, { value: childData.id, label: childData.name }])
      : setSubtypes([
        ...subtypes,
        { value: childData.id, label: childData.name },
      ]))
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      formData,
      errors,
      dataSchema,
      t
    );
    setFormData(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <label className="col-2" htmlFor="MainDocumentType">
              {t("MainType")}<span className="required-asterisk">*</span>
            </label>
            <div className="col-7">
              <Select
                id="MainDocumentType"
                options={mainTypes}
                onChange={(event) => {
                  handlePropertyChange(event);
                  onChangeMainType(event.value);
                  setSubtype(null);
                }}
                value={mainTypes.filter((x) => x.value === mainType)}
              />
              {errors?.typeId && (
              <div className="text-danger">{errors.typeId}</div>
            )}
            </div>
          </div>
          <br />
          <div className="row">
            <label className="col-2" htmlFor="DocumentType">
              {t("Type")}
            </label>
            <div className="col-7">
              <Select
                id="DocumentType"
                options={types}
                onChange={(event) => {
                  event ? onChangeType(event.value) : onChangeType(0);
                }}
                value={types.filter((x) => x.value === type)}
                isClearable
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-3">
                  <button
                    id="createTypeButton"
                    type="button"
                    disabled={disableCreateType}
                    className="btn btn-primary"
                    onClick={createType}
                  >
                    {t("AddType")}
                  </button>
              </div>
            </RenderOnRole>
          </div>
          <br />
          <div className="row">
            <label className="col-2" htmlFor="DocumentSubtype" >
              {t("Subtype")}
            </label>
            <div className="col-7">
              <Select
                id="DocumentSubtype"
                options={subtypes}
                onChange={(event) => {
                  event ? onChangeSubtype(event.value) : onChangeSubtype(0);
                }}
                value={subtypes.filter((x) => x.value === subtype)}
                isClearable
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-3">
                <button
                  type="button"
                  disabled={disableCreateSubtype}
                  className="btn btn-primary"
                  onClick={createSubtype}
                >
                  {t("AddSubtype")}
                </button>
              </div>
            </RenderOnRole> 
          </div>
        </div>
        <div className="form-group col-6">
          <div className="row">
            <label className="col-3">{t("EnactmentDate")} <span className="required-asterisk">*</span></label>
            <div className="col-3">
              <CustomDatePicker
                selected={formData.enactmentDate}
                onChange={(date) => handlePropertyChange(date, 'enactmentDate')}
              />
              
            </div>
            <div className="col-6">
              <input
                id="showEnactmentDateCB"
                type="checkbox"
                name="showEnactmentDate"
                defaultValue={formData.showEnactmentDate}
                checked={formData.showEnactmentDate}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    showEnactmentDate: event.target.checked,
                  });
                }}
              />
              <label htmlFor="showEnactmentDateCB">
                {t("ShowEnactmentDate")}
              </label>
            </div>
            {errors?.enactmentDate && (
              <div className="text-danger">{errors.enactmentDate}</div>
            )}
          </div>
          <br />
          <div className="row">
            <label className="col-3">{t("ActLabelNumber")}</label>
            <div className="col-9">
              <input
                type="text"
                name="actLabelNumber"
                value={formData?.actLabelNumber || ""}
                onChange={(event) => handlePropertyChange(event.target)}
                className="form-control"
              />
            </div>
            {errors?.actLabelNumber && (
              <div className="text-danger">{errors.actLabelNumber}</div>
            )}
          </div>
          <br />
          <div className="row">
            <label className="col-2">{t("Year")}</label>
            <div className="col-3">
              <DatePicker
                className="form-control"
                name="dateYear"
                selected={
                  formData?.year ? new Date(formData.year.toString()) : null
                }
                onChange={(date) => {
                  //prilikom slanja godine na back treba da bude year.getFullYear()
                  setFormData({ ...formData, year: date?.getFullYear() });
                }}
                showYearPicker
                dateFormat="yyyy"
                isClearable
              />
            </div>
            <div className="col-3">
              <input
                id="archivedCB"
                type="checkbox"
                name="archived"
                value={formData.archived || ""}
                checked={formData.archived || ""}
                onChange={(event) => {
                  setFormData({ ...formData, archived: event.target.checked });
                }}
              />
              <label htmlFor="archivedCB">{t("Archived")}</label>
            </div>
            <div className="col-4">
              <input
                id="highlightedCB"
                type="checkbox"
                name="highlighted"
                value={formData.highlighted || ""}
                checked={formData.highlighted || ""}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    highlighted: event.target.checked,
                  });
                }}
              />
              <label htmlFor="highlightedCB">{t("ShowInTopTen")}</label>
            </div>
          </div>
          <br />
        </div>
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateType
          row={typeInfo}
          isEdit={false}
          handleCallback={handleAddingDataToList}
        />
      </SideModal>
    </>
  );
};

export default DocumentType;
