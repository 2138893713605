import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { handleSave } from "../../../common/validation/validation";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";

const PackageExtensionRequest = ({
  errors,
  setErrors,
  handleCallback,
  approve = false
}) => {
  const { t } = useTranslation();
  const schema = {
    validTo: Joi.date().required(),
    comment: Joi.string().allow("").optional(),
  };

  const [object, setObject] = useState({
    validTo: new Date(),
    comment: "",
  });

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, object, errors, schema, t);
    setObject(result.data);
    setErrors(result.errors);
  };
  return (
    <div>
      <div className="form-group">
        <label>Важи до</label>
        <CustomDatePicker
          selected={object.validTo}
          onChange={(date) => handlePropertyChange(date, "validTo")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.validTo && <div className="text-danger">{errors.validTo}</div>}
      </div>
      <div className="form-group">
        <label>Коментар</label>
        <textarea
          className="form-control"
          value={object.comment}
          onChange={(e) => setObject({ ...object, comment: e.target.value })}
        ></textarea>
      </div>
      <div>
        {!approve && <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleCallback(false, object)}
        >
          Креирај захтев
        </button>}
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={() => handleCallback(true, object)}
        >
          Креирај и одобри захтев
        </button>
      </div>
    </div>
  );
};

export default PackageExtensionRequest;
