import api from "../../../../api";
import { env } from "../../../../env";

const baseurl = env.REACT_APP_API_URL_FP;

class LocationsClient {

    async GetLocations(isBackground) {
        try{
            var response = await api.get(baseurl + "/Location/GetLocations", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
          }
    }
}

export default LocationsClient;