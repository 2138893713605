import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";
import { MainContainer, ContentContainer } from "./Layout.style";

const MMLLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();
  useEffect(() => {
    setAppName("ММЛ");
    setAppPath("/mml");
  }, [])
  
  return (
    <>
      <MainContainer>
          <Outlet />
      </MainContainer>
    </>
  );
};

export default MMLLayout;
