import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateLegislativeActType from "../../../components/legislative-act-types-component/legislativeActTypesComponent";
import ActKindClient from "../../../api-services/coders/act-kind/act-kind-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const LegislativeActType = () => {
  const tableName = 'SP_LEGISLATIVE_ACT_TYPE';
  
  const columns = [
    {
      Header: "Назив врсте акта",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation  
            className="btn btn-danger table-btn" 
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Врста акта ће бити трајно обрисана."}
            handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  const [actKind, setActKind] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    name: "",
    weight: "",
    isActive: true
  });

  const client = new ActKindClient();

  useEffect(() => {
    ActKind();
  }, []);
  
  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
    showModal();
  };
  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };
  const onDeleteClick = async (id) => {
    var response = await client.DeleteActKind(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let actKindCopy = actKind.filter((x) => x.id !== id);
      setActKind(actKindCopy);
    }
  };

  const ActKind = async () => {
    await client.ActKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setActKind(handledResponse);
    });
  }

  return (
    <>
      <h4>Врсте аката</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нову врсту акта
        </button>
      </div>
      <Table
        columns={columns}
        data={actKind}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Креирање нове врсте акта" : "Измена врсте акта"}
      >
        <CreateLegislativeActType
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            ActKind();
          }}
        />
      </SideModal>
    </>
  );
};

export default LegislativeActType;
