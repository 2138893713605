import React, { useEffect, useRef, useState } from "react";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import UserClient from "../../api-services/user/user-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { UserFilterModel } from "../../models/UserModel.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SearchUsers from "../../components/search-user/SearchUsers.component.jsx";
import Moment from "moment";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component.jsx";
import CreateOrEditUser from "../../components/create-edit-user/CreateOrEditUser.component.jsx";
import SideModal from "../../../common/modal/SideModal.component.jsx";
import UserSubscriptions from "../../components/user-subscriptions/UserSubscriptions.component.jsx";
import {
  useLocation,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

const User = () => {
  const tableName = "PENG_INDEX";

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [queryParams] = useSearchParams();
  var userId = queryParams.get("userId");
  var userName = queryParams.get("userName");
  const isSubscriptionsModal = pathname.includes("/subscriptions");
  const isEditUserModal = pathname.includes("/editUser");

  const rememberFilter = true;
  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Презиме, име",
      accessor: "firstLastName",
    },
    {
      Header: "Рок трајања претплате",
      accessor: "orderAlarmDate",
      Cell: ({ row }) => {
        return row.original.orderAlarmDate
          ? Moment(row.original.orderAlarmDate).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      Header: "Фирма",
      accessor: "company",
    },
    {
      Header: "Сектор",
      accessor: "sector",
    },
    {
      Header: "Имејл",
      accessor: "email",
    },
    {
      Header: "Корисничко име",
      accessor: "loginName",
    },
    {
      accessor: "buttons",
      className: "three-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <Link
              className="btn btn-primary table-btn"
              to={`/peng/users/editUser?userId=${row.original.id}`}
              onClick={() => onCreateOrEditUserClick(row.original.id)}
              title={"Измени корисника"}
              >
              <FontAwesomeIcon icon={solid("pencil")} />
            </Link>
            <Link
              className="btn btn-primary table-btn"
              to={`/peng/users/subscriptions?userId=${row.original.id}&userName=${row.original.firstLastName}`}
              title={"Права приступа корисника"}
              onClick={() =>
                onUserSubscriptionsClick(
                  row.original.id,
                  row.original.firstLastName
                )
              }
            >
              <FontAwesomeIcon icon={solid("info")} />
            </Link>

            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => deleteUser(row.original.id)}
              contentText={"Корисник ће бити трајно обрисан."}
              handleYesText={"Обриши"}
              title={"Трајно обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  var client = new UserClient();

  const [usersData, setUsersData] = useState([]);
  const [filterData, setFilterData] = useState(new UserFilterModel());
  const [reset, setReset] = useState(false);

  const [show, setShow] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    userName && getSearchParamsFromLink();
    isSubscriptionsModal && onUserSubscriptionsClick(userId, userName);
    isEditUserModal && onCreateOrEditUserClick(userId);
  }, []);

  useEffect(() => {
    reset && setReset(false);
  }, [usersData]);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setIsEdit(false);
    navigate(`/peng/users`);
  };

  const showSubscriptionModal = () => setShowSubscriptions(true);
  const hideSubscriptionModal = () => {
    setShowSubscriptions(false);
    navigate(`/peng/users`);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("PENGUSER__FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.filterUsers({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    });
    let data = HandlingResponse(response);
    return { dataList: data.users, count: data.count };
  };

  const resetFilters = async () => {
    setReset(true);
    setFilterData(new UserFilterModel());
    sessionStorage.removeItem("PENGUSER__FILTER");
    var response = await client.filterUsers(new UserFilterModel());
    let data = HandlingResponse(response);
    setUsersData({dataList: data.users, count: data.count});
  };

  const searchUsers = async (event, filters) => {
    //param filters se koristi ukoliko se search radi na osnovu linka
    event?.preventDefault();
    sessionStorage.setItem("PENGUSER__FILTER", JSON.stringify(filters ? filters : filterData));

    const response = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setUsersData(response);
  };

  const getSearchParamsFromLink = () => {
    const [lastName, firstName] = userName.split(',').map(name => name.trim());
    var model = new UserFilterModel()
    var searchObj = {...model, id: userId, firstName: firstName, lastName: lastName}
    setFilterData(searchObj);
    searchUsers(null, searchObj);
  }

  const onCreateOrEditUserClick = (id = 0) => {
    if (id != 0) {
      setIsEdit(true);
    } else setIsEdit(false);

    showModal();
  };

  const onUserSubscriptionsClick = (id, username) => {
    const data = {
      id: id,
      firstLastName: username,
    };
    setUser(data);
    showSubscriptionModal();
  };

  const deleteUser = async (id) => {
    var response = await client.deleteUser(id);
    HandlingResponse(response);
  };

  const handleAddingUser = async () => {
    hideModal();
    var result = await fetchDataForPage(
      getPageNumber(tableName) + 1,
      getPageSizeNumber(tableName),
      getSortBy(tableName, rememberFilter, true)
    );
    setUsersData(result);
  };

  return (
    <div>
      <SearchUsers
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchUsers}
        resetFilters={resetFilters}
      />
      <hr />
      <div className="text-right">
        <button
          onClick={() => onCreateOrEditUserClick()}
          type="button"
          className="btn btn-primary"
        >
          Додавање новог корисника
        </button>
      </div>
      <Table
        columns={columns}
        data={usersData}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={["id"]}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setUsersData}
        resetFilters={reset}
      />
      <SideModal
        show={show}
        title={isEdit ? "Измена корисника" : "Креирање корисника"}
        handleClose={hideModal}
      >
        <CreateOrEditUser
          userId={isEdit ? userId : null}
          edit={isEdit}
          handleClose={hideModal}
          handleAddingUser={handleAddingUser}
        />
      </SideModal>

      <SideModal
        show={showSubscriptions}
        title={"Права приступа корисника"}
        handleClose={hideSubscriptionModal}
      >
        <UserSubscriptions handleClose={hideSubscriptionModal} user={user} />
      </SideModal>
    </div>
  );
};

export default User;
