import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";
import AuthorityClient from "../../api-services/authority/authority-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {AuthorityModel, authoritySchema} from "../../models/Codebook";

const CreateAuthority = ({ row, isEdit, handleCallback }) => {
    const { t } = useTranslation();
    const [authority, setAuthority] = useState(new AuthorityModel());
    const [errors, setErrors] = useState();
   
    const client = new AuthorityClient();

    useEffect(() => {
        setAuthority(row);
        setErrors({});
    }, [row]);

    const updateAuthority = async () => {
        const errorsResult = validateForm(authority, authoritySchema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            const response = await client.updateAuthority(authority);
            const handledResponse = HandlingResponse(response);

            if (handledResponse.code === 408) {
                const result = serverValidation(handledResponse, t);
                setErrors(result);
            } else {
                handleCallback(handledResponse);
            }
        }
    }

    const createAuthority = async () => {
        const errorsResult = validateForm(authority, authoritySchema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            const response = await client.createAuthority(authority);
            const handledResponse = HandlingResponse(response);

            if (handledResponse.code === 408) {
                const result = serverValidation(handledResponse, t);
                setErrors(result);
            } else {
                handleCallback(handledResponse);
            }
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date ?
        { name: date, value: event.value } :
        { name: event.name, value: event.value };
        const result = handleSave(obj, authority, errors, authoritySchema, t);
        setAuthority(result.data);
        setErrors(result.errors);
    }

    return (
        <>
            <div className="col-md">
                <label>{t("Name")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="authorityName"
                    value={authority.authorityName}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.authorityName && (
                    <div className="text-danger">
                        {errors.authorityName}
                    </div>
                )}
                <label>{t("Description")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="description"
                    value={authority.description}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.description && (
                    <div className="text-danger">
                        {errors.description}
                    </div>
                )}
                <label>{t("Sort")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="sort"
                    value={authority.sort}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.sort && (
                    <div className="text-danger">
                        {errors.sort}
                    </div>
                )}
                <input
                    id="activeCB"
                    type="checkbox"
                    value={authority.active}
                    checked={authority.active}
                    onChange={(event) => {
                        setAuthority({ ...authority, active: event.target.checked });
                    }}
                />
                <label htmlFor="activeCB">{t("Active")}</label>
            </div>

            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={isEdit ? updateAuthority : createAuthority}
                >
                    {t("Save")}
                </button>
            </div>
        </>
    )
}

export default CreateAuthority;