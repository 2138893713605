import API from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_MML;

class SourceKindClient {
  async getSourceKinds(isBackground) {
    try {
      var response = await API.get(baseurl + "/SourceKind", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveSourceKinds(isBackground) {
    try {
      var response = await API.get(baseurl + "/SourceKind/GetAllActiveSourceKinds", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getSourceKindById(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/SourceKind/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createSourceKind(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/SourceKind/CreateSourceKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateSourceKind(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/SourceKind/UpdateSourceKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteSourceKind(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/SourceKind/DeleteSourceKind/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default SourceKindClient;