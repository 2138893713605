import { useTranslation } from "react-i18next";
import UserService from "../../../utils/authentification/keycloak";

const Login = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3>{t("Login")}</h3>
        <button
          className="btn btn-primary m-2"
          onClick={() => UserService.doLogin({redirectUri: window.location.origin + '/'})}
        >
          {t("Login")}
        </button>
      </div>
    </>
  );
};

export default Login;
