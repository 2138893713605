import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";

const PublishedEnum = {
  All: 2,
  Published: 1,
  Notpublished: 0,
};

const SearchStaticPages = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
  handleOptionsChange,
  selectedPublishedEnum,
}) => {
  return (
    <form onSubmit={handleCallback}>
      <div className="form-group col-12">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Текст за приказ на порталу</label>
              <input
                className="form-control"
                type="text"
                name="displayText"
                value={filterData.displayText}
                onChange={(event) =>
                  setFilterData({
                    ...filterData,
                    displayText: event.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Датум објављивања од:</label>
              <CustomDatePicker
                selected={filterData.publishedDateFrom}
                onChange={(date) =>
                  setFilterData({
                    ...filterData,
                    publishedDateFrom: date,
                  })
                }
                showMonthDropdown
                useShortMonthInDropdown
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Интерно обележје</label>
              <input
                className="form-control"
                type="text"
                name="internalMark"
                value={filterData.internalMark}
                onChange={(event) =>
                  setFilterData({
                    ...filterData,
                    internalMark: event.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Датум објављивања до:</label>
              <CustomDatePicker
                selected={filterData.publishedDateTo}
                onChange={(date) =>
                  setFilterData({
                    ...filterData,
                    publishedDateTo: date,
                  })
                }
                showMonthDropdown
                useShortMonthInDropdown
              />
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="form-group col-6"
              style={{ border: "1px solid #e1d6d6", borderRadius: "5px", marginTop: "30px", paddingLeft: "5px"}}
            >
              <label style={{ marginRight: "10px" }}>Статус:</label>
              <>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="2"
                      checked={selectedPublishedEnum === "2"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Сви
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      checked={selectedPublishedEnum === "1"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Објављени
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="0"
                      checked={selectedPublishedEnum === "0"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Необјављени
                  </label>
                </div>
              </>
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-primary"
              type="submit"
            >
              Претрага
            </button>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                resetFilters();
              }}
            >
              Поништи претрагу
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchStaticPages;
