import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ActBasicData from "../act-basic-data/ActBasicData.component";
import OfficialGazette from "../official-gazette/OfficialGazette.component";
import ActReference from "../act-reference/ActReference.component";
import ActOtherData from "../act-other-data/ActOtherData.component";
import ActChangesHistory from "../act-changes-history/ActChangesHistory.component";
import useFormContext from "../../hooks/useFormContext";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import LawActClient from "../../api-services/law-act/law-act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UserService from "../../../utils/authentification/keycloak";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { validateForm } from "../../../common/validation/validation";
import { schema } from "../../models/PreviewLawAct";

const PreviewAndUpdateAct = ({ lawActInfo, actId, getLawAct }) => {
  const { page, handleChangePage, data, setData, errors, setErrors } =
    useFormContext();
  const navigate = useNavigate();
  var lawActClient = new LawActClient();

  const [fromWarnings] = useSearchParams();
  var isUpdatedFromWarnings = fromWarnings.get("fromWarnings");

  const { t } = useTranslation();

  const [user, setUser] = useState({ userGuid: null, userName: "" });
  const [isLocked, setIsLocked] = useState(false);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    setData({
      lawActBasicDataModel: lawActInfo.lawActBasicDataModel,
      officialGazetteModel: lawActInfo.officialGazetteModel,
      lawActReferenceModel: lawActInfo.lawActReferenceModel,
      lawActOtherDataModel: lawActInfo.lawActOtherDataModel,
      lawActChangesHistoryModel: lawActInfo.lawActChangesHistoryModel,
    });
    setFile(lawActInfo.file);
    setFiles(lawActInfo.files);
    setPictures(lawActInfo.pictures);
    setUser({
      userGuid: lawActInfo.lawActBasicDataModel.userGuid,
      userName: lawActInfo.lawActBasicDataModel.userFullName,
    });
    setIsLocked(lawActInfo.lawActBasicDataModel.locked);
  }, [lawActInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errorsResult = validateForm(data, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var formData = new FormData();

      const modifiedglobalActReferencesByType = data.lawActReferenceModel.globalActReferencesByType.map(element => {
        const updatedGlobalActReferences = element.globalActReferences.map((el, index) => {
          return { ...el, sort: index };
        });
        return { ...element, globalActReferences: updatedGlobalActReferences };
      });
      
      const dataForSerialization = {
        lawActBasicDataDto: data.lawActBasicDataModel,
        officialGazetteDto: data.officialGazetteModel,
        lawActReferenceDto: {
          ...data.lawActReferenceModel,
          globalActReferencesByType: modifiedglobalActReferencesByType
        },
        lawActOtherDataDto: {
          ...data.lawActOtherDataModel,
          lawActTabs: data.lawActOtherDataModel.lawActTabs.map((x, i) => {
            return {
              ...x,
              sort: i,
            };
          }),
        },
        lawActChangesHistoryDto: data.lawActChangesHistoryModel,
      };
      serializeObjectIntoFormData(formData, dataForSerialization);

      if (file?.guid) {
        formData.append("file.guid", file?.guid);
        formData.append("file.name", file?.name);
      } else file && formData.append("file.file", file);

      for (let i = 0; i < pictures.length; i++) {
        if (pictures[i].guid)
          formData.append("pictures[" + i + "].guid", pictures[i].guid);
        else formData.append("pictures[" + i + "].file", pictures[i]);

        formData.append("pictures[" + i + "].name", pictures[i].name);
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].guid)
          formData.append("files[" + i + "].guid", files[i].guid);
        else formData.append("files[" + i + "].file", files[i]);
        formData.append("files[" + i + "].name", files[i].name);
      }

      let response = await lawActClient.updateLawAct(formData);
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (isUpdatedFromWarnings == "true") navigate("/registries/warnings");
      else navigate("/registries");
    }
  };

  const onCancel = async () => {
    if (actId) {
      var response = await lawActClient.cancelUpdateLawAct(actId);
      HandlingResponse(response);
    }
    if (isUpdatedFromWarnings == "true") navigate("/registries/warnings");
    else navigate("/registries");
  };

  return (
    <>
      <div className="clearfix">
        {isLocked && user.userGuid !== UserService.getUserGuid() && (
          <label style={{ color: "red", marginBottom: "15px" }}>
            {`Корисник ${user.userName} уређује овај документ`}
          </label>
        )}
      </div>
      <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Основни подаци" value="1" className="tab" />
            <Tab label="Службена гласила" value="2" />
            <Tab label="Везе" value="3" />
            <Tab label="Остало" value="4" />
            <Tab label="Историјат измена" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ActBasicData
            getLawAct={getLawAct}
            file={file}
            setFile={setFile}
            files={files}
            setFiles={setFiles}
            pictures={pictures}
            setPictures={setPictures}
            actId={actId}
            errors={errors}
          />
        </TabPanel>
        <TabPanel value="2">
          <OfficialGazette />
        </TabPanel>
        <TabPanel value="3">
          <ActReference />
        </TabPanel>
        <TabPanel value="4">
          <ActOtherData />
        </TabPanel>
        <TabPanel value="5">
          <ActChangesHistory />
        </TabPanel>
      </TabContext>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginRight: "10px" }}
        onClick={onCancel}
      >
        Одустани
      </button>
      {user.userGuid === UserService.getUserGuid() && (
        <button
          type="button"
          className="pull-right btn btn-primary"
          onClick={handleSubmit}
        >
          Сачувај
        </button>
      )}
      <div>
        <span className="info-about-cancel-span">
          <FontAwesomeIcon icon={solid("circle-info")} /> Уколико не кликнете на
          дугме "Одустани" документ ће остати закључан и други корисници неће
          моћи да врше измену над њим
        </span>
      </div>
    </>
  );
};

export default PreviewAndUpdateAct;
