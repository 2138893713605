import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import DatePicker from "react-datepicker";
import { sr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import MultimediaClient from "../../api-services/multimedia/multimedia";

const CreateMultimedia = ({ edit, row }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    title: Joi.string().required(),
    link: Joi.string().required(),
    publishedDate: Joi.date().required(),
  };

  const [multimediaData, setMultimediaData] = useState({
    title: "",
    description: "",
    publishedDate: new Date(),
    link: "",
    published: false,
  });

  var client = new MultimediaClient();

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, multimediaData, errors, schema, t);
    setMultimediaData(result.data);
    setErrors(result.errors);
  };

  const createMultimedia = async () => {
    const errorsResult = validateForm(multimediaData, schema, t);
    setErrors(errorsResult);

    const inputDate = new Date(multimediaData.publishedDate);
    const offsetInMillis = inputDate.getTimezoneOffset() * 60 * 1000;
    const cestDate = new Date(inputDate.getTime() - offsetInMillis);
    const outputDate = cestDate.toISOString();

    var data = {
      ...multimediaData,
      publishedDate: outputDate
    };

    if (!errorsResult) {
      var response;

      if (edit) {
        response = await client.updateMultimedia(data);
      } else {
        response = await client.createMultimedia(data);
      }
      
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      }
    }
  };

  useEffect(() => {
    setMultimediaData(row);
    setErrors({});
  }, [row]);

  return (
    <>
      <div className="form-group">
        <div>
          <label>Наслов<span className="required-asterisk">*</span></label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={multimediaData.title}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.title && <div className="text-danger">{errors.title}</div>}
        </div>
        <div className="form-group">
          <label>Опис</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={multimediaData.description}
            onChange={(e) =>
              setMultimediaData({
                ...multimediaData,
                description: e.target.value,
              })
            }
          />
        </div>
        <label>Датум објављивања:<span className="required-asterisk">*</span></label>
        <DatePicker
          selected={
            multimediaData.publishedDate
              ? new Date(multimediaData.publishedDate)
              : null
          }
          onChange={(date) => handlePropertyChange(date, "publishedDate")}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={1}
          dateFormat="dd.MM.yyyy. HH:mm"
          className="form-control"
          locale={sr}
          timeCaption="време"
        />
        {errors?.publishedDate && (
          <div className="text-danger">{errors.publishedDate}</div>
        )}
      </div>
  
      <input
        className="form-group"
        id="publishedMultimedia"
        type="checkbox"
        value={multimediaData.published}
        checked={multimediaData.published}
        onChange={(event) => {
          setMultimediaData({
            ...multimediaData,
            published: event.target.checked,
          });
        }}
      />
      <label htmlFor="publishedMultimedia"> Објављен </label>
      <div className="form-group">
        <label>Линк са Youtube-а<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          name="link"
          value={multimediaData.link}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.link && <div className="text-danger">{errors.link}</div>}
      </div>
      <div>
        <button
          type="button"
          className="btn btn-primary form-group"
          style={{ marginTop: "20px" }}
          onClick={() => createMultimedia()}
        >
          {edit ? "Измени" : "Креирај"}
        </button>
      </div>
    </>
  );
};

export default CreateMultimedia;
