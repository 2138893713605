import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthorityClient from "../../api-services/authority/authority-client";
import SideModal from "../../../common/modal/SideModal.component";
import CreateAuthorityGroup from "../create-authority-group/createAuthorityGroup.component";
import CreateAuthorityKind from "../create-authority-kind/createAuthorityKind.component";
import CreateAuthorityArea from "../create-authority-area/createAuthorityArea.component";
import CreateAuthority from "../create-autority/createAuthority.component";
import { CreateDataAuthorityModel, dataSchema } from "../../models/CreateData";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { AuthorityGroupModel } from "../../models/Authority";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import Collapse from "../../../common/collapse/Collapse.component";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";

const Authority = ({
  authoritiesData,
  setAuthoritiesData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      Header: t("Group"),
      accessor: "authorityGroupName",
    },
    {
      Header: t("Kind"),
      accessor: "authorityKindName",
    },
    {
      Header: t("Area"),
      accessor: "authorityAreaName",
    },
    {
      Header: t("Authority"),
      accessor: "authorityName",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => editElement(row.original, row.index)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <button
              className="btn btn-danger table-btn"
              onClick={() => removeElement(row.index)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new AuthorityClient();

  const [authority, setAuthority] = useState(new CreateDataAuthorityModel());
  const [authorityGroups, setAuthorityGroups] = useState([]);
  const [authorityKinds, setAuthorityKinds] = useState([]);
  const [authorityAreas, setAuthorityAreas] = useState([]);
  const [authorities, setAuthorities] = useState([]);

  const [disableCreateAuthorityKind, setDisableCreateAuthorityKind] =
    useState(true);
  const [disableCreateAuthorityArea, setDisableCreateAuthorityArea] =
    useState(true);
  const [disableCreateAuthority, setDisableCreateAuthority] = useState(true);

  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [disableAddingOrEditing, setDisableAddingOrEditing] = useState(true);

  const [data, setData] = useState("");

  const [clickedButton, setClickedButton] = useState("");

  const [groupData] = useState(new AuthorityGroupModel());

  useEffect(() => {
    getAuthorityGroup();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const AddAuthorityToTable = () => {
    const obj = { name: "authorities", value: authorities };
    const result = handleSave(obj, authorities, errors, dataSchema, t);
    setAuthorities(result.data);
    setErrors(result.errors);
    setAuthoritiesData([...authoritiesData, authority]);
    resetSelectLists();
  };

  const saveEditAuthority = () => {
      const obj = { name: "authorities", value: authorities };
      const result = handleSave(obj, authorities, errors, dataSchema, t);
      setErrors(result.errors);

      let dataCopy = [...authoritiesData];
      dataCopy[rowId] = authority;
      setAuthoritiesData(dataCopy);
      resetSelectLists();
  }

  const CancelAddingAuthorityToTable = () => {
    resetSelectLists();
  };

  const resetSelectLists = () => {
    setVisible(false);
    setAuthority(new CreateDataAuthorityModel());
    setAuthorityKinds([]);
    setAuthorityAreas([]);
    setAuthorities([]);
    setDisableCreateAuthorityArea(true);
    setDisableCreateAuthorityKind(true);
    setDisableCreateAuthority(true);
    setIsEdit(false);
    setDisableAddingOrEditing(true);
  };

  const getAuthorityGroup = async () => {
    let response = await client.getAllActiveAuthorityGroup(true);
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorityGroups(array);
  };

  const onChangeAuthorityGroup = async (id, label) => {
    setAuthority({
      ...authority,
      authorityGroupId: id,
      authorityGroupName: label,
    });
    let response = await client.getAllActiveAuthorityKindsByAuhtorityGroupId(
      id,
      true
    );
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorityKinds(array);
    setDisableAddingOrEditing(true);
    setDisableCreateAuthorityKind(false);
    setDisableCreateAuthorityArea(true);
    setDisableCreateAuthority(true);
    setAuthorityAreas([]);
    setAuthorities([]);
  };

  const onChangeAuthorityKind = async (id, label) => {
    setAuthority({
      ...authority,
      authorityKindId: id,
      authorityKindName: label,
    });
    let response = await client.getAllActiveAuthorityAreasByAuhtorityKindId(
      id,
      true
    );
    let handledResponse = HandlingResponse(response);
    let array = handledResponse.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorityAreas(array);
    setDisableAddingOrEditing(true);
    setAuthorities([]);
    setDisableCreateAuthorityArea(false);
    setDisableCreateAuthority(true);
  };

  const onChangeAuthorityArea = async (id, label) => {
    setAuthority({
      ...authority,
      authorityAreaId: id,
      authorityAreaName: label,
    });
    let response = await client.getAllActiveAuthoritiesByAuthorityAreaId(
      id,
      true
    );
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorities(array);
    setDisableAddingOrEditing(true);
    setDisableCreateAuthority(false);
  };

  const createAuthorityGroup = () => {
    setClickedButton("group");
    setTitle(t("CreateAuthorityGroup"));
    showModal();
  };
  const createAuthorityKind = () => {
    setClickedButton("kind");
    setTitle(t("CreateAuthorityKind"));
    setData({
      authorityGroupId: authority.authorityGroupId,
      authorityGroupName: authority.authorityGroupName,
      active: true,
      weight: 0,
    });
    showModal();
  };
  const createAuthorityArea = () => {
    setClickedButton("area");
    setTitle(t("CreateAuthorityArea"));
    setData({
      authorityGroupId: authority.authorityGroupId,
      authorityGroupName: authority.authorityGroupName,
      authorityKindId: authority.authorityKindId,
      authorityKindName: authority.authorityKindName,
      active: true,
      weight: 0,
    });
    showModal();
  };
  const createAuthority = () => {
    setClickedButton("authority");
    setTitle(t("CreateAuthority"));
    setData({
      authorityGroupId: authority.authorityGroupId,
      authorityGroupName: authority.authorityGroupName,
      authorityKindId: authority.authorityKindId,
      authorityKindName: authority.authorityKindName,
      authorityAreaId: authority.authorityAreaId,
      authorityAreaName: authority.authorityAreaName,
      active: true,
      weight: 0,
    });
    showModal();
  };

  const handleAddingToList = (childData) => {
    hideModal();
    childData.active &&
      (clickedButton === "group"
        ? setAuthorityGroups([
            ...authorityGroups,
            { value: childData.id, label: childData.name },
          ])
        : clickedButton === "kind"
        ? setAuthorityKinds([
            ...authorityKinds,
            { value: childData.id, label: childData.name },
          ])
        : clickedButton === "area"
        ? setAuthorityAreas([
            ...authorityAreas,
            { value: childData.id, label: childData.name },
          ])
        : setAuthorities([
            ...authorities,
            { value: childData.id, label: childData.name },
          ]));
  };

  const editElement = async (rowData, id) => {
    setVisible(true);
    setIsEdit(true);
    rowData.authorityId && setDisableAddingOrEditing(false);
    setRowId(id);
    getAuthorityGroup();

    let response = await client.getAllActiveAuthorityKindsByAuhtorityGroupId(rowData.authorityGroupId, true);
    let handledResponse = HandlingResponse(response);
    let array = handledResponse.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorityKinds(array);
    setDisableCreateAuthorityKind(false);

    response = await client.getAllActiveAuthorityAreasByAuhtorityKindId(rowData.authorityKindId, true);
    handledResponse = HandlingResponse(response);
    array = handledResponse.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorityAreas(array);
    setDisableCreateAuthorityArea(false);

    response = await client.getAllActiveAuthoritiesByAuthorityAreaId(rowData.authorityAreaId, true);
    handledResponse = HandlingResponse(response);
    array = handledResponse.map((x) => {
      return { value: x.id, label: x.name };
    });
    setAuthorities(array);
    setDisableCreateAuthority(false);

    setAuthority(rowData);

  }

  const removeElement = (id) => {
    const arrayCopy = [...authoritiesData];
    arrayCopy.splice(id, 1);
    setAuthoritiesData(arrayCopy);
  };

  return (
    <>
      <Collapse open={visible} setOpen={setVisible} title={
    <span>
      Додавање доносилаца
      <span style={{ color: 'red' }}> *</span>
    </span>
  }>
        <div className="row">
          <div className="row col-6">
            <label htmlFor="authorityGroup">{t("AuthorityGroup")}</label>
            <div className="col-8">
              <Select
                id="authorityGroup"
                options={authorityGroups}
                onChange={(event) => {
                  onChangeAuthorityGroup(event.value, event.label);
                }}
                value={authorityGroups.filter(
                  (x) => x.value === authority.authorityGroupId
                )}
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-4">
                <button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  className="btn btn-primary"
                  onClick={createAuthorityGroup}
                >
                  {t("AddGroup")}
                </button>
              </div>
            </RenderOnRole>
          </div>

          <div className="row col-6">
            <label htmlFor="authorityKind">{t("AuthorityKind")} </label>
            <div className="col-8">
              <Select
                id="authorityKind"
                options={authorityKinds}
                onChange={(event) =>
                  onChangeAuthorityKind(event.value, event.label)
                }
                value={authorityKinds.filter(
                  (x) => x.value === authority.authorityKindId
                )}
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-4">
                <button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  disabled={disableCreateAuthorityKind}
                  className="btn btn-primary"
                  onClick={createAuthorityKind}
                >
                  {t("AddKind")}
                </button>
              </div>
            </RenderOnRole>
          </div>
        </div>
        <div className="row">
          <div className="row col-6">
            <label htmlFor="authorityArea">{t("Area")}</label>
            <div className="col-8">
              <Select
                id="authorityArea"
                options={authorityAreas}
                onChange={(event) =>
                  onChangeAuthorityArea(event.value, event.label)
                }
                value={authorityAreas.filter(
                  (x) => x.value === authority.authorityAreaId
                )}
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-4">
                <button
                  type="button"
                  data-modal="DodajMaterijuId"
                  disabled={disableCreateAuthorityArea}
                  className="btn btn-primary"
                  onClick={createAuthorityArea}
                  style={{ marginLeft: "10px" }}
                >
                  {t("AddArea")}
                </button>
              </div>
            </RenderOnRole>
          </div>
          <div className="row col-6">
            <label htmlFor="authority">{t("Authority")}</label>
            <div className="col-8">
              <Select
                id="authority"
                options={authorities}
                onChange={(event) => {
                  setDisableAddingOrEditing(false);
                  setAuthority({
                    ...authority,
                    authorityId: event.value,
                    authorityName: event.label,
                  });
                }}
                value={authorities.filter(
                  (x) => x.value === authority.authorityId
                )}
              />
            </div>
            <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
              <div className="col-4">
                <button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  disabled={disableCreateAuthority}
                  className="btn btn-primary"
                  onClick={createAuthority}
                >
                  {t("AddAuthority")}
                </button>
              </div>
            </RenderOnRole>
          </div>
        </div>
        <br />
        <button
          type="button"
          className="btn btn-primary"
          onClick={isEdit ? saveEditAuthority : AddAuthorityToTable}
          disabled={disableAddingOrEditing
            //authority.authorityId ? "" : "disabled"
          }
        >
          {isEdit ? "Сачувај" : "Додај"}
        </button>
        <button
          type="button"
          style={{ marginLeft: "10px" }}
          className="btn btn-primary"
          onClick={() => CancelAddingAuthorityToTable()}
        >
          {t("Cancel")}
        </button>
      </Collapse>
      {authoritiesData.length > 0 ? (
        <Table
          columns={columns}
          data={authoritiesData}
          rememberFilter={false}
          serverSide={false}
        />
      ) : (
        <></>
      )}
      {errors?.authorities && (
        <div className="text-danger">{errors.authorities}</div>
      )}
      <SideModal show={show} handleClose={hideModal} title={title}>
        {clickedButton === "group" ? (
          <CreateAuthorityGroup
            row={groupData}
            isEdit={false}
            handleCallback={handleAddingToList}
          />
        ) : clickedButton === "kind" ? (
          <CreateAuthorityKind
            row={data}
            isEdit={false}
            handleCallback={handleAddingToList}
          />
        ) : clickedButton === "area" ? (
          <CreateAuthorityArea
            row={data}
            isEdit={false}
            handleCallback={handleAddingToList}
          />
        ) : clickedButton === "authority" ? (
          <CreateAuthority
            row={data}
            isEdit={false}
            handleCallback={handleAddingToList}
          />
        ) : (
          <></>
        )}
      </SideModal>
    </>
  );
};

export default Authority;
