import React, { useState } from "react";
import { TabContainer } from "./Order.style";
import OrdersList from "../../components/orders-list/OrdersList.component";
import { OrderEnum } from "../../models/OdrerModel";

const Order = () => {
  const tabs = [
    {
      label: "Нове наруџбине",
    },
    {
      label: "Наруџбине на чекању",
    },
    {
      label: "Одобрене наруџбине",
    },
    {
      label: "Одбијене наруџбине",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <TabContainer>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </TabContainer>
      {activeTab === 0 ? (
        <OrdersList orderStatus={OrderEnum.New} />
      ) : activeTab === 1 ? (
        <OrdersList orderStatus={OrderEnum.Pending} />
      ) : activeTab === 2 ? (
        <OrdersList orderStatus={OrderEnum.Approved} />
      ) : (
        <OrdersList orderStatus={OrderEnum.Cancelled} />
      )}
    </div>
  );
};

export default Order;
