import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {ActTypeModel, ActTypeSchema} from "../../models/Codebook";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";
import ActTypeClient from "../../api-services/act-type/act-type-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const CreateActType = ({ row, isEdit, handleCallback }) => {
    const { t } = useTranslation();
    const [advertisementAndActType, setAdvertisementAndActType] = useState(new ActTypeModel());
    const [errors, setErrors] = useState({});

    const client = new ActTypeClient();

    useEffect(() => {
        setAdvertisementAndActType(row);
        setErrors({});
    }, [row]);

    const createActType = async () => {
        const errorsResult = validateForm(advertisementAndActType, ActTypeSchema, t);
        setErrors(errorsResult);

        if(!errorsResult) {
            const response = await client.createActType(advertisementAndActType);
            const handledResponse = HandlingResponse(response);

            if(handledResponse === 408) {
                const result = serverValidation(handledResponse);
                setErrors(result);
            } else {
                handleCallback(handledResponse);
            }
        }
    }

    const updateActType = async () => {
        const errorsResult = validateForm(advertisementAndActType, ActTypeSchema);
        setErrors(errorsResult);

        if(!errorsResult) {
            const response = await client.updateActType(advertisementAndActType);
            const handledResponse = HandlingResponse(response);

            if(handledResponse === 408) {
                const result = serverValidation(handledResponse);
                setErrors(result)
            } else {
                handleCallback(handledResponse);
            }
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date
        ? { name: date, value: event }
        : { name: event.name, value: event.value };
        const result = handleSave(obj, advertisementAndActType, errors, ActTypeSchema, t);
        setAdvertisementAndActType(result.data);
        setErrors(result.errors);
    }

    return (
        <>
          <div className="col-md">
            <label>{t("Description")}<span className="required-asterisk">*</span></label>
            <input
              className="form-control"
              type="text"
              name="description"
              value={advertisementAndActType.description}
              onChange={(event) => handlePropertyChange(event.target)}
            />
            {errors?.description && (
              <div className="text-danger">{errors.description}</div>
            )}
            <label>{t("DefaultSort")}<span className="required-asterisk">*</span></label>
            <input
              className="form-control"
              type="text"
              name="defaultSort"
              value={advertisementAndActType.defaultSort}
              onChange={(event) => handlePropertyChange(event.target)}
            />
            {errors?.defaultSort && (
              <div className="text-danger">{errors.defaultSort}</div>
            )}
          <div style={{ display: 'flex' }} >
            <div>
                <input
                style={{ marginLeft: "5px" }}
                id="advertisementAndActTypeActiveCB"
                type="checkbox"
                value={advertisementAndActType.active}
                checked={advertisementAndActType.active}
                onChange={(event) => {
                    setAdvertisementAndActType({
                    ...advertisementAndActType,
                    active: event.target.checked,
                    });
                }}
                />
                <label style={{ allignContet: 'center' }} htmlFor="advertisementAndActTypeActiveCB">{t("Active")}</label>
            </div>
          </div>
          </div>
          <div className="text-right">
            <button
              className="btn btn-primary m-2"
              onClick={
                isEdit
                  ? updateActType
                  : createActType
              }
            >
              {t("Save")}
            </button>
          </div>
        </>
      );
}

export default CreateActType;