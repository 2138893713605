import { useEffect, useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UserClient from "../../api-services/create-users/create-users-client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateSubscriber = ({ row, edit, handleAddingDataToTable }) => {
    const [user, setUser] = useState({
        userType: "1",
        legalEntityName: "",
        pib: "",
        legalAddress: "",
        name: "",
        lastName: "",
        email: "",
        telephone: "",
        address: "",
        company: "",
        sessionsNumber: "",
        smsAuth: false,
        smsNumber: "",
        userName: "",
        password: "",
        initialPassword: "",
        userGuid: "",
        isInternal: false,
        id: 0
    })

    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [notValid, setNotValid] = useState(false);

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        legalEntityName: user.userType === "1" ? Joi.string().required() : Joi.string().allow("").optional(),
        pib: user.userType === "1" ? Joi.string().max(10).required() : Joi.string().max(10).allow("").optional(),
        name: Joi.string().required(),
        lastName: Joi.string().required(),
        email: Joi.string().email().required(),
        userName: Joi.string().required(),
        password: Joi.string().min(6).required(),
    }

    const client = new UserClient();

    useEffect(() => {
        setUser(row);
        setErrors({});
        setPasswordRepeat("");
        setNotValid(false)
    }, [row]);

    const create = async () => {
        if (edit) {
            schema.password = Joi.optional();
        }
        const errorsResult = validateForm(user, schema, t);
        setErrors(errorsResult);

        if (!errorsResult && !notValid) {
            const data = {
                ...user,
                isLegalEntity: user.userType === "2" ? false : true,
                isGroupRequest: false,
                isInternal: false,
            };
            var response;
            if (edit) {
                response = await client.UpdateUser(user.userGuid, data);
            }
            else {
                response = await client.CreateUser(data);
            }
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            } else handleAddingDataToTable(handledResponse)
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, user, errors, schema, t);
        setUser(result.data);
        setErrors(result.errors);
    }

    const checkPassword = (password, passwordRepeat) => {
        if (password !== passwordRepeat) {
          setNotValid(true);
        } else {
          setNotValid(false);
        }
      }

    return (
      <>
        <div className="form-group">
          <input
            type="radio"
            name="userType"
            value="1"
            checked={user.userType === "1" ? "checked" : ""}
            onChange={(e) => {
              setUser({ ...user, userType: e.target.value })
              setErrors({})
            }
          }
          />{" "}
          Правно лице &nbsp;

          <input
            type="radio"
            name="userType"
            value="2"
            checked={user.userType === "2" ? "checked" : ""}
            onChange={(e) => {
              setUser({ ...user, userType: e.target.value, legalEntityName: "", pib: "", legalAddress: "" })
              setErrors({})
            }
          }
          />{" "}
          Физичко лице
        </div>
        <div className="form-group">
          <label>Назив фирме{user.userType === "1" && <span className="required-asterisk">*</span>}</label>
          <input
            type="text"
            className="form-control"
            value={user.legalEntityName}
            name="legalEntityName"
            disabled={user.userType === "1" ? "" : "disabled"}
            onChange={(e) => handlePropertyChange(e.target)}
          />
           {errors?.legalEntityName && <div className="text-danger">{errors.legalEntityName}</div>}
        </div>
        <div className="form-group">
          <label>ПИБ{user.userType === "1" && <span className="required-asterisk">*</span>}</label>
          <input
            type="text"
            className="form-control"
            name="pib"
            value={user.pib}
            disabled={user.userType === "1" ? "" : "disabled"}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.pib && <div className="text-danger">{errors.pib}</div>}
        </div>
        <div className="form-group">
          <label>Адреса правног лица</label>
          <input
            type="text"
            className="form-control"
            name="legalAddress"
            value={user.legalAddress}
            disabled={user.userType === "1" ? "" : "disabled"}
            onChange={(e) => setUser({ ...user, legalAddress: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Име<span className="required-asterisk">*</span></label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={user.name}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Презиме<span className="required-asterisk">*</span></label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            value={user.lastName}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.lastName && (
            <div className="text-danger">{errors.lastName}</div>
          )}
        </div>
        <div className="form-group">
          <label>Е-маил<span className="required-asterisk">*</span></label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={user.email}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.email && <div className="text-danger">{errors.email}</div>}
        </div>
        <div className="form-group">
          <label>Телефон</label>
          <input
            type="text"
            className="form-control"
            name="telephone"
            value={user.telephone}
            onChange={(e) => setUser({ ...user, telephone: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Адреса</label>
          <input
            type="text"
            className="form-control"
            name="address"
            value={user.address}
            onChange={(e) => setUser({ ...user, address: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Компанија</label>
          <input
            type="text"
            className="form-control"
            name="company"
            value={user.company}
            onChange={(e) => setUser({ ...user, company: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Мобилни телефон</label>
          <input
            type="text"
            className="form-control"
            name="smsNumber"
            value={user.smsNumber}
            onChange={(e) => setUser({ ...user, smsNumber: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Корисничко име<span className="required-asterisk">*</span></label>
          <input
            disabled={edit}
            type="text"
            className="form-control"
            name="userName"
            value={user.userName}
            onChange={(e) => handlePropertyChange(e.target)}
            autoComplete="off"
          />
          {errors?.userName && (
            <div className="text-danger">{errors.userName}</div>
          )}
        </div>
        {!edit && (
          <>
            <div className="form-group">
              <label>Лозинка<span className="required-asterisk">*</span></label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={user.password}
                onChange={(e) => {
                  handlePropertyChange(e.target);
                  passwordRepeat !== "" &&
                    checkPassword(user.password, e.target.value);
                }}
                autoComplete="off"
              />
              {errors?.password && (
                <div className="text-danger">{errors.password}</div>
              )}
            </div>
            <div className="form-group">
              <label>Поновите лозинку</label>
              <input
                disabled={user.password === "" ? "disabled" : ""}
                type="password"
                className="form-control"
                value={passwordRepeat}
                onChange={(e) => {
                  setPasswordRepeat(e.target.value);
                  checkPassword(user.password, e.target.value);
                }}
              />
              {notValid && (
                <div className="text-danger">Лозинке се не поклапају!</div>
              )}
            </div>
          </>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => create()}
        >
          {edit ? "Измени" : "Креирај"}
        </button>
      </>
    );
}

export default CreateSubscriber;