import { createContext, useState, useEffect } from "react"
import { PreviewLawActModel, schema } from "../models/PreviewLawAct";
import { useTranslation } from "react-i18next"; 
const FormContext = createContext({})

export const FormProvider = ({ children }) => {
    const { t } = useTranslation();
    const title = {
        1: 'Основни подаци',
        2: 'Службена гласила',
        3: 'Везе',
        4: 'Остало',
        5: 'Историјат измена'
    }

    const [page, setPage] = useState('1');

    const [data, setData] = useState(new PreviewLawActModel());
    const [errors, setErrors] = useState({});

    const handleChangeInput = e => {
        const type = e.target.type;
        const name = e.target.name;
        const model = e.target.getAttribute("data-model");
        
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value;

            setData(prevData => {
                const newData = {...prevData}
                newData[model][name] = value;
                return newData;
            })
    };

    const handleChangeList = e => {
        const name = e.name;
        const value = e.value;
        const model  = e.model;
        setData(prevData => {
            const newData = {...prevData}
            newData[model][name] = value;
            return newData;
        })
    };

    const handleChangeDate = (date, name, model) => {
        setData(prevData => {
            const newData = {...prevData}
            newData[model][name] = date;
            return newData;
        })
    };

    const handleChangePage = (event, newValue) => {
        setPage(newValue);
      };
    
    return (
        <FormContext.Provider value={{title, page, handleChangePage, data, setData, errors, setErrors, handleChangeInput, handleChangeList, handleChangeDate }}>
            {children}
        </FormContext.Provider>
    )
}

export default FormContext 