import {ShiftLawActModel} from './AddLawAct';

class MoveLawActsModel {
    constructor(lawActsGuids= [], lawActsIds = [], shiftLawAct = new ShiftLawActModel(), oldAreaId = 0, parentGuid= "") {
        this.lawActsGuids = lawActsGuids;
        this.lawActsIds = lawActsIds;
        this.shiftLawAct = shiftLawAct;
        this.oldAreaId = oldAreaId;
        this.parentGuid = parentGuid;
    }
}

export { MoveLawActsModel }