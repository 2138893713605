import React, { useState, useRef, useEffect } from "react";
import PreviewNewsClient from "../../../api-services/news/preview/preview";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker.component";
import { useTranslation } from "react-i18next";

const SearchPreviewNews = ({
  newsFilterData,
  setNewsFilterData,
  handleEnumChange,
  selectedEnums,
  resetFilter,
  searchData,
}) => {
  const [categoriesList, setCategoriesList] = useState([]);

  var client = new PreviewNewsClient();
  const { t } = useTranslation();

  const getCategories = async () => {
    let response = await client.getCategoriesApi();
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name };
    });
    setCategoriesList(array);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <form onSubmit={searchData}>
      <div className="form-group">
        <div className="row g-3">
          <div className="form-group col-md-3">
            <label>Датум објављивања од:</label>
            <CustomDatePicker
              selected={newsFilterData.publishedDateFrom}
              onChange={(date) =>
                setNewsFilterData({
                  ...newsFilterData,
                  publishedDateFrom: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
          <div className="form-group col-md-3">
            <label>Датум објављивања до:</label>
            <CustomDatePicker
              selected={newsFilterData.publishedDateTo}
              onChange={(date) =>
                setNewsFilterData({
                  ...newsFilterData,
                  publishedDateTo: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
          <div className="form-group col-md-3">
            <label>Скраћени текст</label>
            <input
              className="form-control"
              type="text"
              value={newsFilterData.shortText}
              onChange={(e) =>
                setNewsFilterData({
                  ...newsFilterData,
                  shortText: e.target.value,
                })
              }
            />
          </div>
          <div
            className="form-group col-md-3"
            stye={{
              marginRIght: "0px",
            }}
          >
            <label>Интерно обележје</label>
            <input
              className="form-control"
              type="text"
              value={newsFilterData.internalMark}
              onChange={(e) =>
                setNewsFilterData({
                  ...newsFilterData,
                  internalMark: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <label>Категорије вести:</label>
          <div className="form-group col-md-3" style={{}}>
            <Select
              options={categoriesList}
              isMulti
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(
                  (option) => option.value
                );
                setNewsFilterData({
                  ...newsFilterData,
                  categoryName: selectedValues, // categoryyName na null ako nista nije selektovano
                });
              }}
              value={categoriesList.filter(
                (option) =>
                  newsFilterData.categoryName &&
                  newsFilterData.categoryName.includes(option.value) // provera da li categoryName je defined pre nego sto iskoristimo includes
              )}
            />
          </div>

          <div
            className="form-group col-2"
            style={{
              borderRadius: "5px",
              border: "1px solid rgb(225, 214, 214)",
            }}
          >
            <>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={selectedEnums.published === "2"}
                    onChange={handleEnumChange}
                    name="published"
                  />
                  {"  "}
                  Све
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={selectedEnums.published === "1"}
                    onChange={handleEnumChange}
                    name="published"
                  />
                  {"  "}
                  Објављене
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={selectedEnums.published === "0"}
                    onChange={handleEnumChange}
                    name="published"
                  />
                  {"  "}
                  Необјављене
                </label>
              </div>
            </>
          </div>

          <div
            className="form-group col-2"
            style={{
              borderRadius: "5px",
              border: "1px solid rgb(225, 214, 214)",
              marginLeft: "5px",
            }}
          >
            <div>
              <>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="2"
                      checked={selectedEnums.actual === "2"}
                      onChange={handleEnumChange}
                      name="actual"
                    />
                    {"  "}
                    Све
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      checked={selectedEnums.actual === "1"}
                      onChange={handleEnumChange}
                      name="actual"
                    />
                    {"  "}
                    Актуелне
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="0"
                      checked={selectedEnums.actual === "0"}
                      onChange={handleEnumChange}
                      name="actual"
                    />
                    {"  "}
                    Архивске
                  </label>
                </div>
              </>
            </div>
          </div>

          <div
            className="form-group col-2"
            style={{
              borderRadius: "5px",
              border: "1px solid rgb(225, 214, 214)",
              marginLeft: "5px",
            }}
          >
            <>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={selectedEnums.breaking === "2"}
                    onChange={handleEnumChange}
                    name="breaking"
                  />
                  {"  "}
                  Све
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={selectedEnums.breaking === "1"}
                    onChange={handleEnumChange}
                    name="breaking"
                  />
                  {"  "}
                  Ударне
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={selectedEnums.breaking === "0"}
                    onChange={handleEnumChange}
                    name="breaking"
                  />
                  {"  "}
                  Остале
                </label>
              </div>
            </>
          </div>

          <div
            className="form-group col-2"
            style={{
              borderRadius: "5px",
              border: "1px solid rgb(225, 214, 214)",
              marginLeft: "5px",
            }}
          >
            <>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={selectedEnums.includeInSearchResult === "2"}
                    onChange={handleEnumChange}
                    name="includeInSearchResult"
                  />
                  {"  "}
                  Све
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={selectedEnums.includeInSearchResult === "1"}
                    onChange={handleEnumChange}
                    name="includeInSearchResult"
                  />
                  {"  "}
                  Укључене у претрагу
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={selectedEnums.includeInSearchResult === "0"}
                    onChange={handleEnumChange}
                    name="includeInSearchResult"
                  />
                  {"  "}
                  Искључене из претраге
                </label>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary form-group"
          style={{ marginLeft: "5px" }}
        >
          Претрага
        </button>
        <button
          type="button"
          className="btn btn-primary form-group"
          style={{ marginLeft: "5px" }}
          onClick={() => {
            resetFilter();
          }}
        >
          Поништи претрагу
        </button>
      </div>
    </form>
  );
};

export default SearchPreviewNews;
