import { useState, useEffect, useRef } from "react";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import LawAdActClient from "../../../api-services/law-ad-act/law-ad-act-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../../common/validation/validation";
import { serializeObjectIntoFormData } from "../../../../utils/serialize-form-data/serializeFormData";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import AuthorityClient from "../../../../ADMIN-PANEL/api-services/authority/authority-client";
import AdvertisementAndActTypeClient from "../../../../ADMIN-PANEL/api-services/advertisement-and-act-type/advertisement-and-act-type";
import SubmitFile from "../../../../common/submit-file/SubmitFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn } from "../../../../common/multiple-files/multipleFiles.style";

const CreateAct = ({ data, edit, handleCallBack }) => {
  const [advertisementTypeList, setAdvertisementTypeList] = useState([]);
  const [file, setFile] = useState();
  const [authorityList, setAuthorityList] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const htmlInputRef = useRef(null);
  

  const [ActData, setActData] = useState({
    lawAdActName: "",
    isLaw: false,
    itemNumber: 0,
    ogPageNumber: 0,
    fkNumber: 0,
    lawAdActAuthorityName: "",
    advertisementAndActTypeName: "",
    lawAdActAuthorityGuid: undefined,
    advertisementAndActTypeGuid: undefined,
    htmlLawAdActUrl: "",
    id: 0,
    publish: false,
  });

  // Validacija, errors, t

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    lawAdActName: Joi.string().required(),
    itemNumber: Joi.number().min(0).max(2147483647).required(),
    ogPageNumber: Joi.number().min(0).max(2147483647).required(),
    lawAdActAuthorityGuid: Joi.required(),
    advertisementAndActTypeGuid: Joi.required(),
  };

  const client = new LawAdActClient();
  const authorityClient = new AuthorityClient();
  const advertisementClient = new AdvertisementAndActTypeClient();

  useEffect(() => {
    getActiveAuthoritiesList();
    GetAdvertisementAndActTypesForOgByAuthorityGuid(data.lawAdActAuthorityGuid);
  }, []);

  useEffect(() => {
    setActData(data);
    setErrors({});
    setFile(data.file);
    setAttachmentFiles(data.attachments);
    setPhotoFiles(data.images);
  }, [data]);

  // Lista donosioca

  const getActiveAuthoritiesList = async () => {
    let response = await authorityClient.Authority();
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return {
        value: x.guid,
        label: x.authorityName,
        name: "lawAdActAuthorityGuid",
      };
    });
    setAuthorityList(array);
  };

  const GetAdvertisementAndActTypesForOgByAuthorityGuid = async (
    authorityGuid
  ) => {
    let response =
      await advertisementClient.GetAdvertisementAndActTypesForOgByAuthorityGuid(
        authorityGuid,
        data.isLaw,
        true
      );
    let res = HandlingResponse(response);
    if (res.length > 0) {
      let array = res?.map((x) => {
        return {
          value: x.guid,
          label: x.description,
          name: "advertisementAndActTypeGuid",
        };
      });
      setAdvertisementTypeList(array);
    } else setAdvertisementTypeList([]);
  };

  // Create/update funckija sa validacijom

  const createAct = async () => {
    const errorsResult = validateForm(ActData, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var data = {
        ...ActData,
        fkNumber: parseInt(ActData.fkNumber),
        itemNumber: parseInt(ActData.itemNumber),
        ogPagenumber: parseInt(ActData.ogPageNumber),
        file: file,
        attachments: attachmentFiles,
        images: photoFiles,
      };

      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      file?.guid
        ? formData.append("file.guid", file?.guid)
        : formData.append("file", file);

      for (let i = 0; i < photoFiles?.length; i++) {
        if (photoFiles[i].guid)
          formData.append("images[" + i + "].guid", photoFiles[i].guid);
        else formData.append("images[" + i + "].file", photoFiles[i]);

        formData.append("images[" + i + "].name", photoFiles[i].name);
      }
      for (let i = 0; i < attachmentFiles?.length; i++) {
        if (attachmentFiles[i].guid)
          formData.append(
            "attachments[" + i + "].guid",
            attachmentFiles[i].guid
          );
        else formData.append("attachments[" + i + "].file", attachmentFiles[i]);
        formData.append("attachments[" + i + "].name", attachmentFiles[i].name);
      }

      var response;
      if (edit) {
        response = await client.updateLawAdAct(formData);
      } else {
        response = await client.createArchiveLawAdAct(formData);
      }
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      } else handleCallBack(handledResponse);
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, ActData, errors, schema, t);
    //svaki put kad se promeni donosilac, vrsta mora da se isprazni
    event.name === "lawAdActAuthorityGuid"
      ? setActData({ ...result.data, advertisementAndActTypeGuid: undefined })
      : setActData(result.data);
    setErrors(result.errors);
  };

  const downloadFilesFromRepo = async () => {
    var zipName = ActData.id + "_" + ActData.lawAdActName;
    if (zipName.length > 30) zipName = zipName.substring(0, 30);
    var response = await client.DownloadFilesFromRepo(ActData.id, zipName);
    HandlingResponse(response);
  };

  const getLawAdActHtml = async () => {
    var response = await client.getLawAdActHtml(ActData.id);
    return response;
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  return (
    <>
      <div className="form-group">
        <label>
          Назив<span className="required-asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          name="lawAdActName"
          value={ActData.lawAdActName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.lawAdActName && (
          <div className="text-danger">{errors.lawAdActName}</div>
        )}
      </div>
      <div className="form-group">
        <label>
          Редни број<span className="required-asterisk">*</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="itemNumber"
          value={ActData.itemNumber}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.itemNumber && (
          <div className="text-danger">{errors.itemNumber}</div>
        )}
      </div>
      <div className="form-group">
        <label>
          Број стране<span className="required-asterisk">*</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="ogPageNumber"
          value={ActData.ogPageNumber}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.ogPageNumber && (
          <div className="text-danger">{errors.ogPageNumber}</div>
        )}
      </div>
      <div className="form-group">
        <label>
          Доносилац<span className="required-asterisk">*</span>
        </label>
        <Select
          options={authorityList}
          onChange={(e) => {
            handlePropertyChange(e);
            GetAdvertisementAndActTypesForOgByAuthorityGuid(e.value);
          }}
          value={authorityList.filter(
            (x) => x.value === ActData.lawAdActAuthorityGuid
          )}
        />
        {errors?.lawAdActAuthorityGuid && (
          <div className="text-danger">{errors.lawAdActAuthorityGuid}</div>
        )}
      </div>
      <div className="form-group">
        <label>
          Врста<span className="required-asterisk">*</span>
        </label>
        <Select
          options={advertisementTypeList}
          onChange={(event) => handlePropertyChange(event)}
          value={advertisementTypeList.filter(
            (x) => x.value === ActData.advertisementAndActTypeGuid
          )}
        />
        {errors?.advertisementAndActTypeGuid && (
          <div className="text-danger">
            {errors.advertisementAndActTypeGuid}
          </div>
        )}
        <br />
        <div>
          {!file && (
            <>
            <NewFileBtn onClick={handleUploadHtmlClick} type="button">
              <FontAwesomeIcon icon={solid("plus")} />
              Додај ХТМЛ фајл
            </NewFileBtn>
            или
            </>
          )}
          <input
            type="file"
            ref={htmlInputRef}
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
            hidden
            accept=".html, .htm"
            name="file"
          />
          <SubmitFile
            mainFile={file}
            setMainFile={setFile}
            photoFiles={photoFiles}
            setPhotoFiles={setPhotoFiles}
            attachmentFiles={attachmentFiles}
            setAttachmentFiles={setAttachmentFiles}
            isEdit={file?.guid}
            downloadFilesFromRepo={downloadFilesFromRepo}
            getHtml={getLawAdActHtml}
            id={ActData.id}
            pathToNavigate={window.location.pathname}
            showEditHTMLButton={false}
          ></SubmitFile>
          {errors?.file && <div className="text-danger">{errors.file}</div>}
        </div>
      </div>
      <br />
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginTop: "20px" }}
        onClick={() => createAct()}
      >
        {edit ? "Измени" : "Креирај"}
      </button>
    </>
  );
};

export default CreateAct;
