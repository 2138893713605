import Joi from "joi-browser";

class SourceKindModel {
    constructor(id = 0, name = "", weight = 0, active = true) {
        this.id = id;
        this.name = name;
        this.weight = weight;
        this.active = active;
    }
}

export const SourceKindSchema = {
    name: Joi.string().max(250).required(),
    weight: Joi.number().min(0).max(2147483647).required()
}

export {SourceKindModel}