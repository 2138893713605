import React, { useState, useEffect } from "react";
import AuthorityClient from "../../api-services/authority/authority-client";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const EditAuthorityAdvertisementAndActType = ({ row, handleCallback }) => {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        sort: Joi.string().min(5).required()
    }

    var client = new AuthorityClient();
    const [AuthorityAdvertisementAndActType, setAuthorityAdvertisementAndActType] = useState({});

    useEffect(() => {
        setAuthorityAdvertisementAndActType(row);
        setErrors({});
    }, [row]);

    const updateAuthorityAdvertisementAndActType = async () => {
        const errorsResult = validateForm(AuthorityAdvertisementAndActType, schema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            var response = await client.updateAuthorityAdvertisementAndActType(AuthorityAdvertisementAndActType);
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
            else
                handleCallback(handledResponse);
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, AuthorityAdvertisementAndActType, errors, schema, t);
        setAuthorityAdvertisementAndActType(result.data);
        setErrors(result.errors);
    }

    return (
        <form onSubmit={updateAuthorityAdvertisementAndActType}>
            <div className="col-md">
                <label>{t("Description")}</label>
                <input
                    className="form-control"
                    type="text"
                    value={AuthorityAdvertisementAndActType.description}
                    disabled
                />
                <label>{t("Sort")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="sort"
                    value={AuthorityAdvertisementAndActType.sort}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.sort && (
                    <div className="text-danger">
                        {errors.sort}
                    </div>
                )}
            </div>
            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={updateAuthorityAdvertisementAndActType}
                >
                    {t("Save")}
                </button>
            </div>
        </form>
    )
};

export default EditAuthorityAdvertisementAndActType;