import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ProductClient from "../../api-services/product/product-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UserClient from "../../api-services/user/user-client";

const UserSubscriptions = ({ handleClose, user }) => {
  const [nodes, setNodes] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const productClient = new ProductClient();
  const userClient = new UserClient();

  useEffect(() => {
    getUserSubscriptions();
    getSubscriptionsForUser();
  }, []);

  useEffect(() => {
    var ids = getNodeIds(nodes);
    setExpanded(ids);
  }, [nodes]);

  const getNodeIds = (nodes) => {
    let ids = [];

    nodes.forEach(({ value, children }) => {
      ids.push(value);
      if (children) {
        ids.push(...getNodeIds(children));
      }
    });
    return ids;
  };

  const getSubscriptionsForUser = async () => {
    var response = await productClient.getSubscriptionsByUser(user.id);
    let data = HandlingResponse(response);
    if (data != "Error") {
      var mappedNodes = mapDataToNodes(data);
      setNodes(mappedNodes);
    }
  };

  const mapDataToNodes = (data) => {
    return data.map((node) => ({
      value: String(node.id),
      label: node.name,
      children: node.children.length > 0 ? mapDataToNodes(node.children) : null,
    }));
  };

  const getUserSubscriptions = async () => {
    var response = await userClient.getUserSubscriptions(user.id);
    let data = HandlingResponse(response);
    setChecked(data);
  };

  const updateUserSubscriptions = async (event) => {
    event.preventDefault();
    let arrayOfCheckedIds = checked.map(Number);
    var response = await userClient.updateUserSubscriptions(
      user.id,
      arrayOfCheckedIds
    );
    let data = HandlingResponse(response);
    if (data != "Error") handleClose();
  };

  return (
    <form onSubmit={updateUserSubscriptions}>
      <div>
        <label>Korisnik: </label> {user?.firstLastName}
      </div>
      <br />
      <div className="expand-all-container">
        <CheckboxTree
          nodes={nodes}
          checked={checked}
          expanded={expanded}
          showExpandAll
          onExpand={(expanded) => setExpanded(expanded)}
          onCheck={(checked) => setChecked(checked)}
          showNodeIcon={false}
          icons={{
            check: (
              <FontAwesomeIcon
                className="rct-icon rct-icon-check"
                icon={regular("check-square")}
              />
            ),
            uncheck: (
              <FontAwesomeIcon
                className="rct-icon rct-icon-uncheck"
                icon={regular("square")}
              />
            ),
            halfCheck: (
              <FontAwesomeIcon
                className="rct-icon rct-icon-half-check"
                icon={regular("check-square")}
              />
            ),
            expandAll: (
              <FontAwesomeIcon
                className="rct-icon rct-icon-expand-all"
                icon={regular("plus-square")}
              />
            ),
            collapseAll: (
              <FontAwesomeIcon
                className="rct-icon rct-icon-collapse-all"
                icon={regular("minus-square")}
              />
            ),
            expandClose: (
              <FontAwesomeIcon
                className="rct-icon-expand-close"
                icon={solid("chevron-right")}
              />
            ),
            expandOpen: (
              <FontAwesomeIcon
                className="rct-icon-expand-open"
                icon={solid("chevron-down")}
              />
            ),
          }}
        />
      </div>
      <br />
      <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ marginTop: "10px", marginLeft: "5px" }}
        >
          Сачувај измене
        </button>
      </div>
    </form>
  );
};

export default UserSubscriptions;
