//pomocna funkcija koja odredjuje sta je selektovano prilikom kreiranja akta/podoblasti ili premestanja
export const valueOfSelectedHierarchyMark = (hierarchyMarkPosition, lawAct) => {
    var selected;
    if (hierarchyMarkPosition == 0)
        if(lawAct.hierarchyMark == "" || lawAct.hierarchyMark == null) selected = "1";
        else selected = lawAct.hierarchyMark?.toString();
    else if (hierarchyMarkPosition == 1)
        selected = lawAct.hierarchyMark + ".1";
    else {
        selected = nextHierarchyMark(lawAct);
    }
    return selected;
};

//pomocna funkcija koja odredjuje koji string treba da se prikaze na "Next" poziciji
export const nextHierarchyMark = (lawAct) => {
    let val;
    let lastIndexOfDot = lawAct.hierarchyMark?.lastIndexOf(".");
    if (lastIndexOfDot != -1) {
        let firstPart = lawAct.hierarchyMark?.substring(0, lastIndexOfDot + 1);
        let secondPart = (
            parseFloat(lawAct.hierarchyMark?.substring(lastIndexOfDot + 1)) + 1
        ).toString();
        val = firstPart + secondPart;
    } else val = (parseFloat(lawAct.hierarchyMark) + 1).toString();
    return val;
};