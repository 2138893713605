import { createContext, useEffect, useLayoutEffect, useState } from "react";

const ThemeContext = createContext(true);

export const ThemeProvider = ({ children }) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (isOverflow) {
      document.body.style.overflow = "hidden";
    } else if (document.getElementsByClassName("Modal")[0]?.classList.contains("show")) 
    document.body.style.overflow = "hidden";
    else
        document.body.style.overflow = "";
  }, [isOverflow]);

  return (
    <ThemeContext.Provider value={{ isOverflow, setIsOverflow }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
