import React, { useState } from "react";
import { NavBarContainer, Logo, LogOutUser } from "./Navigation.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./Navigation.style.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  ADMIN_PANEL,
  MML,
  LOZA,
  SP,
  TEXT_EDITOR,
  SLGL,
  REGS,
  FRONTPAGE,
  NEWSLETTER,
  MO,
  PPP,
  PENG,
  REGULATIONS,
} from "./NavigationLinks";
import useAppUrl from "../../hooks/useAppUrl";
import { listOfAdminPortalApps } from "../pages/index/listOfAllAdminPortalApps";
import UserService from "../../utils/authentification/keycloak";
import RenderOnRole from "../route-and-role/RenderOnRole";
import { navigateToPage } from "../route-and-role/pathBasedOnRole";
import UseRenderDifferentComponent from "../../PPP/hooks/UseRenderDifferentComponent";
import useUnlockAllAreaContext from "../../REGISTRIES/hooks/useLockingAreaContext";
import useLockingAreaContext from "../../REGULATIONS/hooks/useLockingAreaContext";

const Navbar = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();
  const { unlockAllArea } = useUnlockAllAreaContext();
  const { unlockAllRegulationsArea } = useLockingAreaContext();
  const { handleChangeComponent } = UseRenderDifferentComponent();
  const [appUrlForRole, setAppUrlForRole] = useState("");

  let MenuItems = [];
  let logo = "";
  if (appPath.includes("mml")) {
    MenuItems = MML;
  } else if (appPath.includes("loza")) {
    MenuItems = LOZA;
  } else if (appPath.includes("sp")) {
    MenuItems = SP;
  } else if (appPath.includes("adminPanel")) {
    MenuItems = ADMIN_PANEL;
  } else if (appPath.includes("textEditor")) {
    MenuItems = TEXT_EDITOR;
  } else if (appPath.includes("slgl")) {
    MenuItems = SLGL;
  } else if (appPath.includes("registries")) {
    MenuItems = REGS;
  } else if (appPath.includes("frontpage")) {
    MenuItems = FRONTPAGE;
  } else if (appPath.includes("newsletter")) {
    MenuItems = NEWSLETTER;
  } else if (appPath.includes("mo")) {
    MenuItems = MO;
  } else if (appPath.includes("ppp")) {
    MenuItems = PPP;
  } else if (appPath.includes("peng")) {
    MenuItems = PENG;
  } else if (appPath.includes("regulations")) {
    MenuItems = REGULATIONS;
  } else MenuItems = [];

  if (location.pathname === "/" || location.pathname === "/notAllowed") {
    logo = "Уреднички портал";
  } else logo = "УП";

  // useEffect(()=> {
  //   navigateToPage(appUrlForRole, navigate);
  // }, [appUrlForRole])

  const onClickNavigate = (from, path) => {
    navigateToPage(path, navigate);
    if(from.includes('registries'))
      unlockAllArea();
    if(from.includes('regulations'))
      unlockAllRegulationsArea();
  };

  return (
    <>
      <NavBarContainer>
        <Logo>
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => {
                if(window.location.pathname.includes('/registries'))
                  unlockAllArea();
                else if(window.location.pathname.includes('/regulations'))
                  unlockAllRegulationsArea();
                navigate("/");
                setAppPath("/");
                setAppName("");
                sessionStorage.clear();
              }}
            >
              {logo}
            </button>
            {location.pathname !== "/" && (
              <>
                {location.pathname !== "/notAllowed" && "/"}
                <div className="dropdown-content">
                  {listOfAdminPortalApps.map((item, index) => {
                    return (
                      <RenderOnRole key={index} roles={item.roles || []}>
                        <Link
                          key={index}
                          to={item.path}
                          onClick={() => {
                            onClickNavigate(window.location.pathname, item.path);
                            item.path != appUrlForRole &&
                              sessionStorage.clear();
                          }}
                        >
                          {item.name}
                        </Link>
                      </RenderOnRole>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <span className="logo-span" onClick={() => onClickNavigate(window.location.pathname, appPath)}>
            {appName}
          </span>
        </Logo>
        {MenuItems.map((item, index) => {
          return item.links?.length ? (
            <div key={index} className="dropdown">
              <RenderOnRole key={index} roles={item.role}>
                <button className="dropbtn dropbtn-list">
                  {item.buttonName}
                  {item.links?.length > 0 && (
                    <FontAwesomeIcon icon={solid("chevron-down")} />
                  )}
                </button>
                <div className="dropdown-content">
                  {item.links?.map((links, index) => {
                    return (
                      <RenderOnRole
                        key={index}
                        roles={[...item.role, links.role]}
                      >
                        <Link
                          key={index}
                          to={links.path}
                          onClick={() => {
                          if(window.location.pathname.includes('/registries') && item.path !== "/registries")
                             unlockAllArea();
                          else if(window.location.pathname.includes('/regulations') && item.path !== "/regulations")
                            unlockAllRegulationsArea();
                          }}
                        >
                          {links.title}
                        </Link>
                      </RenderOnRole>
                    );
                  })}
                </div>
              </RenderOnRole>
            </div>
          ) : (
            <RenderOnRole key={index} roles={item.role}>
              <Link
                key={index}
                to={item.path}
                //provera ukoliko smo u app ppp kliknuli na pocetna, da ga vrati zaista na pocetnu
                //(nije idealno, ali trenutno jedino resenje)
                onClick={() => {
                  if (item.path === "/ppp") handleChangeComponent(0, 0, "");
                  else if(window.location.pathname.includes('/registries') && item.path !== "/registries")
                     unlockAllArea();
                  else if(window.location.pathname.includes('/regulations') && item.path !== "/regulations")
                    unlockAllRegulationsArea();
                }}
              >
                {item.buttonName}
              </Link>
            </RenderOnRole>
          );
        })}
        {UserService.isLoggedIn() && (
          <LogOutUser>
            <span>
              {UserService.getUsername()}
              <FontAwesomeIcon icon={solid("user")} />
            </span>
            <span>
              <button
                className="logout-btn"
                onClick={() => {
                  if(window.location.pathname.includes('/registries'))
                    unlockAllArea();
                  else if(window.location.pathname.includes('/regulations'))
                    unlockAllRegulationsArea();

                  sessionStorage.clear();
                  localStorage.clear();
                  UserService.doLogout();
                }}
              >
                Одјави се
                <FontAwesomeIcon icon={solid("sign-out")} />
              </button>
            </span>
          </LogOutUser>
        )}
      </NavBarContainer>
    </>
  );
};

export default Navbar;
