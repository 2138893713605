import api from "../../../../api";
import { env } from "../../../../env";

var baseurl = env.REACT_APP_API_URL_FP;

class EventsCalendarClient {
  async filterEvents(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/Event/FilterEvents",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteEventApi(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Event/DeleteEvent/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createEvent(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Event/CreateEvent", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateEvent(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Event/UpdateEvent", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion
}

export default EventsCalendarClient;
