import React, { useEffect, useState } from "react";
import ArticleClient from "../../api-services/article/article-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { ArticleModel, ArticleSchema } from "../../models/ArticleModel";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { productOperationTypes } from "../../models/ProductModel";
import { useTranslation } from "react-i18next";
import { handleSave, validateForm } from "../../../common/validation/validation";

const CreateOrEditArticle = ({ selectedOperationType, selectedArticle, hide, handleRefreshTree }) => {
  const { t } = useTranslation();

  const articleClient = new ArticleClient();
  const [article, setArticle] = useState(new ArticleModel());

  const [descriptionStyles, setDescriptionStyles] = useState([]);
  const [nameStyles, setNameStyles] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    switch (selectedOperationType) {
      case productOperationTypes.addElement:
        setArticle({ ...article, parentId: selectedArticle.parentId, productId: selectedArticle.productId });
        break;
      case productOperationTypes.addSubelement:
        setArticle({ ...article, parentId: selectedArticle.id, productId: selectedArticle.productId });
        break;
      case productOperationTypes.editElement:
        getArticle();
        break;
      default:
        break;
    }
    getStyles();
  }, []);

  const getArticle = async () => {
    var response = await articleClient.getArticle(selectedArticle.id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      setArticle(handledResponse);
    }
  };

  const getStyles = async () => {
    var response = await articleClient.getStyles(selectedArticle.id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      let descriptionStylesArray = handledResponse.map((x) => {
        return { value: x.id, label: x.name, name: "descriptionStyleId" };
      });
      let nameStylesArray = handledResponse.map((x) => {
        return { value: x.id, label: x.name, name: "nameStyleId" };
      });
      setDescriptionStyles(descriptionStylesArray);
      setNameStyles(nameStylesArray);
    }
  };

  const createEditArticle = async (event) => {
    event.preventDefault();

    const errorsResult = validateForm(article, ArticleSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (selectedOperationType === productOperationTypes.editElement) var response = await articleClient.updateArticle(article);
      else response = await articleClient.createArticle(article);
      let handledResponse = HandlingResponse(response);
      handleRefreshTree();
      handledResponse != "Error" && hide();
    }
  };

  const editText = async () => {
    const id = selectedArticle.id;
    window.open(window.location.origin + "/peng/editText?id=" + id, '_blank')
  }

  const handleInputChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setArticle((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeWithValidation = (e) => {
    const obj = { name: e.target.name, value: e.target.value };
    const result = handleSave(obj, article, errors, ArticleSchema, t);
    setArticle(result.data);
    setErrors(result.errors);
  }

  const handleSelectListChange = (e) => {
    const { name, value } = e;
    setArticle((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={createEditArticle}>
      <div className="row">
        <div className="col-8">
          <label>Опис назива (Коментар изнад наслова)</label>
          <input
            className="form-control"
            type="text"
            name="descriptionTitle"
            value={article.descriptionTitle}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-4">
          <label>Стил описа</label>
          <Select
            id="descriptionStyleId"
            options={descriptionStyles}
            onChange={(e) => {
              if (!e) e = { value: null, name: "descriptionStyleId" };
              handleSelectListChange(e);
            }}
            value={descriptionStyles.filter(
              (x) => x.value === article?.descriptionStyleId
            )}
            isClearable
          />
        </div>

        <div className="col-8">
          <label>Назив</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={article.name}
            onChange={handleInputChangeWithValidation}
          />
          {errors?.name && <div className="text-danger">{errors?.name}</div>}
        </div>
        <div className="col-4">
          <label>Стил назива</label>
          <Select
            id="nameStyleId"
            options={nameStyles}
            onChange={(e) => {
              if (!e) e = { value: null, name: "nameStyleId" };
              handleSelectListChange(e);
            }}
            value={nameStyles.filter((x) => x.value === article?.nameStyleId)}
            isClearable
          />
        </div>

        <div className="col-8">
          <label>Белешка (Коментар испод наслова)</label>
          <input
            className="form-control"
            type="text"
            name="note"
            value={article.note}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-4">
          <label>Ранг</label>
          <input
            className="form-control"
            type="number"
            name="rang"
            value={article.rang}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <input
            id="free"
            name="free"
            type="checkbox"
            value={article.free}
            checked={article.free}
            onChange={handleInputChange}
          />
          <label htmlFor="free">Бесплатан</label>
        </div>
        <div className="col-3">
          <input
            id="changed"
            name="changed"
            type="checkbox"
            value={article.changed}
            checked={article.changed}
            onChange={handleInputChange}
          />
          <label htmlFor="changed">Измењен</label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={editText}
        disabled={selectedOperationType !== productOperationTypes.editElement}
        title={
          selectedOperationType !== productOperationTypes.editElement ? 
          "Потребно је прво креирати елемент, а након тога ћете моћи да измените текст" : ""
        }
      >
        Измени текст
      </button>
      <button className="pull-right btn btn-primary" type="submit">
        Сачувај
      </button>
    </form>
  );
};

export default CreateOrEditArticle;
