import { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import "react-datepicker/dist/react-datepicker.css";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";

const CreatePackageGroup = ({ data, edit, handleCallback, packageGroups }) => {
  const statusOptions = [
    {
      value: 0,
      label: "Активна",
    },
    {
      value: 1,
      label: "Неактивна",
    },
  ];
  const [packagesList, setPackagesList] = useState([]); // opcije za Pakete input - products

  const [packageDataGroup, setPackageDataGroup] = useState({
    shortDescription: "",
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    prefixName: "",
    price: 0,
    extCode: "",
    sort: 0,
    packageType: "PRODUCTS",
    packageGroupId: 0,
    isGroup: true,
    packageStatus: 1,
    chosenPackages: []
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    extCode: Joi.string().required(),
    shortDescription: Joi.string().required(),
    description: Joi.string().required(),
    price: Joi.number().min(0).max(2147483647).required(),
    dateFrom: Joi.date().required(),
    dateTo: Joi.date().required(),
    sort: Joi.number().min(0).max(99999)
  };

  const client = new PackageClient();

  const getAllPackages = async () => {
    let response = await client.GetAllPackages();
    let res = HandlingResponse(response);
    var result = res.filter((x) => !x.isGroup).map((handledResponse) => {
      return {
        value: handledResponse.id,
        label: handledResponse.shortDescription,
      };
    });
    setPackagesList(result);
  };

  useEffect(() => {
    getAllPackages();
  }, []);

  useEffect(() => {
    setPackageDataGroup(data);
    setErrors({});
  }, [data]);

  useEffect(() => {
    setPackageDataGroup({...data, chosenPackages: packageGroups});
  }, [packageGroups]);

  const createUpdatePackageGroup = async () => {
    const errorsResult = validateForm(packageDataGroup, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var listPackages = [];
      if (packageDataGroup.packageType === "PRODUCTS") {
        listPackages = packageDataGroup.chosenPackages?.map((item) => {
          return { id: item.value, name: item.label };
        });
      }

      var data = {
        ...packageDataGroup,
        price: parseInt(packageDataGroup.price),
        childPackages: listPackages,
        isGroup: true,
      };

      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      var response;
      if (edit) {
        response = await client.UpdatePackage(formData);
      } else {
        response = await client.CreatePackage(formData);
      }
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback();
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, packageDataGroup, errors, schema, t);
    setPackageDataGroup(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <div className="form-group">
        <label>Статус</label>
        <Select
          options={statusOptions}
          onChange={(e) =>
            setPackageDataGroup({ ...packageDataGroup, packageStatus: e.value })
          }
          value={statusOptions.filter(
            (x) => x.value === packageDataGroup.packageStatus
          )}
          isDisabled={!edit}
        />
      </div>
      <div className="form-group">
        <label>Кратак опис<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          value={packageDataGroup.shortDescription}
          onChange={(e) => handlePropertyChange(e.target)}
          name="shortDescription"
        />
        {errors?.shortDescription && (
          <div className="text-danger">{errors.shortDescription}</div>
        )}
      </div>
      <div className="form-group">
        <label>Опис<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          value={packageDataGroup.description}
          onChange={(e) => handlePropertyChange(e.target)}
          name="description"
        />
        {errors?.shortDescription && (
          <div className="text-danger">{errors.description}</div>
        )}
      </div>
      <div className="form-group">
        <label>Префикс</label>
        <input
          type="text"
          className="form-control"
          value={packageDataGroup.prefixName}
          onChange={(e) =>
            setPackageDataGroup({
              ...packageDataGroup,
              prefixName: e.target.value,
            })
          }
        />
      </div>
      <div className="form-group">
        <label>Датум од<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={packageDataGroup.dateFrom}
          onChange={(date) => handlePropertyChange(date, "dateFrom")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.dateFrom && (
          <div className="text-danger">{errors.dateFrom}</div>
        )}
      </div>
      <div className="form-group">
        <label>Датум до<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={packageDataGroup.dateTo}
          onChange={(date) => handlePropertyChange(date, "dateTo")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.dateTo && <div className="text-danger">{errors.dateTo}</div>}
      </div>
      <div className="form-group">
        <label>Цена</label>
        <input
          type="number"
          className="form-control"
          value={packageDataGroup.price}
          onChange={(e) => handlePropertyChange(e.target)}
          name="price"
        />
        {errors?.price && <div className="text-danger">{errors.price}</div>}
      </div>
      <div className="form-group">
        <label>Код<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          value={packageDataGroup.extCode}
          onChange={(e) => handlePropertyChange(e.target)}
          name="extCode"
        />
        {errors?.extCode && <div className="text-danger">{errors.extCode}</div>}
      </div>
      <div className="form-group">
        <label>Сортирање</label>
        <input
          type="number"
          className="form-control"
          name="sort"
          value={packageDataGroup.sort}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.sort && (
          <div className="text-danger">{errors.sort}</div>
        )}
      </div>
      <div className="form-group">
        <label>Пакети</label>
        <Select
          options={packagesList}
          onChange={(e) => setPackageDataGroup({ ...packageDataGroup, chosenPackages: e })}
          value={packageDataGroup.chosenPackages}
          className={"selectPackages"}
          classNamePrefix={"selectPackages"}
          isMulti
        />
      </div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginTop: "20px" }}
        onClick={() => createUpdatePackageGroup()}
      >
        {edit ? "Измени" : "Креирај"}
      </button>
    </>
  );
};

export default CreatePackageGroup;
