import Joi from "joi-browser";

export const validateForm = (data, schema, translation) => {
    const result = Joi.validate(data, schema, { abortEarly: false, stripUnknown: true });
    const { error } = result;
    if (!error) {
        return null;
    } else {
        const errorData = {};
        for (let item of error.details) {
            let name = item.path.join(".");
            let type = item.type;
            let limit = item.context.limit;
            errorData[name] = translation(type, { limit: limit });
        }
        return errorData;
    }
}

export const validateProperty = (object, schema, translation) => {
    const { name, value } = object;
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema);
    const { error } = result;
    if (error) {
        let type = error.details[0].type;
        let limit = error.details[0].context.limit;
        return translation(type, { limit: limit });
    }
    else {
        return null;
    }
}

export const handleSave = (object, data, errors, schema, translation) => {
    const { name, value } = object;
    let errorData = { ...errors };
    const errorMessage = validateProperty(object, schema, translation);
    if (errorMessage) {
        errorData[name] = errorMessage;
    } else {
        delete errorData[name];
    }
    let userData = { ...data };
    userData[name] = value;
    return { data: userData, errors: errorData };
}

export const serverValidation = (errors, translation) => {
    const errorData = {};
    Object.keys(errors).forEach(key => {
        const name = key;
        var message = "";
        errors[key].forEach(error => {
            let type = error.split("=")[0];
            let limit = error.split("=")[1];
            message = message.concat(" ", translation(type, { limit: limit }));
        })
        errorData[name] = message;
    });
    return errorData;
}