import styled from "styled-components";

export const RegButtonsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;

.search-act {
    width: 160px;
    margin-left: 10px;
}

.invalid-act {
    width: 240px;
    margin-left: 10px;
}

.move-act {
    width: 250px;
    margin-left: 10px;
}

.cancel-moving {
    width: 100px;
    margin-left: 10px;
}

.schedule-button {
    margin-left: auto;
}

div {
    margin-left: 10px;
    padding-top: 5px;
}
`