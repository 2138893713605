import Joi from "joi-browser";

class UserFilterModel {
    constructor(id = 0, lastName = "", firstName = "", company = "", email = "", loginName = "", sector = "", orderAlarmEndDate = null) {
        this.id = id;
        this.lastName = lastName;
        this.firstName = firstName;
        this.company = company;
        this.email = email;
        this.loginName = loginName;
        this.sector = sector;
        this.orderAlarmEndDate = orderAlarmEndDate;
    }
}

class CreateUserModal {
    constructor(id = 0, firstName = "", lastName = "", email = "", www = "", company = "", pib = "", address1 = "", address2 = "", city = "", postalCode = "",
        state = "", oldState = "", phone1 = "", phone2 = "", mobile = "", sector = "", newsletter = 0, newslist = "", buyer = 0, orderAlarm = null, noteContacts = "",
        noteOrders = "", fax = "", loginName = "", password = "") {
        this.id = id;
        this.firstName = firstName
        this.lastName = lastName
        this.email = email
        this.www = www
        this.company = company
        this.pib = pib
        this.address1 = address1
        this.address2 = address2
        this.city = city
        this.postalCode = postalCode
        this.state = state
        this.oldState = oldState
        this.phone1 = phone1
        this.phone2 = phone2
        this.mobile = mobile
        this.sector = sector
        this.newsletter = newsletter
        this.newslist = newslist
        this.buyer = buyer
        this.orderAlarm = orderAlarm
        this.noteContacts = noteContacts
        this.noteOrders = noteOrders
        this.fax = fax
        this.loginName = loginName
        this.password = password
    }
}

export const UserSchema = {
    firstName: Joi.string().max(50).required(),
    lastName: Joi.string().max(50).required(),
    loginName: Joi.string().max(50).required(),
    password: Joi.string().required(),
}

export { UserFilterModel, CreateUserModal }