import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table, { removeTableObjectsFromSessionStorage } from "../../../../common/table/Table.component";
import { useNavigate } from "react-router-dom";
import LawAdActClient from "../../../api-services/law-ad-act/law-ad-act-client";
import { useCallback } from "react";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const LawAdActs = () => {
    const tableName = "SLGL_LAW_AD_ACTS"
    const columns = [
            {
                accessor: "lawAdActName",
            }
    ];

    const navigate = useNavigate();
    const { state } = useLocation();
    const client = new LawAdActClient();
    const [acts] = useState(state);
    const [useTextEditorLite, setUseTextEditorLite] = useState(false)

    // const updateHtmlFile = async () => {
    //     var response = await client.UpdateLawAdActFile();
    //     return response;
    // }

    const handleTableRowClick = useCallback( async (response) => {
        var pdfName = response.lawAdActName.length > 30 ? response.lawAdActName.substring(0, 30) : response.lawAdActName;
        var result = await client.getLawAdActHtml(response.id, pdfName);  //ako je pdf u pitanju, u result su samo bajtovi
        if (result?.data?.fileContent || result.messageCode) {  //ako je html u pitanju ili ako vraca neku gresku koja se obradjuje u HandlingResponse
            var handledResponse = HandlingResponse(result);
            if (handledResponse !== "Error")
            {
                const id = response.id;
                const allOptionsVisible = false; // da li se vide menu, tooolbar, html prikaz
                const pathToNavigate = window.location.pathname;
                const isMainFile = true;
                if(useTextEditorLite)
                    window.open(window.location.origin + "/textEditorLite?id=" + id + "&fileName=" + result.data.fileName + "&appPath=" + pathToNavigate + "&optionsVisible= " + allOptionsVisible + "&isMainFile=" + isMainFile, '_blank')
                else 
                    window.open(window.location.origin + "/textEditor?id=" + id + "&fileName=" + result.data.fileName + "&appPath=" + pathToNavigate + "&optionsVisible= " + allOptionsVisible + "&isMainFile=" + isMainFile, '_blank')
            }
        }
       })


    const backToPublications = () => {
        removeTableObjectsFromSessionStorage(tableName);
        navigate("/slgl");
    }
    return(
        <>
        <div>
            <h3 >Преглед фајлова</h3>
            <Table
                columns={columns}
                data={state?.data || []}
                rememberFilter={true}
                tableName={tableName}
                handleClickOnRow={handleTableRowClick}
            />
            </div>
            <input
                        id="textEditorLite"
                        type="checkbox"
                        
                        value={useTextEditorLite}
                        checked={useTextEditorLite}
                        onChange={(event) => setUseTextEditorLite(event.target.checked)}
                      />
                      <label htmlFor="textEditorLite">Желим да користим упрошћену верзију Уређивача текста</label>
                      <span className="info-about-cancel-span"> 
                        <FontAwesomeIcon icon={solid("circle-info")}/>  Погодна за фајлове чији је рад у основној верзији спор или немогућ
                      </span>
                      <br/>
            <button type="button" className="btn btn-primary" onClick={() => backToPublications()}>Назад на издања</button>
        </>
        
    )
}

export default LawAdActs;