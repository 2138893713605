import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../common/table/Table.component";
import { CreateDataPrincipalActModel } from "../../models/CreateData";
import LawActClient from "../../../REGISTRIES/api-services/law-act/law-act-client";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import parse from "html-react-parser";
import Collapse from "../../../common/collapse/Collapse.component";
import {
  validateParentheses,
  validateQuotationMarks,
} from "../../../utils/common-functions/CommonFunctions";

const PrincipalAct = ({
  principalActsData,
  setPrincipalActsData,
  lawActName,
  setLawActName,
  validationForRelatedActs,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "sort",
      accessor: "sort",
    },
    {
      Header: t("PrincipalAct"),
      accessor: "principalAct",
    },
    {
      Header: "Guid",
      accessor: "regActGuid",
    },
    {
      Header: t("PrincipalActArticles"),
      accessor: "principalActArticles",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original, row.index)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              onClick={() => removeElement(row.index)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </button>
          </>
        );
      },
    },
  ];
  const lawActClient = new LawActClient();
  const [visible, setVisible] = useState(false);
  const [principalActsLinks, setPrincipalActsLinks] = useState([]);
  const [relatedActsLinks, setRelatedActsLinks] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [principalAct, setPrincipalAct] = useState(
    new CreateDataPrincipalActModel()
  );
  const [isEdit, setIsEdit] = useState(false);

  const [validationForPrincipalAct, setValidationForPrincipalAct] =
    useState("");

  const addingPrincipalAct = () => {
    setRowId(null);
    if (principalAct.principalAct != "") {
      principalAct.id = 0;
      principalAct.sort = principalActsData.length;
      if (checkCharactersValidation(principalAct.principalAct)) {
        setPrincipalActsData([...principalActsData, principalAct]);
        cancelAddingPrincipalAct();
      }
    }
  };

  const editingPrincipalAct = () => {
    let dataCopy = [...principalActsData];
    dataCopy[rowId] = {
      ...principalAct,
      id: dataCopy[rowId].id,
      sort: dataCopy[rowId].sort,
    };
    if (checkCharactersValidation(principalAct.principalAct)) {
      setPrincipalActsData(dataCopy);
      cancelAddingPrincipalAct();
    }
  };

  const cancelAddingPrincipalAct = () => {
    setVisible(false);
    setIsEdit(false);
    setPrincipalAct(new CreateDataPrincipalActModel());
    setValidationForPrincipalAct("");
  };

  const onEditClick = (row, id) => {
    setIsEdit(true);
    setRowId(id);
    setPrincipalAct({
      principalAct: row.principalAct,
      principalActArticles: row.principalActArticles,
    });
    setVisible(true);
  };

  const removeElement = (id) => {
    const arrayCopy = [...principalActsData];
    arrayCopy.splice(id, 1);
    setPrincipalActsData(arrayCopy);
  };

  const generateLinks = async () => {
    let principalActsName = principalActsData.map((x) => {
      return x.principalAct;
    });
    let response = await lawActClient.generateLawActLinks(
      principalActsName,
      true
    );
    let handledResponse = HandlingResponse(response);
    setPrincipalActsLinks(handledResponse);

    var actNames = lawActName.split("\n");
    if (lawActName !== "") {
      response = await lawActClient.generateLawActLinks(actNames, true);
      handledResponse = HandlingResponse(response);
      setRelatedActsLinks(handledResponse);
    }
  };

  const checkCharactersValidation = (sentence) => {
    const parentheses = validateParentheses(sentence);
    const questionMarks = validateQuotationMarks(sentence);

    if (!parentheses && !questionMarks) {
      setValidationForPrincipalAct(t("parenthesesAndQuestionMarksMessage"));

      return false;
    } else if (!parentheses) {
      setValidationForPrincipalAct(t("parenthesesMessage"));
      return false;
    } else if (!questionMarks) {
      setValidationForPrincipalAct(t("questionMarksMessage"));
      return false;
    } else {
      setValidationForPrincipalAct("");
      return true;
    }

  };

  return (
    <>
      <div className="row">
        <div>
          <Collapse
            open={visible}
            setOpen={setVisible}
            title="Додавање основних прописа"
          >
            <div className="row">
              <div className="col-6">
                <label>{t("PrincipalAct")}</label>
                <input
                  name="principalAct"
                  className="form-control"
                  type="text"
                  value={principalAct.principalAct}
                  onChange={(event) =>
                    setPrincipalAct({
                      ...principalAct,
                      principalAct: event.target.value,
                    })
                  }
                />
                {validationForPrincipalAct !== "" && (
                  <div className="text-danger">{validationForPrincipalAct}</div>
                )}
              </div>
              <div className="col-6">
                <label>{t("PrincipalActArticles")}</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="principalActArticles"
                  value={principalAct.principalActArticles}
                  onChange={(event) =>
                    setPrincipalAct({
                      ...principalAct,
                      principalActArticles: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <br />
            {isEdit ? (
              <>
                {" "}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={editingPrincipalAct}
                >
                  {t("Save")}
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addingPrincipalAct}
                >
                  {t("Add")}
                </button>
              </>
            )}
            <button
              type="button"
              style={{ marginLeft: "10px" }}
              className="btn btn-primary"
              onClick={cancelAddingPrincipalAct}
            >
              {t("Cancel")}
            </button>
          </Collapse>
          {principalActsData.length > 0 ? (
            <Table
              columns={columns}
              data={principalActsData}
              rememberFilter={false}
              setData={setPrincipalActsData}
              hiddenColumn={["id", "sort", "regActGuid"]}
              serverSide={false}
              rowSortable={true}
            />
          ) : (
            <></>
          )}
          <div>
            <label>Други прописи</label>
            <textarea
              className="form-control"
              type="text"
              value={lawActName}
              onChange={(event) => setLawActName(event.target.value)}
            />
            <div className="text-danger">{validationForRelatedActs}</div>
          </div>
          <br />
          <div className="row">
            <div className="col-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => generateLinks()}
              >
                Генериши линкове
              </button>
            </div>
            <div className="col-10">
              <>
                {principalActsLinks?.length > 0 && (
                  <>
                    <label>Линкови основних прописа</label>
                    {principalActsLinks?.map((el, id) => (
                      <div key={id}>{parse(el)}</div>
                    ))}
                  </>
                )}
                {relatedActsLinks?.length > 0 && (
                  <>
                    <label>Линкови других прописа</label>
                    {relatedActsLinks?.map((el, id) => (
                      <div key={id}>{parse(el)}</div>
                    ))}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalAct;
