import React, { useEffect, useState, useCallback } from "react";
import Publications from "../../../components/publications/publications-table/publications.component";

const CurrentPublications = () => {
  
  const [isCurrent, setIsCurrent] = useState(true);
  const [isArchived, setIsArchived] = useState(false);

  return (
    <>
      <Publications isCurrent={isCurrent} isArchived={isArchived}></Publications>
    </>
  );
};
export default CurrentPublications;
