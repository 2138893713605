import React, { useEffect } from "react";
import { MainPageContainer } from "./Index.style";
import { useNavigate } from "react-router-dom";
import { listOfAdminPortalApps } from "./listOfAllAdminPortalApps";
import useAppUrl from "../../../hooks/useAppUrl";
import RenderOnRole from "../../route-and-role/RenderOnRole";
import { navigateToPage } from "../../route-and-role/pathBasedOnRole";

const MainPage =() => {
  const navigate = useNavigate();
  const { setAppPath, setAppName} = useAppUrl();

  useEffect(() => {
    setAppPath("/");
    setAppName("")
  }, []);

  const onClickNavigate = (path) => {
    navigateToPage(path, navigate);
  }
  
  return (
    <div className="container">
    <div className="row">
      {listOfAdminPortalApps.map((item, index) => {
        return (
          <RenderOnRole key={index} roles={item.roles || []}>
            <MainPageContainer onClick={() => {
                  onClickNavigate(item.path)
                }}>
                <div >
                  <div className="app-box-container">
                    <div className="n-s-title">{item.name}</div>
                    <div className="n-s-key">

                    </div>
                  </div>
                </div>
              </MainPageContainer>
          </RenderOnRole>
        )
      })}
    </div>
    </div>
  );
}

export default MainPage;