import api from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_MML;

class DataClient {
  async filterData(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Data/FilterData", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createData(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.post(baseurl + "/Data/CreateData", data, {
        headers: headers
      }, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteData(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Data/DeleteData/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateData(data, logout, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.put(baseurl + "/Data/UpdateData?logout=" + logout, data, {
        headers: headers,
        isBackground: isBackground
      });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async cancelUpdate(dataId, isBackground) {
    try {
      var response = await api.put(baseurl + "/Data/CancelUpdateData/" + dataId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetDataForUpdateById(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/GetDataForUpdateById/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFilesFromRepo(dataId, fileName, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DownloadFilesFromRepo/" + dataId, { responseType: 'blob' })
        .then(({ data, }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName;
          link.click();
        })
    }
    catch (err) {
      if (err.response.status === 404 && err.response.config.responseType === 'blob') {
        let responseObj = await err.response.data.text();
        return JSON.parse(responseObj);
      }
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadMainFile(fileGuid, fileName, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DownloadMainFile/" + fileGuid, { responseType: 'blob' }
      ).then(({ data }) => {
        const blob = new Blob([data]);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
      });
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadWordFile(wordGuid, name, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DownloadWord/" + wordGuid, { responseType: 'blob' }
      ).then(({ data }) => {
        const blob = new Blob([data]);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = name;
        link.click();
      });
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadImage(imageGuid, name, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DownloadImage/" + imageGuid, { responseType: 'blob' }
      ).then(({ data }) => {
        const blob = new Blob([data]);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = name;
        link.click();
      });
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadAttachment(attachmentGuid, name, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DownloadAttachmentFile/" + attachmentGuid, { responseType: 'blob' }
      ).then(({ data }) => {
        const blob = new Blob([data]);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = name;
        link.click();
      });
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getDataByName(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Data/GetDataByName", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async massIndexing(isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/MassIndexing", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async indexData(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/IndexData?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexData(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/DeleteIndexData?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetDataHtml(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/GetDataHtml?id=" + id, { isBackground: isBackground });
      return response.data;

    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateDataFile(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.put(baseurl + "/Data/UpdateDataFile", data, {
        headers: headers,
        isBackground: isBackground
      });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getDataViewForPrins(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Data/GetDataViewForPrins/" + id, { isBackground: isBackground });
      return response.data;

    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async viewActForPrins(id, responseType = '') {
    try {
      var response = await api.get(baseurl + "/Data/ViewActForPrins/" + id, { responseType: responseType });
      return response.data;

    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default DataClient;