import Joi from "joi-browser";

class ArticleModel {
    constructor(id = 0, name = "", parentId = null, text = "", note = "", free = false, changed = false, rang = null, descriptionTitle = "", productId = null,
         nameStyleId = null, descriptionStyleId = null) {
        this.id = id;
        this.name = name;
        this.parentId = parentId;
        this.text = text;
        this.note = note;
        this.free = free;
        this.changed = changed;
        this.rang = rang;
        this.descriptionTitle = descriptionTitle;
        this.productId = productId;
        this.nameStyleId = nameStyleId;
        this.descriptionStyleId = descriptionStyleId;
    }
}

export const ArticleSchema = {
    name: Joi.string().max(1000).required()
}

export { ArticleModel }