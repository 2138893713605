import { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import IssueTypeClient from "../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import NumberClient from "../../../SLGL/api-services/number/number";
import DatePicker from "react-datepicker";
import LawAdActClient from "../../../SLGL/api-services/law-ad-act/law-ad-act-client";

const TabCurrentYearAndArchive = ({ actGuid, setActGuid }) => {
  const [EditNumbersData, setEditNumbersData] = useState({
    issueTypeGuid: "",
    year: new Date().getFullYear(),
    num: "",
  });

  const [issueTypeList, setIssueTypeList] = useState([]);
  const [numList, setNumList] = useState([]);
  const [actList, setActList] = useState([]);
  const [showActList, setShowActList] = useState(false);
  const [showNumAndSearch, setShowNumAndSearch] = useState(false);
  const [numId, setNumId] = useState(null);
  const [numLabel, setNumLabel] = useState(null);

  const { t } = useTranslation();

  const client = new IssueTypeClient();
  const clientNumber = new NumberClient();
  const lawAdActClient = new LawAdActClient();

  
  useEffect(() => {
    getIssueTypes();
  }, []);

  // Vrsta izdanja lista
  const getIssueTypes = async () => {
    let response = await client.getIssueType();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { value: item.guid, label: item.description };
    });
    setIssueTypeList(result);
  };

  // Filter za Broj listu
  const FilterNumbersByIssueType = async () => {
    setShowNumAndSearch(true);
    var data = {
      issueTypeGuid: EditNumbersData.issueTypeGuid,
      year: EditNumbersData.year,
    };
    const response = await clientNumber.FilterPublishedNumbersByIssueTypeAndYear(data);
    var result = response.data.map((item) => {
      return {
        value: parseInt(item.num),
        label: parseInt(item.num),
        id: item.id,
      };
    });
    setNumList(result);
    setNumId(result[0].id);
  };

  // Filter za odaberi akt listu
  const GetLawAdActsList = async () => {
    setShowActList(true);
    var data = {
      issueTypeGuid: EditNumbersData.issueTypeGuid,
      year: EditNumbersData.year,
      number: parseInt(numLabel)
    };
    var response = await lawAdActClient.GetLawAdActsByTypeNumberYear(data);
    var handledReponse = HandlingResponse(response);
    //const actArray = Object.values(response.data);
    var result = handledReponse.filter(x => !x.published).map((item) => {
      return {
        value: item.actGuid,
        label: item.actName,
      };
    });
    setActList(result);
  };

  const resetForNumbersAndActs = () => {
    showNumAndSearch && setShowNumAndSearch(false);
    setNumList([]);
    setNumId(null);
    showActList && setShowActList(false);
    setActList([]);
    setActGuid("");
  }

  const changeIssueType = (event) => {
    if (event) {
      setEditNumbersData({
        year: new Date().getFullYear(),
        num: "",
        issueTypeGuid: event.value,
      });
    } else {
      setEditNumbersData({
        year: new Date().getFullYear(),
        num: "",
        issueTypeGuid: "",
      });
    }
    resetForNumbersAndActs();
  };

  const changeYear = (date) => {
    if(date) {
      setEditNumbersData({
        ...EditNumbersData,
        year: date.getFullYear(),
        num: ""
      });
    }
    else {
      setEditNumbersData({
        ...EditNumbersData,
        year: new Date().getFullYear(),
        num: "",
      });
    }
    resetForNumbersAndActs();
  }

  const changeNumber = (selectedOption) => {
    {
      if(selectedOption) {
        setNumId(selectedOption.id);
        setNumLabel(selectedOption.value);
      }
      else {
        setNumId(null);
        setNumLabel("");
      }
      showActList && setShowActList(false);
      setActList([]);
      setActGuid("");
    }
  }

  const resetSelection = () => {
    setEditNumbersData({
      year: new Date().getFullYear(),
      num: "",
      issueTypeGuid: "",
    });
    resetForNumbersAndActs();
  };

  return (
    <>
     <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={resetSelection}
        >
          Поништи одабир
        </button>
      </div>
      <div className="form-group">
        <label>Врста издања:</label>
        <Select
          options={issueTypeList}
          onChange={(e) =>changeIssueType(e)}
          value={issueTypeList.filter(
            (x) => x.value === EditNumbersData.issueTypeGuid
          )}
          isClearable
        />
      </div>
      <div className="form-group">
        <label>Година:</label>
        <DatePicker
          className="form-control"
          selected={
            EditNumbersData.year ? new Date(EditNumbersData.year, 0, 1) : null
          }
          onChange={(date) =>changeYear(date)}
          showYearPicker
          dateFormat="yyyy"
          yearItemNumber={10}
          disabled={!EditNumbersData.issueTypeGuid}
          isClearable = {EditNumbersData.year === new Date().getFullYear() ? false : true}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary form-group"
        disabled={!EditNumbersData.issueTypeGuid}
        onClick={FilterNumbersByIssueType}
      >
        Пронађи
      </button>
      {showNumAndSearch && (
        <div className="form-group">
          <label>Број:</label>
          <Select
            options={numList}
            onChange={(selectedOption) => changeNumber(selectedOption)}
            value={numList.find(
              (option) => option.value === EditNumbersData.num
            )}
            isDisabled={!EditNumbersData.issueTypeGuid}
            isClearable
          />
        </div>
      )}
       {showNumAndSearch && (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={GetLawAdActsList}
        >
          Пронађи акте
        </button>
      </div>
      )}
      {showActList && (
      <div className="form-group">
        <div className="form-group">
          <label>Акти:</label>
          <Select
            options={actList}
            onChange={(e) => setActGuid(e ? e.value: "")}
            value={actList.filter((x) => x.value === actGuid)}
            isClearable
          />
        </div>
      </div>
      )}
    </>
  );
};

export default TabCurrentYearAndArchive;
