import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabInternalActData from "../internal-act-basic-data/TabInternalActData.component";
import InternalActChangesHistory from "../internal-act-changes-history/InternalActChangesHistory.component";
import TabInternalActOtherData from "../internal-act-other-data/TabInternalActOtherData.component";
import useInternalFormContext from "../../hooks/useInternalFormContext";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { serverValidation, validateForm } from "../../../common/validation/validation";
import InternalActClient from "../../api-services/internal-act/internal-act-client";
import { schema } from "../../models/Act";
import UserService from "../../../utils/authentification/keycloak";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const EditActs = ({ handleClose, internalActInfo }) => {
  const { page, handleChangePage, data, setData, } = useInternalFormContext();

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [pictures, setPictures] = useState([]);

  const { t } = useTranslation();

  const [errors, setErrors] = useState({});

  // const schema = {
  //   ...schema,
  //   file: Joi.required(),
  // };

  var internalActClient = new InternalActClient();

  useEffect(() => {
    setData({
      internalActBasicData: internalActInfo.internalActBasicData,
      internalActOtherData: internalActInfo.internalActOtherData,
      internalActChangesHistory: internalActInfo.internalActChangesHistory,
    });
    setFile(internalActInfo.file);
    setFiles(internalActInfo.files);
    setPictures(internalActInfo.pictures);
  }, [internalActInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      schema.file = Joi.optional();
    }

    const errorsResult = validateForm({ ...data, file: file },
      schema,
      t);
    setErrors(errorsResult);

    if(!errorsResult) {

      var formData = new FormData();
  
      const dataForSerialization = {
        internalActBasicData: data.internalActBasicData,
        internalActOtherData: data.internalActOtherData,
        internalActChangesHistory: data.internalActChangesHistory,
      };
      serializeObjectIntoFormData(formData, dataForSerialization);
  
      if (file?.guid) {
        formData.append("file.guid", file?.guid);
        formData.append("file.name", file?.name);
      } else formData.append("file.file", file);
  
      for (let i = 0; i < pictures.length; i++) {
        if (pictures[i].guid)
          formData.append("pictures[" + i + "].guid", pictures[i].guid);
        else formData.append("pictures[" + i + "].file", pictures[i]);
  
        formData.append("pictures[" + i + "].name", pictures[i].name);
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].guid)
          formData.append("files[" + i + "].guid", files[i].guid);
        else formData.append("files[" + i + "].file", files[i]);
        formData.append("files[" + i + "].name", files[i].name);
      }
  
      let response = await internalActClient.updateInternalAct(formData);
      if (response !== "Error") var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
      handleClose();
    }
  };

  const onCancel = async () => {
    if(data.internalActBasicData.id) {
      var response = await internalActClient.cancelUpdate(data.internalActBasicData.id);
      HandlingResponse(response)
    }
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      {data.internalActBasicData.locked && data.internalActBasicData.userGuid !== UserService.getUserGuid() && (
            <label style={{ color: "red", marginBottom: "15px" }}>
              {`Корисник ${data.internalActBasicData.userFullName} уређује овај документ`}
            </label>
          )}
      <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Основни подаци" value="1" className="tab" />
            <Tab label="Остало" value="2" />
            <Tab label="Историјат измена" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TabInternalActData
            file={file}
            setFile={setFile}
            files={files}
            setFiles={setFiles}
            pictures={pictures}
            setPictures={setPictures}
            errors={errors}
            setErrors={setErrors}
          />
        </TabPanel>
        <TabPanel value="2">
          <TabInternalActOtherData />
        </TabPanel>
        <TabPanel value="3">
          <InternalActChangesHistory />
        </TabPanel>
      </TabContext>
      { data.internalActBasicData.userGuid === UserService.getUserGuid() && (<> <button
        type="button"
        className="btn btn-primary"
        style={{ marginRight: "10px" }}
        onClick={onCancel}
      >
        Одустани
      </button> 
      

      <button type="submit" className="pull-right btn btn-primary">
        Сачувај
      </button>
      <br/>
      <span className="info-about-cancel-span">
                    <FontAwesomeIcon icon={solid("circle-info")} />   Уколико не
                    кликнете на дугме "Одустани" документ ће остати закључан и
                    други корисници неће моћи да врше измену над њим
                  </span> 
      </>)}
    </form>
  );
};

export default EditActs;
