import styled from "styled-components";

export const RedTransparentButton = styled.button`
    color: #dc3545;
    font-weight: bold;
    background-color: transparent;
    border: none;
    text-align: center;
    &:hover {
        color: darkred !important;
    }
    :disabled {
        color: grey !important;
        background-color: transparent !important;
        border: none !important;
    }
`

export const GreenTransparentButton = styled.button`
    color: #0ca275 !important;
    font-weight: bold;
    background-color: transparent;
    border: none;
    text-align: center;
    &:hover {
        color: #007a59 !important;
    }
    :disabled {
        color: grey !important;
        background-color: transparent !important;
        border: none !important;
    }
`