import React, { useRef } from "react";
import Table from "../table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DeleteButton, DownloadButton, NewFileBtn } from "./multipleFiles.style";

const MultipleFiles = ({ fileList, setFileList, accept, name, handleDownload }) => {
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "name",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <DeleteButton
              type="button"
              title="Обриши фајл"
              className="pull-right"
              onClick={() => removeElement(row.id)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
            {fileList[row.id].guid ? (
              <DownloadButton
                title="Преузми фајл"
                type="button"
                className="pull-right"
                onClick={() => download(row.id)}
              >
                <FontAwesomeIcon icon={solid("download")} />
              </DownloadButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const inputRef = useRef(null);

  const handleUploadClick = () => {
    inputRef.current.value = null;
    inputRef.current?.click();
  };

  const removeElement = (id) => {
    const arrayCopy = [...fileList];
    arrayCopy.splice(id, 1);
    setFileList(arrayCopy);
  };

  const download = (id) => {
    var fileForDownload = fileList.filter((el, index) => {
      if(index == id) return el; 
    });

    fileForDownload && handleDownload(fileForDownload[0]);
  }

  return (
    <>
      <div className="form-group">
        <div>
          {fileList?.length > 0 ? (
            <Table
              columns={columns}
              data={fileList}
              hiddenColumn={["id"]}
              serverSide={false}
            />
          ) : (
            <></>
          )}
          <NewFileBtn onClick={handleUploadClick} type="button">
            <FontAwesomeIcon icon={solid("plus")} />
            Додај
          </NewFileBtn>
          <input
            type="file"
            ref={inputRef}
            onChange={(event) => {
              const files = event.target.files
                ? [...fileList, ...event.target.files]
                : [fileList];
              setFileList(files);
            }}
            hidden
            accept={accept}
            name={name}
            multiple
          />
        </div>
      </div>
    </>
  );
};

export default MultipleFiles;
