import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabCurrentYearAndArchive from "../tab-current-year-archives/TabCurrentYearAndArchive";
import TabRegistries from "../tab-registries/TabRegistries.component";
import { CreateActModel } from "../../models/Act";
import ActClient from "../../api-services/acts/act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import TabCourtPractice from "../tab-court-practice/TabCourtPractice.component";
import AreaClient from "../../api-services/areas/area-client";
import { useTranslation } from "react-i18next";

const CreateActs = ({ handleCallback }) => {
  const { setIsCreateAct, currentLocation, parentIdForSubact, changeLevel, isListEmpty } = UseRenderDifferentComponent();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("SLGL");
  const [actGuid, setActGuid] = useState("");
  const [dataForSubmit, setDataForSubmit] = useState(new CreateActModel());

  const [showErrorMessage, setshowErrorMessage] = useState(false);

  const client = new ActClient();
  const areaClient = new AreaClient();

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setshowErrorMessage(false);
    setActGuid("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      areaId: currentLocation.id,
      guid: actGuid,
      externalReferenceTypeName: activeTab,
      parentId: parentIdForSubact,
    };

    if(!actGuid)
      setshowErrorMessage(true);
    else {
      setshowErrorMessage(false);
      setDataForSubmit(data);

      let response = await client.CreateAct(data);
      let handledResponse = HandlingResponse(response);
      handledResponse && setIsCreateAct(false);

      isListEmpty && changeLevel();
     
    }
    
  };

  return (
    <form onSubmit={handleSubmit}>
      <TabContext value={activeTab}>
        <Box className="tabList" sx={{ borderTop: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="Create Acts Tabs">
            <Tab label="Текућа година и архива гласник" value="SLGL" />
            <Tab label="Регистар" value="REG" />
            <Tab label="Судска пракса" value="SP" />
          </TabList>
          <TabPanel value="SLGL">
            <TabCurrentYearAndArchive actGuid={actGuid} setActGuid={setActGuid} />
          </TabPanel>
          <TabPanel value="REG">
            <TabRegistries actGuid={actGuid} setActGuid={setActGuid} />
          </TabPanel>
          <TabPanel value="SP"><TabCourtPractice actGuid={actGuid} setActGuid={setActGuid} /></TabPanel>
        </Box>
      </TabContext>
      <div>
        {showErrorMessage && <div className="text-danger">Морате изабрати неки акт</div>}
      </div>
      <br />
      <div className="text-right">
        <button type="submit" className="btn btn-primary">
          Сачувај
        </button>
      </div>
    </form>
  );
};

export default CreateActs;
