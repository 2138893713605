import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateSudskaOdeljenja from "../../../components/sudska-odeljenja-component/CreateSudskaOdeljenja.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CourtDepartmentClient from "../../../api-services/coders/court-departments/court-deparments-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const SudskaOdeljenja = () => {
  const tableName = 'SP_SUDSKA_ODELJENJA';
  
  const columns = [
    {
      Header: "Суд",
      accessor: "court.name",
      disableFilters: false,
    },
    {
      Header: "Назив",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn" 
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Судско одељење ће бити трајно обрисано."}
              handleYesText={"Обриши"}
              >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
 
  const [courtDepartment, setCourtDepartment] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    sud: "",
    name: "",
    weight: 0,
    isActive: true,
    id: "",
    user: "",
    lastTimeModified: "",
    court: {
      name: ""
    },
  });
  
  const client = new CourtDepartmentClient();

  useEffect(() => {
    CourtDepartment();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      sud: "",
      name: "",
      weight: 0,
      isActive: true,
      id: "",
      user: "",
      lastTimeModified: "",
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      sud: "",
      name: "",
      weight: 0,
      isActive: true,
      id: "",
      user: "",
      lastTimeModified: "",
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };
  
  const onDeleteClick = async (id) => {
    var response = await client.DeleteCourtDepartment(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let courtDepartmentCopy = courtDepartment.filter((x) => x.id !== id);
      setCourtDepartment(courtDepartmentCopy);
    }
  };
  
  const CourtDepartment = async () => {
    await client.CourtDepartment().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setCourtDepartment(handledResponse);
    });
  }

  return (
    <>
      <h4>Судска одељења</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај ново судско одељење
        </button>
      </div>
      <Table
        columns={columns}
        data={courtDepartment}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={
          isCreate ? "Креирање новог судског одељења" : "Измена судског одељења"
        }
      >
        <CreateSudskaOdeljenja
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            CourtDepartment();
          }}
        />
      </SideModal>
    </>
  );
};

export default SudskaOdeljenja;
