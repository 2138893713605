import { ContentContainer } from "./Layout.style";
import {MainContainer} from "./Layout.style"
import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";

const MOLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();

  useEffect(() => {
    setAppName("Неважеће исправе");
    setAppPath("/mo");
  }, []);
  return (
    <>
      
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      
    </>
  );
};

export default MOLayout;
