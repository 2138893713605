import React, { useState, useEffect } from "react";
import AdvertisementAndActTypeClient from "../../api-services/advertisement-and-act-type/advertisement-and-act-type";
import AdvertisementAndActType from "../../models/AdvertisementAndActType";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { FlexDiv } from "../../../common/table/Table.style";

const CreateAdvertisementAndActType = ({ row, isEdit, handleCallback }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    defaultSort: Joi.string().min(5).max(250).required(),
    description: Joi.string().max(250).required(),
  };

  var client = new AdvertisementAndActTypeClient();
  const [advertisementAndActType, setAdvertisementAndActType] = useState(
    new AdvertisementAndActType()
  );

  useEffect(() => {
    setAdvertisementAndActType(row);
    setErrors({});
  }, [row]);

  const createAdvertisementAndActType = async (e) => {
    e.preventDefault();
    const errorsResult = validateForm(advertisementAndActType, schema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      if (isEdit)
        var response = await client.updateAdvertisementAndActType(
          advertisementAndActType
        );
      else
        var response = await client.createAdvertisementAndActType(
          advertisementAndActType
        );
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, advertisementAndActType, errors, schema, t);
    setAdvertisementAndActType(result.data);
    setErrors(result.errors);
  };

  return (
    <form onSubmit={createAdvertisementAndActType}>
      <div className="col-md">
        <label>
          {t("Description")}
          <span className="required-asterisk">*</span>
        </label>
        <input
          className="form-control"
          type="text"
          name="description"
          value={advertisementAndActType.description}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.description && (
          <div className="text-danger">{errors.description}</div>
        )}
        <label>
          {t("DefaultSort")}
          <span className="required-asterisk">*</span>
        </label>
        <input
          className="form-control"
          type="text"
          name="defaultSort"
          value={advertisementAndActType.defaultSort}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.defaultSort && (
          <div className="text-danger">{errors.defaultSort}</div>
        )}
        <div style={{ display: "flex" }}>
          <div>
            <input
              style={{ marginLeft: "5px" }}
              id="advertisementAndActTypeActiveCB"
              type="checkbox"
              value={advertisementAndActType.active}
              checked={advertisementAndActType.active}
              onChange={(event) => {
                setAdvertisementAndActType({
                  ...advertisementAndActType,
                  active: event.target.checked,
                });
              }}
            />
            <label
              style={{ allignContet: "center" }}
              htmlFor="advertisementAndActTypeActiveCB"
            >
              {t("Active")}
            </label>
          </div>
          <div>
            <input
              style={{ marginLeft: "5px" }}
              id="forOgCB"
              type="checkbox"
              value={advertisementAndActType.isForOg}
              checked={advertisementAndActType.isForOg}
              onChange={(event) => {
                setAdvertisementAndActType({
                  ...advertisementAndActType,
                  isForOg: event.target.checked,
                });
              }}
            />
            <label htmlFor="forOgCB">{t("ForOg")}</label>
          </div>
          <div>
            <input
              style={{ marginLeft: "5px" }}
              id="forRegCB"
              type="checkbox"
              value={advertisementAndActType.isForReg}
              checked={advertisementAndActType.isForReg}
              onChange={(event) => {
                setAdvertisementAndActType({
                  ...advertisementAndActType,
                  isForReg: event.target.checked,
                });
              }}
            />
            <label htmlFor="forRegCB">{t("ForReg")}</label>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={createAdvertisementAndActType}
        >
          {t("Save")}
        </button>
      </div>
    </form>
  );
};

export default CreateAdvertisementAndActType;
