import { SelectedNodeProvider } from "./SelectedNodeProvider";
import TreeList from "./TreeList.component";
import { TreeContainer } from "./Tree.styles";
import { useRef, useState } from "react";
import { preventDefaultEvents } from "../../utils/common-functions/CommonFunctions";
import ContextMenu from "../context-menu/ContextMenu.component";

const Tree = props => {
    const [showContextMenuParams, setShowContextMenuParams] = useState(null);
    const preselectedNodeId = useRef(props.preselectedNodeId || (props.saveStateKey && sessionStorage.getItem(`${props.saveStateKey}`)));
    const contextMenuEvent = (e, node) => {
        preventDefaultEvents(e);
        setShowContextMenuParams({ top: e.clientY, left: e.clientX, callbackParams: node });
    }

    const closeContextMenu = () => setShowContextMenuParams(null);
    return (
        <SelectedNodeProvider saveStateKey={props.saveStateKey}>
            <TreeContainer>
                <TreeList {...props} contextMenuEvent={contextMenuEvent} preselectedNodeId={parseInt(preselectedNodeId.current)}></TreeList>
            </TreeContainer>
            {props.contextMenu && <ContextMenu contextMenu={props.contextMenu} showParams={showContextMenuParams}
                handleClose={closeContextMenu}></ContextMenu>}
        </SelectedNodeProvider>
    )
};

export default Tree;