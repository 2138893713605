import React, { useState, useEffect } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import { SettingsModel, settingsSchema } from "../../models/Settings";
import SettingsClient from "../../api-services/settings/settings-client";

const EditSettings = ({ data, handleCallback }) => {
  const { t } = useTranslation();
  const [settingsParams, setSettingsParams] = useState(new SettingsModel());
  const [errors, setErrors] = useState({});

  const client = new SettingsClient();

  useEffect(() => {
    setSettingsParams(data);
    setErrors({});
  }, [data]);

  const update = async () => {
    const result = validateForm(settingsParams, settingsSchema, t);
    let response = await client.update(settingsParams);
    serverValidateAction(response);
    setErrors(result);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, settingsParams, errors, settingsSchema, t);
    setSettingsParams(result.data);
    setErrors(result.errors);
  };

  const serverValidateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  return (
    <div className="form-group">
      <div className="col-md">
        <label>Назив</label>
        <input
          className="form-control"
          type="text"
          name="parameterKey"
          value={settingsParams.parameterKey}
          disabled="disabled"
        />
        <label>Вредност</label>
        <input
          className="form-control"
          type="text"
          name="parameterValue"
          value={settingsParams.parameterValue}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.parameterValue && <div className="text-danger">{errors.parameterValue}</div>}
        <label>Опис</label>
        <input
          className="form-control"
          name="description"
          type="text"
          value={settingsParams.description}
          onChange={(event) => handlePropertyChange(event.target)}
        />
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-primary m-2" onClick={update}>
          Сачувај
        </button>
      </div>
    </div>
  );
};

export default EditSettings;
