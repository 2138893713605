import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import LawActClient from "../../../REGISTRIES/api-services/law-act/law-act-client";
import Table from "../../../common/table/Table.component";
import parse from "html-react-parser";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Collapse from "../../../common/collapse/Collapse.component";
import Joi from "joi-browser";
import { handleSave } from "../../../common/validation/validation";
import { courtePracticeSchema } from "../../models/CourtPractice";
import {
  validateParentheses,
  validateQuotationMarks,
} from "../../../utils/common-functions/CommonFunctions";

const RelatedActs = ({
  primaryActs,
  setPrimaryActs,
  disputedActsName,
  setDisputedActsName,
  relevantLawsName,
  setRelevantLawsName,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const primaryActColumns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "sort",
      accessor: "sort",
    },
    {
      Header: "Назив",
      accessor: "regActName",
    },
    {
      Header: "Чланови",
      accessor: "regActArticle",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original, row.index)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              onClick={() => removeElement(row.index)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </button>
          </>
        );
      },
    },
  ];
  const [visible, setVisible] = useState(true);
  const [rowId, setRowId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [primaryActLinks, setPrimaryActLinks] = useState([]);
  const [disputedActLinks, setDisputedActLinks] = useState([]);
  const [relevantLawLinks, setRelevantLawLinks] = useState([]);
  const [validationForPrincipalAct, setValidationForPrincipalAct] =
    useState("");

  const [primaryAct, setPrimaryAct] = useState({
    id: 0,
    sort: 0,
    regActName: "",
    regActArticle: "",
  });

  const lawActClient = new LawActClient();

  const addActToList = () => {
    setRowId(null);
    if (primaryAct.regActName != "") {
      primaryAct.id = 0;
      primaryAct.sort = primaryActs.length;
      if (checkCharactersValidation(primaryAct.regActName)) {
        setPrimaryActs([...primaryActs, primaryAct]);
        resetRelatedAct();
      }
    }
  };

  const onEditClick = (row, id) => {
    setIsEdit(true);
    setRowId(id);
    setPrimaryAct({
      regActName: row.regActName,
      regActArticle: row.regActArticle,
    });
    setVisible(true);
  };

  const editingRelatedAct = () => {
    let dataCopy = [...primaryActs];
    dataCopy[rowId] = {
      ...primaryAct,
      id: dataCopy[rowId].id,
      sort: dataCopy[rowId].sort,
    };
    if (checkCharactersValidation(primaryAct.regActName)) {
      setPrimaryActs(dataCopy);
      resetRelatedAct();
    }
  };

  const removeElement = (id) => {
    const arrayCopy = [...primaryActs];
    arrayCopy.splice(id, 1);
    setPrimaryActs(arrayCopy);
  };

  const resetRelatedAct = () => {
    setIsEdit(false);
    setPrimaryAct({
      id: 0,
      sort: 0,
      regActName: "",
      regActArticle: "",
    });
    setVisible(true);
    setErrors({ ...errors, primaryActValidation: "" });
  };

  const generateLinks = async () => {
    var primaryActsName = primaryActs?.map((x) => {
      return x.regActName;
    });
    let response = await lawActClient.generateLawActLinks(
      primaryActsName,
      true
    );
    let handledResponse = HandlingResponse(response);
    setPrimaryActLinks(handledResponse);

    var disputedActs = disputedActsName.split("\n");
    if (disputedActsName !== "") {
      response = await lawActClient.generateLawActLinks(disputedActs, true);
      handledResponse = HandlingResponse(response);
      setDisputedActLinks(handledResponse);
    }

    var relevantLaws = relevantLawsName.split("\n");
    if (relevantLawsName !== "") {
      response = await lawActClient.generateLawActLinks(relevantLaws, true);
      handledResponse = HandlingResponse(response);
      setRelevantLawLinks(handledResponse);
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      relevantLawsName,
      errors,
      courtePracticeSchema,
      t
    );
    setRelevantLawsName(result.data.relevantLawsName);
    setErrors(result.errors);
  };

  const checkCharactersValidation = (sentence) => {
    const parentheses = validateParentheses(sentence);
    const questionMarks = validateQuotationMarks(sentence);

    if (!parentheses && !questionMarks) {
      setErrors({
        ...errors,
        primaryActValidation: t("parenthesesAndQuestionMarksMessage"),
      });

      return false;
    } else if (!parentheses) {
      setErrors({ ...errors, primaryActValidation: t("parenthesesMessage") });
      return false;
    } else if (!questionMarks) {
      setErrors({ ...errors, primaryActValidation: t("questionMarksMessage") });
      return false;
    } else {
      setErrors({ ...errors, primaryActValidation: "" });
      return true;
    }
  };

  return (
    <>
      <Collapse
        open={visible}
        setOpen={setVisible}
        title="Додавање основних аката"
      >
        <div className="row">
          <div className="col-6">
            <label>Основни акт</label>
            <textarea
              name="primaryAct"
              className="form-control"
              type="text"
              value={primaryAct.regActName}
              onChange={(event) =>
                setPrimaryAct({
                  ...primaryAct,
                  regActName: event.target.value,
                })
              }
            />
            {errors?.primaryActValidation !== "" && (
              <div className="text-danger">{errors?.primaryActValidation}</div>
            )}
          </div>
          <div className="col-6">
            <label>Чланови</label>
            <textarea
              type="text"
              className="form-control"
              name="regActArticle"
              value={primaryAct.regActArticle}
              onChange={(event) =>
                setPrimaryAct({
                  ...primaryAct,
                  regActArticle: event.target.value,
                })
              }
            />
          </div>
        </div>
        <br />
        {isEdit ? (
          <>
            {" "}
            <button
              type="button"
              className="btn btn-primary"
              onClick={editingRelatedAct}
            >
              Сачувај
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={addActToList}
            >
              Додај
            </button>
          </>
        )}
        <button
          type="button"
          style={{ marginLeft: "10px" }}
          className="btn btn-primary"
          onClick={resetRelatedAct}
        >
          {t("Cancel")}
        </button>
        {primaryActs.length > 0 ? (
          <Table
            columns={primaryActColumns}
            data={primaryActs}
            setData={setPrimaryActs}
            rememberFilter={false}
            hiddenColumn={["id", "sort"]}
            serverSide={false}
            rowSortable={true}
          />
        ) : (
          <></>
        )}
      </Collapse>
      <div className="row">
        <div className="col-6">
          <label>Оспорени акт</label>
          <textarea
            className="form-control"
            type="text"
            value={disputedActsName}
            onChange={(event) => {
              setDisputedActsName(event.target.value);
            }}
          />
          <div className="text-danger">{errors?.disputedActsValidation}</div>
        </div>
        <div className="col-6">
          <label>
            Релевантно право<span className="required-asterisk">*</span>
          </label>
          <textarea
            className="form-control"
            type="text"
            placeholder="Унесите релевантно право"
            name="relevantLawsName"
            value={relevantLawsName}
            onChange={(event) => {
              handlePropertyChange(event.target);
            }}
          />
          {errors?.relevantLawsName && (
            <div className="text-danger">{errors.relevantLawsName}</div>
          )}
          <div className="text-danger">{errors?.relevantLawsValidation}</div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => generateLinks()}
          >
            Генериши линкове
          </button>
        </div>
        <div className="col-10">
          <>
            {primaryActLinks.length > 0 && (
              <>
                <label>Линкови основних аката</label>
                {primaryActLinks.map((el, id) => (
                  <div key={id}>{parse(el)}</div>
                ))}
              </>
            )}
            {disputedActLinks.length > 0 && (
              <>
                <label>Линкови оспорених аката</label>
                {disputedActLinks.map((el, id) => (
                  <div key={id}>{parse(el)}</div>
                ))}
              </>
            )}
            {relevantLawLinks.length > 0 && (
              <>
                <label>Линкови релевантних права</label>
                {relevantLawLinks.map((el, id) => (
                  <div key={id}>{parse(el)}</div>
                ))}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default RelatedActs;
