import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_REG;

class JsonClient {
    async createJSONFile(jsonClient, isBackground) {
        try {
            const params = {
                params: {jsonClient: jsonClient},
                isBackground: isBackground
              };
          var response = await api.get(baseurl + "/JSON/CreateJSONFile", params);
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

}

export default JsonClient;