import React, { useState, useEffect } from "react";
import SettingsClient from "../../api-services/settings/settings-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const EditPromoSettingsLoza = ({ row, handleCallback }) => {
  const [settingsParams, setSettingsParams] = useState({
    id: 0,
    parameterKey: "",
    parameterValue: "",
    description: ""
  });

  const client = new SettingsClient();

  useEffect(() => {
    setSettingsParams(row);
  }, [row]);

  const updateSetting = async () => {
    let response = await client.updateSetting(settingsParams);
    let handledResponse = HandlingResponse(response);
    handleCallback(handledResponse);
  };

  return (
    <div className="form-group">
      <div className="col-md">
        <label>Назив</label>
        <input
          className="form-control"
          type="text"
          name="parameterKey"
          value={settingsParams.parameterKey}
          disabled="disabled"
        />
        <label>Вредност</label>
        <input
          className="form-control"
          type="text"
          name="parameterValue"
          value={settingsParams.parameterValue}
          onChange={(event) => setSettingsParams({...settingsParams, parameterValue: event.target.value})}
        />
        <label>Опис</label>
        <input
          className="form-control"
          name="description"
          type="text"
          value={settingsParams.description}
          onChange={(event) => setSettingsParams({...settingsParams, description: event.target.value})}
        />
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-primary m-2" onClick={updateSetting}>
          Сачувај
        </button>
      </div>
    </div>
  );
};

export default EditPromoSettingsLoza;
