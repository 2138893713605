import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../../common/modal/SideModal.component";
import Table from "../../../../common/table/Table.component";
import CreateProcedureKind from "../../../components/procedure-kind-component/CreateProcedureKind.component";
import ProcedureKindClient from "../../../api-services/coders/procedure-kind/procedure-kind-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const ProcedureKind = () => {
  const tableName = 'SP_PROCEDURE_KIND';
  
  const columns = [
    {
      Header: "Назив",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation 
            className="btn btn-danger table-btn" 
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Врста поступка ће бити трајно обрисана."}
            handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  const [procedureKind, setProcedureKind] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    name: "",
    weight: "",
    isActive: true
  });

  const client = new ProcedureKindClient();

  useEffect(() => {
    ProcedureKind();
  }, []);
  
  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };  
  const onDeleteClick = async (id) => {
    var response = await client.DeleteProcedureKind(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let procedureKindCopy = procedureKind.filter((x) => x.id !== id);
      setProcedureKind(procedureKindCopy);
    }
  };

  const ProcedureKind = async () => {
    await client.ProcedureKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setProcedureKind(handledResponse);
    });
  }
  
  return (
    <>
      <h4>Врсте поступака</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нову врсту поступка
        </button>
      </div>
      <Table
        columns={columns}
        data={procedureKind}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={
          isCreate ? "Креирање нове врсте поступка" : "Измена врсте поступка"
        }
      >
        <CreateProcedureKind
          row={rowData}
          isCreate={isCreate}
          handleCallback={()=> {
            hideModal();
            ProcedureKind();
          }}
        />
      </SideModal>
    </>
  );
};

export default ProcedureKind;
