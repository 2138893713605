import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  SelectColumnFilter,
} from "../../../common/table/filterHelpFunction.component";
import ApproveClient from "../../api-services/requests-approval/requests-approval-client";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import { format } from "date-fns";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import Moment from "moment";

const UnprocessedRequests = () => {
    const tableName = 'LOZA_REQUESTS';

    const [tableData, setTableData] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [allPackages, setAllPackages] = useState([
      { value: undefined, label: "Сви" },
    ]);
    const filter = {
      filterValue: "equals",
      selectOptions: [
        { value: undefined, label: "Сви" },
        { value: "0", label: "Додавање/Продужавање" },
        { value: "1", label: "Укидање" },
      ],
    };
    const packagesFilter = {
      filterValue: "equals",
      selectOptions: allPackages,
    };
    const accountTypeFilter = {
      filterValue: "equals",
      selectOptions: [
        { value: undefined, label: "Сви" },
        { value: 'true', label: "Групни" },
        { value: 'false', label: "Појединачни" },
      ],
    };
  
    const columns = [
      {
        Header: "Тип налога",
        accessor: "isGroupRequest",
        disableFilters: false,
        Filter: SelectColumnFilter,
        filter: accountTypeFilter,
        Cell: ({ row }) => {
          return (
            <div>{row.original.isGroupRequest ? "Групни" : "Појединачни"}</div>
          );
        },
      },
      {
        Header: "Корисничко име",
        accessor: "userName",
        disableFilters: false,
         
      },
      {
        Header: "Име компаније",
        accessor: "company",
        disableFilters: false,
         
      },
      {
        Header: "ПИБ",
        accessor: "pib",
        disableFilters: false,
         
      },
      {
        Header: "Пакет",
        accessor: "packageName",
        disableFilters: false,
        Filter: SelectColumnFilter,
        filter: packagesFilter,
      },
      {
        Header: "Врста захтева",
        accessor: "requestType",
        disableFilters: false,
        Filter: SelectColumnFilter,
        filter: filter,
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.requestType
                ? "Укидање"
                : row.original.validTo
                ? "Продужавање"
                : "Додавање"}
            </div>
          );
        },
      },
      {
        Header: "Важи од",
        accessor: "validFrom",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.validFrom
                ? format(new Date(row.original.validFrom), "dd.MM.yyyy.")
                : ""}
            </div>
          );
        },
        disableFilters: false,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Важи до",
        accessor: "validTo",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.validTo
                ? format(new Date(row.original.validTo), "dd.MM.yyyy.")
                : ""}
            </div>
          );
        },
        disableFilters: false,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Захтев до",
        accessor: "requestedExtendingDate",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.requestedExtendingDate
                ? format(
                    new Date(row.original.requestedExtendingDate),
                    "dd.MM.yyyy."
                  )
                : ""}
            </div>
          );
        },
        disableFilters: false,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Захтев креирао",
        accessor: "requestedBy",
        disableFilters: false,
         
      },
      {
        Header: "Датум креирања захтева",
        accessor: "requestedDate",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.requestedDate
                ? Moment(row.original.requestedDate).format("DD.MM.YYYY. HH:mm")
                : ""}
            </div>
          );
        },
        disableFilters: false,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Коментар",
        accessor: "comment",
        disableFilters: false,
         
      },
    ];
  
    const client = new ApproveClient();
    const creatClient = new CreateClient();
  
    useEffect(() => {
      getRequests();
      getAllPackages();
    }, []);
  
    const getRequests = async () => {
      let response = await client.GetAllUnprocessedRequests();
      let res = HandlingResponse(response);
      setTableData(res);
    };
  
    const getAllPackages = async () => {
      let response = await creatClient.getPackages();
      let res = HandlingResponse(response);
      let result = res
        .filter((x) => !x.isGroup)
        .map((item) => {
          return { value: item.shortDescription, label: item.shortDescription };
        });
      setAllPackages([...allPackages, ...result]);
    };
  
    useEffect(() => {
      setIsButtonDisabled(selectedRowIds.length > 0 ? false : true);
    }, [selectedRowIds]);
  
    const approveCancelRequest = async (approve) => {
      var ids = [];
      tableData.forEach((x, index) => {
        if (selectedRowIds.includes(index.toString())) {
          ids.push(x.id);
        }
      });
      var data = {
        requestIds: ids,
        approve: approve,
      };
      
      let response = await client.ProcessRequest(data);
      let res = HandlingResponse(response);
      handleDataToTable(res);
    };
  
    const handleDataToTable = (data) => {
      let tableDataCopy = tableData.filter((x) => !data.includes(x.id));
      setTableData(tableDataCopy);
    };
  
    return (
      <>
        <h2>Необрађени захтеви</h2>
        <Table
          columns={columns}
          data={tableData}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
          onRowSelectStateChange={setSelectedRowIds}
        />
        <RenderOnRole roles={["Admin_Prodaja"]}>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => approveCancelRequest(true)}
              disabled={isButtonDisabled}
            >
              Одобри
            </button>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => approveCancelRequest(false)}
              disabled={isButtonDisabled}
            >
              Обриши
            </button>
          </div>
        </RenderOnRole>
      </>
    );
}

export default UnprocessedRequests;