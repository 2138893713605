import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import RegisterClient from "../../api-services/coders/register/register-client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";

const CreateRegister = ({ row, handleCallback, isCreate }) => {
  const [register, setRegister] = useState({
    id: 0,
    value: "",
    weight: 0,
    isActive: true,
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    value: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
  };

  const client = new RegisterClient();

  useEffect(() => {
    setRegister(row);
    setErrors({});
  }, [row, isCreate]);

  const CreateRegister = async () => {
    const errorsResult = validateForm(register, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...register,
        weight: parseInt(register.weight),
      };
      var response = await client.CreateRegister(data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const UpdateRegister = async () => {
    const errorsResult = validateForm(register, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...register,
        weight: parseInt(register.weight),
      };

      var response = await client.UpdateRegister(register.id, data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, register, errors, schema, t);
    setRegister(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Вредност<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="value"
            value={register.value}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.value && <div className="text-danger">{errors.value}</div>}
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={register.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div>
        <input
                id="activeRegisterCB"
                    type="checkbox"
                    value={register.isActive}
                    checked={register.isActive}
                    onChange={(event) => {
                        setRegister({ ...register, isActive: event.target.checked });
                    }}
          />
          <label htmlFor="activeRegisterCB">Активан</label>
        </div>
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateRegister()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateRegister()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateRegister;
