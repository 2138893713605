import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../../common/modal/SideModal.component";
import Table from "../../../../common/table/Table.component";
import CreateType from "../../../components/create-type/createType.component";
import DocumentTypeClient from "../../../api-services/document-type/document-type-client";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { TypesModel, typesLevelEnum } from "../../../models/Types";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { useCallback } from "react";

const CodebookTypes = () => {
  const { t } = useTranslation();
  const tableName = "MML_TYPES";

  const mainTypesColumns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
  ];
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "add",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <div>
            <button
              title="Измени"
              className="btn btn-primary table-btn"
              onClick={() => onCreateOrEditClick('', true, row.original.level, row.original.id)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id, row.original.level)}
              contentText={"Тип ће бити трајно обрисан."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </div>
        );
      },
    },
  ];
  var client = new DocumentTypeClient();

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [typeData, setTypeData] = useState(new TypesModel());

  const [mainTypeId, setMainTypeId] = useState(null);
  const [typeId, setTypeId] = useState(null);

  const [mainTypesList, setMainTypesList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [subtypesList, setSubtypesList] = useState([]);
  

  useEffect(() => {
    getMainTypes();
  }, []);

  const showModal = () => setShow(true);

  const hideModal = () => {
    setTypeData(new TypesModel());
    setShow(false);
  };

  const getMainTypes = async () => {
    let response = await client.getMainTypes();
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != "Error") {
      setMainTypesList(handledResponse);
      setMainTypeId(null);
    }
  };

  const handleTableRowClick = useCallback((response) => {
    if (response.level === typesLevelEnum.parentType) {
      getTypesByParentId(response.id, response.level);
      setMainTypeId(response.id);
    } else {
      getTypesByParentId(response.id, response.level);
      setTypeId(response.id);
    }
  });

  const getTypesByParentId = async (id, parentLevel) => {
    let response = await client.getTypesByParentId(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != "Error") {
      if (parentLevel === typesLevelEnum.parentType) {
        setTypesList(handledResponse);
        if(!isEdit && !typeId) {
          setSubtypesList([]);
          setTypeId(null);
      }
      } else {
        setSubtypesList(handledResponse);
      }
    }
  };

  const onCreateOrEditClick = async (isTypeOrSubtype, isEdit, level, id = 0) => {
    if(isEdit) {
      setIsEdit(true);
      setTitle(level === typesLevelEnum.type ? "Измена типа" : "Измена подтипа");
    }
    else {
      id = isTypeOrSubtype === 'TYPE' ? mainTypeId : isTypeOrSubtype === 'SUBTYPE' ? typeId : undefined;  
      setIsEdit(false);
      setTitle(isTypeOrSubtype === 'TYPE' ? "Креирање типа" : "Креирање подтипа");
    }
    //pribavljanje  osnovnog tipa/tipa u kom zelimo da kreirano novi tip/podtip
    let response = await client.getTypeById(id);
    let handledReponse = HandlingResponse(response);
    if (handledReponse && handledReponse !== 'Error') {
      setTypeData(handledReponse);
    }
    showModal();
  };

  const onDeleteClick = async (id, level) => {
    var response = await client.deleteType(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error") {
      if (level === typesLevelEnum.type) {
        let typesListCopy = typesList.filter((x) => x.id !== id);
        setTypesList(typesListCopy);
      }
      else if (level === typesLevelEnum.subtype) {
        let subtypesListCopy = subtypesList.filter((x) => x.id !== id);
        setSubtypesList(subtypesListCopy);
      }
    }
  };

  return (
    <>
      <div>
        <h4>Типови</h4>
        <hr />
        <div>
          <div>
            <span className="title-span">Основни типови</span>
            <span className="info-about-cancel-span">
              <FontAwesomeIcon icon={solid("circle-info")} />  Кликом на неки основни
              тип приказаће се табела његових типовa
            </span>
          </div>
          <Table
            rememberFilter={false}
            columns={mainTypesColumns}
            data={mainTypesList}
            hiddenColumn={["id"]}
            handleClickOnRow={handleTableRowClick}
            serverSide={false}
          />
        </div>
        {typesList.length ? (
          <div className="row">
            <div className="col-6">
              <div>
                <div>
                  <span className="title-span">Типови</span>
                  <span className="info-about-cancel-span">
                    <FontAwesomeIcon icon={solid("circle-info")} />  Кликом на неки тип 
                  приказаће се табела његових подтиповa
                  </span>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    onCreateOrEditClick('TYPE', false);
                    setTypeId(null);
                  }}
                  style={{marginTop: "10px"}}
                >
                  Додај тип
                </button>
              </div>
              <br />
              <div style={{ marginTop: typesList.length < 10 ? "52px" : "" }}>
                <Table
                  rememberFilter={false}
                  columns={columns}
                  data={typesList}
                  hiddenColumn={["id"]}
                  handleClickOnRow={handleTableRowClick}
                  serverSide={false}
                />
              </div>
            </div>
            {typeId && 
              <div className="col-6">
                <div className="title-span">Подтипови</div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onCreateOrEditClick('SUBTYPE', false)}
                  style={{marginTop: "10px"}}
                >
                  Додај подтип
                </button>
                <div
                  style={{ marginTop: subtypesList.length < 10 ? "52px" : "" }}
                >
                  <br />
                  {subtypesList.length ? (
                    <Table
                      rememberFilter={false}
                      columns={columns}
                      data={subtypesList}
                      hiddenColumn={["id"]}
                      serverSide={false}
                    />
                  ) : (
                    <div>Не постоји ниједан подтип за изабрани тип</div>
                  )}
                </div>
              </div>
            }
          </div>
        ) : (
          <></>
        )}
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateType
          row={typeData}
          isEdit={isEdit}
          handleCallback={(response) => {
            hideModal();
            getTypesByParentId(response.parentId, response.level-1);
          }}
        />
      </SideModal>
    </>
  );
};

export default CodebookTypes;
