import styled from "styled-components";

export const NavBarContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 5px 9px -8px rgb(0 0 0 / 22%);
  min-height: 53px;

  svg {
    margin-left: 7px;
  }

  a {
    float: left;
    font-size: 16px;
    color: #333;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

    &:hover {
      background-color: #00c58f;
      color: #fff;
    }
  }
  .dropdown {
    float: left;

    .dropbtn {
      border: none;
      outline: none;
      color: #333;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
    }

    .dropbtn-list {
      font-size: 16px;
      padding: 14px 16px;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 5px 21px -2px rgb(0 0 0 / 22%);
    z-index: 1;
    border-radius: 7px;
    border: 1px solid #ddd;
    z-Index: 9999;
    max-height: 525px;
    overflow-y: scroll;

    a {
      float: none;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;

      &:hover {
        background-color: #00c58f;
        color: #fff;
      }
    }
    
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .logo-span {
    cursor: pointer;
  }
`;

export const Logo = styled.div`
  float: left;
  position: absolute;
  left: 19px;
  top: 0px;
  font-size: 32px !important;
  font-weight: lighter;
`;

export const LogOutUser = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 13px 0px;

  svg {
    margin-right: 5px;
  }

  span {
    margin-right: 15px;
  }
`;
