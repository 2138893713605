import { env } from "../../../env";
import api from "../../../api";
import axios from "axios";

var baseurl = env.REACT_APP_API_URL_PPP;

class PackageClient {
    async getAllPackages(isBackground) {
        try {
            var response = await api.get(baseurl + "/Package/GetAllPackages", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async createPackage(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Package/CreatePackage", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updatePackage(data, isBackground) {
        try {
            var response = await api.put(baseurl + "/Package/UpdatePackage", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async activatePackage(id, isBackground) {
        try {
            var response = await api.put(baseurl + "/Package/ActivatePackage/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async deactivatePackage(id, isBackground) {
        try {
            var response = await api.put(baseurl + "/Package/DeactivatePackage/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default PackageClient;