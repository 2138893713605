import React, { useEffect, useState } from "react";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import IssueTypeClient from "../../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import CountryClient from "../../../../ADMIN-PANEL/api-services/country/country-client";
import NumberClient from "../../../api-services/number/number";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const SearchPublications = ({
  handleCallback,
  filterDataPublish,
  setFilterDataPublish,
  filterDataUnpublish,
  setFilterDataUnpublish,
  activeTab,
  resetFilters,
  isArchived,
}) => {
  const { t } = useTranslation();
  const issueTypeClient = new IssueTypeClient();
  const countryClient = new CountryClient();
  const numberClient = new NumberClient();

  const [issueTypes, setIssueTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [yearList, setYearList] = useState([]);

  const getAllIssueTypes = async () => {
    let response = await issueTypeClient.getIssueType();
    let res = HandlingResponse(response);
    let issueTypesArray = res.map((x) => {
      return { value: x.guid, label: x.description };
    });
    setIssueTypes(issueTypesArray);
  };

  const getAllCountries = async () => {
    let response = await countryClient.getCountry();
    let res = HandlingResponse(response);
    let countriesArray = res.map((x) => {
      return { value: x.guid, label: x.countryNameLong };
    });
    setCountries(countriesArray);
  };

  const getArchiveYears = async () => {
    let response = await numberClient.GetArchiveYears();
    let res = HandlingResponse(response);
    let array = res.years.map((x) => {
      return { value: x, label: x };
    });
    setYearList(array);
  };

  useEffect(() => {
    getAllIssueTypes();
    getAllCountries();
    getArchiveYears();
  }, []);

  return (
    <form onSubmit={handleCallback}>
      <div>
        <div className="row">
          <div className="form-group col-4">
            <label className="col-1" htmlFor="DocumentType">
              {t("Type")}
            </label>
            <Select
              id="DocumentType"
              options={issueTypes}
              onChange={(e) =>
                activeTab === 0
                  ? setFilterDataUnpublish({
                      ...filterDataUnpublish,
                      issueTypeGuid: e ? e.value : 0,
                    })
                  : setFilterDataPublish({
                      ...filterDataPublish,
                      issueTypeGuid: e ? e.value : 0,
                    })
              }
              value={
                activeTab === 0
                  ? issueTypes.filter(
                      (x) => x.value === filterDataUnpublish.issueTypeGuid
                    )
                  : issueTypes.filter(
                      (x) => x.value === filterDataPublish.issueTypeGuid
                    )
              }
              isClearable
            />
          </div>
          <div className="form-group col-4">
            <label className="col-1" htmlFor="DocumentType">
              {t("Country")}
            </label>
            <Select
              id="DocumentType"
              options={countries}
              onChange={(e) =>
                activeTab === 0
                  ? setFilterDataUnpublish({
                      ...filterDataUnpublish,
                      countryGuid: e ? e.value : 0,
                    })
                  : setFilterDataPublish({
                      ...filterDataPublish,
                      countryGuid: e ? e.value : 0,
                    })
              }
              value={
                activeTab === 0
                  ? countries.filter(
                      (x) => x.value === filterDataUnpublish.countryGuid
                    )
                  : countries.filter(
                      (x) => x.value === filterDataPublish.countryGuid
                    )
              }
              isClearable
            />
          </div>
          {isArchived && (
            <div className="form-group col-4">
              <label className="col-1">{t("Year")}</label>
              <Select
              id="DocumentType"
              options={yearList}
              onChange={(e) =>
                activeTab === 0
                  ? setFilterDataUnpublish({
                      ...filterDataUnpublish,
                      year: e ? e.value : 0,
                    })
                  : setFilterDataPublish({
                      ...filterDataPublish,
                      year: e ? e.value : 0,
                    })
              }
              value={
                activeTab === 0
                  ? yearList.filter(
                      (x) => x.value === filterDataUnpublish.year
                    )
                  : yearList.filter(
                      (x) => x.value === filterDataPublish.year
                    )
              }
              isClearable
            />
            </div>
          )}
        </div>

        <div className="text-right">
          <button
            style={{ marginTop: "10px", marginLeft: "5px" }}
            className="btn btn-primary"
            type="submit"
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "10px", marginLeft: "5px" }}
            onClick={() => {
              resetFilters();
            }}
          >
            Поништи претрагу
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchPublications;
