import Collapse from "@mui/material/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./Collapse.css";

const Collapsing = ({ open, setOpen, children, title }) => {
  return (
    <>
      <div className="row" style={{width: "100%"}} onClick={() => setOpen && setOpen(!open)}>
        <div className="inline-block">
          <hr className="collapse-hr" />
          <label className="collapse-title">{title}</label>
          <div className="icon-div">
            {open ? (
              <FontAwesomeIcon icon={solid("chevron-up")} />
            ) : (
              <FontAwesomeIcon icon={solid("chevron-down")} />
            )}
          </div>
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="children-div">{children}</div>
      </Collapse>
    </>
  );
};

export default Collapsing;
