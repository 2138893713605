const SearchInternalUsers = ({
    filterData,
    setFilterData,
    handleCallback,
    resetFilters
  }) => {
    return (
      <form onSubmit={handleCallback}>
        <div className="row">
        <div className="col-md-4">
            <label>Име</label>
            <input
              type="text"
              className="form-control"
              value={filterData.name}
              onChange={(e) =>
                setFilterData({ ...filterData, name: e.target.value })
              }
            />
          </div>
          <div className="col-md-4">
            <label>Презиме</label>
            <input
              type="text"
              className="form-control"
              value={filterData.lastName}
              onChange={(e) =>
                setFilterData({ ...filterData, lastName: e.target.value })
              }
            />
          </div>
          <div className="col-md-4">
            <label>Корисничко име</label>
            <input
              type="text"
              className="form-control"
              value={filterData.userName}
              onChange={(e) =>
                setFilterData({ ...filterData, userName: e.target.value })
              }
            />
          </div>
          <div className="col-md-4">
              <label>Адреса</label>
              <input
                type="text"
                className="form-control"
                value={filterData.address}
                onChange={(e) =>
                  setFilterData({ ...filterData, address: e.target.value })
                }
              />
            </div>
            <div className="col-md-4">
            <label>Компанија</label>
            <input
              type="text"
              className="form-control"
              value={filterData.company}
              onChange={(e) =>
                setFilterData({ ...filterData, company: e.target.value })
              }
            />
          </div>
          <div className="col-md-4">
            <label>Емаил</label>
            <input
              type="text"
              className="form-control"
              value={filterData.email}
              onChange={(e) =>
                setFilterData({ ...filterData, email: e.target.value })
              }
            />
          </div>
        </div>
        <br />
        <div className="text-right">
        <button
          type="submit"
          className="btn btn-primary"
        >
          Претрага
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "15px"}} 
          onClick={() => {
            resetFilters()}}
          >
            Поништи претрагу
            </button>
        </div>
        </form>
    );
  };
  
  export default SearchInternalUsers;
  