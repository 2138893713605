import React, { useState, useCallback, useEffect } from "react";
import SearchStaticPages from "../../components/search-static-pages/searchStaticPages.component";
import StaticPagesClient from "../../api-services/static-pages/static-pages";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table, { getPageNumber, getPageSizeNumber } from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Moment from "moment";
import SideModal from "../../../common/modal/SideModal.component";
import CreateStaticPage from "../../components/create-static-page/CreateStaticPage.component";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const StaticPages = () => {
  const tableName = "FP_STATIC_PAGE";
  const rememberFilter = true;

  const columns = [
    {
      Header: "Локација на Порталу",
      accessor: "locationName",
    },
    {
      Header: "Текст за приказ на Порталу",
      accessor: "displayText",
    },
    {
      Header: "Интерно обележје",
      accessor: "internalMark",
    },
    {
      Header: "Датум објављивања",
      accessor: "publishedDate",
      Cell: ({ row }) => {
        return row.original.publishedDate
          ? Moment(row.original.publishedDate).format("DD.MM.YYYY. HH:mm")
          : "";
      },
    },
    {
      Header: "Објављена",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          row.original.published && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: "Датум креирања",
      accessor: "creationDate",
      Cell: ({ row }) => {
        return row.original.creationDate
          ? Moment(row.original.creationDate).format("DD.MM.YYYY. HH:mm")
          : "";
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Статичка страна ће бити трајно обрисана."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  const client = new StaticPagesClient();

  const [filterData, setFilterData] = useState({
    displayText: "",
    internalMark: "",
    published: 2,
    publishedDateFrom: null,
    publishedDateTo: null,
  });
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldNamesForSort, setFieldNamesForSort] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [rowData, setRowData] = useState({
    locationId: undefined,
    description: "",
    displayText: "",
    internalMark: "",
    published: false,
    publishedDate: new Date().toLocaleString("en-US", {
      timeZone: "Europe/Belgrade",
    }),
    htmlGuid: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [reset, setReset] =  useState(false);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const [selectedPublishedEnum, setSelectedPublishedEnum] = useState("2");
  const handleOptionsChange = (event) => {
    setSelectedPublishedEnum(event.target.value);
  };
  useEffect(() => {
    setFilterData({
      ...filterData,
      published: parseInt(selectedPublishedEnum),
    });
  }, [selectedPublishedEnum]);

  
  useEffect(() => {
    reset && setReset(false);
  }, [tableData])

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.FilterStaticPages(
      {
        ...filterData,
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
      false
    );
    let data = HandlingResponse(response);
    return data;
  };

  const resetFilters = async () => {
    setReset(true);
    setSelectedPublishedEnum("2");
    setFilterData({
      displayText: "",
      internalMark: "",
      published: 2,
      publishedDateFrom: null,
      publishedDateTo: null,
    });

    var response = await client.FilterStaticPages(
      {
        displayText: "",
        internalMark: "",
        published: 2,
        publishedDateFrom: null,
        publishedDateTo: null,
      },
      true
    );
    let data = HandlingResponse(response);
    setTableData(data);
  };

  const searchData = async (e) => {
    e?.preventDefault()
    const response = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter)
    );
    setTableData(response);
  };

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      locationId: undefined,
      description: "",
      displayText: "",
      internalMark: "",
      htmlGuid: "",
      published: false,
      publishedDate: new Date().toLocaleString("en-US", {
        timeZone: "Europe/Belgrade",
      }),
    });
    showModal();
    setTitle("Креирање статичке стране");
  };

  const onEditClick = async (id) => {
    var response = await client.GetStaticPageById(id);
    setIsEdit(true);
    setRowData(response.data);
    showModal();
    setTitle("Измена статичке стране");
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteStaticPage(id);
    HandlingResponse(response);
  };

  const handleAddingDataToTable = () => {
    hideModal();
    searchData();
  }

  return (
    <>
    <h5 className="col-md-2" style={{marginBottom: "25px"}}>Статичке странице</h5>
      <SearchStaticPages
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
        handleOptionsChange={handleOptionsChange}
        selectedPublishedEnum={selectedPublishedEnum}
      ></SearchStaticPages>
      <hr />
      <div className="text-right">
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginTop: "10px", marginBottom: "20px" }}
          onClick={() => {
            onCreateClick();
          }}
        >
          Додај статичку страну
        </button>
      </div>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={rememberFilter}
        tableName={tableName}
        hiddenColumn={["id"]}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setTableData}
        resetFilters={reset}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateStaticPage row={rowData} isEdit={isEdit} handleCallback={handleAddingDataToTable} />
      </SideModal>
    </>
  );
};

export default StaticPages;
