import React, { useState, useRef, useEffect } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";

const SearchMultimedia = ({
  filterMultimedia,
  setFilterMultimedia,
  handleOptionsChange,
  selectedPublishedEnum,
  searchData,
  resetFilters,
}) => {
  return (
    <form onSubmit={searchData}>
      <div className="form-group">
        <div className="row">
          <div className="col-md-4">
            <label>Наслов</label>
            <input
              className="form-control"
              type="text"
              value={filterMultimedia.title}
              onChange={(e) =>
                setFilterMultimedia({
                  ...filterMultimedia,
                  title: e.target.value,
                })
              }
            />
            <label>Датум објављивања од:</label>
            <CustomDatePicker
              selected={filterMultimedia.publishedDateFrom}
              onChange={(date) =>
                setFilterMultimedia({
                  ...filterMultimedia,
                  publishedDateFrom: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
          <div className="col-md-4">
            <label>Опис</label>
            <input
              className="form-control"
              type="text"
              value={filterMultimedia.description}
              onChange={(e) =>
                setFilterMultimedia({
                  ...filterMultimedia,
                  description: e.target.value,
                })
              }
            />
            <label>Датум објављивања до:</label>
            <CustomDatePicker
              selected={filterMultimedia.publishedDateTo}
              onChange={(date) =>
                setFilterMultimedia({
                  ...filterMultimedia,
                  publishedDateTo: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
          <div className="col-md-4">
            <div
              className="col-6"
              style={{
                border: "1px solid #e1d6d6",
                borderRadius: "5px",
                marginTop: "15px",
                paddingLeft: "5px",
              }}
            >
              <label style={{ marginRight: "10px" }}>Статус:</label>
              <>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="2"
                      checked={selectedPublishedEnum === "2"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Сви
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      checked={selectedPublishedEnum === "1"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Објављени
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="0"
                      checked={selectedPublishedEnum === "0"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Необјављени
                  </label>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary" type="submit">
          Претрага
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "15px" }}
          onClick={() => {
            resetFilters();
          }}
        >
          Поништи претрагу
        </button>
      </div>
    </form>
  );
};

export default SearchMultimedia;
