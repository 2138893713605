import { useState } from "react";
import Table from "../table/Table.component";
import { useTranslation } from "react-i18next";
import Collapse from "../../common/collapse/Collapse.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ButtonWithComfirmation from "../button-with-confirmation/ButtonWithConfirmation.component";
import { NewFileBtn } from "../multiple-files/multipleFiles.style";

const SubmitFile = ({
  mainFile,
  setMainFile,
  photoFiles,
  setPhotoFiles,
  attachmentFiles,
  setAttachmentFiles,
  isEdit,
  downloadFilesFromRepo,
  id,
  pathToNavigate,
  showEditHTMLButton = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [useTextEditorLite, setUseTextEditorLite] = useState(false);
  var isMainFile = true;
  const { t } = useTranslation();
  const columnsAttachments = [
    {
      Header: "Назив",
      accessor: "name",
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <>
            {isEdit &&
            showEditHTMLButton &&
            row.original.name.includes("htm") ? (
              <button
                type="button"
                className="btn-primary btnTiny"
                onClick={() => {
                  isMainFile = false;
                  editHtml(row.original.name, row.original.guid);
                }}
              >
                Измени ХТМЛ
              </button>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const columnsPhotos = [
    {
      Header: "Назив",
      accessor: "name",
    },
  ];
  const filePathRegexPattern = /\/[^/]+\/[^/]+/;

  const pickDirectory = async () => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.webkitdirectory = true;

      input.addEventListener("change", async (event) => {
        const files = event.target.files;
        const photosDir = "slike";
        const attachmentsDir = "prilozi";
        const wordDir = "word";
        let photosArray = [];
        let attachmentsArray = [];

        Object.keys(files).forEach((x) => {
          if (!filePathRegexPattern.test(files[x].webkitRelativePath)) {
            if (files[x].type === "text/html") {
              if (!mainFile) {
                setMainFile(files[x]);
              } else {
                alert("Директоријум може да садржи само један ХТМЛ фајл");
                cancelSelection();
                return;
              }
            }
          } else {
            if (files[x].webkitRelativePath.includes(photosDir)) {
              photosArray.push(files[x]);
            } else if (files[x].webkitRelativePath.includes(attachmentsDir)) {
              attachmentsArray.push(files[x]);
            } else if (files[x].webkitRelativePath.includes(wordDir)) {
              // Ignorisati folder word
            } else {
              alert(
                "Директоријум мора да садржи једино фолдере 'slike' и 'prilozi'"
              );
              cancelSelection();
              return;
            }
          }
        });
        if (photosArray.length > 0) {
          setPhotoFiles(photosArray);
        }
        if (attachmentsArray.length > 0) {
          setAttachmentFiles(attachmentsArray);
        }
      });

      input.click();
    } catch (error) {
      alert(
        "Дошло је до грешке приликом отварања директоријума. Директоријум нема очекивану структуру."
      );
      cancelSelection();
      return;
    }
  };

  const cancelSelection = () => {
    setMainFile(undefined);
    setPhotoFiles([]);
    setAttachmentFiles([]);
    setVisible(false);
  };

  const editHtml = async (attachmentFileName, attachmentFileGuid) => {
    // var result = await getHtml();
    // var handledResponse = HandlingResponse(result);
    // if (handledResponse !== "Error") {
    var fileName = isMainFile ? mainFile?.name : attachmentFileName;
    const allOptionsVisible = true; // da li se vide menu, tooolbar, html prikaz
    if (useTextEditorLite)
      window.open(
        window.location.origin +
          "/textEditorLite?id=" +
          id +
          "&fileName=" +
          fileName +
          "&appPath=" +
          pathToNavigate +
          "&optionsVisible= " +
          allOptionsVisible +
          "&isMainFile=" +
          isMainFile +
          "&attGuid=" +
          attachmentFileGuid,
        "_blank"
      );
    else
      window.open(
        window.location.origin +
          "/textEditor?id=" +
          id +
          "&fileName=" +
          fileName +
          "&appPath=" +
          pathToNavigate +
          "&optionsVisible= " +
          allOptionsVisible +
          "&isMainFile=" +
          isMainFile +
          "&attGuid=" +
          attachmentFileGuid,
        "_blank"
      );
    // }
  };

  return (
    <>
      {mainFile ? (
        <>
          <Collapse open={visible} setOpen={setVisible} title="Checkout">
            <div>
              <div className="row">
                <div>
                  <label>Назив ХТМЛ фајла: </label> {mainFile.name} &nbsp;
                  {isEdit && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          isMainFile = true;
                          editHtml();
                        }}
                        style={{ marginBottom: "7px" }}
                      >
                        Измени ХТМЛ
                      </button>
                      <br />
                      <input
                        id="textEditorLite"
                        type="checkbox"
                        value={useTextEditorLite}
                        checked={useTextEditorLite}
                        onChange={(event) =>
                          setUseTextEditorLite(event.target.checked)
                        }
                      />
                      <label htmlFor="textEditorLite">
                        Желим да користим упрошћену верзију Уређивача текста
                      </label>
                      <span className="info-about-cancel-span">
                        <FontAwesomeIcon icon={solid("circle-info")} /> Погодна
                        за фајлове чији је рад у основној верзији спор или
                        немогућ
                      </span>
                    </>
                  )}
                </div>
                {photoFiles?.length > 0 ? (
                  <>
                    <div className="col-md">
                      <label>Слике</label>
                      <Table
                        columns={columnsPhotos}
                        data={photoFiles}
                        rememberFilter={true}
                        tableName={"PHOTOS_TABLE"}
                        serverSide={false}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {attachmentFiles?.length > 0 ? (
                  <>
                    <div className="col-md">
                      <label>Прилози</label>
                      <Table
                        columns={columnsAttachments}
                        data={attachmentFiles}
                        rememberFilter={true}
                        tableName={"ATTACHMENTS_TABLE"}
                        serverSide={false}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <ButtonWithComfirmation
                className="btn btn-primary"
                onClick={cancelSelection}
                handleYesText={"Поништи одабир"}
                contentText={"Уколико сачувате измене, директоријум ће бити трајно обрисан"}
                style={{ marginBottom: "15px" }}
              >
                Поништи одабир
              </ButtonWithComfirmation>
              <div>
                {isEdit && (
                  <button
                    className="btn btn-primary"
                    onClick={downloadFilesFromRepo}
                    type="button"
                  >
                    {t("DownloadFilesFromRepository")}
                  </button>
                )}
              </div>
            </div>
          </Collapse>
        </>
      ) : (
        <></>
      )}
      {!mainFile && (
        <NewFileBtn
          type="button"
          onClick={pickDirectory}
        >
          oдабери директоријум
        </NewFileBtn>
      )}
    </>
  );
};

export default SubmitFile;
