import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PublicationKindClient from "../../../api-services/coders/publication-kind/types-of-posts-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const TypesOfPosts = () => {
 const tableName = 'SP_TYPES_OF_POSTS';

  const columns = [
    {
      Header: "Назив",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
  ];
  
  const [publicationKind, setPublicationKind] = useState([]);

  const client = new PublicationKindClient();

  useEffect(() => {
    PublicationKind();
  }, []);
  
  const PublicationKind = async () => {
    await client.PublicationKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setPublicationKind(handledResponse);
    });
  }

  return (
    <>
      <h4>Врсте објава</h4>
      <Table
        columns={columns}
        data={publicationKind}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
    </>
  );
};

export default TypesOfPosts;
