import { useEffect, useState, useRef } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import Joi from "joi-browser";
import ManualSendClient from "../../api-services/manual-send/manual-send-client";
import DatePicker from "react-datepicker";
import { sr } from "date-fns/locale";
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/plugins/importcss";
import "tinymce/plugins/table";
import "tinymce/plugins/link";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/code";
import "tinymce/plugins/lists";
import "tinymce/plugins/image";

const ManualSend = () => {
  const [errors, setErrors] = useState({});
  const [schedulesList, setSchedulesList] = useState([]);
  const [manualSend, setManualSend] = useState({
    id: 0,
    email: "",
    notBefore: new Date(),
    subject: "",
    body: "",
  });

  const [hideEmail, setHideEmail] = useState(false);
  const [hideSchedule, setHideSchedule] = useState(true);

  const editorRef = useRef(null);

  const schema = {
    subject: Joi.string().required(),
    email: hideSchedule ? Joi.string().email().required() : Joi.optional(),
    scheduleId: hideEmail ? Joi.required() : Joi.optional(),
  };

  const { t } = useTranslation();
  const client = new ManualSendClient();

  const getSchedules = async () => {
    let response = await client.GetAllSchedules();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.id, label: x.description, name: "scheduleId" };
    });
    setSchedulesList(result);
  };

  useEffect(() => {
    getSchedules();
    setErrors({});
  }, []);

  const handleOptionsChange = (value) => {
    setErrors({});
    if (value === 0) {
      setHideEmail(false);
      setHideSchedule(true);
      setManualSend({ ...manualSend, scheduleId: undefined });
    } else {
      setHideEmail(true);
      setHideSchedule(false);
      setManualSend({ ...manualSend, email: "" });
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, manualSend, errors, schema, t);
    setManualSend(result.data);
    setErrors(result.errors);
  };

  const SendEmail = async () => {
    const errorResult = validateForm(manualSend, schema, t);
    setErrors(errorResult);

    if (!errorResult) {
      var response = await client.ManualSend(manualSend);
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
    }
  };

  return (
    <>
      <label>Прималац</label>
      <div className="radio">
        <label>
          <input
            type="radio"
            value="0"
            defaultChecked
            onChange={() => handleOptionsChange(0)}
            name="receiver"
          />
          {"  "}
          Мејл
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            type="radio"
            value="1"
            onChange={() => handleOptionsChange(1)}
            name="receiver"
          />
          {"  "}
          Ставка распореда
        </label>
      </div>

      <div className="form-group" hidden={hideEmail} style={{ marginTop: "20px" }}>
        <label>Мејл адреса<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="email"
          value={manualSend.email}
          onChange={(event) => handlePropertyChange(event.target)}
          placeholder="Мејл адреса"
        />
        {errors?.email && <div className="text-danger">{errors.email}</div>}
      </div>
      <div className="form-group" hidden={hideSchedule} style={{ marginTop: "20px" }}>
        <label>Ставка распореда</label>
        <Select
          options={schedulesList}
          onChange={(event) => handlePropertyChange(event)}
          value={schedulesList?.filter(
            (x) => x.value === manualSend.scheduleId
          )}
          id="scheduleId"
        />
        {errors?.scheduleId && (
          <div className="text-danger">{errors.scheduleId}</div>
        )}
      </div>
      <div className="form-group">
      <label>Не слати пре</label>
      <DatePicker
        locale={sr}
        className="form-control"
        selected={manualSend.notBefore ? new Date(manualSend.notBefore) : null}
        onChange={(date) => setManualSend({ ...manualSend, notBefore: date })}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="dd.MM.yyyy. HH:mm"
        timeIntervals={1}
        timeCaption="време"
        styles={{ zIndex: 9999 }}
      />
      </div>
      <div className="form-group">
      <label>Наслов мејла<span className="required-asterisk">*</span></label>
      <input
        className="form-control"
        type="text"
        name="subject"
        value={manualSend.subject}
        onChange={(event) => handlePropertyChange(event.target)}
      />
      {errors?.subject && <div className="text-danger">{errors.subject}</div>}
      </div>
      <div className="form-group">
      <label>Текст мејла</label>
      <div id="textEditor">
        <Editor
          id="kljuc"
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={() =>
            setManualSend({
              ...manualSend,
              body: editorRef.current?.getContent(),
            })
          }
          init={{
            height: 400,
            menubar: false,
            toolbar:
              " undo redo | bold italic underline superscript subscript strikethrough backcolor | casechange blocks fonts fontfamily fontsize|\
             alignleft aligncenter alignright alignjustify | \
              bullist numlist checklist outdent indent | removeformat | link table image code  ",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            plugins: " importcss table link searchreplace code lists image",
            selector: "textarea",
            table_toolbar:
              "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          }}
        />
      </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary m-2" onClick={() => SendEmail()}>
          Пошаљи
        </button>
      </div>
    </>
  );
};

export default ManualSend;
