import React, { useCallback, useEffect, useRef, useState } from "react";
import { Buffer } from "buffer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model'
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import "tinymce/plugins/lists";
import "tinymce/plugins/image";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/quickbars";
import './TinyMCE.style.css'
import './sideClasses.style.css'
import HandlingResponse from "../../../common/handling-response/handlingResponse"
import useTextEditorContext from "../../hooks/useTextEditorContext";

const TinyMCELite = () => {
  const [file, setFile] = useState("");
  let fileHandle;
  const content = file;
  const editorRef = useRef(null);
  const navigate = useNavigate();

  const [id] = useSearchParams();
  var docId = id.get("id");
  const [appPath] = useSearchParams();
  var app = appPath.get("appPath")
  const [optionsVisible] = useSearchParams();
  var options = optionsVisible.get("optionsVisible") ? optionsVisible.get("optionsVisible") : true
  const [fileName] = useSearchParams();
  var htmlName = fileName.get("fileName")
  const [isMainFile] = useSearchParams();  //da li se otavara glavni html fajl ili html fajl iz priloga
  var mainFile = isMainFile.get("isMainFile")
  const [attGuid] = useSearchParams();
  var attachmentGuid = attGuid.get("attGuid")
  
  var parser = new DOMParser();
  const htmlDocument = parser.parseFromString(file, "text/html");
  let regex =  /type="text\/css">([\s\S]*?)<\/style>/;
  let result = htmlDocument?.head?.innerHTML.match(regex)
  let contentStyle = result ? result[1] : ""  //u contentStyle se nalaze stilovi koji su u <style> bloku u head-u
  let headContent = htmlDocument?.head?.innerHTML;
  headContent = headContent.replace(/&amp;amp;amp;amp;gt;/g, '>');
  headContent = headContent.replace(/&amp;amp;amp;amp;lt;/g, '<');
  headContent = headContent.replace(/&gt;/g, '>');
  headContent = headContent.replace(/&lt;/g, '<');
  
  const { getHtml, updateHtmlFile, cancelUpdate } = useTextEditorContext();


  const [seachFormats, setSearchFormats] = useState("");
  const [formats, setFormats] = useState([]);
  const [filterFormats, setFilterFormats] = useState([]);

  const [hideHtmlBody, setHideHtmlBody] = useState(false);
  const [hideHtmlHead, setHideHtmlHead] = useState(true);
  
  const [contentTextArea, setContentTextArea] = useState("");

  const [htmlFromApps, setHtmlFromApps] = useState(false); //true je kad je html iz neke od aplikacija mml, fp...

    const getHmtlFile = async () => {
      var response = await getHtml(docId, app, mainFile, attachmentGuid, htmlName);
      var handledResponse = HandlingResponse(response);
      if (handledResponse != 'Error') {
        const fileContent = Buffer.from(response.data.fileContent.fileContents, 'base64').toString();
        setFile(fileContent)
        setHtmlFromApps(true);
      }
      
    }

  useEffect(() => {
    getHmtlFile();
    // if(tinymce?.activeEditor?.getContent())
    //   document.getElementById("htmlTextArea").value = editorRef.current.getContent();
  }, [])


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const textStr = reader.result;
        setFile(textStr);
        setHtmlFromApps(false);
      };

      if (file) {
        reader.readAsText(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/html": [".html", ".htm"],
    },
    maxFiles: 1,
  });

  const deleteFile = () => {
    setFile(null);
  };

  const save = async () => {
    let stream = await fileHandle.createWritable();
    htmlDocument.body.innerHTML = editorRef.current.getContent();
    htmlDocument.head.innerHTML = document.getElementById("textAreaHead").value;
    await stream.write(new XMLSerializer().serializeToString(htmlDocument));
    await stream.close();
  };

  const saveFile = async () => {
    fileHandle = await window.showSaveFilePicker();
    save();
  };


  const editFile = async () => {
    if (htmlFromApps) {
      var formData = new FormData();

      htmlDocument.body.innerHTML = editorRef.current.getContent();
      htmlDocument.head.innerHTML = document.getElementById("textAreaHead").value;
      const modifiedHtml = new XMLSerializer().serializeToString(htmlDocument);

      const encoder = new TextEncoder();
      const uint8Array = encoder.encode(modifiedHtml);
      const blob = new Blob([uint8Array], { type: "application/octet-stream" });

      if (mainFile.includes("true"))
        formData.append("Id", docId)
      else {
        if (app.includes("registries"))
          formData.append("LawActId", docId)
        else if (app.includes("mml"))
          formData.append("DataId", docId)
        else if (app.includes("frontpage"))
          formData.append("id", docId)
        else if (app.includes("regulations"))
          formData.append("LawActId", docId)
      }
      if (mainFile.includes("false"))
        formData.append("AttachmentName", htmlName)
      formData.append("File.Guid", "");
      formData.append("File.Name", htmlName);
      formData.append("File.File", blob);
          
      var response = await updateHtmlFile(formData, app, mainFile);
      var handledResponse = HandlingResponse(response)
      
    }
    else{
      fileHandle = await window.showSaveFilePicker();
      save();
    }
  }

  const cancel = () => {
    if (htmlFromApps){
      window.close()
    }
    else
      setFile(null);
      setContentTextArea("")
  }

  const addBorder = () => {
    const tableElements = document.getElementById('kljuc_ifr').contentWindow.document.getElementById('tinymce').getElementsByTagName("table");
    if (tableElements.length > 0) {
      for (let item of tableElements) {
        item.classList.add("tabela-sa-borderom");
      }
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const addBorderSelect = () => {
    const item = tinymce.activeEditor.selection.getNode();
    if (item.tagName == 'TABLE') {
      item.classList.add("tabela-sa-borderom");
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const removeBorder = () => {
    const tableElements = document.getElementById('kljuc_ifr').contentWindow.document.getElementById('tinymce').getElementsByTagName("table");
    if (tableElements.length > 0) {
      for (let item of tableElements) {
        item.classList.remove("tabela-sa-borderom");
      }
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  }

  const removeBorderSelect = () => {
    const item = tinymce.activeEditor.selection.getNode();
    if (item.tagName == 'TABLE') {
      item.classList.remove("tabela-sa-borderom");
    }
    if (options){
      updateHTML(editorRef.current.getContent());
    }
  };

  const updateHTML = (content) => {
    // document.getElementById("htmlTextArea").value = content;
    setContentTextArea(content)
  }

  const updateHead = (value) => {
    document.getElementById("textAreaHead").value = value;
  }

  const updateEditor = (value) => {
    setContentTextArea(value)
    // if (!tinymce.activeEditor?.hasFocus()) 
    //   tinymce.activeEditor.setContent(document.getElementById("htmlTextArea").value);
      // tinymce.activeEditor.setContent(value);
    
    // if (textToSearch == "")
    //   setTextToSearch(null)
  }

  const removeAlign = () => {
    let updatedText = editorRef.current.getContent().replace(/align="center"|align="left"|align="right"|align="justify"|text-align: center;|text-align: left;|text-align: right;|text-align: justify;/g, '');  // g za globalno trazenje
    setContentTextArea(updatedText);
    tinymce.activeEditor.setContent(updatedText);
  }

  const clearText = (text) => {  //cisti tekst od atributa koje sam dodeljuje tinymce
    let regexHref = / data-mce-href="([\s\S]*?)"/g
    let regexStyle = / data-mce-style="([\s\S]*?)"/g
    text = text.replace(regexHref, '');
    text = text.replace(regexStyle, '');
    return text;
  }

  const clickOnText = async (textareaContent) => {
    var textarea = document.getElementById("htmlTextArea");
    let nodeText = tinymce.activeEditor.selection.getNode().innerHTML;
    nodeText = clearText(nodeText);
    nodeText = nodeText.replace(/&nbsp;/g, '');
    const selectionStart = textareaContent.indexOf(nodeText);
    // const selectionEnd = textareaContent.indexOf(nodeText) + 
    textarea.setSelectionRange(selectionStart, selectionStart);
    textarea.focus();

    //ako se razlikuju pocetni i krajnji offset, znaci da je deo teksta selektovan i ne treba da se vraca pozicija kursora
    //pozicija kursora se vraca samo ako je kliknuto na tekst, bez selektovanja:
    if(tinymce.activeEditor.selection.getSel().anchorOffset === tinymce.activeEditor.selection.getSel().extentOffset) { 
      //vracanje kursora na staru poziciju, odnosno tu gde je kliknuto
      var range = tinymce.activeEditor.dom.createRng();
      range.setStart(tinymce.activeEditor.selection.getSel().baseNode, tinymce.activeEditor.selection.getSel().anchorOffset);
      range.collapse(true);  // Postavljamo kraj range-a na početak
      // Postavljamo kursor na navedeni range
      tinymce.activeEditor.selection.setRng(range);
    }
    tinymce.activeEditor.focus() //fokus na editor se vraca u oba slucaja
  }

  const clickOnHtml = () => {
    const textarea = document.getElementById("htmlTextArea")
    const cursorStart = textarea.selectionStart;
    const cursorEnd = textarea.selectionEnd;

    //nalaze se pocetni i krajni indeksi paragrafa na koji je kliknuto
    let lineStart = cursorStart;
    while (lineStart > 0 && textarea.value[lineStart - 1] !== '\n') {
      lineStart--;
    }
    
    let lineEnd = cursorEnd;
    while (lineEnd < textarea.value.length && textarea.value[lineEnd] !== '\n') {
      lineEnd++;
    }

    // Izdvajamo tekst na koji je kliknuto
    const clickedText = textarea.value.substring(lineStart, lineEnd);

    var regex = /<([^\s>]+)/
    var nodeTag = clickedText.match(regex)[1]
    let listOfNodes = [...tinymce.activeEditor.dom.getRoot().querySelectorAll(nodeTag)]
    listOfNodes = listOfNodes.filter((item) => clearText(item.outerHTML) === clickedText)
    const selectNode = listOfNodes[0];
    tinymce.activeEditor.selection.select(selectNode)
    selectNode?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

  const editHead = () => {
    setHideHtmlBody(true);
    setHideHtmlHead(false);
  }

  const cancelEditHead = () => {
    setHideHtmlBody(false);
    setHideHtmlHead(true);
  }


 const getAllFormats = () => {
    var formatsList = tinymce.activeEditor?.formatter.get();
    formatsList && setFormats(Object.entries(formatsList).filter(([key, value]) => value[0].title))
  }

    useEffect(() => {
      getAllFormats()
    }, [tinymce.activeEditor])


    const addFormatStyle = (classKey, classValue) => {
      if (classValue.includes("v2-")) {
        var classToRemove = tinymce.activeEditor.selection.getNode().classList;
        classToRemove = [...classToRemove]
        classToRemove = classToRemove.filter((x) => x.includes("v2-"))[0]  //trazi samno onu klasu koja pocinje sa v2, to su one za *
        //ako selektovani tekst vec ima neku klasu sa zvezdicom i zeli da dodeli novu klasu sa zvezdicom, onda prvo mora da ukloni prethodnu jer ne smeju da se sabiraju klase
        if(classToRemove?.includes("v2-") && classValue?.includes("v2-"))
        {
          classToRemove = formats.filter(([key, value]) => value[0].title === classToRemove)[0]  //uzima onu vrednost klase mce_x
          tinymce.activeEditor.formatter.remove(classToRemove[0])
        }
      }
    
      tinymce.activeEditor.formatter.apply(classKey);
      updateHTML(editorRef.current.getContent())

    }

  const searchFormats = (seachFormats) => {
    var filterFormatsList = formats.filter(([key, value]) => value[0].title && value[0].title.toLowerCase().includes(seachFormats))
    setFilterFormats(filterFormatsList)
  }

  const formatsSideClasses = () => {
    return seachFormats ? filterFormats : formats;
  }

  const refreshHtml = () => {
    if (editorRef.current) {
      document.getElementById("htmlTextArea").value = editorRef.current.getContent();
    }
  };

  const refresheditor = () => {
    tinymce.activeEditor.setContent(document.getElementById("htmlTextArea").value)
  }

  return (
    <>
      {!file ? (
        <div style={{ padding: "15px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          <h5>Убаците ХТМЛ фајл</h5>
        </div>
      ) : (
        <>
          <div>
            <div className="flex-text-editor">
              <div className="row">
                <div className="col-md-10">
                  <Editor
                    id="kljuc"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={content}
                    init={{
                      height: 530,
                      menubar: true,
                      toolbar: options
                        ? "undo redo visualchars visualblocks | formatselect | " +
                          "bold italic underline superscript subscript strikethrough forecolor backcolor | casechange blocks fonts fontfamily fontsize |alignleft aligncenter alignright alignjustify |" +
                          " outdent indent bullist numlist|  link image |" +
                          "removeformat | code searchreplace"
                        : "formatselect",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
                        "a {text-decoration: underline}",
                      plugins:
                        " importcss table link searchreplace code lists image visualchars visualblocks quickbars",
                      content_css: window.location.origin + "/glasnik.css",
                      content_style: contentStyle,
                      importcss_append: true,
                      entity_encoding: "raw",
                      selector: "textarea",
                      quickbars_selection_toolbar:
                        "bold italic underline fonts fontfamily fontsize forecolor backcolor| blocks |alignleft aligncenter alignright alignjustify  | quicklink ",
                      table_toolbar:
                        "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                      setup:
                        options &&
                        function (editor) {
                          editor.on("init change", function (e) {
                            updateHTML(editor.getContent());
                          });
                          editor.on("click", function (e) {
                            clickOnText(editor.getContent());
                          });
                          editor.addShortcut("meta+s", "Save", () => {
                            editFile();
                          });
                          editor.addShortcut("meta+e", "Center", () => {
                            var classCenter = Object.entries(tinymce.activeEditor?.formatter.get()).filter(([key, value]) => value[0].title === "p.centar")[0]
                            console.log(classCenter[0])
                            addFormatStyle(classCenter[0], "p.centar");
                          });
                          document.addEventListener(
                            "keydown",
                            function (event) {
                              //kad se klikne ctrl s ako je van fokusa editora
                              if (event.ctrlKey && event.code === "KeyS") {
                                event.preventDefault();
                                editFile();
                              }
                            }
                          );
                        },
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Претражи класе..."
                    onChange={(e) => {
                      setSearchFormats(e.target.value);
                      searchFormats(e.target.value);
                    }}
                    value={seachFormats}
                    style={{ marginLeft: "-10px" }}
                  />
                  <div className="classesContainer">
                    {" "}
                    {/*div za prikaz css klasa*/}
                    {formatsSideClasses().map(([key, val]) => {
                      var combinedClasses = val[0].title
                        ? val[0].title.includes("p.")
                          ? val[0].title.substring(2) + " itemList"
                          : val[0].title + " itemList"
                        : "";
                      return (
                        <div key={key} className="divList">
                          <ul
                            key={key}
                            className={combinedClasses}
                            onClick={() => addFormatStyle(key, val[0].title)}
                          >
                            {val[0].title}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div>
                {/* { htmlProp ? <><button type="button" className="btn btn-primary" style={{margin:"3px"}} onClick={saveFile}>Сачувај фајл</button>
               <button type="button" className="btn btn-primary" style={{margin:"3px"}} onClick={deleteFile}>Обриши фајл</button></> :  */}
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={editFile}
                >
                  Сачувај измене
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={cancel}
                >
                  Одустани од измена
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={refresheditor}
                  id="refreshBtn"
                >
                  Освежи текст
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={refreshHtml}
                  id="refreshBtn"
                >
                  Освежи ХТМЛ
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={addBorder}
                >
                  Додај бордер свим табелама
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={addBorderSelect}
                >
                  Додај бордер селектованој табели
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={removeBorder}
                >
                  Уклони бордер свим табелама
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={removeBorderSelect}
                >
                  Уклони бордер селектованој табели
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={removeAlign}
                  title="Уклони поравнања у целом ХТМЛ-у"
                >
                  Уклони поравнања
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={editHead}
                  hidden={!hideHtmlHead}
                >
                  Измени head
                </button>
                <button
                  type="button"
                  className="btnTiny btn-primary"
                  style={{ margin: "3px" }}
                  onClick={cancelEditHead}
                  hidden={hideHtmlHead}
                >
                  Затвори измену head-а
                </button>
              </div>
              {options && (
                
                  <>
                    <textarea
                      defaultValue={contentTextArea}
                      id="htmlTextArea"
                      hidden={hideHtmlBody}
                      className="HTMLContainerLite"
                      onChange={() => updateEditor()}
                      onClick={() => clickOnHtml()}
                    ></textarea>
                    <textarea
                      id="textAreaHead"
                      defaultValue={headContent}
                      hidden={hideHtmlHead}
                      className="HTMLContainerLite"
                      onChange={(e) => updateHead(e.target.value)}
                    ></textarea>
                    </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TinyMCELite;
