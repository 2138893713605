import React, { useState, useEffect, useCallback } from "react";
import SideModal from "../../../common/modal/SideModal.component";
import Table from "../../../common/table/Table.component";
import { AuthorityKindModel } from "../../models/Authority";
import CreateAuthorityKind from "../create-authority-kind/createAuthorityKind.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AuthorityClient from "../../api-services/authority/authority-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const AuthorityKind = ({ authorityKindsData, handleChange, authorityInfo }) => {
  const { t } = useTranslation();
  const tableName = 'MML_AUTHORITY_KIND';

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Измени"
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Врста доносиоца ће бити трајно обрисана."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  var client = new AuthorityClient();

  const [show, setShow] = useState(false);
  const [allAuthorityKinds, setAllAuthorityKinds] = useState([
    new AuthorityKindModel(),
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AuthorityKindModel());
  const [title, setTitle] = useState("");

  useEffect(() => {
    setAllAuthorityKinds(authorityKindsData);
  }, [authorityKindsData]);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      authorityGroupId: authorityInfo.groupId,
      authorityGroupName: authorityInfo.groupName,
      name: "",
      weight: 0,
      active: true
    });
    setTitle(t("CreateAuthorityKind"));
    showModal();
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData({
      id: row.id,
      authorityGroupId: row.authorityGroupId,
      authorityGroupName: authorityInfo.groupName,
      name: row.name,
      weight: row.weight,
      active: row.active,
    });
    setTitle(t("EditAuthorityKind"));
    showModal();
  };

  const onDeleteClick = async (id) => {
    var response = await client.deleteAuthorityKind(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error") {
      let allAuthorityKindsCopy = allAuthorityKinds.filter((x) => x.id !== id);
      setAllAuthorityKinds(allAuthorityKindsCopy);
    }
  };

  const handleTableRowClick = useCallback((response) => {
    getAythorityAreasByAuthorityKind(response.id, response.name);
  });

  const getAuthorityKindsByGroup = async () => {
    let response = await client.getAuthorityKindsByAuhtorityGroupId(authorityInfo.groupId, true);
    let handledResponse = HandlingResponse(response);
    handledResponse && setAllAuthorityKinds(handledResponse);
  };

  const getAythorityAreasByAuthorityKind = async (id, name) => {
    let response = await client.getAuthorityAreasByAuhtorityKindId(id, true);
    let res = HandlingResponse(response);
    handleChange(res, id, name);
  };

  return (
    <>
      <div>
        <h5>{t("AuthorityKinds")}</h5>
        <button className="btn btn-primary" onClick={onCreateClick}>
          {t("AddKind")}
        </button>
      </div>
        <br />
      <Table
        rememberFilter={false}
        tableName={tableName}
        columns={columns}
        data={allAuthorityKinds}
        hiddenColumn={["id"]}
        serverSide={false}
        handleClickOnRow={handleTableRowClick}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAuthorityKind
          row={rowData}
          isEdit={isEdit}
          handleCallback={() => {
            hideModal();
            getAuthorityKindsByGroup();
          }}
        />
      </SideModal>
    </>
  );
};

export default AuthorityKind;
