import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UserClient from "../../api-services/create-users/create-users-client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateUser = ({ row, isCreate, handleResponse }) => {
  const [privileges, setPrivileges] = useState("");
  const [rolesList, setRolesList] = useState([]);

  const [user, setUser] = useState({
    name: "",
    lastName: "",
    email: "",
    telephone: "",
    address: "",
    company: "",
    userName: "",
    userGuid: "",
    password: "",
    id: 0
  })

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [notValid, setNotValid] = useState(false);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    name: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().email().required(),
    telephone: Joi.string().required(),
    address: Joi.string().allow("").optional(),
    company: Joi.string().allow("").optional(),
    userName: Joi.string().required(),
    password: Joi.string().min(6).required()
  }

  useEffect(() => {
    setUser(row);
    setPrivileges(row.roles);
    setErrors({});
    setPasswordRepeat("");
    setNotValid(false)
  }, [row]);

  const client = new UserClient();

  const getRolesList = async () => {
    let response = await client.GetRoles();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { value: item.id, label: item.name };
    });
    setRolesList(result);
  };

  useEffect(() => {
    getRolesList();
  }, []);

  const CreateUser = async () => {
    if (!isCreate) {
      schema.password = Joi.optional();
    }
    const errorsResult = validateForm(user, schema, t);
    setErrors(errorsResult);

    if (!errorsResult && !notValid) {
      const data = {
        ...user,
        roles: privileges.map(x => x.value),
        isInternal: true,
        maxNumOfSessions: 1,
        isGroupRequest: false
      };
      var response;
      if (isCreate) {
        response = await client.CreateUser(data);
      } else {
        response = await client.UpdateUser(user.userGuid, data);
      }
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      }
      else
        handleResponse(handledResponse)
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
    const result = handleSave(obj, user, errors, schema, t);
    setUser(result.data);
    setErrors(result.errors);
  }

  const checkPassword = (password, passwordRepeat) => {
    if (password !== passwordRepeat) {
      setNotValid(true);
    } else {
      setNotValid(false);
    }
  }

  return (
    <>
      <div className="form-group">
        <label>Привилегије</label>
        <Select
          options={rolesList}
          onChange={(e) => setPrivileges(e)}
          value={privileges}
          isMulti
        />
      </div>
      <div className="form-group">
        <label>Име<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="name"
          value={user.name}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.name && (
          <div className="text-danger">
            {errors.name}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Презиме<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="lastName"
          value={user.lastName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.lastName && (
          <div className="text-danger">
            {errors.lastName}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Е-маил<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="email"
          value={user.email}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.email && (
          <div className="text-danger">
            {errors.email}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Телефон<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="telephone"
          value={user.telephone}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.telephone && (
          <div className="text-danger">
            {errors.telephone}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Адреса</label>
        <input
          className="form-control"
          type="text"
          name="address"
          value={user.address}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.address && (
          <div className="text-danger">
            {errors.address}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Компанија</label>
        <input
          className="form-control"
          type="text"
          name="company"
          value={user.company}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.company && (
          <div className="text-danger">
            {errors.company}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Корисничко име<span className="required-asterisk">*</span></label>
        <input
          disabled = {!isCreate}
          className="form-control"
          type="text"
          name="userName"
          value={user.userName}
          onChange={(e) => handlePropertyChange(e.target)}
          autoComplete="off"
        />
        {errors?.userName && (
          <div className="text-danger">
            {errors.userName}
          </div>
        )}
      </div>
      {isCreate && (
        <>
          <div className="form-group">
            <label>Лозинка<span className="required-asterisk">*</span></label>
            <input
              className="form-control"
              type="password"
              name="password"
              value={user.password}
              onChange={(e) => {
                handlePropertyChange(e.target); 
                passwordRepeat !== "" && checkPassword(user.password, e.target.value)}}
              autoComplete="off"
            />
            {errors?.password && (
              <div className="text-danger">
                {errors.password}
              </div>
            )}
          </div>
          <div className="form-group">
            <label>Поновите лозинку</label>
            <input 
              disabled = {user.password === "" ? "disabled" : ""}
              className="form-control"
              type="password"
              value={passwordRepeat}
              onChange={(e) => { setPasswordRepeat(e.target.value); checkPassword(user.password, e.target.value)}}
            />
            {notValid && (
          <div className="text-danger">Лозинке се не поклапају!</div>
        )}
          </div>
        </>
      )}

      <button
        className="btn btn-primary"
        type="button"
        onClick={() => CreateUser()}
      >
        {isCreate ? "Додај" : "Измени"}
      </button>
    </>
  );
};

export default CreateUser;