import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_SLGL;

class NumberClient {
  async getNumber(isBackground) {
    try {
      var response = await API.get(baseurl + "/Number", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async FilterNumbersByIssueTypeAndYear(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Number/FilterNumbersByIssueTypeAndYear", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  // filtriranje pablishovanih brojeva za app PPP
  async FilterPublishedNumbersByIssueTypeAndYear(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Number/FilterPublishedNumbersByIssueTypeAndYear", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async FilterNumbers(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Number/FilterNumbers", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetDateAndPDF(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Number/GetFileAndPublishDate", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateNumberByPublishDate(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Number/UpdateNumberByPublishDate", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateNumberByFile(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Number/UpdateNumberPdf", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateNumberFileAndPublishDate(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Number/UpdateNumberFileAndPublishDate", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteNumber(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/Number/DeleteNumber/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getNumbersByNumberAndYear(searchText, isBackground) {
    const params = {
      params: { query: searchText },
      isBackground: isBackground
    };
    try {
      var response = await API.get(baseurl + "/Number/GetNumbersByNumberAndYear/", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getNumbersByIssueType(issueTypeGuid, isBackground) {
    const params = {
      params: { issueTypeGuid: issueTypeGuid },
      isBackground: isBackground
    };
    try {
      var response = await API.get(baseurl + "/Number/GetNumbersByIssueType", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async publishNumber(id, isBackground) {
    try {
      var response = await API.put(baseurl + "/Number/PublishNumber?numberId=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async unpublishNumber(id, isBackground) {
    try {
      var response = await API.put(baseurl + "/Number/UnpublishNumber?numberId=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  
  async indexNumber(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Number/IndexNumber/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexNumber(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Number/DeleteIndexNumber/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

      async GetNumberFile(id, fileName) {
        try{
          var response = await API.get(baseurl + "/Number/DownloadNumberFile/" + id, { responseType: 'blob' })
            return response.data;
        }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

      async DownloadNumberFile(id, fileName) {
        try {
          var response = await API.get(baseurl + "/Number/DownloadNumberFile/" + id, { responseType: 'blob' }
          ).then(({ data }) => {
              const blob = new Blob([data]);
              const link = document.createElement('a');
              const url = URL.createObjectURL(blob);
              link.href = url;
              link.download = fileName;
              link.click();
          });
      }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

      async GetArchiveYearsByIssueType(issueTypeGuid, isBackground) {
        try {
          var response = await API.get(baseurl + "/Number/GetArchiveYearsByIssueType?issueTypeGuid=" + issueTypeGuid, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

      async GetArchiveYears(isBackground) {
        try {
          var response = await API.get(baseurl + "/Number/GetArchiveYears", { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

      async GetCurrentYearsByIssueType(issueTypeGuid, isBackground) {
        try {
          var response = await API.get(baseurl + "/Number/GetCurrentYearsByIssueType?issueTypeGuid=" + issueTypeGuid, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

}

export default NumberClient;