import React, { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import UserEditions from "../../components/user-editions/UserEditions.component";
import AllEditions from "../../components/all-editions/AllEditions.component";
import { useLocation, useSearchParams } from "react-router-dom";
import UserSettings from "../../components/user-settings/UserSettings.component";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const EditUserAccountById = () => {
  const client = new CreateClient();
  const { state } = useLocation();

  const [userGuid, setUserGuid] = useSearchParams();
  var user = userGuid.get("userGuid");

  const [page, setPage] = useState("1");
  const [pageGroup, setPageGroup] = useState("3");
  const [userPackages, setUserPackages] = useState([]);
  const [nonAssignedPackages, setNonAssignedPackages] = useState([]);
  const [connections, setConnections] = useState();

  useEffect(() => {
    getUserInfo();
  }, [page, pageGroup]);

  const getUserInfo = async () => {
    let response = await client.GetUserInfoByUserGuid(user);
    let handledResponse = HandlingResponse(response);
    if (handledResponse) {
      setNonAssignedPackages(handledResponse.nonAssignedPackages);
      setUserPackages(handledResponse.userPackages);
      setConnections(handledResponse.maxNumOfSessions);
    }
  };
  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  return (
    <TabContext value={!state ? page : pageGroup}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tabList">
        <TabList onChange={handleChangePage}>
          {!state && <Tab label="Пакети купца" value="1" className="tab" />}
          {!state && <Tab label="Додавање нових пакета" value="2" />}
          <Tab label="Подешавања" value="3" />
        </TabList>
      </Box>
      {!state && <TabPanel value="1">
        <UserEditions packages={userPackages} setPackages={setUserPackages} userGuid={user} />
      </TabPanel>}
      {!state && <TabPanel value="2">
        <AllEditions packages={nonAssignedPackages} setPackages={setNonAssignedPackages} userGuid={user} />
      </TabPanel>}
      <TabPanel value="3">
        <UserSettings connections={connections} setConnections={setConnections} userGuid={user} />
      </TabPanel>
    </TabContext>
  );
};

export default EditUserAccountById;
