import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_ADMIN_PANEL;

class ActReferenceTypeClient {

async getActiveActReferenceTypes(isBackground) {
    try {
      var response = await API.get(baseurl + "/ActReferenceType/getActiveActReferenceTypes", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ActReferenceTypeClient;