import React, { useState, useCallback, useEffect } from "react";
import Table, { getPageNumber, getPageSizeNumber, getPropsForFilter, getSortBy } from "../../../common/table/Table.component";
import DataClient from "../../api-services/data/data-client";
import SearchDocument from "../../components/search-document/searchDocument.component";
import Moment from "moment";
import { DataFilterModel } from "../../models/DataFilter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import IndexingStatus, {
  StatusForIndexing,
  StatusForDeleteIndex,
} from "../../../common/indexing-status/IndexingStatus";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import UserService from "../../../utils/authentification/keycloak";

const Index = () => {
  const { t } = useTranslation();
  const tableName = "MML_INDEX";
  const rememberFilter = true;

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "isLocked",
      Cell: ({ row }) => {
        let className =
          row.original.lockUserGuid !== UserService.getUserGuid()
            ? "locked-icon"
            : "locked-icon-by-myself";
        return (
          <>
            {row.original.isLocked ? (
              <FontAwesomeIcon
                title={`Документ је закључаo ${row.original.lockUserName}`}
                className={className}
                icon={solid("lock")}
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      Header: t("fileName"),
      accessor: "fileName",
    },
    {
      Header: t("Type"),
      accessor: "typeName",
    },
    {
      Header: t("Authority"),
      accessor: "authorityName",
    },
    {
      Header: t("ActLabelNumber"),
      accessor: "actLabelNumber",
    },
    {
      Header: t("EnactmentDate"),
      accessor: "enactmentDate",
      Cell: ({ row }) => {
        return row.original.enactmentDate
          ? Moment(row.original.enactmentDate).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      Header: t("ActCloserDesignation"),
      accessor: "actCloserDesignation",
    },
    {
      Header: t("Published"),
      accessor: "published",
      Cell: ({ row }) => {
        return row.original.published ? "Да" : "Не";
      },
    },
    {
      Header: t("CreationDate"),
      accessor: "creationDate",
      Cell: ({ row }) => {
        return row.original.creationDate
          ? Moment(row.original.creationDate).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      accessor: "delete",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => getDataById(row.original.id)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            {!row.original.published &&
              ((row.original.isLocked &&
                row.original.lockUserGuid === UserService.getUserGuid()) ||
                !row.original.isLocked) && (
                <ButtonWithComfirmation
                  className="btn btn-danger table-btn"
                  onClick={() => deleteData(row.original.id)}
                  contentText={"Документ ће бити трајно обрисан."}
                  handleYesText={"Обриши"}
                  title={"Трајно обриши"}
                >
                  <FontAwesomeIcon icon={solid("trash")} />
                </ButtonWithComfirmation>
              )}
          </>
        );
      },
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "addIndex",
      className: "one-button-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title={
                row.original.published
                  ? "Индексирај"
                  : "Индексирање необјављеног документа није дозвољено"
              }
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexData(row.original.id)}
              disabled={
                !row.original.published ||
                !StatusForIndexing.includes(row.original.indexStatus)
              }
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new DataClient();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState(new DataFilterModel());
  const [reset, setReset] =  useState(false);

  useEffect(() => {
    reset && setReset(false);
  }, [tableData])

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("MML_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }
    var response = await client.filterData({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    });
    let data = HandlingResponse(response);
    return data;
  };

  const resetFilters = async () => {
    setReset(true);
    setFilterData(new DataFilterModel());
    sessionStorage.removeItem("MML_FILTER");
    var response = await client.filterData({});
    let data = HandlingResponse(response);
    setTableData(data);
  };

  const searchData = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("MML_FILTER", JSON.stringify(filterData));

    const response = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(response);
  };

  const onCreateClick = () => {
    navigate("/mml/document");
  };

  const getDataById = (id) => {
    client.GetDataForUpdateById(id).then((res) => {
      var data = HandlingResponse(res);
      if (data !== "Error")
        navigate("/mml/document?documentId=" + data.id, { state: { data } });
    });
  };

  const deleteData = async (id) => {
    var response = await client.deleteData(id);
    HandlingResponse(response);
  };

  const indexAllData = async () => {
    var response = await client.massIndexing();
    HandlingResponse(response);
  };

  const indexData = async (id) => {
    var response = await client.indexData(id);
    HandlingResponse(response);
  };

  const deleteIndex = async (id) => {
    var response = await client.deleteIndexData(id);
    HandlingResponse(response);
  };

  return (
    <>
      <div>
        <SearchDocument
          filterData={filterData}
          setFilterData={setFilterData}
          handleCallback={searchData}
          resetFilters={resetFilters}
        />
        <label>{t("countResult",{count: tableData.count, type: 'докумената'})}</label>
        <hr />
        <div className="text-right">
          <button
            onClick={onCreateClick}
            type="button"
            className="btn btn-primary"
          >
            Додавање новог документа
          </button>
          <RenderOnRole roles={["MML_Admin"]}>
          <ButtonWithComfirmation
              className="btn btn-primary"
              onClick={() => indexAllData()}
              style={{ marginLeft: "5px" }}
              contentText={"Покренуће се индексирање свих докумената."}
              handleYesText={"Покрени"}
              >
                Масовно индексирање
            </ButtonWithComfirmation>
          </RenderOnRole>
        </div>
        <Table
          columns={columns}
          data={tableData}
          tableName={tableName}
          rememberFilter={rememberFilter}
          hiddenColumn={["id"]}
          fetchFunction={fetchDataForPage}
          serverSide={true}
          setData={setTableData}
          resetFilters={reset}
        />
      </div>
    </>
  );
};

export default Index;
