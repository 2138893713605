import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChangePassword from "../change-password/ChangePassword.component";
import UserSettings from "../user-settings/UserSettings.component";

const EditSettings = ({ id, sessionsNumber }) => {

    const [page, setPage] = useState("1");
    const [connections, setConnections] = useState();

    useEffect(() => {
        setConnections(sessionsNumber)
    }, [])

    const handleChangePage = (event, newValue) => {
        setPage(newValue);
      };

    return(
        <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Промена лозинке" value="1" className="tab" />
            <Tab label="Подешавања" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ChangePassword id={id}></ChangePassword>
        </TabPanel>
        <TabPanel value="2">
          <UserSettings connections={connections} setConnections={setConnections} userGuid={id}></UserSettings>
        </TabPanel>
        
      </TabContext>
    )
}

export default EditSettings;