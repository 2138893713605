
import { env } from "../../../env";
import API from "../../../api";

var baseurl = env.REACT_APP_API_URL_MML
class AuthorityClient {
  ////#region Authority group
  async getAuthorityGroup(isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityGroup", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveAuthorityGroup(isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityGroup/GetAllActiveAuthorityGroups", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityGroupById(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityGroup" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createAuthorityGroup(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/AuthorityGroup/CreateAuthorityGroup", data, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

  }

  async updateAuthorityGroup(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/AuthorityGroup/UpdateAuthorityGroup", data, {isBackground: isBackground});
        return response.data;
  }
  catch (err) {
    if (err.response.status === 400) return err.response.data;
    else return err.response;
  }
  
  }

  async deleteAuthorityGroup(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/AuthorityGroup/DeleteAuthorityGroup/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion

  //#region Authority kinds

  async getAuthorityKind(isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityKind", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityKindById(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityKind/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityKindsByAuhtorityGroupId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityKind/getAuthorityKindsByAuthorityGroupId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveAuthorityKindsByAuhtorityGroupId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityKind/getActiveAuthorityKindsByAuthorityGroupId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }


  async createAuthorityKind(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/AuthorityKind/CreateAuthorityKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAuthorityKind(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/AuthorityKind/UpdateAuthorityKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteAuthorityKind(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/AuthorityKind/DeleteAuthorityKind/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion

  //#region Authority areas

  async getAuthorityArea(isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityArea", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityAreaById(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityArea/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityAreasByAuhtorityKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityArea/getAuthorityAreasByAuthorityKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveAuthorityAreasByAuhtorityKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityArea/getActiveAuthorityAreasByAuthorityKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createAuthorityArea(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/AuthorityArea/CreateAuthorityArea", data,{isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAuthorityArea(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/AuthorityArea/UpdateAuthorityArea", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteAuthorityArea(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/AuthorityArea/DeleteAuthorityArea/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion

  //#region Authorities

  async getAuthority(isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveAuthorities(isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority/GetAllActiveAuthorities", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthoritiesByAuthorityAreaId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority/getAuthoritiesByAuthorityAreaId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveAuthoritiesByAuthorityAreaId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority/getActiveAuthoritiesByAuthorityAreaId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthoritiesByAuthorityKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority/getAuthoritiesByAuthorityKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createAuthority(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Authority/CreateAuthority", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAuthority(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Authority/UpdateAuthority", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteAuthority(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/Authority/DeleteAuthority/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion
}

export default AuthorityClient;