import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import EventsCalendarClient from "../../../api-services/news/events-calendar/events-calendar";
import Table, { getPageNumber, getPageSizeNumber, getSortBy } from "../../../../common/table/Table.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import SearchEventsCalendar from "../../../components/news/search-events-calendar/SearchEventsCalendar.component";
import { format } from "date-fns";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateEventsCalendar from "../../../components/news/create-events-calendar/CreateEventsCalendar.component";
import SideModal from "../../../../common/modal/SideModal.component";

const EventsCalendar = () => {
  const tableName = "FP_EVENTS_CALENDAR";
  const rememberFilter = true;
  const columns = [
    {
      Header: "Назив догађаја",
      accessor: "title",
    },
    {
      Header: "Опис догађаја",
      accessor: "description",
    },
    {
      Header: "Датум објављивања",
      accessor: "eventDate",
      Cell: ({ row }) => {
        return (
          <div>
            {format(new Date(row.original.eventDate), "dd.MM.yyyy. HH:mm:ss")}
          </div>
        );
      },
    },
    {
      Header: "Објављен",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          row.original.published && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      accessor: "actions",
      className: 'two-buttons-column',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            <button
              className="btn btn-primary table-btn mr-2"
              onClick={() => onEditClick(row.original)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Догађај ће бити трајно обрисан."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
          </div>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldNamesForSort, setFieldNamesForSort] = useState([]);
  const [reset, setReset] =  useState(false);

  const publishedEnum = {
    All: 2,
    Published: 1,
    Unpublished: 0,
  };
  const [eventsFilterData, setEventsFilterData] = useState({
    title: "",
    description: "",
    eventDateFrom: null,
    eventDateTo: null,
    published: 2,
  });

  const [rowData, setRowData] = useState({
    title: "",
    description: "",
    eventDate: new Date(),
    published: false,
    active: false,
  });

  var client = new EventsCalendarClient();

  useEffect(() => {
    reset && setReset(false);
  }, [tableData])

  // Modal
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const filterDataForEventsPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.filterEvents(
      {
        ...eventsFilterData,
        pageSize: pageSize,
        pageNumber: pageNumber,
        fieldNamesForSort: sortBy,
      },
      false
    );
    let data = HandlingResponse(response);
    return data;
  };

  const ResetFilter = async () => {
    setReset(true);
    setSelectedPublishedEnum("2");
    setEventsFilterData({
      title: "",
      description: "",
      eventDateFrom: null,
      eventDateTo: null,
      published: 2,
    });

    var response = await client.filterEvents(
      {
        title: "",
        description: "",
        eventDateFrom: null,
        eventDateTo: null,
        published: 2,
      },
      true
    );
    let data = HandlingResponse(response);
    setTableData(data);
  };

  const searchData = async (e) => {
    e.preventDefault();
    const response = await filterDataForEventsPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(response);
  };

  const [selectedPublishedEnum, setSelectedPublishedEnum] = useState("2");
  const handleOptionsChange = (event) => {
    setSelectedPublishedEnum(event.target.value);
  };
  useEffect(() => {
    setEventsFilterData({
      ...eventsFilterData,
      published: parseInt(selectedPublishedEnum),
    });
  }, [selectedPublishedEnum]);

  // Kreiranje novog eventa

  const createNewEvent = () => {
    setEdit(false);
    setRowData({
      title: "",
      description: "",
      eventDate: new Date(),
      published: false,
      active: false,
    });
    showModal();
  };

  // Izmena eventa

  const onEditClick = (row) => {
    setEdit(true);
    setRowData({
      ...row,
    });
    showModal();
  };

  // Brisanje eventa

  const onDeleteClick = async (id) => {
    let response = await client.deleteEventApi(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      let tableDataCopy = tableData.filter((x) => x.id !== id);
      setTableData(tableDataCopy);
    }
  };

  return (
    <>
    <h5 className="col-md-2" style={{marginBottom: "25px"}}>Календар догађаја</h5> 
      <SearchEventsCalendar
        filterEventsData={eventsFilterData}
        setFilterEventsData={setEventsFilterData}
        handleOptionsChange={handleOptionsChange}
        selectedPublishedEnum={selectedPublishedEnum}
        resetFilters={ResetFilter}
        searchData={searchData}
      />
      <hr />
      <div className="text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => createNewEvent()}
        >
          Креирај догађај
        </button>
      </div>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={rememberFilter}
        tableName={tableName}
        serverSide={true}
        fetchFunction={filterDataForEventsPage}
        setData={setTableData}
        resetFilters={reset}
      />

      <SideModal
        show={show}
        handleClose={hideModal}
        title={edit ? "Измена догађаја" : "Креирање догађаја"}
      >
        <CreateEventsCalendar
          row={rowData}
          setRow={setRowData}
          edit={edit}
        ></CreateEventsCalendar>
      </SideModal>
    </>
  );
};

export default EventsCalendar;
