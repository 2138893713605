import Joi from "joi-browser";

class PreviewLawActModel {
    constructor(lawActBasicDataModel = new LawActBasicDataModel(), lawActReferenceModel = new LawActReferenceModel(),
        lawActOtherDataModel = new LawActOtherDataModel(), lawActChangesHistoryModel = new LawActChangesHistoryModel(), file = null, files = [], pictures = []) {
        this.lawActBasicDataModel = lawActBasicDataModel;
        this.lawActReferenceModel = lawActReferenceModel;
        this.lawActOtherDataModel = lawActOtherDataModel;
        this.lawActChangesHistoryModel = lawActChangesHistoryModel;
        this.file = file;
        this.files = files;
        this.pictures = pictures;
    }
}

class LawActBasicDataModel {
    constructor(id = 0, areaId = 0, categoryId = "", authorityId = "", superscript = "", lawActName = "", abstract = "", subscript = "",
        detailedDefinition = "", acclamationDateTime = null, effectiveDateTime = null, applicationDateTime = null, intlContractStartDate = null,
        validFromDate = null, validToDate = null, versionValidFromDate = null, versionApplicationDate = null, nameDateGazette = "",
        hasNoHtml = false, showStructureLink = false, showEditorMark = false, userGuid= "", userFullName= "",
        locked = false, indexStatus = 1, versionGuid = "") {

        this.id = id;
        this.areaId = areaId;
        this.categoryId = categoryId;
        this.authorityId = authorityId;
        this.superscript = superscript;
        this.lawActName = lawActName;
        this.abstract = abstract;
        this.subscript = subscript;
        this.detailedDefinition = detailedDefinition;
        this.acclamationDateTime = acclamationDateTime;
        this.effectiveDateTime = effectiveDateTime;
        this.applicationDateTime = applicationDateTime;
        this.intlContractStartDate = intlContractStartDate;
        this.validFromDate = validFromDate;
        this.validToDate = validToDate;
        this.versionValidFromDate = versionValidFromDate;
        this.versionApplicationDate = versionApplicationDate;
        this.nameDateGazette = nameDateGazette;
        this.hasNoHtml = hasNoHtml;
        this.showStructureLink = showStructureLink;
        this.showEditorMark = showEditorMark;
        this.userGuid = userGuid;
        this.userFullName = userFullName;
        this.locked = locked;
        this.indexStatus = indexStatus;
        this.versionGuid = versionGuid;
    }
}

class LawActReferenceModel {
    constructor(predecessor = "", globalActRefferences = Array(new GlobalActRefferenceByTypeModel()), globalActReferencesByType = Array(new GlobalActRefferenceByTypeModel())) {
        this.predecessor = predecessor;
        this.globalActRefferences = globalActRefferences;
        this.globalActReferencesByType = globalActReferencesByType;
    }
}

class GlobalActRefferenceByTypeModel {
    constructor(actReferenceTypeGuid = "", actReferenceTypeName = "", globalActRefferences = Array(new GlobalActRefferenceModel())) {
        this.actReferenceTypeGuid = actReferenceTypeGuid;
        this.actReferenceTypeName = actReferenceTypeName;
        this.globalActRefferences = globalActRefferences;

    }
}

class GlobalActRefferenceModel {
    constructor(id = 0, actReferenceTypeGuid = "", actReferenceTypeName = "", packageName = null, refActGuid = "", fkRegulationsAct = 0, actName = "", additionalInfo = "",
        isActive = false, externalUrlName = "", externalUrlValue = "") {

        this.id = id;
        this.actReferenceTypeGuid = actReferenceTypeGuid;
        this.actReferenceTypeName = actReferenceTypeName;
        this.packageName = packageName;
        this.refActGuid = refActGuid;
        this.fkRegulationsAct = fkRegulationsAct;
        this.actName = actName;
        this.additionalInfo = additionalInfo;
        this.isActive = isActive;
        this.externalUrlName = externalUrlName;
        this.externalUrlValue = externalUrlValue
    }
}

class LawActOtherDataModel {
    constructor(editorMark = "", editorMarkDeadlineDate = null, cssSuperscript = "", cssTitle = "", cssSubscript = "", cssDescription = "", cssSuperscriptClass = "",
        cssTitleClass = "", cssSubscriptClass = "", cssDescriptionClass = "", keywords = "") {

        this.editorMark = editorMark;
        this.editorMarkDeadlineDate = editorMarkDeadlineDate;
        this.cssSuperscript = cssSuperscript;
        this.cssTitle = cssTitle;
        this.cssSubscript = cssSubscript;
        this.cssDescription = cssDescription;
        this.cssSuperscriptClass = cssSuperscriptClass;
        this.cssTitleClass = cssTitleClass;
        this.cssSubscriptClass = cssSubscriptClass;
        this.cssDescriptionClass = cssDescriptionClass;
        this.keywords = keywords;
    }
}

class LawActChangesHistoryModel {
    constructor(actsChangesHistory = Array(new ActsChangesHistoryModel())) {
        this.actsChangesHistory = actsChangesHistory;
    }
}

class ActsChangesHistoryModel {
    constructor(username = "", editorMark = "", changedAt = null) {
        this.username = username;
        this.editorMark = editorMark;
        this.changedAt = changedAt;
    }
}
const schemaForBasicData = Joi.object().keys({
    lawActName: Joi.string().required()
})

export const schema = Joi.object({
    file: Joi.required(),
    lawActBasicDataModel: schemaForBasicData
})

export const databaseList = [
    {
      value: "regClient",
      label: "Регистри",
      enumVal: 0,
    },
    {
      value: "spClient",
      label: "Судска пракса",
      enumVal: 1,
    },
    {
      value: "slglClient",
      label: "Службена гласила",
      enumVal: 2,
    },
    {
      value: "mmlClient",
      label: "Мишљења, модели, литература",
      enumVal: 3,
    },
    {
        value: "regulationsClient",
        label: "Прописи града Београда",
        enumVal: 5,
      },
  ];

export { PreviewLawActModel, LawActBasicDataModel, LawActReferenceModel, LawActOtherDataModel, LawActChangesHistoryModel }