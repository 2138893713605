import Joi from "joi-browser";

class AreaModel {
    constructor(id = 0, name = "", active = true, level = 1) {
        this.id = id;
        this.name = name;
        this.active = active;
        this.level = level;
    }
}

class CreateAreaModel {
    constructor(parentAreaId = 1, name = "", packageId = 0, orderBy = 0) {
        this.parentAreaId = parentAreaId;
        this.name = name;
        this.packageId = packageId;
        this.orderBy = orderBy;
    }
}

class MoveAreaModel {
    constructor(areaId = 0, areaName = "", destinationAreaId = undefined, destinationGroupId = undefined) {
        this.areaId = areaId;
        this.areaName = areaName;
        this.destinationAreaId = destinationAreaId;
        this.destinationGroupId = destinationGroupId;
    }
}

const areaSchema = {
    name: Joi.string().required()
}


export { AreaModel, CreateAreaModel, MoveAreaModel, areaSchema }