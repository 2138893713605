import React from "react";
import { useState, useEffect } from "react";
import Tree from "./Tree.component";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TreeLabel } from "./Tree.style";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import useStateOfRegulationsTree from "../../hooks/useStateOfRegulationsTree";
import useMoveRegulationsLawActs from "../../hooks/useMoveRegulationsLawActs";

const TreeNode = ({
  node,
  getNode,
  deleteNode,
  changeNode,
  createNode,
  deactivateNode,
  onFilteredNode,
  isRight,
  expanded,
  isMoving,
  selectForMoving,
  isSearching,
}) => {
  const { children, areaName, name, hierarchyMark, isInactive } = node;

  const [showChildren, setShowChildren] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  //Zbog alerta koji postoji kod deaktivacije akta
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [rightClickClass, setRightClickClass] = useState("");

  const { selected, setSelected, enableMoving, onChecked } =
    useMoveRegulationsLawActs();
  const {
    setAreaId,
    actId,
    changeActId,
    setRightAreaId,
    arrayOfAreaIds,
    removeFromArray,
    rightActId,
    changeRightActId,
  } = useStateOfRegulationsTree();

  const hasChildren = children.length > 0;

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu === null) {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
      setRightClickClass("right-click-tree");
    } else {
      setContextMenu(null);
      setRightClickClass("");
    }
  };

  const handleClose = () => {
    setContextMenu(null);
    setRightClickClass("");
  };

  useEffect(() => {
    if (isRight) setShowChildren(true);

    if (!isMoving) {
      expanded && setShowChildren(true);
      if (node.id == actId && !isRight) {
        getNode(actId);
        setTimeout(() => {
          const leftElement = document
            .getElementsByClassName("left-tree")[0]
            .getElementsByClassName("clicked-tree")[0];
          if (leftElement) {
            leftElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 300);
      }

      if (node.id == rightActId && isRight) {
        getNode(rightActId, node.isSubarea);
        setTimeout(() => {
          const rightElement = document
            .getElementsByClassName("right-tree")[0]
            .getElementsByClassName("clicked-tree")[0];
          if (rightElement) {
            rightElement.scrollIntoView(true, { behavior: "smooth" });
          }
        }, 300);
      }
    }
  }, []);

  useEffect(() => {
    if (!isMoving) expanded && setShowChildren(true);
    if (node.id == actId && !isRight) {
      setTimeout(() => {
        const leftElement = document
          .getElementsByClassName("left-tree")[0]
          .getElementsByClassName("clicked-tree")[0];
        if (leftElement) {
          leftElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  }, [actId]);

  useEffect(() => {
    if (!isMoving)
      if (node.id == rightActId && isRight) {
        setTimeout(() => {
          const rightElement = document
            .getElementsByClassName("right-tree")[0]
            .getElementsByClassName("clicked-tree")[0];
          if (rightElement) {
            rightElement.scrollIntoView(true, { behavior: "smooth" });
          }
        }, 300);
      }
  }, [rightActId]);

  useEffect(() => {
    !enableMoving && setIsChecked(false);
  }, [enableMoving]);

  const handleClick = (event) => {
    if (!isMoving) {
      if (event.target.role === null) {
        var val = arrayOfAreaIds.some(
          (el) => el === parseInt(event.currentTarget.id)
        );

        if (hasChildren) {
          !isRight && setShowChildren(!showChildren);
          if (val) removeFromArray(parseInt(event.currentTarget.id));
          else {
            if (!isRight) setAreaId(parseInt(event.currentTarget.id));
            else setRightAreaId(parseInt(event.currentTarget.id));
          }
        } else if (!isRight) {
          getNode(parseInt(event.currentTarget.id));
          changeActId(parseInt(event.currentTarget.id));
        }
      }
    } else {
      if (event.target.role === null)
        if (hasChildren) {
          if (isRight) {
            selectForMoving(node);
            setSelected(event.currentTarget.id);
          } else setShowChildren(!showChildren);
        } else if (!isRight) {
          getNode(parseInt(event.currentTarget.id));
          if (!hasChildren) {
            selectForMoving(node);
            setSelected(event.currentTarget.id);
          }
        } else {
          selectForMoving(node);
          setSelected(event.currentTarget.id);
        }
    }
  };
  const setHoverAndClickClasses = () => {
    let text = "";
    if (isMoving)
      text = node.id == selected ? "clicked-tree hover-text" : "hover-text";
    else {
      if (isRight)
        text = node.id == rightActId ? "clicked-tree hover-text" : "hover-text";
      else text = node.id == actId ? "clicked-tree hover-text" : "hover-text";
    }

    if (!isSearching && !isMoving) text += ` ${rightClickClass}`;

    return text;
  };

  return (
    <>
      <div
        id={node.id}
        onContextMenu={handleContextMenu}
        onClick={handleClick}
        onDoubleClick={
          !isSearching
            ? isRight
              ? () => {
                  getNode(node.id, node.isSubarea);
                  changeRightActId(node.id);
                }
              : () => {}
            : !node.isInactive
            ? () => {
              changeRightActId(node.id);
              onFilteredNode(node.areaId, node.id);
              }
            : null
        }
        className={setHoverAndClickClasses()}
      >
        <TreeLabel>
          {!isMoving && isRight && enableMoving && (
            <input
              type="checkbox"
              value={node.id}
              checked={isChecked}
              onChange={(event) => {
                setIsChecked(event.target.checked);
                onChecked(
                  node.isInactive,
                  node.areaId,
                  node.parentGuid === null || "" ? "" : node.parentGuid,
                  node.id,
                  node.lawActGuid,
                  event.target.checked,
                  isSearching
                );
              }}
            />
          )}
          {!isRight ? (
            hasChildren > 0 ? (
              <FontAwesomeIcon icon={solid("folder")} />
            ) : (
              <FontAwesomeIcon icon={solid("file")} />
            )
          ) : (
            <></>
          )}
          <span className={isInactive ? "inactive-act" : ""}>
            {" "}
            {isRight ? (hierarchyMark || "") + " " + name : areaName}
          </span>
        </TreeLabel>
        {!isSearching ? (
          !isMoving &&
          (isRight ? (
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  createNode(node, false);
                }}
              >
                Додај акт
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  createNode(node, true);
                }}
              >
                Додај подгрупу
              </MenuItem>
              <MenuItem
                disabled={node.children.length > 0 ? true : false}
                onClick={() => {
                  setShowAlertDialog(true);
                }}
              >
                Прогласи акт за неактиван
              </MenuItem>
              {showAlertDialog && (
                <AlertDialog
                  handleYes={() => {
                    handleClose();
                    deactivateNode(node.id);
                    setShowAlertDialog(false);
                  }}
                  handleNo={() => {
                    handleClose();
                    setShowAlertDialog(false);
                  }}
                  show={showAlertDialog}
                  contentText={
                    "Да ли сте сигурни да желите да прогласите овај акт неактивним?"
                  }
                  handleYesText={"Прогласи акт за неактиван"}
                  className="btn btn-primary table-btn"
                >
                  Прогласи акт за неактиван
                </AlertDialog>
              )}
            </Menu>
          ) : (
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  createNode(node.id, node.children);
                }}
              >
                Додај
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteNode(node.id);
                }}
              >
                Обриши
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  changeNode(node);
                }}
              >
                Преименуј
              </MenuItem>
            </Menu>
          ))
        ) : (
          <></>
        )}
      </div>
      <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
        {showChildren && (
          <Tree
            treeData={children}
            getNode={getNode}
            deleteNode={deleteNode}
            changeNode={changeNode}
            createNode={createNode}
            deactivateNode={deactivateNode}
            onFilteredNode={onFilteredNode}
            isRight={isRight}
            expanded={expanded}
            isMoving={isMoving}
            selectForMoving={selectForMoving}
            isSearching={isSearching}
          />
        )}
      </ul>
    </>
  );
};

export default TreeNode;
