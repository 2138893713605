import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PENG;

class OrderClient {
    async getOrders(status, isBackground) {
        const params = {
            params: { status: status },
            isBackground: isBackground
        };
        try {
            var response = await api.get(baseurl + "/Order/GetOrders", params);
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }
    async pendingOrder(orderId, isBackground) {
        try {
            var response = await api.post(baseurl + "/Order/PendingOrder?orderId=" + orderId, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async acceptOrder(orderId, isBackground) {
        try {
            var response = await api.post(baseurl + "/Order/AcceptOrder?orderId=" + orderId, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async cancelOrder(orderId, isBackground) {
        try {
            var response = await api.post(baseurl + "/Order/CancelOrder?orderId=" + orderId, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }
}
export default OrderClient;