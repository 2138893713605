import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateCountry from "../../../components/create-country/createCountry.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CountryClient from "../../../api-services/country/country-client";
import CountryModel from "../../../models/Country";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const Country = () => {
  const { t } = useTranslation();
  const tableName = "AP_COUNTRY";

  const client = new CountryClient();

  const columns = [
    {
      Header: t("Name"),
      accessor: "countryNameLong",
      disableFilters: false,
    },
    {
      Header: t("ShortName"),
      accessor: "countryNameShort",
      disableFilters: false,
    },
    {
      Header: t("ExternalReference"),
      accessor: "extCode",
      disableFilters: false,
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
            <>
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        <ButtonWithComfirmation
          className="btn btn-danger table-btn"
          onClick={() => onDeleteClick(row.original.id)}
          contentText={"Држава ће бити трајно обрисана."}
          handleYesText={"Обриши"}
          hidden
        >
          <FontAwesomeIcon icon={solid("trash")} />
        </ButtonWithComfirmation>
            </>
        );
      },
    }
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new CountryModel());
  const [allNumCountries, setAllNumCountries] = useState([]);
  const [title, setTitle] = useState("");

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new CountryModel());
  };

  const getCountries = async () => {
    let response = await client.getCountry();
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") setAllNumCountries(handledResponse);
  };

  useEffect(() => {
    getCountries();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new CountryModel());
    showModal();
    setTitle(t("CreatingCountry"));
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingCountry"));
  };

  const onDeleteClick = async (id) => {
    var response = await client.deleteCountry(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      let allNumCountriesCopy = allNumCountries.filter((x) => x.id !== id);
      setAllNumCountries(allNumCountriesCopy);
    }
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    if (!isEdit) setAllNumCountries([...allNumCountries, childData]);
    else {
      let allNumCountriesCopy = [...allNumCountries];
      let index = allNumCountriesCopy.findIndex((x) => x.id === childData.id);
      allNumCountriesCopy[index] = childData;
      setAllNumCountries(allNumCountriesCopy);
    }
  };

  return (
    <>
      <div className="form-control">
        <div className="form-group">
          <h5 className="col-md-2">{t("Countries")}</h5>
          <button className="btn btn-primary col-md-1" onClick={onCreateClick}>
            {t("AddCountry")}
          </button>
        </div>

        <Table
          columns={columns}
          data={allNumCountries}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
        />
      </div>

      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateCountry
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default Country;
