//This function is used to recursively map over an array of objects, 
//applying a callback function to each element and returning
// a new array with the modified or transformed elements.
export const mapObjectRecursively = (oldArray, callback, newArray = []) => {
  if (oldArray.length <= 0) {
    return newArray;
  } else {
    let [item, ...theRest] = oldArray;
    if (item.children) {
      item = {
        ...item,
        children: mapObjectRecursively(item.children, callback),
      };
    }
    const interimArray = [...newArray, callback(item)];
    return mapObjectRecursively(theRest, callback, interimArray);
  }
};

//function that chack if every open parentheses has close parentheses
export const validateParentheses = (sentence) => {
  let stack = [];
  for (const char of sentence) {
    if (char === "(") stack.push("(");
    else if (char === ")") {
      if (stack.length === 0) return false;
      stack.pop();
    }
  }
  return stack.length === 0 ? true : false;
};

//function that chack if all question marks are paired
export const validateQuotationMarks = (sentence) => {
  let count = 0;
  for (const char of sentence) {
    if (char === '"') count++;
  }
  return count % 2 === 0 ? true : false;
};

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      // Resolve with the result (content)
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      // Reject with the error
      reject(error);
    };

    // Read the content of the file as text
    reader.readAsText(file);
  });
};

export const preventDefaultEvents = e => {
  e.stopPropagation();
  e.preventDefault();
}

export const scrollToElement = ref => {
  if (!ref.current) return
  ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
}

export const checkIfAnyLocked = (data) => {
  return data.some(obj => obj.locked === true);
};