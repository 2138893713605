class CountryModel {
    constructor(id = 0, countryNameLong = "",  countryNameShort = "", extCode  = "", active = true){
        this.id = id;
        this.extCode = extCode;
        this.countryNameLong = countryNameLong;
        this.countryNameShort = countryNameShort;
        this.active = active;
    }
}

export default CountryModel;