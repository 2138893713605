import API from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_ADMIN_PANEL;

class AuthorityClient {

  async Authority(isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createAuthority(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Authority/CreateAuthority", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAuthority(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Authority/UpdateAuthority", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorityAdvertisementAndActTypesByAuthorityId(authorityId, isBackground) {
    try {
      var response = await API.get(baseurl + "/AuthorityAdvertisementAndActType/GetAuthorityAdvertisementAndActTypesByAuthorityId?authorityId=" + authorityId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateAuthorityAdvertisementAndActType(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/AuthorityAdvertisementAndActType/UpdateAuthorityAdvertisementAndActType", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActiveAuthorities(isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority/getActiveAuthorities/", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthorities(isBackground) {
    try {
      var response = await API.get(baseurl + "/Authority", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      return err.response.data;
    }
  }
}

export default AuthorityClient;