import React, { useEffect, useState } from "react";
import AdvancedSearchQueryClient from "../../api-services/advanced-search-query/advanced-search-query-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";

const CreateAdvancedSearchQuery = ({ row, handleCallback, isCreate }) => {
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState({
    id: 0,
    name: "",
    weight: 0,
    isActive: false,
    query: "",
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(32767).required(),
    query: Joi.string().required(),
  };

  const client = new AdvancedSearchQueryClient();

  useEffect(() => {
    setAdvancedSearchQuery(row);
    setErrors({});
  }, [row, isCreate]);

  const CreateAdvancedSearchQuery = async () => {
    var response, handledResponse;
    const errorsResult = validateForm(advancedSearchQuery, schema, t);
    setErrors(errorsResult);
    var isJson = isJSON(advancedSearchQuery.query);

    if (!errorsResult  && isJson) {
      const data = {
        ...advancedSearchQuery,
        weight: parseInt(advancedSearchQuery.weight),
      };

      response = await client.CreateAdvancedSearchQuery(data);
      if (response !== "Error")
        handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
    else {
      setErrors({...errors, query: "Неисправан формат упита"})
    }
  };

  const UpdateAdvancedSearchQuery = async () => {
      var response, handledResponse;
      const errorsResult = validateForm(advancedSearchQuery, schema, t);
      setErrors(errorsResult);
      var isJson = isJSON(advancedSearchQuery.query);
  
      if (!errorsResult && isJson) {
        const data = {
          ...advancedSearchQuery,
          weight: parseInt(advancedSearchQuery.weight),
        };
  
        response = await client.UpdateAdvancedSearchQuery(advancedSearchQuery.id, data);
        if (response !== "Error")
          handledResponse = HandlingResponse(response);
        if (handledResponse.code == 408) {
          var result = serverValidation(handledResponse.data, t);
          setErrors(result);
        } else handleCallback(handledResponse);
      }
      else {
        setErrors({...errors, query: "Неисправан формат упита"})
      }
  };

  const isJSON = (str) => {
    try {
        return (JSON.parse(str));
    } catch (e) {
        return false;
    }
}

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, advancedSearchQuery, errors, schema, t);
    setAdvancedSearchQuery(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={advancedSearchQuery.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Упит<span className="required-asterisk">*</span></label>
          <textarea
            className="form-control"
            type="text"
            name="query"
            rows={5}
            value={advancedSearchQuery.query}
            onChange={(event) => handlePropertyChange(event.target)}
          ></textarea>
          {errors?.query && <div className="text-danger">{errors.query}</div>}
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={advancedSearchQuery.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div className="form-group">
          <input
            id="advancedSearchQueryActiveCB"
            type="checkbox"
            name="isActive"
            value={advancedSearchQuery.isActive}
            checked={advancedSearchQuery.isActive}
            onChange={(event) =>
              setAdvancedSearchQuery({
                ...advancedSearchQuery,
                isActive: event.target.checked,
              })
            }
          />
          <label htmlFor="advancedSearchQueryActiveCB">Активан</label>
          {errors?.isActive && (
            <div className="text-danger">{errors.isActive}</div>
          )}
        </div>
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateAdvancedSearchQuery()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateAdvancedSearchQuery()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateAdvancedSearchQuery;
