import api from "../../../api"
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_VASKE;

class AdvancedSearchQueryClient {
  async AdvancedSearchQuery(isBackground) {
    try {
      var response = await api.get(baseurl + "/AdvancedSearchQuery", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async CreateAdvancedSearchQuery(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/AdvancedSearchQuery", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateAdvancedSearchQuery(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/AdvancedSearchQuery/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

  }

  async DeleteAdvancedSearchQuery(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/AdvancedSearchQuery?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default AdvancedSearchQueryClient;