import Joi from "joi-browser";

class ActTypeModel {
    constructor(id = 0, defaultSort = "", description = "", isLaw = true, active = true){
        this.id = id;
        this.defaultSort = defaultSort;
        this.description = description;
        this.isLaw = isLaw;
        this.active = active;
    }
}

const ActTypeSchema = {
    defaultSort: Joi.string().min(5).max(250).required(),
    description: Joi.string().max(250).required()
}

class AuthorityModel {
    constructor(id = 0, authorityName = "",  description = "", sort = "", active = false, ){
        this.id = id;
        this.authorityName = authorityName;
        this.description = description;
        this.sort = sort;
        this.active = active;
    }
}
const authoritySchema = {
    authorityName: Joi.string().max(250).required(),
    description: Joi.string().max(250).required(),
    sort: Joi.string().min(1).max(250).required(),
}


export { ActTypeModel, AuthorityModel, authoritySchema, ActTypeSchema };