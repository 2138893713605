import React, { useState, useEffect, useCallback } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CourtKindClient from "../../../VASKE/api-services/coders/court-types/court-types-client";
import CourtClient from "../../../VASKE/api-services/coders/courts/courts-client";
import LegalAreaClient from "../../../VASKE/api-services/coders/legal-area/legal-area.client";
import LegalMatterClient from "../../../VASKE/api-services/coders/legal-matter/legal-matter-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import CourtPracticeClient from "../../../VASKE/api-services/cases/cases-client";
import Table from "../../../common/table/Table.component";

const TabCourtPractice = ({ actGuid, setActGuid }) => {
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "guid",
      accessor: "guid",
    },
    {
      Header: "Ближе одређење акта",
      accessor: "actCloserDesignation",
    },
    {
      Header: "Ознака и број акта",
      accessor: "actLabelAndNumber",
    },
  ];

  const { t } = useTranslation();

  const courtKindClient = new CourtKindClient();
  const courtClient = new CourtClient();
  const legalAreaClient = new LegalAreaClient();
  const legalMatterClient = new LegalMatterClient();
  const courtPracticeClient = new CourtPracticeClient();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldNamesForSort, setFieldNamesForSort] = useState([]);

  const [listOfCourtKinds, setListOfCourtKinds] = useState([]);
  const [listOfCourt, setCourtOfCourt] = useState([]);
  const [listOfLegalArea, setListOfLegalArea] = useState([]);
  const [listOfLegalMatter, setListOfLegalMatter] = useState([]);

  const [listOfCourtPractices, setListOfCourtPractices] = useState([]);

  const [searchData, setSearchData] = useState({
    courtKindId: null,
    courtId: null,
    legalAreaId: null,
    legalMatterId: null,
    actLabelAndNumber: "",
  });

  const [searched, setSearched] = useState(false); // true kada se izvrsi pretraga

  useEffect(() => {
    getCourtKinds();
    getLegalArea();
    getLegalMatter();
  }, []);

  const getCourtKinds = async () => {
    let response = await courtKindClient.CourtKind(true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name };
    });
    setListOfCourtKinds(result);
  };

  const getCourt = async (courtKindId) => {
    let response = await courtClient.GetAllCourtsByCourtKind(courtKindId, true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name };
    });
    setCourtOfCourt(result);
  };

  const getLegalArea = async () => {
    let response = await legalAreaClient.LegalArea(true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name };
    });
    setListOfLegalArea(result);
  };

  const getLegalMatter = async () => {
    let response = await legalMatterClient.LegalMatter(true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name };
    });
    setListOfLegalMatter(result);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    if(searched) {
        var response = await courtPracticeClient.filterCourtPracticesByInfo({
          ...searchData,
          pageSize: pageSize,
          pageNumber: pageNumber,
          fieldNamesForSort: sortBy,
        });
        let data = HandlingResponse(response);
        return data;
    }
    else return [];
  };

  const searchCourtPractices = async () => {
    setSearched(true);
    const response = await fetchDataForPage(
      pageNumber,
      pageSize,
      fieldNamesForSort
    );
    setListOfCourtPractices(response);
  };

  const handleTableRowClick = useCallback((response) => {
    setActGuid(response.guid);
  });
  
  const resetFilters = async () => {
    setSearchData({
      courtKindId: null,
      courtId: null,
      legalAreaId: null,
      legalMatterId: null,
      actLabelAndNumber: "",
    });
    setSearched(false);
    setActGuid("");
    setListOfCourtPractices([]);
  };

  return (
    <div>
      <div className="row">
        <div className="col-4">
          <label>Ознака и број акта</label>
          <input
            className="form-control"
            type="text"
            name="actLabelAndNumber"
            value={searchData.actLabelAndNumber}
            onChange={(event) =>
              setSearchData({
                ...searchData,
                actLabelAndNumber: event.target.value,
              })
            }
          />
        </div>
        <div className="col-4">
          <label>Правна област</label>
          <Select
            options={listOfLegalArea}
            onChange={(e) => {
              setSearchData({ ...searchData, legalAreaId: e ? e.value : null });
            }}
            isClearable
            value={listOfLegalArea.filter(
              (x) => x.value === searchData.legalAreaId
            )}
          />
        </div>
        <div className="col-4">
          <label>Правна материја</label>
          <Select
            options={listOfLegalMatter}
            onChange={(e) => {
              setSearchData({
                ...searchData,
                legalMatterId: e ? e.value : null,
              });
            }}
            isClearable
            value={listOfLegalMatter.filter(
              (x) => x.value === searchData.legalMatterId
            )}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-4">
          <label>Врста суда</label>
          <Select
            options={listOfCourtKinds}
            onChange={(e) => {
              getCourt(e ? e.value : null);
              e
                ? setSearchData({ ...searchData, courtKindId: e.value })
                : setSearchData({
                    ...searchData,
                    courtKindId: null,
                    courtId: null,
                  });
            }}
            isClearable
            value={listOfCourtKinds.filter(
              (x) => x.value === searchData.courtKindId
            )}
          />
        </div>
        <div className="col-4">
          <label>Суд</label>
          <Select
            options={listOfCourt}
            onChange={(e) => {
              setSearchData({ ...searchData, courtId: e ? e.value : null });
            }}
            isClearable
            isDisabled={searchData.courtKindId !== null ? false : true}
            value={listOfCourt.filter((x) => x.value === searchData.courtId)}
          />
        </div>
        <div className="col-4">
          
          <button
            type="button"
            className="btn btn-primary  pull-right"
            style={{ marginTop: "28px", marginLeft: "5px" }}
            onClick={resetFilters}
          >
            Поништи претрагу
          </button>
          <button
            type="button"
            onClick={searchCourtPractices}
            className="btn btn-primary pull-right"
            style={{ marginTop: "28px" }}
          >
            Претрага
          </button>
        </div>
      </div>
      <hr />
      {searched && (
        <Table
          columns={columns}
          data={listOfCourtPractices}
          rememberFilter={false}
          hiddenColumn={["id", "guid"]}
          fetchFunction={fetchDataForPage}
          serverSide={true}
          setData={setListOfCourtPractices}
          handleClickOnRow={handleTableRowClick}
        />
      )}
    </div>
  );
};

export default TabCourtPractice;
