import { ModalContainer } from "./SideModal.style.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useTheme from '../../hooks/useTheme';

const SideModal = ({ handleClose, show, children, title }) => {
  const { isOverflow } = useTheme();
  if (show) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  return (
    <ModalContainer className={isOverflow && "hide-modal-scroll"}>
      <div className={`Modal ${show ? "show" : ""}`}>
        <div className="modal-header">
          <h4>{title}</h4>
          <button
            type="button"
            className="close-dialog-btn"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={solid("times")} size="lg" />
          </button>
        </div>
        <div className="modal-body-scroll">{show && children}</div>
      </div>
      <div className={`overlay ${show ? "show" : ""}`} />
    </ModalContainer>
  );
};

export default SideModal;