import styled from "styled-components";

export const NewTableRowBtn = styled.button`
  font-size: 16px;
  background-color: #00c58f;
  color: #fff;
  border-radius: 4px;
  padding: 4px 10px;
  margin-left: 15px;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0ca275;
    transition: all 0.2s ease-in-out;
  }

  svg {
    margin-right: 5px;
  }
`;

export const NoPageSize = styled.div`
  select {
    display: none;
  }
`;
