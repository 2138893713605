import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PPP;

class AreaClient {
    async getFirstLevelAreasByPackageId(id, isBackground) {
        try {
            var response = await api.post(baseurl + "/Area/GetFirstLevelAreasByPackageId/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getAreaChildrenById(id, isBackground) {
        try {
            var response = await api.post(baseurl + "/Area/GetAreaChildrenById/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
    async createArea(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Area/CreateArea", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateArea(data, isBackground) {
        try {
            var response = await api.put(baseurl + "/Area/UpdateArea", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async activateArea(id, isBackground) {
        try {
            var response = await api.put(baseurl + "/Area/ActivateArea/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async deactivateArea(id, isBackground) {
        try {
            var response = await api.put(baseurl + "/Area/DeactivateArea/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async deleteArea(id, isBackground) {
        try {
            var response = await api.delete(baseurl + "/Area/DeleteAreaById/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getFirstLevelAreaForMove(areaId, isBackground) {
        try {
            var response = await api.get(baseurl + "/Area/GetFirstLevelAreaForMove?areaId=" + areaId, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async getSecondLevelAreaForMove(areaId, isBackground) {
        try {
            var response = await api.get(baseurl + "/Area/GetSecondLevelAreaForMove?areaId=" + areaId, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async moveArea(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Area/MoveArea/", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async orderAreas(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Area/OrderAreas", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async deleteArea(id, isBackground) {
        try {
            var response = await api.delete(baseurl + "/Area/DeleteAreaById/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default AreaClient;