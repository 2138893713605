import React, { useEffect, useState } from "react";
import AreaClient from "../../api-services/area/area-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

function EditArea({ data, hide, handleCallback }) {
  const [areaName, setAreaName] = useState("");

  const client = new AreaClient();

  useEffect(()=> {
    setAreaName(data.areaName);
  }, []);
  
  const changeAreaName = async () => {
    let response = await client.changeAreaName(data.id, areaName);
    let handledResponse = HandlingResponse(response);
    handledResponse != 'Error' && handleCallback();
    hide();
  };

  return (
    <>
      <div className="form-group">
        <label>Назив области</label>
        <input
          className="form-control"
          type="text"
          value={areaName}
          onChange={(e) => setAreaName(e.target.value)}
        />
      </div>
      <div>
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={changeAreaName}
        >
          Измени
        </button>
      </div>
    </>
  );
}

export default EditArea;
