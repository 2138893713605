import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PreviewAndUpdateAct from '../../components/preview-and-update-act/PreviewAndUpdateAct.component';
import { FormProvider } from '../../context/FormContext';
import LawActClient from '../../api-services/law-act/law-act-client';
import { PreviewLawActModel } from '../../models/PreviewLawAct';
import HandlingResponse from '../../../common/handling-response/handlingResponse';

const UpdateLawAct = () => {
    const [actId, setActId] = useSearchParams();
    const [fromWarnings] = useSearchParams();

    var lawActId = actId.get("actId");
    var isUpdatedFromWarnings = fromWarnings.get("fromWarnings");
    
    //podaci pribavljeni na Index stranici se cuvaju u state
    const { state } = useLocation();
    const navigate = useNavigate();

    var lawActClient = new LawActClient();
    const [lawActInfo, setLawActInfo] = useState(new PreviewLawActModel());    

    useEffect(() => {
      if(lawActId) {
        navigate("/registries/update?actId=" + lawActId + "&fromWarnings=" + isUpdatedFromWarnings, {replace: true});
        //dodat timeout jer iz nekog razloga ovde se ne prikazuje loader kad se udje ns str
        setTimeout(() => getLawAct(), 10);
        }
    }, []);

    const getLawAct = async () => {
      var response, res;
      if(!state) {
        response = await lawActClient.getLawAct(lawActId);
        res = HandlingResponse(response);
      }
        if (res != "Error") {
          setLawActInfo({
            lawActBasicDataModel: state ? state.data.lawActBasicDataDto : res.lawActBasicDataDto,
            officialGazetteModel: state ? state.data.officialGazetteDto: res.officialGazetteDto,
            lawActReferenceModel: state ? state.data.lawActReferenceDto: res.lawActReferenceDto,
            lawActOtherDataModel: state ? state.data.lawActOtherDataDto: res.lawActOtherDataDto,
            lawActChangesHistoryModel: state ? state.data.lawActChangesHistoryDto: res.lawActChangesHistoryDto,
            file: state ? state.data.file : res.file,
            files: state ? state.data.files : res.files,
            pictures: state ? state.data.pictures : res.pictures,
          })
        }
        else setLawActInfo(new PreviewLawActModel());
    };
    
  return (
    <div>
       <FormProvider>
        <PreviewAndUpdateAct lawActInfo={lawActInfo} actId={lawActId} getLawAct={getLawAct} />
      </FormProvider>
    </div>
  )
}

export default UpdateLawAct;
