import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateLegalSubMatter from "../../../components/legal-sub-matter-component/CreateLegalSubMatter.component";
import LegalSubmatterClient from "../../../api-services/coders/legal-sub-matter/legal-sub-matter-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const LegalSubMatter = () => {
  const tableName = 'SP_LEGAL_SUBMATTER';
  
  const columns = [
    {
      Header: "Правна материја",
      accessor: "legalMatter.name",
      disableFilters: false,
    },
    {
      Header: "Назив",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation  
            className="btn btn-danger table-btn" 
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Правна подматерија ће бити трајно обрисана."}
            handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  const client = new LegalSubmatterClient();
  
  const [legalSubmatter, setLegalSubmatter] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    name: "",
    weight: "",
    isActive: true
  });

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      isActive: true
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };
  
  const onDeleteClick = async (id) => {
    var response = await client.DeleteLegalSubmatter(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let legalSubmatterCopy = legalSubmatter.filter((x) => x.id !== id);
      setLegalSubmatter(legalSubmatterCopy);
    }
  };

  const LegalSubmatter = async () => {
    await client.LegalSubmatter().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setLegalSubmatter(handledResponse);
    });
  };

  useEffect(() => {
    LegalSubmatter();
  }, [])

  return (
    <>
      <h4>Правна подматерија</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нову правну подматерију
        </button>
      </div>
      <Table
        columns={columns}
        data={legalSubmatter}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={
          isCreate
            ? "Креирање нове правне подматерије"
            : "Измена правне подматерије"
        }
      >
        <CreateLegalSubMatter
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            LegalSubmatter();
          }}
        />
      </SideModal>
    </>
  );
};

export default LegalSubMatter;
