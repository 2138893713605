import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_LOZA;

class UserClient {
    async GetInternalUsers(isBackground) {
        try {
            var response = await api.get(baseurl + "/User/GetInternalUsers", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterInternalUsers(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/FilterInternalUsers", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterUserSubscribers(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/FilterUserSubscribers", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRoles(data, isBackground) {
        try {
            var response = await api.get(baseurl + "/Role", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async CreateUser(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/CreateUser", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async UpdateUser(userGuid, data, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/UpdateUser?userGuid=" + userGuid, data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async SetUserPassword(id, password, isBackground) {
        try {
            var response = await api.post(baseurl + "/User/SetUserPassword?id=" + id + "&password=" + password, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetUserSubscribers(isBackground) {
        try {
            var response = await api.get(baseurl + "/User/GetUserSubscribers", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async DeletePackage(id, isBackground) {
        try {
            var response = await api.delete(baseurl + "/UserPackage?id=" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async AcceptRejectPackage(id, isBackground) {
        try {
            var response = await api.post(baseurl + "/UserPackage/AcceptRejectUserPackage?id=" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetUserWithPackages(userGuid, isBackground) {
        try {
            var response = await api.get(baseurl + "/UserPackage/GetUserPackagesByUserGuid?userGuid=" + userGuid, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetAllPackages(isBackground) {
        try {
            var response = await api.get(baseurl + "/Package/GetAllActivePackages", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async AddUserPackages(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/UserPackage/AddUserPackages", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default UserClient;