import {  toast } from "react-toastify";
import Cookies from "js-cookie";

const options = {
    hideProgressBar: true,
    position: "top-center",
    autoClose: 1500,
    zIndex: 100000,
}

var language = Cookies.get('i18next');

export const Success = async (message) => {
    let jsonFile = await loadJson(language);
    if(jsonFile[message]) 
        toast.success(jsonFile[message], options);
    else toast.success("200", options);
}

export const Error = async (message) => {
    let jsonFile = await loadJson(language);
    if(jsonFile[message]) 
    toast.error(jsonFile[message], options);
    else toast.error('0', options);
    
}

export const Warning = (message) => {
    toast.warning(message, options);
}

export const Info = (message) => {
    toast.info(message, options);
}

const loadJson = (languageKey) => {
    return new Promise((res, rej) => {
      import(`../../../public/assets/locals/${languageKey}/translation.json`).then((data) => {
        res(data);
      });
    });
  };