import React, { useState, useEffect, useRef } from "react";
import StaticPagesClient from "../../api-services/static-pages/static-pages";
import LocationsClient from "../../api-services/codebooks/locations/locations";
import DatePicker from "react-datepicker";
import { sr } from "date-fns/locale";
import Joi from "joi-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn} from "../../../common/multiple-files/multipleFiles.style";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import SubmitFile from "../../../common/submit-file/SubmitFile";

const CreateStaticPage = ({ row, isEdit, handleCallback }) => {
  const client = new StaticPagesClient();
  const locationsClient = new LocationsClient();
  const { t } = useTranslation();

  const [staticPage, setStaticPage] = useState({
    displayText: "",
    description: "",
    internalMark: "",
    publishedDate: new Date().toLocaleString("en-US", {
      timeZone: "Europe/Belgrade",
    }),
    published: false,
    creationDate: null,
    locationId: undefined,
  });
  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [mainFile, setMainFile] = useState(null);
  const htmlInputRef = useRef(null);

  const [errors, setErrors] = useState({});

  const schema = {
    locationId: Joi.required(),
    displayText: Joi.string().required(),
    publishedDate: Joi.date().required(),
    mainFile: Joi.required(),
  };

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if(mainFile) {
      const obj = { name: "mainFile", value: mainFile };
      const result = handleSave(obj, staticPage, errors, schema, t);
      setErrors(result.errors);
    }
  }, [mainFile]);

  useEffect(() => {
    setStaticPage({
      id: row.id,
      displayText: row.displayText,
      description: row.description,
      internalMark: row.internalMark,
      htmlGuid: row.htmlGuid,
      publishedDate: row.publishedDate,
      published: row.published,
      active: row.active,
      creationDate: row.creationDate,
      locationId: row.locationId,
      locationName: row.locationName,
    });
    setErrors({});
    if (isEdit) {
      setMainFile(row.mainFile);
      setAttachments(row.attachments);
      setImages(row.images);
    } else setMainFile(undefined);
  }, [row]);

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, staticPage, errors, schema, t);
    setStaticPage(result.data);
    setErrors(result.errors);
  };

  const handleInputChange = (name, value) => {
    const formattedDate =
      name === "publishedDate" && value
        ? value.toLocaleString("en-US", { timeZone: "Europe/Belgrade" })
        : null;

    const obj = { name, value: formattedDate };
    const result = handleSave(obj, staticPage, errors, schema, t);
    setStaticPage(result.data);
    setErrors(result.errors);
  };

  const getLocations = async () => {
    let response = await locationsClient.GetLocations();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.id, label: x.name, name: "locationId" };
    });
    setLocations(result);
  };

  const createStaticPage = async () => {
    if (mainFile) {
      schema.mainFile = Joi.optional();
    }

    const errorResult = validateForm(
      { ...staticPage, mainFile: mainFile },
      schema,
      t
    );
    setErrors(errorResult);

    if (!errorResult) {
      var formData = new FormData();

      serializeObjectIntoFormData(formData, staticPage);

      if (mainFile?.guid) {
        formData.append("mainFile.guid", mainFile?.guid);
        formData.append("mainFile.name", mainFile?.name);
      } else formData.append("mainFile.file", mainFile);

      for (let i = 0; i < images.length; i++) {
        if (images[i].guid)
          formData.append("images[" + i + "].guid", images[i].guid);
        else formData.append("images[" + i + "].file", images[i]);

        formData.append("images[" + i + "].name", images[i].name);
      }
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].guid)
          formData.append("attachments[" + i + "].guid", attachments[i].guid);
        else formData.append("attachments[" + i + "].file", attachments[i]);
        formData.append("attachments[" + i + "].name", attachments[i].name);
      }

      if (isEdit) var response = await client.UpdateStaticPage(formData);
      else var response = await client.CreateStaticPage(formData);
      if (response !== "Error") {
        var handledResponse = HandlingResponse(response);
        if (handledResponse?.code == 408) {
          var result = serverValidation(handledResponse?.data, t);
          setErrors(result);
        } else handleCallback();
      }
    }
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  const DownloadFilesFromRepo = async () => {
    let response = await client.DownloadFilesFromRepo(staticPage.id);
    HandlingResponse(response);
  };

  const GetStaticPageHtml = async () => {
    var response = await client.GetStaticPageHtml(staticPage.id);
    return response;
  };

  return (
    <>
      <div className="col-md">
        <label>
          Локација<span className="required-asterisk">*</span>
        </label>
        <Select
          options={locations}
          onChange={(event) => handlePropertyChange(event)}
          value={locations?.filter((x) => x.value === staticPage.locationId)}
        />
        {errors?.locationId && (
          <div className="text-danger">{errors.locationId}</div>
        )}
        <label>
          Текст за приказ на Порталу<span className="required-asterisk">*</span>
        </label>
        <input
          className="form-control"
          type="text"
          name="displayText"
          value={staticPage.displayText}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.displayText && (
          <div className="text-danger">{errors.displayText}</div>
        )}
        <label>Опис</label>
        <textarea
          className="form-control"
          type="text"
          name="description"
          value={staticPage.description}
          onChange={(event) =>
            setStaticPage({ ...staticPage, description: event.target.value })
          }
        />
        <label>Датум објављивања</label>
        <DatePicker
          className="form-control"
          selected={
            staticPage.publishedDate ? new Date(staticPage.publishedDate) : null
          }
          onChange={(date) => handleInputChange("publishedDate", date)}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="dd.MM.yyyy. HH:mm"
          timeIntervals={1}
          locale={sr}
          timeCaption="време"
        />
        {errors?.publishedDate && (
          <div className="text-danger">{errors.publishedDate}</div>
        )}
        <input
          id="publishSP"
          type="checkbox"
          value={staticPage.published}
          checked={staticPage.published}
          onChange={(event) => {
            setStaticPage({ ...staticPage, published: event.target.checked });
          }}
        />
        <label htmlFor="publishSP">Објави на Порталу</label>
        <br />
        <div>
          {!mainFile && (
            <>
              <NewFileBtn onClick={handleUploadHtmlClick} type="button">
                <FontAwesomeIcon icon={solid("plus")} />
                Додај ХТМЛ фајл
              </NewFileBtn>
              или
            </>
          )}
          <input
            type="file"
            ref={htmlInputRef}
            onChange={(event) => {
              setMainFile(event.target.files[0]);
            }}
            hidden
            accept=".html, .htm"
            name="mainFile"
          />
          <SubmitFile
            mainFile={mainFile}
            setMainFile={setMainFile}
            photoFiles={images}
            setPhotoFiles={setImages}
            attachmentFiles={attachments}
            setAttachmentFiles={setAttachments}
            isEdit={isEdit && mainFile?.guid}
            downloadFilesFromRepo={DownloadFilesFromRepo}
            getHtml={GetStaticPageHtml}
            id={staticPage.id}
            pathToNavigate={window.location.pathname + window.location.search}
          ></SubmitFile>
          {errors?.mainFile && (
            <div className="text-danger">{errors.mainFile}</div>
          )}
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={() => createStaticPage()}
        >
          Сачувај
        </button>
      </div>
    </>
  );
};

export default CreateStaticPage;
