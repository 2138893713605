import React, { useState, useEffect } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import AreaClient from "../../../REGISTRIES/api-services/area/area-client";
import LawActClient from "../../../REGISTRIES/api-services/law-act/law-act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";

const TabRegistries = ({ actGuid, setActGuid }) => {
  const [listOfRegistries, setListOfRegistries] = useState([]);
  const [selectedReg, setSelectedReg] = useState();

  const [listOfAreas, setListOfAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState();

  const [listOfGroups, setListOfGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();

  const [listOfActs, setListOfActs] = useState([]);

  const areaClient = new AreaClient();
  const lawActClient = new LawActClient();
  const { t } = useTranslation();

  useEffect(() => {
    getRegistries();
  }, []);

  const getRegistries = async () => {
    let response = await areaClient.getRegistries(true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.areaName };
    });
    setListOfRegistries(result);
  };

  const getAreas = async (id) => {
    let response = await areaClient.getAreasByParentId(id);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.areaName };
    });
    setListOfAreas(result);
  };

  const getGroups = async (id) => {
    let response = await areaClient.getAreasByParentId(id);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.areaName };
    });
    setListOfGroups(result);
  };

  const getActs = async (id) => {
    let response = await lawActClient.getLawActsByAreaId(id);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.lawActGuid, label: item.hierarchyMark + " " + item.name };
    });
    setListOfActs(result);
  };

  const onChangeRegistries = (e) => {
    if (e) {
      getAreas(e.value);
      setSelectedReg(e.value);
    } else {
      resetSelection();
    }
  };

  const onChangeAreas = (e) => {
    if (e) {
      getGroups(e.value);
      setSelectedArea(e.value);
    } else {
      setSelectedArea("");
      setListOfGroups([]);
      setSelectedGroup("");
      setListOfActs([]);
      setActGuid("");
    }
  };

  const onChangeGroups = (e) => {
    if(e) {
      setSelectedGroup(e.value);
      getActs(e.value);
    }
    else {
      setSelectedGroup("");
      setListOfActs([]);
      setActGuid("");
    }
  }

  const resetSelection = () => {
    setSelectedReg('');
      setListOfAreas([]);
      setSelectedArea('');
      setListOfGroups([]);
      setSelectedGroup('');
      setListOfActs([]);
      setActGuid('');
  }

  return (
    <div>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={resetSelection}
        >
          Поништи одабир
        </button>
      </div>
      <label>Подрегистар</label>
      <Select
        options={listOfRegistries}
        onChange={(e) => onChangeRegistries(e)}
        value={listOfRegistries.filter((x) => x.value === selectedReg)}
        isClearable
      />
      <label>Област</label>
      <Select
        options={listOfAreas}
        onChange={(e) => onChangeAreas(e)}
        value={listOfAreas.filter((x) => x.value === selectedArea)}
        isClearable
      />
      <label>Група</label>
      <Select
        options={listOfGroups}
        onChange={(e) => onChangeGroups(e)}
        value={listOfGroups.filter((x) => x.value === selectedGroup)}
        isClearable
      />
      <label>Акт</label>
      <Select
        options={listOfActs}
        onChange={(e) => setActGuid(e ? e.value : "")}
        value={listOfActs.filter((x) => x.value === actGuid)}
        isClearable
      />
    </div>
  );
};

export default TabRegistries;
