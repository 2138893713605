import React, { useState, useEffect } from "react";
import DefaultInterestClient from "../../../api-services/codebooks/default-interests/default-interests";
import Table, { getPageNumber, getPageSizeNumber, getSortBy } from "../../../../common/table/Table.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import Moment from "moment";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateDefaultInterest from "../../../components/create-default-interest/CreateDefaultInterest.component";
import SearchDefaultInterests from "../../../components/search-default-interests/SearchDefaultInterests.component";
import { formatDateForUpdate } from "../../../../common/custom-date-picker/CustomDatePicker.component";

const DefaultInterest = () => {
  const tableName = "FP_DEFAULT_INTEREST";
  const rememberFilter= true;
  const columns = [
    {
      Header: "Вредност",
      accessor: "value",
    },
    {
      Header: "Датум од",
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return row.original.dateFrom
          ? Moment(row.original.dateFrom).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      Header: "Датум до",
      accessor: "dateTo",
      Cell: ({ row }) => {
        return row.original.dateTo
          ? Moment(row.original.dateTo).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
            <>
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original.id)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
          <ButtonWithComfirmation
          className="btn  btn-danger table-btn"
          onClick={() => onDeleteClick(row.original.id)}
          contentText={"Затезна камата ће бити трајно обрисана."}
          handleYesText={"Обриши"}
        >
          <FontAwesomeIcon icon={solid("trash")} />
        </ButtonWithComfirmation>
            </>
        );
      },
    }
  ];

  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [title, setTitle] = useState("");
  const [rowData, setRowData] = useState({
    value: "",
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [lastDateTo, setLastDateTo] = useState(null);
  const [filterData, setFilterData] = useState({
    minDateFrom: null,
    maxDateTo: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  useEffect(() => {
    reset && setReset(false);
  }, [tableData])

  const client = new DefaultInterestClient();

  
  const GetLastDateToInterestRateHistory = async () => {
    let response = await client.GetLastDateToInterestRateHistory();
    let result = HandlingResponse(response);
    setLastDateTo(result);
  };

  useEffect(() => {
    GetLastDateToInterestRateHistory()
  }, [])

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      value: "",
      dateFrom: lastDateTo,
      dateTo: null,
    });
    setTitle("Креирање затезне камате");
    showModal();
  };

  const onEditClick = async (id) => {
    setIsEdit(true);
    var response = await client.GetInterestRateHistoryById(id);
    let handledResponse = HandlingResponse(response);
    setRowData({
      ...handledResponse,
      dateFrom: handledResponse.dateFrom,
      dateTo: handledResponse.dateTo,
    });
    showModal();
    setTitle("Измена затезне камате");
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteInterestRateHistory(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      let interestsCopy = tableData.dataList.filter((x) => x.id != id);
      setTableData({
        ...tableData,
        dataList: interestsCopy,
        count: tableData.count--,
      });
    }
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.FilterInterestRateHistory(
      {
        ...filterData,
        pageSize: pageSize,
        pageNumber: pageNumber,
        fieldNamesForSort: sortBy,
      },
      true
    );
    let data = HandlingResponse(response);
    return data;
  };

  const resetFilters = async () => {
    setReset(true);
    setFilterData({
      minDateFrom: null,
      maxDateTo: null,
    });

    var response = await client.FilterInterestRateHistory(
      {
        minDateFrom: null,
        maxDateTo: null,
      },
      true
    );
    let data = HandlingResponse(response);
    setTableData(data);
  };

  const searchData = async (e) => {
    e?.preventDefault();
    const response = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(response);
  };

  const handleAddingDataToTable = () => {
    hideModal();
    GetLastDateToInterestRateHistory();
    searchData();
  }

  return (
    <>
    <h5 className="col-md-2" style={{marginBottom: "25px"}}>Затезне камате</h5>
      <div className="form-group"></div>
      <SearchDefaultInterests
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <hr />
      <div className="text-right">
        <button
          className="btn btn-primary"
          onClick={onCreateClick}
          style={{ marginBottom: "20px" }}
        >
          Додај затезну камату
        </button>
      </div>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={rememberFilter}
        tableName={tableName}
        hiddenColumn={["id"]}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setTableData}
        resetFilters={reset}
      />
      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateDefaultInterest row={rowData} isEdit={isEdit} handleAddingDataToTable={handleAddingDataToTable}/>
      </SideModal>
    </>
  );
};

export default DefaultInterest;
