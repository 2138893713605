import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";

const PreviewSmallAdd = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const client = new SmallAddsClient();

    const [rowData, setRowData] = useState({});

    useEffect(() => {
      setRowData(state.data)
    }, [state])

    const Cancel = () => {
        navigate("/mo")
    }

    const GenerateConfirmationDocument = async () => {
      await client.GenerateConfirmationDocument(rowData.adId);
    }

  return (
    <>
      <label style={{ marginBottom: "15px", color: "#00c58f" }}>
        Подаци о документу
      </label>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Врста документа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.documentKind}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Подврста документа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.documentSubkind}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Назив документа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.nazivDokumenta}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Број документа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.documentNumber}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Издавалац документа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.documentIssuer}
              readOnly
            />
          </div>
        </div>
      </div>
      <label
        style={{ marginBottom: "15px", marginTop: "20px", color: "#00c58f" }}
      >
        Подаци о кориснику
      </label>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Корисник</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryName}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>ЈМБГ корисника</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryJMBG}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Адреса корисника</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryAddress}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Место корисника</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryCity}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Поштански број</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryZipCode}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Општина корисника</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryMunicipality}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Држава корисника</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.beneficiaryState}
              readOnly
            />
          </div>
        </div>
      </div>
      <label
        style={{ marginBottom: "15px", marginTop: "20px", color: "#00c58f" }}
      >
        Подаци о решењу
      </label>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Број решења</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.decisionNumber}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Доносилац решења</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.decisionIssuer}
              readOnly
            />
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
            <label>Датум решења</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={rowData.decisionDate}
              readOnly
            />
          </div>
        </div>
      </div>
      <label
        style={{ marginBottom: "15px", marginTop: "20px", color: "#00c58f" }}
      >
        Подаци о гласилу
      </label>
      <div className="col-md-6">
      <div className="form-group">
        <label>Број гласила</label>
        <input
          className="form-control"
          type="text"
          name="displayText"
          value={rowData.ogNumYear}
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Датум гласила</label>
        <input
          className="form-control"
          type="text"
          name="displayText"
          value={rowData.ogPublishedDate}
          readOnly
        />
      </div>
      </div>
      <br />
      <div className="form-group">
        <label>Текст малог огласа</label>
        <textarea
          className="form-control"
          type="text"
          name="displayText"
          value={rowData.adPublishedText}
          readOnly
        />
      </div>
      <br />
      <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => GenerateConfirmationDocument()}
            >
              Потврда
            </button>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                Cancel();
              }}
            >
              Откажи
            </button>
          </div>
    </>
  );
};

export default PreviewSmallAdd;
