import api from "../../../api";
import { env } from "../../../env"

const baseurl = env.REACT_APP_API_URL_FP;

class StaticPagesClient {
    async FilterStaticPages(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/StaticPage/FilterStaticPages", data, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async CreateStaticPage(data, isBackground) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        try {
            var response = await api.post(baseurl + "/StaticPage/CreateStaticPage", data, {
                headers: headers
            }, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async UpdateStaticPage(data, isBackground) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        try {
            var response = await api.put(baseurl + "/StaticPage/UpdateStaticPage", data, {
                headers: headers,
                isBackground: isBackground
            });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async DeleteStaticPage(id, isBackground) {
        try {
            var response = await api.delete(baseurl + "/StaticPage/DeleteStaticPage/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async GetStaticPageById(id, isBackground) {
        try {
            var response = await api.get(baseurl + "/StaticPage/" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async DownloadFilesFromRepo(id, isBackground) {
        try {
            var response = await api.get(baseurl + "/StaticPage/DownloadFilesFromRepo/" + id, { responseType: 'blob' })
                .then(({ data }) => {
                    const blob = new Blob([data], { type: 'application/zip' });
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    link.href = url;
                    link.click();
                });
        }
        catch (err) {
            if (err.response.status === 404 && err.response.config.responseType === 'blob') {
                let responseObj = await err.response.data.text();
                return JSON.parse(responseObj);
              }
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async DownloadFile(fileGuid, fileName, isBackground) {
        try {
            var response = await api.get(baseurl + "/StaticPage/DownloadFile/" + fileGuid, { responseType: 'blob' }
            ).then(({ data }) => {
                const blob = new Blob([data]);
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = fileName;
                link.click();
            });
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async GetStaticPageHtml(id, isBackground) {
        try {
            var response = await api.get(baseurl + "/StaticPage/GetStaticPageHtml?id=" + id, { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }

    async UpdateStaticPageFile(data, isBackground) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        try {
            var response = await api.put(baseurl + "/StaticPage/UpdateStaticPageFile", data, {
                headers: headers,
                isBackground: isBackground
            });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
        }
    }
}

export default StaticPagesClient;