import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/localization/localization";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import API from './api';
import App from './App';
import UserService from './utils/authentification/keycloak';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { AppUrlProvider } from './contexts/appUrlProvider';
import { ThemeProvider } from './contexts/themeProvider';
import { RenderDifferentComponentProvider } from './PPP/contexts/RenderDifferentComponentContext';
import { LockingAreaProvider } from './REGISTRIES/context/LockingAreaContext';
import { LockingAreaRegulationsProvider } from './REGULATIONS/context/LockingAreaContext';

API.interceptors.request.use(
  (config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  },
  function (error) {

    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {

    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () => root.render(
  <BrowserRouter>
    <AppUrlProvider>
      <ThemeProvider>
        <LockingAreaProvider>
          <LockingAreaRegulationsProvider>
            <RenderDifferentComponentProvider>
              <ToastContainer limit={1} style={{ zIndex: "99999" }} />
              <Routes>
                <Route path="/*" element={<App />}></Route>
              </Routes>
            </RenderDifferentComponentProvider>
          </LockingAreaRegulationsProvider>
        </LockingAreaProvider>
      </ThemeProvider>
    </AppUrlProvider>
  </BrowserRouter>
);


UserService.initKeycloak(renderApp);