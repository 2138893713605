import React, {useState, useCallback} from "react";
import AuthorityArea from "../../../components/authority-area/authorityArea.component";
import AuthorityGroup from "../../../components/authority-group/authorityGroup.component";
import AuthorityKind from "../../../components/authority-kind/authorityKind.component";
import Authority from "../../../components/authority/authority.component";

const AuthorityPage = () => {
  const [authorityKindsData, setAuthorityKindsData] = useState([]);
  const [authorityAreasData, setAuthorityAreasData] = useState([]);
  const [authorityData, setAuthorityData] = useState([]);

  const [authorityInfo, setAuthorityInfo] = useState({});

  const [showAuthKinds, setShowAuthKinds] = useState(false);
  const [showAuthAreas, setShowAuthAreas] = useState(false);
  const [showAuthorities, setShowAuthorities] = useState(false);


  const handleChangeAuthorityGroup = useCallback((response, groupId, groupName) => {
    if(response) {
      setAuthorityKindsData(response);
      setShowAuthKinds(true);
      setShowAuthAreas(false);
      setShowAuthorities(false);
    }
    setAuthorityInfo({groupId: groupId, groupName: groupName});
    setAuthorityAreasData([]);
    setAuthorityData([]);
}, [authorityKindsData]);

const handleChangeAuthorityKind = useCallback((response, kindId, kindName) => {
  if(response) {
    setAuthorityAreasData(response);
    setShowAuthAreas(true);
    setShowAuthorities(false);
  }
  setAuthorityInfo({...authorityInfo, kindId: kindId, kindName: kindName});
  setAuthorityData([]);
}, [authorityAreasData]);

const handleChangeAuthorityAreas = useCallback((response, areaId, areaName) => {
  if(response) {
    setAuthorityData(response);
    setShowAuthorities(true);
  }
  setAuthorityInfo({...authorityInfo, areaId: areaId, areaName: areaName})
}, [authorityData]);

  return (
    <>
      <h4>Доносиоци</h4>
      <hr />
      <div>
        <AuthorityGroup handleChange = {handleChangeAuthorityGroup} />
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          {showAuthKinds ? <AuthorityKind authorityKindsData = {authorityKindsData} handleChange = {handleChangeAuthorityKind} authorityInfo={authorityInfo} /> : <></>}
        </div>
        <div className="col-6">
          {showAuthAreas? <AuthorityArea authorityAreasData = {authorityAreasData} handleChange = {handleChangeAuthorityAreas} authorityInfo={authorityInfo} /> : <></>}
          <br />
          {showAuthorities? <Authority authorityData = {authorityData} authorityInfo={authorityInfo} /> : <></>}
        </div>
      </div>
    </>
  );
};

export default AuthorityPage;
