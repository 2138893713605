const publishedEnum = {
    All: 2,
    Published: 1,
    Unpublished: 0
  }

class DataFilterModel {
    constructor(id = 0, fileName="", actLabelNumber="", typeId=0, authorityId=0, actCloserDesignation="", startCreationDate=null, endCreationDate=null, published=publishedEnum.All) {
        this.id = id;
        this.fileName = fileName;
        this.actLabelNumber = actLabelNumber;
        this.typeId = typeId;
        this.authorityId = authorityId;
        this.actCloserDesignation = actCloserDesignation;
        this.startCreationDate = startCreationDate;
        this.endCreationDate = endCreationDate;
        this.published = published;
    }
}

export {DataFilterModel, publishedEnum}