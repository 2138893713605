import React, { useEffect, useRef, useState } from "react";
import { ProductModel, ProductSchema, productOperationTypes } from "../../models/ProductModel";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ProductClient from "../../api-services/product/product-client";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DeleteButton,
  NewFileBtn,
} from "../../../common/multiple-files/multipleFiles.style";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { useTranslation } from "react-i18next";
import { handleSave, validateForm } from "../../../common/validation/validation";

const CreateOrEditProduct = ({ selectedOperationType, selectedProduct, hide, handleRefreshTree }) => {
  const { t } = useTranslation();
  const productClient = new ProductClient();
  const [product, setProduct] = useState(new ProductModel());
  const [noteStyles, setNoteStyles] = useState([]);
  const [nameStyles, setNameStyles] = useState([]);

  const [errors, setErrors] = useState({});

  const [pdfFile, setPdfFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    switch (selectedOperationType) {
      case productOperationTypes.addElement:
        setProduct({ ...product, parentId: selectedProduct.parentId });
        break;
      case productOperationTypes.addSubelement:
        setProduct({ ...product, parentId: selectedProduct.id });
        break;
      case productOperationTypes.editElement:
        getProduct();
        break;
      default:
        break;
    }
    getStyles();
  }, []);

  const getProduct = async () => {
    var response = await productClient.getProduct(selectedProduct.id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      setProduct(handledResponse);
      handledResponse.pdf &&
        setPdfFile({
          name: handledResponse.pdf,
          file: handledResponse.pdfFile,
        });
    }
  };

  const getStyles = async () => {
    var response = await productClient.getStyles(selectedProduct.id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      let noteStylesArray = handledResponse.map((x) => {
        return { value: x.id, label: x.name, name: "noteStyleId" };
      });
      let nameStylesArray = handledResponse.map((x) => {
        return { value: x.id, label: x.name, name: "nameStyleId" };
      });
      setNoteStyles(noteStylesArray);
      setNameStyles(nameStylesArray);
    }
  };
  const createEditProduct = async (event) => {
    event.preventDefault();

    const errorsResult = validateForm(product, ProductSchema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      var formData = new FormData();

      if (pdfFile) {
        pdfFile.size && formData.append("pdfFile.file", pdfFile);
      }

      serializeObjectIntoFormData(formData, product);

      if (selectedOperationType === productOperationTypes.editElement)
        var response = await productClient.updateProduct(formData);
      else response = await productClient.createProduct(formData);
      let handledResponse = HandlingResponse(response);
      if(handledResponse != 'Error') {
        handleRefreshTree();
        hide();
      }
    }
  };


  const handleInputChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setProduct((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeWithValidation = (e) => {
    const obj = { name: e.target.name, value: e.target.value };
    const result = handleSave(obj, product, errors, ProductSchema, t);
    setProduct(result.data);
    setErrors(result.errors);
  }

  const handleSelectListChange = (e) => {
    const { name, value } = e;
    setProduct((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUploadFileClick = () => {
    fileInputRef.current?.click();
  };

  const removePdfFile = () => {
    setPdfFile(undefined);
    setProduct({ ...product, pdf: null })
    fileInputRef.current.value = null;
  };

  return (
    <form onSubmit={createEditProduct}>
      <div className="row">
        <div className="col-8">
          <label>Назив</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChangeWithValidation}
          />
          {errors?.name && <div className="text-danger">{errors?.name}</div>}
        </div>
        <div className="col-4">
          <label>Стил назива</label>
          <Select
            id="nameStyleId"
            options={nameStyles}
            onChange={(e) => {
              if (!e) e = { value: null, name: "nameStyleId" };
              handleSelectListChange(e);
            }}
            value={nameStyles.filter((x) => x.value === product?.nameStyleId)}
            isClearable
          />
        </div>

        <div className="col-12">
          <label>Оригинални назив</label>
          <input
            className="form-control"
            type="text"
            name="originalName"
            value={product.originalName}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-8">
          <label>Белешка</label>
          <textarea
            className="form-control"
            type="text"
            name="note"
            value={product.note}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-4">
          <label>Стил белешке</label>
          <Select
            id="noteStyleId"
            options={noteStyles}
            onChange={(e) => {
              if (!e) e = { value: null, name: "noteStyleId" };
              handleSelectListChange(e);
            }}
            value={noteStyles.filter((x) => x.value === product?.noteStyleId)}
            isClearable
          />
        </div>

        <div className="col-4">
          <label>Ранг</label>
          <input
            className="form-control"
            type="number"
            name="rang"
            value={product.rang}
            onChange={handleInputChangeWithValidation}
          />
          {errors?.rang && <div className="text-danger">{errors?.rang}</div>}
        </div>
        <div className="col-4">
          <label>Цена</label>
          <input
            className="form-control"
            type="number"
            name="price"
            value={product.price}
            onChange={handleInputChangeWithValidation}
          />
          {errors?.price && <div className="text-danger">{errors?.price}</div>}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <input
            id="showAsNewest"
            name="showAsNewest"
            type="checkbox"
            value={product.showAsNewest}
            checked={product.showAsNewest}
            onChange={handleInputChange}
          />
          <label htmlFor="showAsNewest">Најновији</label>
        </div>
        <div className="col-3">
          <input
            id="active"
            name="active"
            type="checkbox"
            value={product.active}
            checked={product.active}
            onChange={handleInputChange}
          />
          <label htmlFor="active">Активан</label>
        </div>
        <div className="col-3">
          <input
            id="changed"
            name="changed"
            type="checkbox"
            value={product.changed}
            checked={product.changed}
            onChange={handleInputChange}
          />
          <label htmlFor="changed">Измењен</label>
        </div>
        <div className="col-3">
          <input
            id="outdated"
            name="outdated"
            type="checkbox"
            value={product.outdated}
            checked={product.outdated}
            onChange={handleInputChange}
          />
          <label htmlFor="outdated">Застарео</label>
        </div>
      </div>
      <br />
      <div>
        <label>ПДФ фајл &nbsp;</label>
        {pdfFile ? (
          <span>
            <span>{pdfFile.name}</span>
            <ButtonWithComfirmation
              onClick={removePdfFile}
              handleYesText={"Обриши фајл"}
              contentText={
                "Уколико сачувате измене, фајл ће бити трајно обрисан"
              }
              className="deleteButton"
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </span>
        ) : (
          <NewFileBtn onClick={handleUploadFileClick} type="button">
            <FontAwesomeIcon icon={solid("plus")} />
            Додај ПДФ фајл
          </NewFileBtn>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={(event) => {
            setPdfFile(event.target.files[0]);
          }}
          hidden
          accept=".pdf"
          name="pdfFile"
        />
      </div>
      <button className="pull-right btn btn-primary" type="submit">
        Сачувај
      </button>
    </form>
  );
};

export default CreateOrEditProduct;
