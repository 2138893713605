import React, { useEffect, useState } from "react";
import CourtKindClient from "../../api-services/coders/court-types/court-types-client";
import CourtClient from "../../api-services/coders/courts/courts-client";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import Moment from "moment";

const CreateSudovi = ({ row, handleCallback, isCreate }) => {
  var { t } = useTranslation();

  const [courtKindList, setCourtKindList] = useState([]);
  const [court, setCourt] = useState({
    id: 0,
    name: "",
    fkCourtKind: "",
    weight: 0,
    lastModifyDate: null,
    isActual: true,
    isActive: true,
    userFullName: ""
  });

  const [errors, setErrors] = useState({});

  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    fkCourtKind: Joi.required(),
  };

  const courtKindClient = new CourtKindClient();
  const client = new CourtClient();

  useEffect(() => {
    CourtKind();
    setErrors({});
  }, []);

  const CourtKind = async () => {
    await courtKindClient.CourtKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      var data =
        handledResponse !== "Error"
          ? handledResponse.map((item) => {
              return { value: item.id, label: item.name, name: "fkCourtKind" };
            })
          : [];
      setCourtKindList(data);
    });
  };

  useEffect(() => {
    setCourt(row);
  }, [row, isCreate]);

  const CreateCourt = async () => {
    const errorsResult = validateForm(court, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...court,
        lastModifyDate: new Date(),
      };
      const result = validateForm(data, schema, t);
      var response = await client.CreateCourt(data);
      serverValidateAction(response);
      setErrors(result);
    }
  };

  const UpdateCourt = async () => {
    const errorsResult = validateForm(court, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...court,
        lastModifyDate: new Date(),
      };
      const result = validateForm(data, schema, t);
      var response = await client.UpdateCourt(court.id, data);
      serverValidateAction(response);
      setErrors(result);
    }
  };

  const serverValidateAction = (response) => {
    var handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, court, errors, schema, t);
    setCourt(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            name="name"
            value={court.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Врста суда<span className="required-asterisk">*</span></label>
          <Select
            options={courtKindList}
            onChange={(event) => handlePropertyChange(event)}
            value={courtKindList?.filter((x) => x.value === court.fkCourtKind)}
          />
          {errors?.fkCourtKind && (
            <div className="text-danger">{errors.fkCourtKind}</div>
          )}
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={court.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div className="form-group">
        <input
          id="actualCourtCB"
            type="checkbox"
            checked={court.isActual}
            value={court.isActual}
            onChange={(event) =>
              setCourt({ ...court, isActual: event.target.checked })
            }
          />
          <label style={{marginRight: "15px"}} htmlFor="actualCourtCB">Актуелан</label>
          <input
                id="activeCourtCB"
                    type="checkbox"
                    value={court.isActive}
                    checked={court.isActive}
                    onChange={(event) => {
                        setCourt({ ...court, isActive: event.target.checked });
                    }}
          />
          <label htmlFor="activeCourtCB">Активан</label>
        </div>

        {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{court.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{court.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>{Moment(court.lastModifyDate).format("DD.MM.YYYY.")}</div>
            </div>
          </>
        ) : (
          ""
        )}
        
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateCourt()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateCourt()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateSudovi;
