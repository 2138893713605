import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PENG;

class ProductClient {

  async getAllProducts(isBackground) {
    const params = {
      isBackground
    }
    try {
      const response = await api.get(baseurl + "/Product/GetAllProductsWithChildren", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async getProduct(id, isBackground) {
    const params = {
      params: { productId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/Product/GetProduct", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createProduct(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.post(baseurl + "/Product/CreateProduct", data, { headers: headers },{ isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateProduct(data, isBackground) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      var response = await api.put(baseurl + "/Product/UpdateProduct", data, {
        headers: headers
      },
        { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteProduct(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Product/DeleteProduct?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getStyles(isBackground) {
    try {
      var response = await api.get(baseurl + "/Product/GetStyles", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getSubscriptionsByUser(id, isBackground) {
    const params = {
      params: { userId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/Product/GetProductsWithChildrenByUserId", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}
export default ProductClient;