import {MainContainer} from "./Layout.style"
import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";

const SPLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();

  useEffect(() => {
    setAppName("Судска пракса");
    setAppPath("/sp");
  }, []);
  return (
    <>
      <MainContainer>
          <Outlet />
      </MainContainer>
    </>
  );
};

export default SPLayout;
