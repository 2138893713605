import api from "../../../../api";
import { env } from "../../../../env";

const baseurl = env.REACT_APP_API_URL_FP;

class CategoriesClient {

    async GetCategories(isBackground) {
        try{
            var response = await api.get(baseurl + "/Category/GetCategorys", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async UpdateCategory(data, isBackground) {
        try{
            var response = await api.put(baseurl + "/Category/UpdateCategory", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default CategoriesClient;