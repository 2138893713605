import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_LOZA;

class ApproveClient {
    async GetAllRequests() {
        try {
            var response = await api.get(baseurl + "/Request/GetAllRequests");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterUnprocessedRequests(data) {
        try {
            var response = await api.post(baseurl + "/Request/FilterUnprocessedRequests", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForCreationUsers() {
        try {
            var response = await api.get(baseurl + "/Request/GetRequestsForCreationUsers");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForGroupCreationUsers(data) {
        try {
            var response = await api.post(baseurl + "/Request/GetRequestsForGroupCreationUsers", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForAssignUserPackage() {
        try {
            var response = await api.get(baseurl + "/Request/GetRequestsForAssignUserPackage");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForExtendingUserPackage() {
        try {
            var response = await api.get(baseurl + "/Request/GetRequestsForExtendingUserPackage");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForGroupExtendingUserPackage(data) {
        try {
            var response = await api.post(baseurl + "/Request/GetRequestsForGroupExtendingUserPackage", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetRequestsForCancellationUserPackage() {
        try {
            var response = await api.get(baseurl + "/Request/GetRequestsForCancellationUserPackage");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

    }

    async GetRequestsForGroupCancellationUserPackage(data) {
        try {
            var response = await api.post(baseurl + "/Request/GetRequestsForGroupCancellationUserPackage", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ProcessRequest(data) {
        try {
            var response = await api.post(baseurl + "/Request/ProcessRequest", data);
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterAllRequests(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/Request/FilterAllRequests", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterApprovedRequestSubscriptions(data) {
        try {
            var response = await api.post(baseurl + "/Request/FilterApprovedRequestSubscriptions", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ExportToExcel(data) {
        try {
            var response = await api.post(baseurl + "/Request/ExportRequestSubscriptionsInExcel", data);
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ProcessGroupCreation(data) {
        try {
            var response = await api.post(baseurl + "/Request/ProcessGroupCreationRequest", data);
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ProcessGroupExtending(data) {
        try {
            var response = await api.post(baseurl + "/Request/ProcessGroupExtendingRequest", data);
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ProcessGroupCancellation(data) {
        try {
            var response = await api.post(baseurl + "/Request/ProcessGroupCancellationRequest", data);
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetAllUnprocessedRequests() {
        try {
            var response = await api.get(baseurl + "/Request/GetAllUnprocessedRequests");
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default ApproveClient;