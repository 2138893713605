import Joi from "joi-browser";
import { useEffect, useState } from "react";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import CategoriesClient from "../../api-services/codebooks/categories/categories";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const EditCategory = ({ row, handleCallback }) => {
  const { t } = useTranslation();
  const client = new CategoriesClient();
  const [category, setCategory] = useState({
    weight: 0,
    published: true,
  });
  const [errors, setErrors] = useState({});

  const schema = {
    weight: Joi.number().min(0).max(2147483647),
  };

  const updateCategory = async () => {
    const errorResult = validateForm(category, schema, t);
    setErrors(errorResult);

    if (!errorResult) {
      var response = await client.UpdateCategory(category);
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, category, errors, schema, t);
    setCategory(result.data);
    setErrors(result.errors);
  };

  useEffect(() => {
    setCategory(row);
    setErrors({});
  }, [row]);

  return (
    <>
      <label>Тежина<span className="required-asterisk">*</span></label>
      <input
        className="form-control"
        type="number"
        name="weight"
        value={category.weight}
        onChange={(event) => handlePropertyChange(event.target)}
        style={{ marginBottom: "10px" }}
      />
      {errors?.weight && <div className="text-danger">{errors.weight}</div>}

      <input
        id="publishedCategoryCB"
        type="checkbox"
        value={category.published}
        checked={category.published}
        onChange={(event) => {
          setCategory({ ...category, published: event.target.checked });
        }}
      />
      <label htmlFor="publishedCategoryCB">Објављена</label>

      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={() => updateCategory()}
        >
          Сачувај
        </button>
      </div>
    </>
  );
};

export default EditCategory;
