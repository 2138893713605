import { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import IssueTypeClient from "../../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import NumberClient from "../../../api-services/number/number";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DownloadButton,
  DeleteButton,
  NewFileBtn,
} from "../../../../common/multiple-files/multipleFiles.style";
import { serializeObjectIntoFormData } from "../../../../utils/serialize-form-data/serializeFormData";
import { formatDateForUpdate } from "../../../../common/custom-date-picker/CustomDatePicker.component";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const EditNumbers = ({ isCurrent, filterName, activeTab }) => {
  const [editNumbersData, setEditNumbersData] = useState({
    issueTypeGuid: "",
    year: null,
    num: "",
  });

  const [issueTypeList, setIssueTypeList] = useState([]);
  const [numList, setNumList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [showNumAndSearch, setShowNumAndSearch] = useState(false);
  const [publishDate, setPublishDate] = useState(new Date());
  const [file, setFile] = useState();
  const [id, setId] = useState("");
  const fileInputRef = useRef(null);
  const [numId, setNumId] = useState(null);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    mainFile: Joi.required(),
    publishDate: Joi.date().required(),
  };

  const client = new IssueTypeClient();
  const clientNumber = new NumberClient();

  useEffect(() => {
    getIssueTypes();
  }, []);

  useEffect(() => {
    initialData();
  }, [activeTab, isCurrent]);

  const initialData = async () => {
    const storedObject = sessionStorage.getItem(filterName);
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      if(parsedObject.issueTypeGuid) {
        GetYearsByIssueType(parsedObject.issueTypeGuid);
        setEditNumbersData(parsedObject);
        await FilterNumbersByIssueType();
      }

      if (parsedObject.num) {
        setEditNumbersData(parsedObject);
        setShowInputs(true);
        await FilterNumbersByIssueType();
        const response = await clientNumber.GetDateAndPDF(parsedObject);
        setPublishDate(new Date(response.data.publishDate));
        setFile(response.data.mainFile);
        setId(response.data.id);
      }
    } else {
      setShowNumAndSearch(false);
      setShowInputs(false);
      setEditNumbersData({
        issueTypeGuid: "",
        num: "",
      });
      setPublishDate(new Date());
      setFile(null);
      setId("");
    }
  };

  // Vrsta izdanja lista
  const getIssueTypes = async () => {
    let responseForOgAndReg = await client.getIssueTypesForOgAndReg(true);
    let handledResponseForOgAndReg = HandlingResponse(responseForOgAndReg);

    let responseForOg = await client.getIssueTypesForOg(true);
    let handledResponseForOg = HandlingResponse(responseForOg);

    if (
      handledResponseForOg !== "Error" &&
      handledResponseForOgAndReg !== "Error"
    ) {
      let combinedResponse = [
        ...handledResponseForOgAndReg,
        ...handledResponseForOg,
      ];

      let activeItems = combinedResponse.filter((item) => item.active === true);

      var result = activeItems.map((item) => {
        return { value: item.guid, label: item.description };
      });
      setIssueTypeList(result);
    }
  };

  // Filter za Broj izdanja listu

  const FilterNumbersByIssueType = async () => {
    setShowNumAndSearch(true);

    //ukoliko imamo sacuvanu vrstu i godinu, uzimamo za njih listu brojeva izdanja
    const storedObject = sessionStorage.getItem(filterName);
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      if (parsedObject.issueTypeGuid && parsedObject.year)
        var data = {
          issueTypeGuid: parsedObject.issueTypeGuid,
          year: parsedObject.year,
        };
    } else {
      data = {
        issueTypeGuid: editNumbersData.issueTypeGuid,
        year: editNumbersData.year,
      };
    }
    GetYearsByIssueType(data.issueTypeGuid);
    const response = await clientNumber.FilterNumbersByIssueTypeAndYear(data);
    var result = response.data.map((item) => {
      return {
        value: parseInt(item.num),
        label: parseInt(item.num),
        id: item.id,
      };
    });
    if (result.length > 0) {
      setNumList(result);
      setNumId(result[0].id);
    }
  };

  // Pribavljanje Datuma i PDF fajla

  const GetDateAndPdf = async () => {
    var data = {
      issueTypeGuid: editNumbersData.issueTypeGuid,
      year: editNumbersData.year,
      num: editNumbersData.num,
    };
    const response = await clientNumber.GetDateAndPDF(data);
    setPublishDate(new Date(response.data.publishDate));
    setFile(response.data.mainFile);
    setId(response.data.id);
    setShowInputs(true);
  };

  const downloadMainFile = async () => {
    var response = await clientNumber.DownloadNumberFile(numId, file.name);
    HandlingResponse(response);
  };

  const removeFile = () => {
    setFile(null);
    fileInputRef.current.value = null;
  };

  const handleUploadFileClick = () => {
    fileInputRef.current?.click();
  };

  const onSaveButtonClick = async () => {
    var data = {
      mainFile: file ? file : undefined,
      publishDate: formatDateForUpdate(publishDate),
      id: id,
    };

    const errorsResult = validateForm(data, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      let formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      let response = await clientNumber.updateNumberFileAndPublishDate(
        formData
      );
      if (response !== "Error") {
        let handledResponse = HandlingResponse(response);
        if (handledResponse?.code == 408) {
          var result = serverValidation(handledResponse.data, t);
          setErrors(result);
        }
        initialData();
      }
    }
  };

  const handleNumListChange = (selectedOption) => {
    setNumId(selectedOption.id);
    setEditNumbersData({
      ...editNumbersData,
      num: selectedOption.value,
    });
    setShowInputs(false); // Sakriva inpute kada se selectuje novi element iz nuMList
  };

  const GetYearsByIssueType = async (issueTypeGuid) => {
    if (isCurrent)
      var response = await clientNumber.GetCurrentYearsByIssueType(
        issueTypeGuid
      );
    else
      response = await clientNumber.GetArchiveYearsByIssueType(issueTypeGuid);
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let array = handledResponse.years.map((x) => {
        return { value: x, label: x };
      });
      setYearList(array);
    }
  };

  return (
    <>
      <div className="form-group">
        <label>Врста издања</label>
        <Select
          options={issueTypeList}
          onChange={(e) => {
            setEditNumbersData({
              ...editNumbersData,
              issueTypeGuid: e.value,
              year: null,
              num: null,
            });
            setShowNumAndSearch(false);
            GetYearsByIssueType(e.value);
          }}
          value={issueTypeList.filter(
            (x) => x.value === editNumbersData.issueTypeGuid
          )}
        />
      </div>

      <div className="form-group">
        <label>{isCurrent ? "Година издања" : "Година"}</label>
        <Select
          options={yearList}
          onChange={(e) => {
            setEditNumbersData({
              ...editNumbersData,
              year: e.value,
              num: null,
            });
            !isCurrent && setShowNumAndSearch(false);
            !isCurrent && setShowInputs(false);
          }}
          value={yearList.filter((x) => x.value === editNumbersData.year)}
        />
      </div>

      <button
        type="button"
        className="btn btn-primary form-group"
        disabled={!editNumbersData.year}
        onClick={() => {
          sessionStorage.setItem(filterName, JSON.stringify(editNumbersData));
          FilterNumbersByIssueType();
        }}
      >
        Пронађи издања
      </button>
      {showNumAndSearch && (
        <div className="form-group">
          <label>Број издања</label>
          <Select
            options={numList}
            onChange={(selectedOption) => {
              setNumId(selectedOption.id);
              handleNumListChange(selectedOption); // call handleNumListChange when selected option changes
            }}
            value={numList.find(
              (option) => option.value === editNumbersData.num
            )}
            isDisabled={!editNumbersData.issueTypeGuid}
          />
        </div>
      )}
      {showNumAndSearch && (
        <button
          type="button"
          className="btn btn-primary form-group"
          disabled={!editNumbersData.num}
          onClick={() => {
            sessionStorage.setItem(filterName, JSON.stringify(editNumbersData));
            GetDateAndPdf();
          }}
        >
          Пронађи
        </button>
      )}
      {showInputs && editNumbersData.num && (
        <div>
          <div className="form-group">
            <label>
              Датум објављивања:<span className="required-asterisk">*</span>
            </label>
            <CustomDatePicker
              selected={publishDate}
              onChange={(date) => setPublishDate(date)}
              showMonthDropdown
              useShortMonthInDropdown
            />
            {errors?.publishDate && (
              <div className="text-danger">{errors.publishDate}</div>
            )}
          </div>
          <div className="form-group">
            {file ? (
              <>
                <label>Назив фајла: </label> {file.name}
                {file.guid ? (
                  <DownloadButton
                    title="Преузми фајл"
                    type="button"
                    onClick={() => downloadMainFile()}
                  >
                    <FontAwesomeIcon icon={solid("download")} />
                  </DownloadButton>
                ) : (
                  <></>
                )}
                <ButtonWithComfirmation
                onClick={removeFile}
                handleYesText={"Обриши фајл"}
                contentText={"Уколико сачувате измене, фајл ће бити трајно обрисан"}
                className="deleteButton"
              >
                  <FontAwesomeIcon icon={solid("times")} />
              </ButtonWithComfirmation>
                
              </>
            ) : (
              <NewFileBtn onClick={handleUploadFileClick} type="button">
                <FontAwesomeIcon icon={solid("plus")} />
                Одабери ПДФ фајл<span className="required-asterisk">*</span>
              </NewFileBtn>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={(event) => setFile(event.target.files[0])}
              hidden
              accept=".pdf"
              name="file"
            />
            {errors?.mainFile && (
              <div className="text-danger">{errors.mainFile}</div>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onSaveButtonClick}
          >
            Сачувај
          </button>
        </div>
      )}
    </>
  );
};

export default EditNumbers;
