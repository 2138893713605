import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_PPP;

class AuthorityClient {
    async getAllAuthorities(isBackground) {
        try {
            const response = await API.get(baseurl + "/Authority/GetAllAuthorities", { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async createAuthority(data, isBackground) {
        try {
            const response = await API.post(baseurl + "/Authority/CreateAuthority", data, { isBackground: isBackground })
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateAuthority(data, isBackground) {
        try {
            const response = await API.put(baseurl + "/Authority/UpdateAuthority", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default AuthorityClient;