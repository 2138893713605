import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker.component";
import DatePicker from "react-datepicker";
import { sr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../../common/validation/validation";
import EventsCalendarClient from "../../../api-services/news/events-calendar/events-calendar";
import { serializeObjectIntoFormData } from "../../../../utils/serialize-form-data/serializeFormData";

const CreateEventsCalendar = ({ row, setRow, edit }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    title: Joi.string().required(),
    eventDate: Joi.date().required(),
  };

  var client = new EventsCalendarClient();

  const createEvent = async () => {
    const errorsResult = validateForm(row, schema, t);
    setErrors(errorsResult);

    const inputDate = new Date(row.eventDate);
    const offsetInMillis = inputDate.getTimezoneOffset() * 60 * 1000;
    const cestDate = new Date(inputDate.getTime() - offsetInMillis);
    const outputDate = cestDate.toISOString();

    var data = {
      ...row,
      eventDate: outputDate
    };

    if(!errorsResult) {
      var response;

      if (edit) {
          response = await client.updateEvent(data);
        } else {
        response = await client.createEvent(data);
      }

      let handledResponse = HandlingResponse(response);
      
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      }
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, row, errors, schema, t);
    setRow(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <div className="form-group">
        <label>Назив догађаја<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          name="title"
          value={row.title}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.title && <div className="text-danger">{errors.title}</div>}
      </div>
      <div className="form-group">
        <label>Опис</label>
        <input
          type="text"
          className="form-control"
          name="description"
          value={row.description}
          onChange={(e) => setRow({ ...row, description: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Датум догађаја<span className="required-asterisk">*</span></label>
        {row.eventDate !== undefined && (
          <DatePicker
            locale={sr}
            selected={row.eventDate ? new Date(row.eventDate) : null}
            onChange={(date) => handlePropertyChange(date, "eventDate")}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            dateFormat="dd.MM.yyyy. HH:mm"
            timeCaption="време"
            className="form-control"
          />
        )}
        {errors?.eventDate && (
          <div className="text-danger">{errors.eventDate}</div>
        )}
      </div>
      
      <input
        className=""
        id="activeFP"
        type="checkbox"
        value={row.active}
        checked={row.active}
        onChange={(event) => {
          setRow({
            ...row,
            active: event.target.checked,
          });
        }}
      />
      <label style={{marginRight: "20px"}} htmlFor="activeFP">Активан</label>
      
      <input
        className=""
        id="publishedFP"
        type="checkbox"
        value={row.published}
        checked={row.published}
        onChange={(event) => {
          setRow({
            ...row,
            published: event.target.checked,
          });
        }}
      />
      <label htmlFor="publishedFP">Објављен</label>
      <div>
        <button
          type="button"
          className="btn btn-primary form-group"
          style={{ marginTop: "20px" }}
          onClick={() => createEvent()}
        >
          {edit ? "Измени" : "Креирај"}
        </button>
      </div>
    </>
  );
};

export default CreateEventsCalendar;
