import React, { useState, useEffect } from "react";
import IssueTypeClient from "../../api-services/issue-type/issue-type-client";
import IssueTypeModel from "../../models/IssueType";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateIssueType = ({ row, isEdit, handleCallback }) => {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        name: Joi.string().max(250).required(),
        description: Joi.string().max(250).required(),
        extCode: Joi.string().max(250).required(),
        shortDescription: Joi.string().max(250).required()
    }

    const client = new IssueTypeClient();

    const [issueType, setIssueType] = useState(new IssueTypeModel());

    const createIssueType = async (e) => {
        e.preventDefault();
        const errorsResult = validateForm(issueType, schema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            if (isEdit) var response = await client.updateIssueType(issueType);
            else var response = await client.createIssueType(issueType);
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
            else
                handleCallback(handledResponse);
        }
    }

    useEffect(() => {
        setIssueType(row);
        setErrors({});
    }, [row]);

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, issueType, errors, schema, t);
        setIssueType(result.data);
        setErrors(result.errors);
    }

    return (
        <form onSubmit={createIssueType}>
            <div className="col-md">
                <label>{t("Name")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={issueType.name}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.name && (
                    <div className="text-danger">
                        {errors.name}
                    </div>
                )}
                <label>{t("ExternalReference")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="extCode"
                    value={issueType.extCode}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.extCode && (
                    <div className="text-danger">
                        {errors.extCode}
                    </div>
                )}
                <label>{t("Description")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="description"
                    value={issueType.description}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.description && (
                    <div className="text-danger">
                        {errors.description}
                    </div>
                )}
                <label>{t("ShortDescription")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="shortDescription"
                    value={issueType.shortDescription}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.shortDescription && (
                    <div className="text-danger">
                        {errors.shortDescription}
                    </div>
                )}
                <input
                    id="issueTypeActiveCBforRegCBforOgCB"
                    type="checkbox"
                    value={issueType.forOg}
                    checked={issueType.forOg}
                    onChange={(event) => {
                        setIssueType({ ...issueType, forOg: event.target.checked });
                    }}
                />
                <label htmlFor="issueTypeActiveCBforRegCBforOgCB" style={{marginRight: "10px"}}>{t("ForOg")}</label>
                <input
                    id="issueTypeActiveCBforRegCB"
                    type="checkbox"
                    value={issueType.forReg}
                    checked={issueType.forReg}
                    onChange={(event) => {
                        setIssueType({ ...issueType, forReg: event.target.checked });
                    }}
                />
                <label htmlFor="issueTypeActiveCBforRegCB" style={{marginRight: "10px"}}>{t("ForReg")} </label>
                <input
                id="issueTypeActiveCB"
                    type="checkbox"
                    value={issueType.active}
                    checked={issueType.active}
                    onChange={(event) => {
                        setIssueType({ ...issueType, active: event.target.checked });
                    }}
                />
                <label htmlFor="issueTypeActiveCB">{t("Active")}</label>
            </div>
            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={createIssueType}
                >
                    {t("Save")}
                </button>
            </div>
        </form>
    )
};

export default CreateIssueType;