import React, { useState, useEffect } from "react";
import Table, { getPageNumber, getPageSizeNumber, getSortBy } from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../common/modal/SideModal.component";
import CreateUser from "../../components/create-user/CreateUser.component";
import ChangeUserPassword from "../../components/change-user-password/ChangeUserPassword.component";
import SearchInternalUsers from "../../components/search-internal-users/SearchInternalUsers.component";
import { InternalUsersModel } from "../../models/Users";
import UserClient from "../../api-services/create-users/create-users-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const InternalUser = () => {
  const rememberFilter = true;
  const tableName = "LOZA_INTERNAL_USER";

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState(new InternalUsersModel());
  const [show, setShow] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [reset, setReset] =  useState(false);
  const [rowData, setRowData] = useState({
    name: "",
    lastName: "",
    userName: "",
    password: "",
    telephone: "",
    email: "",
    company: "",
    address: "",
    roles: [],
  });
  const [userGuid, setUserGuid] = useState("");

  const columns = [
    {
      Header: "Име",
      accessor: "name",
    },
    {
      Header: "Презиме",
      accessor: "lastName",
    },
    {
      Header: "Корисничко име",
      accessor: "userName",
    },
    {
      Header: "Адреса",
      accessor: "address",
    },
    {
      Header: "Компанија",
      accessor: "company",
    },
    {
      Header: "Е-маил",
      accessor: "email",
      width: 300,
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEdit(row.original)}
              type="button"
              title="Измена корисника"
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditPassword(row.original.userGuid)}
              type="button"
              title="Измена лозинке"
            >
              <FontAwesomeIcon icon={solid("key")} />
            </button>
          </>
        );
      },
      width: 100,
    },
  ];

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  useEffect(() => {
    reset && setReset(false);
  }, [tableData])

  const client = new UserClient();

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("LOZA_INTERNAL_USER_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.FilterInternalUsers({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesBySort: sortBy,
    });
    let data = HandlingResponse(response);
    return { dataList: data.usersDto, count: data.count };
  };

  const searchData = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("LOZA_INTERNAL_USER_FILTER", JSON.stringify(filterData));
    var result = await fetchDataForPage(
      getPageNumber(tableName) + 1,
      getPageSizeNumber(tableName),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    setFilterData(new InternalUsersModel());
    sessionStorage.removeItem("LOZA_INTERNAL_USER_FILTER");
    var response = await client.FilterInternalUsers({});
    let data = HandlingResponse(response);
    setTableData({ dataList: data.usersDto, count: data.count });
  };

  const onCreateClick = () => {
    setIsEditPassword(false);
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      lastName: "",
      userName: "",
      password: "",
      telephone: "",
      email: "",
      company: "",
      address: "",
      roles: [],
      userGuid: "",
      password: "",
    });
    showModal();
  };

  const onEdit = (data) => {
    setIsEditPassword(false);
    setIsCreate(false);
    setRowData({
      id: data.id,
      userGuid: data.userGuid,
      name: data.name,
      lastName: data.lastName,
      userName: data.userName,
      address: data.address,
      company: data.company,
      email: data.email,
      telephone: data.telephone,
      password: data.initialPassword,
      roles: data.roles.map((x) => {
        return { value: x.id, label: x.name };
      }),
    });
    showModal();
  };

  const handleResponse = async () => {
    hideModal();
    var result = await fetchDataForPage(
      getPageNumber(tableName) + 1,
      getPageSizeNumber(tableName),
      getSortBy(tableName, rememberFilter, true)
    )
    setTableData(result);
  };
  
  const onEditPassword = (userGuid) => {
    setIsEditPassword(true);
    setUserGuid(userGuid);
    showModal();
  };

  return (
    <>
      <h4>Интерни корисници</h4>
      <SearchInternalUsers
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <hr />
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
        >
          Додај корисника
        </button>
      </div>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={rememberFilter}
        tableName={tableName}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setTableData}
        resetFilters={reset}
      />
      {isEditPassword ? (
        <SideModal show={show} handleClose={hideModal} title="Измена лозинке">
          <ChangeUserPassword userGuid={userGuid} handleClose={hideModal} />
        </SideModal>
      ) : (
        <SideModal
          show={show}
          handleClose={hideModal}
          title={isCreate ? "Додавање корисника" : "Измена корисника"}
        >
          <CreateUser
            row={rowData}
            isCreate={isCreate}
            handleResponse={handleResponse}
          />
        </SideModal>
      )}
    </>
  );
};

export default InternalUser;
