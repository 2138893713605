import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { useCallback } from "react";
import Moment  from "moment";

const StatisticsFilterByYear = () => {

    const navigate = useNavigate();
    const {state} = useLocation();
    const client = new SmallAddsClient();

    const columns = 
    [
        {
            Header: "Број",
            accessor: "ogNum"
        },
        {
            Header: "Датум",
            accessor: "publishDate",
            Cell: ({ row }) => {
              return row.original.publishDate
                ? Moment(row.original.publishDate).format("DD.MM.YYYY.")
                : "";
            },
        },
        {
            Header: "Број докумената",
            accessor: "docCount"
        },
    ]

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState("")
    const [filterData, setFilterData] = useState({
        num: 0,
        year: 0
    })
    const [publishDate, setPublishDate] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    

    const onPreviewClick = () => {
        navigate("/mo/smallAdds/statistics/filterByYearAndNum", {state: {filterData, publishDate}})
    }

    const onCancelClick = () => {
        navigate("/mo/smallAdds/statistics")
    }

    const FilterByYearAndAggregateByNumber = async () => {
        let response = await client.FilterByYearAndAggregateByNumber(state.year, false);
        let result = HandlingResponse(response);
        setTableData(result.aggregationData);
        setTotalCount(result.total)
    }

    const handleTableRowClick = useCallback((response) => {
        setFilterData({
            ...filterData,
            num: response.ogNum,
            year: state.year
        });
        setPublishDate(response.publishDate);
        setIsDisabled(false);
    })

    useEffect(() => {
        FilterByYearAndAggregateByNumber();
    }, [])

    return(
        <>
            <h4>Преглед статистике броја</h4>
            {state && <label style={{marginBottom: "15px"}}>Година гласила: {state.year}</label>}
            <Table
                columns={columns}
                data={tableData}
                serverSide={false}
                handleClickOnRow={handleTableRowClick}
                rememberFilter={false}
            />
            {totalCount > 0 && <label style={{marginBottom: "15px"}}>Укупно докумената: {totalCount}</label>}
            <br/>
            <button
                className="btn btn-primary"
                onClick={() => onPreviewClick()}
                style={{marginRight: "5px"}}
                disabled={isDisabled}
                >
                Преглед
            </button>
            <button
                className="btn btn-primary"
                onClick={() => onCancelClick()}
                >
                Откажи
            </button>
            </>
    )
}

export default StatisticsFilterByYear;