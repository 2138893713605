import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import UserClient from "../../api-services/create-users/create-users-client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";

const ChangeUserPassword = ({ userGuid, handleClose }) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [notValid, setNotValid] = useState(false);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    password: Joi.string().min(6).required(),
  };

  const client = new UserClient();

  const ChangePassword = async () => {
    const errorsResult = validateForm({ password: password }, schema, t);
    setErrors(errorsResult);
    checkPassword(password, passwordRepeat);

    if (!errorsResult && !notValid) {
      let response = await client.SetUserPassword(userGuid, password);
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleClose();
    }
  };

  const checkPassword = (password, passwordRepeat) => {
    if (password !== passwordRepeat) {
      setNotValid(true);
    } else {
      setNotValid(false);
    }
  }

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, password, errors, schema, t);
    setPassword(result.data.password);
    setErrors(result.errors);
  };

  useEffect(() => {
    setPassword("");
    setPasswordRepeat("");
    setErrors({});
    setNotValid(false);
  }, [userGuid])

  return (
    <>
      <div className="form-group">
        <label>Нова лозинка</label>
        <input
          type="password"
          className="form-control"
          name="password"
          value={password}
          onChange={(e) => { 
            handlePropertyChange(e.target);
            passwordRepeat !== "" && checkPassword(password, e.target.value) }}
        />
        {errors?.password && (
          <div className="text-danger">{errors.password}</div>
        )}
      </div>
      <div className="form-group">
        <label>Поновите лозинку</label>
        <input
          disabled = {password === "" ? "disabled" : ""}
          type="password"
          className="form-control"
          value={passwordRepeat}
          onChange={(e) => { setPasswordRepeat(e.target.value); checkPassword(password, e.target.value) }}
        />
        {notValid && (
          <div className="text-danger">Лозинке се не поклапају!</div>
        )}
      </div>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => ChangePassword()}
      >
        Измени
      </button>
    </>
  );
};

export default ChangeUserPassword;
