import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PENG;

class UserClient {
  async filterUsers(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/User/FilterUsers", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getUser(id, isBackground) {
    const params = {
      params: { userId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/User/GetUser", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createUser(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/User/CreateUser", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateUser(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/User/UpdateUser", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteUser(id, isBackground) {
    const params = {
      params: { id: id },
      isBackground: isBackground
    };
    try {
      var response = await api.delete(baseurl + "/User/DeleteUser", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getUserSubscriptions(id, isBackground) {
    const params = {
      params: { userId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/User/GetUserSubscriptions", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateUserSubscriptions(id, data, isBackground) {
    const params = {
      params: { userId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.post(baseurl + "/User/UpdateUserSubscriptions", data, params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}
export default UserClient;