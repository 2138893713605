import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class CourtKindClient {
  async CourtKind(isBackground) {
    try {
      var response = await api.get(baseurl + "/CourtKind", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllActiveCourtKinds(isBackground) {
    try {
      var response = await api.get(baseurl + "/CourtKind/GetActiveCourtKinds", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateCourtKind(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/CourtKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateCourtKind(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/CourtKind/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DeleteCourtKind(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/CourtKind?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default CourtKindClient;
