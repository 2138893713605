import React, { useEffect, useState } from "react";
import {
  ActBasicInfoContainer,
  ActInfoData,
  GlasnikCSS,
  HTMLContent,
  SideInfoContainer,
} from "./PreviewDocument.style";
import { PreviwActInfoModel } from "../../models/PreviwActInfo";
import DataClient from "../../api-services/data/data-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
//   ).toString();

const PreviewDocument = () => {
  const [htmlContent, setHtmlContent] = useState();
  const [pdfContent, setPdfContent] = useState();
  const [previewActInfo, setPreviewActInfo] = useState(
    new PreviwActInfoModel()
  );
    
  const [documentId] = useSearchParams();
  var docId = documentId.get("documentId");

  var dataClient = new DataClient();

  useEffect(() => {
    getDataViewForPrins();
  }, []);


  useEffect(() => {
    getDocument();

    if(previewActInfo.documentInfo.dataHtmlUuid !== null && previewActInfo.documentInfo.dataHtmlUuid !== "") {
      const styleTags = document.querySelector('.html-content').querySelectorAll('style');
      styleTags.forEach(styleTag => {
        styleTag.innerHTML = styleTag.innerHTML.replace(/}/g, '} .html-content');
      });
    }
    
  }, [previewActInfo.documentInfo.dataHtmlUuid]);

  const getDataViewForPrins = async () => {
    var response = await dataClient.getDataViewForPrins(docId);
    var handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error")
      setPreviewActInfo(handledResponse);
  };

  const getDocument = async () => {
    if (previewActInfo.documentInfo.dataHtmlUuid !== null)
      await dataClient.viewActForPrins(docId).then((response) => {
        setHtmlContent(response);
      });
    else {
      await dataClient.viewActForPrins(docId, "blob").then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfContent(fileURL);
      });
    }
  };

  return (
    <div>
      <h5>Преглед акта</h5>
      <hr />
      <div>
        <h5 style={{textAlign: "center"}}>{previewActInfo.documentInfo.dataActCloserDesignation}</h5>
      </div>
      <br/>
      <ActBasicInfoContainer>
        <table className="col-10">
          <thead>
            <tr>
              <th>Врста</th>
              <th>Доносилац/Аутор</th>
              <th>Основни пропис</th>
              <th>Начин објаве</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{previewActInfo.documentInfo.typeRootName}</td>
              <td>{previewActInfo.documentInfo.authorityName}</td>
              <td>{previewActInfo.documentInfo.dataPrincipalAct}</td>
              <td>{previewActInfo.documentInfo.defSourceName}</td>
            </tr>
          </tbody>
        </table>
      </ActBasicInfoContainer>
      <div style={{ display: "flex" }}>
        <HTMLContent className="col-10">
          {previewActInfo.documentInfo.dataHtmlUuid === null ? (
            // <Document file={pdfContent}>
            //   <Page pageNumber={1} />
            // </Document>
            <iframe src={pdfContent} width="100%" height="500px" />
          ) : (
            <GlasnikCSS>
              <div
                className="html-content"
                dangerouslySetInnerHTML={{
                  __html: htmlContent,
                }}
              />
            </GlasnikCSS>
          )}
        </HTMLContent>
        <ActInfoData className="col-2">
          {previewActInfo.documentInfo.typeParentName !== "" &&
            previewActInfo.documentInfo.typeParentName !== null &&
            previewActInfo.documentInfo.typeRootId === "4" && (
              <SideInfoContainer>
                <label>Подврсте модела уговора и других аката</label>
                <p>{previewActInfo.documentInfo.typeParentName}</p>
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.typeName !== "" &&
            previewActInfo.documentInfo.typeName !== null && (
              <SideInfoContainer>
                <label>
                  {previewActInfo.documentInfo.typeRootId === "4"
                    ? "Тип / Област"
                    : "Подврста"}
                </label>
                <p>{previewActInfo.documentInfo.typeName}</p>
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.dataActLabelNumberHtml !== "" &&
            previewActInfo.documentInfo.dataActLabelNumberHtml !== null && (
              <SideInfoContainer>
                <label> Ознака / број акта </label>
                <p>{previewActInfo.documentInfo.dataActLabelNumberHtml}</p>
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.dataPrincipalAct !== "" &&
            previewActInfo.documentInfo.dataPrincipalAct !== null && (
              <SideInfoContainer>
                <label>Основни пропис</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewActInfo.documentInfo.dataPrincipalActHtml,
                  }}
                />
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.dataOtherActs !== "" &&
            previewActInfo.documentInfo.dataOtherActs !== null && (
              <SideInfoContainer>
                <label>Други прописи</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewActInfo.documentInfo.dataOtherActsHtml,
                  }}
                />
              </SideInfoContainer>
            )}
          {previewActInfo.references !== null &&
            previewActInfo.references.length > 0 && (
              <SideInfoContainer>
                <label>Повезани акти</label>
                <div>
                  <ul style={{ listStyleType: "none", paddingLeft: 0}}>
                    {previewActInfo.references.map((item, index) => (
                    <div key={index}>
                      { index == 0 ? <></> :<hr/>}
                      <li><a href={item.url} target="_blank">{item.name}</a></li>
 
                      </div>
                    ))}
                  </ul>
                </div>
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.defSourceName !== "" &&
            previewActInfo.documentInfo.defSourceName !== null && (
              <SideInfoContainer>
                <label>Сви начини објаве</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewActInfo.documentInfo.sourceHtml,
                  }}
                />
              </SideInfoContainer>
            )}
          {previewActInfo.documentInfo.dataEnactmentDate !== null &&
            previewActInfo.documentInfo.dataShowEnactmentDate === true && (
              <SideInfoContainer>
                <label>Датум</label>
                <div>
                  {" "}
                  {format(
                    new Date(previewActInfo.documentInfo.dataEnactmentDate),
                    "dd.MM.yyyy."
                  )}
                </div>
              </SideInfoContainer>
            )}
        </ActInfoData>
      </div>
    </div>
  );
};

export default PreviewDocument;
