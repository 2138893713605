import React, { useState, useEffect } from "react";
import CountryClient from "../../api-services/country/country-client";
import CountryModel from "../../models/Country";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { handleSave, validateForm, serverValidation } from "../../../common/validation/validation";

const CreateCountry = ({ row, isEdit, handleCallback }) => {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
        countryNameLong: Joi.string().max(250).required(),
        countryNameShort: Joi.string().max(250).required(),
        extCode: Joi.string().max(45).required()
    }

    const client = new CountryClient();

    const [country, setCountry] = useState(new CountryModel());

    const createCountry = async (e) => {
        e.preventDefault();
        const errorsResult = validateForm(country, schema, t);
        setErrors(errorsResult);

        if (!errorsResult) {
            if (isEdit) var response = await client.updateCountry(country);
            else var response = await client.createCountry(country);
            let handledResponse = HandlingResponse(response);
            if (handledResponse.code == 408) {
                var result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
            else
                handleCallback(handledResponse);
        }
    }

    useEffect(() => {
        setCountry(row);
        setErrors({});
    }, [row]);

    const handlePropertyChange = (event, date) => {
        const obj = date ? { name: date, value: event } : { name: event.name, value: event.value };
        const result = handleSave(obj, country, errors, schema, t);
        setCountry(result.data);
        setErrors(result.errors);
    }

    return (
        <form onSubmit={createCountry}>
            <div className="col-md">
                <label>{t("Name")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="countryNameLong"
                    value={country.countryNameLong}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.countryNameLong && (
                    <div className="text-danger">
                        {errors.countryNameLong}
                    </div>
                )}
                <label>{t("ShortName")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="countryNameShort"
                    value={country.countryNameShort}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.countryNameShort && (
                    <div className="text-danger">
                        {errors.countryNameShort}
                    </div>
                )}
                <label>{t("ExternalReference")}<span className="required-asterisk">*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="extCode"
                    value={country.extCode}
                    onChange={(event) => handlePropertyChange(event.target)}
                />
                {errors?.extCode && (
                    <div className="text-danger">
                        {errors.extCode}
                    </div>
                )}
                <input
                id="countryActiveCB"
                    type="checkbox"
                    value={country.active}
                    checked={country.active}
                    onChange={(event) => {
                        setCountry({ ...country, active: event.target.checked });
                    }}
                />
                <label htmlFor="countryActiveCB">{t("Active")}</label>
            </div>
            <div className="text-right">
                <button
                    className="btn btn-primary m-2"
                    onClick={createCountry}
                >
                    {t("Save")}
                </button>
            </div>
        </form>
    )
};

export default CreateCountry;