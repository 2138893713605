export const MML = [
    {
        buttonName: "Документа",
        path: "/mml",
        role: ['MML_Admin', 'MML_Urednik']
    },
    {
        buttonName: "Шифарници",
        links: [
            {
                title: "Типови",
                path: "/mml/codebooks/codebookTypes",
            },
            {
                title: "Доносиоци",
                path: "/mml/codebooks/authorities",
            },
            {
                title: "Извори података",
                path: "/mml/codebooks/sourceKind",
            }
        ],
        role: ['MML_Admin', 'MML_Sifarnici']
    }
];

export const LOZA = [
    {
        buttonName: "Креирање налога",
        links: [
            {
                title: "Креирање корисничког налога",
                path: "/loza/CreateUser"
            },
            {
                title: "Измена корисничких налога",
                path: "/loza/EditUserAccounts"
            },
            {
                title: "Креирање групних корисничких налога",
                path: "/loza/CreateGroupAccounts"
            },
            {
                title: "Измена групних корисничких налога",
                path: "/loza/EditGroupAccounts"
            },
        ],
        role: ['Admin_Prodaja', 'Prodaja']
    },
    {
        buttonName: "Захтеви",
        links: [
            {
                title: "Необрађени захтеви",
                path: "/loza"
            },
            {
                title: "Сви захтеви",
                path: "loza/AllRequests"
            },
        ],
        role: ['Admin_Prodaja', 'Prodaja']
    },
    {
        buttonName: "Интерни корисници",
        path: "loza/InternalUsers",
        role: ['Admin_Interni']
    },
    {
        buttonName: "Претплатници",
        path: "loza/Subscribers",
        role: ['Interni_Korisnik','Admin_Interni']
    },
    {
        buttonName: "Продаја",
        links: [
            {
                title: "Дефиниција производа",
                path: "loza/Products"
            },
            {
                title: "Дефиниција пакета",
                path: "loza/Packages"
            },
            {
                title: "Дефиниција групе пакета",
                path: "loza/PackageGroups"
            }
        ],
        role: ['Admin_Prodaja']
    },
    {
        buttonName: "Промо подешавања",
        path: "loza/promoSettings",
        role: ['Admin_Prodaja']
    },
];

export const SP = [
    {
        buttonName: "Предмети",
        path: "/sp",
        role: ['SP_Admin', 'SP_Urednik']
    },
    {
        buttonName: "Шифaрници",
        links: [
            {
                title: "Врсте судова",
                path: "/sp/codebooks/courtKinds"
            },
            {
                title: "Судови",
                path: "/sp/codebooks/courts"
            }, {
                title: "Судска одељења",
                path: "/sp/codebooks/courtDepartments"
            },
            {
                title: "Правна област",
                path: "/sp/codebooks/legalFields"
            }, {
                title: "Правна материја",
                path: "/sp/codebooks/legalMatter"
            },
            {
                title: "Правна подматерија",
                path: "/sp/codebooks/legalSubMatter"
            },
            {
                title: "Врсте аката",
                path: "/sp/codebooks/legislativeActType"
            },
            {
                title: "Врсте поступака",
                path: "/sp/codebooks/procedureKind"
            }, {
                title: "Исходи поступака",
                path: "/sp/codebooks/procedureOutcome"
            },
            {
                title: "Врсте објаве",
                path: "/sp/codebooks/typesOfPosts"
            }, {
                title: "Производи",
                path: "/sp/codebooks/products"
            },
            {
                title: "Уписници",
                path: "/sp/codebooks/register"
            },
        ],
        role: ['SP_Admin', 'SP_Sifarnici']
    },
    {
        buttonName: "Регистри",
        path: "/sp/advancedSearchQuery",
        role: ['SP_Admin', 'SP_Sifarnici']
    },
];

export const ADMIN_PANEL = [
    {
        buttonName: "Шифарници",
        links: [
            {
                title: "Врсте законских аката",
                path: "/adminPanel"
            },
            {
                title: "Категорије огласа",
                path: "/adminPanel/codebooks/advertisementCategories"
            },
            {
                title: "Доносиоци",
                path: "/adminPanel/codebooks/authority"
            },
            {
                title: "Врсте издања",
                path: "/adminPanel/codebooks/issueType"
            },
            {
                title: "Шифарник земаља",
                path: "/adminPanel/codebooks/numCountry"
            },
        ],
        role: ['SLGL_REG_Sifarnici']
    },
];

export const TEXT_EDITOR = [
];

export const SLGL = [
    {

        buttonName: "Измена броја и аката",
        path: "/slgl/princess/index",
        role: ['SLGL_Admin', 'SLGL_Arhiva', 'SLGL_Urednik']
    },
    {

        buttonName: "Публикација актуелних гласила",
        path: "/slgl",
        role: ['SLGL_Admin', 'SLGL_Urednik']
    },
    {

        buttonName: "Публикација архивираних гласила",
        path: "/slgl/publications/ArchivedPublications",
        role: ['SLGL_Admin', 'SLGL_Arhiva']
    },
    {

        buttonName: "Подешавања",
        path: "/slgl/settings",
        role: ['SLGL_Admin']
    },
];

export const REGS = [
    {
        buttonName: "Регистри",
        path: "/registries",
        role: ['REG_Admin', 'REG_Urednik']
    },
    {
        buttonName: "Напомене",
        path: "/registries/warnings",
        role: ['REG_Admin', 'REG_Urednik']
    },
    {
        buttonName: "Подешавања",
        path: "/registries/settings",
        role: ['REG_Admin']
    },
    {
        buttonName: "Индексирање",
        path: "/registries/indexing",
        role: ['REG_Admin']
    },
    {
        buttonName: "Закључани акти и области",
        path: "/registries/lockedActs",
        role: ['REG_Admin', 'REG_Urednik']
    },

];

export const FRONTPAGE = [
    {
        buttonName: "Шифарници",
        links: [
            {
                title: "Категорије",
                path: "/frontpage/codebooks/categories"
            },
            {
                title: "Затезне камате",
                path: "/frontpage/codebooks/defaultInterest"
            },
            {
                title: "Локације",
                path: "/frontpage/codebooks/locations"
            },
        ],
        role: ['FP_Admin']
    },
    {
        buttonName: "Вест",
        links: [
            {
                title: "Преглед",
                path: "/frontpage"
            },
            {
                title: "Календар догађаја",
                path: "/frontpage/news/eventsCalendar"
            },
        ],
        role: ['FP_Admin']
    },
    {

        buttonName: "Статичке странице",
        path: "/frontpage/staticPages",
        role: ['FP_Admin']
    },
    {

        buttonName: "Мултимедија",
        path: "/frontpage/multipedia",
        role: ['FP_Admin']
    },
    {

        buttonName: "Подешавања",
        path: "/frontpage/settings",
        role: ['FP_Admin']
    },

];

export const NEWSLETTER = [
    {
        buttonName: "Регистрациони мејл",
        path: "/newsletter",
        role: ['Newsletter_Admin']
    },
    {
        buttonName: "Категорије",
        path: "/newsletter/category",
        role: ['Newsletter_Admin']
    },
    {
        buttonName: "Распоред",
        path: "/newsletter/schedule",
        role: ['Newsletter_Admin']
    },
    {
        buttonName: "Мануелно слање",
        path: "/newsletter/manualSend",
        role: ['Newsletter_Admin']
    },
    {
        buttonName: "Подешавања",
        path: "/newsletter/settings",
        role: ['Newsletter_Admin']
    },
];

export const MO = [
    {
        buttonName: "Мали огласи",
        links: [
            {
                title: "Претрага",
                path: "/mo"
            },
            {
                title: "Статистика",
                path: "/mo/smallAdds/statistics"
            }
        ],
        role: ['SLGL_MO_Admin', 'SLGL_MO_Pretraga']
    },
    {
        buttonName: "Сервис",
        links: [
            {
                title: "Индексирање свих малих огласа",
                path: "/mo/service/indexSmallAdds"
            },
            {
                title: "Индексирање свих малих огласа из Фуруне",
                path: "/mo/service/indexSmallAddsFuruna"
            }
        ],
        role: ['SLGL_MO_Admin', 'SLGL_MO_Servis']
    },
];

export const PPP = [
    {
        buttonName: "Почетна",
        path: "/ppp",
        role: ['PPP_Admin', 'PPP_Urednik']
    },
    {
        buttonName: "Шифарници",
        links: [
            {
                title: "Врсте законских аката",
                path: "/ppp/codebooks/documentType"
            },

            {
                title: "Доносиоци",
                path: "/ppp/codebooks/authority"
            },
        ],
        role: ['PPP_Admin', 'PPP_Sifarnici']
    },
    {
        buttonName: "Подешавања",
        path: "/ppp/settings",
        role: ['PPP_Admin']
    },
];

export const PENG = [
    {
        buttonName: "Производи",
        path: "/peng",
        role: ['PEng_Urednik']
    },
    {
        buttonName: "Корисници",
        path: "/peng/users",
        role: ['PEng_Prodaja']
    },
    {
        buttonName: "Наруџбине",
        path: "/peng/order",
        role: ['PEng_Prodaja']
    }
]

export const REGULATIONS = [
    {
        buttonName: "Прописи градова",
        path: "/regulations",
        role: ['Regulations_Admin', 'Regulations_Urednik']
    },
    {
        buttonName: "Напомене",
        path: "/regulations/warnings",
        role: ['Regulations_Admin', 'Regulations_Urednik']
    },
    {
        buttonName: "Шифарници",
        links: [
            {
                title: "Врсте законских аката",
                path: "/regulations/codebooks/actType"
            },

            {
                title: "Доносиоци",
                path: "/regulations/codebooks/authority"
            },
        ],
        role: ['Regulations_Admin', 'Regulations_Sifarnici']
    },
    {
        buttonName: "Подешавања",
        path: "/regulations/settings",
        role: ['Regulations_Admin']
    },
    {
        buttonName: "Индексирање",
        path: "/regulations/indexing",
        role: ['Regulations_Admin']
    },
    {
        buttonName: "Закључани акти и области",
        path: "/regulations/lockedAreasAndActs",
        role: ['Regulations_Admin', 'Regulations_Urednik']
    },
];