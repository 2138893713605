import TinyMCE from "../../components/text-editor/TinyMCE";
import { HtmlEditorContainer } from "../../components/text-editor/editHtml.style";
import { useLocation } from "react-router-dom";
import { TextEditorProvider, UpdateHtmlProvider } from "../../context/TextEditorContext";

const TextEditor = () => {
  const { state } = useLocation();
  return (
    <>
      <HtmlEditorContainer>       
          <TextEditorProvider>
            <TinyMCE></TinyMCE>
          </TextEditorProvider>
      </HtmlEditorContainer>
    </>
  );
};

export default TextEditor;
