import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import { validateForm } from "../../../common/validation/validation";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const UserSettings = ({ connections, setConnections, userGuid }) => {
  const { t } = useTranslation();
  const client = new CreateClient();

  const [userConnections, setUserConnections] = useState(connections);
  const [userInitialPassword, setUserInitialPassword] = useState();

  const [errors, setErrors] = useState({});
  const schema = {
    connections: Joi.number().min(0).max(2147483647).required(),
  };

  useEffect(() => {
    setUserConnections(connections)
  }, [connections])

  const resetPassword = async () => {
    let response = await client.resetPassword(userGuid);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') 
      setUserInitialPassword(handledResponse);
  };

  const killSessions = async () => {
    let response = await client.killSessions(userGuid);
    let res = HandlingResponse(response);
    return res;
  };

  const changeNumberOfConnections = async () => {
    const errorsResult = validateForm({ connections: userConnections }, schema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      let response = await client.changeNumberOfConnections(
        userGuid,
        parseInt(userConnections)
      );
      let handledResponse = HandlingResponse(response);
      if(handledResponse !== "Error") setConnections(handledResponse);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <label>Број конекција</label>
          <input
            className="form-control"
            type="text"
            name="connections"
            value={userConnections}
            onChange={(e) => setUserConnections(e.target.value)}
          />
          {errors?.connections && (
            <div className="text-danger">{errors.connections}</div>
          )}
        </div>
        <div className="col-md-4" style={{ marginTop: "28px" }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={changeNumberOfConnections}
          >
            Промени број конекција
          </button>
        </div>
      </div>
      <br />
      <div>
        <ButtonWithComfirmation
          className="btn btn-primary"
          onClick={() => resetPassword()}
          contentText={
            "Након ресета, лозинка ће се вратити на иницијалну вредност коју је имала приликом креирања налога."
          }
          handleYesText={"Ресетуј"}
        >
          Ресетуј лозинку
        </ButtonWithComfirmation>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "15px" }}
          onClick={killSessions}
        >
          Одјави корисника са свих уређаја
        </button>
      </div>
      <br />
      {userInitialPassword && <div>
        <label>Нова лозинка је:</label>
        <span>{userInitialPassword}</span>
      </div>}
    </>
  );
};

export default UserSettings;
