import React, {useState, useEffect} from 'react'
import Table from '../../../common/table/Table.component';
import SideModal from '../../../common/modal/SideModal.component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import EditSettings from '../../components/edit-settings/EditSettings.component';
import SettingsClient from '../../api-services/settings/settings-client';
import { SettingsModel } from '../../models/Settings';

const Settings = () => {
    const tableName = 'REGULATIONS_SETTINGS';
  
    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Назив",
        accessor: "parameterKey",
      },
      {
        Header: "Вредност",
        accessor: "parameterValue",
      },
      {
        Header: "Опис",
        accessor: "description",
      },
      {
        accessor: "edit",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <button
              title="Измена"
              className="btn btn-primary table-btn"
              onClick={() => getParamsForEdit(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          );
        },
      },
    ];
  
    const client = new SettingsClient();
  
    const [settingsList, setSettingsList] = useState([]);
    const [show, setShow] = useState(false);
    const [settingsParams, setSettingsParams] = useState(new SettingsModel());
  
    useEffect(() => {
      getAll();
    }, []);
  
    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);
  
    const getAll = async () => {
      let response = await client.getAll();
      let handledResponse = HandlingResponse(response);
      handledResponse && setSettingsList(handledResponse);
    };
  
    const getParamsForEdit = async (data) => {
      setSettingsParams(data);
      showModal();
    };
  
    const handleAddingDataToTable = (childData) => {
      hideModal();
        let settingsListCopy = [...settingsList];
        let index = settingsListCopy.findIndex((x) => x.id === childData.id);
        settingsListCopy[index] = childData;
        setSettingsList(settingsListCopy);
    };
  
    return (
      <>
        <div>
          <h5>Подешавања</h5>
          {settingsList.length > 0 && (
            <Table
              columns={columns}
              data={settingsList}
              rememberFilter={true}
              tableName={tableName}
              hiddenColumn={["id"]}
              serverSide={false}
            />
          )}
        </div>
        <SideModal
          show={show}
          handleClose={hideModal}
          title={"Измена параметара"}
        >
          <EditSettings data={settingsParams} handleCallback={handleAddingDataToTable} />
        </SideModal>
      </>
    );
}

export default Settings