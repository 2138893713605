import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_REG;

class AvailableTabClient {
    async getAll(isBackground) {
        try {
            var response = await api.get(baseurl + "/AvailableTab/GetAll", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default AvailableTabClient;