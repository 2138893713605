import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class ProcedureKindClient {
  async ProcedureKind(isBackground) {
    try {
      var response = await api.get(baseurl + "/ProcedureKind", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllActiveProcedureKinds(isBackground) {
    try {
      var response = await api.get(baseurl + "/ProcedureKind/GetActiveProcedureKinds", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateProcedureKind(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/ProcedureKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateProcedureKind(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/ProcedureKind/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async DeleteProcedureKind(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/ProcedureKind?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ProcedureKindClient;