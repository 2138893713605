import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  margin: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 5px 9px -4px rgb(0 0 0 / 22%);
 
`;


