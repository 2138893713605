import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AdvertisementAndActTypeClient from "../../../api-services/advertisement-and-act-type/advertisement-and-act-type-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAdvertisementAndActType from "../../../components/create-advertisement-and-act-type/CreateAdvertisementAndActType.component";
import AdvertisementAndActTypeModel from "../../../../ADMIN-PANEL/models/AdvertisementAndActType";

const AdvertisementAndActType = () => {
    const tableName = "PPP_ADVERTISEMENT_AND_ACT_TYPE";

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState("");
    const [rowData, setRowData] = useState(new AdvertisementAndActTypeModel());
    const [allAdvertisementAndActType, setAllAdvertisementAndActType] = useState([]);

    const client = new AdvertisementAndActTypeClient();

    const columns = [
        {
            Header: t("Id"),
            accessor: "id",
            disableFilters: false,
          },
          {
            Header: t("Description"),
            accessor: "description",
            Cell: ({ value }) => (
              <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
            ),
            disableFilters: false,
          },
          {
            Header: t("DefaultSort"),
            accessor: "defaultSort",
            Cell: ({ value }) => (
              <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
            ),
            disableFilters: false,
          },
          {
            Header: t("Active"),
            accessor: "active",
            Cell: ({ row }) => {
              return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
            },
          },
          {
            accessor: "edit",
            disableSortBy: true,
            Cell: ({ row }) => {
              return (
                <button
                  className="btn btn-primary table-btn"
                  onClick={() => onEditClick(row.original)}
                >
                  <FontAwesomeIcon icon={solid("pen-to-square")} />
                </button>
              );
            },
          },
    ]

    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);

    const getAllAdvertisementAndActType = async () => {
        const response = await client.getAllAdvertisementAndActType();
        const handledResponse = HandlingResponse(response);
        if (handledResponse !== "Error") setAllAdvertisementAndActType(handledResponse);
    }

    useEffect(() => {
        getAllAdvertisementAndActType();
    }, [])


    const onCreateClick = () => {
        setIsEdit(false);
        showModal();
        setTitle(t("CreatingAKindOfLegalAct"));
        setRowData(new AdvertisementAndActTypeModel());
    }
    
    const onEditClick = (row) => {
        setIsEdit(true);
        showModal();
        setTitle(t("EditingAKindOfLegalAct"));
        setRowData(row);
    }

    return (
        <>
          <div className="form-control">
            <div className="form-group">
              <h5 className="col-md-2">{t("KindsOfLegalActs")}</h5>
              <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
                {t("AddKindOfLegalAct")}
              </button>
            </div>
    
            <Table
              columns={columns}
              data={allAdvertisementAndActType}
              rememberFilter={true}
              tableName={tableName}
              serverSide={false}
            />

            <SideModal show={show} handleClose={hideModal} title={title}>
                <CreateAdvertisementAndActType
                    row={rowData}
                    isEdit={isEdit}
                    handleCallback={() => {
                      hideModal();
                      getAllAdvertisementAndActType();
                    }}
                />
            </SideModal>
          </div>
        </>
      );
};

export default AdvertisementAndActType;