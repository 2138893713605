import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import SideModal from "../../../common/modal/SideModal.component";
import CreateAdvertisementCategory from "../create-advertisement-category/createAdvertisementCategory.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AdvertisementCategoryModel from "../../models/AdvertisementCategory";
import { useTranslation } from "react-i18next";

const AdvertisementSubcategory = ({ dataSubcategory, categoryInfo }) => {
  const { t } = useTranslation();
  const tableName = "AP_ADVERTISEMENT_SUBCATEGORY";

  const columns = [
    {
      Header: t("Id"),
      accessor: "id",
      disableFilters: false,
    },
    {
      Header: t("Description"),
      accessor: "description",
      disableFilters: false,
    },
    {
      Header: t("DefaultSort"),
      accessor: "defaultSort",
      disableFilters: false,
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AdvertisementCategoryModel());
  const [allAdvertisementSubcategories, setAllAdvertisementSubcategories] =
    useState([]);
  const [title, setTitle] = useState("");

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AdvertisementCategoryModel());
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    if (!isEdit)
      setAllAdvertisementSubcategories([
        ...allAdvertisementSubcategories,
        childData,
      ]);
    else {
      let allAdvertisementSubcategoriesCopy = [
        ...allAdvertisementSubcategories,
      ];
      let index = allAdvertisementSubcategoriesCopy.findIndex(
        (x) => x.id === childData.id
      );
      allAdvertisementSubcategoriesCopy[index] = childData;
      setAllAdvertisementSubcategories(allAdvertisementSubcategoriesCopy);
    }
  };

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData({
      parentId: categoryInfo,
      defaultSort: "",
      extCode: "",
      description: "",
      isForOg: true,
      isForReg: false,
      isLaw: false,
      active: true,
    });
    showModal();
    setTitle(t("CreatingAdvertisementSubcategory"));
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingAdvertisementSubcategory"));
  };

  useEffect(() => {
    setAllAdvertisementSubcategories(dataSubcategory);
  }, [dataSubcategory]);

  return (
    <>
      <div className="form-control">
        <div className="form-group row">
          <h5 className="col-md-2">{t("AdvertisementSubcategory")}</h5>
          <button className="btn btn-primary col-md-1" onClick={onCreateClick}>
            {t("AddAdvertisementSubcategory")}
          </button>
        </div>

        <Table
          columns={columns}
          data={allAdvertisementSubcategories}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
        />
      </div>

      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAdvertisementCategory
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default AdvertisementSubcategory;
