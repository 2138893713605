import React, { useEffect, useState } from "react";
import useUpdateLawAct from "../../hooks/useUpdateLawAct";
import { useTranslation } from "react-i18next";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import SettingsClient from "../../api-services/settings/settings-client";

const TabOtherData = () => {
  const { data, handleChangeInput, handleChangeDate, handleChangeList } =
    useUpdateLawAct();
  const { t } = useTranslation();

  const model = "lawActOtherDataModel";

  const settingsClient = new SettingsClient();

  const [titleClassList, setTitleClassList] = useState([]);
  const [desctiptionClassList, setDescriptionClassList] = useState([]);
  const [superscriptClassList, setSuperscriptClassList] = useState([]);
  const [subscriptClassList, setSubscriptClassList] = useState([]);

  useEffect(() => {
    fillDescriptionList();
    fillTitleList();
    fillSubscriptList();
    fillSuperscriptList();
  }, []);

  const fillDescriptionList = async () => {
    let response = await settingsClient.getByParameterKey(
      "ABSTRACT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let data = handledResponse.map((element) => {
        return {
          value: element,
          label: element,
          name: "cssDescriptionClass",
          model: model,
        };
      });
      setDescriptionClassList(data);
    }
  };

  const fillTitleList = async () => {
    let response = await settingsClient.getByParameterKey("TITLE_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let data = handledResponse.map((element) => {
        return {
          value: element,
          label: element,
          name: "cssTitleClass",
          model: model,
        };
      });
      setTitleClassList(data);
    }
  };

  const fillSubscriptList = async () => {
    let response = await settingsClient.getByParameterKey(
      "SUBSCRIPT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let data = handledResponse.map((element) => {
        return {
          value: element,
          label: element,
          name: "cssSubscriptClass",
          model: model,
        };
      });
      setSubscriptClassList(data);
    }
  };

  const fillSuperscriptList = async () => {
    let response = await settingsClient.getByParameterKey(
      "SUPERSCRIPT_CSS_CLASSES"
    );
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let data = handledResponse.map((element) => {
        return {
          value: element,
          label: element,
          name: "cssSuperscriptClass",
          model: model,
        };
      });
      setSuperscriptClassList(data);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <label>Уредничка напомена</label>
          <textarea
            className="form-control"
            type="text"
            name="editorMark"
            data-model={model}
            id="editorMark"
            value={data.lawActOtherDataModel.editorMark}
            onChange={(e) => {
              handleChangeInput(e);
              data.lawActOtherDataModel.editorMark == "" &&
                handleChangeDate(null, "editorMarkDeadlineDate", model);
            }}
          />
        </div>
        <div className="col-3">
          <label>Датум уредничке напомене</label>
          <CustomDatePicker
            selected={
              data.lawActOtherDataModel.editorMarkDeadlineDate
                ? new Date(data.lawActOtherDataModel.editorMarkDeadlineDate)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "editorMarkDeadlineDate", model)
            }
            disabled={data.lawActOtherDataModel.editorMark ? false : true}
          />
        </div>
      </div>
      <br />
      <div>
        <label>Кључне речи</label>
        <input
          className="form-control"
          id="keywords"
          name="keywords"
          data-model={model}
          type="text"
          value={data.lawActOtherDataModel.keywords}
          onChange={handleChangeInput}
        />
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <label>Стил наднапомене</label>
          <Select
            options={superscriptClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSuperscriptClass", value: null, model: model})}
            value={superscriptClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssSuperscriptClass
            )}
            isClearable
          />
          <label>Стил наслова</label>
          <Select
            options={titleClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssTitleClass", value: null, model: model})}
            value={titleClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssTitleClass
            )}
            isClearable
          />
        </div>
        <div className="col-6">
          <label>Стил описа</label>
          <Select
            options={desctiptionClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssDescriptionClass", value: null, model: model})}
            value={desctiptionClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssDescriptionClass
            )}
            isClearable
          />
          <label>Стил поднапомене</label>
          <Select
            options={subscriptClassList}
            onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSubscriptClass", value: null, model: model})}
            value={subscriptClassList.filter(
              (x) => x.value === data.lawActOtherDataModel.cssSubscriptClass
            )}
            isClearable
          />
        </div>
      </div>
    </>
  );
};

export default TabOtherData;
