import React, { useState } from "react";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import DialogModal from "../../../common/modal/Modal.component";
import CreateInternalAct from "../create-internal-act/CreateInternalAct";

const ButtonsForAddingActs = ({ handleCallback }) => {
    const { setIsCreateAct } = UseRenderDifferentComponent();

  const [show, setShow] = useState(false);
  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const handleCreateActClick = () => {
    setIsCreateAct(true);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginLeft: "5px" }}
        onClick={handleCreateActClick}
      >
        Креирај акт
      </button>
      <button
        type="button"
        style={{ marginLeft: "5px" }}
        className="btn btn-primary"
        onClick={showModal}
      >
        Додавање интерног акта
      </button>
      <DialogModal
        show={show}
        onHide={hideModal}
        title="Додавање интерног акта"
      >
        <CreateInternalAct
          handleClose={hideModal}
          getActs={handleCallback}
        />
      </DialogModal>
    </>
  );
};

export default ButtonsForAddingActs;
