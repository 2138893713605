import React, { useEffect } from "react";
import { MainContainer } from "./Layout.style";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";

const AdminPanelLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();

  useEffect(() => {
    setAppName("УПРАВЉАЊЕ ШИФАРНИЦИМА");
    setAppPath("/adminPanel");
  }, []);
  return (
    <>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </>
  );
};

export default AdminPanelLayout;
