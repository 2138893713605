import { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import PackageClient from "../../../LOZA/api-services/create-packages/create-packages-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import { handleSave, serverValidation, validateForm, } from "../../../common/validation/validation";
import Joi from "joi-browser";
import CategoryClient from "../../api-services/category/category-client";

const CreateCategory = ({ row, isEdit, handleCallback }) => {

  const [errors, setErrors] = useState({})
  const [packagesList, setPackagesList] = useState([]);

  const [category, setCategory] = useState({
    id: 0,
    name: "",
    description: "",
    weight: 0,
    packageGuid: "",
    active: false,
    visible: false,
    forGuests: false
  })

  const schema = {
    name: Joi.string().max(250).required(),
    description: Joi.string().max(2000).required(),
    weight: Joi.number().min(0).max(2147483647).required(),
  };

  const { t } = useTranslation();
  const packageClient = new PackageClient();
  const categeryClient = new CategoryClient();

  useEffect(() => {
    setCategory(row);
    setErrors({})
  }, [row]);

  const getPackages = async () => {
    let response = await packageClient.GetPackages();
    let res = HandlingResponse(response);
    var result = res.map((x) => {
      return { value: x.guid, label: x.shortDescription };
    });
    setPackagesList(result);
  }

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, category, errors, schema, t);
    setCategory(result.data);
    setErrors(result.errors);
  };

  useEffect(() => {
    getPackages();
  }, [])

  const CreateCategory = async () => {
    const errorResult = validateForm(category, schema, t);
    setErrors(errorResult);

    if (!errorResult) {
      if (isEdit)
        var response = await categeryClient.UpdateCategory(category);
      else
        var response = await categeryClient.CreateCategory(category);
      if (response !== "Error")
        var handledResponse = HandlingResponse(response);
      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
      else
        handleCallback(handledResponse);
    }
  };

    return(
        <>
        <div className="col-md">
        <label>Назив<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="name"
          value={category.name}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.name && (
          <div className="text-danger">{errors.name}</div>
        )}
        <label>Опис<span className="required-asterisk">*</span></label>
        <textarea
          className="form-control"
          type="text"
          name="description"
          value={category.description}
          onChange={(event) => handlePropertyChange(event.target)}
        />
        {errors?.description && (
          <div className="text-danger">{errors.description}</div>
        )}
      <label>Тежина</label>
      <input
        className="form-control"
        type="number"
        name="weight"
        value={category.weight}
        onChange={(event) => handlePropertyChange(event.target)}       
      />
      {errors?.weight && <div className="text-danger">{errors.weight}</div>}
      <label>Пакет</label>
        <Select
          options={packagesList}
          onChange={(event) =>
            setCategory({ ...category, packageGuid: event ? event.value : "" })
          }
          value={packagesList?.filter((x) => x.value === category.packageGuid)}
          isClearable
        />
        <input
          id="activeNL"
          type="checkbox"
          value={category.active}
          checked={category.active}
          onChange={(event) => {
            setCategory({ ...category, active: event.target.checked });
          }}
        />
        <label htmlFor="activeNL" style={{ marginRight: "15px" }}>Активна</label>
        <input
          id="visibleNL"
          type="checkbox"
          value={category.visible}
          checked={category.visible}
          onChange={(event) => {
            setCategory({ ...category, visible: event.target.checked });
          }}
        />
        <label htmlFor="visibleNL" style={{ marginRight: "15px" }}>Видљива</label>
        <input
          id="forGuests"
          type="checkbox"
          value={category.forGuests}
          checked={category.forGuests}
          onChange={(event) => {
            setCategory({ ...category, forGuests: event.target.checked });
          }}
        />
        <label htmlFor="forGuests">За госте</label>
        <br />

    
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          onClick={() => CreateCategory()}
        >
          Сачувај
        </button>
      </div>
    </>
    )
}

export default CreateCategory;