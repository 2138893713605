import Joi from "joi-browser";

class PackageModel {
    constructor(id = 0, packageName = "", published = false, isInactive = null) {
        this.id = id;
        this.packageName = packageName;
        this.published = published;
        this.isInactive = isInactive;
    }
}

const packageSchema = {
    packageName: Joi.string().required()
}

export { PackageModel, packageSchema }