import React, { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SourceClient from "../../api-services/sources/source-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateSource from "../create-source/createSource.component";
import { SourceModel } from "../../models/Source";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const Source = ({ filter, allSource, setAllSource, sourceKind }) => {
  const { t } = useTranslation();
  const tableName = 'MML_SOURCE';
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
      tableName: tableName
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Измени"
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => EditSource(row.original)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Врста извора ће бити трајно обрисана."}
            handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  var client = new SourceClient();
  const [visible, setVisible] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const [source, setSource] = useState(new SourceModel());
  const [allSources, setAllSources] = useState([]);

  useEffect(() => {
    setAllSources(allSource);
    setVisible(true);
  }, [allSource]);

const GetSourceBySourceKindId = async ()=> {
  let response = await client.getActiveSourceBySourceKindId(sourceKind.id);
  let handledResponse = HandlingResponse(response);
  handledResponse && setAllSource(handledResponse);
}

  const onCreateSource = () => {
    setVisible(false);
    setIsEdit(false);
    setSource(new SourceModel(0, sourceKind.id, "", 0, true))
  };

  const EditSource = (row) => {
    setIsEdit(true);
    setVisible(false);
    setSource(row);
  };

  const onDeleteClick = async (id) => {
    let response = await client.deleteSource(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let allSourcesCopy = allSource.filter((x) => x.id !== id);
      setAllSource(allSourcesCopy);
    }
  };

  return (
    <>
      <div>
        <h5>
          {t("SourceKinds")} : {sourceKind.name}
        </h5>
        <br />
        <Table
          rememberFilter={filter}
          tableName={tableName}
          columns={columns}
          data={allSources}
          hiddenColumn={["id"]}
          serverSide={false}
        />
        {visible ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={onCreateSource}
          >
            {t("Add")}
          </button>
        ) : (
          <CreateSource
            row={source}
            isEdit={isEdit}
            handleCallback={() => {
              setVisible(true);
              GetSourceBySourceKindId();
            }}
          />
        )}
      </div>
    </>
  );
};

export default Source;
