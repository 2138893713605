import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DialogModal from "../../../common/modal/Modal.component";
import CreateInternalAct from "../create-internal-act/CreateInternalAct";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import {
  GreenTransparentButton,
  RedTransparentButton,
} from "../../../global-styles/GlobalStyles.style";
import ActClient from "../../api-services/acts/act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { levelEnum } from "../../contexts/RenderDifferentComponentContext";
import { SelectColumnFilter } from "../../../common/table/filterHelpFunction.component";
import EditActs from "../edit-acts/EditActs.component";
import { FormProvider } from "../../contexts/InternalFormContext";
import { PreviewInternalActModel } from "../../models/Act";
import SideModal from "../../../common/modal/SideModal.component";
import GlobalActReferences from "../global-act-references/globalActReferences.component";
import AreaClient from "../../api-services/areas/area-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import IndexingStatus, { StatusForIndexing } from "../../../common/indexing-status/IndexingStatus";
import InternalActClient from "../../api-services/internal-act/internal-act-client";
import UserService from "../../../utils/authentification/keycloak";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole"
import { checkIfAnyLocked } from "../../../utils/common-functions/CommonFunctions";

const Acts = ({ acts, setActs, handleCallback, level, tableName }) => {
  const filter = {
    filterValue: "equals",
    selectOptions: [
      {
        value: undefined,
        label: "Сви",
      },
      {
        value: "false",
        label: "Неактиван",
      },
      {
        value: "true",
        label: "Активан",
      },
    ],
  };
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "orderBy",
      accessor: "orderBy",
    },
    {
      Header: "Назив акта",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тип акта",
      accessor: "actType",
      disableFilters: false,
    },
    {
      Header: "Статус",
      accessor: "published",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return <div>{row.original.published ? "Активан" : "Неактиван"}</div>;
      },
    },
    {
      accessor: "actions",
      disableSortBy: true,
      className: "eight-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => relatedActs(row.original)}
              title="Повезани прописи"
              type="button"
            >
              <FontAwesomeIcon icon={solid("list")} />
            </button>
            {row.original.highlighted ? (
              <RedTransparentButton
                disabled={!row.original.published}
                title={
                  row.original.published
                    ? "Уклони из листе најновијих"
                    : "Није дозвољено додавање/уклањање у листу најновијих када је акт неактиван"
                }
                type="button"
                className="btn table-btn"
                onClick={() =>
                  addOrRemoveFromFavoriteList(
                    row.original.id,
                    !row.original.highlighted
                  )
                }
              >
                <FontAwesomeIcon icon={solid("star")} />
              </RedTransparentButton>
            ) : (
              <GreenTransparentButton
                disabled={!row.original.published}
                title={
                  row.original.published
                    ? "Додај у листу најновијих"
                    : "Није дозвољено додавање/уклањање у листу најновијих када је акт неактиван"
                }
                type="button"
                className="btn table-btn"
                onClick={() =>
                  addOrRemoveFromFavoriteList(
                    row.original.id,
                    !row.original.highlighted
                  )
                }
              >
                <FontAwesomeIcon icon={solid("star")} />
              </GreenTransparentButton>
            )}
            <RenderOnRole roles={["PPP_Admin", "PPP_Urednik"]}>
              {row.original.published ? (
                <RedTransparentButton
                  title="Деактивирај"
                  type="button"
                  className="btn table-btn"
                  onClick={() =>
                    activateOrDeactivateAct(
                      row.original.id,
                      !row.original.published
                    )
                  }
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </RedTransparentButton>
              ) : (
                <GreenTransparentButton
                  title="Активирај"
                  type="button"
                  className="btn table-btn"
                  onClick={() =>
                    activateOrDeactivateAct(
                      row.original.id,
                      !row.original.published
                    )
                  }
                >
                  <FontAwesomeIcon icon={solid("check")} />
                </GreenTransparentButton>
              )}
            </RenderOnRole>
            <ButtonWithComfirmation
              disabled={row.original.published}
              title={
                row.original.published
                  ? "Брисање објављеног акта није могуће"
                  : "Обриши"
              }
              className="btn btn-danger table-btn"
              contentText={"Акт ће бити трајно обрисан."}
              handleYesText={"Обриши"}
              onClick={() => deleteAct(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithComfirmation>
            {!showListOfSubacts && (
              <button
                className="btn btn-primary table-btn"
                onClick={() => addSubact(row.original.id)}
                title="Додај подакт"
                type="button"
              >
                <FontAwesomeIcon icon={solid("layer-group")} />
              </button>
            )}
            {!showListOfSubacts && (
              <button
                className="btn btn-primary table-btn"
                onClick={() => addInternalSubact(row.original.id)}
                title="Додај интерни подакт"
                type="button"
              >
                <FontAwesomeIcon icon={solid("plus")} />
              </button>
            )}
            {row.original.internalActId && (
              <button
                className="btn btn-primary table-btn"
                onClick={() => editInternalAct(row.original.id)}
                title="Измени"
                type="button"
              >
                <FontAwesomeIcon icon={solid("edit")} />
              </button>
            )}
          </>
        );
      },
    },
    {
      Header: "Подакти",
      accessor: "hasSubacts",
      Cell: ({ row }) => {
        return row.original.hasSubacts ? (
          <button
            className="btn btn-primary table-btn"
            onClick={() => showSubacts(row.original.name, row.original.id)}
            title="Прикажи подакте"
            type="button"
          >
            <FontAwesomeIcon icon={solid("magnifying-glass")} />
          </button>
        ) : (
          "Нема"
        );
      },
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "addIndex",
      className: "one-button-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title={
                row.original.published
                  ? "Индексирај"
                  : "Индексирање необјављеног документа није дозвољено"
              }
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexAct(row.original.id)}
              disabled={
                !row.original.published ||
                !StatusForIndexing.includes(row.original.indexStatus)
              }
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];

  if (checkIfAnyLocked(acts)) {
    columns.unshift({
      Header: "",
      accessor: "locked",
      Cell: ({ row }) => {
        let className =
          row.original.userGuid !== UserService.getUserGuid()
            ? "locked-icon"
            : "locked-icon-by-myself";
        return (
          <>
            {row.original.locked ? (
              <FontAwesomeIcon
                title={`Интерни акт је закључаo ${row.original.userFullName}`}
                className={className}
                icon={solid("lock")}
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    });
  }

  const {
    handleChangeComponent,
    currentLocation,
    setIsListEmpty,
    setIsCreateAct,
    setParentIdForSubact,
    showListOfSubacts,
    setShowListOfSubacts,
    getCurrentLocation,
  } = UseRenderDifferentComponent();

  const actClient = new ActClient();
  const areaClient = new AreaClient();
  const internalActClient = new InternalActClient();

  const [globalActReferences, setGlobalActReferences] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [enableSort, setEnableSort] = useState(false);

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [internalActInfo, setInternalActInfo] = useState(
    new PreviewInternalActModel()
  );

  useEffect(() => {
    if (!showListOfSubacts)
      //dodat timeout zbog toga sto se loader ne prikazuje prilikom refresh-a
      setTimeout(() => {
        getCurrentActs();
      }, 100);
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setParentIdForSubact(null);
  };

  const showModalForEdit = () => setShowModalEdit(true);
  const hideModalForEdit = () => {
    setShowModalEdit(false);
    getCurrentActs();
  };

  const showSideModal = () => setShowSide(true);
  const hideSideModal = () => setShowSide(false);

  const handleCreateActClick = () => {
    setIsCreateAct(true);
  };

  const relatedActs = (row) => {
    showSideModal();
    setRowId(row.id);
    setTitle("Додавање повезаних аката");
    getGlobalActReferencesByAcId(row.id);
  };

  const addOrRemoveFromFavoriteList = async (id, highlighted) => {
    if (!highlighted) {
      response = await actClient.removeHighlightFromAct(id);
    } else {
      var response = await actClient.highlightAct(id);
    }
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      const updatedListOfActs = acts.map((act) => {
        if (act.id === id) {
          return { ...act, highlighted };
        }
        return act;
      });
      setActs(updatedListOfActs);
    }
  };

  const deleteAct = async (id) => {
    var response = await actClient.deleteActById(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      setTimeout(() => {
        getCurrentActs();
      }, 1000);
    }
  };

  const getCurrentActs = async () => {
    if (level === levelEnum.Subact) {
      let response = await actClient.getActsByParentId(currentLocation.id);
      var handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        setActs(handledResponse);
        setShowListOfSubacts(true);
      }
    } else {
      var response = await areaClient.getAreaChildrenById(currentLocation.id);
      var handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        if (handledResponse.length > 0) {
          //izvlacenje akata bez podakata
          let actsList = handledResponse.filter((el) => el.parentId === null);
          //nalazenje parentId-a podakata
          const elementWithParentId = handledResponse
            .filter((act) => act.parentId !== null)
            .map((el) => el.parentId);
          //dodavanje flega aktima koji imaju podakte
          const actListWithFlag = actsList.map((act) => {
            return { ...act, hasSubacts: elementWithParentId.includes(act.id) };
          });
          actListWithFlag && setActs(actListWithFlag);
        } else {
          setIsListEmpty(true);
          setActs([]);
          const object = getCurrentLocation();
          handleChangeComponent(object.level, object.id, object.name);
        } 
      }
    }
  };

  const addInternalSubact = (id) => {
    showModal();
    setParentIdForSubact(id);
  };

  const addSubact = (id) => {
    handleCreateActClick();
    setParentIdForSubact(id);
  };

  const editInternalAct = async (id) => {
    showModalForEdit();

    var response = await internalActClient.getInternalAct(id);
    var handledResponse = HandlingResponse(response);
    setInternalActInfo({
      internalActBasicData: handledResponse.internalActBasicData,
      internalActReference: handledResponse.internalActReference,
      internalActOtherData: handledResponse.internalActOtherData,
      internalActChangesHistory: handledResponse.internalActChangesHistory,
      file: handledResponse.file,
      files: handledResponse.files,
      pictures: handledResponse.pictures,
    });
  };

  const showSubacts = async (name, id) => {
    let response = await actClient.getActsByParentId(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != "Error") {
      setShowListOfSubacts(true);
      handleChangeComponent(levelEnum.Subact, id, name);
      await handleCallback(handledResponse, true);
    }
  };

  const getGlobalActReferencesByAcId = async (id) => {
    const response = await actClient.GetGlobalActReferencesByAcId(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse != "Error") {
      setGlobalActReferences(handledResponse);
    }
  };

  const activateOrDeactivateAct = async (id, published) => {
    let response;
    if (published === false) {
      response = await actClient.deactivateAct(id);
    } else {
      response = await actClient.activateAct(id);
    }

    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      const updatedListOfActs = acts.map((act) => {
        if (act.id === id) {
          return { ...act, published };
        }
        return act;
      });
      setActs(updatedListOfActs);
    }
  };

  const enableSorting = () => {
    setEnableSort(true);
  };

  const saveSorting = async () => {
    var listOfActCopy = [...acts];
    listOfActCopy.forEach((x, i) => {
      x.orderBy = i;
    });
    let response = await actClient.orderActs(listOfActCopy);
    let handledResponse = HandlingResponse(response);
    handledResponse != "Error" && setActs(handledResponse);

    setEnableSort(false);
  };

  const cancelSorting = async () => {
    setEnableSort(false);
    getCurrentActs();
  };

  //dodat je timeout da ne bi stajalo "indeksira se", pa da je potrebno osveziti stranicu da bi se video status
  //ovo je privremeno, potrebno je resiti globalno
  const indexAct = async (id) => {
    var response = await actClient.indexActById(id);
    var handledResponse = HandlingResponse(response);
    handledResponse &&
      setTimeout(() => {
        getCurrentActs();
      }, 500);
  };


  return (
    <>
      <div style={{ marginTop: "15px" }}>
        {!showListOfSubacts && (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateActClick}
            >
              Креирај акт
            </button>
            <button
              type="button"
              style={{ marginLeft: "5px" }}
              className="btn btn-primary"
              onClick={showModal}
            >
              Додавање интерног акта
            </button>
          </>
        )}
        {acts?.length > 0 && (
          <>
            <div className="text-right">
              {enableSort ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginRight: "5px" }}
                    onClick={cancelSorting}
                  >
                    Одустани од сортирања
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveSorting}
                  >
                    Сачувај сортирање
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={enableSorting}
                >
                  Омогући сортирање
                </button>
              )}
            </div>
            <br />
            <Table
              columns={columns}
              rememberFilter={true}
              tableName={tableName}
              hiddenColumn={["id", "orderBy"]}
              data={acts}
              setData={setActs}
              serverSide={false}
              rowSortable={enableSort}
            />
          </>
        )}
      </div>
      <SideModal show={showSide} handleClose={hideSideModal} title={title}>
        <GlobalActReferences
          globalActReferencesData={globalActReferences}
          setGlobalActReferencesData={setGlobalActReferences}
          rowId={rowId}
          hideSideModal={hideSideModal}
        />
      </SideModal>
      <DialogModal
        show={show}
        onHide={hideModal}
        title="Додавање интерног акта"
      >
        <CreateInternalAct handleClose={hideModal} getActs={getCurrentActs} />
      </DialogModal>
      <SideModal
        show={showModalEdit}
        handleClose={hideModalForEdit}
        title="Измена интерног акта"
      >
        <FormProvider>
          <EditActs
            handleClose={hideModalForEdit}
            internalActInfo={internalActInfo}
          />
        </FormProvider>
      </SideModal>
    </>
  );
};

export default Acts;
