export const listOfAdminPortalApps = [
    {
        name: "Мишљења, модели и литература",
        path: "/mml",
        roles: ['MML_Urednik', 'MML_Sifarnici', 'MML_Admin']
    },
    {
        name: "Судска пракса",
        path: "/sp",
        roles: ['SP_Urednik', 'SP_Sifarnici', 'SP_Admin']
    },
    {
        name: "Управљање корисницима",
        path: "/loza",
        roles: ['Admin_Prodaja', 'Prodaja', 'Admin_Interni', 'Interni_Korisnik']
    },
    {
        name: "Управљање шифарницима",
        path: "/adminPanel",
        roles: ['SLGL_REG_Sifarnici']
    },
    {
        name: "Уређивач текста",
        path: "/textEditor",
        roles: []
    },
    {
        name: "Службени гласник",
        path: "/slgl",
        roles: ['SLGL_Admin', 'SLGL_Urednik', 'SLGL_Arhiva']
    },
    {
        name: "Регистри",
        path: "/registries",
        roles: ['REG_Admin', 'REG_Urednik']
    },
    {
        name: "Насловна страна",
        path: "/frontpage",
        roles: ['FP_Admin']
    },
    {
        name: "Newsletter",
        path: "/newsletter",
        roles: ["Newsletter_Admin"]
    },
    {
        name: "Неважеће исправе",
        path: "/mo",
        roles: ['SLGL_MO_Admin', 'SLGL_MO_Servis', 'SLGL_MO_Pretraga']
    },
    {
        name: "Посебни програмски пакети",
        path: "/ppp",
        roles: ["PPP_Admin", "PPP_Urednik", "PPP_Sifarnici"]
    },
    {
        name: "Прописи на енглеском",
        path: "/peng",
        roles: [ "PEng_Urednik", "PEng_Prodaja"]
    },
    {
        name: "Прописи градова",
        path: "/regulations",
        roles: ["Regulations_Admin", "Regulations_Urednik", "Regulations_Sifarnici"]
    }
]