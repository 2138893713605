class InternalUsersModel {
    constructor(
        name = "",
        lastName = "",
        userName = "",
        address = "",
        company = "",
        email = ""
    ) {
        this.name = name;
        this.lastName = lastName;
        this.userName = userName;
        this.address = address;
        this.company = company;
        this.email = email;
    }
}

class SubscribersModel {
    constructor(
        name = "",
        lastName = "",
        userName = "",
        address = "",
        company = "",
        telephone = "",
        email = "",
        legalEntityName = "",
        pib= "",
        legalAddress= ""
    ) {
        this.name = name;
        this.lastName = lastName;
        this.userName = userName;
        this.address = address;
        this.telephone = telephone;
        this.company = company;
        this.email = email;
        this.legalEntityName = legalEntityName;
        this.pib = pib;
        this.legalAddress = legalAddress;
    }
}

export { InternalUsersModel, SubscribersModel }