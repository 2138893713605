import DatePicker from "react-datepicker";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker.component";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GroupInputs, Span } from "./SearchSmallAdds.style";

const Search = ({ filterData, setFilterData, searchData, resetFilters }) => {
  const { t } = useTranslation();
  const client = new SmallAddsClient();

  const [documentKindList, setDocumentKindList] = useState([]);
  const [documentSubkindList, setDocumentSubkindList] = useState([]);

  useEffect(() => {
    getDocumentKinds();
  }, []);

  useEffect(() => {
    filterData.documentKindId && getDocumentSubkinds(filterData.documentKindId);
  }, [filterData.documentKindId])

  const getDocumentKinds = async () => {
    let response = await client.GetDocumentKinds(true);
    let result = HandlingResponse(response);
    let array = result.map((item) => {
      return { value: item.id, label: item.name };
    });
    setDocumentKindList(array);
  };

  const getDocumentSubkinds = async (kindId) => {
    let response = await client.GetDocumentSubkinds(kindId, true);
    let result = HandlingResponse(response);
    let array = result.map((item) => {
      return { value: item.id, label: item.name };
    });
    setDocumentSubkindList(array);
  };

  return (
    <form onSubmit={searchData}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Број огласа</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={filterData.adNumber}
              onChange={(event) =>
                setFilterData({
                  ...filterData,
                  adNumber: event.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label>Број/година гласила</label>
            <div className="input-group">
              <GroupInputs>
                <input
                  className="form-control"
                  type="number"
                  name="displayText"
                  value={filterData.ogNumber}
                  onChange={(event) =>
                    setFilterData({
                      ...filterData,
                      ogNumber: event.target.value,
                    })
                  }
                />
              </GroupInputs>
              <Span>/</Span>
              <GroupInputs>
                <DatePicker
                  className="form-control"
                  selected={
                    filterData.ogYear
                      ? new Date(filterData.ogYear.toString())
                      : null
                  }
                  onChange={(date) => {
                    date === null &&
                      document
                        .querySelector('button[type="submit"]')
                        ?.focus({ preventScroll: true });

                    setFilterData({
                      ...filterData,
                      ogYear: date?.getFullYear().toString(),
                    });
                  }
                  }
                  onCalendarClose={() =>
                    document.querySelector('button[type="submit"]')?.focus({preventScroll: true})
                  }
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={10}
                  isClearable
                />
              </GroupInputs>
            </div>
          </div>
          <div className="form-group">
            <label>Текст огласа</label>
            <textarea
              className="form-control"
              type="text"
              name="displayText"
              value={filterData.adText}
              onChange={(event) =>
                setFilterData({
                  ...filterData,
                  adText: event.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Врста документа</label>
            <Select
              options={documentKindList}
              value={documentKindList?.filter(
                (x) => x.value === filterData.documentKindId
              )}
              onChange={(event) => {
                setFilterData({
                  ...filterData,
                  documentKindId: event ? event.value : null,
                });
              }}
              isClearable
            />
          </div>
          <div className="form-group">
            <label>Подврста документа</label>
            <Select
              options={documentSubkindList}
              value={documentSubkindList?.filter(
                (x) => x.value === filterData.documentSubkindId
              )}
              onChange={(event) =>
                setFilterData({
                  ...filterData,
                  documentSubkindId: event ? event.value : null,
                })
              }
              isClearable
            />
          </div>
          <div className="form-group">
          <label>Корисник</label>
            <input
              className="form-control"
              type="text"
              name="displayText"
              value={filterData.beneficiaryName}
              onChange={(event) =>
                setFilterData({
                  ...filterData,
                  beneficiaryName: event.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="text-right">
          <button className="btn btn-primary" type="submit">
            Претрага
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              resetFilters();
            }}
          >
            Поништи претрагу
          </button>
        </div>
      </div>
    </form>
  );
};

export default Search;
