import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class LegalAreaClient {
    async LegalArea(isBackground) {
      try {
        var response = await api.get(baseurl + "/LegalArea", {isBackground: isBackground});
        return response.data;
      }
      catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetAllActiveLegalArea(isBackground) {
      try {
        var response = await api.get(baseurl + "/LegalArea/GetActiveLegalAreas", {isBackground: isBackground});
        return response.data;
      }
      catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async CreateLegalArea(data, isBackground) {
      try {
        var response = await api.post(baseurl + "/LegalArea", data, {isBackground: isBackground});
        return response.data;
      }
      catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
    
      async UpdateLegalArea(id, data, isBackground) {
        try {
          var response = await api.put(baseurl + "/LegalArea/" + id, data, {isBackground: isBackground});
          return response.data;
        }
        catch (err) {
          if (err.response.status === 400) return err.response.data;
          else return err.response;
        }
      }

      async DeleteLegalArea(id, isBackground) {
        try {
          var response = await api.delete(baseurl + "/LegalArea?id=" + id, {isBackground: isBackground});
          return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
}

export default LegalAreaClient;