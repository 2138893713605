class AdvertisementCategoryModel {
     constructor(id = 0, defaultSort = "", description = "", isForReg = false, isForOg = true, isLaw = false, active = true, parentId = null){
        this.id = id;
        this.defaultSort = defaultSort;
        this.description = description;
        this.isForOg = isForOg;
        this.isForReg = isForReg;
        this.isLaw = isLaw;
        this.active = active;
        this.parentId = parentId;
    }
}

export default AdvertisementCategoryModel;