import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import DocumentTypeClient from "../../api-services/document-type/document-type-client";
import { TypesModel, typeSchema } from "../../models/Types";

const CreateType = ({ row, isEdit, handleCallback }) => {
  var { t } = useTranslation();

  const [type, setType] = useState(new TypesModel());
  const [errors, setErrors] = useState({});

  var client = new DocumentTypeClient();

  useEffect(() => {
    if (isEdit) setType(row);
    else {
      setType({
        name: "",
        parentName: row.name ? row.name : row.parentName,
        parentId: row.id ? row.id : row.parentId,
        weight: 0,
        active: true,
      });
    }
    setErrors({});
  }, [row, isEdit]);

  const createType = async (e) => {
    e.preventDefault();
    const errorsResult = validateForm(type, typeSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (isEdit) var response = await client.updateType(type);
      else var response = await client.createType(type);
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback(handledResponse);
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, type, errors, typeSchema, t);
    setType(result.data);
    setErrors(result.errors);
  };

  return (
    <form onSubmit={createType}>
      <div className="form-group">
        <div className="col-md">
          <label>
            {t("Name")}
            <span className="required-asterisk">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={type.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
          <label>{t("ParentName")}</label>
          <input
            disabled="disabled"
            className="form-control"
            type="text"
            name="parentName"
            value={type.parentName || ""}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          <label>{t("Weight")}</label>
          <input
            className="form-control"
            name="weight"
            type="text"
            value={type.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
          <input
            id="activeCB"
            type="checkbox"
            value={type.active}
            checked={type.active}
            onChange={(event) => {
              setType({ ...type, active: event.target.checked });
            }}
          />
          <label htmlFor="activeCB">{t("Active")}</label>
        </div>
        <div className="text-right">
          <button
            // type="button"
            className="btn btn-primary m-2"
            onClick={createType}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateType;
