import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateCourtType from "../../../components/vrste-sudova-component/create-vrste-sudova/CreateVrsteSudova.componenet";
import SideModal from "../../../../common/modal/SideModal.component";
import CourtKindClient from "../../../api-services/coders/court-types/court-types-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const CourtTypes = () => {
  const tableName = 'SP_COURT_TYPES';
  
  const columns = [
    {
      Header: "Врста суда",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn" 
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Врста суда ће бити трајно обрисана."}
              handleYesText={"Обриши"}
              >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  const [courtKind, setCourtKind] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: "",
    name: "",
    weight: 0,
    isActive:true,
    userGuid: "",
    lastModifyDate: "",
    userFullName: ""
  });

  const client = new CourtKindClient();
  
  useEffect(() => {
    CourtKind();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: "",
      name: "",
      weight: 0,
      isActive:true,
      userGuid: "",
      lastModifyDate: ""
    });
  }
  
  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: "",
      name: "",
      weight: 0,
      isActive:true,
      userGuid: "",
      lastModifyDate: ""
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteCourtKind(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let courtKindCopy = courtKind.filter((x) => x.id !== id);
      setCourtKind(courtKindCopy);
    }
  };

  const CourtKind = async () => {
    await client.CourtKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setCourtKind(handledResponse);
    });
  }

  return (
    <>
      <h4>Врсте судова</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нову врсту суда
        </button>
      </div>
      <Table
        columns={columns}
        data={courtKind}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Креирање врсте суда" : "Измена врсте суда"}
      >
        <CreateCourtType
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            CourtKind();
          }}
        />
      </SideModal>
    </>
  );
};

export default CourtTypes;
