import React from "react";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState, useEffect } from "react";
import CourtPracticeClient from "../../api-services/cases/cases-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { format } from "date-fns";
import SearchCase from "../../components/search-case/searchCase.component";
import { useNavigate } from "react-router-dom";
import IndexingStatus, {
  StatusForIndexing,
  StatusForDeleteIndex,
} from "../../../common/indexing-status/IndexingStatus";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import UserService from "../../../utils/authentification/keycloak";
import { CaseFilterModel } from "../../models/CaseFilter";
import { useTranslation } from "react-i18next";

const Cases = () => {
  const { t } = useTranslation();
  const tableName = "SP_CASES";
  const rememberFilter = true;

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "isLocked",
      Cell: ({ row }) => {
        let className =
          row.original.lockUserGuid !== UserService.getUserGuid()
            ? "locked-icon"
            : "locked-icon-by-myself";
        return (
          <>
            {row.original.isLocked ? (
              <FontAwesomeIcon
                title={`Документ је закључаo ${row.original.lockUserName}`}
                className={className}
                icon={solid("lock")}
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      Header: "Идентификатор",
      accessor: "internalLabelName",
    },
    {
      Header: "Објављен",
      accessor: "isApproved",
      Cell: ({ row }) => {
        return <>{row.original.isApproved ? "Да" : "Не"}</>;
      },
    },
    {
      Header: "Суд",
      accessor: "courtName",
    },
    {
      Header: "Ближе одређење акта",
      accessor: "actCloserDesignation",
    },
    {
      Header: "Датум усвајања правног схватања",
      accessor: "legalUnderstandingAdoptionDate",
      Cell: ({ row }) => {
        return row.original.legalUnderstandingAdoptionDate ? (
          <div>
            {format(
              new Date(row.original.legalUnderstandingAdoptionDate),
              "dd.MM.yyyy."
            )}
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      Header: "Правна материја",
      accessor: "legalMatterName",
    },
    {
      Header: "Ознака и број акта",
      accessor: "actLabelAndNumber",
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => GetCourtPracticeById(row.original.id)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            {!row.original.isApproved &&
              ((row.original.isLocked &&
                row.original.lockUserGuid === UserService.getUserGuid()) ||
                !row.original.isLocked) && (
                <ButtonWithComfirmation
                  className="btn btn-danger table-btn"
                  onClick={() => onDeleteClick(row.original.id)}
                  contentText={"Предмет ће бити трајно обрисан."}
                  handleYesText={"Обриши"}
                  title={"Трајно обриши"}
                >
                  <FontAwesomeIcon icon={solid("trash")} />
                </ButtonWithComfirmation>
              )}
          </>
        );
      },
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "addIndex",
      className: "one-button-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title={
                row.original.isApproved
                  ? "Индексирај"
                  : "Индексирање необјављеног предмета није дозвољено"
              }
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexData(row.original.id)}
              disabled={
                !row.original.isApproved ||
                !StatusForIndexing.includes(row.original.indexStatus)
              }
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const client = new CourtPracticeClient();

  const [courtPractice, setCourtPractice] = useState([]);
  const [courtPracticeFilter, setCourtPracticeFilter] = useState(
    new CaseFilterModel()
  );
  const [reset, setReset] = useState(false);

  useEffect(() => {
    reset && setReset(false);
  }, [courtPractice]);

  const onCreateClick = () => {
    navigate("/sp/case");
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteCourtPractice(id);
    var handledResponse = HandlingResponse(response);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("SP_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setCourtPracticeFilter(parsedObject);
    }
    var response = await client.FilterCourtPracites({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    });
    let data = HandlingResponse(response);
    return data;
  };

  const ResetFilter = async () => {
    setReset(true);
    sessionStorage.removeItem("SP_FILTER");
    setCourtPracticeFilter(new CaseFilterModel());

    var response = await client.FilterCourtPracites({});
    let data = HandlingResponse(response);
    setCourtPractice(data);
  };

  const searchCourtPractice = async (e) => {
    e.preventDefault();
    sessionStorage.setItem("SP_FILTER", JSON.stringify(courtPracticeFilter));
    const response = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setCourtPractice(response);
  };

  const GetCourtPracticeById = async (id) => {
    if (id) {
      await client.GetCourtPracticeById(id).then((response) => {
        var data = HandlingResponse(response);
        if (data !== "Error")
          navigate("/sp/case?caseId=" + id, { state: { data } });
      });
    }
  };

  const indexAll = async () => {
    var response = await client.massIndexing();
    HandlingResponse(response);
  };

  const indexData = async (id) => {
    var response = await client.indexCourtPractice(id);
    HandlingResponse(response);
  };

  const deleteIndex = async (id) => {
    var response = await client.deleteIndexCourtPractice(id);
    HandlingResponse(response);
  };

  return (
    <>
      <SearchCase
        courtPracticeFilter={courtPracticeFilter}
        setCourtPracticeFilter={setCourtPracticeFilter}
        handleCallback={searchCourtPractice}
        resetFilter={ResetFilter}
      ></SearchCase>
      <label>{t("countResult",{count: courtPractice.count, type: 'предмета'})}</label>
      <hr />
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додавање новог предмета
        </button>
        <RenderOnRole roles={["SP_Admin"]}>
          <ButtonWithComfirmation
            style={{ marginLeft: "5px" }}
            className="btn btn-primary"
            onClick={() => indexAll()}
            contentText={"Покренуће се индексирање свих предмета"}
            handleYesText={"Покрени"}
          >
            Масовно индексирање
          </ButtonWithComfirmation>
        </RenderOnRole>
      </div>
      <Table
        columns={columns}
        data={courtPractice}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={["id"]}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setCourtPractice}
        resetFilters={reset}
      />
    </>
  );
};

export default Cases;
