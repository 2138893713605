import React from "react";
import AreaClient from "../../api-services/area/area-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

function DeleteArea({ areaId, hide, handleCallback }) {
  const client = new AreaClient();

  const deleteArea = async () => {
    let response = await client.deleteArea(areaId);
    let handledResponse = HandlingResponse(response);
    handledResponse != 'Error' && handleCallback();
    hide();
  };
  return (
    <>
      <div>
        <button type="button" className="btn" onClick={hide}>
          Откажи
        </button>
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={deleteArea}
        >
          Обриши
        </button>
      </div>
    </>
  );
}

export default DeleteArea;
