import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import AuthorityClient from "../../api-services/authority/authority-client";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import {
  AuthorityGroupModel,
  authorityGroupSchema,
} from "../../models/Authority";

const CreateAuthorityGroup = ({ row, isEdit, handleCallback }) => {
  const { t } = useTranslation();

  const [authorityGroup, setAuthorityGroup] = useState(
    new AuthorityGroupModel()
  );
  const [errors, setErrors] = useState({});

  var client = new AuthorityClient();

  useEffect(() => {
    setAuthorityGroup(row);
    setErrors({});
  }, [row]);

  const createAuthorityGroup = async (e) => {
    e.preventDefault();
    const result = validateForm(authorityGroup, authorityGroupSchema, t);
    setErrors(result);
    if(!result) {
      if (isEdit) var response = await client.updateAuthorityGroup(authorityGroup);
      else var response = await client.createAuthorityGroup(authorityGroup);
      serverValidateAction(response);
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      authorityGroup,
      errors,
      authorityGroupSchema,
      t
    );
    setAuthorityGroup(result.data);
    setErrors(result.errors);
  };

  const serverValidateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  return (
    <form onSubmit={createAuthorityGroup}>
      <div>
        <div className="col-md">
          <label>{t("Name")}<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={authorityGroup.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
          <label>{t("Weight")}</label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={authorityGroup.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
          <input
                id="activeGroupCB"
                    type="checkbox"
                    value={authorityGroup.active}
                    checked={authorityGroup.active}
                    onChange={(event) => {
                        setAuthorityGroup({ ...authorityGroup, active: event.target.checked });
          }}
          />
        <label htmlFor="activeGroupCB">{t("Active")}</label>
        </div>
        <div className="text-right">
          <button
            className="btn btn-primary m-2"
            onClick={createAuthorityGroup}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateAuthorityGroup;
