import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { publishedEnum } from "../../models/CaseFilter";
import CourtClient from "../../api-services/coders/courts/courts-client";
import LegalMatterClient from "../../api-services/coders/legal-matter/legal-matter-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Select from "../../../common/custom-select-list/CustomSelectList.component";

const SearchCase = ({
  handleCallback,
  courtPracticeFilter,
  setCourtPracticeFilter,
  resetFilter,
}) => {
  const { t } = useTranslation();
  var courtClient = new CourtClient();
  var legalMatterClient = new LegalMatterClient();

  const [courts, setCourts] = useState([]);
  const [legalMatters, setLegalMatters] = useState([]);

  useEffect(() => {
    getCourts();
    getLegalMatters();
  }, []);

  const getCourts = async () => {
    let response = await courtClient.GetCourtsForSelect();
    let handledResponse = HandlingResponse(response);
    let courtsArray = handledResponse.map((x) => {
      return { value: x.key, label: x.value, name: "courtName" };
    });
    setCourts(courtsArray);
  };
  const getLegalMatters = async () => {
    let response = await legalMatterClient.GetLegalMattersForSelect();
    let handledResponse = HandlingResponse(response);
    let courtsArray = handledResponse.map((x) => {
      return { value: x.key, label: x.value, name: "legalMatterName" };
    });
    setLegalMatters(courtsArray);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourtPracticeFilter((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectListChange = (e) => {
    const { name, value } = e;
    setCourtPracticeFilter((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDatePickerChange = (date, name) => {
    setCourtPracticeFilter((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleRadioButtonsChange = (e) => {
    const { name, value } = e.target;
    setCourtPracticeFilter((prevFormData) => ({
      ...prevFormData,
      [name]: parseInt(value),
    }));
  };

  return (
    <form onSubmit={handleCallback}>
      <div className="row">
        <div className="col-3">
          <label>Идентификатор</label>
          <input
            className="form-control"
            type="text"
            name="internalLabelName"
            value={courtPracticeFilter.internalLabelName}
            onChange={handleInputChange}
          />
          <label>Суд</label>
          <Select
            id="court"
            options={courts}
            onChange={(e) => {
              if (!e) e = { value: "", name: "courtName" };
              handleSelectListChange(e);
            }}
            value={courts.filter((x) => x.value === courtPracticeFilter.courtName)}
            isClearable
          />
        </div>
        <div className="col-3">
          <label>Ближе одређење акта</label>
          <input
            className="form-control"
            type="text"
            name="actCloserDesignation"
            value={courtPracticeFilter.actCloserDesignation}
            onChange={handleInputChange}
          />
          <label>Правна материја</label>
          <Select
            id="legalMatter"
            options={legalMatters}
            onChange={(e) => {
              if (!e) e = { value: "", name: "legalMatterName" };
              handleSelectListChange(e);
            }}
            value={legalMatters.filter((x) => x.value === courtPracticeFilter.legalMatterName)}
            isClearable
          />
        </div>
        <div className="col-3">
          <label>Ознака и број акта</label>
          <input
            className="form-control"
            type="text"
            name="actLabelAndNumber"
            value={courtPracticeFilter.actLabelAndNumber}
            onChange={handleInputChange}
          />
          <label>Датум усвајања правног схватања</label>
          <CustomDatePicker
            selected={
              courtPracticeFilter.legalUnderstandingAdoptionDate
                ? new Date(courtPracticeFilter.legalUnderstandingAdoptionDate)
                : null
            }
            onChange={(legalUnderstandingAdoptionDate) =>
              handleDatePickerChange(
                legalUnderstandingAdoptionDate,
                "legalUnderstandingAdoptionDate"
              )
            }
          />
        </div>
        <div className="col-2" style={{ paddingTop: "30px" }}>
          <div
            style={{
              border: "1px solid #e1d6d6",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={publishedEnum.All}
                    checked={
                      courtPracticeFilter.isPublished === publishedEnum.All
                    }
                    onChange={handleRadioButtonsChange}
                    name="isPublished"
                  />
                  {"  "}
                  Сви
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={publishedEnum.Published}
                    checked={
                      courtPracticeFilter.isPublished ===
                      publishedEnum.Published
                    }
                    onChange={handleRadioButtonsChange}
                    name="isPublished"
                  />
                  {"  "}
                  Објављени
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={publishedEnum.Unpublished}
                    checked={
                      courtPracticeFilter.isPublished ===
                      publishedEnum.Unpublished
                    }
                    onChange={handleRadioButtonsChange}
                    name="isPublished"
                  />
                  {"  "}
                  Необјављени
                </label>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="text-right" style={{ marginTop: "10px" }}>
        <button className="btn btn-primary" type="submit">
          {t("Search")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "5px" }}
          onClick={() => {
            resetFilter();
          }}
        >
          Поништи претрагу
        </button>
      </div>
    </form>
  );
};

export default SearchCase;
