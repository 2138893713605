import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import IndexingTree from './IndexingTree.component';
import './IndexingTree.style.css'
import { mapObjectRecursively } from '../../../utils/common-functions/CommonFunctions';

const IndexingTreeNode = ({node, handleNodeSelect}) => {
    const { children, areaName, selected } = node;
    
    const hasChildren = children.length > 0;
    
    const handleClick = () => {
      if (node.selected) var isSelected = false;
      else isSelected = true;
      handleNodeSelect(node.id, isSelected);

      if (hasChildren) {
        mapObjectRecursively(children, (obj) =>
          handleNodeSelect(obj.id, isSelected)
        );
      }
    };

    return (
      <>
        <div id={node.id} onClick={handleClick} className='tree-node-div'>
          {hasChildren > 0 ? (
            <FontAwesomeIcon
              className={selected ? " selected-data" : ""}
              icon={solid("folder")}
            />
          ) : (
            <FontAwesomeIcon
              className={selected ? " selected-data" : ""}
              icon={solid("file")}
            />
          )}
          <span className={selected ? " selected-data" : ""}>
            {" " + areaName}
          </span>
        </div>
        <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
          <IndexingTree
            treeData={children}
            handleNodeSelect={handleNodeSelect}
          />
        </ul>
      </>
    );
}

export default IndexingTreeNode;
