import api from '../../../../api';
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class ActKindClient {
  async ActKind(isBackground) {
    try {
      var response = await api.get(baseurl + "/ActKind", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllActiveActKinds(isBackground) {
    try {
      var response = await api.get(baseurl + "/ActKind/GetActiveActKinds", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateActKind(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/ActKind", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateActKind(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/ActKind/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DeleteActKind(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/ActKind?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ActKindClient;