import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_NEWSLETTER;

class ManualSendClient {

    async GetAllSchedules(isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetAllSchedules", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async ManualSend(data, isBackground) {
        try{
            var response = await api.post(baseurl + "/ScheduledJob/ManualSend", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }


}

export default ManualSendClient;