import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_PENG;

class ArticleClient {
  async getArticlesByProductId(id, isBackground) {
    const params = {
      params: { productId: id },
      isBackground
    }
    try {
      const response = await api.get(baseurl + "/Article/GetAllArticlesWithChildrenByProductId", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async getArticle(id, isBackground) {
    const params = {
      params: { articleId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/Article/GetArticle", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createArticle(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Article/CreateArticle", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateArticle(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Article/UpdateArticle", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteArticle(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Article/DeleteArticle?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getStyles(isBackground) {
    try {
      var response = await api.get(baseurl + "/Article/GetStyles", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getArticleText(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Article/GetArticleText?articleId=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateArticleText(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Article/UpdateArticleText", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async clearArticleText(productId, parentId = 0, isBackground) {
    try {
      var response = await api.get(baseurl + "/Article/ClearArticle?productId=" + productId + "&parentId=" + parentId, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ArticleClient;