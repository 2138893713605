import { createContext, useState } from "react"
import { MoveLawActsModel } from "../models/MoveLawActs";

const MoveLawActsContext = createContext({});

export const MoveLawActsProvider = ({ children }) => {
    const [enableMoving, setEnableMoving] = useState(false);
    const [moveLawActs, setMoveLawActs] = useState(new MoveLawActsModel());

    //properties koji se koriste za proveru da li je akt aktivan ili ne prilikom premestanja pretrazenih rezultata
    const [isInactive, setIsInactive] = useState(false);
    const [inactiveArray, setInactiveArray] = useState([]);

    //selektovani propis u koji ce se izvrsiti premestanje
    const [selected, setSelected] = useState();

    //Niz koji pamti ParentGuid-e cekiranih propisa
    const [parentGuidArray, setParentGuidArray] = useState([]);

    const [movingErrorMessage, setMovingErrorMessage] = useState("");

    //akcija prilikom cekiranja propisa
    const onChecked = async (inactive, oldAreaId, parentGuid, nodeId, lawActGuid, isChecked, isSearching) => {
        if (isChecked) {
                setParentGuidArray([...parentGuidArray, parentGuid]);
                var helpArray = [...parentGuidArray, parentGuid];

                if(isSearching) {
                    setInactiveArray([...inactiveArray, inactive]);
                    var helpInactiveArray = [...inactiveArray, inactive];
                    checkIfMovingIsPossibleForSearching(helpInactiveArray);
                }
                else checkIfMovingIsPossible(helpArray);

                setMoveLawActs({
                    ...moveLawActs,
                    lawActsGuids: [...moveLawActs.lawActsGuids, lawActGuid],
                    lawActsIds: [...moveLawActs.lawActsIds, nodeId],
                    oldAreaId: oldAreaId,
                    parentGuid: helpArray[0],
                });
        } else {
            //uklanjanje parentGuid-a iz niza
            const i = parentGuidArray.indexOf(parentGuid);
            var helpArray = [...parentGuidArray];
            helpArray.splice(i, 1);
            setParentGuidArray(helpArray);

            if(isSearching) {
                const i = inactiveArray.indexOf(inactive);
                var helpInactiveArray = [...inactiveArray];
                helpInactiveArray.splice(i, 1); 
                setInactiveArray(helpInactiveArray);
                checkIfMovingIsPossibleForSearching(helpInactiveArray);
            }
            else checkIfMovingIsPossible(helpArray);

            //uklanjanje akta guida iz liste
            const index = moveLawActs.lawActsGuids.indexOf(lawActGuid);
            const arrayCopy = [...moveLawActs.lawActsGuids];
            arrayCopy.splice(index, 1);

            //uklanjanje akta guida iz liste
            const indexOfId = moveLawActs.lawActsIds.indexOf(nodeId);
            const arrayCopy2 = [...moveLawActs.lawActsIds];
            arrayCopy2.splice(indexOfId, 1);

            if (arrayCopy.length === 0) setMoveLawActs(new MoveLawActsModel());
            else
                setMoveLawActs({
                    ...moveLawActs,
                    parentGuid: helpArray[0],
                    lawActsGuids: arrayCopy,
                    lawActsIds: arrayCopy2
                });
        }
    };

    //Provera da li izabrani propisi imaju istog parentGuid-a
    const checkIfMovingIsPossible = (array) => {
        const result = array.every(el => {
            if (el === array[0]) {
                return true;
            }
        });
        if (result) {
            setMovingErrorMessage("");
        }
        else setMovingErrorMessage("Изабрани акти морају бити на истом нивоу и морају имати истог родитеља");
    }

    //Ukoliko se vrsi premestanje filtriranih propisa potrebno je proveriti da li se premesta aktivan ili neaktivan akt
    //Takodje moguce je premestiti samo jedan akt (sa svojom decom, ako ih ima)
    const checkIfMovingIsPossibleForSearching = (array) => {
        if(array.length > 1) {
            setMovingErrorMessage("Можете преместити само један акт који је резултат претраге!")
        }
        else setMovingErrorMessage("");

        const result = array.every(el => {
            if (el === true) return true;
            else return false
        });
        if(result) setIsInactive(true);
        else setIsInactive(false);

    }

    const onCancelMoving = () => {
        setMoveLawActs(new MoveLawActsModel());
        setMovingErrorMessage("");
        setParentGuidArray([]);
        setIsInactive(false);
        setInactiveArray([]);
    }


    return (
        <MoveLawActsContext.Provider value={{
            selected, setSelected, enableMoving, setEnableMoving, moveLawActs,
            setMoveLawActs, onCancelMoving, movingErrorMessage, onChecked, isInactive
        }}>
            {children}
        </MoveLawActsContext.Provider>
    )
}


export default MoveLawActsContext