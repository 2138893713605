import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_REG;

class AreaClient {
  async getTree() {
    try {
      var response = await api.get(baseurl + "/Area");
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAreaParents(id, isBackground) {
    const params = {
      params: { areaId: id },
      isBackground: isBackground
    };
    try {
      var response = await api.get(baseurl + "/Area/GetAreaParents", params);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteArea(id) {
    try {
      var response = await api.delete(baseurl + "/Area/DeleteArea/" + id);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async changeAreaName(id, name) {
    try {
      var response = await api.put(baseurl + "/Area/ChangeAreaName?areaId=" + id + "&areaName=" + name);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async addArea(data) {
    try {
      var response = await api.post(baseurl + "/Area/AddArea", data);
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAreasByParentId(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Area/GetAreasByParentId?id=" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getRegistries(isBackground) {
    try {
      var response = await api.get(baseurl + "/Area/GetAreasByParentId/", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async getAllAreasForIndexing() {
    try {
      var response = await api.get(baseurl + "/Area/GetAllAreasForIndexing");
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async lockArea(id, isBackground) {
    try {
      var response = await api.post(baseurl + "/Area/LockArea/" + id,  { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async getAllLockAreas(isBackground) {
    try {
      var response = await api.get(baseurl + "/Area/GetLockAreas",  { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async unlockArea(id, isBackground) {
    try {
      var response = await api.post(baseurl + "/Area/DeleteAreaLock/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async unlockAllArea(isBackground) {
    try {
      var response = await api.post(baseurl + "/Area/UnlockArea", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default AreaClient;