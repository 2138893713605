import TinyMCELite from "../../components/text-editor/TinyMCELite.jsx";
import { HtmlEditorContainer } from "../../components/text-editor/editHtml.style.js";
import { useLocation } from "react-router-dom";
import { TextEditorProvider, UpdateHtmlProvider } from "../../context/TextEditorContext";

const TextEditorLite = () => {
  const { state } = useLocation();
  return (
    <>
      <HtmlEditorContainer>       
          <TextEditorProvider>
            <TinyMCELite></TinyMCELite>
          </TextEditorProvider>
      </HtmlEditorContainer>
    </>
  );
};

export default TextEditorLite;
