import { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../common/modal/SideModal.component";
import CreatePackage from "../../components/create-package/CreatePackage.component";
import {
  dateBetweenFilterFn,
  DateRangeColumnFilter,
  SelectColumnFilter,
} from "../../../common/table/filterHelpFunction.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const Packages = () => {
  const tableName = 'LOZA_PACKAGE';
  const filter = {
    filterValue: "equals",
    selectOptions: [
      {
        value: undefined,
        label: "Сви",
      },
      {
        value: "0",
        label: "Активан",
      },
      {
        value: "1",
        label: "Неактиван",
      },
    ],
  };
  const [packages, setPackages] = useState([]);
  const columns = [
    {
      Header: "Шифра",
      accessor: "extCode",
      width: 200,
      disableFilters: false,
       
    },
    {
      Header: "Опис пакета",
      accessor: "shortDescription",
      width: 350,
      disableFilters: false,
       
    },
    {
      Header: "Статус",
      accessor: "packageStatus",
      width: 150,
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({row}) => { return (row.original.packageStatus === 0 ? "Активан" : "Неактиван") }
    },
    {
      Header: "Цена",
      accessor: "price",
      width: 150,
      disableFilters: false,
       
    },
    {
      Header: "Сортирање",
      accessor: "sort",
      width: 200,
      disableFilters: false,
       
    },
    {
      Header: "Важи од",
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.dateFrom), "dd.MM.yyyy.")}</div>
        );
      },
      width: 200,
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
    },
    {
      Header: "Важи до",
      accessor: "dateTo",
      Cell: ({ row }) => {
        return <div>{format(new Date(row.original.dateTo), "dd.MM.yyyy.")}</div>;
      },
      width: 200,
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-primary table-btn"
            onClick={() => editPackage(row.original)}
          >
            <FontAwesomeIcon icon={solid("edit")} />
          </button>
        );
      },
      width: 100,
    },
  ];

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [chosenFile, setChosenFile] = useState(null);
  const [rowData, setRowData] = useState({
    packageType: "PRODUCTS",
    issueTypeGuid: "",
    shortDescription: "",
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    price: 0,
    extCode: "",
    sort: 0,
    id: 0,
    isGroup: false,
    packageStatus: 1,
    product: []
  });

  const [products, setProducts] = useState([]);

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const client = new PackageClient();

  const getAllPackages = async () => {
    await client.GetPackages().then((response) => {
      let handledResponse = HandlingResponse(response);
      let result = handledResponse
        .filter((x) => !x.isGroup)
      setPackages(result);
    });
  };

  useEffect(() => {
    getAllPackages();
  }, []);
  useEffect(() => {
    setProducts([]);
  }, [rowData])

  const createNewPackage = () => {
    setEdit(false);
    setRowData({
      packageType: "PRODUCTS",
      issueTypeGuid: "",
      shortDescription: "",
      description: "",
      dateFrom: new Date(),
      dateTo: new Date(),
      price: 0,
      extCode: "",
      sort: 0,
      id: 0,
      isGroup: false,
      packageStatus: 1,
      product: []
    });
    showModal();
  };

  const getPackage = async (id) => {
    let response = await client.GetPackageById(id);
    let handledResponse = HandlingResponse(response);
    setChosenFile(handledResponse.packageImage);
    var productsList = handledResponse.products.map((item) => {
      return { value: item.id, label: item.name, title: item.description };
    });
    setProducts(productsList);
  };

  const editPackage = (data) => {
    setEdit(true);
    setRowData({
      ...data,
      dateFrom: new Date(data.dateFrom),
      dateTo: new Date(data.dateTo),
    });
    getPackage(data.id);
    showModal();
  };

  const handleAddingDataToTable = () => {
    hideModal();
    getAllPackages()
  };

  return (
    <>
      <h4>Пакети</h4>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
        onClick={() => createNewPackage()}
      >
        Креирај пакет
      </button>
      <Table
        data={packages}
        columns={columns}
        rememberFilter={true}
        tableName={tableName}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={edit ? "Измена пакета" : "Креирање пакета"}
      >
        <CreatePackage
          data={rowData}
          edit={edit}
          file={chosenFile}
          listOfProducts={products}
          show={show}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default Packages;
