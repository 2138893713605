import Joi from "joi-browser";

class CreateDataModel {
    constructor(typeId = undefined, typesIdsWithParentIds = [], enactmentDate = null, showEnactmentDate = false, actLabelNumber = "", archived = false, highlighted = false, year = "", actCloserDesignation = "", otherActs = "", editorNote = "", published = false, locked = false, userFullName = "") {
        this.typeId = typeId;
        this.typesIdsWithParentIds = typesIdsWithParentIds;
        this.enactmentDate = enactmentDate;
        this.showEnactmentDate = showEnactmentDate;
        this.actLabelNumber = actLabelNumber;
        this.archived = archived;
        this.highlighted = highlighted;
        this.year = year;
        this.actCloserDesignation = actCloserDesignation;
        this.otherActs = otherActs;
        this.editorNote = editorNote;
        this.published = published;
        this.locked = locked;
        this.userFullName = userFullName;
    }
}

class CreateDataAuthorityModel {
    constructor(id = 0, authorityGroupId = 0, authorityGroupName = "", authorityKindId = 0, authorityKindName = "", authorityAreaId = null, authorityAreaName = "", authorityId = 0, authorityName = "") {
        this.id = id;
        this.authorityGroupId = authorityGroupId;
        this.authorityGroupName = authorityGroupName;
        this.authorityKindId = authorityKindId;
        this.authorityKindName = authorityKindName;
        this.authorityAreaId = authorityAreaId;
        this.authorityAreaName = authorityAreaName;
        this.authorityId = authorityId;
        this.authorityName = authorityName;
    }
}

class CreateDataPrincipalActModel {
    constructor(id = 0, sort = 0, principalAct = "", regActGuid = "", principalActArticles = "") {
        this.id = id;
        this.sort = sort;
        this.principalAct = principalAct;
        this.regActGuid = regActGuid;
        this.principalActArticles = principalActArticles;
    }
}

class CreateDataSourceModel {
    constructor(id = 0, sourceId = undefined, sourceName = null, description = "", primary = false, sourceKindId = undefined, sourceKindName = null) {
        this.id = id;
        this.sourceId = sourceId;
        this.sourceName = sourceName;
        this.description = description;
        this.primary = primary;
        this.sourceKindId = sourceKindId;
        this.sourceKindName = sourceKindName;
    }
}


export const databaseList = [
  {
    value: "regClient",
    label: "Регистри",
    enumVal: 0,
  },
  {
    value: "spClient",
    label: "Судска пракса",
    enumVal: 1,
  },
  {
    value: "slglClient",
    label: "Службена гласила",
    enumVal: 2,
  },
  {
    value: "mmlClient",
    label: "Мишљења, модели, литература",
    enumVal: 3,
  },
  {
    value: "pppClient",
    label: "Посебни програмски пакети",
    enumVal: 4
  },
  ];

export const dataSchema = {
    typeId: Joi.required(),
    enactmentDate: Joi.date(),
    actLabelNumber: Joi.string().max(250).allow("").allow(null).optional(),
    authorities: Joi.array().min(1).unique(),
    editorNote: Joi.string().max(250).allow("").allow(null).optional(),
    htmlFile: Joi.required(),
    pdfFile: Joi.required(),
    primaryRequest: Joi.any().when('primaryPublicationExist', {
      is: true,
      then: Joi.optional(),
      otherwise: Joi.required()
  })
  };

  export const publishingMethodSchema = {
    sourceKindId: Joi.required(),
    sourceId: Joi.required(),
    description: Joi.string().max(900).allow("").allow(null).optional()
  }

  export const principalActSchema = {
    principalAct: Joi.string().required(),
    principalActArticles: Joi.string().max(900).required()
  }

export { CreateDataModel, CreateDataAuthorityModel, CreateDataPrincipalActModel, CreateDataSourceModel }