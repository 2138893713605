import React, { useState, useEffect, useRef } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import ActClient from "../../api-services/acts/act-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import useInternalFormContext from "../../hooks/useInternalFormContext";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import InternalActClient from "../../api-services/internal-act/internal-act-client";
import UserService from "../../../utils/authentification/keycloak";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn } from "../../../common/multiple-files/multipleFiles.style";

const TabBasicInformation = ({
  file,
  setFile,
  files,
  setFiles,
  pictures,
  setPictures,
  errors,
}) => {
  const model = "internalActBasicData";

  const {
    title,
    page,
    data,
    handleChangeInput,
    handleChangeList,
    handleChangeDate,
  } = useInternalFormContext();

  const actClient = new ActClient();
  const internalActClient = new InternalActClient();

  const [categoryList, setCategoryList] = useState([]);
  const [authorityList, setAuthorityList] = useState([]);
  const htmlInputRef = useRef(null);

  useEffect(() => {
    getActiveAdvertisement();
  }, [data.internalActBasicData?.actTypeId]);

  useEffect(() => {
    getAuthorities();
  }, [data.internalActBasicData?.authorityId]);

  const getActiveAdvertisement = async () => {
    let response = await actClient.getActiveAdvertisement(true);
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return {
        value: x.id,
        label: x.description,
        name: "actTypeId",
        model: model,
      };
    });
    setCategoryList(array);
  };

  const getAuthorities = async () => {
    let response = await actClient.getActiveAuthorities(true);
    let handledResponse = HandlingResponse(response);
    let authorities = handledResponse.map((item) => {
      return {
        value: item.id,
        label: item.authorityName,
        name: "authorityId",
        model: model,
      };
    });
    setAuthorityList(authorities);
  };

  const downloadFilesFromRepo = async () => {
    var zipName =
      data.internalActBasicData.id +
      "_" +
      data.internalActBasicData.internalActName;
    if (zipName.length > 30) zipName = zipName.substring(0, 30);
    let response = await actClient.downloadFilesFromRepo(
      data.internalActBasicData.id,
      zipName
    );
    HandlingResponse(response);
  };

  const getInternalActHtml = async () => {
    var response = await internalActClient.getInternalActHtml(
      data.internalActBasicData.id
    );
    return response;
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  return (
    <>
      <div>
        <h4>{title[page]}</h4>
        <label>Категорија</label>
        <Select
          options={categoryList}
          onChange={(e) =>
            e
              ? handleChangeList(e)
              : handleChangeList({
                  name: "actTypeId",
                  value: null,
                  model: model,
                })
          }
          value={categoryList?.filter(
            (x) => x.value === data?.internalActBasicData?.actTypeId
          )}
          isClearable
        />

        <label>Доносилац</label>
        <Select
          options={authorityList}
          onChange={(e) =>
            e
              ? handleChangeList(e)
              : handleChangeList({
                  name: "authorityId",
                  value: null,
                  model: model,
                })
          }
          value={authorityList.filter(
            (x) => x.value === data?.internalActBasicData?.authorityId
          )}
          isClearable
        />
        <label>Наднапомена</label>
        <textarea
          className="form-control"
          type="text"
          name="superscript"
          data-model={model}
          id="superscript"
          value={data.internalActBasicData?.superscript}
          onChange={handleChangeInput}
        />
        <label>
          Наслов за приказ на сајту<span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          type="text"
          name="internalActName"
          data-model={model}
          id="internalActName"
          value={data.internalActBasicData?.internalActName}
          onChange={handleChangeInput}
        />
        {errors && errors["internalActBasicData.internalActName"] && (
          <div className="text-danger">
            {errors["internalActBasicData.internalActName"]}
          </div>
        )}
        <label>Опис</label>
        <textarea
          className="form-control"
          type="text"
          name="abstract"
          data-model={model}
          id="abstract"
          value={data.internalActBasicData?.abstract}
          onChange={handleChangeInput}
        />
        <label>Поднапомена</label>
        <textarea
          className="form-control"
          type="text"
          name="subscript"
          data-model={model}
          id="subscript"
          value={data.internalActBasicData?.subscript}
          onChange={handleChangeInput}
        />
        <label>Додатне информације</label>
        <textarea
          className="form-control"
          type="text"
          name="detailedDefinition"
          data-model={model}
          id="detailedDefinition"
          value={data.internalActBasicData?.detailedDefinition}
          onChange={handleChangeInput}
        />
        <div style={{ display: "flex" }}>
          <div className="col-md-6" style={{ marginRight: "5px" }}>
            <div>
              <label>Датум усвајања</label>
              <CustomDatePicker
                selected={
                  data.internalActBasicData?.versionApplicationDate
                    ? new Date(data.internalActBasicData.versionApplicationDate)
                    : null
                }
                onChange={(date) =>
                  handleChangeDate(date, "versionApplicationDate", model)
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <label>Датум важења</label>
              <CustomDatePicker
                selected={
                  data.internalActBasicData?.validToDate
                    ? new Date(data.internalActBasicData.validToDate)
                    : null
                }
                onChange={(date) =>
                  handleChangeDate(date, "validToDate", model)
                }
              />
            </div>
          </div>
        </div>
        <label>Извор</label>
        <input
          className="form-control form-group"
          id="source"
          name="source"
          data-model={model}
          type="text"
          value={data.internalActBasicData?.source}
          onChange={handleChangeInput}
        />
        <div>
          {!file && (
            <>
              <NewFileBtn onClick={handleUploadHtmlClick} type="button">
                <FontAwesomeIcon icon={solid("plus")} />
                Додај ХТМЛ фајл
              </NewFileBtn>
              или
            </>
          )}
          <input
            type="file"
            ref={htmlInputRef}
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
            hidden
            accept=".html, .htm"
            name="file"
          />
          <SubmitFile
            mainFile={file}
            setMainFile={setFile}
            photoFiles={pictures}
            setPhotoFiles={setPictures}
            attachmentFiles={files}
            setAttachmentFiles={setFiles}
            isEdit={
              file?.guid &&
              ((data.internalActBasicData.locked &&
                data.internalActBasicData.userGuid ===
                  UserService.getUserGuid()) ||
                !data.internalActBasicData.locked)
            }
            downloadFilesFromRepo={downloadFilesFromRepo}
            getHtml={getInternalActHtml}
            id={data.internalActBasicData.id}
            pathToNavigate={window.location.pathname}
            photosAndAttachmentsRequired={true}
            showEditHTMLButton={false}
          ></SubmitFile>
          {!file && errors?.file && <div className="text-danger">{errors.file}</div>}
        </div>
        <div className="checkboxes-container">
          <label>
            <input
              id="hasNoHtml"
              name="hasNoHtml"
              data-model={model}
              type="checkbox"
              checked={data.internalActBasicData?.hasNoHtml}
              onChange={handleChangeInput}
            />
            Само ПДФ
          </label>
          <label>
            <input
              id="showIdCard"
              name="showIdCard"
              data-model={model}
              type="checkbox"
              checked={data.internalActBasicData?.showIdCard}
              onChange={handleChangeInput}
            />
            Прикажи личну карту
          </label>
          <label>
            <input
              id="showStructureLink"
              name="showStructureLink"
              data-model={model}
              type="checkbox"
              checked={data.internalActBasicData?.showStructureLink}
              onChange={handleChangeInput}
            />
            Прикажи структуру
          </label>
          <label>
            <input
              id="showEditorMark"
              name="showEditorMark"
              data-model={model}
              type="checkbox"
              checked={data.internalActBasicData?.showEditorMark}
              onChange={handleChangeInput}
            />
            Прикажи опис
          </label>
        </div>
      </div>
    </>
  );
};

export default TabBasicInformation;
