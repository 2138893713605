import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import Joi from "joi-browser";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import AreaClient from "../../api-services/areas/area-client";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import { MoveAreaModel } from "../../models/Area";

const AreasMoving = ({ level, dataForMoving, handleCallback, hideModal }) => {
  const { t } = useTranslation();
  const areaClient = new AreaClient();
  const { currentLocation, getAreaId} = UseRenderDifferentComponent();

  const [moveArea, setMoveArea] = useState(new MoveAreaModel());
  
  const schema = {
    destinationAreaId: Joi.required(),
    destinationGroupId: level === 3 ? Joi.required() : Joi.optional(),
  };

  useEffect(() => {
    getFirstLevelAreaForMove();
  }, [dataForMoving]);

  const [listOfAreaToMove, setListOfAreaToMove] = useState([]);
  const [listOfGroupToMove, setListOfGroupToMove] = useState([]);
  const [errors, setErrors] = useState({});

  const getFirstLevelAreaForMove = async () => {
    var area = getAreaId();
    setMoveArea({ ...moveArea, areaId: dataForMoving.id, areaName: dataForMoving.name });
    var response = await areaClient.getFirstLevelAreaForMove(area, true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name, name: "destinationAreaId" };
    });

    setListOfAreaToMove(result);
  };

  const getGroupsByArea = async (id) => {
    setMoveArea({ ...moveArea, destinationAreaId: id });
    var response = await areaClient.getSecondLevelAreaForMove(id, true);
    let handledResponse = HandlingResponse(response);
    var result = handledResponse.map((item) => {
      return { value: item.id, label: item.name, name: "destinationGroupId" };
    });

    setListOfGroupToMove(result);
  };

  const movingArea = async () => {
    const errorsResult = validateForm(moveArea, schema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      var response = await areaClient.moveArea(moveArea);
      var handledResponse = HandlingResponse(response);

      if (handledResponse !== "Error") {
        hideModal();
        handleCallback(currentLocation.id, currentLocation.name, true);
      }
    }
  };

  const cancelMoving = () => {
    hideModal();
    setMoveArea(new MoveAreaModel());
    setErrors({});
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, moveArea, errors, schema, t);
    setMoveArea(result.data);
    setErrors(result.errors);
  };

  return (
    <div>
      <div>
        <label>{level === 2 ? "Група: " : "Подгрупа: "}</label>
        <span>{moveArea.areaName}</span>
      </div>
      <label>Области</label>
      <Select
        options={listOfAreaToMove}
        onChange={(e) => {
          handlePropertyChange(e);
          level === 3 && getGroupsByArea(e.value);
        }}
        value={listOfAreaToMove.filter(
          (x) => x.value === moveArea.destinationAreaId
        )}
      />
      {errors?.destinationAreaId && (
        <div className="text-danger">{errors.destinationAreaId}</div>
      )}
      <br />
      {level === 3 && (
        <>
          <label>Групе</label>
          <Select
            options={listOfGroupToMove}
            onChange={(e) => handlePropertyChange(e)}
            value={listOfGroupToMove.filter(
              (x) => x.value === moveArea.destinationGroupId
            )}
          />
          {errors?.destinationGroupId && (
            <div className="text-danger">{errors.destinationGroupId}</div>
          )}
        </>
      )}
      <br />
      <button type="button" className="btn btn-primary" onClick={cancelMoving}>
        Одустани
      </button>
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={movingArea}
      >
        Премести
      </button>
    </div>
  );
};

export default AreasMoving;
