import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import AuthorityClient from "../../api-services/authority/authority-client";
import {
  AuthorityAreaModel,
  authorityAreaSchema,
} from "../../models/Authority";

const CreateAuthorityArea = ({ row, isEdit, handleCallback }) => {
  const { t } = useTranslation();

  const [authorityArea, setAuthorityArea] = useState(new AuthorityAreaModel());
  const [errors, setErrors] = useState({});

  var client = new AuthorityClient();

  useEffect(() => {
    setAuthorityArea(row);
    setErrors({});
  }, [row]);

  const createAuthorityArea = async (e) => {
    e.preventDefault();
    const result = validateForm(authorityArea, authorityAreaSchema, t);
    setErrors(result);
    if(!result) {
      if (isEdit) var response = await client.updateAuthorityArea(authorityArea);
      else var response = await client.createAuthorityArea(authorityArea);
      validateAction(response);
    }
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      authorityArea,
      errors,
      authorityAreaSchema,
      t
    );
    setAuthorityArea(result.data);
    setErrors(result.errors);
  };

  const validateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };
  return (
    <form onSubmit={createAuthorityArea}>
      <div>
        <div className="col-md">
          <label>{t("Name")}</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={authorityArea.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
          <label>{t("Group")}</label>
          <input
            className="form-control"
            disabled
            type="text"
            name="authorityGroupName"
            value={authorityArea.authorityGroupName}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.authorityGroupName && (
            <div className="text-danger">{errors.authorityGroupName}</div>
          )}
          <label>{t("Kind")}</label>
          <input
            className="form-control"
            disabled
            type="text"
            name="authorityKindName"
            value={authorityArea.authorityKindName}
            onChange={(event) =>
              setAuthorityArea({
                ...authorityArea,
                authorityKindName: event.target.value,
              })
            }
          />
          {errors?.authorityKindName && (
            <div className="text-danger">{errors.authorityKindName}</div>
          )}
          <label>{t("Weight")}</label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={authorityArea.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
          <input
                id="activeAreaCB"
                    type="checkbox"
                    value={authorityArea.active}
                    checked={authorityArea.active}
                    onChange={(event) => {
                        setAuthorityArea({ ...authorityArea, active: event.target.checked });
                    }}
          />
          <label htmlFor="activeAreaCB">{t("Active")}</label>
        </div>
        <div className="text-right">
          <button
            className="btn btn-primary m-2"
            onClick={createAuthorityArea}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateAuthorityArea;
