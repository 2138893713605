import React from 'react'
import IndexingTreeNode from './IndexingTreeNode.component';

const IndexingTree = ({
    treeData,
    handleNodeSelect
  }) => {
  
    return (
      <ul>
        {treeData.map((node) => (
          <IndexingTreeNode
            node={node}
            key={node.id}
            handleNodeSelect={handleNodeSelect}
          />
        ))}
      </ul>
    );
}

export default IndexingTree;
