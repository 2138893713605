import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import SideModal from "../../../common/modal/SideModal.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateAdvancedSearchQuery from "../../components/advanced-search-query-component/CreateAdvancedSearchQuery.component";
import AdvancedSearchQueryClient from "../../api-services/advanced-search-query/advanced-search-query-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const AdvancedSearchQuery = () => {
  const tableName = "SP_ADVANCED_SEARCH_QUERY"

  const columns = [
    {
      Header: "Назив регистра",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation
            className="btn btn-danger table-btn" 
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Регистар ће бити трајно обрисан."}
            handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];
  
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    name: "",
    weight: "",
    query: "",
    isActive: true,
  });

  const client = new AdvancedSearchQueryClient();

  useEffect(() => {
    AdvancedSearchQuery();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      weight: "",
      query: "",
      isActive: true,
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteAdvancedSearchQuery(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let advancedSearchQueryCopy = advancedSearchQuery.filter((x) => x.id !== id);
      setAdvancedSearchQuery(advancedSearchQueryCopy);
    }
  };

  const AdvancedSearchQuery = async () => {
    await client.AdvancedSearchQuery().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setAdvancedSearchQuery(handledResponse);
    });
  }
  
  return (
    <>
      <h4>Регистри</h4>

      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нови регистар
        </button>
      </div>
      <Table
        columns={columns}
        data={advancedSearchQuery}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Креирање новог регистра" : "Измена регистра"}
      >
        <CreateAdvancedSearchQuery
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            AdvancedSearchQuery();
          }}
        />
      </SideModal>
    </>
  );
};

export default AdvancedSearchQuery;
