import api from "../../../../api";
import { env } from "../../../../env";

var baseurl = env.REACT_APP_API_URL_FP;

class PreviewNewsClient {
  async filterNews(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/News/FilterNews", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getCategoriesApi(isBackground) {
    try {
      var response = await api.get(baseurl + "/Category/GetCategorys", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteNewsApi(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/News/DeleteNews/" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFile(fileGuid, fileName, isBackground) {
    try {
      var response = await api
        .get(baseurl + "/News/DownloadFile/" + fileGuid, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName;
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createNews(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseurl + "/News/CreateNews",
        data,
        { headers: headers },
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateNews(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.put(baseurl + "/News/UpdateNews", data, 
      { headers: headers },
      { headers: headers },{
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DeleteNews(id, isBackground){
    try{
        var response = await api.delete(baseurl + "/News/DeleteNews/" + id, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
}

  async GetNewsPageById(id, isBackground){
    try{
        var response = await api.get(baseurl + "/News/" + id, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
}

async getMassIndexing(isBackground) {
  try {
    var response = await api.get(baseurl + "/News/MassIndexing", {
      isBackground: isBackground,
    });
    return response.data;
  }catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
}

async getIndexNews(id, isBackground) {
  try {
    var response = await api.get(baseurl + "/News/IndexNews?id=" + id, {
      isBackground: isBackground,
    });
    return response.data;
  }catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
}

async deleteIndexNews(id, isBackground) {
  try {
    var response = await api.get(baseurl + "/News/DeleteIndexNews/" + id, {
      isBackground: isBackground,
    });
    return response.data;
  }catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
}

async DownloadFilesFromRepo(id, fileName, isBackground) {
  try {
      var response = await api.get(baseurl + "/News/DownloadFilesFromRepo/" + id, { responseType: 'blob' })
      .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.click();
      });
  }
  catch (err) {
    if (err.response.status === 404 && err.response.config.responseType === 'blob') {
      let responseObj = await err.response.data.text();
      return JSON.parse(responseObj);
    }
    if (err.response.status === 400) return err.response.data;
    else return err.response;
  }
}

async GetNewsHtml(id, isBackground){
  try{
      var response = await api.get(baseurl + "/News/GetNewsHtml?id=" + id, {isBackground: isBackground});
      return response.data;
  }
  catch (err) {
if (err.response.status === 400) return err.response.data;
else return err.response;
}
}

async UpdateNewsFile(data, isBackground) {
  const headers = {
      'Content-Type': 'multipart/form-data',
  };
  try {
      var response = await api.put(baseurl + "/News/UpdateNewsFile", data, {
          headers: headers,
          isBackground: isBackground
      });
      return response.data;
  }
  catch (err) {
if (err.response.status === 400) return err.response.data;
else return err.response;
}
}

}

export default PreviewNewsClient;
