import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import "./AlertDialog.style.css";

const AlertDialog = ({ handleYes, handleNo, show, contentText, handleYesText }) => {
  return (
    <>
      <Dialog
        open={show}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 99999 }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Да ли сте сигурни?"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="alert-dialog-margin">
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={handleYes}
          >
            {handleYesText}
          </button>
          <button type="button" className="btn btn-light" onClick={handleNo}>
            Одустани
          </button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default AlertDialog;
