import api from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_NEWSLETTER;

class ScheduleClient {

    async GetSchedules(isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetAllSchedules", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetScheduleById(id, isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetSchedule?id=" + id, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
    
    async GenerateExampleHtml(data, isBackground) {
        try{
            var response = await api.post(baseurl + "/Schedule/GenerateExampleHtml", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetDayOfWeeks(isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetDayOfWeeks", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    
    async GetTriggers(isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetTriggers", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async CreateSchedule(data, isBackground) {
        try{
            var response = await api.post(baseurl + "/Schedule/CreateSchedule", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async UpdateSchedule(data, isBackground) {
        try{
            var response = await api.put(baseurl + "/Schedule/UpdateSchedule", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetPeriodicFlags(isBackground) {
        try{
            var response = await api.get(baseurl + "/Schedule/GetPeriodicFlags", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }


}

export default ScheduleClient;