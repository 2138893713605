import React, { useState, useEffect } from "react";
import CategoriesClient from "../../../api-services/codebooks/categories/categories";
import Table from "../../../../common/table/Table.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Moment from "moment";
import DialogModal from "../../../../common/modal/Modal.component";
import EditCategory from "../../../components/edit-category/EditCategory.component";

const Categories = () => {
  const tableName = "FP_CATEGORIES";

  const columns = [
    {
      Header: "Назив",
      accessor: "name",
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Објављена",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          row.original.published && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: "Датум креирања",
      accessor: "creationDate",
      Cell: ({ row }) => {
        return row.original.creationDate
          ? Moment(row.original.creationDate).format("DD.MM.YYYY. HH:mm")
          : "";
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [row, setRow] = useState({
    weight: 0,
    published: true,
  });
  const client = new CategoriesClient();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const getCategories = async () => {
    let response = await client.GetCategories();
    let result = HandlingResponse(response);
    setTableData(result);
  };

  const onEditClick = (row) => {
    setRow(row);
    ShowDialog();
  };

  const handleAddingDataToTable = (childData) => {
    HideDialog();
    let allCategoriesCopy = [...tableData];
    let index = allCategoriesCopy.findIndex((x) => x.id === childData.id);
    allCategoriesCopy[index] = childData;
    setTableData(allCategoriesCopy);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <h5 className="col-md-2" style={{marginBottom: "25px"}}>Категорије</h5>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={true}
        tableName={tableName}
        hiddenColumn={["id"]}
        serverSide={false}
      />

      <DialogModal
        show={show}
        onHide={HideDialog}
        title="Измена категорије"
        wide={0}
      >
        <EditCategory row={row} handleCallback={handleAddingDataToTable} />
      </DialogModal>
    </>
  );
};

export default Categories;
