import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table, { getPageNumber, getPageSizeNumber, getSortBy } from "../../../common/table/Table.component";
import SideModal from "../../../common/modal/SideModal.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useSortBy } from "react-table";
import MultimediaClient from "../../api-services/multimedia/multimedia";
import SearchMultimedia from "../../components/multimedia/SearchMultimedia.component";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateMultimedia from "../../components/multimedia/CreateMultimedia.component";
import Moment from "moment";

const Multimedia = () => {
  const tableName = "FP_MULTIMEDIA";
  const rememberFilter = true;

  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [reset, setReset] =  useState(false);

  const [filterMultimedia, setFilterMultimedia] = useState({
    title: "",
    description: undefined,
    publishedDateFrom: null,
    publishedDateTo: null,
    published: 2,
  });

  const [rowData, setRowData] = useState({
    title: "",
    description: "",
    publishedDate: new Date(),
    link: "",
    published: false,
  });

  const columns = [
    {
      Header: "Наслов",
      accessor: "title",
    },
    {
      Header: "Опис",
      accessor: "description",
    },
    {
      Header: "Датум објављивања",
      accessor: "publishedDate",
      Cell: ({ row }) => {
        return row.original.publishedDate
          ? Moment(row.original.publishedDate).format("DD.MM.YYYY. HH:mm")
          : "";
      },
    },
    {
      Header: "Линк",
      accessor: "link",
    },
    {
      Header: "Објављен",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {row.original.published && (
              <FontAwesomeIcon icon={solid("check")} />
            )}
          </div>
        );
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original.id)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
          <ButtonWithComfirmation
            className="btn btn-danger table-btn"
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Мултимедија ће бити трајно обрисана."}
            handleYesText={"Обриши"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    reset && setReset(false);
  }, [tableData]);

  var client = new MultimediaClient();

  // Modal
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const FilterMultimediaForTable = async (pageNumber, pageSize, sortBy) => {
    const response = await client.filterMultimedia(
      {
        ...filterMultimedia,
        pageSize: pageSize,
        pageNumber: pageNumber,
        fieldNamesForSort: sortBy,
      },
      true
    );
    let data = HandlingResponse(response);
    return data;
  };

  const ResetFilter = async () => {
    setReset(true)
    setSelectedPublishedEnum("2");
    setFilterMultimedia({
      title: "",
      description: null,
      publishedDateFrom: null,
      publishedDateTo: null,
      published: 2,
    });

    var response = await client.filterMultimedia(
      {
        title: "",
        description: null,
        publishedDateFrom: null,
        publishedDateTo: null,
        published: 2,
      },
      true
    );
    let data = HandlingResponse(response);
    setTableData(data);
  };

  const searchData = async (e) => {
    e.preventDefault();
    const response = await FilterMultimediaForTable(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(response);
  };

  const [selectedPublishedEnum, setSelectedPublishedEnum] = useState("2");

  const handleOptionsChange = (event) => {
    setSelectedPublishedEnum(event.target.value);
  };
  useEffect(() => {
    setFilterMultimedia({
      ...filterMultimedia,
      published: parseInt(selectedPublishedEnum),
    });
  }, [selectedPublishedEnum]);

  // Kreiranje novog eventa

  const createNewMultimedia = () => {
    setEdit(false);
    setRowData({
      title: "",
      description: "",
      publishedDate: new Date(),
      link: "",
      published: false,
    });
    showModal();
  };

  // Izmena eventa

  const onEditClick = async (id) => {
    var response = await client.GetMultibediaById(id);
    setEdit(true);
    setRowData(response);
    showModal();
  };

  // Brisanje eventa

  const onDeleteClick = async (id) => {
    let response = await client.deleteMultimedia(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      let tableDataCopy = tableData.filter((x) => x.id !== id);
      setTableData(tableDataCopy);
    }
  };

  return (
    <>
    <h5 className="col-md-2" style={{marginBottom: "25px"}}>Мултимедија</h5>
      <SearchMultimedia
        filterMultimedia={filterMultimedia}
        setFilterMultimedia={setFilterMultimedia}
        handleOptionsChange={handleOptionsChange}
        selectedPublishedEnum={selectedPublishedEnum}
        resetFilters={ResetFilter}
        searchData={searchData}
      />
      <hr />
      <div className="text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => createNewMultimedia()}
        >
          Креирај мултимедију
        </button>
      </div>
      <Table
        columns={columns}
        data={tableData}
        tableName={tableName}
        rememberFilter={rememberFilter}
        serverSide={true}
        fetchFunction={FilterMultimediaForTable}
        setData={setTableData}
        resetFilters={reset}
      />

      <SideModal
        show={show}
        handleClose={hideModal}
        title={edit ? "Измена мултимедије" : "Креирање мултимедије"}
      >
        <CreateMultimedia row={rowData} edit={edit} />
      </SideModal>
    </>
  );
};

export default Multimedia;
