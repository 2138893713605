import { useState, useEffect } from "react";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import IssueTypeClient from "../../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import NumberClient from "../../../api-services/number/number";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import LawAdActClient from "../../../api-services/law-ad-act/law-ad-act-client";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
  removeTableObjectsFromSessionStorage,
} from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAct from "./CreateAct.component";

const EditActs = ({ isCurrent, tableName, filterName, activeTab }) => {
  const rememberFilter = true;
  const [editActData, setEditActData] = useState({
    issueTypeGuid: "",
    year: null,
    num: "",
  });

  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      Header: "Назив",
      accessor: "lawAdActName",
    },
    {
      Header: "Акт/оглас",
      accessor: "isLaw",
      Cell: ({ row }) => {
        return <div>{row.original.isLaw ? "Акт" : "Оглас"}</div>;
      },
    },
    {
      Header: "Редни број",
      accessor: "itemNumber",
    },
    {
      Header: "Број стране",
      accessor: "ogPageNumber",
    },
    {
      Header: "Доносилац",
      accessor: "lawAdActAuthorityName",
    },
    {
      Header: "Врста документа",
      accessor: "advertisementAndActTypeName",
      width: 300,
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
              title="Измена корисника"
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          </>
        );
      },
    },
  ];

  const [issueTypeList, setIssueTypeList] = useState([]);
  const [numList, setNumList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [showNumAndSearch, setShowNumAndSearch] = useState(false);
  const [numId, setNumId] = useState(0);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fkNumber, setFkNumber] = useState(0);
  const [rowData, setRowData] = useState({
    lawAdActName: "",
    isLaw: false,
    itemNumber: 0,
    ogPageNumber: 0,
    lawAdActAuthorityGuid: undefined,
    advertisementAndActTypeGuid: undefined,
    htmlLawAdActUrl: "",
  });

  const client = new IssueTypeClient();
  const clientNumber = new NumberClient();
  const clientLaw = new LawAdActClient();

  useEffect(() => {
    getIssueTypes();
  }, []);

  useEffect(() => {
    initialData();
  }, [activeTab, isCurrent]);

  // Vrste izdanja
  const getIssueTypes = async () => {
    let responseForOgAndReg = await client.getIssueTypesForOgAndReg(true);
    let handledResponseForOgAndReg = HandlingResponse(responseForOgAndReg);

    let responseForOg = await client.getIssueTypesForOg(true);
    let handledResponseForOg = HandlingResponse(responseForOg);

    if (
      handledResponseForOg !== "Error" &&
      handledResponseForOgAndReg !== "Error"
    ) {
      let combinedResponse = [
        ...handledResponseForOgAndReg,
        ...handledResponseForOg,
      ];

      let activeItems = combinedResponse.filter((item) => item.active === true);

      var result = activeItems.map((item) => {
        return { value: item.guid, label: item.description };
      });
      setIssueTypeList(result);
    }
  };

  const initialData = async () => {
    const storedObject = sessionStorage.getItem(filterName);

    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      if(parsedObject.issueTypeGuid) {
        GetYearsByIssueType(parsedObject.issueTypeGuid);
        setEditActData(parsedObject);
        await FilterNumbersByIssueType();
      }
      if (parsedObject.num) {
        setEditActData(parsedObject);
        setShowInputs(true);
      }
    } else {
      setShowNumAndSearch(false);
      setShowInputs(false);
      setEditActData({
        issueTypeGuid: "",
        year: null,
        num: "",
      });
      setTableData([]);
    }
  };
  // Filtriranje broja izdanja u odnosu na izabranu Godinu/Vrstu izdanja

  const FilterNumbersByIssueType = async () => {
    setShowNumAndSearch(true);

    //ukoliko imamo sacuvanu vrstu i godinu, uzimamo za njih listu brojeva izdanja
    const storedObject = sessionStorage.getItem(filterName);
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      if (parsedObject.issueTypeGuid && parsedObject.year)
        var data = {
          issueTypeGuid: parsedObject.issueTypeGuid,
          year: parsedObject.year,
        };
    } else {
      data = {
        issueTypeGuid: editActData.issueTypeGuid,
        year: editActData.year,
      };
    }
    !isCurrent && GetYearsByIssueType(data.issueTypeGuid);
    const response = await clientNumber.FilterNumbersByIssueTypeAndYear(data);
    var result = response.data.map((item) => {
      return { value: parseInt(item.num), label: parseInt(item.num) };
    });
    result.length > 0 && setNumList(result);
  };

  // Tabela

  const filterTableActsData = async (pageNumber, pageSize, sortBy) => {
    setShowInputs(true);
    const data = {
      issueTypeGuid: editActData.issueTypeGuid,
      year: editActData.year,
      num: editActData.num,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    };
    let response = await clientLaw.filterLawAdActs(data);
    let handledResponse = HandlingResponse(response);
    setFkNumber(handledResponse.dataList[0].fkNumber);
    return handledResponse;
  };

  const searchData = async () => {
    setShowNumAndSearch(true);
    var result = await filterTableActsData(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(result);
  };

  // Modal
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  // Izmena akta

  const onEditClick = async (row) => {
    var response = await clientLaw.getLawAdActWithAttachments(row.id);
    setEdit(true);
    setRowData(response.data);
    showModal();
  };

  const onCreateClick = async () => {
    setEdit(false);
    setRowData({
      lawAdActName: "",
      isLaw: true,
      itemNumber: 0,
      ogPageNumber: 0,
      lawAdActAuthorityGuid: undefined,
      advertisementAndActTypeGuid: undefined,
      htmlLawAdActUrl: "",
      fkNumber: fkNumber,
    });
    showModal();
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    searchData();
  };

  const handleNumListChange = (selectedOption) => {
    setNumId(selectedOption.id);
    setEditActData({
      ...editActData,
      num: selectedOption.value,
    });
    //resetujemo filter koji je bio sacuvan za tabelu za prethodni broj
    removeTableObjectsFromSessionStorage(tableName);
    setTableData([]);
    setShowInputs(false); // Sakriva inpute kada se selectuje novi element iz nuMList
  };

  const GetYearsByIssueType = async (issueTypeGuid) => {
    if (isCurrent)
      var response = await clientNumber.GetCurrentYearsByIssueType(
        issueTypeGuid
      );
    else
      response = await clientNumber.GetArchiveYearsByIssueType(issueTypeGuid);
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      let array = handledResponse.years.map((x) => {
        return { value: x, label: x };
      });
      setYearList(array);
    }
  };

  return (
    <>
      <div className="form-group">
        <label>Врста издања</label>
        <Select
          options={issueTypeList}
          onChange={(e) => {
            setEditActData({
              ...editActData,
              issueTypeGuid: e.value,
              year: null,
              num: null,
            });
            setShowNumAndSearch(false);
            GetYearsByIssueType(e.value);
          }}
          value={issueTypeList.filter(
            (x) => x.value === editActData.issueTypeGuid
          )}
        />
      </div>

      <div className="form-group">
        <label>{isCurrent ? "Година издања" : "Година"}</label>
        <Select
          options={yearList}
          onChange={(e) => {
            setEditActData({
              ...editActData,
              year: e.value,
              num: null,
            });
            !isCurrent && setShowNumAndSearch(false);
            !isCurrent && setShowInputs(false);
          }}
          value={yearList.filter((x) => x.value === editActData.year)}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary"
        disabled={!editActData.year}
        onClick={() => {
          sessionStorage.setItem(filterName, JSON.stringify(editActData));
          FilterNumbersByIssueType();
        }}
      >
        Пронађи издања
      </button>
      {showNumAndSearch && (
        <div className="form-group">
          <label>Број издања</label>
          <Select
            options={numList}
            onChange={(e) => {
              setEditActData({ ...editActData, num: e.label });
              setNumId(e.value);
              handleNumListChange(e);
            }}
            value={numList.filter((x) => x.label === editActData.num)}
            isDisabled={!editActData.issueTypeGuid}
          />
        </div>
      )}
      {showNumAndSearch && (
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            disabled={!editActData.num}
            onClick={() => {
              sessionStorage.setItem(filterName, JSON.stringify(editActData));
              searchData();
            }}
          >
            Пронађи
          </button>
        </div>
      )}
      {showInputs && (
        <>
          {!isCurrent && (
            <button className="btn btn-primary" onClick={onCreateClick}>
              Додај акт
            </button>
          )}
          <Table
            columns={columns}
            data={tableData}
            tableName={tableName}
            rememberFilter={true}
            fetchFunction={filterTableActsData}
            serverSide={true}
            setData={setTableData}
          />
        </>
      )}
      <SideModal
        show={show}
        handleClose={hideModal}
        title={edit ? "Измена акта" : "Креирање акта"}
      >
        <CreateAct
          data={rowData}
          edit={edit}
          show={show}
          numId={numId}
          handleCallBack={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default EditActs;
