import api from "../../../../api";
import { env } from "../../../../env";
const baseurl = env.REACT_APP_API_URL_FP;

class DefaultInterestClient {

    async GetInterestRateHistorys(isBackground) {
        try{
            var response = await api.get(baseurl + "/InterestRateHistory/GetInterestRateHistorys", {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetInterestRateHistoryById(id, isBackground){
        try{
            var response = await api.get(baseurl + "/InterestRateHistory/" + id, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async FilterInterestRateHistory(data, isBackground) {
        try {
            var response = await api.post(baseurl + "/InterestRateHistory/FilterInterestRateHistory", data, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async CreateInterestRateHistory(data, isBackground) {
        try{
            var response = await api.post(baseurl + "/InterestRateHistory/CreateInterestRateHistory", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async UpdateInterestRateHistory(data, isBackground) {
        try{
            var response = await api.put(baseurl + "/InterestRateHistory/UpdateInterestRateHistory", data, {isBackground: isBackground})
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async DeleteInterestRateHistory(id, isBackground){
        try{
            var response = await api.delete(baseurl + "/InterestRateHistory/DeleteInterestRateHistory/" + id, {isBackground: isBackground});
            return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async GetLastDateToInterestRateHistory(isBackground) {
      try{
        var response = await api.get(baseurl + "/InterestRateHistory/GetLastDateToInterestRateHistory", {isBackground: isBackground});
        return response.data;
      }
      catch (err) {
        if(err.response.status === 400) return err.response.data;
        else return err.response;
      }
    }
}

export default DefaultInterestClient;