import styled from "styled-components";

export const NewFileBtn = styled.button`
    color: #00c58f;
    font-weight: bold;
    background-color: transparent;
    border: none;

    &:hover {
        color: #0ca275;
    }
    
    svg {
        margin-right: 5px;
    }

    &:disabled {
        cursor: no-drop; 
    }
`

export const DeleteButton = styled.button`
    color: red;
    font-weight: bold;
    background-color: transparent;
    border: none;
`

export const DownloadButton = styled.button`
color: #00c58f;
font-weight: bold;
background-color: transparent;
border: none;
`