import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_FP;

class SettingsFPAClient {

    async getAll(isBackground) {
        try {
          var response = await api.get(baseurl + "/Settings/GetAll", {isBackground: isBackground});
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateSetting(data, isBackground) {
        try {
          var response = await api.put(baseurl + "/Settings/Update", data, { isBackground: isBackground });
          return response.data;
        }
        catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
      }
}

export default SettingsFPAClient;