import React, { useState, useEffect } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import DatePicker from "react-datepicker";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";


const CreateGroupAccounts = () => {
    const [editionsList, setEditionsList] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const [buyer, setBuyer] = useState("");
  
    const [account, setAccount] = useState({
      edition: undefined,
      year: new Date(),
      numberOfAccounts: "",
      paymentDate: new Date(),
      customerNo: "",
      customerName: "",
      customerPib: "",
      validFrom: new Date(),
      validTo: new Date(),
      comment: "",
    });
  
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const schema = {
      edition: Joi.required(),
      year: Joi.date().required(),
      numberOfAccounts: Joi.number().min(0).max(2147483647).required(),
      customerNo: Joi.string().required(),
      customerName: Joi.string().required(),
      customerPib: Joi.string().required(),
      validFrom: Joi.date().required(),
      validTo: Joi.date().required(),
      comment: Joi.string().allow("").optional(),
    };
  
    const handleReset = () => {
      setAccount({
        edition: undefined,
        year: new Date(),
        numberOfAccounts: "",
        paymentDate: new Date(),
        customerNo: "",
        customerName: "",
        customerPib: "",
        validFrom: new Date(),
        validTo: new Date(),
        comment: "",
      });
    };
  
    const client = new CreateClient();
  
    const CreateAccounts = async (approve) => {
      const errorsResult = validateForm(account, schema, t);
      setErrors(errorsResult);
  
      if (!errorsResult) {
        const data = {
          ...account,
          packageId: account.edition,
          year: account.year.getFullYear(),
          numberOfUsers: parseInt(account.numberOfAccounts),
        };
  
        let response = await client.createGroupAccounts(approve, data);
        let handledResponse = HandlingResponse(response);
        if (handledResponse.code == 408) {
          var result = serverValidation(handledResponse.data, t);
          setErrors(result);
        } else handleReset();
      }
    };
  
    const getEditionsList = async () => {
      let response = await client.GetAllGroupPackages();
      let res = HandlingResponse(response);
      var result = res
        .map((item) => {
          return { value: item.id, label: item.extCode, name: "edition" };
        });
      setEditionsList(result);
    };
  
    useEffect(() => {
      getEditionsList();
      getAllCustomersFromNav();
    }, []);
  
    const handlePropertyChange = (event, date) => {
      const obj = date
        ? { name: date, value: event }
        : { name: event.name, value: event.value };
      const result = handleSave(obj, account, errors, schema, t);
      setAccount(result.data);
      setErrors(result.errors);
    };
  
    const getAllCustomersFromNav = async () => {
      let response = await client.GetAllCustomersFromNav();
      var customersArray = response.map((item) => {
        return {value: item.customerValue, label:item.customerText}
      })
      setCustomersList(customersArray);
    }
  
    const getCustomersFromNavByNo = async (customerNo) => {
      let response = await client.GetCustomersFromNavByNo(customerNo);
      setAccount({
        ...account,
        customerNo: response.customerNo,
        customerName: response.customerName,
        customerPib: response.customerPIB
      })
    }
  
    return (
      <>
        <h2>Групно креирање корисничких налога</h2>
        <label> Поља са * су обавезна</label>
        <div className="form-group">
              <label>Изабери купца</label>
              <Select
                options={customersList}
                onChange={(e) => {
                  setBuyer(e.value );
                  getCustomersFromNavByNo(e.value)
                }}
                value={customersList.filter((x) => x.value === buyer)}
              />
            </div>
            <div className="form-group">
          <label>Број купца<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="customerNo"
            value={account.customerNo}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.customerNo && (
            <div className="text-danger">{errors.customerNo}</div>
          )}
        </div>
        <div className="form-group">
          <label>Назив фирме<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="customerName"
            value={account.customerName}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.customerName && (
            <div className="text-danger">{errors.customerName}</div>
          )}
        </div>
        <div className="form-group">
          <label>ПИБ<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="customerPib"
            value={account.customerPib}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.customerPib && (
            <div className="text-danger">{errors.customerPib}</div>
          )}
        </div>
        <div className="form-group">
          <label>Изабери пакет<span className="required-asterisk">*</span></label>
          <Select
            options={editionsList}
            onChange={(e) => handlePropertyChange(e)}
            value={editionsList.filter((x) => x.value === account.edition)}
          />
          {errors?.edition && <div className="text-danger">{errors.edition}</div>}
        </div>
        <div className="form-group">
          <label>Изабери годину</label>
          <DatePicker
            className="form-control"
            selected={account.year}
            onChange={(date) => handlePropertyChange(date, "year")}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={10}
          />
          {errors?.year && <div className="text-danger">{errors.year}</div>}
        </div>
        <div className="form-group">
          <label>Број налога за креирање<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="numberOfAccounts"
            value={account.numberOfAccounts}
            onChange={(e) => handlePropertyChange(e.target)}
          />
          {errors?.numberOfAccounts && (
            <div className="text-danger">{errors.numberOfAccounts}</div>
          )}
        </div>
        
        <div className="form-group">
          <label>Датум уплате</label>
          <CustomDatePicker
            selected={account.paymentDate}
            onChange={(date) => setAccount({ ...account, paymentDate: date })}
            showMonthDropdown
            useShortMonthInDropdown
          />
        </div>
        <div className="form-group">
          <label>Налози важе од<span className="required-asterisk">*</span></label>
          <CustomDatePicker
            selected={account.validFrom}
            onChange={(date) => handlePropertyChange(date, "validFrom")}
            showMonthDropdown
            useShortMonthInDropdown
          />
          {errors?.validFrom && (
            <div className="text-danger">{errors.validFrom}</div>
          )}
        </div>
        <div className="form-group">
          <label>Налози важе до<span className="required-asterisk">*</span></label>
          <CustomDatePicker
            selected={account.validTo}
            onChange={(date) => handlePropertyChange(date, "validTo")}
            showMonthDropdown
            useShortMonthInDropdown
          />
          {errors?.validTo && <div className="text-danger">{errors.validTo}</div>}
        </div>
        <div className="form-group">
          <label>Коментар</label>
          <textarea
            className="form-control"
            name="comment"
            value={account.comment}
            onChange={(e) => handlePropertyChange(e.target)}
          ></textarea>
          {errors?.comment && <div className="text-danger">{errors.comment}</div>}
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              CreateAccounts(false);
            }}
          >
            Креирај захтев
          </button>
          <button
            type="button"
            className="btn btn-primary m-2"
            onClick={() => {
              CreateAccounts(true);
            }}
          >
            Креирај и одобри захтев
          </button>
        </div>
      </>
    );
};

export default CreateGroupAccounts;