import { useState, useEffect, useRef } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import "react-datepicker/dist/react-datepicker.css";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import "react-dropzone-uploader/dist/styles.css";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import {
  NewFileBtn,
  DeleteButton,
} from "../../../common/multiple-files/multipleFiles.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

const CreatePackage = ({
  data,
  edit,
  file,
  listOfProducts,
  show,
  handleCallback,
}) => {
  const statusOptions = [
    {
      value: 0,
      label: "Активно",
    },
    {
      value: 1,
      label: "Неактивно",
    },
  ];
  const packageTypes = [
    {
      value: "PRODUCTS",
      label: "За производе",
    },
    {
      value: "NUMBERS",
      label: "За издања",
    },
  ];

  const [issueTypeList, setIssueTypeList] = useState([]);
  const [packageImage, setPackageImage] = useState(null);
  const [products, setProducts] = useState([]);

  const [packageData, setPackageData] = useState({
    extCode: "",
    shortDescription: "",
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    sort: 0,
    price: 0,
    packageType: "PRODUCTS",
    issueTypeGuid: "",
    id: 0,
    isGroup: false,
    packageStatus: 1,
    product: []
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    extCode: Joi.string().required(),
    shortDescription: Joi.string().required(),
    description: Joi.string().required(),
    price: Joi.number().min(0).max(2147483647).required(),
    packageImage: Joi.optional(),
    dateFrom: Joi.date().required(),
    dateTo: Joi.date().required(),
    sort: Joi.number().min(0).max(99999),
  };

  const imageInputRef = useRef(null);

  const client = new PackageClient();

  const getIssueTypes = async () => {
    let response = await client.GetActiveProductLinks();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { value: item.guid, label: item.description };
    });
    setIssueTypeList(result);
  };

  const getProducts = async () => {
    let response = await client.GetActiveProducts();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { value: item.id, label: item.name, title: item.description };
    });
    setProducts(result);
  };

  useEffect(() => {
    getIssueTypes();
    getProducts();
  }, []);

  useEffect(() => {
    setPackageData(data);
    setErrors({});
  }, [data]);

  useEffect(() => {
    setPackageImage(file);
  }, [file]);

  useEffect(() => {
   setPackageData({...data, product: listOfProducts});
  }, [listOfProducts]);

  const createPackage = async () => {
    if (packageImage) {
      schema.packageImage = Joi.optional();
    }

    const errorsResult = validateForm(packageData, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var productsList = [];
      if (packageData.packageType === "PRODUCTS") {
        productsList =  packageData.product?.map((item) => {
          return { id: item.value };
        });
      }

      var data = {
        ...packageData,
        price: parseInt(packageData.price),
        products: productsList,
      };

      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      if (packageImage) {
        formData.append("packageImage.file", packageImage);
        if (edit) {
          formData.append("packageImage.guid", packageImage);
        }
      }

      var response;
      if (edit) {
        response = await client.UpdatePackage(formData);
      } else {
        response = await client.CreatePackage(formData);
      }
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handleCallback();
    }
  };

  const handleUploadImageClick = () => {
    imageInputRef.current.value = null;
    imageInputRef.current?.click();
  };

  const removeImageElement = () => {
    setPackageImage();
    imageInputRef.current.value = null;
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, packageData, errors, schema, t);
    setPackageData(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <div className="form-group">
        <label>Тип пакета</label>
        <Select
          options={packageTypes}
          onChange={(e) =>
            setPackageData({ ...packageData, packageType: e.value })
          }
          value={packageTypes.filter(
            (x) => x.value === packageData.packageType
          )}
        />
      </div>
      {packageData.packageType === "NUMBERS" && (
        <div className="form-group">
          <label>Врста издања</label>
          <Select
            options={issueTypeList}
            onChange={(e) =>
              setPackageData({ ...packageData, issueTypeGuid: e.value })
            }
            value={issueTypeList.filter(
              (x) => x.value === packageData.issueTypeGuid
            )}
          />
        </div>
      )}
      <div className="form-group">
        <label>Статус</label>
        <Select
          options={statusOptions}
          onChange={(e) =>
            setPackageData({ ...packageData, packageStatus: e.value })
          }
          value={statusOptions.filter(
            (x) => x.value === packageData.packageStatus
          )}
          isDisabled={!edit}
        />
      </div>
      <div className="form-group">
        <label>Кратак опис<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          name="shortDescription"
          value={packageData.shortDescription}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.shortDescription && (
          <div className="text-danger">{errors.shortDescription}</div>
        )}
      </div>
      <div className="form-group">
        <label>Опис<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          name="description"
          value={packageData.description}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.description && (
          <div className="text-danger">{errors.description}</div>
        )}
      </div>
      <div className="form-group">
        <label>Датум од<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={packageData.dateFrom}
          onChange={(date) => handlePropertyChange(date, "dateFrom")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.dateFrom && (
          <div className="text-danger">{errors.dateFrom}</div>
        )}
      </div>
      <div className="form-group">
        <label>Датум до<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={packageData.dateTo}
          onChange={(date) => handlePropertyChange(date, "dateTo")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.dateTo && <div className="text-danger">{errors.dateTo}</div>}
      </div>
      <div className="form-group">
        <label>Цена</label>
        <input
          type="number"
          className="form-control"
          name="price"
          value={packageData.price}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.price && <div className="text-danger">{errors.price}</div>}
      </div>
      <div className="form-group">
        <label>Код<span className="required-asterisk">*</span></label>
        <input
          type="text"
          className="form-control"
          name="extCode"
          value={packageData.extCode}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.extCode && <div className="text-danger">{errors.extCode}</div>}
      </div>
      <div className="form-group">
        <label>Сортирање</label>
        <input
          type="number"
          className="form-control"
          name="sort"
          value={packageData.sort}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.sort && (
          <div className="text-danger">{errors.sort}</div>
        )}
      </div>
      {packageData.packageType === "PRODUCTS" && (
        <div className="form-group">
          <label>Производи</label>
          <Select
            className={"selectProducts"}
            classNamePrefix={"selectProducts"}
            options={products}
            onChange={(e) => setPackageData({ ...packageData, product: e })}
            value={packageData.product}
            isMulti
          />
        </div>
      )}
      <div className="col-4">
        <NewFileBtn onClick={handleUploadImageClick} type="button">
          <FontAwesomeIcon icon={solid("plus")} />
          Додај слику
        </NewFileBtn>
        <input
          type="file"
          ref={imageInputRef}
          onChange={(event) => setPackageImage(event.target.files[0])}
          hidden
          accept="image/*"
          name="packageImage"
        />
        {packageImage ? (
          <>
            {packageImage.name}
            <DeleteButton
              type="button"
              onClick={() => {
                removeImageElement();
                setPackageImage(null);
              }}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        ) : (
          ``
        )}
        {errors?.packageImage && (
          <div className="text-danger">{errors.packageImage}</div>
        )}
      </div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginTop: "20px" }}
        onClick={() => createPackage()}
      >
        {edit ? "Измени" : "Креирај"}
      </button>
    </>
  );
};

export default CreatePackage;
