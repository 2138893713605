import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../../common/modal/SideModal.component";
import Table from "../../../../common/table/Table.component";
import CreateProduct from "../../../components/products-component/CreateProduct.component";
import ProductClient from "../../../api-services/coders/product/product-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const Products = () => {
  const tableName = 'SP_PRODUCTS';

  const columns = [
    {
      Header: "Врста објаве",
      accessor: "publicationKind.name",
      disableFilters: false,
    },
    {
      Header: "Назив",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Аутор",
      accessor: "author",
      disableFilters: false,
    },
    {
      Header: "Назив за портал",
      accessor: "portalName",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
        return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation
              className="btn btn-danger table-btn"
              onClick={() => onDeleteClick(row.original.id)}
              contentText={"Производ ће бити трајно обрисан."}
              handleYesText={"Обриши"}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];


  const [product, setProduct] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    name: "",
    publisher: "",
    author: "",
    isbnIssn: "",
    year: "",
    portalName: "",
    weight: "",
    publicationKind: {
      name: "",
    },
    fkPublicationKind: undefined,
    isActive: true
  });

  const client = new ProductClient();

  useEffect(() => {
    Product();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      name: "",
      publisher: "",
      author: "",
      isbnIssn: "",
      year: "",
      portalName: "",
      weight: "",
      publicationKind: {
        name: "",
      },
      fkPublicationKind: undefined,
      isActive: true
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      id: 0,
      name: "",
      publisher: "",
      author: "",
      isbnIssn: "",
      year: "",
      portalName: "",
      weight: "",
      publicationKind: {
        name: "",
      },
      fkPublicationKind: undefined,
      isActive: true
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteProduct(id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse !== 'Error') {
      let productCopy = product.filter((x) => x.id !== id);
      setProduct(productCopy);
    }
  };

  const Product = async () => {
    await client.Product().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setProduct(handledResponse);
    });
  };

  const handleCallback = (childData) => {
    hideModal();
    if (isCreate) setProduct([...product, childData]);
    else {
      let productCopy = [...product];
      let index = productCopy.findIndex((x) => x.id === childData.id);
      productCopy[index] = childData;
      setProduct(productCopy);
    }
  };

  return (
    <>
      <h4>Производи</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нови производ
        </button>
      </div>
      <Table
        columns={columns}
        data={product}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Креирање новог производа" : "Измена производа"}
      >
        <CreateProduct
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            Product();
          }}
        />
      </SideModal>
    </>
  );
};

export default Products;
