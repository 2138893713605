import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";
import { ContentContainer, MainContainer } from "./Layout.style";

const LozaLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();

  useEffect(() => {
    setAppName("УК");
    setAppPath("/loza");
  }, []);

  return (
    <>
      <MainContainer>
          <Outlet />
      </MainContainer>
    </>
  );
};

export default LozaLayout;