import Joi from "joi-browser";

class CourtPracticeModel {
    constructor(id = 0, fkLegalMatter = undefined, fkLegalSubmatter = undefined, fkLegalArea = undefined, fkActKind = undefined, hasProcedureKindDuration = true, fkProcedureKind = undefined,
     fkProcedureOutcome = undefined, actLabelAndNumber="", fkRegister = undefined, actEnactmentDate = null, actEnactmentYear = "", actCloserDesignation = "Унесите ближе одређење", isClarification = false, 
     fkClarificationCourtPracticeSubgroup, isSentence= false, isDocumentConstruction = false, fkSentenceCourtPracticeSubgroup, isLegalStance= false, fkLegalStanceCourtPracticeSubgroup, legalUnderstandingAdoptionDate=null, 
     showAsNewest = false, isApproved= false, archived = false, editorialNote = "", locked =false,  lockUserFullName = "", userFullName ="", userGuid=null, 
     ) {
        this.id = id;
        this.fkLegalMatter = fkLegalMatter;
        this.fkLegalSubmatter = fkLegalSubmatter;
        this.fkLegalArea = fkLegalArea;
        this.fkActKind = fkActKind;
        this.hasProcedureKindDuration = hasProcedureKindDuration;
        this.fkProcedureKind = fkProcedureKind;
        this.fkProcedureOutcome = fkProcedureOutcome;
        this.actLabelAndNumber = actLabelAndNumber;
        this.fkRegister = fkRegister;
        this.actEnactmentDate = actEnactmentDate;
        this.actEnactmentYear = actEnactmentYear;
        this.actCloserDesignation = actCloserDesignation;
        this.isClarification = isClarification;
        this.fkClarificationCourtPracticeSubgroup = fkClarificationCourtPracticeSubgroup;
        this.isSentence = isSentence;
        this.isDocumentConstruction = isDocumentConstruction;
        this.fkSentenceCourtPracticeSubgroup = fkSentenceCourtPracticeSubgroup;
        this.isLegalStance = isLegalStance;
        this.fkLegalStanceCourtPracticeSubgroup = fkLegalStanceCourtPracticeSubgroup;
        this.legalUnderstandingAdoptionDate = legalUnderstandingAdoptionDate;
        this.showAsNewest = showAsNewest;
        this.isApproved = isApproved;
        this.archived = archived;
        this.editorialNote = editorialNote;
        this.locked = locked;
        this.lockUserFullName = lockUserFullName;
        this.userFullName = userFullName;
        this.userGuid = userGuid;
    }
}

class CourtPracticeCourtModel {
    constructor(courtKind = new CourtKindModel(), court = new CourtModel(), courtDepartment = new CourtDepartmentModel()) {
        this.courtKind = courtKind;
        this.court = court;
        this.courtDepartment = courtDepartment;
    }
}

class CourtPracticePublicationModel {
    constructor(id = 0, ordinalNumber = 0, information, page = "", isPrimary = false, product = new ProductModel()) {
        this.id = id;
        this.ordinalNumber = ordinalNumber;
        this.information = information;
        this.page = page;
        this.isPrimary = isPrimary;
        this.product = product;
        this.publicationKind = undefined;
    }

}

class ProductModel {
    constructor(id = 0, name = "") {
        this.id = id;
        this.name = name;
    }
}

class CourtKindModel {
    constructor(id = 0, name = "") {
        this.id = id;
        this.name = name;
    }
}
class CourtModel {
    constructor(id = 0, name = "") {
        this.id = id;
        this.name = name;
    }
}
class CourtDepartmentModel {
    constructor(id = 0, name = "") {
        this.id = id;
        this.name = name;
    }
}
export const courtePracticeSchema = {
    internalLabelFile: Joi.required(),
    fkLegalMatter: Joi.required(),
    fkLegalArea: Joi.required(),
    actLabelAndNumber: Joi.optional(),
    actCloserDesignation: Joi.string().required(),
    fkSentenceCourtPracticeSubgroup: Joi.any().when("isSentence", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
    fkClarificationCourtPracticeSubgroup: Joi.any().when("isClarification", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
    fkLegalStanceCourtPracticeSubgroup: Joi.any().when("isLegalStance", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
    relevantLawsName: Joi.string().required(),
    courtPracticeCourts: Joi.array().min(1).unique(),
    selectOneAtLeast: Joi.any().when('isClarification', {
        is: true,
        then: Joi.optional(),
        otherwise: Joi.any().when('isSentence', {
            is: true,
            then: Joi.optional(),
            otherwise: Joi.any().when('isLegalStance', {
                is: true,
                then: Joi.optional(),
                otherwise: Joi.any().when('isDocumentConstruction', {
                    is: true,
                    then: Joi.optional(),
                    otherwise: Joi.required()
                })
            })
        })
    }),
    primaryRequest: Joi.any().when('primaryPublicationExist', {
        is: true,
        then: Joi.optional(),
        otherwise: Joi.required()
    })
  };

  export const courtPracticePublicationsSchema = {
    ordinalNumber: Joi.number().min(0).max(2147483647).required(),
    publicationKind: Joi.required(),
  }

export { CourtPracticeModel, CourtPracticeCourtModel, CourtPracticePublicationModel }