import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useStateOfRegulationsTree from "../../hooks/useStateOfRegulationsTree";
import { TreeContainer } from "../../../REGISTRIES/components/tree/Tree.style";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import LawActClient from "../../api-services/law-act/law-act-client";
import AreaClient from "../../api-services/area/area-client";
import Tree from "../../components/tree/Tree.component";
import DialogModal from "../../../common/modal/Modal.component";
import CreateArea from "../../components/create-area/CreateArea.component";
import EditArea from "../../components/edit-area/EditArea.component";
import DeleteArea from "../../components/delete-area/DeleteArea.component";
import CreateAct from "../../components/create-act/CreateAct.component";
import { RegButtonsContainer } from "../../../REGISTRIES/pages/index/Index.style";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import useMoveRegulationsLawActs from "../../hooks/useMoveRegulationsLawActs";
import UserService from "../../../utils/authentification/keycloak";
import IndexingStatus, {
  StatusForIndexing,
} from "../../../common/indexing-status/IndexingStatus";
import Table from "../../../common/table/Table.component";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import MovingSelectedRegulationsActs from "../../components/move-selected-regulations-acts/MovingSelectedRegulationsActs.component";
import SideModal from "../../../common/modal/SideModal.component";
import UseLockingAreaContext from "../../hooks/useLockingAreaContext";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";

const Index = () => {
  const {
    enableMoving,
    setEnableMoving,
    movingErrorMessage,
    moveLawActs,
    onCancelMoving,
    isInactive,
  } = useMoveRegulationsLawActs();
  const { actId, rightActId, changeArrayOfAreaIds,changeRightActId, removeSelectedAct } =
    useStateOfRegulationsTree();
  const navigate = useNavigate();
  const { lockArea } = UseLockingAreaContext();

  const versionColumns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "locked",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.locked ? (
              <FontAwesomeIcon
                title={`Документ је закључаo ${row.original.lockUserName}`}
                className={`${
                  row.original.locked &&
                  row.original.lockUserGuid === UserService.getUserGuid()
                    ? "locked-icon-by-myself"
                    : "locked-icon"
                }`}
                icon={solid("lock")}
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      Header: "Верзија прописа",
      accessor: "versionNumber",
    },
    {
      Header: "Актуелна верзија",
      accessor: "isPublishable",
      Cell: ({ row }) => {
        return (
          row.original.isPublishable && (
            <FontAwesomeIcon icon={solid("check")} />
          )
        );
      },
    },
    {
      Header: "Назив прописа",
      accessor: "lawActName",
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "preview",
      disableSortBy: true,
      className: "six-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Преглед и измена акта"
              className="btn btn-primary table-btn"
              onClick={() => getLawAct(row.original.id)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>

            <button
              title={
                row.original.locked
                  ? "Не можете креирати нову верзију када је акт закључан"
                  : "Креирај нову верзију из изабране"
              }
              className="btn btn-primary table-btn"
              onClick={() => createNewLawActVersion(row.original.id)}
              type="button"
              disabled={row.original.locked}
            >
              <FontAwesomeIcon icon={solid("code-fork")} />
            </button>

            <ButtonWithComfirmation
              title={
                row.original.isPublishable
                  ? "Верзија је већ актуелна"
                  : "Постави верзију за актуелну"
              }
              className="btn btn-primary table-btn"
              onClick={() => changeLawActVersionToActual(row.original.id)}
              disabled={row.original.isPublishable ? "disabled" : ""}
              contentText={
                "Да ли сте сигурни да желите да прогласите ову верзију за актуелну?"
              }
              handleYesText={"Прогласи верзију актуелном"}
            >
              <FontAwesomeIcon icon={solid("check")} />
            </ButtonWithComfirmation>

            <RenderOnRole roles={["Regulations_Admin"]}>
              <ButtonWithComfirmation
                title={
                  row.original.isPublishable
                    ? "Не можете обрисати актуелну верзију"
                    : "Обриши"
                }
                className="btn btn-danger table-btn"
                onClick={() => onDeleteClick(row.original.id)}
                disabled={row.original.isPublishable ? "disabled" : ""}
                contentText={"Верзија прописа ће бити трајно обрисана."}
                handleYesText={"Обриши"}
              >
                <FontAwesomeIcon icon={solid("trash")} />
              </ButtonWithComfirmation>
            </RenderOnRole>

            <button
              title="Индексирај"
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexLawAct(row.original.id)}
              disabled={
                row.original.isPublishable
                  ? !StatusForIndexing.includes(row.original.indexStatus)
                  : true
              }
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];
  const [treeData, setTreeData] = useState([]);
  const [rightTreeData, setRightTreeData] = useState([]);
  const [listOfActVersions, setListOfActVersions] = useState([]);
  const [areaId, setAreaId] = useState();
  const [editAreaData, setEditAreaData] = useState();
  const [createData, setCreateData] = useState();
  const [createSubarea, setCreateSubarea] = useState(false);

  const [messageForSearch, setMessageForSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [searchAct, setSearchAct] = useState("");
  const [searchDate, setSearchDate] = useState(new Date());

  const [info, setInfo] = useState({
    id: null,
    areaId: null,
    hierarchyMark: "1",
  });

  const [showDialogCreateAct, setShowDialogCreateAct] = useState(false);
  const [showDialogChangeAreaName, setShowDialogChangeAreaName] =
    useState(false);
  const [showDialogCreateArea, setShowDialogCreateArea] = useState(false);
  const [showDialogDeleteArea, setShowDialogDeleteArea] = useState(false);
  const [showDialogSearchAct, setShowDialogSearchAct] = useState(false);
  const [showDialogInvalidAct, setShowDialogInvalidAct] = useState(false);

  const [showDialogMoveAct, setShowDialogMoveAct] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [isTreeEmpty, setIsTreeEmpty] = useState(false);

  const lawActClient = new LawActClient();
  const areaClient = new AreaClient();

  useEffect(() => {
    getTree();
    rightActId && getActsVersions(rightActId);
  }, []);

  useEffect(() => {
    resetFilteredValue();
  }, [actId]);

  const ShowDialogCreateAct = () => {
    setShowDialogCreateAct(true);
  };
  const HideDialogCreateAct = () => {
    setShowDialogCreateAct(false);
  };

  const ShowDialogChangeAreaName = (node) => {
    setAreaId(node.id);
    setEditAreaData(node);
    setShowDialogChangeAreaName(true);
  };
  const HideDialogChangeAreaName = () => {
    setShowDialogChangeAreaName(false);
  };

  const ShowDialogCreateArea = (id, children) => {
    setCreateData({ id: id, children: children });
    setShowDialogCreateArea(true);
  };
  const HideDialogCreateArea = () => {
    setShowDialogCreateArea(false);
  };

  const ShowDialogDeleteArea = (id) => {
    setAreaId(id);
    setShowDialogDeleteArea(true);
  };
  const HideDialogDeleteArea = () => {
    setShowDialogDeleteArea(false);
  };

  const ShowDialogSearchAct = () => {
    setShowDialogSearchAct(true);
  };
  const HideDialogSearchAct = () => {
    setShowDialogSearchAct(false);
  };

  const ShowDialogInvalidAct = () => {
    setShowDialogInvalidAct(true);
  };
  const HideDialogInvalidAct = () => {
    setShowDialogInvalidAct(false);
  };

  const ShowDialogMoveAct = () => {
    setShowDialogMoveAct(true);
  };
  const HideDialogMoveAct = () => {
    setShowDialogMoveAct(false);
  };

  const ShowDialogAlert = () => {
    setShowAlert(true);
  };
  const HideDialogAlert = () => {
    setShowAlert(false);
  };

  const getTree = async () => {
    let response = await areaClient.getTree();
    let handledResponse = HandlingResponse(response);
    if (handledResponse && handledResponse !== "Error") {
      setTreeData(handledResponse);
      handledResponse.length === 0
        ? setIsTreeEmpty(true)
        : setIsTreeEmpty(false);
    }
  };

  const filterByAreaId = async (id) => {
    await lockArea(id);
    let response = await lawActClient.filterByAreaId(id);
    let handledResponse = HandlingResponse(response);
    setRightTreeData(handledResponse);
  };

  const onActCreateClick = (data, createSubarea) => {
    ShowDialogCreateAct();
    setInfo(data);
    setCreateSubarea(createSubarea);
  };

  const getActsVersions = async (id, isSubarea) => {
    if (!isSubarea) {
      let response = await lawActClient.getLawActVersions(id);
      let res = HandlingResponse(response);
      if (res != "Error") setListOfActVersions(res);
      else setListOfActVersions([]);
    } else setListOfActVersions([]);
  };

  const onDeactivateLawAct = async (id) => {
    let response = await lawActClient.deactivateLawAct(id);
    let res = HandlingResponse(response);
    filterByAreaId(actId);
  };

  const doubleClickOnFilteredAct = async (areaId, nodeId) => {
    let response = await areaClient.getAreaParents(areaId);
    let res = HandlingResponse(response);
    changeArrayOfAreaIds(res);
    await filterByAreaId(areaId);
   getActsVersions(nodeId, false)
  };

  const getLawAct = async (id) => {
    lawActClient.getLawAct(id).then((res) => {
      var data = HandlingResponse(res);
      if (data !== "Error")
        navigate("/regulations/update?actId=" + id + "&fromWarnings=" + false, {
          state: { data },
        });
    });
  };

  const createNewLawActVersion = async (id) => {
    let response = await lawActClient.createNewLawActVersion(id);
    let handledResponse = HandlingResponse(response);
    handledResponse && getActsVersions(id);
  };

  const changeLawActVersionToActual = async (id) => {
    let response = await lawActClient.changeLawActVersionToActual(id);
    let handledResponse = HandlingResponse(response);
    changeRightActId(id);
    handledResponse && getActsVersions(id);
  };

  const onDeleteClick = async (id) => {
    let response = await lawActClient.deleteLawActVersion(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      filterByAreaId(actId);
      listOfActVersions.length > 1
        ? getActsVersions(rightActId)
        : setListOfActVersions([]);
    }
  };

  const indexLawAct = async (id) => {
    var response = await lawActClient.indexLawAct(id);
    var handledResponse = HandlingResponse(response);
    handledResponse && getActsVersions(id);
  };

  const filterByName = async (e) => {
    e?.preventDefault();
    resetFilteredValue();
    let response = await lawActClient.filterByName(searchAct, false);
    let handledResponse = HandlingResponse(response);
    if (handledResponse.length <= 0) {
      setIsSearching(true);
      setMessageForSearch("Није пронађен ниједан резултат претраге");
      setRightTreeData([]);
    } else {
      if (handledResponse.length < 99) setMessageForSearch("");
      else
        setMessageForSearch(
          "Приказано је првих 100 резултата претраге, уколико желите више унесите прецизнији назив"
        );
      if (handledResponse !== "Error") {
        setIsSearching(true);
        setRightTreeData(handledResponse);
      }
    }
    removeSelectedAct();
    setSearchAct("");
    HideDialogSearchAct();
  };

  const filterByDate = async (e) => {
    e?.preventDefault();
    resetFilteredValue();

    let response = await lawActClient.filterByExpiredValidToDate(searchDate);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      handledResponse.length <= 0 &&
        setMessageForSearch("Није пронађен ниједан резултат претраге");
      setIsSearching(true);
      setRightTreeData(handledResponse);
    }
    removeSelectedAct();
    HideDialogInvalidAct();
  };

  const resetFilteredValue = () => {
    onCancelMoving();
    setMessageForSearch("");
    setIsSearching(false);
    setEnableMoving(false);
  };

  return (
    <>
      {treeData.length > 0 ? (
        <>
          <RegButtonsContainer>
            <button
              type="button"
              className="btn btn-primary search-act"
              onClick={ShowDialogSearchAct}
            >
              Претрага прописа
            </button>
            <button
              type="button"
              className="btn btn-primary invalid-act"
              onClick={ShowDialogInvalidAct}
            >
              Провера неважећих аката
            </button>
            {enableMoving ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary move-act"
                  disabled={
                    moveLawActs.lawActsGuids.length > 0 &&
                    movingErrorMessage === ""
                      ? ""
                      : "disabled"
                  }
                  onClick={() => {
                    isInactive ? ShowDialogAlert() : ShowDialogMoveAct();
                  }}
                >
                  Премести изабране акте
                </button>
                <button
                  type="button"
                  className="btn btn-primary cancel-moving"
                  onClick={() => {
                    onCancelMoving();
                    setEnableMoving(false);
                  }}
                >
                  Одустани
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-primary move-act"
                onClick={() => setEnableMoving(true)}
              >
                Омогући премештање аката
              </button>
            )}

            <div className="text-danger">{movingErrorMessage}</div>
          </RegButtonsContainer>
          <br />
          <TreeContainer>
            <div className="row">
              <div className="col-md-4">
                <h5>Области</h5>
                <hr />
                <div className="left-tree">
                  <Tree
                    treeData={treeData}
                    getNode={filterByAreaId}
                    deleteNode={ShowDialogDeleteArea}
                    changeNode={ShowDialogChangeAreaName}
                    createNode={ShowDialogCreateArea}
                    isRight={false}
                    expanded={false}
                    isMoving={false}
                    isSearching={false}
                  />
                </div>
              </div>
              <div className="col-md-8 right-tree-container">
                {(isSearching || actId) &&
                  (rightTreeData.length > 0 ? (
                    <>
                      <h5>Прописи</h5>
                      <hr />
                      <div className="right-tree">
                        <Tree
                          treeData={rightTreeData}
                          createNode={onActCreateClick} //Dodavanje akta
                          getNode={getActsVersions} //verzije
                          deactivateNode={onDeactivateLawAct}
                          onFilteredNode={doubleClickOnFilteredAct} // dupli klik na akt koji se nalazi u rezultatu pretrage po imenu
                          isRight={true}
                          isMoving={false}
                          isSearching={isSearching}
                        />
                      </div>
                    </>
                  ) : isSearching ? (
                    <div className="text-danger">{messageForSearch}</div>
                  ) : (
                    <>
                      <h5>Прописи</h5>
                      <hr />
                      <div className="create-act-buttons">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            onActCreateClick(
                              {
                                id: null,
                                hierarchyMark: "1",
                                areaId: actId,
                                addingInEmptyArea: true,
                              },
                              false
                            )
                          }
                          type="button"
                        >
                          Додај акт
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            onActCreateClick(
                              {
                                id: null,
                                hierarchyMark: "1",
                                areaId: actId,
                                addingInEmptyArea: true,
                              },
                              true
                            )
                          }
                          type="button"
                        >
                          Додај подгрупу
                        </button>
                      </div>
                    </>
                  ))}
                <br />
                <div>
                  {listOfActVersions.length > 0 && rightActId ? (
                    <div id="versionsTable">
                      <h5>Верзије прописа</h5>
                      <hr />
                      <hr />
                      <Table
                        columns={versionColumns}
                        data={listOfActVersions}
                        rememberFilter={false}
                        tableName={"REGULATIONS_INDEX"}
                        hiddenColumn={["id"]}
                        serverSide={false}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </TreeContainer>
        </>
      ) : (
        <></>
      )}
      {isTreeEmpty && (
        <>
          <div>
            <p style={{ color: "#b71c1c" }}>
              Не постоји ниједна креирана област
            </p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => ShowDialogCreateArea(0, [])}
            >
              Креирај област
            </button>
          </div>
        </>
      )}

      <DialogModal show={showDialogCreateArea} onHide={HideDialogCreateArea}>
        <CreateArea
          data={createData}
          hide={HideDialogCreateArea}
          handleCallback={getTree}
        />
      </DialogModal>

      <DialogModal
        show={showDialogChangeAreaName}
        onHide={HideDialogChangeAreaName}
      >
        <EditArea
          data={editAreaData}
          hide={HideDialogChangeAreaName}
          handleCallback={getTree}
        />
      </DialogModal>

      <DialogModal
        show={showDialogDeleteArea}
        onHide={HideDialogDeleteArea}
        title={"Да ли сте сигурни да желите да обришете изабрану област?"}
      >
        <DeleteArea
          areaId={areaId}
          hide={HideDialogDeleteArea}
          handleCallback={getTree}
        />
      </DialogModal>

      <DialogModal
        show={showDialogCreateAct}
        onHide={HideDialogCreateAct}
        title={createSubarea ? "Додавање подобласти" : "Додавање акта"}
      >
        <CreateAct
          lawActInfo={info}
          createSubarea={createSubarea}
          hide={HideDialogCreateAct}
          handleCallback={filterByAreaId}
        />
      </DialogModal>

      <DialogModal
        show={showDialogSearchAct}
        onHide={HideDialogSearchAct}
        title={"Претрага прописа"}
      >
        <form onSubmit={filterByName}>
          <div>
            <label>Назив прописа</label>
            <div>
              <input
                className="form-control"
                type="text"
                value={searchAct}
                onChange={(e) => setSearchAct(e.target.value)}
              />
            </div>
            <br />
            <button type="submit" className="btn btn-primary pull-right">
              {" "}
              Претражи
            </button>
          </div>
        </form>
      </DialogModal>

      <DialogModal
        show={showDialogInvalidAct}
        onHide={HideDialogInvalidAct}
        title={"Провера неважећих аката"}
      >
        <form onSubmit={filterByDate}>
          <div>
            <label>Датум</label>
            <div>
              <CustomDatePicker
                selected={searchDate}
                onChange={(date) => setSearchDate(date)}
              />
            </div>
            <br />
            <button type="submit" className="btn btn-primary pull-right">
              {" "}
              Провери
            </button>
          </div>
        </form>
      </DialogModal>

      <SideModal
        show={showDialogMoveAct}
        handleClose={HideDialogMoveAct}
        title={"Премештање изабраних аката"}
      >
        {showDialogMoveAct ? (
          <MovingSelectedRegulationsActs
            hide={HideDialogMoveAct}
            handleCallback={filterByAreaId}
            handleGetLawActVersions={getActsVersions}
          />
        ) : (
          <></>
        )}
      </SideModal>

      <DialogModal
        show={showAlert}
        onHide={HideDialogAlert}
        title={"Да ли сте сигурни?"}
      >
        <p>
          Пребацивање неактивног акта ће реактивирати акт. Да ли сте сигурни да
          желите да наставите?
        </p>
        <br />
        <button type="button" className="btn" onClick={HideDialogAlert}>
          Откажи
        </button>
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={() => {
            HideDialogAlert();
            ShowDialogMoveAct();
          }}
        >
          Премести
        </button>
      </DialogModal>
    </>
  );
};

export default Index;
