import React, { useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SourceKindClient from "../../api-services/source-kind/source-kind-clinet";
import SourceClient from "../../api-services/sources/source-client";
import SideModal from "../../../common/modal/SideModal.component";
import {
  CreateDataSourceModel,
  publishingMethodSchema,
} from "../../models/CreateData";
import CreateSourceKind from "../create-source-kind/createSourceKind.component";
import { SourceKindModel } from "../../models/SourceKind";
import CreateSource from "../create-source/createSource.component";
import { SourceModel } from "../../models/Source";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import Collapse from "../../../common/collapse/Collapse.component";
import { useEffect } from "react";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";

const PublishingMethod = ({ dataSources, setDataSources, primaryPublicationExist, setPrimaryPublicationExist, handlePublicationsPrimaryChange }) => {
  const { t } = useTranslation();

  const columns = [
    {
      Header: t("Primary"),
      accessor: "primary",
      Cell: ({ row }) => {
        return row.original.primary ? t("Yes") : t("No");
      },
    },
    {
      Header: t("Name"),
      accessor: "sourceName",
    },
    {
      Header: t("Description"),
      accessor: "description",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className:"two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original, row.index)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              onClick={() => removeElement(row.index)}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </button>
          </>
        );
      },
    },
  ];

  var clientSourceKind = new SourceKindClient();
  var clientSource = new SourceClient();

  const [dataSource, setDataSource] = useState(new CreateDataSourceModel());

  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [showCreateSourceKind, setShowCreateSourceKind] = useState(false);
  const [showCreateSource, setShowCreateSource] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [allSourceKinds, setAllSourceKinds] = useState([]);
  const [sourceKind, setSourceKind] = useState(new SourceKindModel());

  const [allSources, setAllSources] = useState([]);
  const [source, setSource] = useState(new SourceModel());
  const [disableSource, setDisableSource] = useState(true);

  const [rowId, setRowId] = useState(null);
  const [errors, setErrors] = useState({});

  //const [primaryPublicationExist, setPrimaryPublicationExist] = useState(false);
  const [showMessageForPrimary, setShowMessageForPrimary] = useState(false);

  useEffect(() => {
    getAllSourceKinds();
  }, []);

  useEffect(() => {
    if(dataSources.length > 0) {
      var data = dataSources
        .map((x) => {
          return x.primary;
        })
        .some((x) => x === true);
      data && setPrimaryPublicationExist(true);
  }
  }, [dataSources]);

  useEffect(()=> {
    if(!visible) {
      setIsEdit(false);
      setDataSource(new CreateDataSourceModel());
      setDisableSource(true);
    }
  }, [visible])

  const getAllSourceKinds = async () => {
    var response = await clientSourceKind.getAllActiveSourceKinds(true);
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name, name: "sourceKindId" };
    });
    setAllSourceKinds(array);
  };

  const onChangeSourceKind = async (id, label, event) => {
    handlePropertyChange(event);
    setSource({ ...source, sourceKindId: id, sourceId: undefined });
    setDataSource({ ...dataSource, sourceKindId: id, sourceKindName: label, sourceId: undefined, sourceName: "" });
    let response = await clientSource.getAllActiveSourceBySourceKindId(
      id,
      true
    );
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name, name: "sourceId" };
    });
    setDisableSource(false);
    setAllSources(array);
  };

  const onChangeSource = (event) => {
    handlePropertyChange(event);
    setSource({ ...source, sourceId: event.id });
    setDataSource({
      ...dataSource,
      sourceId: event.value,
      sourceName: event.label,
    });
  };

  const addSourceToTable = () => {
    if(dataSource.primary) {
      if(primaryPublicationExist) {
          var primaryMessage = true;
          setShowMessageForPrimary(true);
      }
      else {
          primaryMessage = false;
          setShowMessageForPrimary(false);
          setPrimaryPublicationExist(true);
      }
  }
  else {
      primaryMessage = false;
      setShowMessageForPrimary(false);
  }
    const result = validateForm(dataSource, publishingMethodSchema, t);
    setErrors(result);
    if (!result && !primaryMessage) {
      setDataSources([...dataSources, dataSource]);
      setVisible(false);
      handlePublicationsPrimaryChange(dataSource.primary)
    }
  };

  const editSourceToTable = () => {
    const result = validateForm(dataSource, publishingMethodSchema, t);
    setErrors(result);
    if (!result) {
      let dataCopy = [...dataSources];
      dataCopy[rowId] = dataSource;
      setDataSources(dataCopy);
      setVisible(false);
      handlePublicationsPrimaryChange(dataSource.primary)
    }
  };

  const canceladdingSourceToTable = () => {
    setVisible(false);
    setIsEdit(false);
    setDisableSource(true);
    setDataSource(new CreateDataSourceModel());
    setErrors({});
  };

  const removeElement = (id) => {
    const arrayCopy = [...dataSources];
    arrayCopy.splice(id, 1);
    setDataSources(arrayCopy);
  };

  const onEditClick = async (row, id) => {
    setIsEdit(true);
    setDisableSource(false);
    allSourceKinds.filter((x) => x.value === row.sourceKindId);
    let response = await clientSource.getAllActiveSourceBySourceKindId(
      row.sourceKindId,
      true
    );
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name, name: "sourceId" };
    });
    setDisableSource(false);
    setAllSources(array);
    setSource({ ...source, sourceKindId: row.sourceKindId });
    setDataSource({ ...dataSource, sourceKindId: id, sourceKindName: row.sourceKindName });
    allSources.filter((x) => x.value === row.sourceId);
    setDataSource({
      ...dataSource,
      sourceKindId: row.sourceKindId,
      sourceKindName: row.sourceKindName,
      sourceId: row.sourceId,
      sourceName: row.sourceName,
      primary: row.primary,
      description: row.description,
    });
    setRowId(id);
    setVisible(true);
  };

  const createSourceKind = () => {
    setTitle(t("AddingSourceKind"));
    setShowCreateSourceKind(true);
  };

  const handleAddingSourceKindToList = (childData) => {
    setShowCreateSourceKind(false);
    childData.active &&
      setAllSourceKinds([
        ...allSourceKinds,
        { value: childData.id, label: childData.name, name: "sourceKindId" },
      ]);
  };

  const createSource = () => {
    setTitle(t("AddingSource"));
    setShowCreateSource(true);
  };

  const handleAddingSourceToList = (childData) => {
    setShowCreateSource(false);
    childData.active &&
      setAllSources([
        ...allSources,
        { value: childData.id, label: childData.name, name: "sourceId" },
      ]);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      dataSource,
      errors,
      publishingMethodSchema,
      t
    );
    setDataSource(result.data);
    setErrors(result.errors);
  };

  const copySourceName = (value) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <>
      <Collapse
        open={visible}
        setOpen={setVisible}
        title={
          <span>
            Додавање начина објаве
            <span style={{ color: "red" }}> *</span>
          </span>
        }
      >
        <>
          <div className="row">
            <div className="row col-5">
              <label htmlFor="sourceKind">
                {t("SourceType")}
                <span className="required-asterisk">*</span>
              </label>
              <div className="col-7">
                <Select
                  id="sourceKind"
                  options={allSourceKinds}
                  onChange={(event) => {
                    onChangeSourceKind(event.value, event.label, event);
                  }}
                  value={allSourceKinds.filter(
                    (x) => x.value === dataSource.sourceKindId
                  )}
                />
                {errors?.sourceKindId && (
                  <div className="text-danger">{errors.sourceKindId}</div>
                )}
              </div>
              <div className="col-1">
                <button
                  type="button"
                  disabled={!dataSource.sourceKindId}
                  onClick={() => copySourceName(dataSource.sourceKindName)}
                  className="btn btn-primary"
                  title="Копирај тип извора"
                >
                  <FontAwesomeIcon icon={solid("copy")} />
                </button>
              </div>
              <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={createSourceKind}
                  >
                    {t("AddType")}
                  </button>
                </div>
              </RenderOnRole>
            </div>
            <div className="row col-5">
              <label htmlFor="source">
                {t("Source")}
                <span className="required-asterisk">*</span>
              </label>
              <div className="col-7">
                <Select
                  id="source"
                  options={allSources}
                  onChange={(event) => onChangeSource(event)}
                  value={allSources.filter(
                    (x) => x.value === dataSource.sourceId
                  )}
                />
                {errors?.sourceId && (
                  <div className="text-danger">{errors.sourceId}</div>
                )}
              </div>
              <div className="col-1">
                <button
                  type="button"
                  disabled={!dataSource.sourceId}
                  onClick={() => copySourceName(dataSource.sourceName)}
                  className="btn btn-primary"
                  title="Копирај извор"
                >
                  <FontAwesomeIcon icon={solid("copy")} />
                </button>
              </div>
              <RenderOnRole roles={["MML_Admin", "MML_Sifarnici"]}>
                <div className="col-4">
                  <button
                    type="button"
                    disabled={disableSource}
                    className="btn btn-primary"
                    onClick={createSource}
                  >
                    {t("AddSource")}
                  </button>
                </div>
              </RenderOnRole>
            </div>
            <div className="col-2" style={{ paddingTop: "25px" }}>
              <input
                id="primaryCB"
                type="checkbox"
                defaultValue={dataSource.primary}
                checked={dataSource.primary}
                onChange={(event) => {
                  !event.target.checked && setShowMessageForPrimary(false);
                  setDataSource({
                    ...dataSource,
                    primary: event.target.checked,
                  });
                }}
              />
              <label htmlFor="primaryCB">{t("Primary")}</label>
            </div>
            {showMessageForPrimary ? (
              <div className="text-danger">Примарна објава већ постоји.</div>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label>{t("Description")}</label>
            <textarea
              className="form-control"
              type="text"
              name="description"
              value={dataSource.description}
              onChange={(event) => handlePropertyChange(event.target)}
            />
          </div>
          {isEdit ? (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={editSourceToTable}
              >
                {t("Save")}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addSourceToTable}
              >
                {t("Add")}
              </button>
            </>
          )}
          <button
            type="button"
            style={{ marginLeft: "10px" }}
            className="btn btn-primary"
            onClick={canceladdingSourceToTable}
          >
            {t("Cancel")}
          </button>
        </>
      </Collapse>

      {dataSources.length > 0 ? (
        <Table
          columns={columns}
          data={dataSources}
          rememberFilter={false}
          serverSide={false}
        />
      ) : (
        <></>
      )}
      
      <SideModal
        show={showCreateSourceKind}
        handleClose={() => setShowCreateSourceKind(false)}
        title={title}
      >
        <CreateSourceKind
          row={sourceKind}
          isEdit={isEdit}
          handleCallback={handleAddingSourceKindToList}
        />
      </SideModal>
      <SideModal
        show={showCreateSource}
        handleClose={() => setShowCreateSource(false)}
        title={title}
      >
        <CreateSource
          row={source}
          isEdit={isEdit}
          handleCallback={handleAddingSourceToList}
        />
      </SideModal>
    </>
  );
};

export default PublishingMethod;
