import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import InternalActClient from "../../api-services/internal-act/internal-act-client";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn } from "../../../common/multiple-files/multipleFiles.style";

const CreateInternalAct = ({handleClose, getActs}) => {
  const { currentLocation, parentIdForSubact, isListEmpty, changeLevel } = UseRenderDifferentComponent();

  const [htmlFile, setHtmlFile] = useState(null);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const htmlInputRef = useRef(null);

  const [internalActData, setInternalActData] = useState({
    internalActName: "",
    areaId: currentLocation,
    parentActId: null
  });

  const schema = {
    internalActName: Joi.string().required(),
    htmlFile: Joi.required(),
  };

  const client = new InternalActClient();
  
  useEffect(() => {
    setErrors({});
  }, [])

  useEffect(() => {
    const obj = { name: "htmlFile", value: htmlFile} ;
    const result = handleSave(obj, internalActData, errors, schema, t);
    if (!result.errors)
    setInternalActData({ ...internalActData, file: htmlFile, files: attachmentFiles, pictures: photoFiles });
  else
    setErrors(result.errors);
   
  }, [htmlFile])

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, internalActData, errors, schema, t);
    setInternalActData(result.data);
    setErrors(result.errors);
  };

  
  const createAct = async (event) => {
    event.preventDefault();
    if (htmlFile) {
      schema.htmlFile = Joi.optional();
    }

    const errorsResult = validateForm(internalActData, schema, t);
    setErrors(errorsResult);
    if (!errorsResult) {
      var dataCopy = {...internalActData, areaId: internalActData.areaId.id, parentActId: parentIdForSubact};
      setInternalActData(dataCopy);
      
      var formData = new FormData();

      serializeObjectIntoFormData(formData, dataCopy);

      htmlFile?.guid
        ? formData.append("file.guid", htmlFile?.guid)
        : formData.append("file.file", htmlFile);

      for (let i = 0; i < photoFiles.length; i++) {
        if (photoFiles[i].guid)
          formData.append("pictures[" + i + "].guid", photoFiles[i].guid);
        else formData.append("pictures[" + i + "].file", photoFiles[i]);

        formData.append("pictures[" + i + "].name", photoFiles[i].name);
      }
      for (let i = 0; i < attachmentFiles.length; i++) {
        if (attachmentFiles[i].guid)
          formData.append("files[" + i + "].guid", attachmentFiles[i].guid);
        else formData.append("files[" + i + "].file", attachmentFiles[i]);
        formData.append("files[" + i + "].name", attachmentFiles[i].name);
      }

      var response = await client.AddInternalAct(formData);
      if (response !== "Error") {
        var handledResponse = HandlingResponse(response);
        handleClose();
        isListEmpty && changeLevel();
        await getActs();
      }

      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
    }
  }; 
  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  }; 
  
  return (
    <form>
      <div>
        <label>Назив<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="internalActName"
          value={internalActData.internalActName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.internalActName && (
          <div className="text-danger">{errors.internalActName}</div>
        )}
        <br />
        <div>
          {!htmlFile && (
            <>
            <NewFileBtn onClick={handleUploadHtmlClick} type="button">
              <FontAwesomeIcon icon={solid("plus")} />
              Додај ХТМЛ фајл
            </NewFileBtn>
            или
            </>
          )}
          <input
            type="file"
            ref={htmlInputRef}
            onChange={(event) => {
              setHtmlFile(event.target.files[0]);
            }}
            hidden
            accept=".html, .htm"
            name="htmlFile"
          />
        <SubmitFile
          mainFile={htmlFile}
          setMainFile={setHtmlFile}
          photoFiles={photoFiles}
          setPhotoFiles={setPhotoFiles}
          attachmentFiles={attachmentFiles}
          setAttachmentFiles={setAttachmentFiles}
          showEditHTMLButton={false}
          ></SubmitFile>
          </div>
        {errors?.htmlFile && <div className="text-danger">{errors.htmlFile}</div>}
        <div>
          <button
            onClick={createAct}
            className="btn btn-primary pull-right"
            style={{ marginLeft: "5px" }}
          >
            Додај
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateInternalAct;
