import React, { useState, useEffect, useRef } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ActTypeClient from "../../api-services/act-type/act-type-client";
import AuthorityClient from "../../api-services/authority/authority-client";
import LawActClient from "../../api-services/law-act/law-act-client";
import useUpdateLawAct from "../../hooks/useUpdateLawAct";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import IndexingStatus, {
  StatusForIndexing,
} from "../../../common/indexing-status/IndexingStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NewFileBtn } from "../../../common/multiple-files/multipleFiles.style";

const TabActBasicData = ({
  getLawAct,
  file,
  setFile,
  files,
  setFiles,
  pictures,
  setPictures,
  actId,
  errors,
}) => {
  const actTypeClient = new ActTypeClient();
  const authorityClient = new AuthorityClient();
  const lawActClient = new LawActClient();
  const model = "lawActBasicDataModel";

  const {
    title,
    page,
    data,
    handleChangeInput,
    handleChangeList,
    handleChangeDate,
  } = useUpdateLawAct();

  const [actTypesList, setActTypesList] = useState([]);
  const [authoritiesList, setAuthoritiesList] = useState([]);
  const htmlInputRef = useRef(null);

  useEffect(() => {
    getAuthorities();
    getActTypes();
  }, []);

  const getActTypes = async () => {
    let response = await actTypeClient.getActiveActTypes(true);
    let handledResponse = HandlingResponse(response);
    let actTypes = handledResponse.map((item) => {
      return {
        value: item.id,
        label: item.description,
        name: "categoryId",
        model: model,
      };
    });
    setActTypesList(actTypes);
  };

  const getAuthorities = async () => {
    let response = await authorityClient.getActiveAuthorities(true);
    let handledResponse = HandlingResponse(response);
    let authorities = handledResponse.map((item) => {
      return {
        value: item.id,
        label: item.authorityName,
        name: "authorityId",
        model: model,
      };
    });
    setAuthoritiesList(authorities);
  };

  const indexLawAct = async () => {
    var response = await lawActClient.indexLawAct(data.lawActBasicDataModel.id);
    var handledResponse = HandlingResponse(response);
    handledResponse && getLawAct();
  };

  const downloadFilesFromRepo = async () => {
    var zipName = actId + "_" + data.lawActBasicDataModel.lawActName;
    if (zipName.length > 30) zipName = zipName.substring(0, 30);
    var response = await lawActClient.DownloadFilesFromRepo(actId, zipName);
    HandlingResponse(response);
  };

  const getLawActHtml = async () => {
    var response = await lawActClient.GetLawActHtml(
      data.lawActBasicDataModel.id
    );
    return response;
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };
  return (
    <div>
      <h4>{title[page]}</h4>
      <div>
        <label style={{ marginRight: "10px" }}>Статус индексирања: </label>
        <IndexingStatus value={data.lawActBasicDataModel.indexStatus} />
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginLeft: "10px" }}
          onClick={indexLawAct}
          disabled={
            data.lawActBasicDataModel.isPublishable
              ? !StatusForIndexing.includes(
                  data.lawActBasicDataModel.indexStatus
                )
              : true
          }
        >
          Индексирај
        </button>
      </div>
      <div className="row">
        <div className="col-6">
          <label>Доносилац</label>
          <Select
            options={authoritiesList}
            onChange={(e) =>
              e
                ? handleChangeList(e)
                : handleChangeList({
                    name: "authorityId",
                    value: null,
                    model: model,
                  })
            }
            value={authoritiesList.filter(
              (x) => x.value === data.lawActBasicDataModel.authorityId
            )}
            isClearable
          />
        </div>
        <div className="col-6">
          <label>Врста законског акта</label>
          <Select
            options={actTypesList}
            onChange={(e) =>
              e
                ? handleChangeList(e)
                : handleChangeList({
                    name: "categoryId",
                    value: null,
                    model: model,
                  })
            }
            value={actTypesList.filter(
              (x) => x.value === data.lawActBasicDataModel.categoryId
            )}
            isClearable
          />
        </div>
      </div>
      <div>
        <label htmlFor="superscript">Наднапомена</label>
        <textarea
          className="form-control"
          type="text"
          name="superscript"
          data-model={model}
          id="superscript"
          value={data.lawActBasicDataModel.superscript}
          onChange={handleChangeInput}
        />
        <label htmlFor="lawActName">
          Наслов за приказ на сајту<span className="required-asterisk">*</span>
        </label>
        <textarea
          className="form-control"
          type="text"
          name="lawActName"
          data-model={model}
          id="lawActName"
          value={data.lawActBasicDataModel.lawActName}
          onChange={handleChangeInput}
        />
        {errors && errors["lawActBasicDataModel.lawActName"] && (
          <div className="text-danger">
            {errors["lawActBasicDataModel.lawActName"]}
          </div>
        )}
        <label htmlFor="abstract">Опис</label>
        <textarea
          className="form-control"
          type="text"
          name="abstract"
          data-model={model}
          id="abstract"
          value={data.lawActBasicDataModel.abstract}
          onChange={handleChangeInput}
        />
        <label htmlFor="subscript">Поднапомена</label>
        <textarea
          className="form-control"
          type="text"
          name="subscript"
          data-model={model}
          id="subscript"
          value={data.lawActBasicDataModel.subscript}
          onChange={handleChangeInput}
        />
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <label>Датум усвајања</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.acclamationDateTime
                ? new Date(data.lawActBasicDataModel.acclamationDateTime)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "acclamationDateTime", model)
            }
          />
        </div>
        <div className="col-3">
          <label>Датум ступања на снагу</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.effectiveDateTime
                ? new Date(data.lawActBasicDataModel.effectiveDateTime)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "effectiveDateTime", model)
            }
          />
        </div>
        <div className="col-3">
          <label>Датум примене</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.applicationDateTime
                ? new Date(data.lawActBasicDataModel.applicationDateTime)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "applicationDateTime", model)
            }
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-3">
          <label>Почетак важења</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.validFromDate
                ? new Date(data.lawActBasicDataModel.validFromDate)
                : null
            }
            onChange={(date) => handleChangeDate(date, "validFromDate", model)}
          />
        </div>
        <div className="col-3">
          <label>Крај важења</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.validToDate
                ? new Date(data.lawActBasicDataModel.validToDate)
                : null
            }
            onChange={(date) => handleChangeDate(date, "validToDate", model)}
          />
        </div>
        <div className="col-3">
          <label>Верзија на снази од</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.versionValidFromDate
                ? new Date(data.lawActBasicDataModel.versionValidFromDate)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "versionValidFromDate", model)
            }
          />
        </div>
        <div className="col-3">
          <label>Почетак примене верзије</label>
          <CustomDatePicker
            selected={
              data.lawActBasicDataModel.versionApplicationDate
                ? new Date(data.lawActBasicDataModel.versionApplicationDate)
                : null
            }
            onChange={(date) =>
              handleChangeDate(date, "versionApplicationDate", model)
            }
          />
        </div>
      </div>
      <br/>
      <div>
        <label htmlFor="nameDateGazette">Име - Датум - Гласило</label>
        <input
          className="form-control"
          id="nameDateGazette"
          name="nameDateGazette"
          data-model={model}
          type="text"
          value={data.lawActBasicDataModel.nameDateGazette}
          onChange={handleChangeInput}
        />
      </div>
      <br />
      <div>
        {!file && (
          <>
            <NewFileBtn onClick={handleUploadHtmlClick} type="button">
              <FontAwesomeIcon icon={solid("plus")} />
              Додај ХТМЛ фајл
            </NewFileBtn>
            или
          </>
        )}
        <input
          type="file"
          ref={htmlInputRef}
          onChange={(event) => {
            setFile(event.target.files[0]);
          }}
          hidden
          accept=".html, .htm"
          name="file"
        />
        <SubmitFile
          mainFile={file}
          setMainFile={setFile}
          photoFiles={pictures}
          setPhotoFiles={setPictures}
          attachmentFiles={files}
          setAttachmentFiles={setFiles}
          isEdit={true && file?.guid}
          downloadFilesFromRepo={downloadFilesFromRepo}
          getHtml={getLawActHtml}
          id={data.lawActBasicDataModel.id}
          pathToNavigate={window.location.pathname}
        ></SubmitFile>
         {!file && errors?.file && <div className="text-danger">{errors.file}</div>}
      </div>
      <div className="row">
        <label htmlFor="showIdCard" className="col-2">
          <input
            id="showIdCard"
            name="showIdCard"
            data-model={model}
            type="checkbox"
            checked={data.lawActBasicDataModel.showIdCard}
            onChange={handleChangeInput}
          />
          Прикажи личну карту
        </label>
        <label htmlFor="showStructureLink" className="col-2">
          <input
            id="showStructureLink"
            name="showStructureLink"
            data-model={model}
            type="checkbox"
            checked={data.lawActBasicDataModel.showStructureLink}
            onChange={handleChangeInput}
          />
          Прикажи структуру
        </label>
        <label htmlFor="showEditorMark" className="col-2">
          <input
            id="showEditorMark"
            name="showEditorMark"
            data-model={model}
            type="checkbox"
            checked={data.lawActBasicDataModel.showEditorMark}
            onChange={handleChangeInput}
          />
          Прикажи опис
        </label>
      </div>
    </div>
  );
};

export default TabActBasicData;
