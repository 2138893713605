import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import CacheBuster from "react-cache-buster";
import packageJson from '../package.json';
import { env } from './env.js';
import { Routes, Route } from "react-router-dom";
import MainPage from "./common/pages/index/Index";
import Layout from "./common/layout/Layout.component";
import Login from "./common/pages/login/Login";
import PublicRoute from "./common/route-and-role/publicRoute";
import ProtectedRoute from "./common/route-and-role/protectedRoute";
import NotAllowed from "./common/pages/not-allowed/NotAllowed";
import LoadingSpinner from "./common/loader/Loader.component";

//#region mml-import
import Document from "./MML/pages/document/Document";
import Index from "./MML/pages/index/Index";
import MMLLayout from "./MML/components/layout/MMLLayout.component";
import Types from "./MML/pages/codebooks/codebook-types/Types";
import AuthorityPage from "./MML/pages/codebooks/authority/Authority";
import SourceKind from "./MML/pages/codebooks/source-kind/SourceKind";
import PreviewDocument from "./MML/pages/preview-document/PreviewDocument";
//#endregion

//#region sp-import
import SPLayout from "./VASKE/components/layout/Layout.component";
import Cases from "./VASKE/pages/cases/cases";
import CourtTypes from "./VASKE/pages/sifarnici/vrste-sudova/vrste-sudova";
import Sudovi from "./VASKE/pages/sifarnici/sudovi/sudovi";
import LegalMatter from "./VASKE/pages/sifarnici/legal-matter/legal-matter";
import LegalFields from "./VASKE/pages/sifarnici/legal-fields/legal-fields";
import SudskaOdeljenja from "./VASKE/pages/sifarnici/sudska-odeljenja/sudska-odeljenja";
import LegalSubMatter from "./VASKE/pages/sifarnici/legal-sub-matter/legal-sub-matter";
import LegislativeActType from "./VASKE/pages/sifarnici/legislative-act-types/legislative-act-types";
import ProcedureKind from "./VASKE/pages/sifarnici/procedure-kind/procedure-kind";
import ProcedureOutcome from "./VASKE/pages/sifarnici/procedure-outcomes/procedure-outcomes";
import TypesOfPosts from "./VASKE/pages/sifarnici/types-of-posts/types-of-posts";
import Products from "./VASKE/pages/sifarnici/products/products";
import Register from "./VASKE/pages/sifarnici/register/register";
import AdvancedSearchQuery from "./VASKE/pages/advanced-search-query/advanced-search-query";
import CreateAndUpdateCase from "./VASKE/pages/create-and-update-case/CreateAndUpdateCase";
//#endregion

// #region loza-import
import LozaLayout from "./LOZA/components/layout/Layout.component";
import CreateGroupAccounts from "./LOZA/pages/create-group-accounts/CreateGroupAccounts.jsx";
import CreateIndividualUser from "./LOZA/pages/create-individual-user/CreateIndividualUser.jsx";
import EditGroupAccounts from "./LOZA/pages/edit-group-user-accounts/EditGroupUserAccounts.jsx";
import UnprocessedRequests from "./LOZA/pages/unprocessed-requests/unprocessedRequests.jsx";
import EditUserAccount from "./LOZA/pages/edit-user-account/EditUserAccount.jsx";
import AllRequests from "./LOZA/pages/all-requests/all-requests";
import Packages from "./LOZA/pages/packages/Packages.jsx";
import PackageGroups from "./LOZA/pages/package-groups/PackageGroups.jsx";
import Product from "./LOZA/pages/products/Products";
import InternalUser from "./LOZA/pages/internal-users/InternalUsers.jsx";
import Subscribers from "./LOZA/pages/subscribers/Subscribers.jsx";
import PromoSettingsLoza from "./LOZA/pages/settings/PromoSettingsLoza.jsx";
import EditUserAccountById from "./LOZA/pages/edit-user-account-by-id/EditUserAccountById.jsx";
//#endregion

//#region admin-panel import
import AdminPanelLayout from "./ADMIN-PANEL/components/layout/Layout.component";
import AdvertisementAndActType from "./ADMIN-PANEL/pages/codebooks/advertisement-and-act-type/AdvertisementAndActType";
import AdvertisementCategories from "./ADMIN-PANEL/pages/codebooks/advertisement-categories/AdvertisementCategories";
import Authority from "./ADMIN-PANEL/pages/codebooks/authority/Authority";
import IssueType from "./ADMIN-PANEL/pages/codebooks/issue-type/IssueType";
import Country from "./ADMIN-PANEL/pages/codebooks/country/Country";
//#endregion

//#region text-editor import
import TextEditor from "./TEXT-EDITOR/pages/TinyMCE/TextEditor";
import TextEditorLite from "./TEXT-EDITOR/pages/TinyMCE/TextEditorLite";
import TextEditorLayout from "./TEXT-EDITOR/components/layout/TEXTEDITORLayout.component";
import TextEditorLiteLayout from "./TEXT-EDITOR/components/layout/TEXTEDITORLITELayout.component copy";
//#endregion

//#region slgl import
import SLGLLayout from "./SLGL/components/layout/SLGLLayout.component";
import IndexPrincess from "./SLGL/pages/princess/index/Index";
import CurrentPublications from "./SLGL/pages/publications/current-publications/CurrentPublications";
import ArchivedPublications from "./SLGL/pages/publications/archived-publications/ArchivedPublications";
import LawAdActs from "./SLGL/pages/publications/law-ad-acts/lawAdActs";
import EditHTML from "./SLGL/pages/publications/edit-html/editHTML";
import SettingsSLGL from "./SLGL/pages/settings/SettingsSLGL";
//#endregion

//#region registries import
import REGSLayout from "./REGISTRIES/components/layout/REGSLayout.component";
import REGSIndex from "./REGISTRIES/pages/index/Index";
import UpdateLawAct from "./REGISTRIES/pages/update-law-act/UpdateLawAct";
import Warnings from "./REGISTRIES/pages/warnings/Warnings";
import Settings from "./REGISTRIES/pages/settings/Settings";
import REGSIndexing from "./REGISTRIES/pages/indexing/Indexing";
import { StateOfTreeProvider } from "./REGISTRIES/context/StateOfTreeContext";
import { MoveLawActsProvider } from "./REGISTRIES/context/MoveLawActsContext";
import LockedActs from "./REGISTRIES/pages/locked-area-and-acts/LockedActs.jsx";
//#endregion

//#region Frontpage import
import FrontpageLayout from "./FRONTPAGE/components/layout/Layout.component";
import Locations from "./FRONTPAGE/pages/codebooks/locations/Locations";
import DefaultInterest from "./FRONTPAGE/pages/codebooks/default-interest/DefaultInterest";
import Categories from "./FRONTPAGE/pages/codebooks/categeories/Categories";
import Preview from "./FRONTPAGE/pages/news/preview/Preview";
import EventsCalendar from "./FRONTPAGE/pages/news/events-calendar/EventsCalendar";
import StaticPages from "./FRONTPAGE/pages/static-pages/StaticPages";
import Multimedia from "./FRONTPAGE/pages/multimedia/Multimedia";
import SettingsFPA from "./FRONTPAGE/pages/settings/SettingsFPA";

//#endregion

//#region Newsletter import
import NewsletterLayout from "./NEWSLETTER/components/layout/NewsletterLayout.component";
import RegistrationMail from "./NEWSLETTER/pages/registration-mail/RegistrationMail";
import Category from "./NEWSLETTER/pages/category/Category";
import Schedule from "./NEWSLETTER/pages/schedule/Schedule";
import ManualSend from "./NEWSLETTER/pages/manual-send/ManualSend";
import SettingsNL from "./NEWSLETTER/pages/settings/SettingsNL";
//#endregion

//#region MO import
import MOLayout from "./NADJIMO/components/layout/Layout.component";
import SearchSmallAdds from "./NADJIMO/pages/small-adds/search/SearchSmallAdds";
import PreviewSmallAdd from "./NADJIMO/pages/small-adds/preview-small-add/PreviewSmallAdd";
import Statistics from "./NADJIMO/pages/small-adds/statistics/Statistics";
import StatisticsFilterByYear from "./NADJIMO/pages/small-adds/statistics/StatisticsFilterByYear";
import StatisticsFilterByYearAndNum from "./NADJIMO/pages/small-adds/statistics/StatisticsFilterByYearAndNum";
import IndexSmallAdds from "./NADJIMO/pages/service/index-small-adds/IndexSmallAdds";
import IndexSmallAddsFuruna from "./NADJIMO/pages/service/index-small-adds-furuna/IndexSmallAddsFuruna";
//#endregion

//#region PPP import
import PPPLayout from "./PPP/components/layout/PPPLayout.component";
import PPPIndex from "./PPP/pages/index/Index";
import CreateActs from "./PPP/components/create-acts/CreateActs.component";
import PPPAdvertisementAndActType from "./PPP/pages/codebooks/advertisement-and-act-type/AdvertisementAndActType";
import PPPAuthority from "./PPP/pages/codebooks/authority/Authority";
import PPPSettings from "./PPP/pages/settings/Settings";
//#endregion

//#region regulations import
import RSLayout from "./REGULATIONS/components/layout/RSLayout.component";
import RSIndex from "./REGULATIONS/pages/index/Index";
import RSWarnings from "./REGULATIONS/pages/warnings/Warnings";
import RSSettings from "./REGULATIONS/pages/settings/Settings";
import RSIndexing from "./REGULATIONS/pages/indexing/Indexing";
import RSAdvertisementAndActType from "./REGULATIONS/pages/codebooks/act-type/ActType";
import RSAuthority from "./REGULATIONS/pages/codebooks/authority/Authority";
import RSUpdateLawAct from "./REGULATIONS/pages/update-law-act/UpdateLawAct";
import { StateOfRegulationsTreeProvider } from "./REGULATIONS/context/StateOfRegulationsTreeContext";
import { MoveRegulationsLawActsProvider } from "./REGULATIONS/context/MoveRegulationsLawActsContext";
//#endregion

//#region peng import
import PengLayout from './PENG/components/layout/PengLayout.component';
import PengIndex from './PENG/pages/index/Index';
import User from "./PENG/pages/user/User";
import Order from "./PENG/pages/order/Order";
import EditText from "./PENG/pages/index/EditText";
import LockedAreasAndActs from "./REGULATIONS/pages/locked-areas-and-acts/LockedAreasAndActs.jsx";
//#endregion

function App() {
  const isProduction = env.ENVIRONMENT === "production";
  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isProduction}
      isVerboseMode={false}//If true, the library writes verbose logs to console.
      loadingComponent={<LoadingSpinner />}
      metaFileDirectory={'.'}>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<MainPage />} />
          <Route path="/mml" element={<MMLLayout />}>
            <Route path="/mml" element={<Index />} />
            <Route path="/mml/document" name="Document" element={<Document />} />
            <Route path="/mml/document/previewDocument" name="PreviewDocument" element={<PreviewDocument />} />
            <Route path="/mml/codebooks/codebookTypes" element={<Types />} />
            <Route
              path="/mml/codebooks/authorities"
              element={<AuthorityPage />}
            />
            <Route path="/mml/codebooks/sourceKind" element={<SourceKind />} />
          </Route>
          <Route path="/sp" element={<SPLayout />}>
            <Route path="/sp" element={<Cases />} />
            <Route
              path="/sp/case"
              name="CreateAndUpdateCase"
              element={<CreateAndUpdateCase />}
            />
            <Route path="/sp/codebooks/courtKinds" element={<CourtTypes />} />
            <Route path="/sp/codebooks/courts" element={<Sudovi />} />
            <Route
              path="/sp/codebooks/courtDepartments"
              element={<SudskaOdeljenja />}
            />
            <Route path="/sp/codebooks/legalFields" element={<LegalFields />} />
            <Route path="/sp/codebooks/legalMatter" element={<LegalMatter />} />
            <Route
              path="/sp/codebooks/legalSubMatter"
              element={<LegalSubMatter />}
            />
            <Route
              path="/sp/codebooks/legislativeActType"
              element={<LegislativeActType />}
            />
            <Route
              path="/sp/codebooks/procedureKind"
              element={<ProcedureKind />}
            />
            <Route
              path="/sp/codebooks/procedureOutcome"
              element={<ProcedureOutcome />}
            />
            <Route path="/sp/codebooks/typesOfPosts" element={<TypesOfPosts />} />
            <Route path="/sp/codebooks/products" element={<Products />} />
            <Route path="/sp/codebooks/register" element={<Register />} />
            <Route
              path="/sp/advancedSearchQuery"
              element={<AdvancedSearchQuery />}
            />
          </Route>
          <Route path="/loza" element={<LozaLayout />}>
            <Route
              path="/loza/CreateGroupAccounts"
              element={<CreateGroupAccounts />}
            ></Route>
            <Route
              path="/loza/CreateUser"
              element={<CreateIndividualUser />}
            ></Route>
            <Route
              path="/loza/EditUserAccounts"
              element={<EditUserAccount />}
            ></Route>
            <Route
              path="/loza/EditUserAccountById"
              element={<EditUserAccountById />}
            ></Route>
            <Route
              path="/loza/EditGroupAccounts"
              element={<EditGroupAccounts />}
            ></Route>
            <Route path="/loza/AllRequests" element={<AllRequests />}></Route>
            <Route
              path="/loza"
              element={<UnprocessedRequests />}
            ></Route>
            <Route path="/loza/Packages" element={<Packages />}></Route>
            <Route path="/loza/PackageGroups" element={<PackageGroups />}></Route>
            <Route path="/loza/Products" element={<Product />}></Route>
            <Route path="/loza/InternalUsers" element={<InternalUser />}></Route>
            <Route path="/loza/Subscribers" element={<Subscribers />}></Route>
            <Route path="/loza/promoSettings" element={<PromoSettingsLoza />}></Route>
          </Route>
          <Route path="/adminPanel" element={<AdminPanelLayout />}>
            <Route
              path="/adminPanel"
              element={<AdvertisementAndActType />}
            />
            <Route
              path="/adminPanel/codebooks/advertisementCategories"
              element={<AdvertisementCategories />}
            />
            <Route
              path="/adminPanel/codebooks/authority"
              element={<Authority />}
            />
            <Route
              path="/adminPanel/codebooks/issueType"
              element={<IssueType />}
            />
            <Route
              path="/adminPanel/codebooks/numCountry"
              element={<Country />}
            />
          </Route>
          <Route path="/textEditor" element={<TextEditorLayout />}>
            <Route path="/textEditor" element={<TextEditor />}></Route>
          </Route>
          <Route path="/textEditorLite" element={<TextEditorLiteLayout />}>
            <Route path="/textEditorLite" element={<TextEditorLite />}></Route>
          </Route>
          <Route path="/slgl" element={<SLGLLayout />}>
            <Route path="/slgl/princess/index" element={<IndexPrincess />} />
            <Route
              path="/slgl"
              element={<CurrentPublications />}
            />
            <Route
              path="/slgl/publications/ArchivedPublications"
              element={<ArchivedPublications />}
            />
            <Route path="/slgl/publications/LawAdActs" element={<LawAdActs />} />
            {/* <Route path="/slgl/publications/EditHTML" element={<EditHTML />} /> */}
            <Route path="/slgl/settings" element={<SettingsSLGL />} />
          </Route>
          <Route path="/registries" element={<REGSLayout />}>
            <Route path="/registries" element={
              <StateOfTreeProvider>
                <MoveLawActsProvider>
                  <REGSIndex />
                </MoveLawActsProvider>
              </StateOfTreeProvider>} />
            <Route path="/registries/warnings" element={<Warnings />} />
            <Route path="/registries/update" element={<UpdateLawAct />} />
            <Route path="/registries/settings" element={<Settings />} />
            <Route path="/registries/indexing" element={<REGSIndexing />} />
            <Route path="/registries/lockedActs" element={<LockedActs />} />
          </Route>
          <Route path="/frontpage" element={<FrontpageLayout />}>
            <Route
              path="/frontpage/codebooks/categories"
              element={<Categories />}
            />
            <Route
              path="/frontpage/codebooks/defaultInterest"
              element={<DefaultInterest />}
            />
            <Route
              path="/frontpage/codebooks/locations"
              element={<Locations />}
            />
            <Route path="/frontpage" element={<Preview />} />
            <Route
              path="/frontpage/news/eventsCalendar"
              element={<EventsCalendar />}
            />
            <Route path="/frontpage/staticPages" element={<StaticPages />} />
            <Route path="/frontpage/multipedia" element={<Multimedia />} />
            <Route path="/frontpage/settings" element={<SettingsFPA />} />
          </Route>
          <Route path="/newsletter" element={<NewsletterLayout />}>
            <Route
              path="/newsletter"
              element={<RegistrationMail />}
            />
            <Route path="/newsletter/category" element={<Category />} />
            <Route path="/newsletter/schedule" element={<Schedule />} />
            <Route path="/newsletter/manualSend" element={<ManualSend />} />
            <Route path="/newsletter/settings" element={<SettingsNL />} />
          </Route>

          <Route path="/mo" element={<MOLayout />}>
            <Route path="/mo" element={<SearchSmallAdds />} />
            <Route path="/mo/smallAdds/preview" element={<PreviewSmallAdd />} />
            <Route path="/mo/smallAdds/statistics" element={<Statistics />} />
            <Route path="/mo/smallAdds/statistics/filterByYear" element={<StatisticsFilterByYear />} />
            <Route path="/mo/smallAdds/statistics/filterByYearAndNum" element={<StatisticsFilterByYearAndNum />} />
            <Route path="/mo/service/indexSmallAdds" element={<IndexSmallAdds />} />
            <Route path="/mo/service/indexSmallAddsFuruna" element={<IndexSmallAddsFuruna />} />
          </Route>
          <Route path="/ppp" element={<PPPLayout />}>
            <Route path="/ppp" element={<PPPIndex />} />
            <Route path="/ppp/CreateActs" element={<CreateActs />} />
            <Route path="/ppp/codebooks/documentType" element={<PPPAdvertisementAndActType />} />
            <Route path="/ppp/codebooks/authority" element={<PPPAuthority />} />
            <Route path="/ppp/settings" element={<PPPSettings />} />
          </Route>
          <Route path="/peng" element={<PengLayout />}>
            <Route path="/peng" element={<PengIndex />} />
            <Route path="/peng/users/*" element={<User />} />
            <Route path="/peng/order" element={<Order />} />
            <Route path="/peng/editText" element={<EditText />} />
          </Route>
          <Route path="/regulations" element={<RSLayout />}>
            <Route path="/regulations" element={
              <StateOfRegulationsTreeProvider>
                <MoveRegulationsLawActsProvider>
                  <RSIndex />
                </MoveRegulationsLawActsProvider>
              </StateOfRegulationsTreeProvider>} />
            <Route path="/regulations/update" element={<RSUpdateLawAct />} />
            <Route path="/regulations/warnings" element={<RSWarnings />} />
            <Route path="/regulations/settings" element={<RSSettings />} />
            <Route path="/regulations/indexing" element={<RSIndexing />} />
            <Route path="/regulations/codebooks/actType" element={<RSAdvertisementAndActType />} />
            <Route path="/regulations/codebooks/authority" element={<RSAuthority />} />
            <Route path="/regulations/lockedAreasAndActs" element={<LockedAreasAndActs />} />
          </Route>
        </Route>
        <Route path="/" element={<Layout />}>
          <Route path="/notAllowed" element={<NotAllowed />} />
        </Route>
      </Routes>
    </CacheBuster>
  );
}

export default App;
