import { createContext, useEffect, useState } from "react";

const SelectedNodeContext = createContext(null);

export const SelectedNodeProvider = ({ children, saveStateKey }) => {
    const [selectedNodeId, setSelectedNodeId] = useState();

    useEffect(() => {
        if (selectedNodeId && saveStateKey) {
            sessionStorage.setItem(`${saveStateKey}`, selectedNodeId);
        }
    }, [selectedNodeId])

    return <SelectedNodeContext.Provider value={{ selectedNodeId, setSelectedNodeId }}>
        {children}
    </SelectedNodeContext.Provider>
}

export default SelectedNodeContext;