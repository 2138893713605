import api from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_FP;

class MultimediaClient {

  async filterMultimedia(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/MultimediaLink/FilterMultimediaLinks", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  
  async deleteMultimedia(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/MultimediaLink/DeleteMultimediaLink/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createMultimedia(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/MultimediaLink/CreateMultimediaLink", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateMultimedia(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/MultimediaLink/UpdateMultimediaLink", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetMultibediaById(id, isBackground){
    try{
        var response = await api.get(baseurl + "/MultimediaLink/" + id, {isBackground: isBackground});
        return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  //#endregion   

}

export default MultimediaClient;