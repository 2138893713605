import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model'
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import "tinymce/plugins/lists";
import "tinymce/plugins/image";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/quickbars";
import './EditText.style.css'
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ArticleClient from "../../api-services/article/article-client";

const EditText = () => {
  const [content, setContent] = useState("");
  const [formats, setFormats] = useState([])
  const [formatsVisible, setFormatsVisible] = useState(false);
  const editorRef = useRef(null);
  const articleClient = new ArticleClient();

  const [id] = useSearchParams();
  var docId = id.get("id");

    const getHmtlFile = async () => {
       var response = await articleClient.getArticleText(docId);
       var handledResponse = HandlingResponse(response);
       setContent(handledResponse.text)
    }

  useEffect(() => {
    getHmtlFile();
  }, [])

  const editFile = async () => {
        var response = await articleClient.updateArticleText({ id: docId, text: tinymce.activeEditor?.getContent() });
        var handledResponse = HandlingResponse(response);
        if(handledResponse!== "Error")
            window.close();
  }


 const getAllFormats = () => {
    var formatsList = tinymce.activeEditor?.formatter?.get();
    formatsList && setFormats(Object.entries(formatsList).filter(([key, value]) => value[0].title))
  }

    useEffect(() => {
      getAllFormats()
    }, [formatsVisible])


    const addFormatStyle = (classKey, classValue) => {
      tinymce.activeEditor.formatter.apply(classKey);
    }


  return (
    <>
    <div className="row">
    <div className="col-md-10">
      <Editor
        id="kljuc"
        onInit={(evt, editor) => {(editorRef.current = editor)
        getAllFormats()
        }}
        initialValue={content}
        init={{
          height: 530,
          menubar: true,
          toolbar:
            "undo redo  | formatselect | " +
            "bold italic underline superscript subscript strikethrough | casechange blocks fonts fontfamily fontsize |alignleft aligncenter alignright alignjustify |" +
            " outdent indent bullist numlist|  link image |" +
            "removeformat | code searchreplace",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
            "a {text-decoration: underline}",
          plugins:
            " importcss table link searchreplace code lists image visualchars visualblocks quickbars",
          content_css: window.location.origin + "/peng.css",
          importcss_append: true,
          entity_encoding: "raw",
          selector: "textarea",
          setup: function (editor) {
            editor.on("init", function(e) {
              setFormatsVisible(true)
            })
          }

        }}
      /></div>
      <div className="col-md-2">
        {
          formats.map(([key, val]) => {
            console.log(formats)
            var combinedClasses = val[0].title + " itemList"            
            return (
              <div key={key} className="divList">
                <ul
                  key={key}
                  className={combinedClasses}
                  onClick={() => addFormatStyle(key, val[0].title)}
                >
                  {val[0].title}
                </ul>
              </div>
            );
          })
        }
      </div>
      </div>
      <button
        type="button"
        className="btnTiny btn-primary"
        style={{ marginTop: "10px" }}
        onClick={editFile}
        >
            Сачувај измене
        </button>
    </>
  );}


export default EditText;
