import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../common/table/Table.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import SideModal from "../../../common/modal/SideModal.component";
import SettingsSLGLClient from "../../api-services/settings/settings-client";
import EditSettingsSLGL from "../../components/settings/EditSettingsSLGL.component";
import LawAdActClient from "../../api-services/law-ad-act/law-ad-act-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const SettingsSLGL = () => {
  const tableName = "SLGL_SETTINGS_FPA";
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "parameterKey",
    },
    {
      Header: "Вредност",
      accessor: "parameterValue",
    },
    {
      Header: "Опис",
      accessor: "description",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            title="Измена"
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
            type="button"
          >
            <FontAwesomeIcon icon={solid("edit")} />
          </button>
        );
      },
    },
  ];

  const client = new SettingsSLGLClient();
  const lawAdActClient = new LawAdActClient();

  const [smsNotifications, setSmsNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const [settingsList, setSettingsList] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    id: 0,
    parameterKey: "",
    parameterValue: "",
    description: "",
  });

  useEffect(() => {
    getAll();
    GetNotificationSettings();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      id: 0,
      parameterKey: "",
      parameterValue: "",
      description: "",
    });
  };

  const getAll = async () => {
    let response = await client.getAll();
    let handledResponse = HandlingResponse(response);
    if (handledResponse)
      var settingsArray = handledResponse.filter(
        (x) =>
          x.parameterKey !== "NOTIFICATION_MAIL_ENABLED" &&
          x.parameterKey !== "NOTIFICATION_SMS_ENABLED"
      );
    setSettingsList(settingsArray);
  };

  const GetNotificationSettings = async () => {
    let response = await client.GetNotificationSettings();
    let handledResponse = HandlingResponse(response);
    if (handledResponse) {
      setEmailNotifications(handledResponse.mailEnabled);
      setSmsNotifications(handledResponse.smsEnabled);
    }
  };

  const onEditClick = (row) => {
    setRowData(row);
    showModal();
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    let settingsListCopy = [...settingsList];
    let index = settingsListCopy.findIndex((x) => x.id === childData.id);
    settingsListCopy[index] = childData;
    setSettingsList(settingsListCopy);
  };

  const UpdateNotificationMailParameter = async (emailEnabled) => {
    let response = await client.UpdateNotificationMailParameter(emailEnabled);
    let handledResponse = HandlingResponse(response);
    handledResponse && setEmailNotifications(emailEnabled);
  };

  const UpdateNotificationSMSParameter = async (smsEnabled) => {
    let response = await client.UpdateNotificationSMSParameter(smsEnabled);
    let handledResponse = HandlingResponse(response);
    handledResponse && setSmsNotifications(smsEnabled);
  };

  const indexAllData = async () => {
    var response = await lawAdActClient.massIndexing();
    HandlingResponse(response);
  };

  return (
    <>
      <div>
        <h5 className="col-md-2">Подешавања</h5>
        <br />
        <div>
          <input
            type="checkbox"
            value={smsNotifications}
            checked={smsNotifications}
            onChange={(event) => {
              setSmsNotifications(event.target.checked);
            }}
            style={{
              marginLeft: "5px",
              marginBottom: "15px",
              width: "18px",
              height: "18px",
            }}
            id="smsCB"
          />
          {smsNotifications ? (
            <label htmlFor="smsCB">Укључено СМС обавештење</label>
          ) : (
            <label htmlFor="smsCB">Искључено СМС обавештење</label>
          )}
          <br />
          <input
            type="checkbox"
            value={emailNotifications}
            checked={emailNotifications}
            onChange={(event) => {
              setEmailNotifications(event.target.checked);
            }}
            style={{ marginLeft: "5px", width: "18px", height: "18px" }}
            id="emailCB"
          />
          {emailNotifications ? (
            <label htmlFor="emailCB">Укључено Емаил обавештење</label>
          ) : (
            <label htmlFor="emailCB">Искључено Емаил обавештење</label>
          )}
          <br />
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  UpdateNotificationMailParameter(emailNotifications);
                  UpdateNotificationSMSParameter(smsNotifications);
                }}
              >
                Сачувај измене
              </button>
            </div>
            <div className="col-6">
            <ButtonWithComfirmation
              className="btn btn-primary pull-right"
              onClick={() => indexAllData()}
              contentText={"Покренуће се масовно индексирање свих бројева, актуелних и архивских."}
              handleYesText={"Покрени"}
              >
                Масовно индексирање
            </ButtonWithComfirmation>
            </div>
          </div>
        </div>

        <hr />
        {settingsList.length > 0 && (
          <Table
            columns={columns}
            data={settingsList}
            tableName={tableName}
            rememberFilter={true}
            hiddenColumn={["id"]}
            serverSide={false}
          />
        )}
      </div>
      <SideModal
        show={show}
        handleClose={hideModal}
        title={"Измена параметара"}
      >
        <EditSettingsSLGL
          row={rowData}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default SettingsSLGL;
