class AuthorityModel {
    constructor(id = 0, authorityName = "", description = "", sort = "", isCurrent = false, active = false, archival = false){
        this.id = id;
        this.authorityName = authorityName;
        this.description = description;
        this.sort = sort;
        this.isCurrent = isCurrent;
        this.active = active;
        this.archival = archival;
    }
}

export default AuthorityModel;