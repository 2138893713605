import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import DocumentTypeClient from "../../../MML/api-services/document-type/document-type-client";
import AuthorityClient from "../../../MML/api-services/authority/authority-client";
import { useTranslation } from "react-i18next";
import { publishedEnum } from "../../models/DataFilter";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const SearchDocument = ({
  handleCallback,
  filterData,
  setFilterData,
  resetFilters,
}) => {
  const { t } = useTranslation();
  var clientForType = new DocumentTypeClient();
  var clientForAuth = new AuthorityClient();

  const [types, setTypes] = useState([]);
  const [authorities, setAuthorities] = useState([]);

  useEffect(() => {
    getDocumentTypes();
    getAuthorities();
  }, []);

  const getAuthorities = async () => {
    let response = await clientForAuth.getAllActiveAuthorities();
    let res = HandlingResponse(response);
    let authoritiesArray = res.map((x) => {
      return { value: x.id, label: x.name, name: "authorityId" };
    });
    setAuthorities(authoritiesArray);
  };

  const getDocumentTypes = async () => {
    let response = await clientForType.getAllActiveTypes();
    let res = HandlingResponse(response);
    let typesArray = res
      .filter((x) => x.parentId === 0)
      .map((x) => {
        return { value: x.id, label: x.name, name: "typeId" };
      });
    setTypes(typesArray);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectListChange = (e) => {
    const { name, value } = e;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDatePickerChange = (date, name) => {
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const handleRadioButtonsChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: parseInt(value),
    }));
  }

  return (
    <form onSubmit={handleCallback}>
      <div className="row">
        <div className="col-4">
          <div className="row">
            <div className="col-6">
              <label>{t("fileName")}</label>
              <input
                className="form-control"
                type="text"
                value={filterData.fileName}
                name="fileName"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-6">
              <label>{t("ActLabelNumber")}</label>
              <input
                className="form-control"
                type="text"
                value={filterData.actLabelNumber}
                name="actLabelNumber"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <label>{t("ActCloserDesignation")} </label>
          <div>
            <input
              className="form-control"
              type="text"
              value={filterData.actCloserDesignation}
              name="actCloserDesignation"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="width-50">
              <label htmlFor="DocumentType">{t("Type")}</label>
              <Select
                id="DocumentType"
                options={types}
                onChange={(e) => {
                  if (!e) e = { value: 0, name: "typeId" };
                  handleSelectListChange(e);
                }}
                value={types.filter((x) => x.value === filterData.typeId)}
                isClearable
              />
            </div>
            <div className="width-50">
              <label>{t("Authority")}</label>
              <Select
                id="AuthorityType"
                options={authorities}
                onChange={(e) => {
                  if (!e) e = { value: 0, name: "authorityId" };
                  handleSelectListChange(e);
                }}
                value={authorities.filter(
                  (x) => x.value === filterData.authorityId
                )}
                isClearable
              />
            </div>
          </div>
          <div className="row">
            <div className="width-50">
              <label>{t("DateFrom")}</label>
              <CustomDatePicker
                selected={
                  filterData.startCreationDate
                    ? new Date(filterData.startCreationDate)
                    : null
                }
                onChange={(startCreationDate) => {
                  handleDatePickerChange(
                    startCreationDate,
                    "startCreationDate"
                  );
                }}
              />
            </div>
            <div className="width-50">
              <label>{t("DateTo")}</label>
              <CustomDatePicker
                selected={
                  filterData.endCreationDate
                    ? new Date(filterData.endCreationDate)
                    : null
                }
                name="endCreationDate"
                minDate={filterData.startCreationDate}
                onChange={(endCreationDate) => {
                  handleDatePickerChange(endCreationDate, "endCreationDate");
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-2" style={{ paddingTop: "30px" }}>
          <div
            style={{
              border: "1px solid #e1d6d6",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={publishedEnum.All}
                  checked={filterData.published === publishedEnum.All}
                  name="published"
                  onChange={handleRadioButtonsChange}
                />
                {"  "}
                Сви
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={publishedEnum.Published}
                  checked={filterData.published === publishedEnum.Published}
                  name="published"
                  onChange={handleRadioButtonsChange}
                />
                {"  "}
                Објављени
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={publishedEnum.Unpublished}
                  checked={filterData.published === publishedEnum.Unpublished}
                  name="published"
                  onChange={handleRadioButtonsChange}
                />
                {"  "}
                Необјављени
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          style={{ marginTop: "10px", marginLeft: "5px" }}
          className="btn btn-primary"
          type="submit"
          id="search-button"
        >
          {t("Search")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginTop: "10px", marginLeft: "5px" }}
          onClick={() => {
            resetFilters();
          }}
        >
          Поништи претрагу
        </button>
      </div>
    </form>
  );
};

export default SearchDocument;
