import React, { useEffect, useState, useRef } from "react";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import DatePicker from "react-datepicker";
import { sr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import Select from "../../../../common/custom-select-list/CustomSelectList.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../../common/validation/validation";
import PreviewNewsClient from "../../../api-services/news/preview/preview";
import { NewFileBtn } from "../../../../common/multiple-files/multipleFiles.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { serializeObjectIntoFormData } from "../../../../utils/serialize-form-data/serializeFormData";
import SubmitFile from "../../../../common/submit-file/SubmitFile";

const CreateNews = ({ row, edit, handleCallback }) => {
  const { t } = useTranslation();
  const schema = {
    publishedDate: Joi.date().required(),
  };
  const [categoriesList, setCategoriesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [mainFile, setMainFile] = useState();

  const [newsData, setNewsData] = useState({
    categories: [],
    shortText: "",
    fullText: "",
    publishedDate: new Date().toLocaleString("en-US", {
      timeZone: "Europe/Belgrade",
    }),
    published: false,
    actual: false,
    breaking: false,
    includeInSearchResult: false,
    active: false,
  });
  const htmlInputRef = useRef(null);

  var client = new PreviewNewsClient();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setNewsData({
      id: row.id,
      categories: row.categories,
      shortText: row.shortText,
      fullText: row.fullText,
      internalMark: row.internalMark,
      htmlGuid: row.htmlGuid,
      publishedDate: row.publishedDate,
      published: row.published,
      actual: row.actual,
      breaking: row.breaking,
      includeInSearchResult: row.includeInSearchResult,
      indexStatus: row.indexStatus,
      active: row.active,
    });
    setErrors({});
    if (edit) {
      setMainFile(row.mainFile);
      setAttachments(row.attachments);
      setImages(row.images);
    } else setMainFile(undefined);
  }, [row]);

  const getCategories = async () => {
    let response = await client.getCategoriesApi();
    let res = HandlingResponse(response);
    let array = res.map((x) => {
      return { value: x.id, label: x.name };
    });
    setCategoriesList(array);
  };

  const DownloadFilesFromRepo = async (id) => {
    let response = await client.DownloadFilesFromRepo(id, mainFile.name);
    HandlingResponse(response);
  };

  const downloadFilesFromRepository = () => DownloadFilesFromRepo(row?.id);

  // Create/Update

  const createNews = async () => {
    const errorsResult = validateForm(newsData, schema, t);

    setErrors(errorsResult);
    if (!newsData.publishedDate) {
      return;
    }

    if (!errorsResult) {
      var formData = new FormData();
      setNewsData({
        ...newsData,
      });

      serializeObjectIntoFormData(formData, newsData);

      if (mainFile?.guid) {
        formData.append("mainFile.guid", mainFile?.guid);
        formData.append("mainFile.name", mainFile?.name);
      } else formData.append("mainFile.file", mainFile);

      for (let i = 0; i < images.length; i++) {
        if (images[i].guid)
          formData.append("images[" + i + "].guid", images[i].guid);
        else formData.append("images[" + i + "].file", images[i]);

        formData.append("images[" + i + "].name", images[i].name);
      }
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].guid)
          formData.append("attachments[" + i + "].guid", attachments[i].guid);
        else formData.append("attachments[" + i + "].file", attachments[i]);
        formData.append("attachments[" + i + "].name", attachments[i].name);
      }

      if (edit) var response = await client.updateNews(formData);
      else var response = await client.createNews(formData);
      if (response !== "Error") {
        var handledResponse = HandlingResponse(response);
        if (handledResponse?.code == 408) {
          var result = serverValidation(handledResponse?.data, t);
          setErrors(result);
        } else handleCallback();
      }
    }
  };

  // Formatiranje datuma

  const handleInputChange = (name, value) => {
    const formattedDate =
      name === "publishedDate" && value
        ? value.toLocaleString("en-US", { timeZone: "Europe/Belgrade" })
        : null;

    const obj = { name, value: formattedDate };
    const result = handleSave(obj, newsData, errors, schema, t);
    setNewsData(result.data);
    setErrors(result.errors);
  };

  const GetNewsHtml = async () => {
    var response = await client.GetNewsHtml(newsData.id);
    return response;
  };

  const handleUploadHtmlClick = () => {
    htmlInputRef.current?.click();
  };

  return (
    <>
      <div>
        <div className="form-group">
          <label>Категорије: </label>
          <Select
            options={categoriesList}
            isMulti
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions.map(
                (option) => option.value
              );
              setNewsData((prevNewsData) => ({
                ...prevNewsData,
                categories: selectedValues,
              }));
            }}
            value={
              Array.isArray(newsData.categories)
                ? categoriesList?.filter((x) =>
                    newsData.categories.includes(x.value)
                  )
                : null
            }
            getOptionLabel={(option) => option.label}
          />
        </div>
        <div className="form-group">
          <label>Скраћени текст вести</label>
          <input
            type="text"
            className="form-control"
            name="shortText"
            value={newsData.shortText}
            onChange={(e) =>
              setNewsData({ ...newsData, shortText: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label>Пун текст вести</label>
          <textarea
            type="text"
            className="form-control"
            name="fullText"
            value={newsData.fullText}
            onChange={(e) =>
              setNewsData({ ...newsData, fullText: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label>Датум објављивања:</label>
          <DatePicker
            locale={sr}
            selected={
              newsData.publishedDate ? new Date(newsData.publishedDate) : null
            }
            onChange={(date) => handleInputChange("publishedDate", date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            dateFormat="dd.MM.yyyy. HH:mm"
            timeCaption="време"
            className="form-control"
          />
          {errors?.publishedDate && (
            <div className="text-danger">{errors.publishedDate}</div>
          )}
          <div style={{ marginTop: "15px" }}>
            <input
              id="publishedNews"
              type="checkbox"
              value={newsData.published}
              checked={newsData.published}
              onChange={(event) => {
                setNewsData({
                  ...newsData,
                  published: event.target.checked,
                });
              }}
            />
            <label htmlFor="publishedNews" style={{ marginRight: "15px" }}>
              Објави на порталу
            </label>
            <input
              id="actualNews"
              type="checkbox"
              value={newsData.actual}
              checked={newsData.actual}
              onChange={(event) => {
                setNewsData({
                  ...newsData,
                  actual: event.target.checked,
                });
              }}
            />
            <label htmlFor="actualNews" style={{ marginRight: "15px" }}>
              Актуелна вест
            </label>
            <input
              id="breakingNews"
              type="checkbox"
              value={newsData.breaking}
              checked={newsData.breaking}
              onChange={(event) => {
                setNewsData({
                  ...newsData,
                  breaking: event.target.checked,
                });
              }}
            />
            <label htmlFor="breakingNews" style={{ marginRight: "15px" }}>
              Ударна вест
            </label>
            <input
              id="includeSearchNews"
              type="checkbox"
              value={newsData.includeInSearchResult}
              checked={newsData.includeInSearchResult}
              onChange={(event) => {
                setNewsData({
                  ...newsData,
                  includeInSearchResult: event.target.checked,
                });
              }}
            />
            <label htmlFor="includeSearchNews" style={{ marginRight: "15px" }}>
              Укључи у претрагу
            </label>

            <div>
              {!mainFile && (
                <>
                <NewFileBtn onClick={handleUploadHtmlClick} type="button">
                  <FontAwesomeIcon icon={solid("plus")} />
                  Додај ХТМЛ фајл
                </NewFileBtn>
                или
                </>
              )}
              <input
                type="file"
                ref={htmlInputRef}
                onChange={(event) => {
                  setMainFile(event.target.files[0]);
                }}
                hidden
                accept=".html, .htm"
                name="mainFile"
              />
              <SubmitFile
                mainFile={mainFile}
                setMainFile={setMainFile}
                photoFiles={images}
                setPhotoFiles={setImages}
                attachmentFiles={attachments}
                setAttachmentFiles={setAttachments}
                isEdit={edit && mainFile?.guid}
                downloadFilesFromRepo={downloadFilesFromRepository}
                getHtml={GetNewsHtml}
                id={newsData.id}
                pathToNavigate={
                  window.location.pathname + window.location.search
                }
              ></SubmitFile>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary form-group"
              style={{ marginTop: "20px" }}
              onClick={() => createNews()}
            >
              {edit ? "Измени" : "Креирај"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNews;
