import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_ADMIN_PANEL;

class IssueTypeClient {

  async getIssueType(isBackground) {
    try {
      var response = await API.get(baseurl + "/IssueType", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActiveIssueTypes(isBackground) {
    try {
      var response = await API.get(baseurl + "/IssueType/getActiveIssueTypes", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getIssueTypesForReg(onlyForReg, isBackground) {
    try {
      var response = await API.get(baseurl + "/IssueType/getIssueTypesForReg?onlyForReg=" + onlyForReg, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getIssueTypesForOg(onlyForOg, isBackground) {
    try {
      var response = await API.get(baseurl + "/IssueType/getIssueTypesForOg?onlyForOg=" + onlyForOg, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getIssueTypesForOgAndReg(isBackground) {
    try {
      var response = await API.get(baseurl + "/IssueType/getIssueTypesForOgAndReg", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createIssueType(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/IssueType/CreateIssueType", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateIssueType(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/IssueType/UpdateIssueType", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default IssueTypeClient;