import React from "react";
import useLocalStorage from "../../hooks/useStateOfTree";
import TreeNode from "./TreeNode.component";

const Tree = ({
  treeData,
  getNode,
  deleteNode,
  changeNode,
  createNode,
  createOtherNode,
  deactivateNode,
  onFilteredNode,
  isRight,
  expanded,
  isMoving,
  selectForMoving,
  isSearching
}) => {

  const { arrayOfAreaIds } = useLocalStorage();
  
  const expandFunction = (id) => {
    var val = arrayOfAreaIds.some(el => el === id);
     return val;
  }

  return (
    <ul>
      {treeData.map((node) => (
        <TreeNode
          node={node}
          key={node.id}
          getNode={getNode}
          deleteNode={deleteNode}
          changeNode={changeNode}
          createNode={createNode}
          createOtherNode={createOtherNode}
          deactivateNode={deactivateNode}
          onFilteredNode={onFilteredNode}
          isRight={isRight}
          expanded={!isMoving && expandFunction(node.id)}
          isMoving={isMoving}
          selectForMoving={selectForMoving}
          isSearching={isSearching}
        />
      ))}
    </ul>
  );
};

export default Tree;
