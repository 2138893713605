import React, { useState, useEffect } from 'react'
import { NewTableRowBtn } from '../../pages/create-and-update-case/CreateAndUpdateCase.style';
import Table from '../../../common/table/Table.component';
import { CourtPracticePublicationModel, courtPracticePublicationsSchema } from '../../models/CourtPractice';
import ProductClient from '../../api-services/coders/product/product-client';
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import DialogModal from '../../../common/modal/Modal.component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { handleSave, validateForm } from '../../../common/validation/validation';

const CourtPracticePublications = ({ courtPracticePublications, setCourtPracticePublications, primaryPublicationExist, setPrimaryPublicationExist, handleCourtPracticePublicationsPrimaryChange }) => {
    const { t } = useTranslation();

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Редни број",
            accessor: "ordinalNumber",
        },
        {
            Header: "Производ",
            accessor: "product.name",
        },
        {
            Header: "Податак",
            accessor: "information",
        },
        {
            Header: "Страна",
            accessor: "page",
        },
        {
            Header: "Примарни",
            accessor: "isPrimary",
            Cell: ({ row }) => {
                return <>{row.original.isPrimary ? "Да" : "Не"}</>;
            },
        },
        {
            accessor: "delete",
            disableSortBy: true,
            className: "two-buttons-column",
            Cell: ({ row }) => {
                return (
                    <>
                        <button
                            type="button"
                            className="btn btn-primary table-btn"
                            onClick={() => onEditClick(row.original, row.index)}
                        >
                            <FontAwesomeIcon icon={solid("pen-to-square")} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger table-btn"
                            onClick={() => removeElementFromTable(row.id, row.original.id)}
                        >
                            <FontAwesomeIcon icon={solid("times")} />
                        </button>
                    </>
                );
            },
        },
    ];
    const productClient = new ProductClient();

    const [showDialog, setShowDialog] = useState(false);
    const [productList, setProductList] = useState([]);
    const [publication, setPublication] = useState(new CourtPracticePublicationModel());
    const [errors, setErrors] = useState({});
    const [showMessageForPrimary, setShowMessageForPrimary] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [rowId, setRowId] = useState("");

    //podatak koji se unese u toku dana se cuva u ovu listu i vadi iz nje na dnevnom nivou, radi lakseg unosa
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const todayKey = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    useEffect(() => {
        Product()
    }, []);

    useEffect(() => {
        //brisemo datum koji je vec prosao, da se ne bi gomilalo u storage-u
        clearExpiredStorage();
        //Ucitavamo danasnje podatke iz lokalnog storage-a
        const todayEntries = JSON.parse(localStorage.getItem(todayKey)) || [];
        setSuggestions(todayEntries);
    }, [todayKey]);

    useEffect(() => {
        if (courtPracticePublications.length > 0) {
            var data = courtPracticePublications.map(x => { return x.isPrimary }).some(x => x === true);
            data && setPrimaryPublicationExist(true);
        }
    }, [courtPracticePublications]);

    const ShowDialog = () => setShowDialog(true);
    const HideDialog = () => {
        if (publication.information) {
            let todayEntries = JSON.parse(localStorage.getItem(todayKey)) || [];
            if (!todayEntries.includes(publication.information)) {
                todayEntries.push(publication.information);
                localStorage.setItem(todayKey, JSON.stringify(todayEntries));
            }
        }
        setShowDialog(false)};

    const Product = async () => {
        var response = await productClient.GetAllActiveProducts(true);
        var handledResponse = HandlingResponse(response);
        var data = handledResponse !== "Error"
            ? handledResponse.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                    name: "publicationKind"
                };
            }) : [];
        setProductList(data);
    };

    const handlePropertyChange = (event) => {
        const obj = { name: event.name, value: event.value };
        const result = handleSave(obj, publication, errors, courtPracticePublicationsSchema, t);

        //mora ovako zbog validacije liste, jer se nalazi u posebnom modelu
        if (obj.name === "publicationKind")
            setPublication({ ...result.data, product: { id: event.value, name: event.label } });
        else setPublication(result.data);
        setErrors(result.errors);
    };

    const addProductTableRow = () => {
        if (!publication.isPrimary && primaryPublicationExist)  //u slucaju da se primarna objava menja u neprimarnu
            setPrimaryPublicationExist(false);
        let indexOfPrimary = courtPracticePublications.findIndex((x) => x.isPrimary)
        if (publication.isPrimary && indexOfPrimary !== rowId) {
            if (primaryPublicationExist) {
                var primaryMessage = true;
                setShowMessageForPrimary(true);
            }
            else {
                primaryMessage = false;
                setShowMessageForPrimary(false);
                setPrimaryPublicationExist(true);
            }
        }
        else {
            primaryMessage = false;
            setShowMessageForPrimary(false);
        }

        const errorsResult = validateForm(publication, courtPracticePublicationsSchema, t);
        setErrors(errorsResult);
        if (!errorsResult && !primaryMessage) {
            if (isEdit) {
                let courtPracticePublicationsCopy = [...courtPracticePublications]
                courtPracticePublicationsCopy[rowId] = publication;
                setCourtPracticePublications(courtPracticePublicationsCopy);
            }
            else {
                setCourtPracticePublications([...courtPracticePublications, publication]);

            }
            cancelAddingProductToTable();
            handleCourtPracticePublicationsPrimaryChange(publication.isPrimary)
        }
    };

    const removeElementFromTable = (deleteIndex, id) => {
        const courtPracticePublicationsCopy = [...courtPracticePublications];
        var data = courtPracticePublications.filter((x) => x.id === id);
        if (data[0].isPrimary) setPrimaryPublicationExist(false);

        courtPracticePublicationsCopy.splice(deleteIndex, 1);
        setCourtPracticePublications(courtPracticePublicationsCopy);
    };

    const cancelAddingProductToTable = () => {
        HideDialog();
        setPublication(new CourtPracticePublicationModel());
        setShowMessageForPrimary(false);
        setErrors({});
        setRowId("")
    }

    const onEditClick = (row, id) => {
        setIsEdit(true);
        setRowId(id);
        setPublication({ ...row, publicationKind: row.product.id })
        ShowDialog();
    }

    const showSuggestionsList = () => {
        const todayEntries = JSON.parse(localStorage.getItem(todayKey)) || [];
        setSuggestions(todayEntries);
        setShowSuggestions(todayEntries.length > 0);
    };

    const handleSuggestionClick = (suggestion) => {
        setPublication({ ...publication, information: suggestion });
        setSelectedSuggestion(suggestion);
        setShowSuggestions(false);
    };

    const handleFocus = () => {
        showSuggestionsList();
    };

    const handleBlur = () => {
        setShowSuggestions(false);
    };

    const clearExpiredStorage = () => {
        const storedDate = localStorage.getItem('storedDate');
        if (storedDate !== todayKey) {
            localStorage.clear();
            localStorage.setItem('storedDate', todayKey);
        }
    };

    return (
        <>
            <div>
                <h5>
                    <span>
                        Објаве
                        <span style={{ color: 'red' }}> *</span>
                    </span>
                    <NewTableRowBtn type="button" onClick={() => {
                        setIsEdit(false);
                        ShowDialog();
                    }}>
                        <FontAwesomeIcon icon={solid("plus")} />
                        Нова објава
                    </NewTableRowBtn>
                </h5>
                {courtPracticePublications.length > 0 && (
                    <Table
                        columns={columns}
                        data={courtPracticePublications}
                        rememberFilter={false}
                        hiddenColumn={["id"]}
                    />
                )}
            </div>
            <DialogModal
                show={showDialog}
                onHide={cancelAddingProductToTable}
                title="Објава за предмет судске праксе"
            >

                <div className="form-group">
                    <label>Редни број</label>
                    <input
                        className="form-control"
                        name="ordinalNumber"
                        type="number"
                        value={publication.ordinalNumber}
                        onChange={event => handlePropertyChange(event.target)}
                    />
                    {errors?.ordinalNumber && (
                        <div className="text-danger">
                            {errors.ordinalNumber}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>Производ<span className="required-asterisk">*</span></label>
                    <Select
                        options={productList}
                        onChange={(event) => handlePropertyChange(event)}
                        value={productList?.filter((x) => x.value === publication.product.id)}
                    />
                    {errors?.publicationKind && (
                        <div className="text-danger">
                            {errors.publicationKind}
                        </div>
                    )}
                </div>
                <div className="form-group" style={{ position: 'relative' }}>
                    <label>Податак</label>
                    <input
                className="form-control"
                value={publication.information}
                //onClick={() => setShowSuggestions(!showSuggestions)}
                onChange={(event) => setPublication({ ...publication, information: event.target.value })}
                onKeyDown={(event) => {event.key === 'Enter' && setShowSuggestions(false)}}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {showSuggestions && (
                <div className="suggestions">
                    {suggestions.map((suggestion, index) => (
                        <div
                            className='suggestionOption'
                            key={index}
                            style={{ padding: '8px', cursor: 'pointer', backgroundColor: selectedSuggestion === suggestion ? '#e9f5f2' : '' }}
                            onMouseDown={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </div>
                    ))}
                </div>
            )}
                </div>
                <div className="form-group">
                    <label>Страна</label>
                    <input
                        className="form-control"
                        value={publication.page}
                        onChange={(event) => setPublication({ ...publication, page: event.target.value })}
                    />
                </div>
                <div className="form-group">
                    <input
                        id="primaryCB"
                        type="checkbox"
                        value={publication.isPrimary || ""}
                        checked={publication.isPrimary || ""}
                        onChange={(event) => {
                            !event.target.checked && setShowMessageForPrimary(false)
                            setPublication({ ...publication, isPrimary: event.target.checked })
                        }}
                    />
                    <label htmlFor="primaryCB">Примарна</label>
                    {showMessageForPrimary ?
                        <div className="text-danger">Примарна објава већ постоји.</div>
                        : <></>
                    }
                </div>
                <div>
                   
                    <button
                        type="button"
                        onClick={cancelAddingProductToTable}
                        className="btn btn-primary  pull-right"
                    >
                        Одустани
                    </button>
                    <button
                        type="button"
                        onClick={addProductTableRow}
                        className="btn btn-primary"
                    >
                        Сачувај
                    </button>
                </div>
            </DialogModal>
        </>
    )
}

export default CourtPracticePublications;