import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  color: #333;
  border-radius: 6px 6px 0 0;
  margin: -15px -15px 25px -15px;
  justify-content: center;
  /* border-bottom: 1px solid #ddd; */
  
  .tab {
    cursor: pointer;
    padding: 10px 20px;
    margin-bottom: 0;
    color: #555;

    &:hover {
      border-bottom: 2px solid #ddd;
    }

    &.active {
      color: #000;
      background-color: #fff;
      border-bottom: 2px solid #00c58f;
      font-weight: 500;
    }
  }
`;
