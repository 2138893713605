import { useState, useEffect } from "react";
import Search from "../../../components/small-adds/search-small-adds/SearchSmallAdds.component";
import { FilterSmallAddsModel } from "../../../models/FilterSmallAdds";
import { useNavigate } from "react-router-dom";
import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import Table, { getPageNumber, getPageSizeNumber } from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const SearchSmallAdds = () => {
    const tableName = "MO_SMALL_ADDS";
    const rememberFilter = true;
    const columns = 
    [
        {
            Header: "Број огласа",
            accessor: "adNumber"
        },
        {
            Header: "Гласник",
            accessor: "ogNumYear"
        },
        {
            Header: "Врста документа",
            accessor: "documentKind"
        },
        {
            Header: "Број документа",
            accessor: "documentNumber"
        },
        {
            Header: "Корисник",
            accessor: "beneficiaryName"
        },
        {
            Header: "Место",
            accessor: "beneficiaryMunicipality"
        },
        {
            Header: "Број решења",
            accessor: "decisionNumber"
        },
        {
            Header: "Доносилац решења",
            accessor: "decisionIssuer"
        },
        {
            accessor: "edit",
            disableSortBy: true,
            Cell: ({ row }) => {
              return (
                <button
                  className="btn btn-primary table-btn"
                  onClick={() => onPreviewClick(row.original.dbDocId)}
                >
                  <FontAwesomeIcon icon={solid("magnifying-glass")} />
                </button>
              );
            },
          },
    ]

    const [filterData, setFilterData] = useState(new FilterSmallAddsModel());
    const [tableData, setTableData] = useState();
    const [reset, setReset] =  useState(false);

    const client = new SmallAddsClient();
    const navigate = useNavigate();

    useEffect(() => {
      reset && setReset(false);
    }, [tableData])

    const onPreviewClick = async (id) => {
        var response = await client.PreviewMO(id);
        let data = HandlingResponse(response);
        if (data != "Error"){       
            navigate("/mo/smallAdds/preview", { state: { data } }) 
        }   
    }

    const fetchDataForPage = async (pageNumber, pageSize) => {
        const storedObject = sessionStorage.getItem("MO_FILTER");
        if (storedObject) {
            var parsedObject = JSON.parse(storedObject);
            setFilterData(parsedObject);
        }
        var response = await client.FilterMO(
            {
             ...parsedObject,
                pageSize: pageSize,
                pageNumber: pageNumber
              }
            );
            let data = HandlingResponse(response);
            return data;
    }

    const searchData = async (e) => {
        e.preventDefault();
        sessionStorage.setItem("MO_FILTER", JSON.stringify(filterData));
        const response = await fetchDataForPage(
          getPageNumber(tableName, rememberFilter),
          getPageSizeNumber(tableName, rememberFilter),
          //getSortBy(tableName, rememberFilter, true)
          );
          setTableData(response);
    }
    
    const resetFilters = async () => {
      setReset(true);
        setFilterData(new FilterSmallAddsModel());
        sessionStorage.removeItem("MO_FILTER")
        const response = await client.FilterMO({});
          let data = HandlingResponse(response);

        setTableData(data);
    }

    return (
      <>
        <Search
          filterData={filterData}
          setFilterData={setFilterData}
          searchData={searchData}
          resetFilters={resetFilters}
        ></Search>
          <label>Укупан број резултата: {tableData?.count}</label>
        <Table
          columns={columns}
          data={tableData}
          rememberFilter={rememberFilter}
          tableName={tableName}
          fetchFunction={fetchDataForPage}
          serverSide={true}
          setData={setTableData}
          showPaginateButton={false}
          resetFilters={reset}
        />
        <br/>
      </>
    );
}

export default SearchSmallAdds;