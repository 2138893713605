import React, { useEffect, useState, useRef } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import ProductLinks from  "../product-links/ProductLinks.component";
import "react-dropzone-uploader/dist/styles.css";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation
} from "../../../common/validation/validation";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import {
  NewFileBtn,
  DeleteButton,
} from "../../../common/multiple-files/multipleFiles.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

const CreateProduct = ({ row, isCreate, file, tableName, handleCallback }) => {
  const statusOptions = [
    {
      value: 0,
      label: "Активан",
    },
    {
      value: 1,
      label: "Неактиван",
    },
  ];
  const productOptions = [
    {
      value: "PROGRAMSKI_PAKET",
      label: "ПП",
    },
    {
      value: "GLASILA",
      label: "Гласило",
    },
    {
      value: "DIGITALIZOVANA_IZDANJA",
      label: "Дигитализована издања",
    },
    {
      value: "VESTI",
      label: "Вести",
    },
    {
      value: "REGISTAR",
      label: "Регистар",
    },
    {
      value: "SUDSKA_PRAKSA",
      label: "Судска пракса",
    },
    {
      value: "JAVNE_NABAVKE",
      label: "Јавне набавке",
    },
    {
      value: "ARHIVA",
      label: "АРХИВА",
    },
    {
      value: "PRAVNI_PODACI",
      label: "Пакет плус",
    },
  ];
  const [listCountry, setListCountry] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [productId, setProductId] = useState(0);
  const periodOptions = [
    {
      value: "1",
      label: "Текућа година",
    },
    {
      value: "2",
      label: "За период",
    },
    {
      value: "3",
      label: "Све сем текуће године",
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [productData, setProductData] = useState({
    id: 0,
    countryName: undefined,
    productStatus: 1,
    productTemplate: "",
    name: "",
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    shortName: "",
    numCountryGuid: "",
    price: "",
    helpUrl: "",
    impressumUrl: "",
    editorialUrl: "",
    contactUrl: "",
    productMainTemplateLogo: "",
    period: "1",
    pageInactivePicUrl: "",
    pageActivePicUrl: "",
    pageHeaderImageUrl: "",
    imageBytes: "",
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    name: Joi.string().required(),
    shortName: Joi.string().required(),
    description: Joi.string().required()
  };

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const imageInputRef = useRef(null);

  const client = new PackageClient();

  useEffect(() => {
    setProductData(row);
    setProductId(row.id);
    getProductIssueTypes(row.issueTypes);
    setErrors({});
  }, [row]);

  const getProductIssueTypes = async (types) => {
    let response = await client.GetActiveProductLinks();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { ...item, isChecked: false };
    });
    if (isCreate) {
      setTableData(result);
    }
    else {
      var issueTypes = types.map((x) => {
        return x.issueTypeGuid;
      });
      result.forEach((item) => {
        if (issueTypes.includes(item.guid)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      });
      setTableData(result);

    }
  };

  const AddProduct = async () => {
    if (productImage) {
      schema.productImage = Joi.optional();
    }

    const errorsResult = validateForm(productData, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var productIssueTypes = tableData
        .filter((item) => {
          return item.isChecked === true;
        })
        .map((item) => {
          return { productId: productId, issueTypeGuid: item.guid };
        });

      const data = {
        ...productData,
        dateFrom: productData.period == "2" ? productData.dateFrom : "",
        dateTo: productData.period == "2" ? productData.dateTo : "",
        price: parseInt(productData.price),
        isIncludedInPeriod: productData.period == "2" ? true : false,
        isCurrentYearProduct: productData.period == "1" ? true : false,
        productIssueTypes: productIssueTypes,
      };

      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      if (productImage) {
        formData.append("productImage.file", productImage);
        if (!isCreate) {
          formData.append("productImage.guid", productImage);
        }
      }

      var response;
      if (isCreate) {
        response = await client.AddProduct(formData);
      } else {
        response = await client.UpdateProduct(formData);
      }
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else {
        handleCallback();
      }
    }
  };

  const getCountryList = async () => {
    let response = await client.GetActiveCountries();
    let res = HandlingResponse(response);
    var result = res.map((country) => {
      return { value: country.guid, label: country.countryNameLong };
    });
    setListCountry(result);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    setProductImage(file);
  }, [file]);

  const handleUploadImageClick = () => {
    imageInputRef.current.value = null;
    imageInputRef.current?.click();
  };

  const removeImageElement = () => {
    setProductImage(null);
    imageInputRef.current.value = null;
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, productData, errors, schema, t);
    setProductData(result.data);
    setErrors(result.errors);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };
  return (
    <>
      <div className="form-group">
        <label>Држава</label>
        <Select
          options={listCountry}
          onChange={(e) => {
            setProductData({
              ...productData,
              countryName: e.label,
              numCountryGuid: e.value
            });
          }} value={listCountry.filter((x) => x.label === productData.countryName)}
        />
      </div>
      <div className="form-group">
        <label>Статус</label>
        <Select
          options={statusOptions}
          onChange={(e) =>
            setProductData({ ...productData, productStatus: e.value })
          }
          value={statusOptions.filter(
            (x) => x.value === productData.productStatus
          )}
          isDisabled={isCreate}
        />
      </div>
      <div className="form-group">
        <label>Темплејт</label>
        <Select
          options={productOptions}
          onChange={(e) =>
            setProductData({ ...productData, productTemplate: e.value })
          }
          value={productOptions.filter(
            (x) => x.value === productData.productTemplate
          )}
        />
      </div>
      <div className="form-group">
        <label>Назив<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="name"
          value={productData.name}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.name && <div className="text-danger">{errors.name}</div>}
      </div>
      <div className="form-group">
        <label>Опис<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="description"
          value={productData.description}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.description && (
          <div className="text-danger">{errors.description}</div>
        )}
      </div>
      <div className="form-group">
        <label>Скраћени назив<span className="required-asterisk">*</span></label>
        <input
          className="form-control"
          type="text"
          name="shortName"
          value={productData.shortName}
          onChange={(e) => handlePropertyChange(e.target)}
        />
        {errors?.shortName && (
          <div className="text-danger">{errors.shortName}</div>
        )}
      </div>
      <div className="form-group">
        <label>Цена</label>
        <input
          className="form-control"
          type="number"
          name="price"
          value={productData.price}
          onChange={(e) => setProductData({ ...productData, price: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Страна за помоћ</label>
        <input
          className="form-control"
          type="text"
          name="helpUrl"
          value={productData.helpUrl}
          onChange={(e) => setProductData({ ...productData, helpUrl: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Импресум страна</label>
        <input
          className="form-control"
          type="text"
          name="impressumUrl"
          value={productData.impressumUrl}
          onChange={(e) => setProductData({ ...productData, impressumUrl: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Реч уредника</label>
        <input
          className="form-control"
          type="text"
          name="editorialUrl"
          value={productData.editorialUrl}
          onChange={(e) => setProductData({ ...productData, editorialUrl: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Контакт</label>
        <input
          className="form-control"
          type="text"
          name="contactUrl"
          value={productData.contactUrl}
          onChange={(e) => setProductData({ ...productData, contactUrl: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Стил класа за заглавље</label>
        <input
          className="form-control"
          type="text"
          name="productMainTemplateLogo"
          value={productData.productMainTemplateLogo}
          onChange={(e) => setProductData({ ...productData, productMainTemplateLogo: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Период</label>
        <Select
          className={"selectPeriod"}
          classNamePrefix={"selectPeriod"}
          options={periodOptions}
          defaultValue={periodOptions[0]}
          onChange={(e) => setProductData({ ...productData, period: e.value })}
          value={periodOptions.filter((x) => x.value === productData.period)}
        />
      </div>
      {productData.period == "2" && (
        <>
          <div className="form-group col-md-3">
            <label>Датум од</label>
            <CustomDatePicker
              selected={productData.dateFrom}
              onChange={(date) => setProductData({ ...productData, dateFrom: date })}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Датум до</label>
            <CustomDatePicker
              selected={productData.dateTo}
              onChange={(date) => setProductData({ ...productData, dateTo: date })}
            />
          </div>
        </>
      )}
      <div className="col-4">
        <NewFileBtn onClick={handleUploadImageClick} type="button">
          <FontAwesomeIcon icon={solid("plus")} />
          Додај слику
        </NewFileBtn>
        <input
          type="file"
          ref={imageInputRef}
          onChange={(event) => setProductImage(event.target.files[0])}
          hidden
          accept="image/*"
          name="productImage"
        />
        {productImage ? (
          <>
            {productImage.name}
            <DeleteButton type="button" onClick={() => {
              removeImageElement();
              setProductImage(null)
            }}>
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        ) : (
          ``
        )}
        {errors?.productImage && (
          <div className="text-danger">{errors.productImage}</div>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        <h5>Врсте докумената</h5>
        <ProductLinks data={tableData} tableName={tableName}></ProductLinks>
      </div>
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => AddProduct()}
      >
        {isCreate ? "Додај" : "Измени"}
      </button>
    </>
  );
};

export default CreateProduct;
