import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import SideModal from "../../../common/modal/SideModal.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import EditAuthorityAdvertisementAndActType from "../edit-authority-advertisement-and-act-type/editAuthorityAdvertisementAndActType.component";
import AuthorityAdvertisementAndActTypeModel from "../../models/AuthorityAdvertisementAndActType";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { useTranslation } from "react-i18next";

const AuthorityAdvertisementAndActType = ({
  dataAuthorityAdvertisementAndActType,
  idCategory,
}) => {
  const { t } = useTranslation();
  const tableName = "AP_AUTH_ADVERTISEMENT_AND_ACT_TYPE";

  const columns = [
    {
      Header: t("DocumentType"),
      accessor: "description",
      disableFilters: false,
    },
    {
      Header: t("Sort"),
      accessor: "sort",
      disableFilters: false,
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(
    new AuthorityAdvertisementAndActTypeModel()
  );
  const [
    allAuthorityAdvertisementAndActTypes,
    setAllAuthorityAdvertisementAndActTypes,
  ] = useState([]);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(1);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AuthorityAdvertisementAndActTypeModel());
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingDocumentType"));
  };

  const handleAddingDataToTable = (childData) => {
    hideModal();
    if (!isEdit)
      setAllAuthorityAdvertisementAndActTypes([
        ...allAuthorityAdvertisementAndActTypes,
        childData,
      ]);
    else {
      let allAuthorityAdvertisementAndActTypesCopy = [
        ...allAuthorityAdvertisementAndActTypes,
      ];
      let index = allAuthorityAdvertisementAndActTypesCopy.findIndex(
        (x) => x.id === childData.id
      );
      allAuthorityAdvertisementAndActTypesCopy[index] = childData;
      setAllAuthorityAdvertisementAndActTypes(
        allAuthorityAdvertisementAndActTypesCopy
      );
    }
  };

  useEffect(() => {
    selectType(value);
  }, [dataAuthorityAdvertisementAndActType]);

  const selectType = async (value) => {
    let response;
    if (value == 1) {
      response = dataAuthorityAdvertisementAndActType.filter((x) => x.isLaw);
    } else if (value == 2) {
      response = dataAuthorityAdvertisementAndActType.filter((x) => !x.isLaw);
    }
    if (response != null) setAllAuthorityAdvertisementAndActTypes(response);
  };

  const listOptions = [
    {
      label: t("ForLegalActs"),
      value: 1,
    },
    {
      label: t("ForAdvertisements"),
      value: 2,
    },
  ];

  return (
    <>
      <div className="form-control">
        <div className="form-group row">
          <h5 className="col-md-2">{t("DocumentTypes")}</h5>
        </div>
        <div className="form-group col-2">
          <label>{t("Kind")}</label>
          <Select
            options={listOptions}
            onChange={(e) => {
              selectType(e.value);
              setValue(e.value);
            }}
            defaultValue={listOptions[0]}
          />
        </div>

        <Table
          columns={columns}
          data={allAuthorityAdvertisementAndActTypes}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
        />
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <EditAuthorityAdvertisementAndActType
          row={rowData}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default AuthorityAdvertisementAndActType;
