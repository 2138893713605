import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class ProcedureOutcomeClient {
  async ProcedureOutcome(isBackground) {
    try {
      var response = await api.get(baseurl + "/ProcedureOutcome", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllActiveProcedureOutcomes(isBackground) {
    try {
      var response = await api.get(baseurl + "/ProcedureOutcome/GetActiveProcedureOutcomes", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateProcedureOutcome(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/ProcedureOutcome", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateProcedureOutcome(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/ProcedureOutcome/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async DeleteProcedureOutcome(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/ProcedureOutcome?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ProcedureOutcomeClient;
