import React, { useEffect, useState } from "react";
import PublicationKindClient from "../../api-services/coders/publication-kind/types-of-posts-client";
import ProductClient from "../../api-services/coders/product/product-client";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import Moment from "moment";

const CreateProduct = ({ row, handleCallback, isCreate }) => {
  const [publicationKindList, setPublicationKindList] = useState([]);

  const [product, setProduct] = useState({
    id: 0,
    name: "",
    publisher: "",
    author: "",
    isbnIssn: "",
    year: "",
    portalName: "",
    weight: 0,
    fkPublicationKind: "",
    fkPublicationName: "",
    isActive: true,
    userFullName: "",
    lastModifyDate: null,
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    fkPublicationKind: Joi.number().min(0).max(2147483647).required(),

  };

  const publicationKindClient = new PublicationKindClient();
  const client = new ProductClient();

  const PublicationKind = async () => {
    await publicationKindClient.PublicationKind().then((response) => {
      var handledResponse = HandlingResponse(response);
      var data =
        handledResponse !== "Error"
          ? handledResponse.map((item) => {
              return {
                value: item.id,
                label: item.name,
                name: "fkPublicationKind",
                weight: item.weight
              };
            })
          : [];
      setPublicationKindList(data);
    });
  };

  useEffect(() => {
    setProduct(row);
    setErrors({});
  }, [row, isCreate]);

  const CreateProduct = async () => {
    const errorsResult = validateForm(product, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...product,
        year: parseInt(product.year),
        weight: parseInt(product.weight),
        lastModifyDate: new Date(),
        fkPublicationName: publicationKindList?.filter(x => x.value === product.fkPublicationKind)[0].label,
        publicationKind: {
          name: publicationKindList?.filter(x => x.value === product.fkPublicationKind)[0].label,
          weight: publicationKindList?.filter(x => x.value === product.fkPublicationKind)[0].weight,
        }
      };
      var response = await client.CreateProduct(data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handledResponse !== "Error" && handleCallback(data);
    }
  };

  const UpdateProduct = async () => {
    const errorsResult = validateForm(product, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const data = {
        ...product,
        year: parseInt(product.year),
        weight: parseInt(product.weight),
        lastModifyDate: new Date(),
        fkPublicationName: publicationKindList?.filter(x => x.value === product.fkPublicationKind)[0].label
      };
      var response = await client.UpdateProduct(product.id, data);
      var handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else handledResponse !== "Error" && handleCallback(data);
    }
  };

  useEffect(() => {
    PublicationKind();
  }, []);

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, product, errors, schema, t);
    setProduct(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={product.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Издавач</label>
          <input
            className="form-control"
            type="text"
            name="publisher"
            value={product.publisher}
            onChange={(event) => {
              setProduct({ ...product, publisher: event.target.value });
          }}
          />
        </div>
        <div className="form-group">
          <label>Аутор</label>
          <input
            className="form-control"
            type="text"
            name="author"
            value={product.author}
            onChange={(event) => {
              setProduct({ ...product, author: event.target.value });
          }}
          />
        </div>
        <div className="form-group">
          <label>ISBN ISSN</label>
          <textarea
            className="form-control"
            name="isbnIssn"
            value={product.isbnIssn}
            onChange={(event) => {
              setProduct({ ...product,isbnIssn: event.target.value });
          }}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Година</label>
          <input
            className="form-control"
            type="number"
            name="year"
            value={product.year}
            onChange={(event) => {
              setProduct({ ...product, year: event.target.value });
          }}
          />
        </div>
        <div className="form-group">
          <label>Назив за портал</label>
          <input
            className="form-control"
            type="text"
            name="portalName"
            value={product.portalName}
            onChange={(event) => {
              setProduct({ ...product, portalName: event.target.value });
          }}
          />
        </div>
        <div className="form-group">
          <label>Тежина<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={product.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div className="form-group">
          <label>Врста објаве<span className="required-asterisk">*</span></label>
          <Select
            options={publicationKindList}
            onChange={(event) => {
              handlePropertyChange(event);
            }}
            value={publicationKindList?.filter(
              (x) => x.value === product.fkPublicationKind
            )}
          />
          {errors?.fkPublicationKind && (
            <div className="text-danger">{errors.fkPublicationKind}</div>
          )}
        </div>
        <div>
        <input
                id="activeProductCB"
                    type="checkbox"
                    value={product.isActive}
                    checked={product.isActive}
                    onChange={(event) => {
                        setProduct({ ...product, isActive: event.target.checked });
                    }}
          />
          <label htmlFor="activeProductCB">Активан</label>
        </div>
        {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{product.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{product.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>
                {Moment(product.lastModifyDate).format("DD.MM.YYYY.")}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateProduct()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateProduct()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateProduct;
