import { useState, useEffect, useCallback } from "react";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
  removeTableObjectsFromSessionStorage,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../common/modal/SideModal.component";
import CreateSubscriber from "../../components/create-subscriber/CreateSubscriber.component";
import UserClient from "../../api-services/create-users/create-users-client";
import { format } from "date-fns";
import AddPackages from "../../components/add-packages/AddPackages.component";
import DialogModal from "../../../common/modal/Modal.component";
import { SubscribersModel } from "../../models/Users";
import SearchSubscribers from "../../components/search-subscribers/SearchSubscribers.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import EditSettings from "../../components/edit-subscriber-settings/EditSubscriberSettings.component";
import { validateForm } from "../../../common/validation/validation";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import PackageExtensionRequest from "../../components/package-extension-request/PackageExtensionRequest.component";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";

const Subscribers = () => {
  const rememberFilter = true;
  const tableName = "LOZA_SUBSCRIBERS";
  const addingPackagesTableName = "LOZA_ADD_PACKAGES";
  const tableNamePackages = "LOZA_SUBSCRIBES_PACKAGES";

  const client = new UserClient();
  const requestClient = new CreateClient();

  const [filterData, setFilterData] = useState(new SubscribersModel());
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [edit, setEdit] = useState(false);

  const [subscribers, setSubscribers] = useState([]);
  const [userPackages, setUserPackages] = useState([]);
  const [showUserPackages, setShowUserPackages] = useState(false);

  const [isEditSettings, setIsEditSettings] = useState(false);
  const [userGuid, setUserGuid] = useState("");
  const [userId, setUserId] = useState(0);
  const [maxNumOfSessions, setMaxNumOfSessions] = useState("");

  const [extend, setExtend] = useState(true);
  const [idForExtend, setIdForExtend] = useState(null);
  const [showExtend, setShowExtend] = useState(false);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    validTo: Joi.date().required(),
    comment: Joi.string().allow("").optional(),
  };

  const [rowData, setRowData] = useState({
    userType: "1",
    legalEntityName: "",
    pib: "",
    legalAddress: "",
    name: "",
    lastName: "",
    email: "",
    telephone: "",
    address: "",
    company: "",
    sessionsNumber: "",
    smsAuth: false,
    smsNumber: "",
    userName: "",
    password: "",
    userGuid: "",
    id: 0,
    initialPassword: "",
  });
  const [reset, setReset] = useState(false);

  const columns = [
    {
      Header: "Име",
      accessor: "name",
    },
    {
      Header: "Презиме",
      accessor: "lastName",
    },
    {
      Header: "Корисничко име",
      accessor: "userName",
    },
    {
      Header: "Компанија",
      accessor: "company",
    },
    {
      Header: "Адреса",
      accessor: "address",
    },
    {
      Header: "Телефон",
      accessor: "telephone",
    },
    {
      Header: "Е-маил",
      accessor: "email",
    },
    {
      Header: "Иницијална лозинка",
      accessor: "initialPassword",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => editUser(row.original)}
              type="button"
              title="Измена корисника"
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
            <button
              className="btn btn-primary table-btn"
              onClick={() =>
                editSettings(
                  row.original.userGuid,
                  row.original.maxNumOfSessions
                )
              }
              type="button"
              title="Измена лозинке"
            >
              <FontAwesomeIcon icon={solid("key")} />
            </button>
          </>
        );
      },
    },
  ];

  const packagesColumns = [
    {
      Header: "Статус",
      accessor: "status",
      width: 150,
    },
    {
      Header: "Датум захтева",
      accessor: "requestDate",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.requestDate), "dd.MM.yyyy.")}</div>
        );
      },
    },
    {
      Header: "Важи од",
      accessor: "validFrom",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.validFrom), "dd.MM.yyyy.")}</div>
        );
      },
    },
    {
      Header: "Важи до",
      accessor: "validTo",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.validTo), "dd.MM.yyyy.")}</div>
        );
      },
    },
    {
      Header: "Изабрани пакет",
      accessor: "packageIdOfProductNavigation.shortDescription",
    },
    {
      Header: "Тип",
      accessor: "packageIdOfProductNavigation.packageType",
    },
    {
      Header: "Е-маил",
      accessor: "sendEmail",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              checked={row.original.sendEmail}
              disabled="disabled"
            />
          </>
        );
      },
    },
    {
      Header: "Детаљан",
      accessor: "advancedEmail",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              checked={row.original.advancedEmail}
              disabled="disabled"
            />
          </>
        );
      },
    },
    {
      Header: "СМС",
      accessor: "sendSms",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              checked={row.original.sendSms}
              disabled="disabled"
            />
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "buttons",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deletePackage(row.original.id)}
            >
              Обриши
            </button>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => acceptRejectPackage(row.original.id)}
            >
              Одобри / Одбиј
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setExtend(true);
                setIdForExtend(row.original.packageIdOfProduct);
                showExtendModal();
              }}
            >
              Продужи
            </button>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => {
                setIdForExtend(row.original.packageIdOfProduct);
                setExtend(false);
                showExtendModal();
              }}
            >
              Укини
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    reset && setReset(false);
  }, [subscribers]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("LOZA_SUBSCRIBERS_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    let response = await client.FilterUserSubscribers({
      ...parsedObject,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesBySort: sortBy,
    });
    let data = HandlingResponse(response);
    setShowUserPackages(false);
    setUserPackages([]);
    return { dataList: data.usersDto, count: data.count };
  };

  const searchData = async (e) => {
    e?.preventDefault();
    sessionStorage.setItem(
      "LOZA_SUBSCRIBERS_FILTER",
      JSON.stringify(filterData)
    );
    var result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setSubscribers(result);
  };

  const resetFilters = async () => {
    setReset(true);
    setFilterData(new SubscribersModel());
    sessionStorage.removeItem("LOZA_SUBSCRIBERS_FILTER");
    var response = await client.FilterUserSubscribers({});
    let data = HandlingResponse(response);
    setSubscribers({ dataList: data.usersDto, count: data.count });
    setShowUserPackages(false);
  };

  const createUser = () => {
    setEdit(false);
    setIsEditSettings(false);
    setRowData({
      userType: "1",
      legalEntityName: "",
      pib: "",
      legalAddress: "",
      name: "",
      lastName: "",
      email: "",
      telephone: "",
      address: "",
      company: "",
      sessionsNumber: "",
      smsAuth: false,
      smsNumber: "",
      userName: "",
      password: "",
      userGuid: "",
      id: 0,
      initialPassword: "",
    });
    showModal();
  };

  const editUser = (data) => {
    setEdit(true);
    setIsEditSettings(false);
    setRowData({
      userType: data.isLegalEntity ? "1" : "2",
      legalEntityName: data.legalEntityName ? data.legalEntityName : "",
      pib: data.pib ? data.pib : "",
      legalAddress: data.legalAddress ? data.legalAddress : "",
      name: data.name ? data.name : "",
      lastName: data.lastName ? data.lastName : "",
      email: data.email ? data.email : "",
      telephone: data.telephone ? data.telephone : "",
      address: data.address ? data.address : "",
      company: data.company ? data.company : "",
      sessionsNumber: data.maxNumOfSessions ? data.maxNumOfSessions : "",
      smsAuth: data.smsAuth ? data.smsAuth : false,
      smsNumber: data.smsNumber ? data.smsNumber : "",
      userName: data.userName ? data.userName : "",
      password: data.password ? data.password : "",
      userGuid: data.userGuid,
      id: data.id,
      initialPassword: data.password,
    });
    showModal();
  };

  const editSettings = (userGuid, maxNumOfSessions) => {
    setIsEditSettings(true);
    setUserGuid(userGuid);
    setMaxNumOfSessions(maxNumOfSessions);
    showModal();
  };

  const deletePackage = async (id) => {
    let response = await client.DeletePackage(id);
    HandlingResponse(response);
    getUserPackages(userGuid);
  };

  const acceptRejectPackage = async (id) => {
    let response = await client.AcceptRejectPackage(id);
    HandlingResponse(response);
    getUserPackages(userGuid);
  };

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
    isEditSettings && searchData();
  };

  const showExtendModal = () => {
    setShowExtend(true);
  };

  const hideExtendModal = () => {
    setShowExtend(false);
  };

  const ShowDialog = () => {
    setShowDialog(true);
  };

  const HideDialog = () => {
    setShowDialog(false);
    removeTableObjectsFromSessionStorage(addingPackagesTableName);
  };

  const getUserPackages = async (userGuid) => {
    await client.GetUserWithPackages(userGuid).then((response) => {
      let handledResponse = HandlingResponse(response);
      setUserPackages(handledResponse);
      setShowUserPackages(true);
    });
  };

  const addPackageToUser = () => {
    ShowDialog();
  };

  const handleTableClickOnRow = useCallback((response) => {
    setUserId(response.id);
    setUserGuid(response.userGuid);
    getUserPackages(response.userGuid);
  });

  const handleAddingDataToTable = async () => {
    hideModal();
    var result = await fetchDataForPage(
      getPageNumber(tableName) + 1,
      getPageSizeNumber(tableName),
      getSortBy(tableName, rememberFilter, true)
    );
    setSubscribers(result);
  };

  const extendOrCancelUserPackage = async (approve, extendCancelInfo) => {
    const errorsResult = validateForm(extendCancelInfo, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (idForExtend) {
        const data = {
          packageIds: [idForExtend],
          userGuid: userGuid,
          cancellingOrExtending: extend,
          validTo: extendCancelInfo.validTo,
          comment: extendCancelInfo.comment,
        };

        let response =
          await requestClient.requestForCancellationOrExtendingUserPackage(
            approve,
            data
          );
        var handledReponse = HandlingResponse(response);
        if (handledReponse) {
          hideExtendModal();
          getUserPackages(userGuid);
        }
      }
    }
  };

  return (
    <>
      <h4>Претплатници</h4>
      <SearchSubscribers
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <hr />
      <div className="text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => createUser()}
          style={{ marginBottom: "10px" }}
        >
          Додај новог претплатника
        </button>
      </div>
      <Table
        columns={columns}
        data={subscribers}
        rememberFilter={rememberFilter}
        tableName={tableName}
        handleClickOnRow={handleTableClickOnRow}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setSubscribers}
        resetFilters={reset}
      />
      {isEditSettings ? (
        <SideModal
          show={show}
          title="Измена лозинке и подешавања"
          handleClose={hideModal}
        >
          <EditSettings
            id={userGuid}
            sessionsNumber={maxNumOfSessions}
          ></EditSettings>
        </SideModal>
      ) : (
        <SideModal
          show={show}
          title={edit ? "Измена претплатника" : "Креирање претплатника"}
          handleClose={hideModal}
        >
          <CreateSubscriber
            row={rowData}
            edit={edit}
            handleClose={hideModal}
            handleAddingDataToTable={handleAddingDataToTable}
          />
        </SideModal>
      )}

      <div id="div-packages" style={{ marginTop: "20px" }}>
        {showUserPackages && (
          <>
            <h5>Преглед пакета</h5>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginBottom: "10px" }}
              onClick={() => addPackageToUser()}
            >
              Додај пакете
            </button>
            {userPackages.length > 0 && (
              <Table
                columns={packagesColumns}
                data={userPackages}
                rememberFilter={false}
                tableName={tableNamePackages}
              />
            )}
          </>
        )}
      </div>
      <DialogModal
        show={showDialog}
        onHide={HideDialog}
        title="Додавање пакета"
        wide={"true"}
      >
        <AddPackages
          userId={userId}
          onHide={HideDialog}
          tableName={addingPackagesTableName}
          handleCallback={() => getUserPackages(userGuid)}
        />
      </DialogModal>
      <DialogModal
        show={showExtend}
        onHide={() => hideExtendModal()}
        title={
          extend ? "Захтев за продужавање пакета" : "Захтев за укидање пакета"
        }
      >
        <PackageExtensionRequest
          errors={setErrors}
          setErrors={setErrors}
          handleCallback={extendOrCancelUserPackage}
          approve={true}
        />
      </DialogModal>
    </>
  );
};

export default Subscribers;
