import { useState } from 'react';
import DatePicker from "react-datepicker";
import { StyleDataPicker } from './IndexSmallAdds.style';
import { IndexSmallAdsModel, indexSmallAdsFurunaSchema } from '../../../models/SmallAds';
import IndexSmallAds from '../../../api-services/index-small-adds/index-small-ads';
import HandlingResponse from '../../../../common/handling-response/handlingResponse';
import { handleSave, serverValidation, validateForm } from '../../../../common/validation/validation';
import { useTranslation } from 'react-i18next';

const IndexSmallAdds = () => {
    const [inputData, setInputData] = useState(new IndexSmallAdsModel());
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    const client = new IndexSmallAds();

    const indexingAds = async () => {
        let obj = { 
            ...inputData,
            numFromInt: parseInt(inputData.numFromInt),
            numToInt: parseInt(inputData.numToInt),
            yearFromInt: inputData.yearFromInt.getFullYear(),
            yearToInt: inputData.yearToInt.getFullYear()
        }
        const errorsResult = validateForm(obj, indexSmallAdsFurunaSchema, t);
        setErrors(errorsResult);

        if (obj.numFromInt === 0 && obj.numToInt === 999 && obj.yearFromInt === 1900 && obj.yearToInt === 2100) {
            obj = { ...obj, indexNewSmallAdds: false }
        }

        if (!errorsResult) {
            const response = await client.adsIndexing(obj);
            const handledResponse = HandlingResponse(response);

            if (handledResponse.code === 408) {
                const result = serverValidation(handledResponse.data, t);
                setErrors(result);
            }
        }
    }

    const handlePropertyChange = (event, date) => {
        const obj = date
          ? { name: date, value: event }
          : { name: event.name, value: event.value };
        const result = handleSave(obj, inputData, errors, indexSmallAdsFurunaSchema, t);
        setInputData(result.data);
        setErrors(result.errors);
    };

    return(
        <>
        <div>
            <div className="row">
                <div className="col-4">
                    <label>Гласила (број/година)</label>
                    <div className="input-group">
                        <div>
                            <input
                                className="form-control"
                                type="number"
                                name="numFromInt"
                                value={inputData.numFromInt}
                                onChange={(event) => handlePropertyChange(event.target)}
                                min={0}
                            />
                            {errors?.numFromInt && <div className="text-danger">{errors.numFromInt}</div>}
                        </div>
                        <span style={{ margin: ".5rem" }}>/</span>
                        <div>
                            <StyleDataPicker>
                                <DatePicker
                                    className="form-control"
                                    name="yearFromInt"
                                    selected={inputData.yearFromInt}
                                    onChange={(date) => handlePropertyChange(date, "yearFromInt")}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={10}
                                    minDate={new Date("1900-01-01")}
                                    maxDate={new Date("2100-12-31")}
                                />
                            </StyleDataPicker>
                            {errors?.yearFromInt && <div className="text-danger">{errors.yearFromInt}</div>}
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <label>до</label>
                    <div className="input-group">
                        <div>
                            <input
                                className="form-control"
                                type="number"
                                name="numToInt"
                                value={inputData.numToInt}
                                onChange={(event) => handlePropertyChange(event.target)}
                                min={0}
                            />
                            {errors?.numToInt && <div className="text-danger">{errors.numToInt}</div>}
                        </div>
                        <span style={{ margin: ".5rem" }}>/</span>
                        <div>
                            <StyleDataPicker>
                                <DatePicker
                                    className="form-control"
                                    name="yearToInt"
                                    selected={inputData.yearToInt}
                                    onChange={(date) => handlePropertyChange(date, "yearToInt")}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={10}
                                    minDate={new Date("1900-01-01")}
                                    maxDate={new Date("2100-12-31")}
                                />
                            </StyleDataPicker>
                            {errors?.yearToInt && <div className="text-danger">{errors.yearToInt}</div>}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mt-5">
                <div className="col-md">
                    <button type="button" className="btn btn-primary me-2" onClick={indexingAds}>Индексирај</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default IndexSmallAdds;