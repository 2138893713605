class AdvertisementAndActTypeModel {
    constructor(id = 0, defaultSort = "", description = "", isForReg = false, isForOg = false, isLaw = true, active = true){
        this.id = id;
        this.defaultSort = defaultSort;
        this.description = description;
        this.isForOg = isForOg;
        this.isForReg = isForReg;
        this.isLaw = isLaw;
        this.active = active;
    }
}

export default AdvertisementAndActTypeModel;