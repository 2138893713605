import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class ProductClient {
  async Product(isBackground) {
    try {
      var response = await api.get(baseurl + "/Product", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllActiveProducts(isBackground) {
    try {
      var response = await api.get(baseurl + "/Product/GetActiveProducts", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateProduct(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Product", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateProduct(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Product/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async DeleteProduct(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Product?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default ProductClient;