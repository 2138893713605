class IssueTypeModel {
    constructor(id = 0, name = "", extCode  = "", description = "", shortDescription="", isForReg = false, isForOg = false, active = true){
        this.id = id;
        this.name = name;
        this.extCode = extCode;
        this.description = description;
        this.shortDescription = shortDescription;
        this.forOg = isForOg;
        this.forReg = isForReg;
        this.active = active;
    }
}

export default IssueTypeModel;