import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";
import Table from "../../../../common/table/Table.component"
import { useEffect, useState } from "react";
import HandlingResponse from "../../../../common/handling-response/handlingResponse"
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Statistics = () => {

    const client = new SmallAddsClient();
    const navigate = useNavigate();

    const columns = 
    [
        {
            Header: "Година",
            accessor: "aggregateField"
        },
        {
            Header: "Број докумената",
            accessor: "docCount"
        }
    ]

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [year, setYear] = useState("");

    const AggregateByYear = async () => {
        let response = await client.AggregateByYear(false);
        let result = HandlingResponse(response);
        setTableData(result.aggregationList);
        setTotalCount(result.total)
    }

    const handleTableRowClick = useCallback((response) => {
        setYear(response.aggregateField)
    })

    useEffect(() => {
        AggregateByYear()
    }, [])
    const onPreviewClick = () => {
        navigate("/mo/smallAdds/statistics/filterByYear", {state: {year}})
    }

    return(
        <>
            <h4>Статистика малих огласа</h4>
            <Table
                columns={columns}
                data={tableData}
                serverSide={false}
                handleClickOnRow={handleTableRowClick}
                rememberFilter={false}
            />
            {totalCount > 0 && <label>Укупно докумената: {totalCount}</label>}
            <br/>
            <button
              className="btn btn-primary"
              onClick={() => onPreviewClick()}
              style={{marginTop: "15px"}}
              disabled={year ? "" : "disabled"}
            >
              Преглед
            </button>
            
        </>
    )
}

export default Statistics;