if (process.env.NODE_ENV === "development") {
    window.env = {
        "REACT_APP_API_URL_LOZA": "https://kcproxy.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_VASKE": "https://sp.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_MML": "https://mml.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_ADMIN_PANEL": "https://codebook.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_REG": "https://reg.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_SLGL": "https://slgl.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_FP": "https://fp.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_NEWSLETTER": "https://newsletter.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_DI": "https://di.dev.ocp.dev.coming.rs",
        "REACT_APP_API_URL_PPP": "https://ppp.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_REGULATIONS": "https://regulations.dev.ocp.dev.coming.rs/api",
        "REACT_APP_API_URL_PENG": "https://peng.dev.ocp.dev.coming.rs/api",
        "REACT_APP_URL": "https://sso.ocp.dev.coming.rs/auth",
        "PRINS_PORTAL_APP": "https://prinsportal.dev.ocp.dev.coming.rs/",
        "REACT_APP_REALM": "SluzbeniGlasnik-DEV",
        "REACT_APP_CLIENT_ID": "adminuiclient",
        "ENVIRONMENT": "development"
    };
}
//"http://localhost:8080/",

export const env = { ...process.env, ...window['env'] }
