import React from "react";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";

const SearchUsers = ({
  handleCallback,
  filterData,
  setFilterData,
  resetFilters,
}) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDatePickerChange = (date, name) => {
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: date,
    }));
  };

  const setDate = (type) => {
    var date = new Date();
    if (type === "15days") 
        date = date.setDate(date.getDate() + 15);
    if (type === "30days") 
        date = date.setDate(date.getDate() + 30);
    
    handleDatePickerChange(new Date(date), "orderAlarmEndDate");
  };

  return (
    <form onSubmit={handleCallback}>
      <div className="row">
        <div className="col-3">
          <label>Презиме</label>
          <input
            className="form-control"
            type="text"
            value={filterData.lastName}
            name="lastName"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <label>Име</label>
          <input
            className="form-control"
            type="text"
            value={filterData.firstName}
            name="firstName"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <label>Корисничко име</label>
          <input
            className="form-control"
            type="text"
            value={filterData.loginName}
            name="loginName"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <label>Фирма</label>
          <input
            className="form-control"
            type="text"
            value={filterData.company}
            name="company"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <label>Имејл</label>
          <input
            className="form-control"
            type="text"
            value={filterData.email}
            name="email"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <label>Сектор</label>
          <input
            className="form-control"
            type="text"
            value={filterData.sector}
            name="sector"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-2">
          <label>Рок трајања претплате</label>
          <CustomDatePicker
            selected={
              filterData.orderAlarmEndDate
                ? new Date(filterData.orderAlarmEndDate)
                : null
            }
            onChange={(orderAlarmEndDate) => {
              handleDatePickerChange(orderAlarmEndDate, "orderAlarmEndDate");
            }}
          />
        </div>
        <div className="col-4" style={{ paddingTop: "28px" }}>
          <button
            className="btn btn-primary"
            type="button"
            id="today-button"
            onClick={() => setDate("today")}
            style={{ marginLeft: "5px" }}
          >
            Данас
          </button>
          <button
            className="btn btn-primary"
            type="button"
            id="15days-button"
            onClick={() => setDate("15days")}
            style={{ marginLeft: "5px" }}
          >
            У следећих 15 дана
          </button>
          <button
            className="btn btn-primary"
            type="button"
            id="30days-button"
            onClick={() => setDate("30days")}
            style={{ marginLeft: "5px" }}
          >
            У следећих 30 дана
          </button>
        </div>
      </div>
      <div className="text-right">
        <button
          style={{ marginTop: "10px", marginLeft: "5px" }}
          className="btn btn-primary"
          type="submit"
          id="search-button"
        >
          Претрага
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginTop: "10px", marginLeft: "5px" }}
          onClick={() => {
            resetFilters();
          }}
        >
          Поништи претрагу
        </button>
      </div>
    </form>
  );
};

export default SearchUsers;
