import api from "../../../../api";
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class CourtClient {
  async Court(isBackground) {
    try {
      var response = await api.get(baseurl + "/Court", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreateCourt(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/Court", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateCourt(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/Court/" + id, data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      return err.response
    }
  }
  async DeleteCourt(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/Court?id=" + id, { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtsByCourtKind(id, isBackground) {
    try {
      var response = await api.get(baseurl +
        "/Court/GetCourtsByCourtKind?courtKindId=" + id, { isBackground: isBackground }
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtForUpdate(id, isBackground) {
    try {
      var response = await api.get(baseurl +
        "/Court/GetCourtForUpdate?id=" + id, { isBackground: isBackground }
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllCourtsByCourtKind(courtKindId, isBackground) {
    try {
      var response = await api.get(baseurl +
        "/Court/GetAllCourtsByCourtKind?courtKindId=" + courtKindId, { isBackground: isBackground }
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtsForSelect(isBackground) {
    try {
      var response = await api.get(baseurl + "/Court/GetCourtsForSelect", { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

}

export default CourtClient;