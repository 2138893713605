import React, { useEffect, useState } from "react";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker.component";

const SearchEventsCalendar = ({
  filterEventsData,
  setFilterEventsData,
  handleOptionsChange,
  selectedPublishedEnum,
  resetFilters,
  searchData,
}) => {
  return (
    <form onSubmit={searchData}>
      <div className="form-group col-12">
        <div className="row">
          <div className="col-md-4">
            <label>Назив догађаја</label>
            <input
              className="form-control"
              type="text"
              value={filterEventsData.title}
              onChange={(e) =>
                setFilterEventsData({
                  ...filterEventsData,
                  title: e.target.value,
                })
              }
            />
            <label>Датум објављивања од:</label>
            <CustomDatePicker
              selected={filterEventsData.eventDateFrom}
              onChange={(date) =>
                setFilterEventsData({
                  ...filterEventsData,
                  eventDateFrom: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>

          <div className="col-md-4">
            <label>Опис догађаја</label>
            <input
              className="form-control"
              type="text"
              value={filterEventsData.description}
              onChange={(e) =>
                setFilterEventsData({
                  ...filterEventsData,
                  description: e.target.value,
                })
              }
            />
            <label>Датум објављивања до:</label>
            <CustomDatePicker
              selected={filterEventsData.eventDateTo}
              onChange={(date) =>
                setFilterEventsData({
                  ...filterEventsData,
                  eventDateTo: date,
                })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
          <div className="col-md-4">
            <div
              className="col-8 col-sm-8"
              style={{
                border: "1px solid #e1d6d6",
                borderRadius: "5px",
                marginTop: "15px",
                paddingLeft: "5px",
              }}
            >
              <label style={{ marginRight: "10px" }}>Статус:</label>
              <>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="2"
                      checked={selectedPublishedEnum === "2"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Сви
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      checked={selectedPublishedEnum === "1"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Објављени
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="0"
                      checked={selectedPublishedEnum === "0"}
                      onChange={handleOptionsChange}
                      name="published"
                    />
                    {"  "}
                    Необјављени
                  </label>
                </div>
              </>
            </div>
          </div>
          <div className="text-right mt-3">
            <div>
              <button className="btn btn-primary" type="submit">
                Претрага
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  resetFilters();
                }}
              >
                Поништи претрагу
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchEventsCalendar;
