import API from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_MML;

class DocumentTypeClient {
  async getTypes(isBackground) {
    try {
      var response = await API.get(baseurl + "/Type", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getMainTypes(isBackground) {
    try {
      var response = await API.get(baseurl + "/Type/GetMainTypes", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveTypes(isBackground) {
    try {
      var response = await API.get(baseurl + "/Type/GetAllActiveTypes", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getTypeById(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Type/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getTypesByParentId(id, isBackground) {
    try{
      var response = await API.get(baseurl + "/Type/getTypesByParentId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveTypesByParentId(id, isBackground) {
    try{
      var response = await API.get(baseurl + "/Type/getActiveTypesByParentId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getParentTypesForTypeId(id,isBackground) {
    try {
      var response = await API.get(baseurl + "/Type/GetParentTypesForTypeId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createType(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Type/CreateType", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateType(data,isBackground) {
    try {
      var response = await API.put(baseurl + "/Type/UpdateType", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteType(id,isBackground) {
    try {
      var response = await API.delete(baseurl + "/Type/DeleteType/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default DocumentTypeClient;