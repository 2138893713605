import React, { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import DialogModal from "../../../common/modal/Modal.component";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import { validateForm } from "../../../common/validation/validation";
import PackageExtensionRequest from "../package-extension-request/PackageExtensionRequest.component";

const UserEditions = ({ packages, setPackages, userGuid }) => {
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [extend, setExtend] = useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    validTo: Joi.date().required(),
    comment: Joi.string().allow("").optional(),
  };

  const columns = [
    {
      Header: "Број пакета",
      accessor: "packageIdOfProduct",
    },
    {
      Header: "Назив пакета",
      accessor: "packageIdOfProductNavigation.shortDescription",
    },
    {
      Header: "Статус",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.status === "APPROVED" ? "Одобрено" : "Одбијено"}
          </div>
        );
      },
    },
    {
      Header: "Важи од",
      accessor: "validFrom",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.validFrom), "dd.MM.yyyy.")}</div>
        );
      },
    },
    {
      Header: "Важи до",
      accessor: "validTo",
      Cell: ({ row }) => {
        return (
          <div>{format(new Date(row.original.validTo), "dd.MM.yyyy.")}</div>
        );
      },
    },
  ];

  const client = new CreateClient();

  useEffect(() => {
    setTableData(packages);
  }, [packages]);

  const showModal = () => setShow(true);

  const hideModal = () => setShow(false);

  const extendOrCancelUserPackage = async (approve, extendCancelInfo) => {
    var ids = [];
    tableData.forEach((x, index) => {
      if (selectedRowIds.includes(index.toString())) {
        ids.push(x.packageIdOfProduct);
      }
    });

    const errorsResult = validateForm(extendCancelInfo, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (ids.length > 0) {
        const data = {
          packageIds: ids,
          userGuid: userGuid,
          cancellingOrExtending: extend,
          validTo: extendCancelInfo.validTo,
          comment: extendCancelInfo.comment,
        };

        let response =
          await client.requestForCancellationOrExtendingUserPackage(
            approve,
            data
          );
        hideModal();
        var handledReponse = HandlingResponse(response);
        if (handledReponse) {
          let result = await client.getUserWithPackages(userGuid);
          let res = HandlingResponse(result);
          setPackages(res);
        }
      }
    }
  };

  return tableData.length > 0 ? (
    <>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={false}
        serverSide={false}
        onRowSelectStateChange={setSelectedRowIds}
      />
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setExtend(true);
            showModal();
          }}
        >
          Продужи
        </button>
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={() => {
            setExtend(false);
            showModal();
          }}
        >
          Укини
        </button>
      </div>
      <DialogModal
        show={show}
        onHide={() => hideModal()}
        title={
          extend ? "Захтев за продужавање пакета" : "Захтев за укидање пакета"
        }
      >
        <PackageExtensionRequest
          errors={errors}
          setErrors={setErrors}
          handleCallback={extendOrCancelUserPackage}
        />
      </DialogModal>
    </>
  ) : (
    <label>Купац нема додатих пакета.</label>
  );
};

export default UserEditions;
