import React, { useEffect, useState } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import useInternalFormContext from "../../hooks/useInternalFormContext";
import SettingsClient from "../../api-services/settings/settings-client";
import { useTranslation } from "react-i18next";

function TabInternalActOtherData() {
  const {
    data,
    handleChangeInput,
    handleChangeList,
  } = useInternalFormContext();

  const { t } = useTranslation();
  const model = "internalActOtherData";

  const [titleClassList, setTitleClassList] = useState([]);
  const [desctiptionClassList, setDescriptionClassList] = useState([]);
  const [superscriptClassList, setSuperscriptClassList] = useState([]);
  const [subscriptClassList, setSubscriptClassList] = useState([]);

  const client = new SettingsClient();

  const fillDescriptionList = async () => {
    let response = await client.getByParameterKey("ABSTRACT_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssDescriptionClass",
        model: model,
      };
    });
    handledResponse && setDescriptionClassList(data);
  };

  const fillTitleList = async () => {
    let response = await client.getByParameterKey("TITLE_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssTitleClass",
        model: model,
      };
    });
    handledResponse && setTitleClassList(data);
  };

  const fillSubscriptList = async () => {
    let response = await client.getByParameterKey("SUBSCRIPT_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssSubscriptClass",
        model: model,
      };
    });
    handledResponse && setSubscriptClassList(data);
  };

  const fillSuperscriptList = async () => {
    let response = await client.getByParameterKey("SUPERSCRIPT_CSS_CLASSES");
    let handledResponse = HandlingResponse(response);
    let data = handledResponse.map((element) => {
      return {
        value: element,
        label: element,
        name: "cssSuperscriptClass",
        model: model,
      };
    });
    handledResponse && setSuperscriptClassList(data);
  };

  useEffect(() => {
    fillDescriptionList();
    fillTitleList();
    fillSubscriptList();
    fillSuperscriptList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <label>Уредничка напомена</label>
          <textarea
          className="form-control"
          type="text"
          name="editorMark"
          data-model={model}
          id="editorMark"
          value={data.internalActOtherData?.editorMark || ""}
          onChange={handleChangeInput}
        />
        </div>

        <div className="row">
          <div className="col-6">
            <label>Стил наднапомене</label>
            <Select
              options={superscriptClassList}
              onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSuperscriptClass", value: null, model: model})}
              value={superscriptClassList.filter(
                (x) =>
                  x.value ===
                  data.internalActOtherData?.cssSuperscriptClass || null
              )}
              isClearable
            />
            <label>Стил наслова</label>
            <Select
              options={titleClassList}
              onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssTitleClass", value: null, model: model})}
              value={titleClassList.filter(
                (x) => x.value === data.internalActOtherData?.cssTitleClass || null
              )}
              isClearable
            />
          </div>
          <div className="col-6">
            <label>Стил описа</label>
            <Select
              options={desctiptionClassList}
              onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssDescriptionClass", value: null, model: model})}
              value={desctiptionClassList.filter(
                (x) =>
                  x.value ===
                  data.internalActOtherData?.cssDescriptionClass || null
              )}
              isClearable
            />
            <label>Стил поднапомене</label>
            <Select
              options={subscriptClassList}
              onChange={(e) => e ? handleChangeList(e) : handleChangeList({name: "cssSubscriptClass", value: null, model: model})}
              value={subscriptClassList.filter(
                (x) =>
                  x.value === data.internalActOtherData?.cssSubscriptClass || null
              )}
              isClearable
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TabInternalActOtherData;
