import API from "../../../api";
import { env } from "../../../env";

var baseurl = env.REACT_APP_API_URL_MML;

class SourceClient {
  async getSourceBySourceKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Source/getSourceBySourceKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getActiveSourceBySourceKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Source/getActiveSourceBySourceKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAllActiveSourceBySourceKindId(id, isBackground) {
    try {
      var response = await API.get(baseurl + "/Source/getActiveSourceBySourceKindId/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createSource(data, isBackground) {
    try {
      var response = await API.post(baseurl + "/Source/CreateSource", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }

  }

  async updateSource(data, isBackground) {
    try {
      var response = await API.put(baseurl + "/Source/UpdateSource", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteSource(id, isBackground) {
    try {
      var response = await API.delete(baseurl + "/Source/DeleteSource/" + id, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default SourceClient;