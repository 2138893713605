import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from '../../../common/table/Table.component';
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import { format } from "date-fns";
import LawActClient from '../../api-services/law-act/law-act-client';

const Warnings = () => {
    const tableName = "REGULATIONS_WARNINGS";
  
    const versionColumns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Верзија прописа",
        accessor: "versionNumber",
      },
      {
        Header: "Датум уредничке напомене",
        accessor: "editorMarkDeadlineDate",
        Cell: ({ row }) => {
          return (<div>
          {format(new Date(row.original.editorMarkDeadlineDate), "dd.MM.yyyy.")}
        </div>)
        },
      },
      {
        Header: "Актуелна верзија",
        accessor: "isPublishable",
        Cell: ({ row }) => {
          return (
            row.original.isPublishable && (
              <FontAwesomeIcon icon={solid("check")} />
            )
          );
        },
      },
      {
        Header: "Назив прописа",
        accessor: "lawActName",
      },
      {
        accessor: "preview",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <button
                title="Преглед акта"
                className="btn btn-primary table-btn"
                onClick={() => getLawAct(row.original.id)}
                type="button"
              >
                <FontAwesomeIcon icon={solid("pencil")} />
              </button>
            </>
          );
        },
      },
    ];
  
    const [deadlineLawActs, setDeadlineLawActs] = useState([]);
  
    const lawActClient = new LawActClient();
    const navigate = useNavigate();
  
    useEffect(() => {
      getAllDeadlineLawActs();
    }, []);
  
    const getAllDeadlineLawActs = async () => {
      let response = await lawActClient.getAllDeadlineLawActs();
      let handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setDeadlineLawActs(handledResponse);
    };
  
    const getLawAct = async (id) => {
        lawActClient.getLawAct(id).then((res) => {
          var data = HandlingResponse(res);
          if (data !== "Error")
            navigate("/regulations/update?actId=" + id + "&fromWarnings=" + true, { state: { data } });
        });
    };
  
    return (
      <div>
        <h5>Уредничке напомене</h5>
        {deadlineLawActs.length> 0 ? <Table
          columns={versionColumns}
          data={deadlineLawActs}
          rememberFilter={true}
          tableName={tableName}
          hiddenColumn={["id"]}
          serverSide={false}
        /> :
        <p style={{ color: "#b71c1c" }}>Не постоји ниједна напомена</p>}
      </div>
      
    );
}

export default Warnings