import api from "../../../api" ;
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_LOZA;
const adminUrl = env.REACT_APP_API_URL_ADMIN_PANEL;

class PackageClient {
  async GetPackages(isBackground) {
    try {
      var response = await api.get(baseurl + "/Package", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetPackageById(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Package/" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetIssueTypes(isBackground) {
    try {
      var response = await api.get(adminUrl + "/IssueType", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetProduct(isBackground) {
    try {
      var response = await api.get(baseurl + "/Product", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetActiveProducts(isBackground) {
    try {
      var response = await api.get(baseurl + "/Product/GetAllActiveProducts", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetProductById(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Product/" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetProductReview(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/Product/" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async CreatePackage(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseurl + "/Package/CreatePackage",
        data,
        {
          headers: headers,
        },
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdatePackage(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.put(
        baseurl + "/Package/UpdatePackage",
        data,
        {
          headers: headers,
        },
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAllPackages(isBackground) {
    try {
      var response = await api.get(
        baseurl + "/Package/GetAllActivePackages",
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetProductLinks(isBackground) {
    try {
      var response = await api.get(adminUrl + "/IssueType", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetActiveProductLinks(isBackground) {
    try {
      var response = await api.get(adminUrl + "/IssueType/getActiveIssueTypes", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async AddProduct(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseurl + "/Product/CreateProduct",
        data,
        {
          headers: headers,
        },
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateProduct(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.put(
        baseurl + "/Product/UpdateProduct",
        data,
        {
          headers: headers,
        },
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCountries(isBackground) {
    try {
      var response = await api.get(adminUrl + "/Country", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetActiveCountries(isBackground) {
    try{
      var response = await api.get(adminUrl + "/Country/getActiveCountries", { isBackground: isBackground });
      return response.data;
    }
    catch (err){
      return err.response
    }
  }

  async DeleteProductAuthority(id, isBackground) {
    try {
      var response = await api.delete(
        baseurl + "/Product/DeleteProductAuthority?id=" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetAuthority(isBackground) {
    try {
      var response = await api.get(adminUrl + "/Authority", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async AddProductAuthority(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/Product/AddProductAuthority",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default PackageClient;