import React, { useEffect, useState, useCallback } from "react";
import SearchPublications from "../../../components/publications/search-publications/searchPublications.component";
import { useTranslation } from "react-i18next";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../../common/table/Table.component";
import { TabContainer } from "./Tabs.style";
import NumberClient from "../../../api-services/number/number";
import LawAdActClient from "../../../api-services/law-ad-act/law-ad-act-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import Moment from "moment";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import IndexingStatus, {
  StatusForIndexing,
  StatusForDeleteIndex,
} from "../../../../common/indexing-status/IndexingStatus";
import { env } from "../../../../env";

const Publications = ({ isCurrent, isArchived }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rememberFilter = true;
  const tableNamePablishedArchived = "SLGL_PUBLISH_ARCHIVED";
  const tableNameUnpublishedArchived = "SLGL_UNPUBLISH_ARCHIVED";

  const tableNamePablishedActual = "SLGL_PUBLISH_ACTUAL";
  const tableNameUnpublishedActual = "SLGL_UNPUBLISH_ACTUAL";
  const tableNamesArchived = [tableNameUnpublishedArchived, tableNamePablishedArchived]
  const tableNamesActual = [tableNamePablishedActual, tableNameUnpublishedActual]

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Број",
      accessor: "num",
    },
    {
      Header: "Тип",
      accessor: "issueType",
      disableSortBy: true,
    },
    {
      Header: "Држава (скраћено)",
      accessor: "countryNameShort",
      disableSortBy: true,
    },
    {
      Header: "Држава",
      accessor: "countryName",
      disableSortBy: true,
    },
    {
      Header: "Датум",
      accessor: "publishDate",
      Cell: ({ row }) => {
        return row.original.publishDate
          ? Moment(row.original.publishDate).format("DD.MM.YYYY.")
          : "";
      },
    },
    {
      accessor: "delete",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <ButtonWithComfirmation
            className="btn btn-danger table-btn"
            onClick={() => deleteNumber(row.original.id)}
            contentText={"Издање ће бити трајно обрисано."}
            handleYesText={"Обриши"}
            title={"Трајно обриши"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithComfirmation>
        );
      },
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "addIndex",
      className: "one-button-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title="Индексирај"
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexData(row.original.id)}
              disabled={!StatusForIndexing.includes(row.original.indexStatus)}
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];

  const client = new NumberClient();
  const lawAdActClient = new LawAdActClient();
  const [reset, setReset] = useState(false);
  const [number, setNumber] = useState({
    id: 0,
  });
  const [numbers, setNumbers] = useState([]);
  const [filterDataPublish, setFilterDataPublish] = useState({
    issueTypeGuid: "",
    countryGuid: "",
    year: null,
    archived: isArchived,
    published: true,
  });
  const [filterDataUnpublish, setFilterDataUnpublish] = useState({
    issueTypeGuid: "",
    countryGuid: "",
    year: null,
    archived: isArchived,
    published: false,
  });

  useEffect(() => {
    reset && setReset(false);
  }, [numbers]);

  const getActiveTab = () => {
    if (isCurrent) {
      var current = sessionStorage.getItem("current_active_tab");
      return current ? parseInt(current) : 0;
    } else if (isArchived) {
      var arrchived = sessionStorage.getItem("archived_active_tab");
      return arrchived ? parseInt(arrchived) : 0;
    } else return 0;
  };

  const [isDisabledPublished, setIsDisabledPublished] = useState(true);
  const [isDisabledUnpublished, setIsDisabledUnpublished] = useState(true);
  const [isDisabledPreviewPDF, setIsDisabledPreviewPDF] = useState(true);
  const [activeTab, setActiveTab] = useState(getActiveTab);
  const tabs = [
    {
      label: "Непубликована издања",
    },
    {
      label: "Публикована издања",
    },
  ];

  const handleTabClick = (index) => {
    isCurrent && sessionStorage.setItem("current_active_tab", index);
    isArchived && sessionStorage.setItem("archived_active_tab", index);
    setActiveTab(index);
  };

  const handleAddingDataToTable = () => {
    let allNumCountriesCopy = numbers.dataList.filter(
      (x) => x.id !== number.id
    );
    setNumbers({ ...numbers, dataList: allNumCountriesCopy });
  };

  const updateNumber = async (id) => {
    let response;
    if (activeTab === 0) response = await client.publishNumber(id);
    else response = await client.unpublishNumber(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") handleAddingDataToTable();
  };

  const Publish = async () => {
    await updateNumber(number.id);
    DisableAllButtons();
  };

  const handleTableRowClick = useCallback((response) => {
    setNumber(response);
    if (activeTab === 0) {
      setIsDisabledUnpublished(false);
      response.num === 0 && response.pdfDocumentUrl === null ? setIsDisabledPreviewPDF(true) : setIsDisabledPreviewPDF(false);
    } else setIsDisabledPublished(false);
  });

  const deleteNumber = async (id) => {
    var response = await client.deleteNumber(id);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      let allNumCountriesCopy = numbers.dataList.filter((x) => x.id !== id);
      setNumbers({ ...numbers, dataList: allNumCountriesCopy });
    }
    DisableAllButtons();
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem(
      isArchived
        ? activeTab === 0
          ? "SLGL_PUBLISH_UNARCHIVED_FILTER"
          : "SLGL_PUBLISH_ARCHIVED_FILTER"
        : activeTab === 0
        ? "SLGL_PUBLISH_UNACTUAL_FILTER"
        : "SLGL_PUBLISH_ACTUAL_FILTER"
    );

    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      activeTab === 0
        ? setFilterDataUnpublish(parsedObject)
        : setFilterDataPublish(parsedObject);
    }
    var response = await client.FilterNumbers({
      ...parsedObject,
      year: parsedObject?.year ? parsedObject?.year.toString() : null,
      countryGuid:
        parsedObject?.countryGuid == 0 ? "" : parsedObject?.countryGuid,
      issueTypeGuid:
        parsedObject?.issueTypeGuid == 0 ? "" : parsedObject?.issueTypeGuid,
      published: activeTab === 0 ? false : true,
      archived: isArchived,
      pageSize: pageSize,
      pageNumber: pageNumber,
      fieldNamesForSort: sortBy,
    });
    let data = HandlingResponse(response);
    return data;
  };

  const searchNumbers = async (e) => {
    e.preventDefault();
    var storage_name = isArchived
      ? activeTab === 0
        ? "SLGL_PUBLISH_UNARCHIVED_FILTER"
        : "SLGL_PUBLISH_ARCHIVED_FILTER"
      : activeTab === 0
      ? "SLGL_PUBLISH_UNACTUAL_FILTER"
      : "SLGL_PUBLISH_ACTUAL_FILTER";

    const tName = isArchived
      ? tableNamesArchived[activeTab]
      : tableNamesActual[activeTab]

    sessionStorage.setItem(
      storage_name,
      JSON.stringify(activeTab === 0 ? filterDataUnpublish : filterDataPublish)
    );

    const response = await fetchDataForPage(
      getPageNumber(tName, rememberFilter) + 1,
      getPageSizeNumber(tName, rememberFilter),
      getSortBy(tName, rememberFilter, true)
    );
    setNumbers(response);
    DisableAllButtons();
  };

  const resetFilters = async () => {
    setReset(true);
    var storage_name = isArchived
      ? activeTab === 0
        ? "SLGL_PUBLISH_UNARCHIVED_FILTER"
        : "SLGL_PUBLISH_ARCHIVED_FILTER"
      : activeTab === 0
      ? "SLGL_PUBLISH_UNACTUAL_FILTER"
      : "SLGL_PUBLISH_ACTUAL_FILTER";

    activeTab === 0
      ? setFilterDataUnpublish({
          issueTypeGuid: "",
          countryGuid: "",
          year: null,
          published: false,
          archived: isArchived,
        })
      : setFilterDataPublish({
          issueTypeGuid: "",
          countryGuid: "",
          year: null,
          published: true,
          archived: isArchived,
        });

    sessionStorage.removeItem(storage_name);

    var response = await client.FilterNumbers({
      issueTypeGuid: "",
      countryGuid: "",
      year: null,
      published: activeTab === 0 ? false : true,
      archived: isArchived,
    });
    let data = HandlingResponse(response);
    setNumbers(data);
    DisableAllButtons();
  };

  useEffect(() => {
    setNumber("");
    DisableAllButtons();
  }, [activeTab]);

  const GetLawAdActsByNumberId = async () => {
    var response = await lawAdActClient.getLawAdActsByNumberId(number.id);
    let data = HandlingResponse(response);
    if (data != "Error")
      navigate("/slgl/publications/LawAdActs", { state: { data } });
  };

  const GetPDF = async () => {
   
      await client.GetNumberFile(number.id).then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    
  };

  const DisableAllButtons = () => {
    setIsDisabledPublished(true);
    setIsDisabledUnpublished(true);
    setIsDisabledPreviewPDF(true);
  };

  const indexData = async (id) => {
    var response = await client.indexNumber(id);
    HandlingResponse(response);
  };

  const deleteIndex = async (id) => {
    var response = await client.deleteIndexNumber(id);
    HandlingResponse(response);
  };

  const internalOverview = () => {
    if(isArchived) {
      var portalPage = number.issueType.includes("Службени гласник")
      ? "arhslgl/numberOverview/sgarh/"
      : number.issueType.includes("Службени лист") 
      ? "arhslgl/numberOverview/slarh/" 
      : number.issueType.includes("Просветни гласник") 
      ? "arhslgl/numberOverview/pgarh/"
      : "arhslgl/numberOverview/muarh/"
    } else {
      portalPage =
        number.issueType === "Службени гласник РС"
          ? "slglrsNP-overview/"
          : number.issueType === "Просветни гласник"
          ? "slglrsPG2010-overview/"
          : "slglrsMUTG-overview/";
    }
    window.open(env.PRINS_PORTAL_APP + portalPage + number.id + "?internal=" + true, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${getActiveTab() === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </TabContainer>

      <SearchPublications
        filterDataPublish={filterDataPublish}
        setFilterDataPublish={setFilterDataPublish}
        filterDataUnpublish={filterDataUnpublish}
        setFilterDataUnpublish={setFilterDataUnpublish}
        activeTab={activeTab}
        handleCallback={searchNumbers}
        resetFilters={resetFilters}
        isArchived={isArchived}
      ></SearchPublications>
      <hr />

      <Table
        columns={columns}
        data={numbers}
        rememberFilter={rememberFilter}
        tableName={
          isArchived
            ? tableNamesArchived[activeTab]
            : tableNamesActual[activeTab]
        }
        activeTab={activeTab}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setNumbers}
        handleClickOnRow={handleTableRowClick}
        hiddenColumn={
          isArchived || activeTab === 0
            ? ["id", "indexStatus", "addIndex"]
            : ["id"]
        }
        resetFilters={reset}
      />

      {activeTab === 0 ? (
        <div>
          <button
            style={{ marginBottom: "10px", marginLeft: "1px" }}
            className="btn btn-primary"
            onClick={() => Publish()}
            disabled={isDisabledUnpublished}
          >
            Објави на порталу
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginBottom: "10px", marginLeft: "5px" }}
            onClick={() => internalOverview()}
            disabled={isDisabledUnpublished}
          >
            Преглед издања
          </button>
          {isCurrent ? (
            <>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginBottom: "10px", marginLeft: "5px" }}
                onClick={() => {
                  GetLawAdActsByNumberId();
                }}
                disabled={isDisabledUnpublished}
              >
                Преглед фајлова
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginBottom: "10px", marginLeft: "5px" }}
                onClick={() => GetPDF()}
                disabled={isDisabledPreviewPDF}
              >
                Преглед ПДФ-а
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
          <button
            style={{ marginBottom: "10px", marginLeft: "1px" }}
            className="btn btn-primary"
            onClick={() => Publish()}
            disabled={isDisabledPublished}
          >
            Одјави са портала
          </button>
        </div>
      )}
    </>
  );
};

export default Publications;
