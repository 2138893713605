import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SmallAddsClient from "../../../api-services/small-adds/small-adds-client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { useCallback } from "react";
import Moment from "moment";

const StatisticsFilterByYearAndNum = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const client = new SmallAddsClient();

    const columns =
        [
            {
                Header: "Врста документа",
                accessor: "aggregateField"
            },
            {
                Header: "Број докумената",
                accessor: "docCount"
            },
            {
                Header: "Почетни број документа",
                accessor: "minDocNum"
            },
            {
                Header: "Крајњи број документа",
                accessor: "maxDocNum"
            },
        ]

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState("")
    const [filterData, setFilterData] = useState({})
    const [year, setYear] = useState(""); //koristi se za filtriranje po godini kad se klikne na dugme Otkazi, odnosno za vracanje na stranicu StatisticsFilterByYear

    useEffect(() => {
        setYear(state.filterData.year)
    }, []);

    const onCancelClick = () => {
        navigate("/mo/smallAdds/statistics/filterByYear", { state: { year } })
    }

    const FilterByYearAndNumberAggregateByDocumentKind = async (data) => {
        let response = await client.FilterByYearAndNumberAggregateByDocumentKind(data, false);
        let result = HandlingResponse(response);
        setTableData(result.aggregationList);
        setTotalCount(result.total)
    }

    useEffect(() => {
        FilterByYearAndNumberAggregateByDocumentKind(state.filterData);
    }, [])

    return (
        <>
            <h4>Преглед врста малих огласа у броју</h4>
            {state && <label style={{ marginBottom: "15px" }}>Број/година гласила: {state.filterData.num} / {state.filterData.year} од {Moment(state.publishDate).format("DD.MM.YYYY.")}</label>}
            <Table
                columns={columns}
                data={tableData}
                serverSide={false}
                rememberFilter={false}
            />
            {totalCount > 0 && <label style={{ marginBottom: "15px" }}>Укупно докумената: {totalCount}</label>}
            <br />
            <button
                className="btn btn-primary"
                onClick={() => onCancelClick()}
            >
                Откажи
            </button>
        </>
    )
}

export default StatisticsFilterByYearAndNum;