import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useEffect } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import NumberClient from "../../../SLGL/api-services/number/number";

const CreateGazetteLink = forwardRef(({ handleCallback }, ref) => {
  const GazetteLinkColums = [
    {
      Header: "CountryGuid",
      accessor: "countryGuid",
    },
    {
      Header: "Држава",
      accessor: "countryName",
    },
    {
      Header: "IissueTypeGuid",
      accessor: "issueTypeGuid",
    },
    {
      Header: "Издање",
      accessor: "issueTypeName",
    },
    {
      Header: "Број/година",
      accessor: "yearNumber",
    },
    {
      accessor: "pdfDocumentUrl",
    },
  ];

  const slglClient = new NumberClient();

  const [searchGazette, setSearchGazette] = useState("");
  const [listOfGazetteLinks, setListOfGazetteLinks] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [emptyList, setEmptyList] = useState("");

  const resetValues = () => {
    setSearchGazette("");
    setListOfGazetteLinks([]);
  }

  useImperativeHandle(ref, () => ({
    resetValues,
  }));

  const onSearchForGazetteLinks = async (e) => {
    e.preventDefault();
    let response = await slglClient.getNumbersByNumberAndYear(
      searchGazette
    );
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== "Error"){
      if(handledResponse.length <= 0)
      setEmptyList("Није пронађен ниједан резултат")
      else{
        setEmptyList("")
      }
      setListOfGazetteLinks(handledResponse);
    } 
  };

  const addGazetteLink = () => {
    var result = [];
    listOfGazetteLinks.forEach((x, index) => {
        if (selectedRowIds.includes(index.toString())) {
          result.push(x);
        }
      })
      handleCallback(result);
  };

  return (
    <div>
      <form onSubmit={onSearchForGazetteLinks}>
          <label>Унесите годину или број/година</label>
          <input
            className="form-control"
            type="text"
            placeholder="годинa или број/година"
            value={searchGazette}
            onChange={(e) => setSearchGazette(e.target.value)}
          />
          <br />
        <button 
          type="submit"
          className="pull-right btn btn-primary"
          onClick={onSearchForGazetteLinks}
        >
          Тражи
        </button>
      </form>
      <br />
      {emptyList && (
          <>
          <div className="text-danger">{emptyList}</div>
          <br />
          </>
          )}
      <br />
      {listOfGazetteLinks.length > 0 ? (
        <>
        <div>
          <Table
            columns={GazetteLinkColums}
            data={listOfGazetteLinks}
            rememberFilter={false}
            hiddenColumn={["countryGuid", "issueTypeGuid", "pdfDocumentUrl"]}
            serverSide={false}
            onRowSelectStateChange={setSelectedRowIds}
          />
        </div>
          <br />
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={addGazetteLink}
          >
            Додај
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
});

export default CreateGazetteLink;
