import React, { useState, useEffect } from "react"
import Table from "../../../../common/table/Table.component"
import LocationsClient from "../../../api-services/codebooks/locations/locations"
import HandlingResponse from "../../../../common/handling-response/handlingResponse"
import Moment from "moment";

const Locations = () => {
    const tableName ='FP_LOCATIONS';

    const columns = [
        {
            Header: "Назив",
            accessor: "name"
        },
        {
            Header: "Текст за приказ на порталу",
            accessor: "displayText"
        },
        {
            Header: "Код",
            accessor: "code"
        },
        {
            Header: "Датум креирања",
            accessor: "creationDate",
            Cell: ({ row }) => {
                return row.original.creationDate
                ? Moment(row.original.creationDate).format("DD.MM.YYYY. HH:mm")
                : "";
      },
        }
    ]

    const [tableData, setTableData] = useState([]);
    const client = new LocationsClient();

    const getLocations = async () => {
        let response = await client.GetLocations();
        let result = HandlingResponse(response);
        setTableData(result);
    }

    useEffect(() => {
        getLocations()
    }, [])
    return (
        <>
        <h5 className="col-md-2" style={{marginBottom: "25px"}}>Локације</h5>
       <Table
          columns={columns}
          data={tableData}
          tableName={tableName}
          rememberFilter={true}
          hiddenColumn={["id"]}
          serverSide={false}
        />
        </>
    )
}

export default Locations