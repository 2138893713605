import React, {useEffect} from "react";
import { Outlet } from "react-router-dom";
import useAppUrl from "../../../hooks/useAppUrl";
import { MainContainer } from "./Layout.style";

const PPPLayout = () => {
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();
  useEffect(() => {
    setAppName("ППП");
    setAppPath("/ppp");
  }, [])
  
  return (
    <>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </>
  );
};

export default PPPLayout;
