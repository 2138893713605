import { useEffect } from "react";
import { createContext, useState } from "react"
import { removeTableObjectsFromSessionStorage } from '../../common/table/Table.component';

const RenderDifferentComponentContext = createContext({});

export const levelEnum = {
    Package: 0,
    Area: 1,
    Group: 2,
    Subgroup: 3,
    Act: 4,
    Subact: 5
}

export const RenderDifferentComponentProvider = ({ children }) => {
    const pppPath = [
        { title: "Пакет", id: null, level: levelEnum.Package, show: true, name: "", tableName: "PPP_PACKAGES" },
        { title: "Област", id: null, level: levelEnum.Area, show: false, name: "", tableName: "PPP_AREAS" },
        { title: "Група", id: null, level: levelEnum.Group, show: false, name: "", tableName: "PPP_GROUPS" },
        { title: "Подгрупа", id: null, level: levelEnum.Subgroup, show: false, name: "", tableName: "PPP_SUBGROUPS" },
        { title: "Акт", id: null, level: levelEnum.Act, show: false, name: "", tableName: "PPP_ACTS" },
        { title: "Подакт", id: null, level: levelEnum.Subact, show: false, name: "", tableName: "PPP_SUBACTS" },
    ]

    const [onTopLevel, setOnTopLevel] = useState(true);

    const getInitialState = () => {
        const path = sessionStorage.getItem("PPP_path");
        if (path) {
            var parsedObj = JSON.parse(path);
            let level = parsedObj.find((x) => x.show == true)?.level;
            if (level === 0) setOnTopLevel(true);
            else setOnTopLevel(false);
            return parsedObj;
        }
        else return pppPath;
    }

    const getCurrentLocation = () => {
        const location = sessionStorage.getItem("PPP_current_loc");
        return location ? JSON.parse(location) : { id: 0, name: "", level: levelEnum.Package }
    }

    const [pathOfRender, setPathOfRender] = useState(getInitialState);
    const [currentLocation, setCurrentLocation] = useState(getCurrentLocation); // id i ime gde se nalazimo trenutno
    const [isCreateAct, setIsCreateAct] = useState(false);
    const [parentIdForSubact, setParentIdForSubact] = useState(null);
    const [showListOfSubacts, setShowListOfSubacts] = useState(false);
    const [isListEmpty, setIsListEmpty] = useState(false); //setuje se na true onda kada je oblast/grupa/podgrupa prazna

    useEffect(() => {
        sessionStorage.setItem("PPP_path", JSON.stringify(pathOfRender));
    }, [pathOfRender]);

    useEffect(() => {
        sessionStorage.setItem("PPP_current_loc", JSON.stringify(currentLocation));
    }, [currentLocation]);


    const handleChangeComponent = (level, id, value) => {
        setCurrentLocation({ id: id, name: value, level: level });
        let indexLevel = pppPath.findIndex((x) => x.level === level);
        if (indexLevel === 0) setOnTopLevel(true);
        else setOnTopLevel(false);

        setPathOfRender(prevData => {
            const newData = [...prevData];
            newData.map((el, index) => {
                if (el['show']) {
                    el['name'] = value;
                    el['id'] = id;
                }
                if (index === indexLevel) el['show'] = true;
                else el['show'] = false;
            })
            return newData;
        });
    }

    const handleBackClick = () => {
        let indexLevel = pathOfRender.findIndex((x) => { if (x.show === true && x.name != "") return x.level }) - 1;

        if (indexLevel < 0)
            indexLevel = pathOfRender.findIndex(x => { if (x.name == "") return x.level }) - 1; //ukoliko iz oblasti idu direktno akti
        else if (pathOfRender.find((x) => (x.level == indexLevel)).name === "")
            indexLevel--; //ukoliko ne postoji podgrupa

        if (showListOfSubacts) {
            indexLevel = levelEnum.Subact - 1;
            setShowListOfSubacts(false);
        }
        if (isCreateAct) {
            setIsCreateAct(false);
        }
        else if (indexLevel === levelEnum.Package) {
            setOnTopLevel(true);
            setPathOfRender(pppPath);
            removeTableObjectsFromSessionStorage('PPP_AREAS');
            setCurrentLocation({ id: 0, name: "", level: levelEnum.Package });
        }
        else {
            setOnTopLevel(false);
            setParentIdForSubact(null);
            setPathOfRender(prevData => {
                const newData = [...prevData];
                newData.map((el, id) => {
                    if (el['show']) {
                        removeTableObjectsFromSessionStorage(el['tableName']);
                        el['name'] = "";
                        el['id'] = null;
                    };
                    if (id === indexLevel) {
                        el['show'] = true;
                        //uslovi postoje zato sto je moguce da akti postoje vec u oblasti, ukoliko se vracamo nazad iza liste akata, da se ne bi u
                        //current stavilo null, potrebno je da se vidi na kom nivou se zapravo nalazimo
                        if (newData[id - 1].level === levelEnum.Subgroup && newData[id - 1].id === null)
                            if (newData[id - 2].level === levelEnum.Group && newData[id - 2].id === null)
                                setCurrentLocation({ id: newData[id - 3].id, name: newData[id - 3].name, level: newData[id - 3].level });
                            else setCurrentLocation({ id: newData[id - 2].id, name: newData[id - 2].name, level: newData[id - 2].level  });
                        else setCurrentLocation({ id: newData[id - 1].id, name: newData[id - 1].name, level: newData[id - 1].level });
                    }
                    else el['show'] = false;
                })
                return newData;
            });
        }
    }
    // vraca ID oblasti u kojoj se trenutno nalazi neka grupa/podgrupa/akt
    const getAreaId = () => {
        let element = pathOfRender.find(element => element.level === levelEnum.Area);
        if (element) return element.id;
        else return 0;
    }

    const getPackageId = () => {
        let element = pathOfRender.find(element => element.level === levelEnum.Package);
        if (element) return element.id;
        else return 0;
    }

    //poziva se kada se iz prazne oblasti/grupe kreira akt, pa je potrebno da se ti akti prikazu
    const changeLevel = () => {
        setOnTopLevel(false);
        setIsListEmpty(false);
        setPathOfRender(prevData => {
            const newData = [...prevData];
            newData.map((el, id) => {
                if (el['level'] === levelEnum.Act)
                    el['show'] = true;
                else el['show'] = false;
            })
            return newData;
        });
    }

    return (
        <RenderDifferentComponentContext.Provider value={{
            pathOfRender, onTopLevel, handleChangeComponent, handleBackClick, isCreateAct, setIsCreateAct,
            currentLocation, setCurrentLocation, parentIdForSubact, setParentIdForSubact, showListOfSubacts,
            setShowListOfSubacts, getAreaId, getPackageId, isListEmpty, setIsListEmpty, changeLevel,
            getCurrentLocation
        }}>
            {children}
        </RenderDifferentComponentContext.Provider>
    )
}

export default RenderDifferentComponentContext