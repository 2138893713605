import React, { useEffect, useState } from "react";
import CourtClient from "../../api-services/coders/courts/courts-client";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CourtDepartmentClient from "../../api-services/coders/court-departments/court-deparments-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import { handleSave, serverValidation, validateForm } from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi  from "joi-browser";
import Moment from "moment";

const CreateSudskaOdeljenja = ({ row, handleCallback, isCreate }) => {
  var { t } = useTranslation();

  const [courtList, setCourtList] = useState([]);
  const [errors, setErrors] = useState({});
  const [courtDepartment, setCourtDepartment] = useState({
    id: "",
    name: "",
    weight: 0,
    isActive: true,
    userGuid: "",
    userFullName: "",
    lastModifyDate: null,
    fkCourt: "",
  });

  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
    fkCourt: Joi.required()
  }

  const client = new CourtDepartmentClient();
  const courtClient = new CourtClient();

  useEffect(() => {
    Court();
    setErrors({});
  }, []);
  
  const Court = async () => {
    await courtClient.Court().then((response) => {
      var handledResponse = HandlingResponse(response);
      var data = handledResponse !== 'Error' ? handledResponse.map((item) => {
        return { value: item.id, label: item.name, name: "fkCourt" };
      }): [];
      setCourtList(data);
    });
  };
  useEffect(() => {
    setCourtDepartment(row);
  }, [row, isCreate]);

  const CreateCourtDepartment = async () => {
    const data = {
      id: 0,
      fkCourt: courtDepartment.fkCourt,
      name: courtDepartment.name,
      weight: courtDepartment.weight,
      isActive: courtDepartment.isActive,
      lastModifyDate: new Date(),
    };
    const result = validateForm(data, schema, t);
    var response = await client.CreateCourtDepartment(data);
    serverValidateAction(response);
    setErrors(result);
  };

  const UpdateCourtDepartment = async () => {
    const data = {
      id: courtDepartment.id,
      fkCourt: courtDepartment.fkCourt,
      name: courtDepartment.name,
      weight: courtDepartment.weight,
      isActive: courtDepartment.isActive,
      lastModifyDate: new Date(),
    };
    const result = validateForm(data, schema, t);
    var response = await client.UpdateCourtDepartment(courtDepartment.id, data);
    serverValidateAction(response);
    setErrors(result);
  };

  const serverValidateAction = (response) => {
    var handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, courtDepartment, errors, schema, t);
    setCourtDepartment(result.data);
    setErrors(result.errors);
  }

  return (
    <>
      <form>
        <div className="form-group">
          <label>Суд<span className="required-asterisk">*</span></label>
          <Select
            options={courtList}
            onChange={(event) => handlePropertyChange(event)}
            value={courtList?.filter((x) => x.value === courtDepartment.fkCourt)}
          />
          {errors?.fkCourt && <div className="text-danger">{errors.fkCourt}</div>}
        </div>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={courtDepartment.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Тежина</label>
          <input
            className="form-control"
            type="number"
            name="weight"
            value={courtDepartment.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div>
          <input
                  id="activeCourtDepartmentCB"
                      type="checkbox"
                      value={courtDepartment.isActive}
                      checked={courtDepartment.isActive}
                      onChange={(event) => {
                          setCourtDepartment({ ...courtDepartment, isActive: event.target.checked });
                      }}
            />
            <label htmlFor="activeCourtDepartmentCB">Активан</label>
        </div>
          {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{courtDepartment.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{courtDepartment.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>{Moment(courtDepartment.lastModifyDate).format("DD.MM.YYYY.")}</div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateCourtDepartment()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateCourtDepartment()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateSudskaOdeljenja;
