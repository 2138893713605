import Joi from "joi-browser";

class SettingsModel {
    constructor(id = 0, parameterKey = "", parameterValue = "", description="") {
        this.id = id;
        this.parameterKey = parameterKey;
        this.parameterValue = parameterValue;
        this.description = description;
    }
}


const settingsSchema = {
    parameterKey: Joi.string().required(),
    parameterValue: Joi.string().max(500).required(),
    description: Joi.string().optional()
}



export { SettingsModel, settingsSchema }