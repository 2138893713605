import Joi from "joi-browser";

class AddLawActModel {
    constructor(name="", shiftLawAct = new ShiftLawActModel()){
        this.name = name;
        this.shiftLawAct = shiftLawAct;
    }
}

class AddLawAdActModel {
    constructor(lawAdActGuid = "", shiftLawAct = new ShiftLawActModel()) {
        this.lawAdActGuid = lawAdActGuid;
        this.shiftLawAct = shiftLawAct;
    }
}

class ShiftLawActModel {
    constructor(selectedHierarchyMark = "1", areaId = 0, hierarchyMarkPosition = 0, createSubarea = false, parentId = null) {
        this.selectedHierarchyMark = selectedHierarchyMark;
        this.areaId = areaId;
        this.hierarchyMarkPosition = hierarchyMarkPosition;
        this.createSubarea = createSubarea;
        this.parentId = parentId;
    }
}

export const AddLawActSchema = {
    name: Joi.string().max(1000).required(),
    file: Joi.any().when("shiftLawAct.createSubarea", {
        is: true,
        then: Joi.optional(),
        otherwise: Joi.required(),
      }),
}

const hierarchyMarkPositionEnum = {
    Clicked: 0, //stavi na mesto kliknutog akta
    Nested: 1, //stavi na prvo mesto u aktu na koji je kliknuto
    Next: 2 //stavi na sledece mesto u odnosu na akt na koji je kliknuto
}

export {AddLawActModel, AddLawAdActModel, ShiftLawActModel, hierarchyMarkPositionEnum}