class PreviwActInfoModel {
    constructor(documentInfo = new DataInfo(), enactmentDate = null, references = Array(new LawActLinkView()), ) {
        this.documentInfo = documentInfo;
        this.enactmentDate = enactmentDate;
        this.references = references;
    }
}

class DataInfo
{
    constructor(dataId = "", dataHtmlUuid = "", dataHtmlContent = "", typeId = "", typeName = "", typeParentId = "", typeParentName = "",
    typeRootId= "", typeRootName = "", authorityId = "", authorityName = "", authorityKindId = "", authorityKindName = "",
    authorityGroupId ="", authorityGroupName = "", dataEnactmentDate = "", dataShowEnactmentDate = false, dataActLabelNumber ="", dataActLabelNumberHtml = "",
    dataActCloserDesignation = "", dataPrincipalAct = "", dataPrincipalActHtml = "", dataPrincipalActArticles = "", dataOtherActs = "",
    dataOtherActsHtml = "", sourceKindId  = "", sourceKindName = "", sourceId = "", sourceName = "", dataSourceId = "", 
    dataSourceDescription = "", defSourceKindId = "", defSourceKindName = "", defSourceId = "", defSourceName = "", 
    defDataSourceId = "", defDataSourceDescription  = "", sourceHtml = "", dataPdfUuid = "", dataYear = "", dataArchived = "") {
        this.dataId = dataId;
        this.dataHtmlUuid = dataHtmlUuid;
        this.dataHtmlContent= dataHtmlContent;
        this.typeId = typeId;
        this.typeName = typeName;
        this.typeParentId = typeParentId;
        this.typeParentName = typeParentName;
        this.typeRootId = typeRootId;
        this.typeRootName = typeRootName;
        this.authorityId = authorityId;
        this.authorityName = authorityName;
        this.authorityKindId = authorityKindId;
        this.authorityKindName = authorityKindName;
        this.authorityGroupId = authorityGroupId;
        this.authorityGroupName = authorityGroupName;
        this.dataEnactmentDate = dataEnactmentDate;
        this.dataShowEnactmentDate = dataShowEnactmentDate;
        this.dataActLabelNumber = dataActLabelNumber;
        this.dataActLabelNumberHtml = dataActLabelNumberHtml;
        this.dataActCloserDesignation = dataActCloserDesignation;
        this.dataPrincipalAct = dataPrincipalAct;
        this.dataPrincipalActHtml = dataPrincipalActHtml;
        this.dataPrincipalActArticles = dataPrincipalActArticles;
        this.dataOtherActs = dataOtherActs;
        this.dataOtherActsHtml = dataOtherActsHtml; 
        this.sourceKindId = sourceKindId; 
        this.sourceKindName = sourceKindName;
        this.sourceId = sourceId;
        this.sourceName = sourceName;
        this.dataSourceId = dataSourceId;
        this.dataSourceDescription = dataSourceDescription;
        this.defSourceKindId = defSourceKindId;
        this.defSourceKindName = defSourceKindName;
        this.defSourceId = defSourceId;
        this.defSourceName = defSourceName;
        this.defDataSourceId = defDataSourceId;
        this.defDataSourceDescription = defDataSourceDescription;
        this.sourceHtml = sourceHtml;
        this.dataPdfUuid= dataPdfUuid;
        this.dataYear = dataYear;
        this.dataArchived = dataArchived;
    }
}

class LawActLinkView {
    constructor(id = 0, name = "", url = "") {
       this.id = id;
       this.name = name;
       this.url = url; 
    }
}
export {PreviwActInfoModel}