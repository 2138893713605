import React, { useState, useEffect } from "react";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import { Link } from "react-router-dom";
import SideModal from "../../../common/modal/SideModal.component";
import { PackageModel, packageSchema } from "../../models/Package";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import PackageClient from "../../api-services/packages/package-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import AreaClient from "../../api-services/areas/area-client";
import { levelEnum } from "../../contexts/RenderDifferentComponentContext";
import { SelectColumnFilter } from "../../../common/table/filterHelpFunction.component";
import { RedTransparentButton } from "../../../global-styles/GlobalStyles.style";
import { GreenTransparentButton } from "../../../global-styles/GlobalStyles.style";
import { handleSave, validateForm } from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import ActClient from "../../api-services/acts/act-client";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const Packages = ({ handleCallback, tableName }) => {
  const filter = {
    filterValue: "equals",
    selectOptions: [
      {
        value: undefined,
        label: "Сви",
      },
      {
        value: "false",
        label: "Неактиван",
      },
      {
        value: "true",
        label: "Активан",
      },
    ],
  };
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Назив пакета",
      accessor: "packageName",
      disableFilters: false,
       
      Cell: ({ row }) => {
        return (
          <Link onClick={(e) => areasByPackageId(row.original.id, row.original.packageName)}>
            {row.original.packageName}
          </Link>
        );
      },
    },
    {
      Header: "Статус",
      accessor: "isInactive",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return <div>{row.original.isInactive ? "Неактиван" : "Активан"}</div>;
      },
    },
    {
      accessor: "activation",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
          <button
              title="Измени"
              className="btn btn-primary table-btn"
              onClick={() => onEditPackageClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
          <RenderOnRole roles={["PPP_Admin"]}>
          {row.original.isInactive ? (
              <GreenTransparentButton
              title="Активирај"
              type="button"
              className="btn table-btn"
              onClick={() => onActivateOrDeactivatePackage(row.original.id, !row.original.isInactive)}
            >
              <FontAwesomeIcon icon={solid("check")} />
            </GreenTransparentButton>
             
            ) : (
              <RedTransparentButton
              title="Деактивирај"
              type="button"
              className="btn table-btn"
              onClick={() => onActivateOrDeactivatePackage(row.original.id, !row.original.isInactive)}
            >
              <FontAwesomeIcon icon={solid("xmark")} />
            </RedTransparentButton>
            )}
          </RenderOnRole>
          </>
        );
      },
    },
  ];

  const { handleChangeComponent } = UseRenderDifferentComponent();
  var { t } = useTranslation();

  const packageClient = new PackageClient();
  const areaClient = new AreaClient();
  const actClient = new ActClient();

  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  const [showCreatePackage, setShowCreatePackage] = useState(false);
  const [packageObj, setPackageObj] = useState(new PackageModel());
  const [listOfPackages, setListOfPackages] = useState([]);

  useEffect(() => {
    getAllPackages();
  }, []);

  const showModalForCreatingPackage = () => {
    setErrors({});
    setShowCreatePackage(true);
  };
  const hideModalForCreatingPackage = () => {
    setPackageObj(new PackageModel());
    setShowCreatePackage(false);
  }

  const getAllPackages = async () => {
    let response = await packageClient.getAllPackages();
    let handledResponse = HandlingResponse(response);
    setListOfPackages(handledResponse);
    setPackageObj(new PackageModel());
    setIsEdit(false);
  }

  const createOrEditPackage = async () => {
    const result = validateForm(packageObj, packageSchema, t);
    if(result) {
      setErrors(result);
    } else {
      if(isEdit) {
        var response = await packageClient.updatePackage(packageObj);
      }
      else {
        var response = await packageClient.createPackage(packageObj);
      }
      var handledResponse = HandlingResponse(response);
      if(handledResponse != 'Error') {
        hideModalForCreatingPackage();
        getAllPackages();
      }
    }
  };

  const onEditPackageClick = async (row) => {
    setIsEdit(true);
    setPackageObj(row);
    showModalForCreatingPackage();
  };

  const onActivateOrDeactivatePackage = async (id, isInactive) => {
    let response;
    if (isInactive === false) {
      response = await packageClient.activatePackage(id);
      
    } else {
      response = await packageClient.deactivatePackage(id);
    }
    
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      const updatedListOfActs = listOfPackages.map(pack => {
        if (pack.id === id) {
          return { ...pack, isInactive };
        }
        return pack;
      });
      setListOfPackages(updatedListOfActs);
    }
  };

  const areasByPackageId = async (id, packageName) => {
    let response = await areaClient.getFirstLevelAreasByPackageId(id);
    let handledResponse = HandlingResponse(response);
    await handleCallback(handledResponse);
    handleChangeComponent(levelEnum.Area, id, packageName);
  };

  
  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, packageObj, errors, packageSchema, t);
    setPackageObj(result.data);
    setErrors(result.errors);
  };

  const indexAllData = async () => {
    var response = await actClient.massIndexing();
    HandlingResponse(response);
  };

  return (
    <>
      <div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={showModalForCreatingPackage}
          >
            Креирај пакет
          </button>
          <RenderOnRole roles={["PPP_Admin"]}>
          <ButtonWithComfirmation
              className="btn btn-primary pull-right"
              onClick={() => indexAllData()}
              contentText={"Покренуће се индексирање свих аката."}
              handleYesText={"Покрени"}
              >
                Масовно индексирање
            </ButtonWithComfirmation>
          </RenderOnRole>
        </div>
        <br />
        {listOfPackages.length > 0 && <Table
          columns={columns}
          rememberFilter={true}
          tableName={tableName}
          hiddenColumn={["id"]}
          data={listOfPackages}
          serverSide={false}
        />}
      </div>
      <SideModal
        show={showCreatePackage}
        handleClose={hideModalForCreatingPackage}
        title={isEdit ? "Измени пакет" : "Креирај нови пакет"}
      >
        <div>
          <label>Назив пакета<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="packageName"
            value={packageObj.packageName}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.packageName && <div className="text-danger">{errors.packageName}</div>}
        </div>
        <br />
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={createOrEditPackage}
        >
          Сачувај
        </button>
      </SideModal>
    </>
  );
};

export default Packages;
