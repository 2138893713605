import API from "../../../api";
import { env } from "../../../env";

const baseurl = env.REACT_APP_API_URL_PPP;

class AdvertisementAndActTypeClient {
    async getAllAdvertisementAndActType(isBackground) {
        try {
            const response = await API.get(baseurl + "/AdvertisementAndActType/GetAllAdvertisementAndActTypes", { isBackground: isBackground });
            return response.data;
        }
        catch (err) {
            if (err.response.status === 400) return err.response.data;
            else return err.response;
          }
    }

    async createAdvertisementAndActType(data, isBackground) {
        try {
            const response = await API.post(baseurl + "/AdvertisementAndActType/CreateAdvertisementAndActType", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }

    async updateAdvertisementAndActType(data, isBackground) {
        try {
            const response = await API.put(baseurl + "/AdvertisementAndActType/UpdateAdvertisementAndActType", data, { isBackground: isBackground });
            return response.data;
        } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
    }
}

export default AdvertisementAndActTypeClient;