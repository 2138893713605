import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TreeLabel } from './Tree.styles'
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import SelectedNodeContext from "./SelectedNodeProvider";
import TreeList from "./TreeList.component";
import { preventDefaultEvents } from "../../utils/common-functions/CommonFunctions";
import { scrollToElement } from "../../utils/common-functions/CommonFunctions";

const TreeNode = ({ node, expanded, contextMenuEvent, handleSelected, preselectedNodeId, openParent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { selectedNodeId, setSelectedNodeId } = useContext(SelectedNodeContext);
    const isActive = useMemo(() => selectedNodeId === node.id, [selectedNodeId])
    const hasChildren = useMemo(() => node.children && node.children.length > 0, [node])
    const treeNodeRef = useRef()

    useEffect(() => {
        if (preselectedNodeId && preselectedNodeId === node.id) {
            selectNode();
            if (openParent) {
                openParent();
            }
        }
    }, [])

    const toggleChildren = e => {
        preventDefaultEvents(e);
        selectNode();
    }

    const selectNode = () => {
        setSelectedNodeId(node.id);
        if (hasChildren) {
            setIsOpen(!isOpen);
        }
        if (handleSelected) {
            handleSelected(node);
        }
        scrollToElement(treeNodeRef);
    }

    const openNode = () => {
        setIsOpen(true);
        if (openParent) {
            openParent();
        }
    }

    return <>
        {node.name && <li ref={treeNodeRef} style={{ display: expanded ? "block" : "none" }}>
            <TreeLabel onContextMenu={e => node.id !== 0 && contextMenuEvent(e, node)} onClick={toggleChildren}>
                {hasChildren ? (isOpen ? <FontAwesomeIcon icon={solid("folder-open")} /> :
                    <FontAwesomeIcon icon={solid("folder")} />) : <FontAwesomeIcon icon={solid("file")} />}
                <span className={isActive ? "active-act-background" : ""}>{node.name}</span>
            </TreeLabel>
            {hasChildren &&
                <TreeList className={"visible-acts"} data={node.children} contextMenuEvent={contextMenuEvent} handleSelected={handleSelected}
                    expanded={isOpen} preselectedNodeId={preselectedNodeId} openParent={openNode}></TreeList>}
        </li>}
    </>
};

TreeNode.propTypes = {
    node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired
    })
}
export default TreeNode;