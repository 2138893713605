import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateAdvertisementAndActType from "../../../components/create-advertisement-and-act-type/createAdvertisementAndActType.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AdvertisementAndActTypeClient from "../../../api-services/advertisement-and-act-type/advertisement-and-act-type";
import AdvertisementAndActTypeModel from "../../../models/AdvertisementAndActType";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const AdvertisementAndActType = () => {
  const { t } = useTranslation();
  const tableName = "AP_ADVERTISEMENT_AND_ACT_TYPE";

  const client = new AdvertisementAndActTypeClient();

  const columns = [
    {
      Header: t("Id"),
      accessor: "id",
      disableFilters: false,
    },
    {
      Header: t("Description"),
      accessor: "description",
      Cell: ({ value }) => (
        <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
      ),
      disableFilters: false,
    },
    {
      Header: t("DefaultSort"),
      accessor: "defaultSort",
      Cell: ({ value }) => (
        <div style={{ wordBreak: "break-word", height: "auto" }}>{value}</div>
      ),
      disableFilters: false,
    },
    {
      Header: t("ForOg"),
      accessor: "isForOg",
      Cell: ({ row }) => {
        return (
          row.original.isForOg && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: t("ForReg"),
      accessor: "isForReg",
      Cell: ({ row }) => {
        return (
          row.original.isForReg && <FontAwesomeIcon icon={solid("check")} />
        );
      },
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
        return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState(new AdvertisementAndActTypeModel());
  const [allAdvertisementAndActType, setAllAdvertisementAndActType] = useState(
    []
  );
  const [title, setTitle] = useState("");

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData(new AdvertisementAndActTypeModel());
  };

  const getAdvertisementAndActType = async () => {
    let response = await client.getAdvertisementAndActType();
    let handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      const categories = handledResponse.filter((x) => x.isLaw);
      setAllAdvertisementAndActType(categories);
    }
  };

  useEffect(() => {
    getAdvertisementAndActType();
  }, []);

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new AdvertisementAndActTypeModel());
    showModal();
    setTitle(t("CreatingAKindOfLegalAct"));
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
    setTitle(t("EditingAKindOfLegalAct"));
  };

  const handleAddingDataToTable = () => {
    hideModal();
    getAdvertisementAndActType()
  };

  return (
    <>
      <div className="form-control">
        <div className="form-group">
          <h5 className="col-md-2">{t("KindsOfLegalActs")}</h5>
          <button className="btn btn-primary col-md-2" onClick={onCreateClick}>
            {t("AddKindOfLegalAct")}
          </button>
        </div>

        <Table
          columns={columns}
          data={allAdvertisementAndActType}
          rememberFilter={true}
          tableName={tableName}
          serverSide={false}
        />
      </div>

      <SideModal show={show} handleClose={hideModal} title={title}>
        <CreateAdvertisementAndActType
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default AdvertisementAndActType;
