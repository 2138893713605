import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PreviewNewsClient from "../../../api-services/news/preview/preview";
import { format } from "date-fns";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../../common/table/Table.component";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import SearchPreviewNews from "../../../components/news/search-preview-news/SearchPreviewNews.component";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateNews from "../../../components/news/create-news/CreateNews.component";
import IndexingStatus, {
  StatusForIndexing,
  StatusForDeleteIndex,
} from "../../../../common/indexing-status/IndexingStatus";
import { useTranslation } from "react-i18next";

const Preview = () => {
  const { t } = useTranslation();
  const tableName = "FP_PREVIEW";
  const rememberFilter = true;

  const columns = [
    {
      Header: "Категорије",
      accessor: "categoryName",
    },
    {
      Header: "Скраћени текст",
      accessor: "shortText",
    },
    {
      Header: "Интерно обележје",
      accessor: "internalMark",
    },
    {
      Header: "Датум објављивања",
      accessor: "publishedDate",
      Cell: ({ row }) => {
        const parsedDate = Date.parse(row.original.publishedDate);
        return format(parsedDate, "dd.MM.yyyy. HH:mm");
      },
    },
    {
      Header: "Објављена",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {row.original.published && (
              <FontAwesomeIcon icon={solid("check")} />
            )}
          </div>
        );
      },
    },
    {
      Header: "Актуелна",
      accessor: "actual",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {row.original.actual && <FontAwesomeIcon icon={solid("check")} />}
          </div>
        );
      },
    },
    {
      Header: "Ударна",
      accessor: "breaking",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {row.original.breaking && <FontAwesomeIcon icon={solid("check")} />}
          </div>
        );
      },
    },
    {
      Header: "Укључена у претрагу",
      accessor: "includeInSearchResult",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {row.original.includeInSearchResult && (
              <FontAwesomeIcon icon={solid("check")} />
            )}
          </div>
        );
      },
    },
    {
      Header: "Датум креирања",
      accessor: "creationDate",
      Cell: ({ row }) => {
        const parsedDate = Date.parse(row.original.creationDate);
        return format(parsedDate, "dd.MM.yyyy. HH:mm");
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
            {!row.original.published && (
              <ButtonWithComfirmation
                className="btn btn-danger table-btn"
                onClick={() => onDeleteClick(row.original.id)}
                contentText={"Вест ће бити трајно обрисана."}
                handleYesText={"Обриши"}
              >
                <FontAwesomeIcon icon={solid("trash")} />
              </ButtonWithComfirmation>
            )}
          </>
        );
      },
    },
    {
      Header: "Статус индексирања",
      accessor: "indexStatus",
      Cell: ({ value }) => <IndexingStatus value={value} />,
    },
    {
      accessor: "addIndex",
      className: "one-button-column",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <button
              title={
                row.original.published
                  ? "Индексирај"
                  : "Индексирање необјављене вести није дозвољено"
              }
              type="button"
              className="btn btn-primary table-btn"
              onClick={() => indexNews(row.original.id)}
              disabled={
                !row.original.published ||
                !StatusForIndexing.includes(row.original.indexStatus)
              }
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [reset, setReset] = useState(false);

  const [newsFilterData, setNewsFilterData] = useState({
    newsCategories: "",
    shortText: "",
    internalMark: "",
    publishedDateFrom: null,
    publishedDateTo: null,
    published: 2,
    actual: 2,
    breaking: 2,
    includeInSearchResult: 2,
  });

  const [rowData, setRowData] = useState({
    newsCategories: "",
    shortText: "",
    fullText: "",
    publishedDate: null,
    published: false,
    actual: false,
    breaking: false,
    active: false,
    includeInSearchResult: false,
    htmlGuid: "",
    internalMark: "",
  });

  useEffect(() => {
    reset && setReset(false);
  }, [tableData]);

  var client = new PreviewNewsClient();

  // Modal
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  // Pretraga i resetovanje filtera
  const filterNewsForTable = async (pageNumber, pageSize, sortBy) => {
    const response = await client.filterNews(
      {
        ...newsFilterData,
        newsCategories: newsFilterData.categoryName,
        pageSize: pageSize,
        pageNumber: pageNumber,
        fieldNamesForSort: sortBy,
      },
      false
    );

    let data = HandlingResponse(response);
    return data;
  };

  const ResetFilter = async () => {
    setReset(true);
    setSelectedEnums({
      published: "2",
      actual: "2",
      breaking: "2",
      includeInSearchResult: "2",
    });
    setNewsFilterData({
      newsCategories: "",
      shortText: "",
      internalMark: "",
      publishedDateFrom: null,
      publishedDateTo: null,
      published: 2,
      actual: 2,
      breaking: 2,
      includeInSearchResult: 2,
    });
    const newsCategoriesArr = [parseInt(newsFilterData.categoryName)].filter(
      Boolean
    );
    var response = await client.filterNews(
      {
        shortText: "",
        internalMark: "",
        publishedDateFrom: null,
        publishedDateTo: null,
        published: 2,
        actual: 2,
        breaking: 2,
        includeInSearchResult: 2,
      },
      true
    );
    let data = HandlingResponse(response);
    setTableData(data);
    setShowTable(false);
  };

  const searchData = async (e) => {
    e?.preventDefault();
    const response = await filterNewsForTable(
      getPageNumber(tableName, rememberFilter),
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setTableData(response);
    setShowTable(true);
  };

  // Checkboxovi
  const [selectedEnums, setSelectedEnums] = useState({
    published: "2",
    actual: "2",
    breaking: "2",
    includeInSearchResult: "2",
  });

  const handleEnumChange = (event) => {
    const { name, value } = event.target;
    setSelectedEnums({ ...selectedEnums, [name]: value });
  };

  useEffect(() => {
    setNewsFilterData({
      ...newsFilterData,
      published: parseInt(selectedEnums.published),
      actual: parseInt(selectedEnums.actual),
      breaking: parseInt(selectedEnums.breaking),
      includeInSearchResult: parseInt(selectedEnums.includeInSearchResult),
    });
  }, [selectedEnums]);

  // Kreiranje nove vesti
  const createNews = () => {
    setEdit(false);
    setRowData({
      categoryName: "",
      shortText: "",
      fullText: "",
      publishedDate: new Date().toLocaleString("en-US", {
        timeZone: "Europe/Belgrade",
      }),
      published: false,
      actual: true,
      breaking: false,
      includeInSearchResult: true,
      active: true,
    });
    showModal();
  };

  // Izmena vesti i slanje podataka u modal
  const onEditClick = async (id) => {
    var response = await client.GetNewsPageById(id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") {
      setEdit(true);
      setRowData(handledResponse);
      showModal();
    }
  };

  // Brisanje vesti
  const onDeleteClick = async (id) => {
    var response = await client.DeleteNews(id);
    HandlingResponse(response);
  };

  // masovno indeksiranje

  const indexAllnews = async () => {
    var response = await client.getMassIndexing();
    HandlingResponse(response);
  };

  const indexNews = async (id) => {
    var response = await client.getIndexNews(id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") searchData();
  };

  const deleteIndex = async (id) => {
    var response = await client.deleteIndexNews(id);
    var handledResponse = HandlingResponse(response);
    if (handledResponse !== "Error") searchData();
  };

  const handleAddingDataToTable = () => {
    hideModal();
    searchData();
  };

  return (
    <>
      <h5 className="col-md-2" style={{ marginBottom: "25px" }}>
        Вести
      </h5>
      <SearchPreviewNews
        newsFilterData={newsFilterData}
        setNewsFilterData={setNewsFilterData}
        handleEnumChange={handleEnumChange}
        selectedEnums={selectedEnums}
        resetFilter={ResetFilter}
        searchData={searchData}
      />
      <label>{t("countResult",{count: tableData.count, type: 'вести'})}</label>
      <hr />
      <div className="text-right">
        <button
          type="button"
          style={{ marginRight: "5px" }}
          className="btn btn-primary"
          onClick={() => createNews()}
        >
          Креирај вест
        </button>
        <ButtonWithComfirmation
          className="btn btn-primary"
          onClick={() => indexAllnews()}
          contentText={
            "Покренуће се масовно индексирање вести."
          }
          handleYesText={"Покрени"}
        >
          Масовно индексирање
        </ButtonWithComfirmation>
      </div>
       

      <Table
        columns={columns}
        data={tableData}
        rememberFilter={rememberFilter}
        tableName={tableName}
        serverSide={true}
        fetchFunction={filterNewsForTable}
        setData={setTableData}
        resetFilters={reset}
      />

      <SideModal
        show={show}
        handleClose={hideModal}
        title={edit ? "Измена вести" : "Креирање вести"}
      >
        <CreateNews
          row={rowData}
          edit={edit}
          handleCallback={handleAddingDataToTable}
        ></CreateNews>
      </SideModal>
    </>
  );
};

export default Preview;
