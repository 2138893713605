import { env } from "../../../env";
import api from "../../../api";

var baseurl = env.REACT_APP_API_URL_SLGL;
var baseurl2 = env.REACT_APP_API_URL_ADMIN_PANEL;

class LawAdActClient {
  async getLawActByName(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/LawAdAct/GetLawAdActByName",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterLawAdActs(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAdAct/FilterLawAdActs", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async filterLawAdActsByIssueTypeYearAndNum(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/LawAdAct/FilterLawAdActsByIssuetypeYearAndNum",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetLawAdActsByTypeNumberYear(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/LawAdAct/GetLawAdActsByTypeNumberYear", data, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createLawAdAct(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/LawAdAct/CreateLawAdAct",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async createArchiveLawAdAct(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/LawAdAct/CreateArchiveLawAdAct",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async updateLawAdAct(data, isBackground) {
    try {
      var response = await api.put(baseurl + "/LawAdAct/UpdateLawAdAct", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawAdActByInfos(data, isBackground) {
    try {
      var response = await api.post(
        baseurl + "/LawAdAct/GetLawAdActByInfos/",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawAdActsByNumberId(id, isBackground) {
    try {
      var response = await api.get(
        baseurl + "/LawAdAct/GetLawAdActsByNumberId?id=" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawAdActHtml(id, pdfName) {  //akcija koja vraca html ili pdf
    try{
      var response = await api.get(baseurl + "/LawAdAct/GetLawAdActHtml?id=" + id)
      if (response.data.data){  //vraca html i nije potrebna nikakva obrada
        return response.data;
      }
      else {  //vraca pdf
        var response = await api.get(baseurl + "/LawAdAct/GetLawAdActHtml?id=" + id, { responseType: 'blob' }
          ).then(({ data }) => {
              const blob = new Blob([data], { type: 'application/pdf' });
              const link = document.createElement('a');
              const url = URL.createObjectURL(blob);
              link.href = url;
              link.download = pdfName;
              link.click();
          });
      }
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getAuthoritiesByNumber(numberId, isBackground) {
    const params = {
      params: {
        numberId: numberId,
      },
      isBackground: isBackground,
    };
    try {
      var response = await api.get(
        baseurl + "/LawAdAct/GetAuthoritiesByNumber",
        params
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getCategoriesByNumberAndAuthority(
    numberId,
    authorityGuid,
    isBackground
  ) {
    const params = {
      params: {
        numberId: numberId,
        authorityGuid: authorityGuid,
      },
      isBackground: isBackground,
    };
    try {
      var response = await api.get(
        baseurl + "/LawAdAct/GetCategoriesByNumberAndAuthority",
        params
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawAdActByNymberAuthorityAndCategory(
    numberId,
    authorityGuid,
    categoryGuid,
    isBackground
  ) {
    const params = {
      params: {
        numberId: numberId,
        authorityGuid: authorityGuid,
        advertisementAndActTypeGuid: categoryGuid,
      },
      isBackground: isBackground,
    };
    try {
      var response = await api.get(
        baseurl + "/LawAdAct/GetLawAdActByNymberAuthorityAndCategory",
        params
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async getLawAdActWithAttachments(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAdAct/GetLawAdActWithAttachments/" + id, { isBackground: isBackground });
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }


  async UpdateLawAdActFile(data, isBackground) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.put(
        baseurl + "/LawAdAct/UpdateLawAdActFile",
        data,
        { headers: headers },
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async massIndexing(isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAdAct/MassIndexing", {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async indexLawAdAct(id, isBackground) {
    try {
      var response = await api.get(baseurl + "/LawAdAct/IndexLawAdAct/" + id, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async deleteIndexLawAdAct(id, isBackground) {
    try {
      var response = await api.get(
        baseurl + "/LawAdAct/DeleteIndexLawAdAct/" + id,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async DownloadFilesFromRepo(id, name) {
    try {
      var response = await api
        .get(baseurl + "/LawAdAct/DownloadFilesFromRepo/" + id, { responseType: 'blob' })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = name;
          link.click();
        });
    } catch (err) {
      if (err.response.status === 404 && err.response.config.responseType === 'blob') {
        let responseObj = await err.response.data.text();
        return JSON.parse(responseObj);
      }
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default LawAdActClient;
