import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import EditNumbers from "../edit-numbers/EditNumbers.component";

const CurrentYearNumbers = ({ activeTab }) => {
  const [isCurrent, setIsCurrent] = useState(true);

  return (
    <>
      {isCurrent ? (
        <EditNumbers
          isCurrent={isCurrent}
          filterName={"SLGL_EDIT_CURRENT_NUMBERS_FILTER"}
          activeTab={activeTab}
        ></EditNumbers>
      ) : (
        <></>
      )}
    </>
  );
}

export default CurrentYearNumbers