import React, { useState, useEffect, useCallback } from "react";
import Table, { removeTableObjectsFromSessionStorage } from "../../../common/table/Table.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../common/modal/SideModal.component";
import CreateProduct from "../../components/create-product/CreateProduct.component";
import ProductReview from "../../components/product-review/ProductReview.component";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import { format } from "date-fns";
import {
  dateBetweenFilterFn,
  DateRangeColumnFilter,
  SelectColumnFilter,
} from "../../../common/table/filterHelpFunction.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";

const Product = () => {
  const tableName = 'LOZA_PRODUCT';
  const productLinksTableName = "LOZA_PRODUCT_LINKS";

  const filter = {
    filterValue: "equals",
    selectOptions: [
      {
        value: undefined,
        label: "Сви",
      },
      {
        value: "0",
        label: "Активан",
      },
      {
        value: "1",
        label: "Неактиван",
      },
    ],
  };
  const columns = [
    {
      Header: "Назив производа",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Статус",
      accessor: "productStatus",
      width: 150,
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({row}) => { return (row.original.productStatus === 0 ? "Активан" : "Неактиван") }
    },
    {
      Header: "Цена",
      accessor: "price",
      disableFilters: false,
    },
    {
      Header: "Важи од",
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return (
          row.original.dateFrom && (
            <div>{format(new Date(row.original.dateFrom), "dd.MM.yyyy.")}</div>
          )
        );
      },
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
    },
    {
      Header: "Важи до",
      accessor: "dateTo",
      Cell: ({ row }) => {
        return (
          row.original.dateTo && (
            <div>{format(new Date(row.original.dateTo), "dd.MM.yyyy.")}</div>
          )
        );
      },
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
    },
    {
      Header: "Држава",
      accessor: "countryName",
      disableFilters: false,
    },
    {
      Header: "Врстa документа",
      accessor: "listOfIssueTypeNames",
      disableFilters: false,
      Cell: ({row}) => {
        return ( row.original.listOfIssueTypeNames.join(", ") )
      }
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEdit(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          </>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [productId, setProductId] = useState("");
  const [chosenFile, setChosenFile] = useState("");
  const [show, setShow] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [rowData, setRowData] = useState({
    countryName: undefined,
    name: "",
    productStatus: 1,
    productTemplate: 0,
    dateFrom: "",
    dateTo: "",
    pageHeaderImageUrl: "",
    description: "",
    numCountryGuid: "",
    shortName: "",
    price: 0,
    helpUrl: "",
    impressumUrl: "",
    editorialUrl: "",
    contactUrl: "",
    productMainTemplateLogo: "",
    isIncludedInPeriod: true,
    isCurrentYearProduct: true,
    id: 0,
    issueTypes: [],
  });
  const [productAuthorities, setProductAuthorities] = useState([]);
  const [showProductAuthorities, setShowProductAuthorities] = useState(false);

  const client = new PackageClient();

  const showModal = () => setShow(true);
  const hideModal = () => {
    removeTableObjectsFromSessionStorage(productLinksTableName);
    setShow(false);
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    let response = await client.GetProduct();
    let res = HandlingResponse(response);
    let result = res.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    setTableData(result);
    setShowProductAuthorities(false);
  };

  // proveriti na backendu da li se koristi handling response i kako se vracaju podaci u ovoj situaciji
  const GetProductId = async (id) => {
    let response = await client.GetProductById(id);
    let res = HandlingResponse(response);
    setChosenFile(res.productImage);
  };

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      period: "1",
      countryName: undefined,
      name: "",
      productStatus: 1,
      productTemplate: 0,
      description: "",
      shortName: "",
      price: 0,
      helpUrl: "",
      impressumUrl: "",
      editorialUrl: "",
      contactUrl: "",
      productMainTemplateLogo: "",
      pageHeaderImageUrl: "",
      isIncludedInPeriod: true,
      isCurrentYearProduct: true,
      dateFrom: "",
      dateTo: "",
      numCountryGuid: "",
      id: 0,
      issueTypes: [],
    });
    showModal();
  };

  const onEdit = (data) => {
    setIsCreate(false);
    setRowData({
      ...data,
      period: data.isCurrentYearProduct
        ? "1"
        : data.isIncludedInPeriod
        ? "2"
        : "3",
      dateFrom: data.dateFrom ? new Date(data.dateFrom) : "",
      dateTo: data.dateTo ? new Date(data.dateTo) : "",
      numCountryGuid: data.numCountryGuid,
      issueTypes: data.productIssueTypes,
    });
    GetProductId(data.id);
    showModal();
  };

  const getProductReview = async (id) => {
    let response = await client.GetProductReview(id);
    let res = HandlingResponse(response);
    var result = res.productAuthorities.map((item) => {
      return { ...item, actual: item.actual ? "Да" : "Не" };
    });
    setProductAuthorities(result);
    setShowProductAuthorities(true);
  };

  const handleTableRowClick = useCallback((response) => {
    getProductReview(response.id);
    setProductId(response.id);
  });

  const handleAddingDataToTable = () => {
    hideModal();
    getProduct();
  };

  return (
    <>
      <h4>Производи</h4>
      <button onClick={onCreateClick} type="button" className="btn btn-primary" style={{marginBottom: "10px"}}>
        Креирај производ
      </button>
      <Table
        columns={columns}
        data={tableData}
        rememberFilter={true}
        tableName={tableName}
        handleClickOnRow={handleTableRowClick}
      />
      {showProductAuthorities && <ProductReview
          data={productAuthorities}
          productId={productId}
          handleCallback={() => getProductReview(productId)}
       ></ProductReview>}

      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Додавање производа" : "Измена производа"}
      >
        <CreateProduct
          row={rowData}
          isCreate={isCreate}
          file={chosenFile}
          tableName={productLinksTableName}
          handleCallback={handleAddingDataToTable}
        />
      </SideModal>
    </>
  );
};

export default Product;
