import { useState, useEffect } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import CreateClient from "../../api-services/create-accounts/create-accounts-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import DialogModal from "../../../common/modal/Modal.component";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import {
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import PackageExtensionRequest from "../../components/package-extension-request/PackageExtensionRequest.component";

const EditGroupAccounts = () => {
  const tableName = "LOZA_GROUP_ACCOUNTS";
  const { t } = useTranslation();
  const client = new CreateClient();

  const schema = {
    validTo: Joi.date().required(),
  };
  const searchSchema = {
    packageId: Joi.required(),
  };

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "userGuid",
      accessor: "userGuid",
    },
    {
      Header: "Корисничко име",
      accessor: "userName",
    },
    {
      Header: "Име",
      accessor: "name",
    },
    {
      Header: "Презиме",
      accessor: "lastName",
    },
    {
      Header: "Име компаније",
      accessor: "company",
    },
    {
      Header: "ПИБ",
      accessor: "pib",
    },
    {
      Header: "Иницијална лозинка",
      accessor: "initialPassword",
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditUser(row.original.userGuid)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
    },
  ];

  const [allPackages, setAllPackages] = useState([]);
  const [users, setUsers] = useState([]);
  const [extend, setExtend] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [reset, setReset] = useState(false);

  const [searchData, setSearchData] = useState({
    packageId: undefined,
    userName: "",
    name: "",
    lastName: "",
    company: "",
    pib: "",
  });

  const [isGroupAccount] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getAllPackages();

    const storedObject = sessionStorage.getItem("LOZA_GROUP_USER_ACCOUNTS");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setSearchData(parsedObject);
      getGroupUserPackages(parsedObject);
    }
  }, []);

  useEffect(() => {
    reset && setReset(false);
  }, [users]);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const onEditUser = async (userGuid) => {
    navigate("/loza/EditUserAccountById?userGuid=" + userGuid, {
      state: isGroupAccount,
    });
  };

  const getAllPackages = async () => {
    let response = await client.getPackages(true);
    let res = HandlingResponse(response);
    var result = res
      .filter((x) => x.isGroup)
      .map((item) => {
        return {
          value: item.id,
          label: item.extCode,
          name: "packageId",
        };
      });
    setAllPackages(result);
  };

  const getGroupUserPackages = async (dataForSearch) => {
    const errorsResult = validateForm(dataForSearch, searchSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      sessionStorage.setItem(
        "LOZA_GROUP_USER_ACCOUNTS",
        JSON.stringify(dataForSearch)
      );

      let response = await client.FilterGroupUsers(dataForSearch);
      let handledResponse = HandlingResponse(response);
      if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      } else {
        setUsers(handledResponse.usersDto);
      }
    }
  };

  const resetFilters = async () => {
    sessionStorage.removeItem("LOZA_GROUP_USER_ACCOUNTS");
    // setShowTable(false);
    setUsers([]);
    setReset(true);
    setSearchData({
      packageId: undefined,
      userName: "",
      name: "",
      lastName: "",
      company: "",
      pib: "",
    });
  };

  const extendCancelUserPackage = async (approve, extendCancelInfo) => {
    const errorsResult = validateForm(extendCancelInfo, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      var userGuids = [];
      users.forEach((x, index) => {
        if (selectedRowIds.includes(index.toString())) {
          userGuids.push(x.userGuid);
        }
      });

      if (userGuids.length > 0) {
        const data = {
          userGuids: userGuids,
          packageId: searchData.packageId,
          validTo: extendCancelInfo.validTo,
          comment: extendCancelInfo.comment,
        };
        if (extend) {
          var response = await client.extendGroupUserPackage(approve, data);
        } else {
          var response = await client.cancelGroupUserPackage(approve, data);
        }
        hideModal();
        let handledResponse = HandlingResponse(response);
        if (handledResponse.code == 408) {
          var result = serverValidation(handledResponse.data, t);
          setErrors(result);
        }
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setReset(true);
        getGroupUserPackages(searchData);
      }}
    >
      <h2>Измена групних корисничких налога</h2>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Изабери пакет</label>
          <Select
            options={allPackages}
            onChange={(e) =>
              setSearchData({ ...searchData, packageId: e.value })
            }
            value={allPackages.filter((x) => x.value === searchData.packageId)}
          />
          {errors?.packageId && (
            <div className="text-danger">{errors.packageId}</div>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>Корисничко име</label>
          <input
            type="text"
            className="form-control"
            value={searchData.userName}
            onChange={(e) =>
              setSearchData({ ...searchData, userName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>Име</label>
          <input
            type="text"
            className="form-control"
            value={searchData.name}
            onChange={(e) =>
              setSearchData({ ...searchData, name: e.target.value })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Презиме</label>
          <input
            type="text"
            className="form-control"
            value={searchData.lastName}
            onChange={(e) =>
              setSearchData({ ...searchData, lastName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>Име компаније</label>
          <input
            type="text"
            className="form-control"
            value={searchData.company}
            onChange={(e) =>
              setSearchData({ ...searchData, company: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>ПИБ</label>
          <input
            type="text"
            className="form-control"
            value={searchData.pib}
            onChange={(e) =>
              setSearchData({ ...searchData, pib: e.target.value })
            }
          />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setReset(true);
            getGroupUserPackages(searchData);
          }}
          style={{ marginRight: "15px" }}
        >
          Претрага
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={resetFilters}
        >
          Поништи претрагу
        </button>
      </div>
      {users.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <hr />
          <div className="text-danger">
            {users.length === 500 &&
              "Приказано је првих 500 резултата, сузите претрагу да бисте добили више детаља"}
          </div>
          <Table
            columns={columns}
            data={users}
            setData={setUsers}
            rememberFilter={true}
            //fetchFunction={fetchDataForPage}
            tableName={tableName}
            hiddenColumn={["id", "userGuid"]}
            serverSide={false}
            onRowSelectStateChange={setSelectedRowIds}
            resetFilters={reset}
          />
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setExtend(true);
                showModal();
              }}
            >
              Продужи
            </button>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => {
                setExtend(false);
                showModal();
              }}
            >
              Укини
            </button>
          </div>
        </div>
      )}

      <DialogModal
        show={show}
        onHide={() => hideModal()}
        title={
          extend ? "Захтев за продужавање пакета" : "Захтев за укидање пакета"
        }
      >
        <PackageExtensionRequest
          errors={setErrors}
          setErrors={setErrors}
          handleCallback={extendCancelUserPackage}
        />
      </DialogModal>
    </form>
  );
};

export default EditGroupAccounts;
