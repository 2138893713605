import React, { useState } from "react";
import UseRenderDifferentComponent from "../../hooks/UseRenderDifferentComponent";
import Packages from "../packages/Packages.component";
import Areas from "../areas/Areas.component";
import { useEffect } from "react";
import { levelEnum } from "../../contexts/RenderDifferentComponentContext";
import Acts from "../acts/Acts.component";
import CreateActs from "../create-acts/CreateActs.component";

const HierarchyComponentRender = () => {
  const { pathOfRender, onTopLevel, handleBackClick, isCreateAct } = UseRenderDifferentComponent();
  const [path, setPath] = useState([{title: "", name: ""}]);

  const [listOfAreas, setListOfAreas] = useState([]);
  const [listOfGroups, setListOfGroups] = useState([]);
  const [listOfSubgroups, setListOfSubgroups] = useState([]);
  const [listOfActs, setListOfActs] = useState([]);
  const [listOfSubacts, setListOfSubacts] = useState([]);

  const handleAddingAreasToTable = (data) => {
    setListOfAreas(data);
  }

  const handleAddingGroupsToTable = (data) => {
    setListOfGroups(data);
  }

  const handleAddingSubgroupsToTable = (data) => {
    setListOfSubgroups(data);
  }

  const handleAddingActsToTable = (data) => {
    setListOfActs(data);
  }

  const handleAddingSubactsToTable = (data) => {
    setListOfSubacts(data);
  }

  useEffect(() => {
    const path = getPath();
    setPath(path);
  }, [pathOfRender]);

  const getPath = () => {
    var path = [];
    for (var i = 0; i < pathOfRender.length; i++) {
      if (pathOfRender[i].show === true) break;
      else if(pathOfRender[i].name != '')
        path.push({ title: pathOfRender[i].title, name: pathOfRender[i].name });
    }
    return path;
  };

  return (
    <>
      {path.map((x, index) => {
        return (
          <span key={index}>
            <span style={{ fontWeight: " bold" }}>{x.title}: </span>
            <span>
              {x.name} {index !== path.length - 1 && " >> "}
            </span>
          </span>
        );
      })}
      {pathOfRender.map((element, index) => {
        if (element.level === levelEnum.Package && element.show === true)
          return (
            <Packages
              key={index}
              handleCallback={handleAddingAreasToTable}
              tableName={element.tableName}
            />
          );
        else if (element.level === levelEnum.Area && element.show === true)
          return (
            <Areas
              key={index}
              areas={listOfAreas}
              setAreas={setListOfAreas}
              handleCallback={handleAddingGroupsToTable}
              handleActsCallback={handleAddingActsToTable}
              level={levelEnum.Area}
              tableName={element.tableName}
            />
          ); // oblasti
        else if (element.level === levelEnum.Group && element.show === true)
          return (
            <Areas
              key={index}
              areas={listOfGroups}
              setAreas={setListOfGroups}
              handleCallback={handleAddingSubgroupsToTable}
              handleGroupSubgroupCallback={handleAddingGroupsToTable}
              handleActsCallback={handleAddingActsToTable}
              level={levelEnum.Group}
              tableName={element.tableName}
            />
          ); // grupe
        else if (element.level === levelEnum.Subgroup && element.show === true)
          return (
            <Areas
              key={index}
              areas={listOfSubgroups}
              setAreas={setListOfSubgroups}
              handleGroupSubgroupCallback={handleAddingSubgroupsToTable}
              handleActsCallback={handleAddingActsToTable}
              level={levelEnum.Subgroup}
              tableName={element.tableName}
            />
          ); // podgrupe
        else if (
          element.level === levelEnum.Act &&
          element.show === true &&
          !isCreateAct
        )
          return (
            <Acts
              key={index}
              acts={listOfActs}
              setActs={setListOfActs}
              handleCallback={handleAddingSubactsToTable}
              level={levelEnum.Act}
              tableName={element.tableName}
            />
          ); //akti
        else if (element.level === levelEnum.Subact && element.show === true)
          return (
            <Acts
              key={index}
              acts={listOfSubacts}
              setActs={setListOfSubacts}
              level={levelEnum.Subact}
              tableName={element.tableName}
            />
          ); //podakti
      })}
      <br />
      {isCreateAct && <CreateActs handleCallback={handleAddingActsToTable} />}
      {!onTopLevel && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleBackClick()}
        >
          {isCreateAct ? "Назад" : "Повратак на претходни ниво"}
        </button>
      )}
    </>
  );
};

export default HierarchyComponentRender;
