import React, { useState, useEffect } from "react";
import SideModal from "../../../../common/modal/SideModal.component";
import Table from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Source from "../../../components/sources/source.component";
import SourceKindClient from "../../../api-services/source-kind/source-kind-clinet";
import SourceClient from "../../../api-services/sources/source-client";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateSourceKind from "../../../components/create-source-kind/createSourceKind.component";
import { SourceKindModel } from "../../../models/SourceKind";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";

const SourceKind = () => {
  const tableName = 'MML_SOURCE_KIND';

  const {t} = useTranslation();
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
      tableName: tableName
    },
    {
      Header: t("Weight"),
      accessor: "weight",
    },
    {
      Header: t("Active"),
      accessor: "active",
      Cell: ({ row }) => {
          return row.original.active && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "three-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
          <button
          title= "Измени"
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
          <button
            title="Додај извор"
            className="btn btn-primary table-btn"
            onClick={() => onAddClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("add")} />
          </button>
          <ButtonWithComfirmation
            className="btn btn-danger table-btn"
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Тип извора ће бити трајно обрисан."}
            handleYesText={"Обриши"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  var clientSourceKind = new SourceKindClient();
  var clientSource = new SourceClient();

  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [showOnCreateOrEdit, setShowOnCreateOrEdit] = useState(false);
  const [allSourceKinds, setAllSourceKinds] = useState([]);
  const [allSource, setAllSource] = useState([]);
  const [sourceKind, setSourceKind] = useState(new SourceKindModel());
  const [title, setTitle] = useState("");

  useEffect(() => {
    getAllSourceKinds();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);
  

  const showCreateOrEditModal = () => setShowOnCreateOrEdit(true);
  const hideCreateOrEditModal = () => {setShowOnCreateOrEdit(false);
    setSourceKind(new SourceKindModel())

  }

  const getAllSourceKinds = async () => {
    var response = await clientSourceKind.getAllActiveSourceKinds();
    let res = HandlingResponse(response);
    setAllSourceKinds(res);
  };

  const onCreateClick = () => {
    setIsEdit(false);
    setSourceKind(new SourceKindModel());
    showCreateOrEditModal();
    setTitle(t("AddingSource"));
  };

  const onAddClick = async (row) => {
    let response = await clientSource.getActiveSourceBySourceKindId(row.id);
    let res = HandlingResponse(response);
    setSourceKind(row);
    setAllSource(res);
    setTitle(t("AddingSourceKind"));
    showModal();
  };

  const onEditClick = async (row) => {
    setIsEdit(true);
    setSourceKind(row);
    showCreateOrEditModal();
    setTitle(t("EditSource"));
  };

  const onDeleteClick = async (id) => {
    let response = await clientSourceKind.deleteSourceKind(id);
    let handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let allSourceKindsCopy = allSourceKinds.filter((x) => x.id !== id);
      setAllSourceKinds(allSourceKindsCopy);
    }
  };

  return (
    <>
      <div>
        <h4>Извори података</h4>
        <hr/>
        <h5>{t("SourceTypes")}</h5>
        <Table
          rememberFilter={true}
          tableName={tableName}
          columns={columns}
          data={allSourceKinds}
          hiddenColumn={["id"]}
          serverSide={false}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onCreateClick()}
        >
          {t("Add")}
        </button>
      </div>
      <SideModal show={show} handleClose={hideModal} title={title}>
        <Source
          filter={show}
          allSource={allSource}
          setAllSource={setAllSource}
          sourceKind={sourceKind}
        />
      </SideModal>
      <SideModal
        show={showOnCreateOrEdit}
        handleClose={hideCreateOrEditModal}
        title={title}
      >
        <CreateSourceKind
          row={sourceKind}
          isEdit={isEdit}
          handleCallback={() => {
            hideCreateOrEditModal();
            getAllSourceKinds();
          }}
        />
      </SideModal>
    </>
  );
};

export default SourceKind;
