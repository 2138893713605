import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
const SearchDefaultInterests = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
}) => {
  return (
    <form onSubmit={handleCallback}>
      <div className="row">
        <div className="col-4">
          <label className="col-6">Почетак опсега</label>
          <div>
            <CustomDatePicker
              selected={filterData.minDateFrom}
              onChange={(date) =>
                setFilterData({ ...filterData, minDateFrom: date })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
        </div>
        <div className="col-4">
          <label className="col-6">Крај опсега</label>
          <div>
            <CustomDatePicker
              selected={filterData.maxDateTo}
              onChange={(date) =>
                setFilterData({ ...filterData, maxDateTo: date })
              }
              showMonthDropdown
              useShortMonthInDropdown
            />
          </div>
        </div>
        <div className="text-right col-4" style={{marginTop: "28px"}}>
          <button
            className="btn btn-primary"
            type="submit"
          >
            Претрага
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              resetFilters();
            }}
          >
            Поништи претрагу
          </button>
        </div>
        </div>
    </form>
  );
};

export default SearchDefaultInterests;
