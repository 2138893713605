
import Modal from "react-bootstrap/Modal";
import "./Modal.style.css";

const DialogModal = (props) => {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      styles={{ zIndex: 9999 }}
      className={props.wide ? "wide-modal" : ""}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default DialogModal;
