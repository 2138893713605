import React, { useEffect } from "react";
import { useState } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import ActReferenceTypeClient from "../../../ADMIN-PANEL/api-services/act-reference-type/act-reference-type";

const CreateEUGlobalActReference = ( { handleCallback} ) => {
  const actReferenceTypeClient = new ActReferenceTypeClient();

  const [externalUrlName, setExternalUrlName] = useState("");
  const [externalUrlValue, setExternalUrlValue] = useState("");
  const [actReferenceType, setActReferenceType] = useState({})

  useEffect(() => {
    getActReferencesTypes()
  }, []);

  const getActReferencesTypes = async () => {
    var response = await actReferenceTypeClient.getActiveActReferenceTypes(
      true
    );
    var handledResponse = HandlingResponse(response);

    let types = handledResponse
      .filter(
        (item) => item.description === "Директива ЕУ"
      )
      .map((item) => {
        return {
          value: item.guid,
          label: item.description,
          name: "typeGuid",
        };
      });
      setActReferenceType({actReferenceTypeGuid: types[0].value, actReferenceTypeName: types[0].label})
  };

  const addEUGlobalActReference = () => {
    var result = [];
    result.push(actReferenceType);
    result[0].externalUrlName = externalUrlName;
    result[0].externalUrlValue = externalUrlValue;
    handleCallback(result);
  };

  return (
    <div>
      <label>Назив референце</label>
      <input
        className="form-control"
        type="text"
        value={externalUrlName}
        onChange={(e) => setExternalUrlName(e.target.value)}
      />
      <label>УРЛ референце</label>
      <input
        className="form-control"
        type="text"
        value={externalUrlValue}
        onChange={(e) => setExternalUrlValue(e.target.value)}
      />
      <br />
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={() => addEUGlobalActReference()}
      >
        Додај
      </button>
    </div>
  );
};

export default CreateEUGlobalActReference;
