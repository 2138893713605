import { createContext, useState, useEffect, useLayoutEffect } from "react"

const StateOfRegulationsTreeContext = createContext();

//Ovaj context se koristi da se zapamte stanja stabala nakon sto se stranica osvezi ili se ode na pregled akta, pa vrati nazad

/**
*@param areaId - id oblasti u na koju je kliknuto u levom stablu
*@param arrayOfAreaIds - niz oblasti koje su expand-ovane u levom stablu
*@param actId - id acta na osnovu kojeg se prikazuju podaci u desnom stablu
*@param rightAreaId - id oblasti u na koju je kliknuto u desnom stablu
*@param rightActId - id akta na osnovu kojeg se prikazuju verzije
**/
export const StateOfRegulationsTreeProvider = ({ children }) => {
    const [areaId, setAreaId] = useState();
    const [arrayOfAreaIds, setArrayOfAreaIds] = useState([]);

    const [actId, setActId] = useState();
    const [rightActId, setRightActId] = useState();

    const [rightAreaId, setRightAreaId] = useState();

    useLayoutEffect(() => {
        let areaIds = JSON.parse(sessionStorage.getItem('regulationsAreaIds'));
        areaIds && setArrayOfAreaIds([...areaIds]);
        if (areaId && areaIds && areaIds?.find(el => el == areaId) == null)
            setArrayOfAreaIds([...areaIds, parseInt(areaId)]);

        let act = sessionStorage.getItem('regulationsActId');
        setActId(act);

        let rightAct = sessionStorage.getItem('regulationsRightActId');
        setRightActId(rightAct);
    }, []);

    //na klik neke oblasti u levom stablu se postavlja areaId koji se ubacuje u niz arrayOfAreaIds ukoliko se vec ne nalazi u njemu
    useEffect(() => {
        if (areaId) {
            let areaIds = JSON.parse(sessionStorage.getItem('regulationsAreaIds'));
            if (areaIds && areaIds?.find(el => el == areaId) == null)
                var array = [...areaIds, areaId]
            else var array = [areaId];
            setArrayOfAreaIds(array);
            sessionStorage.setItem('regulationsAreaIds', JSON.stringify(array));
            setAreaId();
        }
    }, [areaId]);

    const changeArrayOfAreaIds = (array) => {
        var arrayOfIds = [];
        array.forEach((element, index) => {
            if(index < array.length - 1)
                arrayOfIds.push(element.id);
        });
        setArrayOfAreaIds(arrayOfIds);
        sessionStorage.setItem('regulationsAreaIds', JSON.stringify(arrayOfIds));
        setActId(array[array.length-1].id);
        sessionStorage.setItem('regulationsActId', array[array.length-1].id);
    }

    const changeActId = (actId) => {
        setActId(actId);
        sessionStorage.setItem('regulationsActId', actId);

        sessionStorage.removeItem('regulationsRightActId');
        setRightActId();
    }

    const changeRightActId = (actId) => {
        if (actId) {
            setRightActId(actId);
            sessionStorage.setItem('regulationsRightActId', actId);
        }
        else {
            sessionStorage.removeItem('regulationsRightActId');
            setRightActId();
        }
    }

    //uklanjanje iz niza arrayOfAreaIds
    //ukoliko se id nalazi u nekom nizu, potrebno je ukloniti ga ukoliko je ponovo kliknuto na njega
    const removeFromArray = (value) => {
            const arrayCopy = [...arrayOfAreaIds];
            const index = arrayCopy.indexOf(value);
            arrayCopy.splice(index, 1);
            setArrayOfAreaIds(arrayCopy);
            if (arrayCopy.length > 0) {
                sessionStorage.setItem('regulationsAreaIds', JSON.stringify(arrayCopy))
            }
            else {
                sessionStorage.removeItem("regulationsAreaIds");
                setArrayOfAreaIds([]);
            }

            sessionStorage.removeItem("regulationsActId");
            setActId();
            sessionStorage.removeItem("regulationsRightActId");
            setRightActId();
    }

    const removeSelectedAct = () => {
        sessionStorage.removeItem("regulationsRightActId");
        setRightActId();
    }
    return (
        <StateOfRegulationsTreeContext.Provider value={{
            areaId, setAreaId, arrayOfAreaIds, setArrayOfAreaIds, actId, setActId, rightAreaId,
            setRightAreaId, setArrayOfAreaIds, removeFromArray, removeSelectedAct, rightActId, setRightActId,
            changeActId, changeRightActId, changeArrayOfAreaIds
        }}>
            {children}
        </StateOfRegulationsTreeContext.Provider>
    )
}

export default StateOfRegulationsTreeContext;