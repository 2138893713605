import Joi from 'joi-browser';

class IndexSmallAdsFurunaModel {
    constructor(num = 0, year = new Date(), size = 0, take = 1000) {
        this.num = num;
        this.year = year;
        this.size = size;
        this.take = take;
    }
}

class IndexSmallAdsModel {
    constructor(indexNewSmallAdds = true, numFromInt = 0, numToInt = 0, yearFromInt = new Date(), yearToInt = new Date(), rObjectId = "") {
        this.indexNewSmallAdds = indexNewSmallAdds;
        this.numFromInt = numFromInt;
        this.numToInt = numToInt;
        this.yearFromInt = yearFromInt;
        this.yearToInt = yearToInt;
        this.rObjectId = rObjectId;
    }
}

export const indexSmallAdsFurunaSchema = {
    numFromInt: Joi.number().min(0).min(0).max(999).required(),
    numToInt: Joi.number().min(0).min(0).max(999).required(),
    yearFromInt: Joi.date().required(),
    yearToInt: Joi.date().required(),
};

export const indexSmallAdsSchema = {
    num: Joi.number().min(0).min(0).max(999).required(),
    year: Joi.date().required(),
};

export { IndexSmallAdsFurunaModel, IndexSmallAdsModel };