import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import DataClient from "../../../MML/api-services/data/data-client";
import CourtPracticeClient from "../../api-services/cases/cases-client";
import LawActClient from "../../../REGISTRIES/api-services/law-act/law-act-client";
import LawAdActClient from "../../../SLGL/api-services/law-ad-act/law-ad-act-client";
import IssueTypeClient from "../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import Collapse from "../../../common/collapse/Collapse.component";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import ActClient from "../../../PPP/api-services/acts/act-client";

const GlobalActReferences = ({
  globalActReferencesData,
  setGlobalActReferencesData
}) => {
  const { t } = useTranslation();

  const columnsOfSearched = [
    {
      Header: "ActGuid",
      accessor: "actGuid",
    },
    {
      Header: "Назив акта",
      accessor: "actName",
    },
    {
      Header: "Додатне информације",
      accessor: "additionalInfo",
      Cell: ({ row }) => {
        let date = new Date(row.original.additionalInfo);
        return row.original.additionalInfo ? (row.original.additionalInfo?.includes('0001') ? "" : (!isNaN(date) ? (
            <div>{format(date, "dd.MM.yyyy.")}</div>
          ) : row.original.additionalInfo)) : ""
      },
    },
  ];

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Guid",
      accessor: "actGuid",
    },
    {
      Header: "База",
      accessor: "databaseTypeName",
      Cell: ({ row }) => {
        return databaseList
          .filter((x) => {
            return row.original.databaseType === x.enumVal;
          })
          .map((x) => {
            return x.label;
          })[0];
      },
    },
    {
      Header: "Назив",
      accessor: "actName",
    },
    {
      Header: "Додатне информације",
      accessor: "additionalInfo",
      Cell: ({ row }) => {
        let date = new Date(row.original.additionalInfo);
        return row.original.additionalInfo ? (row.original.additionalInfo?.includes('0001') ? "" : (!isNaN(date) ? (
            <div>{format(date, "dd.MM.yyyy.")}</div>
          ) : row.original.additionalInfo)) : ""
      },
    },
    {
      accessor: "delete",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-danger table-btn"
            onClick={() => removeElement(row.index)}
          >
            <FontAwesomeIcon icon={solid("times")} />
          </button>
        );
      },
    },
  ];

  const databaseList = [
    {
      value: "regClient",
      label: "Регистри",
      enumVal: 0,
    },
    {
      value: "spClient",
      label: "Судска пракса",
      enumVal: 1,
    },
    {
      value: "slglClient",
      label: "Службена гласила",
      enumVal: 2,
    },
    {
      value: "mmlClient",
      label: "Мишљења, модели, литература",
      enumVal: 3,
    },
    {
      value: "pppClient",
      label: "Посебни програмски пакети",
      enumVal: 4
    },
  ];

  const issueTypeClient = new IssueTypeClient();

  const [visible, setVisible] = useState(false);
  const [databaseAction, setDatabaseAction] = useState({
    name: "",
    client: null,
  });

  const [actName, setActName] = useState("");
  const [listOfIssueTypes, setListOfIssueTypes] = useState([]);
  const [gazetteIssueTypeName, setGazetteIssueTypeName] = useState("");
  const [gazetteNumber, setGazetteNumber] = useState(null);
  const [gazetteYear, setGazetteYear] = useState(null);

  const [listOfGlobalActReferences, setListOfGlobalActReferences] = useState(
    []
  );
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getIssueTypeNames();
  }, []);

  const getIssueTypeNames = async () => {
    var response = await issueTypeClient.getIssueType(true);
    var handledResponse = HandlingResponse(response);
    let types = handledResponse.map((item) => {
      return {
        value: item.guid,
        label: item.shortDescription,
      };
    });
    setListOfIssueTypes(types);
  };

  const onChangeBase = (databaseName) => {
    if (databaseName === "mmlClient")
      setDatabaseAction({ name: databaseName, client: new DataClient() });
    else if (databaseName === "spClient")
      setDatabaseAction({
        name: databaseName,
        client: new CourtPracticeClient(),
      });
    else if (databaseName === "regClient")
      setDatabaseAction({ name: databaseName, client: new LawActClient() });
    else if (databaseName === "slglClient")
      setDatabaseAction({ name: databaseName, client: new LawAdActClient() });
      else if (databaseName === "pppClient")
      setDatabaseAction({ name: databaseName, client: new ActClient() });
    setErrors({...errors, selectDatabase: ""})
  };

  const onSearchForGlobalActReference = async () => {
    let data = {
      actName: actName,
      gazetteIssueTypeName: gazetteIssueTypeName,
      gazetteNumber: gazetteNumber,
      gazetteYear: gazetteYear,
    };
    if(!databaseAction.name) {
      setErrors({...errors, selectDatabase: "Морате селектовати базу у којој желите да претражите."})
    }
    else {
    var response;
    if (databaseAction.name === "mmlClient") {
      response = await databaseAction.client.getDataByName(data);
    } else if (databaseAction.name === "spClient") {
      response = await databaseAction.client.getCourtPracticeByName(data);
    } else if (databaseAction.name === "regClient") {
      response = await databaseAction.client.getLawActByName(data.actName);
    } else if (databaseAction.name === "slglClient") {
      response = await databaseAction.client.getLawAdActByInfos(data);
    } else if (databaseAction.name === "pppClient")
     response = await databaseAction.client.getActByName(data);

    var handledResponse = HandlingResponse(response);
    if(handledResponse && handledResponse.code !== 408) {
      if(handledResponse.length <= 0)
        setErrors({...errors, emptyList: "Није пронађен ниједан акт за тражени упит."});
      else if(handledResponse.length < 49)
        setErrors({...errors, emptyList: ""});
      else setErrors({...errors, emptyList: "Приказано је првих 50 резултата претраге, уколико желите више унесите прецизнији упит."});
      handledResponse && setListOfGlobalActReferences(handledResponse);
    }
  }
  };

  const addGlobalActReference = () => {
    var result = [];
    listOfGlobalActReferences.forEach((x, index) => {
      if (selectedRowIds.includes(index.toString())) {
        result.push(x);
      }
    });
    let base = databaseList
      .filter((x) => {
        return databaseAction.name === x.value;
      })
      .map((x) => {
        return { databaseName: x.label, databaseType: x.enumVal };
      })[0];

    result.forEach((x) => {
      x.databaseName = base.databaseName;
      x.databaseType = base.databaseType;
      if(x.additionalInfo) {
        let date = new Date(x.additionalInfo);
        if (!isNaN(date)) x.additionalInfo = format(date, "dd.MM.yyyy.");
      }
      
    });
    setGlobalActReferencesData([...globalActReferencesData, ...result]);

    setVisible(false);
    resetValues();
    setDatabaseAction({ name: "", client: null });
  };

  const removeElement = (id) => {
    const arrayCopy = [...globalActReferencesData];
    arrayCopy.splice(id, 1);
    setGlobalActReferencesData(arrayCopy);
  };

  const cancelAddingToTable = () => {
    setVisible(false);
    setDatabaseAction({ name: "", client: null });
    resetValues();
  };

  const resetValues = () => {
    setActName("");
    setGazetteIssueTypeName("");
    setGazetteNumber(null);
    setGazetteYear(null);
    setSelectedRowIds([]);
    setListOfGlobalActReferences([]);
    setErrors({})
  };

  return (
    <>
      <Collapse
        open={visible}
        setOpen={setVisible}
        title="Додавање повезаних аката"
      >
        <div className="row">
          <div className="col-6">
            <label>База</label>
            <Select
              options={databaseList}
              onChange={(event) => {
                onChangeBase(event.value);
              }}
            />
          </div>
          {databaseAction.name === "slglClient" ? (
            <>
              <div className="col-6">
                <label>Назив</label>
                <input
                  className="form-control"
                  type="text"
                  value={actName}
                  onChange={(e) => setActName(e.target.value)}
                />
              </div>
                <div className="col-6">
                  <label>Тип</label>
                  <Select
                    options={listOfIssueTypes}
                    onChange={(e) => setGazetteIssueTypeName(e.value)}
                  />
                </div>
                <div className="col-3">
                  <label>Број</label>
                  <input
                    className="form-control"
                    type="number"
                    value={gazetteNumber}
                    onChange={(e) => setGazetteNumber(e.target.value || null)}
                  />
                </div>
                <div className="col-3">
                  <label>Година</label>
                  <ReactDatePicker
                    className="form-control"
                    name="gazetteYear"
                    selected={
                      gazetteYear ? new Date(gazetteYear.toString()) : null
                    }
                    onChange={(date) => {
                      setGazetteYear(date?.getFullYear());
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    isClearable
                  />
                </div>
            </>
          ) : (
            <>
              <div className="col-6">
                <label>Упит</label>
                <input
                  className="form-control"
                  type="text"
                  value={actName}
                  onChange={(e) => setActName(e.target.value)}
                />
              </div>
            </>
          )}
           {errors?.selectDatabase && (
          <div className="text-danger">{errors.selectDatabase}</div>
        )}
          <div>
            <br />
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSearchForGlobalActReference()}
            >
              Тражи
            </button>
            <button
              type="button"
              style={{ marginLeft: "10px" }}
              className="btn btn-primary"
              onClick={cancelAddingToTable}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
        <br />
        {errors?.emptyList && (
          <>
          <div className="text-danger">{errors.emptyList}</div>
          <br />
          </>
          )}
        {listOfGlobalActReferences.length > 0 ? (
          <>
            <Table
              columns={columnsOfSearched}
              data={listOfGlobalActReferences}
              rememberFilter={false}
              hiddenColumn={["actGuid"]}
              serverSide={false}
              onRowSelectStateChange={setSelectedRowIds}
            />
            <br />
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => addGlobalActReference()}
            >
              Додај
            </button>
          </>
        ) : (
          <></>
        )}
      </Collapse>
      {globalActReferencesData.length > 0 ? (
        <>
          <h5>Повезани акти</h5>
          <Table
            columns={columns}
            data={globalActReferencesData}
            setData={setGlobalActReferencesData}
            rememberFilter={false}
            hiddenColumn={["id", "actGuid"]}
            serverSide={false}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default GlobalActReferences;
