import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import SourceClient from "../../api-services/sources/source-client";
import { SourceModel, SourceSchema } from "../../models/Source";

const CreateSource = ({ row, handleCallback, isEdit }) => {
  const { t } = useTranslation();

  const [source, setSource] = useState(new SourceModel());
  const [errors, setErrors] = useState({});

  var client = new SourceClient();

  useEffect(() => {
    setSource(row);
    setErrors({});
  }, [row]);

  const createSource = async (e) => {
    e.preventDefault();
    const result = validateForm(source, SourceSchema, t);
    if (isEdit) var response = await client.updateSource(source);
    else var response = await client.createSource(source);
    validateAction(response);
    setErrors(result);
    setSource(new SourceModel());
  };

  const validateAction = (response) => {
    let handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const cancelEdit = () => {
    setSource(new SourceModel());
    handleCallback(null);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, source, errors, SourceSchema, t);
    setSource(result.data);
    setErrors(result.errors);
  };

  return (
    <form onSubmit={createSource}>
      <div className="form-group row">
        <div>
          <label>{t("Name")}<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={source?.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div>
          <label>{t("Weight")}<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="weight"
            value={source?.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <br />
      </div>
      <input
        id="activeSourceCB"
        type="checkbox"
        value={source.active}
        checked={source.active}
        onChange={(event) => {
          setSource({ ...source, active: event.target.checked });
        }}
      />
      <label htmlFor="activeSourceCB">{t("Active")}</label>
      <button
        className="btn btn-primary pull-right"
        onClick={createSource}
        style={{ marginLeft: "5px" }}
      >
        {t("Save")}
      </button>
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={cancelEdit}
      >
        {t("Cancel")}
      </button>
    </form>
  );
};

export default CreateSource;
