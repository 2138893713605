import { useEffect, useState, useCallback } from "react";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import PackageClient from "../../api-services/create-packages/create-packages-client";
import { handleSave,
  serverValidation,
  validateForm, } from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";

const AddProductAuthority = ({ productId, handleCallback }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [authorityGuid, setAuthorityGuid] = useState('');
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});

  const schema = {
    comments: Joi.string().required(),
  };

  const columns = [
    {
      Header: "Идентификатор",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "authorityName",
    },
    {
      Header: "Екстерна референца",
      accessor: "extCode",
    },
    {
      Header: "Сортирање",
      accessor: "sort",
    },
    {
      Header: "Актуелан",
      accessor: "isCurrent",
    },
  ];
  const client = new PackageClient();

  const getAuthority = async () => {
    let response = await client.GetAuthority();
    let res = HandlingResponse(response);
    var result = res.map((item) => {
      return { ...item, isCurrent: item.isCurrent ? "Да" : "Не" };
    });
    setData(result);
  };
  useEffect(() => {
    getAuthority();
    setErrors([]);
  }, []);

  const handleTableRowClick = useCallback((response) => {
    setAuthorityGuid(response.guid);
  });


  const addAuthority = async () => {
    var obj = {
      productId: productId,
      authorityGuid: authorityGuid,
      comments: comments
    }
    const errorResult = validateForm(obj, schema, t);
    setErrors(errorResult);

    if (!errorResult){ 
        var response = await client.AddProductAuthority(obj);
        let handledResponse = HandlingResponse(response);
        if (handledResponse.code == 408) {
        var result = serverValidation(handledResponse.data, t);
        setErrors(result);
      }
      else
        handleCallback(handledResponse);
    }
  }

  const handlePropertyChange = (event) => {
    const obj =  { name: event.name, value: event.value };
    const result = handleSave(obj, comments, errors, schema, t);
    setComments(result.data.comments);
    setErrors(result.errors);
  };

  return (
    <>
      <Table
        columns={columns}
        data={data}
        rememberFilter={false}
        handleClickOnRow={handleTableRowClick}
      />
      <div className="form-group">
        <label>Коментар<span className="required-asterisk">*</span></label>
        <textarea
          className="form-control"
          type="text"
          value={comments}
          name="comments"
          onChange={(e) => handlePropertyChange(e.target)}
        />
      </div>
      {errors?.comments && (
        <div className="text-danger">{errors.comments}</div>
      )}
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => addAuthority()}
      >
        Додај
      </button>
    </>
  );
};

export default AddProductAuthority;
