import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { StyleDataPicker } from "../index-small-adds/IndexSmallAdds.style";
import {
  IndexSmallAdsFurunaModel,
  indexSmallAdsSchema,
} from "../../../models/SmallAds";
import IndexSmallAds from "../../../api-services/index-small-adds/index-small-ads";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import { useTranslation } from "react-i18next";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../../common/validation/validation";

const IndexSmallAddsFuruna = () => {
  const [inputData, setInputData] = useState(new IndexSmallAdsFurunaModel());
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const client = new IndexSmallAds();

  const indexingAdsFromFuruna = async () => {
    const obj = {
      ...inputData,
      num: parseInt(inputData.num),
      year: inputData.year.getFullYear(),
    };
    const errorsResult = validateForm(obj, indexSmallAdsSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const response = await client.adsIndexingFuruna(obj);
      if (response !== "Error") {
        var handledResponse = HandlingResponse(response);
        if (handledResponse?.code == 408) {
          var result = serverValidation(handledResponse?.data, t);
          setErrors(result);
        }
      }
    }
  };

  const indexingNewAdsFromFuruna = async () => {
    const obj = {
      ...inputData,
      num: parseInt(inputData.num),
      year: inputData.year.getFullYear(),
    };
    const errorsResult = validateForm(obj, indexSmallAdsSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      const response = await client.adsIndexingNewFuruna(obj);
      if (response !== "Error") {
        var handledResponse = HandlingResponse(response);
        if (handledResponse?.code == 408) {
          var result = serverValidation(handledResponse?.data, t);
          setErrors(result);
        }
      }
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, inputData, errors, indexSmallAdsSchema, t);
    setInputData(result.data);
    setErrors(result.errors);
  };

  return (
    <>
        <div className="row">
          <div>
            <label>Гласила (број/година)</label>
            <div className="input-group">
              <div>
                <input
                  className="form-control"
                  type="number"
                  name="num"
                  value={inputData.num}
                  onChange={(event) => handlePropertyChange(event.target)}
                  min={0}
                />
                {errors?.num && <div className="text-danger">{errors.num}</div>}
              </div>
              <span style={{ margin: ".5rem" }}>/</span>
              <div>
                <StyleDataPicker>
                  <ReactDatePicker
                    className="form-control"
                    name="year"
                    selected={inputData.year}
                    onChange={(date) => handlePropertyChange(date, "year")}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    minDate={new Date("1900-01-01")}
                    maxDate={new Date("2100-12-31")}
                  />
                </StyleDataPicker>
                {errors?.year && (
                  <div className="text-danger">{errors.year}</div>
                )}
                
              </div>
              <div>
                    <button
                    style={{marginLeft: "20px"}}
                    type="button"
                    className="btn btn-primary"
                    onClick={indexingAdsFromFuruna}
                    >
                    Индексирај
                    </button>
                </div>

                <div>
                    <button
                    style={{marginLeft: "20px"}}
                    type="button"
                    className="btn btn-primary"
                    onClick={indexingNewAdsFromFuruna}
                    >
                    Индексирај новоунете мале огласе
                    </button>
                </div>
            </div>
          </div>

          
        </div>

        
    </>
  );
};

export default IndexSmallAddsFuruna;
