import React, { useEffect, useState } from "react";
import Collapse from "../../../common/collapse/Collapse.component";
import OrderClient from "../../api-services/order/order-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import Moment from "moment";
import { OrderEnum } from "../../models/OdrerModel";
import { Link } from "react-router-dom";

const NewOrders = ({ orderStatus }) => {
  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Назив",
      accessor: "name",
    },
    {
      Header: "Количина",
      accessor: "quantity",
    },
    {
      Header: "Цена",
      accessor: "price",
    },
  ];
  const [orders, setOrders] = useState([]);
  const [messageIfOrderNotExist, setMessageIfOrderNotExist] = useState("");
  const orderClient = new OrderClient();

  useEffect(() => {
    getOrders();
  }, [orderStatus]);

  const getOrders = async () => {
    var response = await orderClient.getOrders(orderStatus);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      var helpArray = handledResponse.map((x) => {
        return { ...x, collapse: true };
      });
      setOrders(helpArray);
      if (handledResponse.length === 0) {
        orderStatus === OrderEnum.New
          ? setMessageIfOrderNotExist("Не постоји ниједна нова наруџбина")
          : orderStatus === OrderEnum.Pending
          ? setMessageIfOrderNotExist("Не постоји ниједна наруџбина на чекању")
          : setMessageIfOrderNotExist("Не постоји ниједна одбијена наруџбина");
      } else setMessageIfOrderNotExist("");
    }
  };

  const onAcceptOrder = async (orderId) => {
    var response = await orderClient.acceptOrder(orderId);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      getOrders();
    }
  };

  const onCancelOrder = async (orderId) => {
    var response = await orderClient.cancelOrder(orderId);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      getOrders();
    }
  };

  const onPendingOrder = async (orderId) => {
    var response = await orderClient.pendingOrder(orderId);
    let handledResponse = HandlingResponse(response);
    if (handledResponse != "Error") {
      getOrders();
    }
  };

  return (
    <div>
      {orders.length > 0 ? (
        <>
          {orders.map((order, index) => (
            <Collapse
              key={index}
              open={order.collapse}
              setOpen={(value) =>
                setOrders((prevData) =>
                  prevData.map((x, i) =>
                    index === i ? { ...x, collapse: value } : x
                  )
                )
              }
              title={
                <Link
                  to={`/peng/users?userId=${order.userId}&userName=${order.firstLastName}`}
                >
                  <span>{order.firstLastName}</span>
                </Link>
              }
            >
              <div>
                {order.orderDate && (
                  <>
                    <label>Датум креирања поруџбине: </label>{" "}
                    {Moment(order.orderDate).format("DD.MM.YYYY.")}
                  </>
                )}
                {orderStatus !== OrderEnum.Approved && (
                  <div className="pull-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => onAcceptOrder(order.id)}
                      style={{ marginLeft: "5px" }}
                      type="button"
                    >
                      Одобри
                    </button>
                    {orderStatus !== OrderEnum.Pending && (
                      <button
                        className="btn btn-primary"
                        onClick={() => onPendingOrder(order.id)}
                        style={{ marginLeft: "5px" }}
                        type="button"
                      >
                        На чекању
                      </button>
                    )}
                    {orderStatus !== OrderEnum.Cancelled && (
                      <button
                        className="btn btn-primary"
                        style={{ marginLeft: "5px" }}
                        onClick={() => onCancelOrder(order.id)}
                        type="button"
                      >
                        Одбиј
                      </button>
                    )}
                  </div>
                )}
              </div>
              <br />
              <Table
                columns={columns}
                data={order.products}
                rememberFilter={false}
                hiddenColumn={["id"]}
                serverSide={false}
              />
              <div className="pull-right">
                <label>Ukupno: </label>
                {order.totalPrice}
              </div>
            </Collapse>
          ))}
        </>
      ) : (
        <>
          <label>{messageIfOrderNotExist}</label>
        </>
      )}
    </div>
  );
};

export default NewOrders;
