import React, { useState, useEffect } from 'react'
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import { TreeContainer } from '../tree/Tree.style';
import Tree from '../tree/Tree.component';
import useMoveRegulationsLawActs from '../../hooks/useMoveRegulationsLawActs';
import useStateOfRegulationsTree from '../../hooks/useStateOfRegulationsTree';
import AreaClient from '../../api-services/area/area-client';
import LawActClient from '../../api-services/law-act/law-act-client';
import { hierarchyMarkPositionEnum } from '../../models/AddLawAct';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DialogModal from '../../../common/modal/Modal.component';
import { NewFileBtn } from '../../../common/multiple-files/multipleFiles.style';
import { nextHierarchyMark, valueOfSelectedHierarchyMark } from '../../../REGISTRIES/common/CommonFunctions';

const MovingSelectedRegulationsActs = ({ hide, handleCallback, handleGetLawActVersions }) => {
    const [treeData, setTreeData] = useState([]);
    const [rightTreeData, setRightTreeData] = useState([]);
  
    const [show, setShow] = useState(false);
    const [isSubarea, setIsSubarea] = useState(false);
  
    const [clicked, setClicked] = useState();
    const [nested, setNested] = useState();
    const [next, setNext] = useState();
  
    const {selected, moveLawActs, setMoveLawActs, setEnableMoving, onCancelMoving } = useMoveRegulationsLawActs();
    const { actId, changeArrayOfAreaIds, changeRightActId } = useStateOfRegulationsTree();
  
    const [infoAboutClicked, setInfoAboutClicked] = useState({
      id: null,
      areaId: null,
      hierarchyMark: "1",
      parentId: null,
      isSubarea: false
    });
  
    const areaClient = new AreaClient();
    const lawActClient = new LawActClient();
  
    useEffect(() => {
      getTree();
    }, []);
  
    useEffect(() => {
      setClicked(infoAboutClicked.hierarchyMark);
      setNested(infoAboutClicked.hierarchyMark + ".1");
      setNext(nextHierarchyMark(infoAboutClicked));
  
      var selected = valueOfSelectedHierarchyMark(moveLawActs.shiftLawAct.hierarchyMarkPosition, infoAboutClicked);
      setMoveLawActs({
        ...moveLawActs,
        shiftLawAct: {
          areaId: infoAboutClicked.areaId,
          hierarchyMarkPosition: moveLawActs.shiftLawAct.hierarchyMarkPosition,
          createSubarea: false,
          parentId: infoAboutClicked.parentId,
          selectedHierarchyMark: selected,
        },
      });
      if(infoAboutClicked.isSubarea === undefined) setIsSubarea(true);
      else setIsSubarea(infoAboutClicked.isSubarea);
    }, [infoAboutClicked, moveLawActs.shiftLawAct.hierarchyMarkPosition]);
  
    const ShowDialog = () => {
      setShow(true);
    };
    const HideDialog = () => {
      setShow(false);
    };
  
    const getTree = async () => {
      let response = await areaClient.getTree();
      let res = HandlingResponse(response);
      setTreeData(res);
    };
  
    const filterByAreaId = async (id) => {
      let response = await lawActClient.filterByAreaId(id);
      let res = HandlingResponse(response);
      setRightTreeData(res);
    };
  
    const selectForMoving = (data) => {
      setInfoAboutClicked({
        id: data.id,
        areaId: data.areaId || data.id,
        hierarchyMark: data.hierarchyMark || "1",
        parentId: data.areaId ? data.id : null,
        isSubarea: data.isSubarea,
        hasChildren: data.children?.length > 0 ? true : false
      })
    };
  
    const onNextClick = () => {
      ShowDialog();
    };
  
    const onMoveLawActs = async () => {
      let response = await lawActClient.moveLawActs(moveLawActs);
      let res = HandlingResponse(response);
      if(res == 'Success') {
        handleCallback(infoAboutClicked.areaId);
        onCancelMoving();
        setEnableMoving(false);
        hide();
  
        //U ovom delu se vrsi selektovanje i prikazivanje oblasti i akta u koji je premesten izabrani akt
        response = await areaClient.getAreaParents(infoAboutClicked.areaId);
        var handledResponse = HandlingResponse(response);
        changeArrayOfAreaIds(handledResponse);
        changeRightActId(moveLawActs.lawActsIds[0]);
        handleGetLawActVersions(moveLawActs.lawActsIds[0]);
      }
      HideDialog();
    };
    return (
      <>
        <div>
          <div className="text-right">
            <NewFileBtn
              onClick={onNextClick}
              type="button"
              disabled={selected ? "" : "disabled"}
            >
              Одабери позицију за премештање{" "}
              <FontAwesomeIcon icon={solid("arrow-right")} />
            </NewFileBtn>
          </div>
          <br />
          <TreeContainer>
            <div className="row">
              <div className="col-md-4">
                <h5>Области</h5>
                <hr />
                <div className="left-tree">
                  <Tree
                    treeData={treeData}
                    getNode={filterByAreaId}
                    isRight={false}
                    expanded={false}
                    isMoving={true}
                    selectForMoving={selectForMoving}
                  />
                </div>
              </div>
              <div className="col-md-8">
                {rightTreeData.length > 0 && (
                  <>
                    <h5>Прописи</h5>
                    <hr />
                    <div className="right-tree">
                      <Tree
                        treeData={rightTreeData}
                        expanded={false}
                        isRight={true}
                        isMoving={true}
                        selectForMoving={selectForMoving}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </TreeContainer>
        </div>
  
        <DialogModal
          show={show}
          onHide={HideDialog}
          title={"Одаберите позицију за премештање"}
        >
          <div>
            <label>Позиција акта</label>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={hierarchyMarkPositionEnum.Clicked}
                  checked={
                    moveLawActs.shiftLawAct.hierarchyMarkPosition ==
                    hierarchyMarkPositionEnum.Clicked
                  }
                  onChange={(e) =>
                    setMoveLawActs({
                      ...moveLawActs,
                      shiftLawAct: {
                        ...moveLawActs.shiftLawAct,
                        hierarchyMarkPosition: e.target.value,
                      },
                    })
                  }
                  name="hierarchyMarkPosition"
                />
                {" " + clicked}
              </label>
            </div>
            <div className="radio radio-margin">
              <label>
                <input
                  disabled={
                    isSubarea && true
                  }
                  type="radio"
                  value={hierarchyMarkPositionEnum.Nested}
                  checked={
                    moveLawActs.shiftLawAct.hierarchyMarkPosition ==
                    hierarchyMarkPositionEnum.Nested
                  }
                  onChange={(e) =>
                    setMoveLawActs({
                      ...moveLawActs,
                      shiftLawAct: {
                        ...moveLawActs.shiftLawAct,
                        hierarchyMarkPosition: e.target.value,
                      },
                    })
                  }
                  name="hierarchyMarkPosition"
                />
                {" " + nested}
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  disabled={
                    isSubarea && !infoAboutClicked.hasChildren
                  }
                  type="radio"
                  value={hierarchyMarkPositionEnum.Next}
                  checked={
                    moveLawActs.shiftLawAct.hierarchyMarkPosition ==
                    hierarchyMarkPositionEnum.Next
                  }
                  onChange={(e) =>
                    setMoveLawActs({
                      ...moveLawActs,
                      shiftLawAct: {
                        ...moveLawActs.shiftLawAct,
                        hierarchyMarkPosition: e.target.value,
                      },
                    })
                  }
                  name="hierarchyMarkPosition"
                />
                {" " + next}
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary pull-right"
                style={{ marginLeft: "5px" }}
                onClick={() => onMoveLawActs()}
              >
                Премести
              </button>
            </div>
          </div>
        </DialogModal>
      </>
    );
  };
  

export default MovingSelectedRegulationsActs