import React from 'react';

const StatusEnum = {
  1: "Није индексиран", // NotIndexed
  2: "Индексира се", // Indexing
  3: "Индексиран", // Indexed 
  4: "Индексирање није успело", // IndexingFailed 
  5: "Ажуриран", // Updated
  6: "Ажурирање није успело", // UpdateFailed
  7: "Избрисан из индекса", // DeletedFromIndex
  8: "Брисање из индекса није успело", // DeletionFromIndexFailed
};

export const StatusForIndexing = [1, 3, 4, 5, 6, 7];
export const StatusForDeleteIndex = [3, 5, 6, 8];

const IndexingStatus = ({ value }) => {
  return <span>{StatusEnum[value]}</span>;
};

export default IndexingStatus;