import React, { useState, useEffect, useCallback } from "react";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import IssueTypeClient from "../../../ADMIN-PANEL/api-services/issue-type/issue-type-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import Table from "../../../common/table/Table.component";
import LawAdActClient from "../../../SLGL/api-services/law-ad-act/law-ad-act-client";
import LawActClient from "../../api-services/law-act/law-act-client";
import NumberClient from "../../../SLGL/api-services/number/number";
import {
  AddLawAdActModel,
  hierarchyMarkPositionEnum,
} from "../../models/AddLawAct";
import { valueOfSelectedHierarchyMark } from "../../common/CommonFunctions";
import { nextHierarchyMark } from "../../common/CommonFunctions";
import { useTranslation } from "react-i18next";
import Collapse from "../../../common/collapse/Collapse.component";

function CreateActFromGazette({ lawActInfo, hide, handleCallback }) {
  const columns = [
    {
      Header: "Ид",
      accessor: "id",
    },
    {
      Header: "Назив акта",
      accessor: "lawAdActName",
    },
  ];
  const issueTypeClient = new IssueTypeClient();
  const numberClient = new NumberClient();
  const lawAdActClient = new LawAdActClient();
  const lawActClient = new LawActClient();
  const { t } = useTranslation();

  const [issueTypeList, setIssueTypeList] = useState([]);
  const [numbersList, setNumbersList] = useState([]);
  const [authoritiesList, setAuthoritiesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [lawAdActList, setLawAdActList] = useState([]);

  const [issueType, setIssueType] = useState(null);
  const [number, setNumber] = useState(null);
  const [authority, setAuthority] = useState(null);
  const [category, setCategory] = useState(null);
  const [lawAdAct, setLawAdAct] = useState(new AddLawAdActModel());

  const [clicked, setClicked] = useState('1');
  const [nested, setNested] = useState('1.1');
  const [next, setNext] = useState('2');

  const [listOfLockedActs, setListOfLockedActs] = useState([]);
  const [visible, setVisible] = useState(false);

  const [showHierarchyPosition, setShowHierarchyPosition] = useState(false);

  useEffect(() => {
    getIssueTypes();
    getListOfLockedActs();
  }, []);

  useEffect(() => {
    if(lawActInfo.hierarchyMark) {
      setClicked(lawActInfo.hierarchyMark);
      setNested(lawActInfo.hierarchyMark + ".1");
      setNext(nextHierarchyMark(lawActInfo));
    }
    var selected = valueOfSelectedHierarchyMark(lawAdAct.shiftLawAct.hierarchyMarkPosition, lawActInfo);
    setLawAdAct({
      ...lawAdAct,
      shiftLawAct: {
        areaId: lawActInfo.areaId,
        hierarchyMarkPosition: lawAdAct.shiftLawAct.hierarchyMarkPosition,
        createSubarea: false,
        parentId: lawActInfo.id, 
        selectedHierarchyMark: selected,
      },
    });
  }, [lawActInfo, lawAdAct.shiftLawAct.hierarchyMarkPosition]);

  const getIssueTypes = async () => {
    let response = await issueTypeClient.getActiveIssueTypes(true);
    let handledResponse = HandlingResponse(response);
      let array = handledResponse.map((item) => {
        return {
          value: item.guid,
          label: item.description,
        };
      });
      setIssueTypeList(array);
    
  };

  const onChangeIssueType = async (id) => {
    if(id) {
      setIssueType(id);
      let response = await numberClient.getNumbersByIssueType(id);
      let handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        let numbers = handledResponse.map((item) => {
          return {
            value: item.id,
            label: item.numYear,
          };
        });
        setNumbersList(numbers);
      }
    }
    else {
      setIssueType(null);
      setNumbersList([]);
    }
      setAuthoritiesList([]);
      setCategoriesList([]);
      setLawAdActList([]);
      setNumber(null);
      setAuthority(null);
      setCategory(null);
      setShowHierarchyPosition(false);
  };
  const onChangeNumber = async (id) => {
    if(id) {
      setNumber(id);
      let response = await lawAdActClient.getAuthoritiesByNumber(id);
      let handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        let authorities = handledResponse.map((item) => {
          return {
            value: item.guid,
            label: item.authorityName,
          };
        });
        setAuthoritiesList(authorities);
      }
    }
    else {
      setNumber(null);
      setAuthoritiesList([]);
    }
      setCategoriesList([]);
      setLawAdActList([]);
      setAuthority(null);
      setCategory(null);
      setShowHierarchyPosition(false);
  };
  const onChangeAuthority = async (id) => {
    if(id) {
      setAuthority(id);
      let response = await lawAdActClient.getCategoriesByNumberAndAuthority(
        number,
        id);
      let handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        let categories = handledResponse.map((item) => {
          return {
            value: item.guid,
            label: item.description,
          };
        });
        setCategoriesList(categories);
      }
    } else {
      setAuthority(null);
      setCategoriesList([]);
  }
      setCategory(null);
      setLawAdActList([]);
      setShowHierarchyPosition(false);
    
  };
  const onChangeCategory = async (id) => {
    if(id) {
      setCategory(id);
      let response = await lawAdActClient.getLawAdActByNymberAuthorityAndCategory(
        number,
        authority,
        id,
        true
      );
      let handledResponse = HandlingResponse(response);
      if (handledResponse !== "Error") {
        setLawAdActList(handledResponse);
      }
    }
    else  {
      setCategory(null);
      setLawAdActList([]);
    }
    setShowHierarchyPosition(false);
  };

  const handleTableRowClick = useCallback((response) => {
    setShowHierarchyPosition(true);
    setLawAdAct({ ...lawAdAct, lawAdActGuid: response.guid });
  });

  const createLawAdAct = async () => {
    let response = await lawActClient.addLawActFromGazette(lawAdAct);
    let handledResponse = HandlingResponse(response);
    hide();
    handleCallback(lawAdAct.shiftLawAct.areaId);
  };

  const getListOfLockedActs = async () => {
    var response = await lawActClient.getLockedLawActsByAreaId(lawActInfo.areaId);
    var handledResponse = HandlingResponse(response);
    setListOfLockedActs(handledResponse);
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <label>Гласило</label>
          <Select
            options={issueTypeList}
            onChange={(e) => onChangeIssueType(e ? e.value : null)}
            value={issueTypeList.filter((x) => x.value === issueType)}
            isClearable
          />
        </div>
        <div className="col-6">
          <label>Број/Година</label>
          <Select
            options={numbersList}
            onChange={(e) => onChangeNumber(e ? e.value : null)}
            value={numbersList.filter((x) => x.value === number)}
            isClearable
            valForDifferentMessage={issueType}
            message={"Изаберите гласило да бисте добили резултате"}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6">
          <label>Доносилац</label>
          <Select
            options={authoritiesList}
            onChange={(e) => onChangeAuthority(e ? e.value : null)}
            value={authoritiesList.filter((x) => x.value === authority)}
            isClearable
            valForDifferentMessage={number}
            message={
              "Изаберите гласило и број/година да бисте добили резултате"
            }
          />
        </div>
        <div className="col-6">
          <label>Врста законског акта</label>
          <Select
            options={categoriesList}
            onChange={(e) => onChangeCategory(e ? e.value : null)}
            value={categoriesList.filter((x) => x.value === category)}
            isClearable
            valForDifferentMessage={authority}
            message={
              "Изаберите гласило, број-година и доносиоца да бисте добили резултате"
            }
          />
        </div>
      </div>
      <br />
      {lawAdActList.length > 0 ? (
        <Table
          columns={columns}
          data={lawAdActList}
          rememberFilter={false}
          serverSide={false}
          hiddenColumn={"id"}
          handleClickOnRow={handleTableRowClick}
        />
      ) : (
        <div className="text-danger">
          {issueType && number && authority && category && lawAdActList.length > 0 && t("noResult")}
        </div>
      )}
      <br />
      {showHierarchyPosition ? (
        <div>
          <label>Позиција акта</label>
          <div className="radio">
            <label>
              <input
                type="radio"
                value={hierarchyMarkPositionEnum.Clicked}
                checked={
                  lawAdAct.shiftLawAct.hierarchyMarkPosition ==
                  hierarchyMarkPositionEnum.Clicked
                }
                onChange={(e) =>
                  setLawAdAct({
                    ...lawAdAct,
                    shiftLawAct: {
                      ...lawAdAct.shiftLawAct,
                      hierarchyMarkPosition: e.target.value,
                    },
                  })
                }
                name="hierarchyMarkPosition"
              />
              {" " + clicked}
            </label>
          </div>
          <div className="radio radio-margin">
            <label>
              <input
                disabled={
                  (lawActInfo.isSubarea == true ? true : false) ||
                  lawActInfo.addingInEmptyArea
                }
                type="radio"
                value={hierarchyMarkPositionEnum.Nested}
                checked={
                  lawAdAct.shiftLawAct.hierarchyMarkPosition ==
                  hierarchyMarkPositionEnum.Nested
                }
                onChange={(e) =>
                  setLawAdAct({
                    ...lawAdAct,
                    shiftLawAct: {
                      ...lawAdAct.shiftLawAct,
                      hierarchyMarkPosition: e.target.value,
                    },
                  })
                }
                name="hierarchyMarkPosition"
              />
              {" " + nested}
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                disabled={
                  ((lawActInfo.isSubarea == true ? true : false) &&
                    (lawActInfo.children.length === 0 ? true : false)) ||
                  lawActInfo.addingInEmptyArea
                }
                type="radio"
                value={hierarchyMarkPositionEnum.Next}
                checked={
                  lawAdAct.shiftLawAct.hierarchyMarkPosition ==
                  hierarchyMarkPositionEnum.Next
                }
                onChange={(e) =>
                  setLawAdAct({
                    ...lawAdAct,
                    shiftLawAct: {
                      ...lawAdAct.shiftLawAct,
                      hierarchyMarkPosition: e.target.value,
                    },
                  })
                }
                name="hierarchyMarkPosition"
              />
              {" " + next}
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
      {listOfLockedActs.length > 0 && <Collapse
            open={visible}
            setOpen={setVisible}
            title={"Закључани акти у области"}
          >
            {listOfLockedActs.map((item, index) => {
              return <span className="info-about-locked-acts" key={index}>
              <p>{item.userFullName} - {item.lawActName}</p>
            </span>
            })}
          </Collapse>
          }
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={createLawAdAct}
      >
        Додај
      </button>
      </div>
    </>
  );
}

export default CreateActFromGazette;
