import React, { useRef, useState } from "react";
import Table from "../../../common/table/Table.component";
import useFormContext from "../../hooks/useFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DeleteButton } from "../../../common/multiple-files/multipleFiles.style";
import SideModal from "../../../common/modal/SideModal.component";
import CreateGazetteLink from "../create-gazette-link/CreateGazetteLink.component";
import CreateBasicGlobalActReference from "../create-basic-global-act-reference/CreateBasicGlobalActReference.component";
import { TableDiv } from "./OfficialGazette.style";
const OfficialGazette = () => {
  const { data, setData } = useFormContext();
  const GazetteLinkColums = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "LawActId",
      accessor: "lawActId",
    },
    {
      Header: "CountryGuid",
      accessor: "countryGuid",
    },
    {
      Header: "Држава",
      accessor: "countryName",
    },
    {
      Header: "Id",
      accessor: "issueTypeGuid",
    },
    {
      Header: "Издање",
      accessor: "issueTypeName",
    },
    {
      Header: "Број/година",
      accessor: "yearNumber",
    },
    {
      accessor: "pdfDocumentUrl",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <DeleteButton
              type="button"
              onClick={() => {
                deleteGazetteLink(row.index);
              }}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        );
      },
    },
  ];

  const BasicGlobalActRefferencesColumns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "actGuid",
      accessor: "actGuid",
    },
    {
      Header: "Назив акта",
      accessor: "actName",
    },
    {
      Header: "Држава",
      accessor: "actReferenceTypeGuid",
    },
    {
      Header: "Гласило",
      accessor: "additionalInfo",
    },
    {
      accessor: "delete",
      disableSortBy: true,
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <DeleteButton
              type="button"
              onClick={() => {
                deleteBasicGlobalActRefferences(row.index);
              }}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        );
      },
    },
  ];

  const createBasicGlobalActRef = useRef(null);

  const resetValuesInChild = () => {
    if (createBasicGlobalActRef.current) {
      createBasicGlobalActRef.current.resetValues();
    }
  };

  const [showGazetteLinkModal, setShowGazetteLinkModal] = useState(false);
  const [showBasicGlobalActReference, setShowBasicGlobalActReference] =
    useState(false);

  const showModalForGazetteLink = () => setShowGazetteLinkModal(true);
  const hideModalForGazetteLink = () => {
    setShowGazetteLinkModal(false);
    resetValuesInChild();
  }

  const showModalForBasicGlobalActReference = () =>
    setShowBasicGlobalActReference(true);
  const hideModalForBasicGlobalActReference = () => {
    setShowBasicGlobalActReference(false);
    resetValuesInChild();
  }

  const deleteGazetteLink = (id) => {
    const arrayCopy = [...data.officialGazetteModel.gazetteLinks];
    arrayCopy.splice(id, 1);
    setData({
      ...data,
      officialGazetteModel: {
        ...data.officialGazetteModel,
        gazetteLinks: arrayCopy,
      },
    });
  };

  const deleteBasicGlobalActRefferences = (id) => {
    const arrayCopy = [...data.officialGazetteModel.basicGlobalActRefferences];
    arrayCopy.splice(id, 1);
    setData({
      ...data,
      officialGazetteModel: {
        ...data.officialGazetteModel,
        basicGlobalActRefferences: arrayCopy,
      },
    });
  };

  const onAddGazetteLink = () => {
    showModalForGazetteLink();
  };

  const handleAddingGazetteLinkToTable = (childData) => {
    hideModalForGazetteLink();
    setData({
      ...data,
      officialGazetteModel: {
        ...data.officialGazetteModel,
        gazetteLinks: [...data.officialGazetteModel.gazetteLinks, ...childData],
      },
    });
  };

  const onAddBasicGlobalActReference = () => {
    showModalForBasicGlobalActReference();
  };

  const handleAddingBasicGlobalActRefsToTable = (childData, close) => {
   close && hideModalForBasicGlobalActReference();
    setData({
      ...data,
      officialGazetteModel: {
        ...data.officialGazetteModel,
        basicGlobalActRefferences: [...data.officialGazetteModel.basicGlobalActRefferences, ...childData],
      },
    });
  }

  return (
    <>
      <TableDiv>
        <div>
          <h5 className="inline-element">Службена гласила</h5>
          <button
            onClick={onAddGazetteLink}
            type="button"
            className="btn btn-primary"
          >
            Додај
          </button>
        </div>
        <br />
        <Table
          columns={GazetteLinkColums}
          data={data.officialGazetteModel.gazetteLinks}
          rememberFilter={true}
          tableName="REGS_OFFICIAL_GAZETTE_LINKS"
          hiddenColumn={["id", "lawActId", "countryGuid", "issueTypeGuid", "pdfDocumentUrl"]}
          serverSide={false}
        />
      </TableDiv>
      <hr />
      <div>
        <div>
          <h5 className="inline-element">Основни текст, измене, допуне и исправке</h5>
          <button
            onClick={onAddBasicGlobalActReference}
            type="button"
            className=" btn btn-primary"
          >
            Додај
          </button>
        </div>
        <br />
        <Table
          columns={BasicGlobalActRefferencesColumns}
          data={data.officialGazetteModel.basicGlobalActRefferences}
          rememberFilter={true}
          tableName="REGS_OFFICIAL_GAZETTE_GLOBAL_ACT_REF"
          hiddenColumn={["id", "actGuid", "actReferenceTypeGuid"]}
          serverSide={false}
        />
      </div>
      <SideModal
        show={showGazetteLinkModal}
        handleClose={hideModalForGazetteLink}
        title={"Додавање службеног гласила"}
      >
        <CreateGazetteLink 
          handleCallback={handleAddingGazetteLinkToTable}
          ref={createBasicGlobalActRef}
        />
      </SideModal>
      <SideModal
        show={showBasicGlobalActReference}
        handleClose={hideModalForBasicGlobalActReference}
        title={"Додавање основног текста, измене, допуне и исправке"}
      >
        <CreateBasicGlobalActReference 
          fromOfficialGazette={true} 
          handleCallback={handleAddingBasicGlobalActRefsToTable}
          ref={createBasicGlobalActRef}
        />
      </SideModal>
    </>
  );
}

export default OfficialGazette;
