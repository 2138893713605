import React, { useState, useEffect }from 'react';
import AreaClient from '../../api-services/area/area-client';
import LawActClient from '../../api-services/law-act/law-act-client'
import HandlingResponse from '../../../common/handling-response/handlingResponse';
import { mapObjectRecursively } from '../../../utils/common-functions/CommonFunctions';
import IndexingTree from '../../components/indexing-tree/IndexingTree.component';

const Indexing = () => {
  const areaClient = new AreaClient();
  const lawActClient = new LawActClient();

  const [treeData, setTreeData] = useState([]);
  const [disableIndexing, setDisableIndexing] = useState(true);

  useEffect(() => {
    getAllAreasForIndexing();
  }, []);

  const getAllAreasForIndexing = async () => {
    let response = await areaClient.getAllAreasForIndexing();
    let handledResponse = HandlingResponse(response);
    let data = mapObjectRecursively(handledResponse, (el) => {
      return { ...el, selected: false };
    });

    setTreeData(data);
  };

  const handleNodeSelect = (nodeId, selected) => {
    const updatedData = updateSelectedNode(treeData, nodeId, selected);
    setTreeData(updatedData);
    let selectedIds = getSelectedIds();

    if(selectedIds.length > 0) setDisableIndexing(false);
    else setDisableIndexing(true);
  };

  const updateSelectedNode = (data, nodeId, selected) => {
    return data.map((node) => {
      if (node.id === nodeId) {
        node.selected = selected;
      }
      if (node.children.length > 0) {
        node.children = updateSelectedNode(node.children, nodeId, selected);
      }
      return node;
    });
  };

  const getSelectedIds = () => {
    let selectedIds = [];
        mapObjectRecursively(
          treeData,
          (item) => {
            if (item.selected) {
              selectedIds.push(item.id);
            }
          },
          selectedIds
        );
    return selectedIds;
  }

  const startIndexing = async () => {
    if(disableIndexing) {
        let response = await lawActClient.massIndexing({ areaIds: [] });
        HandlingResponse(response);
    }
    else {
        let selectedIds = getSelectedIds();
        let response = await lawActClient.massIndexing({ areaIds: selectedIds });
        HandlingResponse(response);
    }
  };

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginRight: "10px" }}
          onClick={() => startIndexing()}
        >
          Индексирај комплетан регистар
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={disableIndexing}
          onClick={() => startIndexing()}
        >
          Индексирај селектоване области
        </button>
      </div>
      <br />
      <IndexingTree treeData={treeData} handleNodeSelect={handleNodeSelect} />
    </>
  );
}

export default Indexing