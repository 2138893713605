class FilterSmallAddsModel {
    constructor(adNumber = "", ogNumber = "", ogYear = "", ogPublishedDateFrom = null, ogPublishedDateTo = null, documentKindId = null, documentSubkindId = null, documentNumber = "", documentIssuer = "", beneficiaryName = "", beneficiaryJmbg = "", beneficiaryAddress = "", beneficiaryCity = "", beneficiaryZipCode = "", beneficiaryMunicipality = "", beneficiaryCountry = "", decisionNumber = "", decisionIssuer = "", decisionDate = null, adText = "") {
        this.adNumber = adNumber;
        this.ogNumber = ogNumber;
        this.ogYear = ogYear;
        this.ogPublishedDateFrom = ogPublishedDateFrom;
        this.ogPublishedDateTo = ogPublishedDateTo;
        this.documentKindId = documentKindId;
        this.documentSubkindId = documentSubkindId;
        this.documentNumber = documentNumber;
        this.documentIssuer = documentIssuer;
        this.beneficiaryName = beneficiaryName;
        this.beneficiaryJmbg = beneficiaryJmbg;
        this.beneficiaryAddress = beneficiaryAddress;
        this.beneficiaryCity = beneficiaryCity;
        this.beneficiaryZipCode = beneficiaryZipCode;
        this.beneficiaryMunicipality = beneficiaryMunicipality;
        this.beneficiaryCountry = beneficiaryCountry;
        this.decisionNumber = decisionNumber;
        this.decisionIssuer = decisionIssuer;
        this.decisionDate = decisionDate;
        this.adText = adText;
    }
}

export {FilterSmallAddsModel}