import React, { useState, useEffect } from "react";
import Table from "../../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SideModal from "../../../../common/modal/SideModal.component";
import CreateLegalField from "../../../components/legal-fields-component/CreateLegalField.component";
import LegalAreaClient from "../../../api-services/coders/legal-area/legal-area.client";
import HandlingResponse from "../../../../common/handling-response/handlingResponse";
import ButtonWithComfirmation from "../../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const LegalFields = () => {
 const tableName = "SP_LEGAL_FIELDS";
 
  const columns = [
    {
      Header: "Назив правне области",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Тежина",
      accessor: "weight",
    },
    {
      Header: "Активан",
      accessor: "isActive",
      Cell: ({ row }) => {
          return row.original.isActive && <FontAwesomeIcon icon={solid("check")} />;
      },
    },
    {
      accessor: "edit",
      disableSortBy: true,
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
              type="button"
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <ButtonWithComfirmation 
            className="btn btn-danger table-btn" 
            onClick={() => onDeleteClick(row.original.id)}
            contentText={"Правна област ће бити трајно обрисана."}
            handleYesText={"Обриши"}

            >
              <FontAwesomeIcon icon={solid("times")} />
            </ButtonWithComfirmation>
          </>
        );
      },
    },
  ];

  const [legalArea, setLegalArea] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState({
    name: "",
    weight: 0,
    isActive: true,
    userGuid: "",
    lastModifyDate: "",
  });

  const client = new LegalAreaClient();
  
  useEffect(() => {
    LegalArea();
  }, []);

  const showModal = () => setShow(true);
  const hideModal = () => {
    setShow(false);
    setRowData({
      name: "",
      weight: 0,
      isActive: true,
      userGuid: "",
      lastModifyDate: "",
    });
  }

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData({
      name: "",
      weight: 0,
      isActive: true,
      userGuid: "",
      lastModifyDate: "",
    });
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (id) => {
    var response = await client.DeleteLegalArea(id);
    var handledResponse = HandlingResponse(response);
    if(handledResponse !== 'Error') {
      let legalAreaCopy = legalArea.filter((x) => x.id !== id);
      setLegalArea(legalAreaCopy);
    }
  };

  const LegalArea = async () => {
    await client.LegalArea().then((response) => {
      var handledResponse = HandlingResponse(response);
      handledResponse !== 'Error' && setLegalArea(handledResponse);
    });
  }

  return (
    <>
      <h4>Правна област</h4>
      <div className="text-right">
        <button
          onClick={onCreateClick}
          type="button"
          className="btn btn-primary"
        >
          Додај нову правну област
        </button>
      </div>
      <Table
        columns={columns}
        data={legalArea}
        tableName={tableName}
        rememberFilter={true}
        serverSide={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={
          isCreate ? "Креирање нове правне области" : "Измена правне области"
        }
      >
        <CreateLegalField
          row={rowData}
          isCreate={isCreate}
          handleCallback={() => {
            hideModal();
            LegalArea();
          }}
        />
      </SideModal>
    </>
  );
};

export default LegalFields;
