import api from '../../../../api';
import { env } from '../../../../env';

const baseurl = env.REACT_APP_API_URL_VASKE;

class CourtDepartmentClient {
  async CourtDepartment(isBackground) {
    try {
      var response = await api.get(baseurl + "/CourtDepartment", {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async CreateCourtDepartment(data, isBackground) {
    try {
      var response = await api.post(baseurl + "/CourtDepartment", data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async UpdateCourtDepartment(id, data, isBackground) {
    try {
      var response = await api.put(baseurl + "/CourtDepartment/" + id, data, {isBackground: isBackground});
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
  async DeleteCourtDepartment(id, isBackground) {
    try {
      var response = await api.delete(baseurl + "/CourtDepartment?id=" + id, {isBackground: isBackground});
      return response.data;
    } catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }

  async GetCourtDepartmentsByCourt(id,isBackground) {
    try {
      var response = await api.get(baseurl +
        "/CourtDepartment/GetCourtDepartmentsByCourt?courtId=" + id, {isBackground: isBackground}
      );
      return response.data;
    }
    catch (err) {
      if (err.response.status === 400) return err.response.data;
      else return err.response;
    }
  }
}

export default CourtDepartmentClient;