const publishedEnum = {
    All: 2,
    Published: 1,
    Unpublished: 0
  }

class CaseFilterModel {
    constructor(id = "", internalLabelName = "", isPublished= publishedEnum.All,  courtName= "", actCloserDesignation= "", legalUnderstandingAdoptionDate= null,
    legalMatterName= "", actLabelAndNumber= "") {
        this.id = id;
        this.internalLabelName = internalLabelName;
        this.isPublished = isPublished;
        this.courtName = courtName;
        this.actCloserDesignation = actCloserDesignation;
        this.legalUnderstandingAdoptionDate = legalUnderstandingAdoptionDate;
        this.legalMatterName = legalMatterName;
        this.actLabelAndNumber = actLabelAndNumber;
    }
}

export {CaseFilterModel, publishedEnum}