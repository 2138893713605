import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  handleSave,
  serverValidation,
  validateForm,
} from "../../../common/validation/validation";
import LegalMatterClient from "../../api-services/coders/legal-matter/legal-matter-client";
import Joi from "joi-browser";
import Moment from "moment";

const CreateLegalMatter = ({ row, handleCallback, isCreate }) => {
  var { t } = useTranslation();

  const [errors, setErrors] = useState({});

  const [legalMatter, setLegalMatter] = useState({
    id: 0,
    name: "",
    weight: 0,
    isActive: true,
    userGuid: "",
    userFullName: "",
    lastModifyDate: null,
  });

  const schema = {
    name: Joi.string().required(),
    weight: Joi.number().min(0).max(2147483647).required(),
  };

  const client = new LegalMatterClient();

  useEffect(() => {
    setLegalMatter(row);
    setErrors({});
  }, [row, isCreate]);

  const CreateLegalMatter = async () => {
    const data = {
      id: 0,
      name: legalMatter.name,
      weight: legalMatter.weight,
      isActive: legalMatter.isActive,
      lastModifyDate: new Date(),
    };
    const result = validateForm(data, schema, t);
    var response = await client.CreateLegalMatter(data);
    serverValidateAction(response);
    setErrors(result);
  };

  const UpdateLegalMatter = async () => {
    const data = {
      id: legalMatter.id,
      name: legalMatter.name,
      weight: legalMatter.weight,
      isActive: legalMatter.isActive,
      lastModifyDate: new Date(),
    };

    const result = validateForm(data, schema, t);
    var response = await client.UpdateLegalMatter(legalMatter.id, data);
    serverValidateAction(response);
    setErrors(result);
  };

  const serverValidateAction = (response) => {
    var handledResponse = HandlingResponse(response);
    if (handledResponse.code == 408) {
      var result = serverValidation(handledResponse.data, t);
      setErrors(result);
    } else handleCallback(handledResponse);
  };

  const handlePropertyChange = (event) => {
    const obj = { name: event.name, value: event.value };
    const result = handleSave(obj, legalMatter, errors, schema, t);
    setLegalMatter(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Назив<span className="required-asterisk">*</span></label>
          <input
            className="form-control"
            type="text"
            name="name"
            defaultValue={legalMatter.name}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Тежина</label>
          <input
            className="form-control"
            name="weight"
            type="number"
            value={legalMatter.weight}
            onChange={(event) => handlePropertyChange(event.target)}
          />
          {errors?.weight && <div className="text-danger">{errors.weight}</div>}
        </div>
        <div>
          <input
            id="activeMatterCB"
            type="checkbox"
            value={legalMatter.isActive}
            checked={legalMatter.isActive}
            onChange={(event) => {
              setLegalMatter({
                ...legalMatter,
                isActive: event.target.checked,
              });
            }}
          />
          <label htmlFor="activeMatterCB">Активан</label>
        </div>

        {!isCreate ? (
          <>
            <div className="form-group">
              <label>Идентификатор</label>
              <div>{legalMatter.id}</div>
            </div>
            <div className="form-group">
              <label>Корисник</label>
              <div>{legalMatter.userFullName}</div>
            </div>
            <div className="form-group">
              <label>Датум и време последње измене</label>
              <div>
                {Moment(legalMatter.lastModifyDate).format("DD.MM.YYYY.")}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="text-right">
          {isCreate ? (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => CreateLegalMatter()}
            >
              Сачувај
            </button>
          ) : (
            <button
              className="btn btn-primary m-2"
              type="button"
              onClick={() => UpdateLegalMatter()}
            >
              Сачувај измене
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateLegalMatter;
