import { useEffect, useState } from "react";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import Joi from "joi-browser";
import { useTranslation } from "react-i18next";
import { handleSave } from "../../../common/validation/validation";

const ChosenEdition = ({ data, handleEditionChange }) => {
  const [edition, setEdition] = useState({
    packageId: data.value,
    validFrom: new Date(),
    validTo: new Date(),
    comment: "",
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    validFrom: Joi.date().required(),
    validTo: Joi.date().required(),
  };

  useEffect(() => {
    handleEditionChange(edition);
  }, [edition]);

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(obj, edition, errors, schema, t);
    setEdition(result.data);
    setErrors(result.errors);
  };

  return (
    <div className="row col-md-12">
      {/* <h5>{data.label}</h5> */}
      <div className="form-group col-md-4">
        <label>Важи од<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={edition.validFrom}
          onChange={(date) => handlePropertyChange(date, "validFrom")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.validFrom && (
          <div className="text-danger">{errors.validFrom}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Важи до<span className="required-asterisk">*</span></label>
        <CustomDatePicker
          selected={edition.validTo}
          onChange={(date) => handlePropertyChange(date, "validTo")}
          showMonthDropdown
          useShortMonthInDropdown
        />
        {errors?.validTo && <div className="text-danger">{errors.validTo}</div>}
      </div>
      <div className="form-group col-md-4">
        <label>Коментар</label>
        <input
          className="form-control"
          type="text"
          value={edition.comment}
          onChange={(e) => setEdition({ ...edition, comment: e.target.value })}
        />
      </div>
    </div>
  );
};

export default ChosenEdition;
